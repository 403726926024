import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Main_Page from './mainPage';
import Home from './Home';
import ViewTilesQuote from './ViewTilesQuote';
import ItemsforQuote from './ItemsforSO';
import ConsumerLogin from './ConsumerLogin';
import OTPpage from './OTPpage';
import AddUserDetails from './AddUserDetails';
import NotApproved from './NotApproved';
import AddProject from './AddProject';

 import DoorsQuote from './DoorsQuote';
// import WindowsQuoteDetails from './WindowsquoteDetails';

//import MaterialReq from './MaterialReq';
import ViewWiendowDetailsQuote from './ViewQuotationDetails';
import ViewQuotationDetails from './ViewQuotationDetails';
import WindowSummary from './WindowSummary';
import QuotationSummary from './QuotationSummary';
import DoorSummary from './DoorSummary';
import DoorQuotationSummary from './DoorQuotationSummary';
import TilesOrderSummary from './TilesOrderSummary';


 import ViewWindowsQuote from './ViewQuotationDetails';
 import MaterialRequirement from './MaterialRequirement';
 import MaterialReq from './MaterialReq';
 import MaterialReq2 from './MaterialReq2';
 import QuotationDetails from './QuotationDetails';
import WindowsQuotationDetails from './WindowsQuotationDetails';
import WindowsEconomyQuote from './WindowQuote';
 import DoorQuotationDetails from './DoorQuotationDetails';
import TilesQuote from './TilesQuote';
import WindowQuote from './WindowQuote';
import QuoteSummary from './TilesQuoteSummary';
import DoorsQuoteSummary from './DoorsQuoteSummary'
import WindowsQuotationSummary from './WindowsQuoteSummary';
import SummaryPage from './SummaryPage';


 
function App() {
/*   const [appId, setappId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const BrickBucket = () => {
      if (location.pathname.startsWith('/measurit_v1.0')) {
        const storedLoginData = JSON.parse(localStorage.getItem('LoginData'));
        if (storedLoginData) {
          const appId = storedLoginData.appId;
        setappId(appId);
        setLoading(false);
        } else {
          // Handle case where user data is not available
          // For example, navigate to login page
          if (location.pathname.startsWith('/measurit_v1.0') && 
          !location.pathname.startsWith('/measurit_v1.0/reset-password') &&
          !location.pathname.startsWith('/measurit_v1.0/forgot-password') &&
          !location.pathname.startsWith('/measurit_v1.0/reset-password')
      ) {
          navigate('/measurit_v1.0/adminhome');
          setLoading(false);
      }
          
        }
      }
    };

    BrickBucket(); // Call BrickBucket function directly here
  }, [navigate, location.pathname]); // Add navigate and location.pathname as dependencies
 */

  /* if (loading) {
    return <div>Loading...</div>;
  } */

/*   const AccessDenied = () => {
    return (
      <div
        className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
        style={{ backdropFilter: 'blur(100px)' }}
        role="dialog"
        aria-modal="true"
      >
<div className="bg-white rounded shadow-md p-8 max-w-md">
  <h2 className="text-lg font-bold text-center">Access Denied</h2>
  <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
  <div className="flex justify-center mt-2">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/bb');
      }}
    >
      Go Back
    </button>
  </div>
</div>
      </div>
    );
  }; */

  return (
    <Routes>
       {/* <Route path="/bb" element={<Login />} /> */}
       <Route path='/consumer' element={<ConsumerLogin />} />
       <Route path='/consumer/otp' element={<OTPpage />} />
       <Route path='/consumer/UserDetails' element={<AddUserDetails/>}/>
       <Route path='/consumer/NotApproved' element={<NotApproved/>}/>
       <Route path='/consumer/addproject' element={<AddProject/>}/>
       
     <Route path="/consumer/app" element={<Main_Page />}>
       <Route path='home' element = {<Home/>}/> 
       <Route path='materialrequirement' element={<MaterialRequirement/>} />
       <Route path='materialreq' element={<MaterialReq/>} />
       <Route path='materialreq2' element={<MaterialReq2/>} />
       <Route path='tilesquote' element={<TilesQuote/>} />
       <Route path='viewtilesquote' element = {<ViewTilesQuote/>}/>
       <Route path='itemsforso' element = {<ItemsforQuote/>} />
         <Route path='doorsquote' element = {<DoorsQuote/>} /> 
           <Route path='viewquotationdetails' element={<ViewQuotationDetails/>}/>
           <Route path='windowquote' element = {<WindowQuote/>} />
        {/* <Route path='windowsQuoteDetails' element = {<WindowsQuoteDetails/>} />  */}
        <Route path='windowsummary' element = {<WindowSummary/>} />
        <Route path='quotationsummary' element = {<QuotationSummary/>} />
        <Route path='doorsummary' element= {<DoorSummary/>} />
        <Route path='doorquotationsummary' element= {<DoorQuotationSummary/>} />
        <Route path='tilesordersummary' element = {<TilesOrderSummary/>} />
        <Route path='MaterialReq' element = {<MaterialReq/>} />
        <Route path='quotationdetails' element = {<QuotationDetails/>} />
        <Route path='doorsQuotationDetails' element = {<DoorQuotationDetails/>} />
        <Route path='windowsquotationdetails' element = {<WindowsQuotationDetails />} />
        <Route path='quotesummary' element = {<QuoteSummary/>}/>
        <Route path='doorsquotesummary' element = {<DoorsQuoteSummary/>}/>
        <Route path='windowsquotesummary' element = {<WindowsQuotationSummary/>}/>
        <Route path = 'summarydetails' element = {<SummaryPage/>}/>
      </Route>
   
    </Routes>
  );
}

export default App;
