import React from 'react';
import { FaTimes, FaDoorOpen } from 'react-icons/fa';

const DoorDetailsModal = ({ isVisible, onClose, doors, categoryName }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-end justify-center z-50">
    <div className="absolute bottom-0 inset-x-0 sm:h-[100vh] h-screen bg-black bg-opacity-50 sm:bg-opacity-0"></div>
    <div className="bg-white rounded-t-lg shadow-lg w-full max-w-2xl p-6 relative z-10">
  
  
  
        {/* Closing Line */}
        <div 
          className="absolute top-0 left-0 right-0 h-6 bg-gray-200 cursor-pointer"
          onClick={onClose}
        >
          <div className="w-12 h-1 bg-gray-400 rounded-full mx-auto mt-2"></div>
        </div>
        <div className="flex justify-between items-center mb-4 mt-6">
          <h2 className="text-xl font-bold text-gray-800">{categoryName}</h2>
          <button onClick={onClose}>
            <FaTimes size={24} color="#EA011F" />
          </button>
        </div>
        <div className="max-h-96 overflow-y-auto">
          {doors.map((door) => (
            <div 
              key={door.door_name} 
              className="flex justify-between items-center p-4 bg-gray-50 rounded-xl mb-3"
            >
              <div className="flex items-center flex-1">
                <FaDoorOpen size={20} color="#4A5568" className="mr-3" />
                <div>
                  <p className="text-base font-medium text-gray-800">{door.door_name}</p>
                  <p className="text-sm text-gray-500 mt-1">{door.room_name}</p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-sm font-medium text-gray-700 mr-4">
                  {door.area.toFixed(2)} sq.ft
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoorDetailsModal;