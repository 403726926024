import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSortUp, faSortDown, faSearch,    } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { FaChevronDown } from 'react-icons/fa';
import Search from '../Components/Search';

const AllItemGroups = () => {
    const  [ItemGroups , setItemGroups] = useState ([])
    const navigate = useNavigate()
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedItemGroups, setSelectedItemGroups] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const [retryFunction, setRetryFunction] = useState(null);
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
  const [roleId, setRoleId] = useState(null);
  const [filteredItemGroups, setFilteredItemGroups] = useState([]);
const searchTypes = ['Item Group', 'Vendors', 'Item Category', 'Item Sub Category'];


  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  
    // Function to get nested value using key
    const getNestedValue = (item, key) => {
      return key.split('.').reduce((value, keyPart) => {
        return value ? value[keyPart] : undefined;
      }, item);
    };
  
    const sortedArray = [...ItemGroups].sort((a, b) => {
      const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
      const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string
  
      // Convert to string for comparison and handle case insensitivity
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();
  
      
  
      if (aString < bString) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aString > bString) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    setItemGroups(sortedArray);
  };
  

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };
  
  
  useEffect(() => {
    fetchRoleId()
    fetchItemGroups();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [ItemGroups]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredItemGroups(ItemGroups);
      return;
    }
  
    const filtered = ItemGroups.filter((group) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Item Group':
          return group.product_group_name?.toLowerCase().includes(lowercaseQuery);
        case 'Vendors':
          return group.vendor_id?.name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Category':
          return group.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Sub Category':
          return group.product_sub_category_id?.product_sub_category_name?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredItemGroups(filtered);
  };
  

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const fetchItemGroups = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `product-groups/get-all-productGroups?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-token': token
        },
      });
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setItemGroups(data.data);
        console.log(data.data);
      } else if (data.status === 400) {
        setShowPopup(true);
        setPopupType('400');
        setPopupMessage('Bad Request!');
      } else if (data.status === 401) {
        setShowPopup(true);
        setPopupType('401');
        setPopupMessage('Unauthorized!');
      } else if (data.status === 403) {
        setShowPopup(true);
        setPopupType('403');
        setPopupMessage('Forbidden!');
      } else if (data.status === 404) {
        setShowPopup(true);
        setPopupType('404');
        setPopupMessage('Not Found!');
      } else if (data.status === 500) {
        setShowPopup(true);
        setPopupType('500');
        setPopupMessage('Internal Server Error!');
      } else {
        setShowPopup(true);
        setPopupType('unknown');
        setPopupMessage('Unknown error!');
      }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchItemGroups(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchItemGroups(attempt)); // Set retry function
      }
    } finally {
      setIsLoading(false);
    }
  };


if (isLoading) {
  return <Loader />;
}


const handleCheckboxChange = (group) => {
  if (selectedItemGroups.includes(group)) {
    setSelectedItemGroups(selectedItemGroups.filter((g) => g !== group));
    setSelectAll(false); // Update selectAll state when deselecting a checkbox
  } else {
    setSelectedItemGroups([...selectedItemGroups, group]);
    if (selectedItemGroups.length === ItemGroups.length - 1) {
      setSelectAll(true); // Update selectAll state when selecting all checkboxes
    }
  }
};

const handleSelectAll = () => {
  if (selectAll) {
    setSelectedItemGroups([]);
    setSelectAll(false);
  } else {
    setSelectedItemGroups(ItemGroups);
    setSelectAll(true);
  }
};

const handleDelete = async (attempt = 1) => {
  const selectedItemGroupIds = selectedItemGroups.map((group) => group._id);
  console.log(selectedItemGroupIds);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "item-groups/delete-item-groups", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify({ ItemGroupIds: selectedItemGroupIds })
    });

    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      // Update the vendors state to reflect the deleted vendors
      setItemGroups(ItemGroups.filter((group) => !selectedItemGroupIds.includes(group._id)));
      setSelectedItemGroups([]);
      setSelectAll(false);
    } else {
      // Handle error cases
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  }  catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
    }
  }
};

const handleCreate = () => {
  navigate("/bb/app/items/newproductgroups");
};
 const handleNavigateItemGroupsDetails = (group) =>{
  navigate("/bb/app/items/productgroupdetails", {state:{ItemGroupData:group}})
 }

 const closePopup = () => {
  setShowPopup(false);
};

const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};


const groupsToDisplay = filteredItemGroups.length > 0 ? filteredItemGroups : ItemGroups;



  return (
<div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
  <div className="flex w-full flex-row">
    <div className="w-full flex-1">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <div className="w-full h-full flex flex-col justify-between">
          <div className="w-full h-16 border-b flex items-center">
            <div className="flex w-full justify-between px-8 items-center">
              {selectedItemGroups.length > 0 ? (
                <button onClick={handleDelete} className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md">
                  Delete
                </button>
              ) : (
                <>
                  <div className="space-x-10 flex flex-row">
                    <h1 className="text-lg font-bold">All Item Groups</h1>
                    <Search onSearch={handleSearch} searchTypes={searchTypes} />
                  </div>
                  <button
        onClick={handleCreate}
        className="bg-[#F7525A] z-20 text-white px-2 py-1 rounded"
      
        title="" data-tooltip="Create Item Group"  style={{ '--tooltip-bg': 'black' }}>
        + New
      </button>
                </>
              )}
            </div>
          </div>

          <div className="w-full h-full bg-white rounded-xs overflow-hidden">
            <div className="overflow-y-auto h-[80vh]">
              {groupsToDisplay.length > 0 ? (
                <table className="w-full mb-5">
                  <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                    <tr className="border-b">
                      <th className="text-xs py-3 text-center">
                        <input type="checkbox" className="mr-2" onChange={handleSelectAll} checked={selectAll} />
                      </th>
                      <th className="text-xs py-3 text-center">ITEM GROUP IMAGE</th>
                      <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_group_name')}>
                        ITEM GROUP {getSortIcon('product_group_name') && <FontAwesomeIcon icon={getSortIcon('product_group_name')} />}
                      </th>
                      <th className="text-xs py-3 text-center">PURCHASE RATE</th>
                      <th className="text-xs py-3 text-center">RATE</th>
                      <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_category_id.product_category_name')}>
                        ITEM CATEGORY {getSortIcon('product_category_id.product_category_name') && <FontAwesomeIcon icon={getSortIcon('product_category_id.product_category_name')} />}
                      </th>
                      <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('vendor_id.name')}>
                        VENDOR {getSortIcon('vendor_id.name') && <FontAwesomeIcon icon={getSortIcon('vendor_id.name')} />}
                      </th>
                      <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('product_sub_category_id.product_sub_category_name')}>
                        ITEM SUB CATEGORY {getSortIcon('product_sub_category_id.product_sub_category_name') && <FontAwesomeIcon icon={getSortIcon('product_sub_category_id.product_sub_category_name')} />}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b border-t">
                  {filteredItemGroups.length > 0 ? (
    filteredItemGroups.map((group) => (
                        <tr key={group._id} className="text-sm border-b">
                          <td className="py-2 text-center">
                            <input type="checkbox" className="mr-2" onChange={(e) => handleCheckboxChange(group)} checked={selectedItemGroups.includes(group) || selectAll} />
                          </td>
                          <td className="py-2 text-center">
                            {group?.product_group_image && (
                              <div className="flex justify-center items-center">
                                <img src={imageUrl + group.product_group_image} alt={group.product_group_name} className="w-16 h-16 object-cover" />
                              </div>
                            )}
                          </td>
                          <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={() => handleNavigateItemGroupsDetails(group)}>
                            {group.product_group_name}
                          </td>
                          <td className="py-2 text-center">₹{group?.cost_price?.toLocaleString()}</td>
                          <td className="py-2 text-center">₹{group?.selling_price?.toLocaleString()}</td>
                          <td className="py-2 text-center">{group.product_category_id?.product_category_name}</td>
                          <td className="py-2 text-center">{group.vendor_id?.name}</td>
                          <td className="py-2 text-center">{group.product_sub_category_id?.product_sub_category_name}</td>
                        </tr>
    ))
  ) : (

    <tr>
    <td colSpan="10" className="text-center text-[#6c7184] py-4">
      Not found
    </td>
  </tr>
)}
                  </tbody>
                </table>
              ) : (
                <>
                  <div className="m-4 p-4 text-center border border-grey-500 text-gray-500">
                    <h1 className="text-lg">No Item Groups Available</h1>
                  </div>
                  <div className="flex justify-center items-center mb-4">
                    <h1 className="text-xl font-bold">Create New Item Group</h1>
                  </div>
                  <div className="flex justify-center items-center">
                    <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>
                      Create
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {showPopup && (
    <div>
      {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
      {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
      {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
      {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
    </div>
  )}
</div>

    
  );
};

export default AllItemGroups;