import React from 'react';

const LoaderModal = ({ isVisible, title, packageName }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black/40 backdrop-blur-sm absolute inset-0"></div>
      <div className="relative bg-white rounded-xl p-8 shadow-xl max-w-sm w-full mx-4">
        <div className="flex flex-col items-center">
          {/* Animated Logo */}
          <div className="mb-8">
            <div className="w-16 h-16 relative">
              <div className="absolute inset-0 border-4 border-gray-100 rounded-full"></div>
              <div className="absolute inset-0 border-4 border-t-[#EA011F] border-r-[#EA011F] rounded-full animate-spin"></div>
              <div className="absolute inset-2 bg-white rounded-full flex items-center justify-center">
                <svg className="w-8 h-8 text-[#EA011F] animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                </svg>
              </div>
            </div>
          </div>

          {/* Package Info */}
          <div className="text-center space-y-3">
            <span className="inline-block px-4 py-1 bg-red-50 text-[#EA011F] rounded-full text-sm font-medium">
              {title || 'Switching to'}
            </span>
            
            {packageName && (
              <h3 className="text-xl font-bold text-gray-800">
                {packageName}
              </h3>
            )}

            <div className="flex items-center justify-center gap-2">
              {[...Array(3)].map((_, i) => (
                <div
                  key={i}
                  className="w-1.5 h-1.5 bg-[#EA011F] rounded-full animate-bounce"
                  style={{ animationDelay: `${i * 150}ms` }}
                />
              ))}
            </div>
          </div>

          {/* Progress Bar */}
          <div className="w-full mt-8">
            <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
              <div className="h-full bg-gradient-to-r from-red-300 to-[#EA011F] animate-pulse w-3/4 rounded-full"></div>
            </div>
            <p className="text-center text-sm text-gray-500 mt-3">Please wait while we process your request</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderModal;
