import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronDown, faChevronUp, faSortUp, faSortDown, faX, faPaperclip, faPlus, faPen, faMobileAndroidAlt, faPlusCircle, faSave, faTimes, faTrash, faBank } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import  '../Css/Tooltip.css';
import {Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className="relative w-1/6" ref={dropdownRef}>
      {trigger}
      {isOpen && (
        <div className="absolute mt-1 w-full h-40 bg-white border border-gray-300 rounded-md shadow-lg z-10 flex flex-col">
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {options.map((option) => (
              option.value !== 'add-new' && (
                <div className='p-1'>
                  <div
                    key={option.value}
                    className="py-2 pl-1 cursor-pointer text-sm hover:bg-blue-500 hover:rounded-md  hover:text-white"
                    onClick={() => handleOptionClick(option.value)}
                  >
                    {option.icon && <FontAwesomeIcon icon={option.icon} className="" />}
                    {option.label}
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ViewVendor = () => {
  const navigate = useNavigate();
  const  [vendors , setVendors] = useState ([]);
  const location = useLocation()
  const {VendorData}= location.state
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [showAddress, setShowAddress] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorid , setVendorid] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showOtherDetails , setShowOtherDetails] = useState(false);
  const [showContactPersons , setShowContactPersons] = useState(false);
  const [ShowBankAccountDetails, setShowBankAccountDetails] = useState(false);
  const [ VendorContactPersons , setVendorContactPersons] = useState([]);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [showVendorDetailsPopup, setVendorDetailsPopup] = useState(false);
  const [showProjectDetailsPopup, setShowProjectDetailsPopup] = useState(false);
  const [showAddContactPersonPopup, setShowAddContactPersonPopup] = useState(false);
  const [showAddBankAccountDetails, setShowAddBankAccountDetails] = useState(false);
  const [showAssignProductCategoryPopup , setShowAssignProductCategoryPopup] = useState(false);
  const [ShowEditContactPersonPopup ,setShowEditContactPersonPopup] = useState(false);
  const [ShowDeleteContactPersonPopup , setShowDeleteContactPersonPopup] = useState(false);
  const [newContactPerson, setNewContactPerson] = useState({
    name: '',    email: '',
    mobile_number: '',    designation: '',  });
    const [newBankAccount, setNewBankAccount] = useState({
      bank_name: '',    bank_branch: '',
      account_holder_name: '',    bank_IFSC: '', account_number: '' });
  const [salutation, setSalutation] = useState('');
  const [categories,setCategories] = useState([]);
  const [vendorCategories,setVendorCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [alreadySavedCategories, setAlreadySavedCategories] = useState({});
  const [selectedOption, setSelectedOption] = useState(null); // define selectedOption and setSelectedOption
  const [product_category_ids,setproduct_category_ids] = useState();
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorStatus, setVendorStatus] = useState('Deactivate');
  const RETRY_LIMIT = 3; // Maximum number of retries
const [retryCount, setRetryCount] = useState(0); // Track retry attempts
const [PurchaseOrders, setPurchaseOrders] = useState([]);
const [vendorEditErrors, setVendorEditErrors] = useState([]);
const [contactPersonErrors, setContactPersonErrors] = useState([]);


  useEffect(() => {
    fetchVendors(VendorData);
}, [VendorData]);

    const fetchVendors = async (Vendor,attempt = 1) => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `vendor/get-all-vendors?organizationId=${sessionStorage.getItem('organizationId')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                }
            }); 
            const data = await response.json();
            if (data.status === 200 || data.status === 201 || data.status === 204) {
              setVendors(data.data);
              const FindVendor = data.data.find(p => p._id===Vendor._id)
              handleVendorClick(FindVendor);
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setPopupMessage(data.message);
              setPopupType(`${data.status}`);
              setShowPopup(true);
            }
          } 
          catch (error) {
            console.error('Failed to fetch:', error);
        
            if (attempt < RETRY_LIMIT) {
              setRetryCount(attempt + 1); // Increment retry count
              setTimeout(() => fetchVendors(attempt + 1), 1000); // Retry after 1 second
            } else {
              setIsLoading(false);
              setPopupMessage('Internal Server Error. Kindly Retry.');
              setPopupType('500');
              setShowPopup(true);
              setRetryFunction(() => () => fetchVendors(attempt)); // Set retry function
            }
          }
        };


/* useEffect(()=>{
  if(selectedVendor){
  }else{
    setVendorid(VendorData._id);
    setSelectedVendor(VendorData)
    fetchProductCategoriesOfSelectedVendor(VendorData._id);
  }
},[VendorData]) */

const fetchProductCategories = async (attempt = 1) => {
  try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-category/get-all-productCategories", {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'x-token':token
          }
      });
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setCategories(data.data); 
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }
  };

  const fetchProductCategoriesOfSelectedVendor = async (idofvendor, attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}product-category/get-productCategoriesBy-vendor_id?vendor_id=${idofvendor}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
  
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        if (Array.isArray(data.data)) {
          const assignedCategoryIds = data.data.map(category => category._id);
          setVendorCategories(assignedCategoryIds);
        } else if (data.status === 404) {
          console.error('Unexpected response format:', data);
          setVendorCategories([]);
          setSelectedCategories({});
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPopupMessage(data.message || 'An error occurred');
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategoriesOfSelectedVendor(idofvendor, attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategoriesOfSelectedVendor(idofvendor, attempt)); // Set retry function
      }
    }
  };
  

  
  const handleCategoryChange = (categoryId) => {
    setVendorCategories(prevCategories => {
      // Check if the categoryId is already in the array
      const isCategoryIdPresent = prevCategories.includes(categoryId);
      
      // If the categoryId is present, remove it from the array
      if (isCategoryIdPresent) {
        return prevCategories.filter(id => id !== categoryId);
      }
      
      // If the categoryId is not present, add it to the array
      return [...prevCategories, categoryId];
    });
  };
  


const selectedCategoriesToassign ={
  vendor_id:vendorid,
  product_category_ids:product_category_ids,
}


const assignProductCategoriesToSelectedVendor = async (attempt = 1) => {
  setIsSaving(true);
    //const selectedCategoryIds = Object.keys(selectedCategories).filter(categoryId => selectedCategories[categoryId]);
    const selectedCategoriesToassign = {
      vendor_id: vendorid,
      product_category_ids: vendorCategories,
    };
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${backendApiUrl}product-category/assign-productCategory-to-vendor?vendor_id=${vendorid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(selectedCategoriesToassign),
    });
    const data = await response.json();
    if (data.status === 201 || data.status === 200 || data.status === 204) {
      setIsSaving(false);
      setPopupType('200or201');
      setPopupMessage(data.message);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      // Update vendorCategories state with the new data
      const newVendorCategories = [...vendorCategories];
      vendorCategories.forEach(categoryId => {
        const category = categories.find(cat => cat._id === categoryId);
        if (category && !newVendorCategories.find(cat => cat._id === categoryId)) {
          newVendorCategories.push(category);
        }
      });
      setVendorCategories(newVendorCategories);
      setShowAssignProductCategoryPopup(false);
      setIsLoading(false);
    } else if (response.status === 500) {
      setIsSaving(false);
      setPopupType('500');
      setRetryFunction(() => assignProductCategoriesToSelectedVendor);
      setPopupMessage(data.message);
      setShowPopup(true);
    } else {
      setIsSaving(false);
      setPopupType(`${data.status}`);
      setPopupMessage(data.message);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => assignProductCategoriesToSelectedVendor(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsSaving(false);
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => assignProductCategoriesToSelectedVendor(attempt)); // Set retry function
    }
  }
};


useEffect(() => {
  if (selectedVendor) {
    fetchContactPersonsByVendorId(selectedVendor._id)
  }
}, [selectedVendor]);

const fetchContactPersonsByVendorId = async (vendorId,attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const GetContactPerson = `${backendApiUrl}vendor/get-vendor-and-contacts?vendor_id=${vendorId}`;
    const response = await fetch(GetContactPerson, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });
    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      setVendorContactPersons(data.data.contacts? data.data.contacts : []);  
      setIsLoading(false);
    } else if (data.status === 404 ) {
      setVendorContactPersons([]);
      setIsLoading(false);
    }else if (data.status === 500 ) {
      setPopupMessage(data.message);
      setPopupType('500');
      setRetryFunction(() => fetchContactPersonsByVendorId);
      setShowPopup(true);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
      setRetryFunction(() => fetchContactPersonsByVendorId);
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
    }  catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchContactPersonsByVendorId(vendorId,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchContactPersonsByVendorId(vendorId,attempt)); // Set retry function
      }
    }
    };



const handleVendorInputfieldChange = (e) => {
  const { id, value } = e.target;
  if (id === 'mobile_number') {
    // Check for numbers only
    if (!/^[0-9]*$/.test(value)) {
      return;
    }
    
    // Limit to 10 digits
    if (value.length > 10) {
      return;
    }

    // Validate first digit
    if (value.length === 1 && !['6','7','8','9'].includes(value)) {
      setErrorMessages(['Mobile number must start with 6, 7, 8 or 9']);
      return;
    }

    // Validate length
    if (value.length < 10 && value.length > 0) {
      setErrorMessages(['Enter 10 digits mobile number']);
    } else {
      setErrorMessages([]);
    }
  }


  setSelectedVendor((prevselectedVendor) => ({
     ...prevselectedVendor,
     [id]: value
  }));
};

const handleContactPersonInputfieldChange = (e) => {
  const { id, value } = e.target;
  setSelectedContactPerson((prevselectedContactPerson) => ({
     ...prevselectedContactPerson,
     [id]: value
  }));
};

const handleEditContactPerson = (contactPerson) => {
  setSelectedContactPerson(contactPerson);
  setShowEditContactPersonPopup(true);
};

const handleUpdateVendor = async (attempt = 1) => {
  const token = sessionStorage.getItem('token');
  const url = process.env.REACT_APP_FORE_BRICKBUCKET + "vendor/update-vendor";

  // Initialize error messages array
  const errorMessages = [];
  if (!selectedVendor.name) {
    errorMessages.push('Name is required.');
  }
    // Validate mobile number required
    if (!selectedVendor.mobile_number) {
      errorMessages.push('Mobile number is required.');
    } else if (selectedVendor.mobile_number.length !== 10) {
      // Validate mobile number length
      errorMessages.push('Mobile number must be exactly 10 digits.');
    }

  // Validate email if provided
  if (selectedVendor.email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(selectedVendor.email)) {
      errorMessages.push('Invalid email address.');
    }
  }

  // If there are any errors, display them and stop the execution
  if (errorMessages.length > 0) {
    setVendorEditErrors(errorMessages);
    setIsSaving(false);
    return;
  }
  setIsSaving(true); // Set isSaving to true when the Save button is clicked
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(selectedVendor)
    });
    const responseData = await response.json();
    if (responseData.status === 200 || responseData.status === 201 || responseData.status === 204) {
      handleSetUpdatingVendorsData(selectedVendor);
      setIsLoading(false);
      // Close the current popup and loader
      setVendorDetailsPopup(false);
      setIsSaving(false);
      setIsPanEditing(false);
      setIsGSTEditing(false);
      SetisAddressEditing(false)
      // Display a new popup with a 5-second timer and response message
      setShowPopup(true);
      setPopupType('200or201');
      setPopupMessage(responseData.message);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    } else if (responseData.status === 500) {
      setIsLoading(false);
      setIsSaving(false);
      setShowPopup(true);
      setPopupType('500');
      setRetryFunction(() => handleUpdateVendor);
      setPopupMessage(responseData.message);
      // Log the user out and redirect to login page
    } else {
      setIsSaving(false);
      setIsLoading(false);
      setPopupMessage(responseData.message);
      setPopupType(`${responseData.status}`);
      setShowPopup(true);
      setVendorDetailsPopup(false);
    }
  }  catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleUpdateVendor(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleUpdateVendor(attempt)); // Set retry function
    }
  } finally {
    setErrorMessages([]);
  }
};

const handleSetUpdatingVendorsData = async (selectedVendor) => {
  const FindingVendorIndex = vendors.findIndex(p => p._id === selectedVendor._id);
  if (FindingVendorIndex !== -1) {
    // Create a new array with the updated vendor data to avoid mutating the state directly
    const updatedVendors = vendors.map((vendor, index) =>
      index === FindingVendorIndex
        ? { ...vendor, ...selectedVendor }
        : vendor
    );
    // Update the state with the new array
    setVendors(updatedVendors);
  }
};

  const handleVendorClick = (vendor) => {
    setVendorCategories([]);
    setVendorid(vendor._id);
    setSelectedVendor(vendor);
    setPurchaseOrders([]);
    fetchVendorPurchaseOrders(vendor._id);

  };

  const handleToggleAddress = () => {
    setShowAddress(!showAddress);
    setShowOtherDetails(false);
    setShowContactPersons(false);
    setShowBankAccountDetails(false);
  };

  const handleToggleOtherDetails = () => {
    setShowOtherDetails(!showOtherDetails);
    setShowContactPersons(false);
    setShowAddress(false);
    setShowBankAccountDetails(false);
  };

  const handleToggleContactPersons = () => {
    setShowContactPersons(!showContactPersons);
    setShowAddress(false);
    setShowOtherDetails(false);
    setShowBankAccountDetails(false);
  };

  const handleToggleBankAccountDetails = () => {
    setShowContactPersons(false);
    setShowAddress(false);
    setShowOtherDetails(false);
    setShowBankAccountDetails(!ShowBankAccountDetails);
  };

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...vendors].sort((a, b) => {
      if (a[key] < b[key]) {
       return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setVendors(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  const handleDeactivateClick = async (attempt = 1) => {
    setIsLoading(true);
    console.log(vendorid);
      try {
        const token = sessionStorage.getItem('token');
        const ActivateURL = `${backendApiUrl}vendor/deactivate-vendor?vendorId=${vendorid}`;
        const response = await fetch(ActivateURL, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        console.log(data);
        if (data.status === 200 || data.status === 201 || data.status === 204) {
          setSelectedVendor((prevVendor) => ({ ...prevVendor, is_active: false }));
          setVendorStatus('Activate');
          setShowPopup(true);
          setIsLoading(false);
          setPopupType('200or201');
          setPopupMessage(data.message);
          setTimeout(() => {
            setShowPopup(false);
          }, 3000);
          // Update the vendor status in the local state
          setVendors(vendors.map((vendor) => {
            if (vendor._id === vendorid) {
              vendor.is_active = false;
            }
            return vendor;
          }));
        } else {
          setIsLoading(false);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleDeactivateClick(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleDeactivateClick(attempt)); // Set retry function
        }
      }
    };
  
  const handleActivateClick = async (attempt = 1) => {
    setIsLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const ActivateURL = `${backendApiUrl}vendor/activate-vendor?vendorId=${vendorid}`;
        const response = await fetch(ActivateURL, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        if (data.status === 200 || data.status === 201 || data.status === 204) {
          setSelectedVendor((prevVendor) => ({ ...prevVendor, is_active: true }));
          setVendorStatus('Deactivate');
          setShowPopup(true);
          setIsLoading(false);
          setPopupType('200or201');
          setPopupMessage(data.message);
          setTimeout(() => {
            setShowPopup(false);
          }, 3000);
          // Update the vendor status in the local state
          setVendors(vendors.map((vendor) => {
            if (vendor._id === vendorid) {
              vendor.is_active = true;
            }
            return vendor;
          }));
        } else {
          setIsLoading(false);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleActivateClick(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleActivateClick(attempt)); // Set retry function
        }
      }
    };

const handleEditClick = () => {
  setVendorDetailsPopup(true);
};


const handlePlusIconClick = (contactPerson) => {
  setSelectedContactPerson(contactPerson);
  setShowAddContactPersonPopup(true);
};

const handleBankPlusIconClick = () => {
  setShowAddBankAccountDetails(true);
}


const handleClosePopup = () => {
  setIsSaving(false);
  setVendorEditErrors([]);
  setContactPersonErrors([]);
  setVendorDetailsPopup(false);
  setShowAddContactPersonPopup(false);
  setShowProjectDetailsPopup(false);
  setShowAssignProductCategoryPopup(false);
  setShowEditContactPersonPopup(false);
  setShowDeleteContactPersonPopup(false);
};

const handleUpdateContactPerson = async (attempt = 1) => {
  if (selectedContactPerson) {
    // Update contact person
    const updatedContactPerson = {
      _id: selectedContactPerson._id,
      customer_id: selectedContactPerson.customer_id,
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      mobile_number: document.getElementById('mobile_number').value,
      designation: document.getElementById('designation').value,
    };

    const errorMessages = [];
    if (!updatedContactPerson.name) {
      errorMessages.push('Name is required.');
    }
      // Validate mobile number required
      if (!updatedContactPerson.mobile_number) {
        errorMessages.push('Mobile number is required.');
      } else if (updatedContactPerson.mobile_number.length !== 10) {
        // Validate mobile number length
        errorMessages.push('Mobile number must be exactly 10 digits.');
      }
  
     // Validate email if provided
     if (updatedContactPerson.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(updatedContactPerson.email)) {
        errorMessages.push('Invalid email address.');
      }
    }

    if (errorMessages.length > 0) {
      setErrorMessages(errorMessages);
      return;
    }

    setIsSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const url = process.env.REACT_APP_FORE_BRICKBUCKET + "vendor/update-vendor-contact";
      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'x-token': token },
        body: JSON.stringify(updatedContactPerson),
      });

      const data = await response.json();
      if (data.status === 201 || data.status === 200 || data.status === 204) {
        setIsLoading(false);
        setIsSaving(false);
        setPopupMessage(data.message);
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        // Update successful, close the popup and update the CustomerContactPersons state
        const index = VendorContactPersons.findIndex((contactPerson) => contactPerson._id === selectedContactPerson._id);
        if (index !== -1) {
          const updatedVendorContactPersons = [...VendorContactPersons];
          updatedVendorContactPersons[index] = updatedContactPerson;
          setVendorContactPersons(updatedVendorContactPersons);
        }
        setShowEditContactPersonPopup(false);
      } else {
        setIsLoading(false);
        setIsSaving(false);
        setShowEditContactPersonPopup(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
        if (data.status === 500) {
          setRetryFunction(() => handleUpdateContactPerson);
        }
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleActivateClick(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setIsSaving(false);
        setShowEditContactPersonPopup(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        setRetryFunction(() => () => handleActivateClick(attempt)); // Set retry function
      }
    }
    finally{
      setErrorMessages([]);
    }
  }
};

  const handleCreateContactPerson = async (attempt = 1) => {
    // Save new contact person
    const newContactPerson = {
      vendor_id: vendorid, // Assuming you have a customerId state or prop
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      mobile_number: document.getElementById('mobile_number').value,
      designation: document.getElementById('designation').value,
    };
  
    const errorMessages = [];
    if (!newContactPerson.name) {
      errorMessages.push('Name is required.');
    }
      // Validate mobile number required
      if (!newContactPerson.mobile_number) {
        errorMessages.push('Mobile number is required.');
      } else if (newContactPerson.mobile_number.length !== 10) {
        // Validate mobile number length
        errorMessages.push('Mobile number must be exactly 10 digits.');
      }
     // Validate email if provided
     if (newContactPerson.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(newContactPerson.email)) {
        errorMessages.push('Invalid email address.');
      }
    } 
    if (errorMessages.length > 0) {
      setContactPersonErrors(errorMessages);
      return;
    }
  
    setIsSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const url = process.env.REACT_APP_FORE_BRICKBUCKET + "vendor/create-vendor-contact";
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-token': token },
        body: JSON.stringify(newContactPerson),
      });
      const data = await response.json();
      if (data.status === 201 || data.status === 200 || data.status === 204){      
        setIsLoading(false);
        setIsSaving(false);
        setPopupMessage(data.message);
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        // Save successful, close the popup and update the CustomerContactPersons state
        setShowAddContactPersonPopup(false);
        const newVendorContactPersons = [...VendorContactPersons, data.data];
        setVendorContactPersons(newVendorContactPersons);
      } else {
        setIsLoading(false);
        setIsSaving(false);
        setShowAddContactPersonPopup(false);
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
        if (data.status === 500) {
          setRetryFunction(() => handleCreateContactPerson);
        }
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleActivateClick(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setIsSaving(false);
        setShowEditContactPersonPopup(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        setRetryFunction(() => () => handleCreateContactPerson(attempt)); // Set retry function
      }
    }
    finally{
      setErrorMessages([]);
    }
  };

const handleCreateContactPersonInputfieldChange = (e) => {
  const { id, value } = e.target;
   
  if (id === 'mobile_number') {
    // Check for numbers only
    if (!/^[0-9]*$/.test(value)) {
      return;
    }
    
    // Limit to 10 digits
    if (value.length > 10) {
      return;
    }

    // Validate first digit
    if (value.length === 1 && !['6','7','8','9'].includes(value)) {
      setErrorMessages(['Mobile number must start with 6, 7, 8 or 9']);
      return;
    }

    // Validate length
    if (value.length < 10 && value.length > 0) {
      setErrorMessages(['Enter 10 digits mobile number']);
    } else {
      setErrorMessages([]);
    }
  }

  
  setNewContactPerson((prevNewContactPerson) => ({ ...prevNewContactPerson, [id]: value }));
};

const handleUpdateContactPersonInputfieldChange = (e) => {
  const { id, value } = e.target;
  setSelectedContactPerson((prevSelectedContactPerson) => ({ ...prevSelectedContactPerson, [id]: value }));
};

const handleDropdownChange = (value) => {
  setSalutation(value);
};



const SalutationOptions = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Miss.', value: 'Miss.' },
  { label: 'Dr.', value: 'Dr.' },
];

const [isPanEditing, setIsPanEditing] = useState(false);
const [panValue, setPanValue] = useState();
const [isGSTEditing, setIsGSTEditing] = useState(false);
const [GSTValue, setGSTValue] = useState();
const [address, setAddress]=useState()
  const [city, setCity]=useState()
  const [isAddressEditing, SetisAddressEditing] = useState (false)

const handleEditPanClick = () => {
  setIsPanEditing(true);
  setIsGSTEditing(false)
  setPanValue(selectedVendor.pan)
};
const handleEditGSTClick = () => {
  setIsGSTEditing(true);
  setIsPanEditing(false);
  setGSTValue(selectedVendor.gstin)
};

const handleEditAddress = () =>{
  SetisAddressEditing(true)
  setIsPanEditing(false);
  setIsGSTEditing(false)
  setAddress(selectedVendor.address)
  setCity(selectedVendor.city)
}
 

const handleCancelClick = () => {
  setIsPanEditing(false);
  setIsGSTEditing(false);
  SetisAddressEditing (false)
};

const handleAssignProductCategoryPopup = () => {
  fetchProductCategories();
  fetchProductCategoriesOfSelectedVendor(selectedVendor._id);
  setShowAssignProductCategoryPopup(true);
}

const handleDeleteContactPerson = (contactPerson) => {
  setSelectedContactPerson(contactPerson);
  setShowDeleteContactPersonPopup(true);
}

const handleConfirmDelete = async (attempt = 1) => {
  const token = sessionStorage.getItem('token');
  const vendorContactId = selectedContactPerson._id;
  setIsSaving(true);
  try {
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `vendor/delete-vendor-contact?vendor_contacts_id=${vendorContactId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        "x-token": token
      }
    });
    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      setIsLoading(false);
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType('204');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      // Update successful, close the popup and update the CustomerContactPersons state
      const index = VendorContactPersons.findIndex((contactPerson) => contactPerson._id === vendorContactId);
      if (index !== -1) {
        const updatedVendorContactPersons = [...VendorContactPersons];
        updatedVendorContactPersons.splice(index, 1); // Remove the deleted contact person
        setVendorContactPersons(updatedVendorContactPersons);
      }
      setShowDeleteContactPersonPopup(false);
    } else {
      setIsLoading(false);
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
      if (data.status === 500) {
        setRetryFunction(() => handleConfirmDelete);
      }
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      setShowDeleteContactPersonPopup(false);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleConfirmDelete(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setIsSaving(false);
      setShowEditContactPersonPopup(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      setRetryFunction(() => () => handleConfirmDelete(attempt)); // Set retry function
    }
  }
}

const fetchVendorPurchaseOrders = async (vendorId, attempt = 1) => {
  console.log("Fetching purchase orders for vendorId:", vendorId);


  try {
    const token = sessionStorage.getItem('token');
    const getPurchaseOrdersUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}vendor/get-vendor-purchaseorders?vendor_id=${vendorId}`;
console.log(getPurchaseOrdersUrl)
    const response = await fetch(getPurchaseOrdersUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });

    const data = await response.json();

    if (response.ok) {
      if (data.status === 200 || data.status === 201 || data.status === 204) {
       setPurchaseOrders(data.data || []);
        console.log(data.data);
      } else if (data.status === 404) {
        setPurchaseOrders([]);
      }
      setIsLoading(false);
    } else {
      if (data.status === 500) {
        setPopupMessage(data.message || 'Internal Server Error');
        setPopupType('500');
      } else {
        setPopupMessage(data.message || `Error: ${response.status}`);
        setPopupType(`${data.status || response.status}`);
      }
      setRetryFunction(() => () => fetchVendorPurchaseOrders(vendorId, attempt));
      setShowPopup(true);
      setIsLoading(false);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => fetchVendorPurchaseOrders(vendorId, attempt + 1), 1000);
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchVendorPurchaseOrders(vendorId, attempt));
    }
  }
};




const handleCreateVendor = () => {
  navigate('/bb/app/purchases/newvendor'); 
};

const HandleClose = () => {
  navigate('/bb/app/purchases/vendorslist'); 
};

const handleCloseError = () => {
  setErrorMessages([]);
};

const handleEdit = () => {
  navigate('/bb/app/purchases/EditVendor',  {state:{VendorData:selectedVendor}});
  console.log(VendorData);
}
const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const handlenavigateToPurchaseOrderDetails = (po) => {
  navigate("/bb/app/purchases/purchaseorderdetails", {state:{PurchaseOrderData:po}});
  console.log(po);
}

if (isLoading) {
  return <Loader />;
}

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
{/* List of Vendors Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Vendors
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button 
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"  data-tooltip="Create Vendor" title=""  style={{ '--tooltip-bg': 'black' }}
  onClick={handleCreateVendor}>
  <FontAwesomeIcon icon={faPlus}  className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className=" flex justify-center  overflow-y-hidden  flex-col ">
   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
   {vendors.map((vendor) => (
        <div
          key={vendor.id}
          onClick={() => handleVendorClick(vendor)}
          className={`border-b text-sm flex items-center px-4 py-2 ${vendorid === vendor._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        >
          <div className='flex flex-row'>
            <div>
              <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
            </div>
            <div className='flex flex-col'>
              <p className='text-md font-semibold' >{vendor.name}</p>
              <p className="text-gray-600 text-sm">{vendor.company_name}</p>
            </div>
          </div>
        </div>
      ))}

  </div>

</div>
</div>
{/* List of Vendors Div end */}

<div className="border-l  w-full flex flex-col ">


<div className="border-b h-24"> {/* or h-12, depending on your desired height */}
  <div className='p-4 flex justify-between items-center '>
    <span className="text-md font-semibold">{selectedVendor.name}</span>
    <div className="flex items-center px-2">
      <button className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md mr-2" onClick={handleEdit}>
        Edit
      </button>
      {/* <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2">
        <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer " />
      </button>
      <button className="bg-[#f7525a] text-sm text-white px-2 py-1 shadow-sm border rounded-md mr-2">
        New Transaction
      </button> */}
    <button onClick={handleAssignProductCategoryPopup}
    className="bg-gray-100 text-sm px-2 py-1 shadow-sm border rounded-md mr-2">
      Assign
    </button>
        <button className=" text-white px-1 py-1 rounded-md "
         title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>   
        <FontAwesomeIcon icon={faX} onClick={HandleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer " />
      </button>
    </div>
  </div>
</div>
  <div className=" flex h-5/6 w-full  overflow-y-auto  flex-row">
  <div className="bg-[#fbfbfb] w-[32%] border-r  p-4">
    <h2 className="text-sm pb-2 border-b">
    {selectedVendor.company_name}
    </h2>
    <div className='pt-4 pl-4 pr-4  flex flex-row'>
    <div className='flex-shrink-0'>
  <img 
    className="rounded-md w-10 h-10 cursor-pointer" 
    src={selectedVendor.image ? `${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${selectedVendor.image}` : '/ProfileImageSvg.jpg'} 
    alt="Vendor Logo"
  />
</div>

  <div className=' text-sm ml-3 flex flex-col'>
    <div className='font-semibold mb-0.5 break-words word-break'>{selectedVendor.name}</div>
    <div className='break-words word-break'>{selectedVendor.email}</div>
    <div className='break-words word-break'>
      <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
      {selectedVendor.mobile_number}
    </div>
    <div className='text-xs flex flex-row'>
      <button onClick={handleEditClick} className='text-[#408dfb] hover:text-blue-600'>Edit</button>
      <p className='text-gray-300 ml-2 mr-2 '>|</p>
      <button
      onClick={selectedVendor.is_active ? handleDeactivateClick : handleActivateClick}
      className='text-[#408dfb] hover:text-blue-600' >
      {selectedVendor.is_active ? 'Deactivate' : 'Activate'}
    </button>  </div>
  </div>
</div>

<div onClick={handleToggleAddress}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className=' text-sm cursor-pointer' onClick={handleToggleAddress}>ADDRESS</span>
  <button className=' text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleAddress}>
    {showAddress? <FontAwesomeIcon icon={faChevronUp} className='text-xs text-blue-800'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs text-blue-800'/>}
  </button>
</div>

<div className={`${showAddress ? '' : 'hidden'}`}>
  <div className='text-sm pt-2 pl-4 pr-4'>
    <div className='flex justify-between'><h1>Billing Address</h1>
    {!isAddressEditing&&
    <FontAwesomeIcon icon={faPen} className='text-xs'
    onClick={handleEditAddress}/> 
    }
    </div>

    <div className='flex flex-col'>
    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
<div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end'>
    {isAddressEditing ? (
      <div className='flex flex-col space-y-2'>
      <input 
        type="text" 
        value={address} 
        onChange={(e) => {setAddress(e.target.value); selectedVendor.address = e.target.value}} 
        className="border border-gray-300 w-30 rounded px-2 py-1"
      />
      <input 
        type="text" 
        value={city} 
        onChange={(e) => {setCity(e.target.value); selectedVendor.city = e.target.value}} 
        className="border border-gray-300 w-30 rounded px-2 py-1"
      />
       </div>
    ) : (
 <>
 <div className='flex flex-col space-y-2'>

  <p>{selectedVendor.address}</p>

  <p>{selectedVendor.city}</p>
  </div>
  </>
)}
  <div className='flex items-center'>
  {isSaving && (
    <Spinner/>
  )}
    {isAddressEditing &&(
      <>
        <FontAwesomeIcon 
          icon={faSave} 
          className='mr-2 text-xs ml-2 cursor-pointer' 
          onClick={handleUpdateVendor} 
        />
        <FontAwesomeIcon 
          icon={faTimes} 
          className='mr-2 text-xs ml-2 cursor-pointer' 
          onClick={handleCancelClick} 
        />
      </>
    )}
  </div>
  </div>
</div>
    </div>
  </div>
</div>

<div onClick={handleToggleOtherDetails}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className='text-sm cursor-pointer' onClick={handleToggleOtherDetails}>TAX DETAILS</span>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleOtherDetails}>
    {showOtherDetails? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
</div>

<div className={`${showOtherDetails? '' : 'hidden'} mt-2 mb-2 flex flex-col `}>

  <div className='flex flex-row pb-2 '>

    <div className='text-[#212529] text-xs pt-2 pl-2 pr-2 w-32'>

      <h1>GSTIN</h1>

    </div>

    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>

    <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end'>
        {isGSTEditing ? (
          <>
          <input 
            type="text" 
            value={GSTValue} 
            onChange={(e) => {setGSTValue(e.target.value); selectedVendor.gstin = e.target.value;
              if (e.target.value && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(e.target.value)) {
                setErrorMessages(['Invalid GSTIN']);
              } else {
                setErrorMessages([]);
              }
          
            }} 
            className="border border-gray-300 w-24 rounded px-2 py-1"
          />
          {errorMessages.length > 0 && (
            <span className="text-red-500 text-xs mt-1">{errorMessages[0]}</span>
          )}
          </>
        ) : (

      <p>{selectedVendor && selectedVendor.gstin ? selectedVendor.gstin:''}</p>
    )}
      </div>
  
      <div className='flex items-center'>
      {isSaving && (
    <Spinner/>
  )}
        {isGSTEditing ? (
          <>
            <FontAwesomeIcon 
              icon={faSave} 
              className='mr-2 text-xs ml-2 cursor-pointer' 
              onClick={handleUpdateVendor} 
            />
            <FontAwesomeIcon 
              icon={faTimes} 
              className='mr-2 text-xs ml-2 cursor-pointer' 
              onClick={handleCancelClick} 
            />
          </>
        ) : (
          <FontAwesomeIcon 
            icon={faPen} 
            className='mr-2 text-xs ml-2 cursor-pointer' 
            onClick={handleEditGSTClick} 
          />
        )}
      </div>
  </div>
  </div>
  <div className='flex flex-row pb-2 '>
    <div className='text-[#212529] text-xs pt-2 pl-2 pr-2 w-32'>
      <h1>PAN</h1>
    </div>
    <div className=' flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
      <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex justify-end '>
        {isPanEditing ? (
          <>
          <input 
            type="text" 
            value={panValue} 
            onChange={(e) => {setPanValue(e.target.value); selectedVendor.pan = e.target.value;
              if (e.target.value && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(e.target.value)) {
                setErrorMessages(['Invalid PAN']);
              } else {
                setErrorMessages([]);
              }
            }} 
            className="border border-gray-300 w-24 rounded px-2 py-1"
          />
          {errorMessages.length > 0 && (
            <span className="text-red-500 text-xs mt-1">{errorMessages[0]}</span>
          )}
          </>
        ) : (
          <p>{selectedVendor&& selectedVendor.pan ? selectedVendor.pan:''}</p>
        )}
      </div>
      <div className='flex items-center'>
      {isSaving && (
    <Spinner/>
  )}
        {isPanEditing ? (
          <>
            <FontAwesomeIcon 
              icon={faSave} 
              className='mr-2 text-xs ml-2 cursor-pointer' 
              onClick={handleUpdateVendor} 
            />
            <FontAwesomeIcon 
              icon={faTimes} 
              className='mr-2 text-xs ml-2 cursor-pointer' 
              onClick={handleCancelClick} 
            />
          </>
        ) : (
          <FontAwesomeIcon 
            icon={faPen} 
            className='mr-2 text-xs ml-2 cursor-pointer' 
            onClick={handleEditPanClick} 
          />
        )}
      </div>
    </div>
  </div>
</div>

<div onClick={handleToggleContactPersons}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <div>
  <span className='text-sm cursor-pointer' onClick={handleToggleContactPersons}>CONTACT PERSONS</span>
  </div>
  <div>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleContactPersons}>
    <FontAwesomeIcon icon={faPlusCircle} onClick={handlePlusIconClick} className='text-blue-500 text-md'/>
  </button>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleContactPersons}>
    {showContactPersons? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
  </div>
</div>
<div className={`${showContactPersons? '' : 'hidden'}`}>
  {Array.isArray(VendorContactPersons) && VendorContactPersons.length > 0 ? (
    VendorContactPersons.map((contactPerson) => (
      <div key={contactPerson._id} className='pt-4 pl-4 pr-4 flex flex-row'>
        <div className='w-[90%] flex flex-row'>
          <div className='w-2/5'>
            <img className=" rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
          </div>
          <div className='w-4/5 text-sm ml-3 flex flex-col'>
            <div className='font-semibold mb-0.5 break-words word-break'>{contactPerson.name}</div>
            <div className='break-words word-break'>{contactPerson.email}</div>
            <div className='flex items-center'>
              <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
              <span className='break-words word-break'>{contactPerson.mobile_number}</span>
            </div>
            <div className='mb-0.5 break-words word-break'>{contactPerson.designation}</div>
          </div>
        </div>
        <div className='w-[5%]'></div>
        <div className=' w-[10%] flex justify-end'>
          <FontAwesomeIcon icon={faPen} onClick={() => handleEditContactPerson(contactPerson)} className='cursor-pointer hover:text-gray-500 text-gray-300 text-xs'/>
          <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteContactPerson(contactPerson)} className='cursor-pointer hover:text-gray-500 text-gray-300 text-xs ml-1'/>
        </div>
      </div>
    ))
  ) : (
    <div className='flex justify-center text-sm'>No contact persons found</div>
  )}
</div>
<div onClick={handleToggleBankAccountDetails}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <div>
  <span className='text-sm cursor-pointer' onClick={handleToggleBankAccountDetails}>BANK ACCOUNT DETAILS</span>
  </div>
  <div>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleBankAccountDetails}>
   {/*  <FontAwesomeIcon icon={faPlusCircle} onClick={handleBankPlusIconClick} className='text-blue-500 text-md'/> */}
  </button>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleBankAccountDetails}>
    {ShowBankAccountDetails? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
  </div>
</div>
<div className={`${ShowBankAccountDetails ? '' : 'hidden'}`}>
  {selectedVendor.bank_name || selectedVendor.bank_branch || selectedVendor.bank_IFSC || selectedVendor.account_number || selectedVendor.account_holder_name ? (
    <div className='pt-4 pl-4 pr-4 flex flex-col'>
      <div className='flex items-center mb-4'>
        <div className='bg-blue-100 flex justify-center items-center p-2 rounded-md'>
          <FontAwesomeIcon icon={faBank} className='text-blue-500 text-md' />
        </div>
        <div className='flex flex-col ml-4'>
          <div className='font-semibold text-sm'>{selectedVendor.bank_name}</div>
          <div className='text-xs text-gray-600'>{selectedVendor.account_number}</div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='flex mb-1 space-x-2'>
          <span className='w-20 text-xs font-normal text-gray-500'>IFSC Code</span>
          <span className='text-xs'>: {selectedVendor.bank_IFSC}</span>
        </div>
        <div className='flex mt-2 space-x-2'>
          <span className='w-20 text-xs font-normal text-gray-500'>Account Holder Name</span>
          <span className='text-xs'>: {selectedVendor.account_holder_name}</span>
        </div>
      </div>
    </div>
  ) : (
    <div className='flex justify-center text-sm'>No bank account details added yet</div>
  )}
</div>


  </div>
  <div className="w-[68%]  pl-2">
  <div className="w-full  ">
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center">
      {PurchaseOrders && PurchaseOrders.length > 0 ? (
          <div className="overflow-x-auto w-full container mx-auto px-4">
            <div className="p-2 flex justify-between items-center">
              <h1 className="text-md font-semibold">Purchase Orders</h1>
            </div>
            <table class="border-t border-b w-full">
              <thead>
                <tr className="text-[#212529] bg-[#fafafc]">                    
                  <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
                    PO Number
                  </th>
                  <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
                    Date
                  </th>
                  <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
                    Status
                  </th>
                  <th className="font-semibold text-xs py-2 px-4 border-b cursor-pointer" >
                    Item category
                  </th>
                </tr>
              </thead>
              <tbody>
                {PurchaseOrders.map((po) => (
                  <tr key={po._id} className="text-xs  border-b">
                    <td className="py-2 text-center px-4 text-[#408dfb] font-500 cursor-pointer" onClick={() => handlenavigateToPurchaseOrderDetails(po)}>
                      {po?.purchaseorder_number}
                    </td>
                    <td className="py-2 text-center px-4">
                      {new Date(po?.purchaseorder_date).toLocaleDateString()}
                    </td>
                    <td className="py-2 text-center px-4">
                      {po?.purchaseorder_status}
                    </td>
                    <td className="py-2 text-center px-4">
                     {po?.product_category_id?.product_category_name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-gray-600">There are no Purchase Orders for this {selectedVendor?.name}.</p>
        )}
      </div>
    </div>
  </div>
</div>
</div>
</div>
                   

 {/* Popup starts */}

 {showVendorDetailsPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div className="bg-white rounded-b-md shadow-md w-[550px] h-[300px] flex flex-col animate-slide-in" style={{ maxWidth: '550px', maxHeight: '300px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
      <h2 className="text-md">Edit Vendor Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    {vendorEditErrors.length > 0 && (
        <div className="flex justify-between items-center mt-1 mb-1 px-auto py-1 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col justify-start items-start">
            {vendorEditErrors.map((errorMessage, index) => (
              <span key={index} className="text-sm">• {errorMessage}</span>
            ))}
          </div>
          <div className='px-4'>
            <button className="text-gray-700 hover:text-red-500" onClick={() => setVendorEditErrors([])}>
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}
    <div className='p-20px mt-4 '>
    <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Name
    </label>
    <input
      type="name"
      id="name"
      value={selectedVendor.name }
      onChange={handleVendorInputfieldChange}
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Enter Full Name"/>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Email Address
    </label>
    <input
      type="email"
      value={selectedVendor.email}
      onChange={handleVendorInputfieldChange}
      id="email"
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Email Address"
    />
  </div>
  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Contact Info 
    </label>
    <input
      type="text"
      id="mobile_number"
      value={selectedVendor.mobile_number}
      onChange={handleVendorInputfieldChange}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Mobile"
      pattern="\d{10}" 
            maxLength="10"
    />
  </div>    
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button onClick={handleUpdateVendor}
        className="px-2 py-1 bg-[#f7525a]  border-[#f7525a] text-white rounded-md space-x-2 flex items-center">
  <span>Save</span>
  {isSaving && (<Spinner/>)}
</button>
        <button onClick={handleClosePopup} 
         className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{showAddContactPersonPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div className="bg-white rounded-b-md shadow-md w-[550px] h-[400px] flex flex-col animate-slide-in" style={{ maxWidth: '550px', maxHeight: '400px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md">
    Add Contact Person
    </h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px '>
    {contactPersonErrors.length > 0 && (
        <div className="flex justify-between items-center mt-1 px-auto py-1 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col justify-start items-start">
            {contactPersonErrors.map((errorMessage, index) => (
              <span key={index} className="text-sm">• {errorMessage}</span>
            ))}
          </div>
          <div className='px-4'>
            <button className="text-gray-700 hover:text-red-500" onClick={() => setContactPersonErrors([])}>
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}  
      <div className="w-full flex flex-row items-center mb-4 space-x-6 mt-5 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Name
    </label>
    <div className='w-5/6 flex justify-between'>
      <input
        type="text"
        id="name"
        value={newContactPerson.name}
        placeholder="Name"
        onChange={handleCreateContactPersonInputfieldChange }
        className="w-full py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"/>
    </div>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Email Address
    </label>
    <input
      type="email"
      id="email"
      value={newContactPerson.email}
      onChange={handleCreateContactPersonInputfieldChange }
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Email Address"    />
  </div> 
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Contact Info 
    </label>
    <input
      type="text"
      id="mobile_number"
      onChange={handleCreateContactPersonInputfieldChange }
      value={newContactPerson.mobile_number}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Mobile"
      pattern="\d{10}" 
            maxLength="10"
    />
  </div>  
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Other_details" className="block text-xs font-regular text-[#212529] w-1/6">
      Other Details 
    </label>
    <input
      type="text"
      id="designation"
      onChange={handleCreateContactPersonInputfieldChange }
      value={newContactPerson.designation}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Designation" />
  </div>  
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button className="px-2 py-1 bg-[#f7525a]  border-[#f7525a] text-white rounded-md space-x-2 flex items-center" onClick={handleCreateContactPerson}>
<span>Save</span>   {isSaving && (<Spinner/>)}
</button>
        <button onClick={handleClosePopup} 
         className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{ShowEditContactPersonPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div className="bg-white rounded-b-md shadow-md w-[550px] h-[400px] flex flex-col animate-slide-in" style={{ maxWidth: '550px', maxHeight: '400px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md">
    Edit Contact Person
    </h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px '>
    {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )}
  <div className="w-full flex flex-row items-center mb-4 space-x-6 mt-5 px-6">
    <label htmlFor="name" className="block text-xs font-regular text-[#212529] w-1/6">
      Name
    </label>
    <div className='w-5/6 flex justify-between'>
      <input
        type="text"
        id="name"
        value={selectedContactPerson ? selectedContactPerson.name : ''}
        placeholder="Name"
        onChange={handleUpdateContactPersonInputfieldChange}
        className="w-full py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"/>
    </div>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="email" className="block text-xs font-regular text-[#212529] w-1/6">
      Email Address
    </label>
    <input
      type="email"
      id="email"
      value={selectedContactPerson ? selectedContactPerson.email : ''}
      onChange={handleUpdateContactPersonInputfieldChange}
      className="w-5/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Email Address"    />
  </div> 
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Contact_info" className="block text-xs font-regular text-[#212529] w-1/6">
      Contact Info 
    </label>
    <input
      type="text"
      id="mobile_number"
      onChange={handleUpdateContactPersonInputfieldChange}
      value={selectedContactPerson ? selectedContactPerson.mobile_number : ''}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Mobile"
      pattern="\d{10}" 
            maxLength="10"
    />
  </div>  
  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="Other_details" className="block text-xs font-regular text-[#212529] w-1/6">
      Other Details 
    </label>
    <input
      type="text"
      id="designation"
      onChange={handleUpdateContactPersonInputfieldChange}
      value={selectedContactPerson ? selectedContactPerson.designation : ''}
      className="w-2/6 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      placeholder="Designation" />
  </div>  
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button className="px-2 py-1 bg-[#f7525a]  border-[#f7525a] text-white rounded-md space-x-2 flex items-center" onClick={handleUpdateContactPerson}>
<span>Update</span>
{isSaving && (<Spinner/>)}
</button>
        <button onClick={handleClosePopup} 
         className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}



{showAssignProductCategoryPopup && (
  <div className="fixed inset-0 flex justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-md shadow-md w-[550px] h-[400px] flex flex-col animate-slide-in" style={{ maxWidth: '550px', maxHeight: '400px' }}>
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6 rounded-t-md'>
        <h2 className="text-md ">Assign Product Category</h2>
        <button onClick={handleClosePopup} className="text-[#212529] hover:text-red-500">
          <FontAwesomeIcon icon={faX} className="text-md cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='flex flex-col p-4 space-y-4'>
        <div className='flex items-center'>
          <h2 className="text-md font-medium">Vendor Name - {selectedVendor.name}</h2>
        </div>
        <p className="text-sm text-gray-600">Select the categories to assign :</p>
        <div className="grid grid-cols-4 gap-4 pl-6">
  {categories.map((category) => (
    <div key={category._id} className="flex items-center space-x-2">
      <input
        type="checkbox"
        id={category._id}
        value={category._id}
        checked={vendorCategories.includes(category._id) || false}
        onChange={(e) => handleCategoryChange(category._id)}
        className="form-checkbox"
      />
      <label htmlFor={category._id} className="text-gray-700">{category.product_category_name}</label>
    </div>
  ))}
</div>
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start space-x-2 px-6 mt-4 mb-4">
          <button onClick={assignProductCategoriesToSelectedVendor} className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md flex items-center space-x-2">
            <span>Save</span>
            {isSaving && (<Spinner />)}
          </button>
          <button onClick={handleClosePopup} className={`px-2 py-1 rounded-md ${isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] hover:bg-gray-100'}`} disabled={isSaving}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}

{ShowDeleteContactPersonPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div className="bg-white rounded-b-md shadow-md w-[550px] h-[150px] flex flex-col animate-slide-in" style={{ maxWidth: '550px', maxHeight: '150px' }}>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='pt-4 pl-4 '>
  <div className="w-full items-center p-5">
    <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
    Do you want to delete the contact person?
    </label>
  </div>  
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
      <button className="px-2 py-1 bg-[#f7525a]  text-white rounded-md space-x-2 flex items-center" onClick={handleConfirmDelete}>
<span>Delete</span>  {isSaving && (
    <Spinner/>
  )}
</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* Popup ends */}

    </div>
  );
};
export default ViewVendor;