import React, { useState, useEffect, useCallback, useRef } from 'react';
// import Geolocation from '@react-native-community/geolocation';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaChevronUp, FaChevronDown, FaTimes} from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { faBuilding, faTimes, faHome, faHeadset, faArrowRight, faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft, 
  faTag, 
  faListAlt, 
  faChevronRight,
  faPhone,
  faInfoCircle,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from 'react-dom/server';
import { BiRefresh } from 'react-icons/bi';
import LoaderModal from '../BBAdmin/Components/Loader';




const TilesQuote = () => {  
  
  const location = useLocation();
  const { 
    projectId,  
    packageId,
    customerId, 
    token, 
    organizationId,  
    packages  
  } = location.state;

 
  const searchParams = new URLSearchParams(location.search);
  console.log('Project ID:', projectId);

   const fetchUrl = process.env.REACT_APP_FORE_APILINK;
   const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
  const urlToken = searchParams.get('token');
  


  const [projectData, setProjectData] = useState({
    projectId: '',
    projectName: '',
    rooms: []
  });
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [uoms, setUoms] = useState([]);
  const [isTilesLoading, setIsTilesLoading] = useState(true);
  const [flats, setFlats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCallbackModalVisible, setIsCallbackModalVisible] = useState(false);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [selectedRoomDetails, setSelectedRoomDetails] = useState(null);
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState([]);
  const [categoryId, setCategoryId] = useState(location.state?.categoryId || '');

const [isProductModalOpen, setIsProductModalOpen] = useState(false);
const [selectedCategory, setSelectedCategory] = useState(null);
const [coordinates, setCoordinates] = useState({
  latitude: 16.51898484861941,
  longitude: 80.67474377952813,
  latitudeDelta: 0.002,
  longitudeDelta: 0.002,
});
const [selectedProductId, setSelectedProductId] = useState(null);
const [selectedProducts, setSelectedProducts] = useState({});
const [isProductModalVisible, setIsProductModalVisible] = useState(false);
const [availableProducts, setAvailableProducts] = useState([]);

const [attributes, setAttributes] = useState([]);
const [categories, setCategories] = useState([]);
const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
const [pendingPackage, setPendingPackage] = useState(null);
const [isPackageChangeModalVisible, setIsPackageChangeModalVisible] = useState(false);
const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
const [selectedDetails, setSelectedDetails] = useState(null);
const [expandedCategories, setExpandedCategories] = useState({});
const [isSavingQuote, setIsSavingQuote] = useState(false);
const [showNoChangesModal, setShowNoChangesModal] = useState(false);
const [currentSelectedProduct, setCurrentSelectedProduct] = useState(null);

const BRICKBUCKETIMAGE = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
const [activePackage, setActivePackage] = useState('economy');
const [showTermsModal, setShowTermsModal] = useState(false);

const [expandedPackages, setExpandedPackages] = useState({
  1: true,
});  

const modalRef = useRef(null);
const [isDragging, setIsDragging] = useState(false);
const [dragPosition, setDragPosition] = useState(0);
const dragThreshold = 100; // Pixels to drag before closing
const [isPriceLoading, setIsPriceLoading] = useState(false);
const [isRefreshing, setIsRefreshing] = useState(false);
const [selectedPackage, setSelectedPackage] = useState(null);
const [isPackageSwitching, setIsPackageSwitching] = useState(false);
const [loadingCategories, setLoadingCategories] = useState({});
const [isQuotationSaving, setIsQuotationSaving] = useState(false);
const [isDesignsModalVisible, setIsDesignsModalVisible] = useState(false);
const [selectedProduct, setSelectedProduct] = useState(null);
const [selectedImageUrl, setSelectedImageUrl] = useState(null);
const [selectedDesign, setSelectedDesign] = useState(null);
const [quotationId, setQuotationId] = useState(null);
const [showDesignConfirmation, setShowDesignConfirmation] = useState(false);
const [selectedRoomCategory, setSelectedRoomCategory] = useState('');
const [RoomCategoryIndex, setRoomCategoryIndex ] = useState('')


useEffect(() => {  
  window.scrollTo(0, 0);
}, []);

 useEffect(() => {
      const handleBeforeUnload = () => {
        if (!isPackageSwitching) {
        setIsRefreshing(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    };
    
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isPackageSwitching]);
    


useEffect(() => {
  if (location.state?.categoryId) {
    setCategoryId(location.state.categoryId);
  }
}, [location.state]);

useEffect(() => {
  const loadInitialData = async () => {
   
    try {
      // Load measurements first
     
     await fetchProjectData();
      await fetchTilesData();
      await fetchAttributesByproductCategory();
    } catch (error) {
     console.log('Error loading initial data:', error);
    }
  };
  setIsTilesLoading(false);
  loadInitialData();
}, [projectId, categoryId]);

useEffect(() => {
  if (categories?.data?.total_price) {
    setTotalPrice(categories.data.total_price);
  }
}, [categories?.data?.total_price]);


  useEffect(() => {
      if (isViewDetailsModalOpen || isProductModalVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [isViewDetailsModalOpen, isProductModalVisible]);
    

const calculatePricePerSqft = (product) => {
  // Find the Sq Ft per Box attribute
  const sqFtPerBoxAttribute = product.attributes?.find(
    attr => attr.attribute_name === "Sq Ft per Box"
  );
  
  // Get the sq ft value
  const sqFtPerBox = sqFtPerBoxAttribute ? parseFloat(sqFtPerBoxAttribute.attribute_value) : 1;
  
  // Calculate price per sqft
  const pricePerSqft = product.selling_price / sqFtPerBox;
  
  return pricePerSqft.toFixed(2); // Round to 2 decimal places
};




const fetchProjectData = async () => {
  console.log('Fetching projects...');
  try {
    const token = await sessionStorage.getItem('token');
    const organizationId = await sessionStorage.getItem('organizationId');
    
    // Make sure the URL is correctly formatted
    const response = await fetch(fetchUrl + `/measurements/get-flat-wise-tile-measurements-of-floor?project_id=${projectId}&organizationId=${organizationId}`, {
      method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'x-token': token
  }
});

console.log('Response status:', response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Fetched project data:', data);
    if (data) {
      setProjectData(data);
      const uniqueFlats = [...new Set(data.rooms.flatMap(room => 
        room.blocks.flatMap(block => 
          block.flats.map(flat => flat.flatNo)
        )
      ))].sort();
      console.log('Unique Flats:', uniqueFlats);
      setFlats(uniqueFlats);
    }
  } catch (error) {
    console.log('Error fetching project data:', error.message);
    // Handle the error appropriately - maybe set an error state
  }
};

// const handleChangeProduct = async (categoryName, currentProductId) => {
//   console.log('Current Product ID:', currentProductId); // Debug log
//   setSelectedCategory(categoryName);
  
//   // Get current product ID from the category's products
//   const currentProduct = categories?.data?.room_categories?.find(
//     cat => cat.room_category === categoryName
//   )?.products.find(prod => prod.default === true);
  
//   setCurrentSelectedProduct(currentProduct);
//   setSelectedProductId(currentProduct?._id || null);
//   setIsProductModalVisible(true);

//   try {
//     const token = await sessionStorage.getItem('token');
//     const organizationId = await sessionStorage.getItem('organizationId');
    
//     const response = await fetch(fetchUrl2 + `product-groups/get-productGroups-by-productCategory?product_category_id=${categoryId}&organizationId=${organizationId}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'x-token': token
//       }
//     });

//     const data = await response.json();

//     console.log('Fetched group data:', data);

//     if (data) {
//       setAvailableProducts(data.data);
//     }
//   } catch (error) {
//     console.log('Error fetching group data:', error.message);
//   }
// };


const fetchAttributesByproductCategory = async ()=>{
  try {
    const token = await sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl2}product-attributes/get-all-attributes-by-productCategory?product_category_id=${categoryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();

    console.log('Fetched attributes data:', data);

    if (data.success) {
      setAttributes(data.data);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);
  }
  };


// Update fetchTilesData to check for valid categoryId
const fetchTilesData = async () => {
  try {
    const token = await sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}/summary/package-details?product_category_id=${categoryId}&project_id=${projectId}&package_id=${packageId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    console.log('Tiles data:', data);
    setCategories(data); // Store the complete response
  } catch (error) {
    console.log('Error fetching tiles data:', error);
  }
};

  const handlePackageChange = (newPackage) => {
    if (hasUnsavedChanges) {
      setPendingPackage(newPackage);
      setIsPackageChangeModalVisible(true);
    } else {
      fetchTilesData( );
    }
  };
  const getUoms = async () => {
    try {
      const token = await sessionStorage.getItem('token');
      const getUomUrl = `${fetchUrl2}UOMs/get-all-UOMs`;
  
      const response = await fetch(getUomUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
     
      if (data.data) {
        setUoms(data.data);
      }
    } catch (error) {
     // console.error('Failed to fetch:', error);
  
    
    }
  };
  
  useEffect(() => {
    getUoms();
  }, []);
  
  const getUOMText = (uomId) => {
    const foundUom = uoms.find(uom => uom._id === uomId);
    return foundUom?.UOM ;
  };


const toggleCategory = (categoryId) => {
setExpandedCategories(prev => ({
  ...prev,
  [categoryId]: !prev[categoryId]
}));
};

const togglePackage = (packageId) => {
setExpandedPackages(prev => ({
  ...prev,
  [packageId]: !prev[packageId]
}));
};

const getSqFtPerBox = (productId) => {
const product = availableProducts.find(p => p._id === productId);
if (!product) return 1;

const sqFtPerBoxAttribute = attributes.find(attr => attr.attribute_name === "Sq Ft per Box");
const selectedSqFtValue = sqFtPerBoxAttribute?.attribute_values.find(
  val => product.attribute_value_ids.includes(val.attribute_value_id)
);

return selectedSqFtValue ? parseFloat(selectedSqFtValue.attribute_value) : 1;
};

const calculateTaxAmount = (amount, taxRate) => {
return amount * (taxRate || 0) / 100;
};

const handleViewDetails = (category) => {
setSelectedDetails(category);
setIsDetailsModalVisible(true);
};


const handleProductSelection = (selectedProduct) => {
  console.log('Selected Product:', selectedProduct);
  setIsPriceLoading(true); 
   setLoadingCategories(prev => ({...prev, [selectedCategory]: true}));


  setHasUnsavedChanges(true);
  setSelectedProducts(prev => ({
    ...prev,
    [selectedCategory]: selectedProduct._id
  }));

  // Get Sq Ft per Box directly from product attributes
  const sqFtPerBox = selectedProduct.attributes?.find(
    attr => attr.attribute_name === "Sq Ft per Box"
  )?.attribute_value || 1;

  const targetCategory = categories?.data?.room_categories?.find(cat => 
    cat.room_category === selectedCategory
  );

  if (targetCategory) {
    const updatedCategories = {
      ...categories,
      data: {
        ...categories.data,
        room_categories: categories.data.room_categories.map(cat => {
          if (cat.room_category === selectedCategory) {
            const totalBoxes = Math.ceil(cat.room_category_area / parseFloat(sqFtPerBox));
            const newPrice = totalBoxes * selectedProduct.selling_price;
            
            // Keep all products in the category while marking the selected one
            return {
              ...cat,
              products: cat.products.map(prod => ({
                ...prod,
                default: prod._id === selectedProduct._id,
                isModified: prod._id === selectedProduct._id
              })),
              room_category_price: newPrice
            };
          }
          return cat;
        })
      }
    };
    setTimeout(() => {
      const newTotal = updatedCategories.data.room_categories.reduce((sum, cat) => 
        sum + (cat.room_category_price || 0), 0
      );
      
      setTotalPrice(newTotal);
      setCategories(updatedCategories);
      setIsPriceLoading(false);
      setLoadingCategories(prev => ({...prev, [selectedCategory]: false}));
    }, 1000);
  }

  setIsProductModalVisible(false);
};

/* const handleDesignChange = (newDesign) => {
  const updatedCategories = {...categories};
  const roomCategory = updatedCategories.data.room_categories[RoomCategoryIndex];
  
  const productIndex = roomCategory.products.findIndex(
    product => product._id === selectedProduct._id
  );

  if (productIndex !== -1) {
    const product = roomCategory.products[productIndex];
    
    // Reset all design selections
    product.products.forEach(design => {
      design.selected = false;
    });

    // Set new design as selected
    const designIndex = product.products.findIndex(
      design => design._id === newDesign._id
    );

    if (designIndex !== -1) {
      product.products[designIndex].selected = true;
    }

    setCategories(updatedCategories);
    setSelectedProduct(product);
  }
};


const handleDesignSelection = async (product) => {
  // Find the parent category based on the current selectedCategory
  const parentCategory = categories?.data?.room_categories?.find(cat => 
    cat.room_category === selectedCategory
  );

  // Find the specific product that contains this design
  const parentProduct = parentCategory?.products?.find(p => 
    p.products?.some(design => design._id === product._id)
  );

  console.log('Starting Design Update:', {
    quotationId: categories?.data?._id,
    roomCategory: selectedCategory,
    parentProductId: parentProduct?._id,
    newDesignId: product._id
  });

  if (categories?.data?._id && parentProduct) {
    const result = await updateSelectedBaseProduct(
      categories.data._id,
      selectedCategory,
      parentProduct._id,
      product._id
    );

    if (result) {
      setSelectedDesign(product._id);
    
    }
  }
};

const updateSelectedBaseProduct = async (quotationId, roomCategory, productId, baseProductId) => {
  console.log('Starting API Call:', {
    quotationId, roomCategory, productId, baseProductId 
  });

  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/summary/update-selected-product`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify({
        quotationId,
        room_category: roomCategory,
        product_id: productId,
        base_product_id: baseProductId
      })
    });

    const data = await response.json();  
    console.log('API Response:', data);

    if (data.success) {
      setSelectedRoomCategory(roomCategory);
      setIsDesignsModalVisible(false);
      setShowDesignConfirmation(true);
      
      setTimeout(() => {
        setShowDesignConfirmation(false);
      }, 2000);
      
      return data.data;
    }
    return null;

  } catch (error) {
    console.log('API Call Details:', {
      url: `${process.env.REACT_APP_FORE_APILINK}/summary/update-selected-product`,
      error: error.message
    });
    return null;
  }
}; */


const handleDesignChange = (newDesign) => {
  const updatedCategories = {...categories};
  const roomCategoryIndex = updatedCategories.data.room_categories.findIndex(
    cat => cat.room_category === selectedCategory
  );
  const roomCategory = updatedCategories.data.room_categories[roomCategoryIndex];
  
  const productIndex = roomCategory.products.findIndex(
    product => product._id === selectedProduct._id
  );

  if (productIndex !== -1) {
    const product = roomCategory.products[productIndex];
    product.products.forEach(design => {
      design.selected = false;
    });

    const designIndex = product.products.findIndex(
      design => design._id === newDesign._id
    );

    if (designIndex !== -1) {
      product.products[designIndex].selected = true;
      setCategories(updatedCategories);
      setSelectedProduct(product);
    }
  }
};

const handleDesignSelection = async (product) => {
  const parentCategory = categories?.data?.room_categories?.find(cat =>
    cat.room_category === selectedCategory
  );

  const parentProduct = parentCategory?.products?.find(p =>
    p.products?.some(design => design._id === product._id)
  );

  if (categories?.data?._id && parentProduct) {
    const result = await updateSelectedBaseProduct(
      categories.data._id,
      selectedCategory,
      parentProduct._id,
      product._id
    );

    if (result) {
      handleDesignChange(product);
      setSelectedDesign(product._id);
    }
  }
};

const updateSelectedBaseProduct = async (quotationId, roomCategory, productId, baseProductId) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/summary/update-selected-product`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify({
        quotationId,
        room_category: roomCategory,
        product_id: productId,
        base_product_id: baseProductId
      })
    });

    const data = await response.json();

    if (data.success) {
      setSelectedRoomCategory(roomCategory);
      setIsDesignsModalVisible(false);
      setShowDesignConfirmation(true);
      
      setTimeout(() => {
        setShowDesignConfirmation(false);
      }, 2000);
      
      return data.data;
    }
    return null;

  } catch (error) {
    console.log('API Call Details:', {
      url: `${process.env.REACT_APP_FORE_APILINK}/summary/update-selected-product`,
      error: error.message
    });
    return null;
  }
};




const handleSaveQuote = async () => {
  setIsQuotationSaving(true); 

  try {
    const token = await sessionStorage.getItem('token');
    const organizationId = await sessionStorage.getItem('organizationId');
    const customerId = await sessionStorage.getItem('customerId');

    const hasModifiedProducts = categories?.data?.room_categories?.some(cat => 
      cat.products.some(prod => prod.isModified || prod.default)
    );

    if (!hasModifiedProducts) {
      setShowNoChangesModal(true);
      return;
    }

    const itemsData = categories?.data?.room_categories?.map(category => {
      // Get the current selected product
      const currentProduct = category.products.find(prod => 
        prod.default === true || prod.isModified === true
      );
      
      // Get sqFtPerBox directly from product attributes
      const sqFtPerBox = currentProduct.attributes?.find(
        attr => attr.attribute_name === "Sq Ft per Box"
      )?.attribute_value || 1;

      const totalBoxes = Math.ceil(category.room_category_area / parseFloat(sqFtPerBox));
      const quantity_breakup = category.rooms
        .filter(room => room.area > 0)
        .reduce((acc, room) => {
          const roomBoxes = Math.ceil(room.area / sqFtPerBox);
          acc[room.room_name] = {
            boxes: roomBoxes,
            area: room.area
          };
          return acc;
        }, {});

      return {
        item_id: currentProduct._id,
        item_name: currentProduct.product_group_name,
        price_per_sqft: currentProduct.price_per_sqft,
        quantity: totalBoxes,
        selling_price: currentProduct.selling_price,
        cost_price: currentProduct.cost_price || 0,
        discount: 0,
        tax_rate: currentProduct.GST || 0,
        tax_type: 'GST',
        total_tax_amount: (category.room_category_price * (currentProduct.GST / 100)) || 0,
        tax_amount: (category.room_category_price * (currentProduct.GST / 100)) || 0,
        total: category.room_category_price,
        quantity_breakup,
        UOM: getUOMText(currentProduct.UOM_id),
        UOM_id: currentProduct.UOM_id,
        area_types: {
          area_name: category.room_category,
          area: category.room_category_area.toString()
        }
      };
    });

    console.log("itemsData:", itemsData);

    const quotationData = {
      customer_id: customerId,
      product_category_id: categoryId,
      quotation_date: new Date(),
      status: 'Draft',
      quotation_type: 'Group',
      project_id: projectId,
      items: itemsData,
      subtotal: itemsData.reduce((sum, item) => sum + item.total, 0),
      tax_amount: itemsData.reduce((sum, item) => sum + item.total_tax_amount, 0),
      discount: 0,
      adjustment: 0,
      tax_prefrence: 'Tax Exclusive',
      total: itemsData.reduce((sum, item) => sum + item.total + item.total_tax_amount, 0),
      organizationId
    };

    console.log("quotationData:", quotationData);

    const response = await fetch(`${fetchUrl2}customer/quotations/save-quotation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(quotationData),
    });

    const data = await response.json();
    if (data.success) {
   
        navigateToQuoteSummary(data.data._id)
        /* navigate('/consumer/app/quotationDetails', {
          state: { quotationId: data.data._id }
        }); */
 
        
    }
  } catch (error) {
    console.error('Failed to save quotation:', error);
   // alert('Failed to save quotation. Please try again.');
  }
};

const navigateToQuoteSummary = (quotation_id) => {
  try {
    const navigationData = {
      quotation_id: quotation_id || '',
      product_category_id: categoryId || '',
      project_id: projectId || '',
      package_id: packageId || '',
      product_category_name : 'Tiles',
      projectName: projectData.projectName,
      timestamp: Date.now()
    };
    setTimeout(() => { 
     setIsQuotationSaving(false);

    navigate('/consumer/app/summarydetails', {
      state: navigationData,
      replace: true 
    });
  }, 2000);

  } catch (error) {
    console.error('Navigation failed:', error);
    // Fallback navigation
    navigate('/consumer/app/summarydetails');
  }
};




const handleDirectPackageChange = async (packageId) => {
setHasUnsavedChanges(false);
await fetchTilesData(packageId);
setIsPackageChangeModalVisible(false);
};

const handleProductChange = (category) => {
  setSelectedCategory(category.room_category);
  
  // Store original category data with all products
  const originalCategoryData = categories.data.room_categories.find(
    cat => cat.room_category === category.room_category
  );

  // Set all available products from original data
  setAvailableProducts(originalCategoryData.products);
  
  // Get current selected product
  const currentProduct = category.products.find(prod => 
    prod.default === true || prod.isModified === true
  );
  setCurrentSelectedProduct(currentProduct);
  
  setIsProductModalVisible(true);

};

const handleViewDetailsClick = (category) => {
  setSelectedRoomDetails(category);
  setIsViewDetailsModalOpen(true);
};


const handleTouchStart = (e) => {
  setIsDragging(true);
  setDragPosition(e.touches[0].clientY);
};

const handleTouchMove = (e) => {
  if (!isDragging) return;
  
  const currentPosition = e.touches[0].clientY;
  const diff = currentPosition - dragPosition;
  
  if (diff > 0) { // Only allow downward drag
    modalRef.current.style.transform = `translateY(${diff}px)`;
  }
};

const handleTouchEnd = (e) => {
  if (!isDragging) return;
  
  const currentPosition = e.changedTouches[0].clientY;
  const diff = currentPosition - dragPosition;
  
  if (diff > dragThreshold) {
    // Close the modal with animation
    modalRef.current.style.transform = `translateY(100%)`;
    setTimeout(() => setIsViewDetailsModalOpen(false),
    setIsProductModalVisible(false),
    300);
  } else {
    // Reset position
    modalRef.current.style.transform = 'translateY(0)';
  }
  
  setIsDragging(false);
};


const handlePackageSwitch = (pkg) => {
  setSelectedPackage(pkg);
  setIsPackageSwitching(true);
  
  // Set maximum loader display time to 800ms
  setTimeout(() => {
    navigate(`/consumer/app/tilesQuote`, {
      state: {
        projectId,
        categoryId, 
        packageId: pkg.package_id,
        customerId,
        token,
        organizationId,
        packages,
        timestamp: Date.now()
      },
      replace: true
    });
    
    // Quick refresh for smooth transition
    setTimeout(() => window.location.reload(), 100);
  }, 2000);
};




const RefreshLoader = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
    <div className="p-8 rounded-lg flex flex-col items-center">
      <div className="w-12 h-12 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
      <div className="text-center">
        <p className="text-lg font-medium text-white mb-1">Refreshing</p>
        <h3 className="text-sm font-bold text-white">Please wait...</h3>
      </div>
    </div>
  </div>
);


const ProductDesignsModal = ({
  products,
  onClose,
  handleProductSelection,
  selectedDesign,
  setSelectedDesign,
  setSelectedImageUrl,
  imageUrl,
  selectedCategory,
  currentSelectedProduct,
  categories
}) => {
  return (
    <div className="fixed inset-0 flex items-end justify-center z-50">
      <div className="bg-black/10 backdrop-blur-xs absolute inset-0 transition-opacity" onClick={onClose} />
      <div className="bg-white rounded-t-2xl w-full max-w-2xl fixed bottom-0 z-10 animate-slide-up">
        <div className="p-4 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-bold text-gray-800">Available Designs</h3>
            <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full transition-colors">
              <FontAwesomeIcon icon={faTimes} className="text-gray-500" />
            </button>
          </div>
        </div>

        <div className="p-4 max-h-[70vh] overflow-y-auto">
          {(!products?.some(product => product.product_image)) ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={faImages} className="text-gray-400 text-xl" />
              </div>
              <h4 className="text-gray-500 font-medium">No product images available</h4>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              {products.map((product) => (
                product.product_image && (
                  <div key={product._id} className="group bg-white rounded-xl border border-gray-100 overflow-hidden hover:shadow-md transition-all duration-300">
                    <div
                      className="relative aspect-square cursor-pointer"
                      onClick={() => {
                        setSelectedImageUrl(`${imageUrl}${product.product_image}`);
                        onClose();
                      }}
                    >
                      <img
                        src={`${imageUrl}${product.product_image}`}
                        alt={product.product_name}
                        className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                    </div>
                    
                    <div className="p-3 flex items-center gap-2 cursor-pointer hover:bg-red-50 transition-all duration-300">
                      <div className="flex items-center gap-2" onClick={async (e) => {
  e.stopPropagation();
  const result = await handleDesignSelection({
    ...product,
    room_category: selectedCategory
  });
}}
>
<div className="relative">
<input
  type="radio"
  name="design"
  value={product._id}
  checked={product.selected}
  onChange={() => {}}
  className="appearance-none w-5 h-5 rounded-full border-2 border-gray-300 checked:border-[#EA011F] transition-colors cursor-pointer 
  checked:before:content-[''] checked:before:absolute checked:before:w-2.5 checked:before:h-2.5 checked:before:bg-[#EA011F] checked:before:rounded-full 
  checked:before:top-1/2 checked:before:left-1/2 checked:before:-translate-x-1/2 checked:before:-translate-y-1/2 relative"
/>

</div>
<h4 className="text-[10px] font-semibold text-gray-800 group-hover:text-[#EA011F] transition-colors">
  {product.product_name}
</h4>

                      </div>
                    </div>
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};





const renderHeader = () => (
  <div className="bg-white sticky top-0 z-50 border-b border-gray-200">
    <div className="h-1 bg-gradient-to-r from-[#EA011F] via-red-400 to-[#EA011F]"></div>
    <div className="px-4 py-3">
      <div className="flex items-center justify-between gap-3">
        {/* Back Button */}
        <div className="flex-shrink-0">
          <button
            onClick={() => navigate(-1)}
            className="w-9 h-9 flex items-center justify-center bg-gradient-to-br from-red-50 to-white rounded-xl hover:shadow-md transition-all duration-300 group"
          >
            <FontAwesomeIcon 
              icon={faArrowLeft} 
              className="text-gray-600 group-hover:text-[#EA011F] transition-colors" 
            />
          </button>
        </div>

        {/* Project Name and Category Container */}
        <div className="flex-1 flex flex-col items-center">
          <h1 className="text-lg font-bold text-gray-800 truncate w-full text-center mb-0.5">
            {projectData.projectName}
          </h1>
          <div className="flex items-center gap-1.5">
            <div className="w-1 h-1 rounded-full bg-[#EA011F]"></div>
            <span className="text-xs font-medium text-gray-500">Tiles</span>
            <div className="w-1 h-1 rounded-full bg-[#EA011F]"></div>
          </div>
        </div>

        {/* Home Button */}
        <div className="flex-shrink-0">
          <button
            onClick={() => navigate('/consumer/app/home')}
            className="w-9 h-9 flex items-center justify-center bg-gradient-to-br from-red-50 to-white rounded-xl hover:shadow-md transition-all duration-300 group"
          >
            <FontAwesomeIcon 
              icon={faHome} 
              className="text-gray-600 group-hover:text-[#EA011F] transition-colors" 
            />
          </button>
        </div>
      </div>
    </div>
  </div>
);


const renderFloatingButtons = () => {
  const hasChanges = categories?.data?.room_categories?.some(cat =>
    cat.products.some(prod => prod.isModified)
  );



  return (
    <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 shadow-xl z-10">
      <div className="px-4 py-3">
        <div className="flex items-center justify-between w-full gap-3">
          {/* Stylish Package/Quote Status Display - 30% width */}
          <div className="w-[30%] h-[48px] flex items-center">
            {hasChanges ? (
              <div className="relative group">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-red-50 to-white px-4 py-2 rounded-lg border-l-4 border-[#EA011F]">
                    <span className="text-sm font-semibold text-[#EA011F]">Customized Quote</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative group">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-gray-50 to-white px-4 py-2 rounded-lg border-l-4 border-green-500">
                  
                    <span className="text-sm font-semibold text-gray-700">{categories?.data?.package_name} Quote</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Proceed Button remains unchanged */}
          <button
            onClick={() => {
              if (hasChanges) {
                setIsSavingQuote(true);
                handleSaveQuote();
              } else {
                setIsQuotationSaving(true);
                navigateToQuoteSummary('');
              }
            }}
            className="w-[70%] h-[48px] rounded-xl bg-gradient-to-r from-red-500 to-red-600 hover:shadow-lg transition-all duration-300"
          >
            <div className="flex items-center justify-between px-6 py-2">
              <span className="text-lg font-bold text-white">
                {isPriceLoading ? (
                  <div className="w-4 h-4 border-2 border-gray-300 border-t-[#EA011F] rounded-full animate-spin"/>
                ) : (
                  `₹${totalPrice.toLocaleString('en-IN')}`
                )}
              </span>
              <div className="flex items-center gap-2">
                <span className="text-lg font-semibold text-white">Proceed</span>
                <FontAwesomeIcon icon={faChevronRight} className="text-white text-xs"/>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};


 
         return (
          <>
     {isRefreshing ? (
      <RefreshLoader />
    ) : (
             <div className="flex-1 bg-gray-50 min-h-screen">
               {renderHeader()}
               <div className="bg-[#F5F7FA] flex-1 p-0.1 pt-0.1 -mt-5 overflow-y-auto">
                 <div className="bg-gray-100 rounded-lg shadow-md">
                   <div className="p-4">
                     {categories?.data?.room_categories?.map((category, categoryIndex) => (
                       <div key={category.room_category} className="mx-0 mt-4 lg:mx-8 bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
                         <div className="p-2 border-b border-gray-100">{console.log("Category",category)}
                    <div className="flex justify-between items-center">
                      <span className="text-lg font-semibold text-[#EA011F]">{category.room_category}</span>
                      <button 
                        onClick={() => handleViewDetailsClick(category)} 
                        className="flex items-center gap-1 text-sm font-medium text-gray-600 hover:text-[#EA011F] transition-colors group"
                        title="Click to view area details"
                      >
                        <span>{category.room_category_area} sq.ft</span>
                        <svg 
                          className="w-4 h-4 group-hover:translate-x-1 transition-transform" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                        </svg>
                      </button>
                    </div>
                  </div>
     
                  {loadingCategories[category.room_category] ? (
  <div className="mt-1 bg-gray-50 rounded-lg p-3 shadow-sm flex justify-center items-center h-24">
    <div className="w-8 h-8 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin"></div>
  </div>
) : (
  category.products       
    .filter(product => product.default === true || product.isModified)
    .map(product => (
      <div key={product._id} className="p-2">
        <div className="flex justify-between items-center mb-2">
          <span className="text-base font-medium text-gray-800">{product.product_group_name}</span>
          <button
            onClick={() => handleProductChange(category)}
            className="px-2 py-0 border border-[#EA011F] rounded-full"
          >
            <span className="text-[#EA011F] text-sm font-regular">Customize</span>
          </button>
        </div>

        <div className="mt-1 bg-gray-50 rounded-lg p-3 shadow-sm">
  <div className="grid grid-cols-3 gap-4">
    <div className="flex flex-col">
      <span className="text-xs text-gray-500 mb-1">Quantity</span>
      <p className="text-sm font-medium text-gray-700">
        {product.boxes_needed}
        <span className="text-gray-500 text-xs ml-1">
          {getUOMText(product.UOM_id) === 'Box' && product.boxes_needed > 1 ? 'Boxes' : getUOMText(product.UOM_id)}
        </span>
      </p>
    </div>

    <div className="flex flex-col text-center">
      <span className="text-xs text-gray-500 mb-1">Unit Price</span>
      <p className="text-sm font-medium text-gray-700">
        ₹{product.selling_price}
        <span className="text-gray-500 text-xs"> per {getUOMText(product.UOM_id)}</span>
      </p>
    </div>

    <div className="flex flex-col text-right">
      <span className="text-xs text-gray-500 mb-1">Price</span>
      <p className="text-base font-bold text-green-700">
        ₹{category.room_category_price.toLocaleString('en-IN')}
      </p>
    </div>
  </div>

  <div className="flex items-center justify-between mt-3 pt-2 border-t border-gray-200">
  <button 
  onClick={() => {
    setSelectedProduct(product);
    setSelectedCategory(category.room_category); // Set selected category directly
    setIsDesignsModalVisible(true);
    setRoomCategoryIndex(categoryIndex);
  }}
  className="group relative flex items-center gap-2 px-4 py-1.5 bg-gradient-to-r from-red-50 to-white rounded-lg border border-red-100 hover:border-[#EA011F] transition-all duration-300"
> <div className="w-5 h-5 rounded-full bg-white shadow-sm flex items-center justify-center">
      <FontAwesomeIcon
        icon={faImages}
        className="text-[#EA011F] text-xs group-hover:scale-110 transition-transform"
      />
    </div>
    <span className="text-sm font-medium text-gray-700 group-hover:text-[#EA011F] transition-colors">
      View Designs
    </span>
  </button>

  <div className="flex items-center gap-1 bg-white px-3 py-1.5 rounded-lg shadow-sm">
    <FontAwesomeIcon icon={faTag} className="text-[#EA011F] text-xs" />
    <div className="flex flex-col">
      <div className="flex items-center gap-1">
        <span className="text-xs font-medium text-gray-700">₹{product.price_per_sqft}/sq.ft</span>
      </div>
      <span className="text-[10px] text-gray-500 leading-none">(approx)</span>
    </div>
  </div>
</div>

</div>

{isDesignsModalVisible && selectedProduct && (
  <ProductDesignsModal 
    products={selectedProduct.products}
    onClose={() => setIsDesignsModalVisible(false)}
    handleProductSelection={handleProductSelection}
    selectedDesign={selectedDesign}
    setSelectedDesign={setSelectedDesign}
    setSelectedImageUrl={setSelectedImageUrl}
    imageUrl={imageUrl}
    selectedCategory={selectedCategory}
    currentSelectedProduct={currentSelectedProduct}
    categories={categories}
  />
)}

        {isProductModalVisible && (
          <div className="fixed inset-0 flex items-end justify-center z-50">
            <div className="bg-black/10 backdrop-blur-xs absolute inset-0 transition-opacity" onClick={() => setIsProductModalVisible(false)} />
            <div
              ref={modalRef}
              className="bg-white border-t-2 border-gray-500 rounded-t-[20px] p-6 w-full max-w-2xl relative z-10 animate-slide-up"
              style={{height: '70vh'}}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
              
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800">Select Product</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-2xl text-gray-600 cursor-pointer hover:text-[#EA011F]"
                  onClick={() => setIsProductModalVisible(false)}
                />
              </div>

              <div className="h-[55vh] overflow-y-auto no-scrollbar">
                {availableProducts.map((product) => (
                  <button
                    key={product._id}
                    className="w-full p-4 border-b border-gray-100 flex items-center gap-3"
                    onClick={() => handleProductSelection(product)}
                  >
                    <div className="flex-shrink-0">
                      <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                        product.default ? 'border-[#EA011F]' : 'border-gray-300'
                      }`}>
                        {product.default && (
                          <div className="w-3 h-3 rounded-full bg-[#EA011F]" />
                       
                        )}
                      </div>
                    </div>
                    <div className="flex-1 text-left">
                      <h3 className="text-lg font-bold text-gray-800">{product.product_group_name}</h3>
                      <p className="text-gray-600">₹{product.price_per_sqft}/sq.ft</p>
                      </div>
                 
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        
      </div>
    ))
)}

                       
     {/*                                       <button
                                             onClick={() => handleViewDetailsClick(category)}
                                             className="m-4 flex justify-between items-center p-3 bg-gray-100 rounded-lg w-[285px]"
                                           >
                                             <div className="flex items-center">
                                             <span className="text-black font-semibold text-sm">{category.room_category}</span>     
                                             </div>
                                             <div className="flex items-center  justify-end">
                                             <FontAwesomeIcon icon={faListAlt} className="text-[#736f70] mr-2" />
                                             <span className="font-regular text-gray-800">View Details</span>
                                             </div>
                                           </button> */}
                       </div>
                     ))}
                     
                   </div>
                  
                 </div>
                 {isViewDetailsModalOpen && selectedRoomDetails && (
      <div className="fixed inset-0 flex items-end justify-center z-50 ">
      <div className="bg-black/10 backdrop-blur-xs absolute inset-0 transition-opacity" onClick={() => setIsViewDetailsModalOpen(false)} />
      <div
      ref={modalRef}
       className="bg-white border-gray-500 rounded-t-2xl p-6 w-full max-w-2xl relative z-10 animate-slide-up" style={{height: '70vh'}}
       onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
       >
              <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
     
           <div className="flex justify-between items-center mb-4">
             <h3 className="text-xl font-bold text-[#EA011F]">Room Details</h3>
             <button 
               onClick={() => setIsViewDetailsModalOpen(false)}
               className="text-red-500 hover:text-[#EA011F] transition-colors"
             >
               <FaTimes size={24} />
             </button>
           </div>
           <div className="max-h-96 overflow-y-auto">
             {selectedRoomDetails.rooms.filter(room => room.area > 0).map((room) => (
               <div key={room.room_name} className="flex justify-between items-center py-2 border-b">
                 <span className="text-gray-900">{room.room_name}</span>
                 <span className="font-bold text-gray-900">{room.area.toFixed(2)} sq.ft</span>
               </div>
             ))}
           </div>
         </div>
       </div>
     )}
     
     <div className="p-6 mt-6 mb-16 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-sm">
  <div className="flex items-center gap-3 mb-6">
    <div className="bg-red-100 p-2 rounded-lg">
      <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
      </svg>
    </div>
    <h3 className="text-base font-bold text-gray-800">Explore More Options</h3>
  </div>

  <div className="grid grid-cols-2 gap-4">
    {packages?.filter(pkg =>
      pkg.product_category_id === categoryId &&
      pkg.project_id === projectId &&
      pkg.package_id !== packageId
    )
    .sort((a, b) => a.package_id - b.package_id) 
    .map(pkg => (
      <button
        key={pkg.package_id}
        className="group relative flex flex-col p-5 bg-white hover:bg-red-50 border-2 border-gray-100 hover:border-[#EA011F] rounded-xl transition-all duration-300 transform hover:-translate-y-1 hover:shadow-md"
        onClick={() => handlePackageSwitch(pkg)}
      >
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <div className="p-1.5 bg-red-50 rounded-md">
              <svg className="w-4 h-4 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
              </svg>
            </div>
            <span className="text-lg font-bold text-gray-800 group-hover:text-[#EA011F]">
              {pkg.package_name}
            </span>
          </div>
          
          <div className="flex items-baseline gap-2">
            <span className="text-base font-bold text-[#EA011F]">
              ₹{pkg.total_price.toLocaleString('en-IN')}
            </span>
            <svg className="w-4 h-4 text-gray-400 group-hover:text-[#EA011F] group-hover:translate-x-1 transition-all" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
            </svg>
          </div>
        </div>
      </button>
    ))}
  </div>
</div>
     
          
           {/* Store Information */}
             {/* <div className="bg-white rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] p-8 mt-6 backdrop-blur-sm">
  <div className="max-w-2xl mx-auto">
   
    <div className="text-center mb-6">
      <h2 className="text-2xl font-bold bg-gradient-to-r from-[#EA011F] to-red-500 text-transparent bg-clip-text">
        Brickbucket Center
      </h2>
      <div className="h-1 w-20 bg-gradient-to-r from-[#EA011F] to-red-500 mx-auto mt-2 rounded-full"/>
    </div>

   
    <div className="flex items-start gap-3 mb-6">
      <div className="p-2 bg-red-50 rounded-full flex-shrink-0">
        <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
        </svg>
      </div>
      <p className="text-gray-600 text-sm leading-relaxed">
        1st Floor, Besides Rainbow Hospital, Swarna Grand, NH 16 Service Rd, 
        Nagarjuna Nagar, Sri Ramachandra Nagar, Vijayawada, Andhra Pradesh 520008.
      </p>
    </div>

    
    <a
      href="tel:+919701967272"
      className="group flex items-center justify-center gap-3 p-3 mb-8
        bg-gradient-to-r from-red-50 to-white
        hover:from-red-100 hover:to-red-50
        border border-red-100 rounded-xl
        transform hover:scale-[1.02] hover:shadow-lg
        transition-all duration-300"
    >
      <div className="p-2 bg-white rounded-full shadow-sm group-hover:shadow">
        <svg className="w-5 h-5 text-[#EA011F] group-hover:animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
        </svg>
      </div>
      <div className="flex flex-col">
        <span className="text-gray-800 font-medium">+91 9701967272</span>
        <span className="text-xs text-gray-500">Click to call now</span>
      </div>
      <svg className="w-5 h-5 text-gray-400 group-hover:translate-x-1 transition-transform" 
        fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
      </svg>
    </a>

   
    <div className="relative rounded-xl overflow-hidden shadow-lg group">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20 
        opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.8661790804444!2d80.67221177489614!3d16.518743088608843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35e59e85c4baa7%3A0x2b2d63f275dc3d6e!2sKRAFTO%20Tiles!5e0!3m2!1sen!2sin!4v1706521145617!5m2!1sen!2sin"
        className="w-full h-72 group-hover:scale-105 transition-transform duration-700"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
       <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
        <button className="bg-white/90 backdrop-blur-sm px-4 py-2 rounded-lg shadow-lg 
          text-sm font-medium text-gray-700 hover:bg-white transition-colors">
          Open in Google Maps
        </button>
      </div> 
    </div>
  </div>
</div> */}

       
     
   
     
                 {/* Callback Modal */}
                 {isCallbackModalVisible && (
                   <div className="fixed inset-0 flex items-center justify-center z-50">
                     <div className="absolute inset-0 bg-black opacity-50" />
                     <div className="bg-white p-8 rounded-lg w-11/12 max-w-md relative z-10">
                       <h3 className="text-xl font-bold text-[#EA011F] text-center mb-4">
                         Thanks for your interest
                       </h3>
                       <p className="text-gray-700 text-center mb-6">
                         Our executive will call you shortly.
                       </p>
                       <button
                         className="w-full bg-[#EA011F] py-3 px-6 rounded-lg text-white font-semibold hover:bg-red-700"
                         onClick={() => setIsCallbackModalVisible(false)}
                       >
                         Okay
                       </button>
                     </div>
                   </div>
                 )}
     
     {showNoChangesModal && (
       <div className="fixed inset-0 flex items-center justify-center z-50">
         <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setShowNoChangesModal(false)} />
         <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
           <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">No Changes Found</h2>
           <p className="text-gray-700 text-center mb-6">There are no modifications to save.</p>
           <button
             className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
             onClick={() => setShowNoChangesModal(false)}
           >
             Close
           </button>
         </div>
       </div>
     )}
           
           {/* {isSavingQuote && (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
         <div className="bg-white p-8 rounded-lg flex flex-col items-center">
           <div className="w-16 h-16 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
           <p className="text-lg font-medium text-gray-800">Generating New Custom Quote...</p>
         </div>
       </div>
     )} */}
     
               </div>

{/* 
        <div className="sticky bottom-0 left-0 right-0 p-3 shadow-lg bg-gray-100">
        <div className="max-w-7xl mx-auto flex gap-4">    
          <button
            className="w-full md:w-1/8 border border-red-500 py-2 px-3 rounded-lg text-black font-semibold text-xs hover:bg-red-700 whitespace-nowrap"
            onClick={() => setIsCallbackModalVisible(true)}
          >
        <FontAwesomeIcon icon={faPhone} className="text-red-500 text-lg mr-2" size="md" />
        Request a Call Back
          </button>
          <button
        onClick={() => {
          const hasModifiedProducts = categories?.data?.room_categories?.some(cat => 
            cat.products.some(prod => prod.isModified)
          );
          
          if (hasModifiedProducts) {
            setIsSavingQuote(true);
            handleSaveQuote();         
          } else {
            setShowNoChangesModal(true);
          }
        }}
        className={`w-full md:w-1/8 py-2 px-4 rounded-lg text-white font-regular text-md whitespace-nowrap ${
          categories?.data?.room_categories?.some(cat => 
            cat.products.some(prod => prod.isModified)
          )
          ? 'bg-red-500 hover:bg-red-700'
          : 'bg-gray-300'
        }`}
      >
        Save
      </button>
        </div>
      
                </div> */}

                {renderFloatingButtons()}


               {/* {isSavingQuote && (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
         <div className="bg-white p-8 rounded-lg flex flex-col items-center">
           <div className="w-16 h-16 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
           <p className="text-lg font-medium text-gray-800">Generating New Custom Quote...</p>
         </div>
       </div>
     )} */}
    
  {selectedImageUrl && (
  <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black/70">
    <button 
      onClick={() => {
        setSelectedImageUrl(null);
        setIsDesignsModalVisible(true); // Reopen designs modal
      }}
      className="absolute top-4 right-4 text-white hover:text-red-500 transition-colors"
    >
      <FontAwesomeIcon icon={faTimes} size="2x" />
    </button>
    <img 
      src={selectedImageUrl}
      alt="Full size preview" 
      className="max-w-[90vw] max-h-[90vh] object-contain"
    />
  </div>
)}

{showDesignConfirmation && (
  <div className="fixed inset-0 z-[70] flex items-center justify-center bg-black/50">
    <div className="bg-white rounded-xl p-6 w-[90%] max-w-md animate-bounce-in">
      <div className="text-center">
        <div className="w-16 h-16 mx-auto mb-4 bg-green-50 rounded-full flex items-center justify-center">
          <FontAwesomeIcon icon={faCheck} className="text-green-500 text-2xl" />
        </div>
        <h3 className="text-xl font-bold text-gray-800 mb-2">
          Design Updated Successfully!
        </h3>
        <p className="text-gray-600">
          Thanks for choosing a new design for {selectedRoomCategory}
        </p>
      </div>
    </div>
  </div>
)}

<LoaderModal 
  isVisible={isPackageSwitching}
  title="Switching to"
  packageName={selectedPackage?.package_name}
/>

<LoaderModal 
  isVisible={isQuotationSaving}
  title="Generating Custom Quote"
  packageName="Please wait..."
/>

             </div>
     
      )}
    </>
             
           );
    }
      

export default TilesQuote;
