import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronDown, faChevronUp, faSortUp, faSortDown, faX, faPaperclip, faPlus, faPen, faMobileAndroidAlt, faPlusCircle, faSave, faTimes, faTrash, faBank } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '../BBAdmin/Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from './Messages/Messages';


const ViewVendor = () => {
  const navigate = useNavigate();
  const  [vendors , setVendors] = useState ([]);
  const location = useLocation()
  const projectDetails = location.state.projectData
 // const {measurementType} = location.state.projectData
  // console.log(projectDetails)
  const [showAddress, setShowAddress] = useState(true);
  const [SelectedBlock, setSelectedBlock] = useState(null);
  const [blockId , SelectedBlockId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const backendApiUrl = process.env.REACT_APP_FORE_APILINK;
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorStatus, setVendorStatus] = useState('Deactivate');
  const RETRY_LIMIT = 3; // Maximum number of retries
const [retryCount, setRetryCount] = useState(0); // Track retry attempts
const [searchQuery, setSearchQuery] = useState('');
const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
const [BlockDetails, setBlockDetails] = useState([]);
const [EditBlockPopup, setEditBlockPopup] = useState(false);
const [EditFlatsPopup, setEditFlatsPopup] = useState(false);
const [CreateFlatPopup, setCreateFlatPopup] = useState(false);
const [flatNo, setFlatNo] = useState('');
const [type, setType] = useState('');
const [plinthArea, setPlinthArea] = useState('');
const [facing, setFacing] = useState('');
const [ErrorSaveBlock , setErrorSaveBlock]= useState('')

const projectData = [
   
  ];
  const [CreateBlockPopup, setCreateBlockPopup] = useState(false);
  const [blockNo, setBlockNo] = useState('');
  const [numFloors, setFloors] = useState('');
  const [numFlatsPerFloor, setFlatsPerFloor] = useState('');
  const [numFlats, setTotalFlats] = useState('');

const [flats , setFlats]= useState ([
  ]
  )
  const flatTypes = ['1BHK', '2BHK', '3BHK', '4BHK'];
const facingOptions = ['East', 'West', 'North', 'South'];

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...BlockDetails].sort((a, b) => {
      const aValue = a[key] || ''; // Treat undefined as empty string
      const bValue = b[key] || ''; // Treat undefined as empty string

      // Convert to string for comparison and handle case insensitivity
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();

      if (aString < bString) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aString > bString) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setBlockDetails(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
    return null;
  };


  useEffect(() => {
   if(projectDetails){
    handleBlockClick(projectDetails.Block);
    
   }
   
}, [projectDetails]);

useEffect(() => {
  if (projectDetails) {
    fetchBlocksByProjectid(projectDetails.project._id);
  }
}, [projectDetails]);

const fetchBlocksByProjectid = async (Projectid, attempt = 1) => {
  // console.log(Projectid);
  try {
    const token = sessionStorage.getItem('token');
    const GetContactPerson = `${backendApiUrl}/blocks/get-all-blocks-of-project?projectId=${Projectid}`;
    // console.log(GetContactPerson);

    const response = await fetch(GetContactPerson, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });

   
    const data = await response.json();


    if (response.status === 200 || data.status === 201 || data.status === 204) {
      setBlockDetails(data);
      return data;
    } else if (data.status === 500) {
      setShowPopup(true);
      setPopupType("500");
      setPopupMessage(data.message);
      setRetryFunction(() => () => fetchBlocksByProjectid(Projectid));
    } else {
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setTimeout(() => fetchBlocksByProjectid(Projectid, attempt + 1), 1000);
    } else {
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => fetchBlocksByProjectid(Projectid));
    }
  }
};

useEffect(() => {
  if (SelectedBlock) {
    //fetchContactPersonsByblockId(SelectedBlock._id)
    fetchFlatsByBlockid(SelectedBlock._id);
  }
}, [SelectedBlock ,  ]);

const fetchFlatsByBlockid = async (blockId, attempt = 1) => {
  //console.log(measurementType);
  try {
    const token = sessionStorage.getItem('token');
    const GetContactPerson = `${backendApiUrl}/flats/get-flats-by-blockId?blockId=${blockId}`;


    const response = await fetch(GetContactPerson, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-token': token
      },
    });


    const data = await response.json();


    if (response.status === 200 || data.status === 201 || data.status === 204) {
      setFlats(data);
      return data;
    } else if (data.status === 500) {
      setShowPopup(true);
      setPopupType("500");
      setPopupMessage(data.message);
      setRetryFunction(() => () => fetchFlatsByBlockid(blockId));
    } else {
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setTimeout(() => fetchFlatsByBlockid(blockId, attempt + 1), 1000);
    } else {
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => fetchFlatsByBlockid(blockId));
    }
  }
};

const handleUpdateBlock = async ( attempt = 1) => {
   
  if (!SelectedBlock._id) {
      
    setErrorSaveBlock("block ID is missing");
    return;
  }


  if (!SelectedBlock.blockNo || SelectedBlock.blockNo.trim() === '') {
    setErrorSaveBlock("Block Number is required");
    return;
  }

  if (
    SelectedBlock.numFloors === null || 
    SelectedBlock.numFloors === undefined || 
    SelectedBlock.numFloors === '' || 
    isNaN(Number(SelectedBlock.numFloors))
  ) {
    setErrorSaveBlock("Number of floors is required");
    return;
  }

  // Ensure it's a positive number
  if (SelectedBlock.numFloors <= 0) {
    setErrorSaveBlock("Number of floors must be greater than zero");
    return 
  }
  
  if (SelectedBlock.numFlatsPerFloor === null || 
    SelectedBlock.numFlatsPerFloor === undefined || 
    SelectedBlock.numFlatsPerFloor === '' || 
    isNaN(Number(SelectedBlock.numFlatsPerFloor))
  ) {
    setErrorSaveBlock("Number Flats Per Floor is required");
    return;
  }

  if (SelectedBlock.numFlatsPerFloor <= 0) {
    setErrorSaveBlock("Number of Flats floors must be greater than zero");
    return 
  }

  const BlockData = {
   ...SelectedBlock,
  };

  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/update-block?blockId=${SelectedBlock._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(BlockData),
    });

    const data = await response.json();

    if (response.ok) {
      //setCreateBlockPopup(false);
      setShowPopup(true);
      setPopupType("200or201");
      setPopupMessage("Block updated successfully.");
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);

      setEditBlockPopup(false);
      // Clear input fields after successful update
     /*  setBlockNo('');
      setFloors('');
      setFlatsPerFloor('');
      setTotalFlats('');
      await fetchBlocksByProjectid(SelectedProject._id); */
    } else if (response.status === 404) {
      setPopupMessage("Block not found");
      setPopupType("404");
      setShowPopup(true);
      setEditBlockPopup(false);
    } else if (response.status === 500) {
      setShowPopup(true);
     setEditBlockPopup(false);
      setRetryFunction(() => () => handleUpdateBlock());
      setPopupType("500");
      setPopupMessage("Internal Server Error");
    } else {
      setPopupMessage(data.error || "Failed to update block");
      setEditBlockPopup(false);
      setPopupType(`${response.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Error during API call:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleUpdateBlock( attempt + 1), 1000);
    } else {
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => handleUpdateBlock( attempt));
    }
  }
};



  const handleBlockClick = (block) => {
    
    SelectedBlockId(block._id);
    setSelectedBlock(block);
  };


  const handleBlockInputfieldChange = (e) => {
    setErrorSaveBlock('')
    const { id, value } = e.target;
    setSelectedBlock((prevSelectedBlock) => ({
        ...prevSelectedBlock,
        [id]: value
      }));
  };
 
  

const HandleClose = () => {
 navigate('/bb/app/projects/projectslist'); 
};

const handleEdit = () => {
 // navigate('/bb/app/purchases/EditVendor',  {state:{projectData:SelectedBlock}});
}

const handleNavigatetoMeasurements = (flat , measurementType) => {
  if (projectDetails?.project) {
    const data = {
      projectData: projectDetails.project,
      measurementsType: measurementType || 'quotation',
      block: SelectedBlock,
      flat: flat
    };

    navigate('/bb/app/projects/measurements', { state: { projectData: data } });
  } else {
    console.log('Project data is not available. Navigation prevented.');
    // Optionally, you can show a message to the user here
  }
};


const handleEditFlats = () => {
  setEditFlatsPopup(true);
}

const handleBlocksEdit = () => {
  setEditBlockPopup(true);
}

const handleClosePopup = () => {
  setEditBlockPopup(false);
  setEditFlatsPopup(false);
  setCreateFlatPopup(false);
  setCreateBlockPopup(false);
  setErrorSaveBlock('')
  setBlockNo('')
   setFloors ();
  setFlatsPerFloor();
   setTotalFlats();
}


const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

const handleCreateFlat = ()=>{
 // console.log(flats)
 /*  if(measurementType === 'quotation'){
    if(flats.length >= SelectedBlock.numFlatsPerFloor){
      setPopupMessage(`Flats already created per floor for ${measurementType}`);
      setPopupType("400");
      setShowPopup(true);
      return;
    }else{
      setCreateFlatPopup(true);
    }
  }
  else{ */
    if(flats.length >= SelectedBlock.numFlats){
      setPopupMessage(`Flats already created for all floors `);
      setPopupType("400");
      setShowPopup(true);
      return;
    }else{
      setCreateFlatPopup(true);
    }
  
 
}
const [errorSaveflat , setErrorSaveFlat]= useState('')

const handleSaveFlat = async() =>{

  if (!SelectedBlock || !SelectedBlock._id) {
    setErrorSaveFlat("Please select a block");
    return;
  }

  if (!SelectedBlock.projectId) {

    setErrorSaveFlat("Project ID is missing");
    return;
  }


  if (!flatNo || flatNo.trim() === '') {
    setErrorSaveFlat("Flat Number is required");
    return;
  }

  if (!type || type.trim() === '') {
    setErrorSaveFlat("Flat Type is required");
    return;
  }

/*   if (!facing || facing.trim() === '') {
    setShowPopup(true);
    setPopupType("error");
    setErrorSaveFlat("Facing is required");
    return;
  }

  if (!plinthArea || isNaN(parseFloat(plinthArea)) || parseFloat(plinthArea) <= 0) {
    setShowPopup(true);
    setPopupType("error");
    setErrorSaveFlat("Valid Plinth Area is required");
    return;
  }
 */
   

  const token = sessionStorage.getItem('token');
  const FlatData = {
    blockId: SelectedBlock._id,
    projectId: SelectedBlock.projectId,
    flatNo: flatNo,
    type: type,
    facing: facing,
    plinthArea: plinthArea,
    //measurementType: measurementType,
  }
try {
  const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/flats/create-flat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-token': token
    },
    body: JSON.stringify(FlatData),
  });
  const data = await response.json();
  if (response.ok) {
    setCreateFlatPopup(false);
    setShowPopup(true);
    setPopupType("200or201");
    setPopupMessage("Flat created successfully.");
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
    setFlatNo('');
    setType('');
    setFacing('');
    setPlinthArea(''); 
    await fetchFlatsByBlockid(blockId)
  }else if (response.status === 404) {
    setPopupMessage("Block not found");
    setPopupType("404");
    setShowPopup(true);
  } else if (response.status === 500) {
    setPopupMessage("Internal Server Error");
    setPopupType("500");
    setShowPopup(true);
  } else {
    setPopupMessage(data.error || "Failed to create flat");
    setPopupType(`${response.status}`);
    setShowPopup(true);
  }
  
} catch (error) {
  console.error('Error during API call:', error);
}
}


const handleCreateBlock = () => {
  setCreateBlockPopup(true);
}

const handleFloorsChange = (e) => {
  const floors = parseInt(e.target.value);
  setFloors(floors);
  setTotalFlats(floors * numFlatsPerFloor);
};

const handleFlatsPerFloorChange = (e) => {
  const flatsPerFloor = parseInt(e.target.value) ;
  setFlatsPerFloor(flatsPerFloor);
  setTotalFlats(numFloors * flatsPerFloor);
};

const handleTotalFlatsChange = (e) => {
  setErrorSaveBlock('')
  const totalFlats = parseInt(e.target.value) ;
  setTotalFlats(totalFlats);
};

const handleSaveBlock = async (projectId,attempt = 1) => {

  if (!projectDetails.project._id) {
      
    setErrorSaveBlock("project ID is missing");
    return;
  }


  if (!blockNo || blockNo.trim() === '') {
    setErrorSaveBlock("Block Number is required");
    return;
  }

  if (
    numFloors === null || 
    numFloors === undefined || 
    numFloors === '' || 
    isNaN(Number(numFloors))
  ) {
    setErrorSaveBlock("Number of floors is required");
    return;
  }

  // Ensure it's a positive number
  if (numFloors <= 0) {
    setErrorSaveBlock("Number of floors must be greater than zero");
    return 
  }
  
  if (numFlatsPerFloor === null || 
    numFlatsPerFloor === undefined || 
    numFlatsPerFloor === '' || 
    isNaN(Number(numFlatsPerFloor))
  ) {
    setErrorSaveBlock("Number Flats Per Floor is required");
    return;
  }

  if (numFlatsPerFloor <= 0) {
    setErrorSaveBlock("Number of Flats floors must be greater than zero");
    return 
  }  

  const BlockData = {
    blockNo: String(blockNo),
    numFloors: Number(numFloors),
    numFlatsPerFloor: Number(numFlatsPerFloor),
    numFlats: Number(numFlats),
    projectId: projectDetails.project._id,
  };
  

  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/create-new-block`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(BlockData),
    });
    const data = await response.json();
  

    if (response.ok) {
      //fetchBlocksData();
     // setEditModeIndex(null);
     // setsaveAlert(true);
     setCreateBlockPopup(false);
      setShowPopup(true);
      setPopupType("200or201");
      setPopupMessage("Block saved successfully.");
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      
      // Clear input fields after successful save
      setBlockNo('');
      setFloors('');
      setFlatsPerFloor('');
      setTotalFlats('');
      await fetchBlocksByProjectid(projectDetails.project._id._id);
    } else if (response.status === 500) {
      setShowPopup(true);
      setCreateBlockPopup(false);
      setRetryFunction(() => () => handleSaveBlock(projectId));
      setPopupType("500");
      setPopupMessage(data.message || "Internal Server Error");
    } else {
      setPopupMessage(data.message || "Failed to save block");
      setCreateBlockPopup(false);
      setPopupType(`${response.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Error during API call:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleSaveBlock(projectId, attempt + 1), 1000);
    } else {
      setPopupMessage("Internal Server Error. Kindly Retry.");
      setPopupType("500");
      setShowPopup(true);
      setRetryFunction(() => () => handleSaveBlock(projectId, attempt));
    }
  }
};

/* if (isLoading) {
  return <Loader />;
} */


return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
{/* List of Vendors Div start */} {/* sm:hidden md:hidden lg:hidden xl:block */}
      <div className=" flex flex-col  w-[26%] sm:hidden md:hidden lg:hidden xl:block ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Blocks
        <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button 
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"  /* data-tooltip="Create Vendor" title=""  style={{ '--tooltip-bg': 'black' }} */
  /* onClick={handleCreateVendor} */>
  <FontAwesomeIcon icon={faPlus}  className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className=" flex justify-center  overflow-y-hidden  flex-col ">
   <div className='flex  flex-col h-[80vh]  overflow-y-auto '>
   {BlockDetails.map((Block) => (
        <div
          key={Block.id}
          onClick={() => handleBlockClick(Block)}
          className={`border-b text-sm flex items-center px-4 py-2 ${blockId === Block._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        >
          <div className='flex flex-row'>
            <div>
              <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
            </div>
            <div className='flex flex-col'>
              <p className='text-md font-semibold' > Block : {Block.blockNo}</p>
               <p className="text-gray-600 text-sm">{/* {projectDetails?.project?.projectName} */}</p> 
            </div>
          </div>
        </div>
      ))}

  </div>

</div>
</div>
{/* List of Vendors Div end */}

<div className="border-l  w-full flex flex-col ">
{SelectedBlock ? (
  <>
<div className="border-b h-24"> {/* or h-12, depending on your desired height */}
  <div className='p-4 flex justify-between items-center '>
     <span className="text-md font-semibold">Block: {SelectedBlock.blockNo}</span> 
    <div className="flex items-center px-2">
     {/*  <button className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md mr-2" onClick={handleEdit}>
        Edit
      </button> */}
      {/* <button className="bg-gray-100 px-1 py-1 shadow-sm text-sm border rounded-md mr-2">
        <FontAwesomeIcon icon={faPaperclip} className="text-md text-black mx-2 cursor-pointer " />
      </button> */}
      <button className="bg-[#f7525a] text-sm text-white px-2 py-1 shadow-sm border rounded-md mr-2" onClick={handleCreateBlock}>
       + New Block
      </button>
   {/*  <button 
    className="bg-gray-100 text-sm px-2 py-1 shadow-sm border rounded-md mr-2">
      Assign
    </button> */}
        <button className=" text-white px-1 py-1 rounded-md "
         title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>   
        <FontAwesomeIcon icon={faX} onClick={HandleClose} className="text-md text-[#f7525a] cursor-pointer " />
      </button>
    </div>
  </div>
</div>
<div className=" flex flex-col md:flex-row h-5/6 w-full overflow-y-auto">
<div className="bg-[#fbfbfb] w-full md:w-[32%] border-r  p-4">
<div className="relative">
  <h1 className="text-[1rem] font-bold pb-2">{projectDetails?.project?.projectName}</h1>

  <h2 className="text-sm pb-2 border-b flex justify-between">
    Block : {SelectedBlock.blockNo}
  </h2>

  <button 
    className="absolute top-0 right-0 px-2 py-1 text-[#408dfb] text-sm"
    onClick={handleBlocksEdit}
  >
    Edit
  </button>
</div>

    
 {/*    <div className='pt-4 pl-4 pr-4  flex flex-row'>
    <div className='flex-shrink-0'>
    <img className=" rounded-md w-10 h-10 cursor-pointer" src={'/ProfileImageSvg.jpg'} alt="Brick Bucket Logo" />
  </div>
  <div className=' text-sm ml-3 flex flex-col'>
    <div className='font-semibold mb-0.5 break-words word-break'>{SelectedBlock.projectName}</div>
    <div className='break-words word-break'>{SelectedBlock.builderName}</div>
    <div className='break-words word-break'>
      <FontAwesomeIcon icon={faMobileAndroidAlt} className='mr-1 text-xs'/>
      {SelectedBlock.builderContactNo}
    </div>
    <div className='text-xs flex flex-row'>
      <button onClick={handleEditClick} className='text-[#408dfb] hover:text-blue-600'>Edit</button>
      <p className='text-gray-300 ml-2 mr-2 '>|</p>
      <button
      onClick={SelectedBlock.is_active ? handleDeactivateClick : handleActivateClick}
      className='text-[#408dfb] hover:text-blue-600' >
      {SelectedBlock.is_active ? 'Deactivate' : 'Activate'}
    </button>  </div>
  </div>
</div> */}

<div /* onClick={handleToggleAddress} */  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className=' text-sm cursor-pointer'/*  onClick={handleToggleAddress} */>BLOCK DETAILS</span>
  <button className=' text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' /* onClick={handleToggleAddress} */>
    {showAddress? <FontAwesomeIcon icon={faChevronUp} className='text-xs text-blue-800'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs text-blue-800'/>}
  </button>
</div>

<div className={`${showAddress ? '' : 'hidden'}`}>
  <div className='text-sm pt-2 pl-4 pr-4'>


    <div className='flex flex-col'>
    <div className='flex flex-row hover:bg-[#d7d7ec] hover:rounded-md'>
<div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex '>
  
 <div className='flex flex-col space-y-2'>
  <div className='flex flex-row space-x-2'>
  <p className='font-semibold mb-0.5'>Number Of Floors :</p>
  <p>{SelectedBlock.numFloors}</p>
  </div>
 
  <div className='flex flex-row space-x-2'>
<p className='font-semibold mb-0.5'>Nuber Of Flats Per Floor :</p>
<p>{SelectedBlock.numFlatsPerFloor}</p>
</div>
<div className='flex flex-row space-x-2'>
<p className='font-semibold mb-0.5'>Total Flats :</p>
<p>{SelectedBlock.numFlats}</p>
</div>
  
  </div>

  <div className='flex items-center'>
 
  </div>
  </div>
</div>
    </div>
  </div>
</div>

{/* <div onClick={handleToggleOtherDetails}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <span className='text-sm cursor-pointer' onClick={handleToggleOtherDetails}>LOCATION</span>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleOtherDetails}>
    {showOtherDetails? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
</div> */}
{/* <div className={`${showOtherDetails ? '' : 'hidden'} mt-2 mb-2 flex flex-col`}>
  <div className='flex flex-row  hover:rounded-md'>
    <div className='flex-1 text-xs pt-2 pb-2 pl-1 pr-1 flex'>
      <div className='flex flex-col pb-2 space-y-2'>
        <div className='flex flex-col space-y-1 ml-3'>
          <label className='font-semibold mb-0.5'>Latitude</label>
          <input className='border border-gray-300 p-1 rounded' type='text' />
        </div>
        <div className='flex flex-col space-y-1 ml-3'>
          <label className='font-semibold mb-0.5'>Longitude</label>
          <input className='border border-gray-300 p-1 rounded' type='text' />
        </div>
      </div>
    </div>
  </div>
</div> */}


{/* <div onClick={handleToggleContactPersons}  className='pt-4 pl-4 pr-4 border-b flex flex-row justify-between items-center'>
  <div>
  <span className='text-sm cursor-pointer' onClick={handleToggleContactPersons}>VISIT NOTES</span>
  </div>
  <div>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleContactPersons}>
    <FontAwesomeIcon icon={faPlusCircle} onClick={handlePlusIconClick} className='text-blue-500 text-md'/>
  </button>
  <button className='ml-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out' onClick={handleToggleContactPersons}>
    {showContactPersons? <FontAwesomeIcon icon={faChevronUp} className='text-xs'/> : <FontAwesomeIcon icon={faChevronDown} className='text-xs'/>}
  </button>
  </div>
</div>

<div className={`${showContactPersons ? '' : 'hidden'}`}>
  <div className='mt-2 mb-2 flex flex-col'>
    <label htmlFor='notes' className='mb-1 text-sm font-semibold'>
    Visit History
    </label>
    <textarea
      id='notes'
      className='border border-gray-300 p-2 rounded-md resize-none'
      placeholder='Enter your notes here...'
      rows={4}
    ></textarea>
  </div>
</div> */}





  </div>

  <div className="w-full md:w-[90vh] flex flex-col md:flex-row">
  <div className="flex flex-col w-full">
  <div className='mt-2 flex justify-between'>
  <h1 className='ml-4 mr-4'>Flats</h1>
    <button 
        className='bg-[#f7525a] px-2 py-1 text-white shadow-sm text-sm border rounded-md mr-2' 
        onClick={() => {handleCreateFlat()}}
    >
        + New Flat
    </button>
</div>

  <div className='mt-2 flex justify-between'>
   
    
</div>
    <div className="flex justify-center overflow-x-auto w-full mt-2">
      <table className="w-full mb-5">
        <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
          <tr className="border-b">
            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('flatNo')}>
              Flat No {getSortIcon('flatNo')}
            </th>
            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('facing')}>
              Facing {getSortIcon('facing')}
            </th>
            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('type')}>
              Type {getSortIcon('type')}
            </th>
            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('plinthArea')}>
              Plinth Area {getSortIcon('plinthArea')}
            </th>
            {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '3') && (
              <>
            <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('flatMeasurementsStatus')}>
              Status {getSortIcon('flatMeasurementsStatus')}
            </th>
            <th className="text-xs py-3 text-center cursor-pointer" >
              Measurements 
            </th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="border-b border-t">
          {flats.filter(
            (flat) =>
              flat.flatNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
              flat.facing.toLowerCase().includes(searchQuery.toLowerCase()) ||
              flat.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
              flat.plinthArea.toString().toLowerCase().includes(searchQuery.toLowerCase())
          ).map((flat) => (
            <tr key={flat._id.$oid} className="text-sm border-b">
              <td className="py-2 text-center">{flat.flatNo}</td>
              <td className="py-2 text-center">{flat.facing}</td>
              <td className="py-2 text-center">{flat.type}</td>
              <td className="py-2 text-center">
              {flat.plinthArea} {flat.plinthArea !== 0 && flat.plinthArea !== '' && flat.plinthArea !== null ? 'Sq Ft' : '---'}
            </td>

              {(sessionStorage.getItem('roleId') === '0' || sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '3') && (
              <>
              <td className="py-2 text-center">{flat.flatMeasurementsStatus}</td>
              <td className="py-2 text-center cursor-pointer text-[#408dfb] font-500 hover:underline" >
              <div className="flex flex-row space-x-2 items-center justify-center">
              <span className="text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={()=>handleNavigatetoMeasurements(flat, 'quotation')}>
            quote
          </span>
          <span className="text-[#408dfb] font-500 cursor-pointer hover:underline" onClick={()=>handleNavigatetoMeasurements(flat, 'production')}>
            production
          </span>
          </div>
                </td>
                </>
            )}
            </tr>
          ))}
          {flats.filter(
            (flat) =>
              flat.flatNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
              flat.facing.toLowerCase().includes(searchQuery.toLowerCase()) ||
              flat.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
              flat.plinthArea.toString().toLowerCase().includes(searchQuery.toLowerCase())
          ).length === 0 && (
            <tr>
              <td colSpan="5" className="text-center text-[#6c7184] py-4">
                Not found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>


          </div>
  

</>
) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">Select a vendor to view details</p>
          </div>
        )}
</div>

{/* Popup Starts Here */}
              {EditBlockPopup && (
 <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
  <div className="bg-white rounded-b-md shadow-md w-full md:w-1/2 h-3/4 md:h-2/3 flex flex-col">
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
      <h2 className="text-md">Edit Block Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-1 '>
   {/*  {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )} */}

<div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
    <label htmlFor="blockNo" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
      Block
    </label>
    <input
  type="text"
  id="blockNo"  
   value={SelectedBlock.blockNo}
  onChange={handleBlockInputfieldChange} 
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
  <label htmlFor="numFloors" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
      No of Floors
    </label>
    <input
  type="number"
  id="numFloors"
  value={SelectedBlock.numFloors}
  onChange={handleBlockInputfieldChange} 
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
  <label htmlFor="numFlatsPerFloor" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
Flats Per Floors </label>
<input
  type="number"
  id="numFlatsPerFloor"
  value={SelectedBlock.numFlatsPerFloor}
  onChange={handleBlockInputfieldChange} 
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>    

  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
  <label htmlFor="numFlats" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
No of Flats </label>
<input
  type="number"
  id="numFlats"
 value={SelectedBlock.numFlats}
  onChange={handleBlockInputfieldChange} 
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>  
       
</div>
<div className="w-full flex justify-center items-center mt-2">
  {ErrorSaveBlock && (
    <p className="text-red-500 text-sm  px-4 py-2 rounded-md shadow-sm">
      {ErrorSaveBlock}
    </p>
  )}
</div>

    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button   onClick={handleUpdateBlock} 
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
           <Spinner/> 
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)} 

{CreateFlatPopup && (
 <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
  <div className="bg-white rounded-b-md shadow-md w-full md:w-1/2 h-3/4 md:h-2/3 flex flex-col">
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
      <h2 className="text-md">Create Flat</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className=' flex  flex-col mt-4 w-full '>
   {/*  {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )} */}

<div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
    <label htmlFor="flatNo" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
      Flat No
    </label>
    <input
  type="text"
  id="flatNo"  
  value={flatNo}
  onChange={(e) => {setFlatNo(e.target.value); setErrorSaveFlat('')}}
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-3 px-4 md:px-6">
  <label htmlFor="type" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        Flat Type
      </label>
      <select
        id="type"
        value={type}
        onChange={(e) =>{ setType(e.target.value); setErrorSaveFlat('')}}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        >
        <option value="">Select Flat Type</option>
        {flatTypes.map((flatType) => (
          <option key={flatType} value={flatType}>{flatType}</option>
        ))}
      </select>
    </div>

    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-3 px-4 md:px-6">
    <label htmlFor="facing" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        Facing
      </label>
      <select
        id="facing"
        value={facing}
        onChange={(e) => {setFacing(e.target.value); setErrorSaveFlat('')}}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        >
        <option value="">Select Facing</option>
        {facingOptions.map((facingOption) => (
          <option key={facingOption} value={facingOption}>{facingOption}</option>
        ))}
      </select>
    </div>    

    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-3 px-4 md:px-6">
    <label htmlFor="plinthArea" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
Plinth Area (sq ft) </label>
<input
  type="number"
  id="plinthArea"
  value={plinthArea}
  onChange={(e) => {setPlinthArea(e.target.value); setErrorSaveFlat('')}}
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div> 

<div className="w-full flex justify-center items-center mt-2">
  {errorSaveflat && (
    <p className="text-red-500 text-sm  px-4 py-2 rounded-md shadow-sm">
      {errorSaveflat}
    </p>
  )}
</div>


       
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button  onClick={handleSaveFlat} 
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
           <Spinner/> 
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{EditFlatsPopup && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
  <div
  className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
  style={{    maxWidth: '40vw', maxHeight: '60vh',}}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
      <h2 className="text-md">Edit Flats Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-1 '>
   {/*  {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )} */}

{/* <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6 mt-4">
    <label htmlFor="blockNo" className="block text-xs font-regular text-[#212529] w-1/6">
      Block
    </label>
    <input
  type="text"
  id="flatNo"  
   value={flat.flatNo}
  onChange={handleFlatInputfieldChange} 
  className="w-1/3 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-6 px-6">
    <label htmlFor="numFloors" className="block text-xs font-regular text-[#212529] w-1/6">
      No of Floors
    </label>
    <input
  type="number"
  id="facing"
  value={flat.facing}
  onChange={handleFlatInputfieldChange} 
  className="w-1/3 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="numFlatsPerFloor" className="block text-xs font-regular text-[#212529] w-1/6">
Flats Per Floors </label>
<input
  type="number"
  id="numFlatsPerFloor"
  value={flat.type}
  onChange={handleBlockInputfieldChange} 
  className="w-1/3 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
/>
  </div>    

  <div className="w-full flex flex-row items-center mb-4 space-x-5 px-6">
    <label htmlFor="numFlats" className="block text-xs font-regular text-[#212529] w-1/6">
No of Flats </label>
<input
  type="number"
  id="numFlats"
 value={SelectedBlock.numFlats}
  onChange={handleBlockInputfieldChange} 
  className="w-1/3 py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
/>
  </div> */}  


       
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button   /* onClick={handleUpdateBlock}  */
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
           <Spinner/> 
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)} 

{CreateBlockPopup && (
  <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
  <div className="bg-white rounded-b-md shadow-md w-full md:w-1/2 h-3/4 md:h-2/3 flex flex-col">
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-4 md:px-6'>
      <h2 className="text-md">Create Block Details</h2>
      <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#f7525a] cursor-pointer " />
      </button>
    </div>
    <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
    <div className='p-20px mt-1 '>
   {/*  {errorMessages.length > 0 && (
                <div className="flex justify-between items-center mt-1 mb-1  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )} */}

<div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
    <label htmlFor="blockNo" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
     Block
    </label>
    <input
  type="text"
  id="blockNo"  
  value={blockNo}
  onChange={(e) => setBlockNo(e.target.value)}
  className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
  </div>

  <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
      <label htmlFor="numFloors" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        No of Floors
      </label>
      <input
        type="number"
        id="numFloors"
        value={numFloors}
        onChange={handleFloorsChange}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      />
    </div>

    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
      <label htmlFor="numFlatsPerFloor" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        Flats Per Floors
      </label>
      <input
        type="number"
        id="numFlatsPerFloor"
        value={numFlatsPerFloor}
        onChange={handleFlatsPerFloorChange}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      />
    </div>

    <div className="w-full flex flex-col md:flex-row items-start md:items-center mb-4 space-y-2 md:space-y-0 md:space-x-2 mt-5 px-4 md:px-6">
      <label htmlFor="numFlats" className="block text-sm font-regular text-[#212529] w-full md:w-1/6">
        No of Flats
      </label>
      <input
        type="number"
        id="numFlats"
        value={numFlats}
        onChange={handleTotalFlatsChange}
        className="w-full md:w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
      />
    </div>
    <div className="w-full flex justify-center items-center mt-2">
  {ErrorSaveBlock && (
    <p className="text-red-500 text-sm  px-4 py-2 rounded-md shadow-sm">
      {ErrorSaveBlock}
    </p>
  )}
</div>
       
</div>
    <div className="mt-auto w-full">
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button  onClick={handleSaveBlock} 
        className="px-2 py-1 bg-[#408dfb] bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"><span>Save</span> {isSaving && (
           <Spinner/> 
        )}</button>
        <button onClick={handleClosePopup} 
        className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
      </div>
    </div>
  </div>
</div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}

    </div>
  );
};
export default ViewVendor;