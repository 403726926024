import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faInfoCircle, faWrench,faArrowRight , faTimes, faDoorClosed, faSleigh, faDoorOpen, faRuler,faChevronUp, faChevronDown, faCheck  } from '@fortawesome/free-solid-svg-icons';
import DoorDetailsModal from './DoorDetailsModal';
import QuotationPDFModal from './QuotationPDF';
import ProductSelectionModal from './ProductSelectionModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  faPaintBrush,
  faMinus,
  faPlus,
  faArrowLeft,
  faHome,
  faTag,
  faHeadset,
  faChevronRight,

} from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FaDoorClosed } from 'react-icons/fa';
import LoaderModal from '../BBAdmin/Components/Loader';
import { BiRefresh } from 'react-icons/bi';




const DoorsQuote = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedHardware, setSelectedHardware] = useState({});
  const [showHardware, setShowHardware] = useState(true);
  const [isHardwareOpen, setIsHardwareOpen] = useState(false);
  const [selectedDoors, setSelectedDoors] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);

  const [quotation, setQuotation] = useState();
  const [isPdfModalVisible, setIsPdfModalVisible] = useState(false);
  const [isProductSelectionModalVisible, setIsProductSelectionModalVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [hasQuotationChanges, setHasQuotationChanges] = useState(true);
  const [UOMSData, setUOMSData] = useState([]);

  const BRICKBUCKET = process.env.REACT_APP_FORE_BRICKBUCKET || '';
const Measurit = process.env.REACT_APP_FORE_APILINK || '';
const BRICKBUCKETIMAGE = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES || '';

  const location= useLocation()
  const NavigatedData= location?.state || {}
  const project_id = NavigatedData.projectId || null;
  const projectName= NavigatedData.projectName || '';
  const product_category_id = NavigatedData.categoryId || null;
  const PackageData= NavigatedData.packages || []
  const [Data, setData] = useState(null);
  //console.log(Data)
  const [isCustomizeOpen, setIsCustomizeOpen] = useState({});
  const package_id = NavigatedData.packageId
  const [vendorChangeSubcategory,setVendorChangeSubcategory] = useState()
  const [vendorChangeVendor, setVendorChangeVendor] = useState()
  const [CustamisationOfSubcategoryAddons,setCustamisationOfSubcategoryAddons]= useState(false)
  const [hardwareData, setHardwareData] = useState([]);
  const [isSavingQuote, setIsSavingQuote] = useState(false);
  const [isPackageSwitching, setIsPackageSwitching] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isChangingVendor, setIsChangingVendor] = useState(false);
const [loadingCategories, setLoadingCategories] = useState({});
const [isPriceLoading, setIsPriceLoading] = useState(false);
const [isRefreshing, setIsRefreshing] = useState(false);
const [categorySpecificHardware, setCategorySpecificHardware] = useState({});
const [isModified, setIsModified] = useState(false)
const [showNoChangesModal, setShowNoChangesModal] = useState(false);
const [isHardwarePriceLoading, setIsHardwarePriceLoading] = useState(false);
const [totalPrice, setTotalPrice] = useState([]);
const [isCallbackModalVisible, setIsCallbackModalVisible] = useState(false);
const [isVendorPriceLoading, setIsVendorPriceLoading] = useState(false);




  useEffect(() => {
      const handleBeforeUnload = () => {
        if (!isPackageSwitching) {
        setIsRefreshing(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    };
    
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isPackageSwitching]);
    


  const [preEngHardware, setPreEngHardware] = useState(true);
const [onlyDoorsHardware, setOnlyDoorsHardware] = useState(true);
const [showTermsModal, setShowTermsModal] = useState(false);

  useEffect(() => {
    if (quotation) {
      // Deep comparison of current quotation with initial state
      const hasChanged = JSON.stringify(quotation.products) !== quotation.initial_state;
  
      // Set hasQuotationChanges to true when any change is detected
      if (hasChanged) {
        setHasQuotationChanges(true);
      }
    }
  }, [quotation]);
  


  const handleChangeCustomization = (categoryIndex, productIndex, doorsType) => {
    // Input validation
    if (typeof categoryIndex !== 'number' || typeof productIndex !== 'number' || !doorsType) {
      console.error('Invalid parameters provided to handleChangeCustomization');
      return;
    }
  
    // Create unique key with sanitized inputs
    const customizationKey = `${Math.max(0, categoryIndex)}-${Math.max(0, productIndex)}-${String(doorsType).trim()}`;
  
    setIsCustomizeOpen(prevState => {
      // Ensure prevState is an object
      const currentState = prevState || {};
      
      // Create new state with toggled value
      return {
        ...currentState,
        [customizationKey]: !currentState[customizationKey]
      };
    });
  };


  const handleUpdateVendorInAddonsSubcategory = (subcategory, vendor) => {
    setIsVendorPriceLoading(true); 

    // Validate input parameters
    if (!subcategory?.product_sub_category_id || !vendor?._id || !Data) {
      console.error('Invalid parameters or missing Data');
      return;
    }
  
    try {
      const updatedData = JSON.parse(JSON.stringify(Data));
  
      const calculateProductAddonPrice = (addon, area, count) => {
        if (!addon?.products || !Array.isArray(addon.products)) {
          return 0;
        }
  
        return addon.products.reduce((sum, p) => {
          if (!p?.default || !p.UOM_id || !p.selling_price) return sum;
  
          const UOM = UOMSData.find(uom => uom._id === p.UOM_id);
          const price = p.selling_price || 0;
          const multiplier = UOM?.UOM?.toLowerCase() === 'no' ? count : area;
  
          return sum + (price * (multiplier || 0));
        }, 0);
      };
  
      // Process room categories
      if (Array.isArray(updatedData.room_categories)) {
        updatedData.room_categories.forEach(category => {
          if (!category) return;
  
          let categoryAddonPrice = 0;
          const defaultProduct = category.products?.find(product => product?.default);
  
          if (defaultProduct?.doorAddOns) {
            let productAddonPrice = 0;
  
            defaultProduct.doorAddOns.forEach(addon => {
              if (addon.product_sub_category_id === subcategory.product_sub_category_id) {
                addon.products = addon.products.map(p => ({
                  ...p,
                  default: p.vendor_id === vendor._id && 
                          p.product_sub_category_id === subcategory.product_sub_category_id
                }));
              }
  
              const addonPrice = calculateProductAddonPrice(
                addon,
                category.room_category_area || 0,
                category.room_category_count || 0
              );
              productAddonPrice += addonPrice;
            });
  
            defaultProduct.addons_price = productAddonPrice;
            categoryAddonPrice += productAddonPrice;
          }
  
          category.room_category_addon_price = categoryAddonPrice;
        });
      }
  
      // Update vendor defaults safely
      if (Array.isArray(updatedData.door_addon_subcategory_vendors)) {
        updatedData.door_addon_subcategory_vendors = updatedData.door_addon_subcategory_vendors.map(sub => {
          if (sub?.product_sub_category_id === subcategory.product_sub_category_id) {
            return {
              ...sub,
              vendors: Array.isArray(sub.vendors) ? sub.vendors.map(v => ({
                ...v,
                default: v._id === vendor._id
              })) : []
            };
          }
          return sub;
        });
      }
  
      // Calculate totals with safety checks
      updatedData.total_addon_price = (updatedData.room_categories || []).reduce(
        (total, category) => total + (Number(category?.room_category_addon_price) || 0),
        0
      );
  
      const totalPrice = (updatedData.room_categories || []).reduce(
        (total, category) => total + 
          (Number(category?.room_category_price) || 0) + 
          (Number(category?.room_category_addon_price) || 0),
        0
      );
  
      updatedData.total_price = Math.round(totalPrice * 100) / 100;
  

      setTimeout(() => {
        setIsModified(true)
        setData(updatedData);
        setIsChangingVendor(false);
        setIsVendorPriceLoading(false);
      }, 2000);
    } catch (error) {
      console.error('Error updating vendor in addons subcategory:', error);
    }
  };
  
  
 /*  const handleVendorChange = (subcategory, vendor) => {

    // Validate input parameters
    if (!subcategory || !vendor) {
      console.warn('Invalid vendor or subcategory data provided');
      return;
    }
  
    // Ensure required properties exist
    const validSubcategory = {
      ...subcategory,
      product_sub_category_id: subcategory.product_sub_category_id || null,
      product_sub_category_name: subcategory.product_sub_category_name || ''
    };
  
    const validVendor = {
      ...vendor,
      _id: vendor._id || null,
      name: vendor.name || ''
    };
  
    // Update states with validated data
    setVendorChangeSubcategory(validSubcategory);
    setVendorChangeVendor(validVendor);
    setCustamisationOfSubcategoryAddons(true);
  }; */

  const HandleChangeAddonProduct = (addonProduct) => {
    try {
      // Input validation
      if (!addonProduct || !Data?.door_addon_subcategory_vendors) {
        console.error('Invalid input or missing data');
        return;
      }
  
      // Type checking and safe access
      const subcategories = Array.isArray(Data.door_addon_subcategory_vendors) 
        ? Data.door_addon_subcategory_vendors 
        : [];
  
      // Find subcategory with null check
      const subcategory = subcategories.find(p => 
        p?.product_sub_category_id === addonProduct.product_sub_category_id
      ) || {};
  
      // Find vendor with null check
      const vendor = Array.isArray(subcategory.vendors) 
        ? subcategory.vendors.find(p => p?._id === addonProduct.vendor_id)
        : {};
  
      // Create validated subcategory object with default values
      const validSubcategory = {
        ...subcategory,
        product_sub_category_id: subcategory?.product_sub_category_id || null,
        product_sub_category_name: typeof subcategory?.product_sub_category_name === 'string' 
          ? subcategory.product_sub_category_name 
          : ''
      };
  
      // Create validated vendor object with default values
      const validVendor = {
        ...vendor,
        _id: vendor?._id || null,
        name: typeof vendor?.name === 'string' ? vendor.name : ''
      };
  
      // State updates with validation
      if (validSubcategory.product_sub_category_id) {
        setVendorChangeSubcategory(validSubcategory);
      }
  
      if (validVendor._id) {
        setVendorChangeVendor(validVendor);
      }
  
      setCustamisationOfSubcategoryAddons(true);
  
    } catch (error) {
      console.error('Error in HandleChangeAddonProduct:', error);
      // Optionally reset states to default values
      setVendorChangeSubcategory({});
      setVendorChangeVendor({});
      setCustamisationOfSubcategoryAddons(false);
    }
  };
  
  
  const VendorChangeModal = ({ isVisible, onClose, subcategory, vendor }) => {
    // Validate and initialize props with defaults
    const validSubcategory = subcategory || { 
      product_sub_category_name: '',
      vendors: []
    };
    
    const [selectedVendor, setSelectedVendor] = useState(() => vendor || {});
    const [isLoading, setIsLoading] = useState(false);
   
   
    // Early return if modal is not visible
    if (!isVisible) return null;
  
    const handleVendorSelection = (v) => {
      if (!v?._id) return;
      setSelectedVendor(v);
    };
  
    const handleConfirm = async () => {
      if (!selectedVendor?._id || !validSubcategory) return;
      
      setIsLoading(true);
      setIsChangingVendor(true);

      try {

          await handleUpdateVendorInAddonsSubcategory(validSubcategory, selectedVendor);
           
    setTimeout(() => {
      setIsChangingVendor(false);
      onClose();
    }, 1000);

        onClose();
      } catch (error) {
        console.error('Error updating vendor:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const renderVendorImage = (vendor) => {
      if (!vendor) return null;
  
      if (vendor.image) {
        return (
          <img
            src={`${BRICKBUCKETIMAGE}${vendor.image}`}
            alt={vendor.name || 'Vendor'}
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'fallback-image-url';
            }}
          />
        );
      }
  
      return (
        <div className="w-full h-full bg-red-50 flex items-center justify-center">
          <span className="text-base font-bold text-[#EA011F]">
            {vendor.name?.[0]?.toUpperCase() || "V"}
          </span>
        </div>
      );
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg w-11/12 max-w-md p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-800">
              Change {validSubcategory.product_sub_category_name}
            </h3>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
  
          <div className="max-h-96 overflow-y-auto">
            {Array.isArray(validSubcategory.vendors) && validSubcategory.vendors.map((v) => (
              <button
                key={v._id || Math.random()}
                onClick={() => handleVendorSelection(v)}
                disabled={isLoading}
                className={`w-full flex items-center p-3 mb-2 rounded-lg border
                  ${v._id === selectedVendor._id ? 'border-[#EA011F] bg-red-50' : 'border-gray-200'}
                  ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:border-[#EA011F]'}
                `}
              >
                <div className="w-10 h-10 rounded-full overflow-hidden mr-3">
                  {renderVendorImage(v)}
                </div>
                <div className="flex-1">
                  <h4 className="text-sm font-medium text-gray-800">{v.name || 'Unnamed Vendor'}</h4>
                </div>
                {v._id === selectedVendor._id && (
                  <FontAwesomeIcon icon={faCheck} className="text-[#EA011F] ml-2" />
                )}
              </button>
            ))}
          </div>
  
          <div className="mt-6 flex gap-3">
            <button
              onClick={onClose}
              disabled={isLoading}
              className="flex-1 py-2.5 border border-gray-300 rounded-lg text-gray-700 font-medium
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              disabled={isLoading || !selectedVendor?._id}
              className="flex-1 py-2.5 bg-[#EA011F] text-white rounded-lg font-medium
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Updating...' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  const handleProductChange = (selectedProduct, category) => {
    // Input validation
    const categoryKey = category.room_category;
  setLoadingCategories(prev => ({...prev, [categoryKey]: true}));
  setIsPriceLoading(true); 

    if (!selectedProduct?._id || !category || !Data) {
      console.error('Invalid input parameters for product change');
      return;
    }
  
    try {
      const updatedData = JSON.parse(JSON.stringify(Data));
  
      // Validate room_categories exists
      if (!Array.isArray(updatedData.room_categories)) {
        throw new Error('Invalid room categories data structure');
      }
  
      const categoryIndex = updatedData.room_categories.findIndex(
        cat => cat?.room_category === category.room_category && 
               cat?.category === category.category
      );
  
      if (categoryIndex !== -1) {
        const currentCategory = updatedData.room_categories[categoryIndex];
  
        // Update products with safe array operations
        if (Array.isArray(currentCategory.products)) {
          currentCategory.products = currentCategory.products.map(product => ({
            ...product,
            default: product._id === selectedProduct._id
          }));
  
          const defaultProduct = currentCategory.products.find(p => p.default);
  
          if (defaultProduct) {
            // Safe price calculations
            const UOM = UOMSData.find(p => p._id === defaultProduct.UOM_id);
            const multiplier = UOM?.UOM?.toLowerCase() === 'sq.ft' 
              ? (category.room_category_area || 0)
              : (category.room_category_count || 0);
            
            const basePrice = (defaultProduct.selling_price || 0) * multiplier;
  
            // Calculate addon prices
            let addonPrice = 0;
            if (Array.isArray(defaultProduct.doorAddOns)) {
              defaultProduct.doorAddOns.forEach(addon => {
                if (!addon) return;
  
                const subcategoryData = Data.door_addon_subcategory_vendors?.find(
                  sub => sub.product_sub_category_id === addon.product_sub_category_id
                );
  
                const defaultVendor = subcategoryData?.vendors?.find(v => v.default);
  
                // Safe addon products update
                if (Array.isArray(addon.products)) {
                  addon.products = addon.products.map(p => ({
                    ...p,
                    default: p.vendor_id === defaultVendor?._id &&
                            p.product_sub_category_id === addon.product_sub_category_id
                  }));
  
                  // Calculate addon price safely
                  addonPrice += addon.products
                    .filter(p => p.default)
                    .reduce((sum, p) => {
                      const addonUOM = UOMSData.find(uom => uom._id === p.UOM_id);
                      const addonMultiplier = addonUOM?.UOM?.toLowerCase() === 'sq.ft'
                        ? (category.room_category_area || 0)
                        : (category.room_category_count || 0);
                      
                      return sum + ((p.selling_price || 0) * addonMultiplier);
                    }, 0);
                }
              });
            }
  
            // Update prices safely
            defaultProduct.addons_price = addonPrice;
            currentCategory.room_category_price = basePrice;
            currentCategory.room_category_addon_price = addonPrice;
          }
        }
  
        // Safe total price calculations
        const totalPrice = updatedData.room_categories.reduce(
          (total, cat) => total +
            (Number(cat?.room_category_price) || 0) +
            (Number(cat?.room_category_addon_price) || 0),
          0
        );
  
        updatedData.total_price = Math.round(totalPrice * 100) / 100;
        updatedData.total_addon_price = updatedData.room_categories.reduce(
          (sum, cat) => sum + (Number(cat?.room_category_addon_price) || 0),
          0
        );
      }
  
      setData(updatedData);
      setIsModified(true)
      setIsProductSelectionModalVisible(false);
      setTimeout(() => {
        setLoadingCategories(prev => ({...prev, [categoryKey]: false}));
        setIsPriceLoading(false); 

      }, 1000);
    } catch (error) {
      console.error('Error updating product:', error);
      // Optionally show user feedback
    }
  };
    
   
  const handlePackageSwitch = (pkg) => {
    setSelectedPackage(pkg);
    setIsPackageSwitching(true);
    // Validate package data
    if (!pkg || !pkg.package_id) {
      console.warn('Invalid package data provided');
      return;
    }
  
    // Create validated navigation state
    const navigationState = {
      ...NavigatedData,
      packageId: pkg.package_id,
      timestamp: Date.now() // Add timestamp to ensure state changes are tracked
    };
  
    try {
      setTimeout(() => {
      navigate(`/consumer/app/doorsQuote`, {
        state: navigationState,
        replace: true
      });
  
      // Store current state in sessionStorage as backup
      sessionStorage.setItem('lastPackageState', JSON.stringify(navigationState));
  
      // Reload page with safety timeout
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }, 1000);
  
    } catch (error) {
      console.error('Package switch failed:', error);
      // Attempt recovery from session storage if navigation fails
      const lastState = sessionStorage.getItem('lastPackageState');
      if (lastState) {
        navigate(`/consumer/app/doorsQuote`, {
          state: JSON.parse(lastState),
          replace: true
        });
      }
    }
  };
  
  const RefreshLoader = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
      <div className="p-8 rounded-lg flex flex-col items-center">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
        <div className="text-center">
          <p className="text-lg font-medium text-white mb-1">Refreshing</p>
          <h3 className="text-sm font-bold text-white">Please wait...</h3>
        </div>
      </div>
    </div>
  );


  const renderPackages = () => {
    const validPackages = Array.isArray(PackageData) ? PackageData : [];
  
    const formatPrice = (price) => {
      const numericPrice = Number(price);
      return isNaN(numericPrice) ? '0.0' : (numericPrice / 100000).toFixed(1);
    };
  return (
  <div className="p-4 mt-6 mb-16 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-sm">
    <div className="flex items-center gap-3 mb-6">
      <div className="bg-red-100 p-2 rounded-lg">
        <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
        </svg>
      </div>
      <h3 className="text-base font-bold text-gray-800">Explore More Options</h3>
    </div>

    <div className="grid grid-cols-2 gap-3">
      {validPackages
        .filter(pkg => pkg?.package_id !== package_id)
        .sort((a, b) => a.package_id - b.package_id) 
        .map(pkg => (
          <button
            key={pkg?._id || `package-${pkg?.package_id}`}
            className="group relative flex flex-col p-5 bg-white hover:bg-red-50 border-2 border-gray-100 hover:border-[#EA011F] rounded-xl transition-all duration-300 transform hover:-translate-y-1 hover:shadow-md"
            onClick={() => pkg?.package_id && handlePackageSwitch(pkg)}
            disabled={!pkg?.package_id}
          >
            <div className="flex flex-col gap-3">
              <div className="flex items-start gap-2">
                <div className="p-1.5 bg-red-50 rounded-md flex-shrink-0">
                  <svg className="w-4 h-4 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
                  </svg>
                </div>
                <span className="text-lg font-bold text-gray-800 group-hover:text-[#EA011F]">
                  {pkg?.package_name || 'Unnamed Package'}
                </span>
              </div>
              
              <div className="flex items-baseline gap-2">
                <span className="text-base font-bold text-[#EA011F]">
                  ₹{formatPrice(pkg?.total_price)}L
                </span>
                <svg className="w-4 h-4 text-gray-400 group-hover:text-[#EA011F] group-hover:translate-x-1 transition-all" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                </svg>
              </div>
            </div>
          </button>
        ))}
    </div>
  </div>
);

  };
  

  const fetchUOMs = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // 30s timeout
  
    try {
      setIsLoading(true);
  
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'x-token': token
      };
  
      const response = await fetch(`${BRICKBUCKET}UOMs/get-all-UOMs`, {
        method: 'GET',
        headers,
        signal: controller.signal
      });
  
      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorBody}`);
      }
  
      const uomsData = await response.json();
  
      // Enhanced data validation
      if (!uomsData?.data?.length) {
        throw new Error('No UOM data available');
      }
  
      // Validate data structure
      const isValidData = uomsData.data.every(uom => 
        uom._id && 
        typeof uom.UOM === 'string' && 
        uom.UOM.length > 0
      );
  
      if (!isValidData) {
        throw new Error('Invalid UOM data structure');
      }
  
      return uomsData;
  
    } catch (error) {
      const errorMessages = {
        'AbortError': 'Request timeout - please try again',
        'TypeError': 'Network connection error',
        'Invalid UOM data structure': 'Data format error',
        'No UOM data available': 'No data available'
      };
  
      const errorMessage = errorMessages[error.name] || 
                          errorMessages[error.message] || 
                          'Unable to fetch UOM data';
  
      // Custom error notification
      window.alert(errorMessage);
      
      // Detailed error logging
      console.error('UOM Fetch Error:', {
        timestamp: new Date().toISOString(),
        message: error.message,
        name: error.name,
        stack: error.stack,
        endpoint: `${BRICKBUCKET}UOMs/get-all-UOMs`
      });
  
      return null;
  
    } finally {
      clearTimeout(timeoutId);
      setIsLoading(false);
    }
  };
  
  
  const fetchDoorQuotationData = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);
  
    try {
      // Frontend validation
      const requiredParams = {
        product_category_id,
        project_id,
        package_id
      };
  
      const missingParams = Object.entries(requiredParams)
        .filter(([_, value]) => !value)
        .map(([key]) => key);
  
      if (missingParams.length > 0) {
        console.error('[Frontend Error] Missing parameters:', missingParams.join(', '));
        return null;
      }
  
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('[Frontend Error] Authentication token missing');
        return null;
      }
  
      const url = new URL(`${Measurit}/summary/package-details`);
      url.searchParams.append('product_category_id', product_category_id);
      url.searchParams.append('project_id', project_id);
      url.searchParams.append('package_id', package_id);
  
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
          'Cache-Control': 'no-cache'
        },
        signal: controller.signal
      });
  
      // Backend error handling
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('[Backend Error]', {
          status: response.status,
          statusText: response.statusText,
          error: errorData?.message || 'Unknown server error'
        });
        return null;
      }
  
      const doorQuotationData = await response.json();
  
      // Backend data validation
      if (!doorQuotationData?.data) {
        console.error('[Backend Error] Invalid response format:', doorQuotationData);
        return null;
      }
  
      // Frontend data structure validation
      const isValidStructure = doorQuotationData.data.room_categories?.every(category => 
        category.room_category &&
        Array.isArray(category.products)
      );
  
      if (!isValidStructure) {
        console.error('[Frontend Error] Invalid data structure in received data');
        return null;
      }
     //console.log(doorQuotationData.data)
      return doorQuotationData.data;
  
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('[Frontend Error] Request timeout');
      } else if (!navigator.onLine) {
        console.error('[Frontend Error] Network connection lost');
      } else {
        console.error('[Frontend Error] Unexpected error:', error.message);
      }
      return null;
  
    } finally {
      clearTimeout(timeoutId);
    }
  };
  
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      
      try {
        // Parallel data fetching for better performance
        const [uomsResult, doorQuotationResult, hardwareResult] = await Promise.all([
          fetchUOMs(),
          fetchDoorQuotationData(),
          fetchHardwareData()
        ]);
  
        // Handle UOMs data
        if (uomsResult?.data?.length > 0) {
          //console.log('[Frontend] UOMs data loaded successfully');
          setUOMSData(uomsResult.data);
        } else {
          console.error('[Frontend] Invalid UOMs data structure');
        }
  
        // Handle Door Quotation data
        if (doorQuotationResult) {
          //console.log('[Frontend] Door quotation data loaded successfully');
          setData(doorQuotationResult);
        } else {
          console.error('[Frontend] Failed to load door quotation data');
        }
  
        // Handle Hardware data
        if (hardwareResult) {
          //console.log('[Frontend] Hardware data loaded successfully');
          setHardwareData(hardwareResult);
        } else {
          console.error('[Frontend] Failed to load hardware data');
        }
  
      } catch (error) {
        console.error('[Frontend] Data loading error:', error.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    loadData();
  
    // Cleanup function
    return () => {
      setUOMSData([]);
      setData(null);
      setHardwareData([]);
    };
  }, []);
  

  const fetchHardwareData = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);
  
    try {
      // Frontend validation
      if (!product_category_id) {
        console.error('[Frontend Error] Missing product_category_id parameter');
        return [];
      }
  
      const token = sessionStorage.getItem('token');
      const url = `${BRICKBUCKET}product/get-door-hardware?product_category_id=${product_category_id}`;
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
          'Cache-Control': 'no-cache'
        },
        signal: controller.signal
      });
  
      if (!response.ok) {
        console.error('[Backend Error]', {
          status: response.status,
          statusText: response.statusText
        });
        return [];
      }
  
      const result = await response.json();
  
      // Backend success validation
      if (result.success && Array.isArray(result.data)) {
       // console.log(' Hardware data fetched successfully');
        return result.data;
      }
  
      console.error('[Backend Error] Invalid data format received');
      return [];
  
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('[Frontend Error] Hardware fetch timeout');
      } else if (!navigator.onLine) {
        console.error('[Frontend Error] Network connection lost');
      } else {
        console.error('[Frontend Error] Hardware fetch failed:', error.message);
      }
      return [];
  
    } finally {
      clearTimeout(timeoutId);
    }
  };
  

  
  const prepareQuotationData = async () => {
    try {
      // Frontend validation
      if (!Data?.room_categories || !Array.isArray(Data.room_categories)) {
        console.error('[Frontend Error] Invalid room categories data');
        return null;
      }
  
      const items = Data.room_categories.map(category => {
        // Validate category data
        if (!category?.products) {
          console.error('[Frontend Error] Invalid category data structure');
          return null;
        }
  
        const defaultProduct = category.products.find(p => p?.default);
        if (!defaultProduct) {
          console.error('[Frontend Error] No default product found for category');
          return null;
        }
  
        const hardwareForCategory = selectedHardware[`${category.category}-${category.room_category}`] || {};
        
        // UOM calculations with validation
        const UOM = UOMSData.find(p => p?.UOM?.toLowerCase() === 'sq.ft');
        if (!UOM?._id) {
          console.error('[Frontend Error] Square feet UOM not found');
          return null;
        }
  
        const quantity = defaultProduct.UOM_id === UOM._id
          ? Number(category.room_category_area) || 0
          : Number(category.room_category_count) || 0;
  
        // Tax calculations with validation
        const taxAmount = ((Number(defaultProduct.GST) || 0) / 100) * 
                         ((Number(defaultProduct.selling_price) || 0) * quantity);
  
        // Addons calculations with validation
        const addonsCalculations = calculateAddonsTotal(defaultProduct, quantity);
        
        // Hardware calculations with validation
        const hardwareCalculations = calculateHardwareTotal(
          hardwareForCategory, 
          category.room_category_count,
          category.category
        );
  
        // Order breakup preparation with validation
        const requiredOrderBreakup = prepareOrderBreakup(
          defaultProduct,
          hardwareForCategory,
          category,
          quantity,
          UOMSData,
          category.category
        );
  
        return {
          item_id: defaultProduct._id,
          item_name: defaultProduct.product_name || 'Unnamed Product',
          quantity,
          selling_price: Number(defaultProduct.selling_price) || 0,
          cost_price: Number(defaultProduct.cost_price) || 0,
          tax_type: 'GST',
          tax_rate: Number(defaultProduct.GST) || 0,
          total_tax_amount: taxAmount,
          UOM: UOMSData.find(p => p._id === defaultProduct.UOM_id)?.UOM || 'N/A',
          UOM_id: defaultProduct.UOM_id,
          total: (Number(defaultProduct.selling_price) || 0) * quantity,
          addons_total: addonsCalculations.total,
          addons_tax: addonsCalculations.tax,
          hardware_total: hardwareCalculations.total,
          hardware_tax: hardwareCalculations.tax,
          required_order_breakup: requiredOrderBreakup,
          area_types: {
            area_name: category.room_category || 'Unknown Area',
            area: String(category.room_category_area || 0),
            count: String(category.room_category_count || 0)
          },
          applied_areas_breakup: category?.applied_doors || []
        };
      }).filter(Boolean); // Remove null items
  
      // Calculate totals with validation
      const subtotal = calculateSubtotal(items, showHardware);
      const totalTaxAmount = calculateTotalTax(items, showHardware);
  
      const organizationId = await sessionStorage.getItem('organizationId');
      const customerId = await sessionStorage.getItem('customerId');
  
      if (!customerId || !organizationId) {
        console.error('[Frontend Error] Missing customer or organization ID');
        return null;
      }
  
      return {
        quotation_type: 'Group',
        items,
        subtotal,
        tax_amount: totalTaxAmount,
        total: subtotal + totalTaxAmount,
        tax_prefrence: 'Tax Inclusive',
        customer_id: customerId,
        project_id,
        product_category_id,
        organizationId,
        discount: 0,
        adjustment: 0,
        quotation_date: new Date(),
        status: 'Draft'
      };
  
    } catch (error) {
      console.error('[Frontend Error] Error preparing quotation data:', error);
      return null;
    }
  };

  const prepareOrderBreakup = (defaultProduct, hardwareForCategory, category, quantity, UOMSData, doorType) => {
    const addonBreakup = defaultProduct.doorAddOns?.flatMap(addon =>
      addon.products.filter(p => p.default).map(p => ({
        sub_item_name: p.product_name || 'Unnamed Item',
        sub_item_id: p._id,
        sub_item_quantity: quantity,
        selling_price: Number(p.selling_price) || 0,
        cost_price: Number(p.cost_price) || 0,
        UOM: UOMSData.find(uom => uom._id === p.UOM_id)?.UOM || 'N/A',
        UOM_id: p.UOM_id,
        sub_item_image: p.product_image,
        tax_type: 'GST',
        tax_amount: ((Number(p.GST) || 0) / 100) * ((Number(p.selling_price) || 0) * quantity),
        tax_rate: Number(p.GST) || 0,
      }))
    ) || [];
  
    // Check hardware based on door type
    const isHardwareEnabled = doorType === 'pre eng doors' ? preEngHardware : onlyDoorsHardware;
  
    const hardwareBreakup = isHardwareEnabled && Object.keys(hardwareForCategory).length > 0
      ? Object.values(hardwareForCategory)
        .filter(hardware => hardware?.product_name)
        .map(hardware => ({
          sub_item_name: hardware.product_name,
          sub_item_id: hardware._id,
          sub_item_quantity: Number(category.room_category_count) || 0,
          selling_price: Number(hardware.selling_price) || 0,
          cost_price: Number(hardware.cost_price) || 0,
          UOM: UOMSData.find(p => p._id === hardware.UOM_id)?.UOM || 'N/A',
          UOM_id: hardware.UOM_id,
          sub_item_image: hardware.product_image,
          tax_type: 'GST',
          tax_amount: hardware.GST
            ? ((Number(hardware.GST) || 0) / 100) * ((Number(hardware.selling_price) || 0) * category.room_category_count * (hardware.quantity || 1))
            : 0,
          tax_rate: Number(hardware.GST) || 0,
          quantity: hardware.quantity || 1
        }))
      : [];
  
    return [...addonBreakup, ...hardwareBreakup];
  };
  
  
  // Helper functions for cleaner code
  const calculateAddonsTotal = (defaultProduct, quantity) => {
    if (!defaultProduct?.doorAddOns) return { total: 0, tax: 0 };
    
    return defaultProduct.doorAddOns.reduce((acc, addon) => {
      const addonValues = (addon.products || [])
        .filter(p => p?.default)
        .reduce((sum, p) => {
          const amount = (Number(p.selling_price) || 0) * quantity;
          const tax = ((Number(p.GST) || 0) / 100) * amount;
          return {
            total: sum.total + amount,
            tax: sum.tax + tax
          };
        }, { total: 0, tax: 0 });
      
      return {
        total: acc.total + addonValues.total,
        tax: acc.tax + addonValues.tax
      };
    }, { total: 0, tax: 0 });
  };
  
  const calculateHardwareTotal = (hardwareForCategory, count, doorType) => {
    const isHardwareEnabled = doorType === 'pre eng doors' ? preEngHardware : onlyDoorsHardware;

    if (!isHardwareEnabled  || !Object.keys(hardwareForCategory).length) {
      return { total: 0, tax: 0 };
    }
  
    return Object.values(hardwareForCategory).reduce((acc, hardware) => {
      if (hardware?.selling_price) {
        const amount = (Number(hardware.selling_price) || 0) * (Number(count) || 0) * (Number(hardware.quantity) || 1);
        const tax = hardware.GST ? ((Number(hardware.GST) || 0) / 100) * amount : 0;
        return {
          total: acc.total + amount,
          tax: acc.tax + tax
        };
      }
      return acc;
    }, { total: 0, tax: 0 });
  };
  
/*   const calculateSubtotal = (items, showHardware) => {
    return items.reduce((sum, item) => 
      sum + (Number(item.total) || 0) + 
      (Number(item.addons_total) || 0) + 
      (showHardware ? (Number(item.hardware_total) || 0) : 0), 
    0);
  };
  
  const calculateTotalTax = (items, showHardware) => {
    return items.reduce((sum, item) => 
      sum + (Number(item.total_tax_amount) || 0) + 
      (Number(item.addons_tax) || 0) + 
      (showHardware ? (Number(item.hardware_tax) || 0) : 0), 
    0);
  }; */
  
 
  const calculateSubtotal = (items) => {
    return items.reduce((sum, item) => {
      const isPreEngDoor = item.doorType === 'pre eng doors';
      const baseTotal = (Number(item.total) || 0) + (Number(item.addons_total) || 0);
      const hardwareTotal = isPreEngDoor && preEngHardware ? (Number(item.hardware_total) || 0) :
                           !isPreEngDoor && onlyDoorsHardware ? (Number(item.hardware_total) || 0) : 0;
      
      return sum + baseTotal + hardwareTotal;
    }, 0);
  };
  
  const calculateTotalTax = (items) => {
    return items.reduce((sum, item) => {
      const isPreEngDoor = item.doorType === 'pre eng doors';
      const baseTax = (Number(item.total_tax_amount) || 0) + (Number(item.addons_tax) || 0);
      const hardwareTax = isPreEngDoor && preEngHardware ? (Number(item.hardware_tax) || 0) :
                         !isPreEngDoor && onlyDoorsHardware ? (Number(item.hardware_tax) || 0) : 0;
      
      return sum + baseTax + hardwareTax;
    }, 0);
  };
  


  const handleSave = async () => {
    setIsSavingQuote(true);
    const token = sessionStorage.getItem('token');
  
    if (!token) {
      console.error('[Frontend Error] Authentication token missing');
      setIsSavingQuote(false);
      return;
    }
  
    try {
      const quotationData = await prepareQuotationData(Data, selectedHardware);
      
      if (!quotationData?.items?.length) {
        throw new Error('Invalid quotation data structure');
      }
  
      const response = await fetch(`${BRICKBUCKET}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
          'Cache-Control': 'no-cache'
        },
        body: JSON.stringify(quotationData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (!data?.success || !data?.data?._id) {
        throw new Error('Invalid response data');
      }
  
      await navigateToQuoteSummary(data.data._id , quotationData);
  
    } catch (error) {
      console.error('[Frontend Error]:', error);
      window.alert('Failed to save quotation. Please try again.');
    } finally {
      setIsSavingQuote(false);
    }
  };

  
  const navigateToQuoteSummary = (quotationId) => {
 
   const navigationData = {
      quotation_id: quotationId,
      product_category_id: product_category_id || '',
      project_id: project_id || '',
      package_id: package_id || '',
      product_category_name : 'Doors',
      projectName: projectName,
      timestamp: Date.now()
    }; 
   
    // Using Promise to handle navigation timing
     return new Promise((resolve) => {
      setTimeout(() => {
       
        navigate('/consumer/app/summarydetails', {
          state: navigationData,
          replace: true
        });
        resolve();
      }, 2000);
    }).catch(error => {
      console.error('Navigation error:', error);
      navigate('/consumer/app/summarydetails');
    }); 
  };

  const roundPrice = (price) => {
    const beforeDecimal = Math.floor(price);
    const afterDecimal = price - beforeDecimal;
    
    if (afterDecimal < 0.5) {
      return beforeDecimal;
    } else {
      return beforeDecimal + 1;
    }
  };
  
  
  



/* const handleHardwareSelection = (categoryIndex, productIndex, doorsType, groupName, selectedOption, room_category) => {
  const uniqueKey = `${doorsType}-${room_category}`;
  const updatedHardware = { ...selectedHardware };

  // Initialize hardware category if not exists
  if (!updatedHardware[uniqueKey]) {
    updatedHardware[uniqueKey] = {
      totalPrice: 0
    };
  }

  // Handle quantity updates
  if (selectedOption.quantity !== undefined) {
    // Update quantity for existing selection
    if (selectedOption.quantity === 0) {
      // Remove item if quantity is 0
      delete updatedHardware[uniqueKey][groupName];
    } else {
      // Update with new quantity
      updatedHardware[uniqueKey][groupName] = {
        ...selectedOption,
        quantity: selectedOption.quantity
      };
    }
  } else {
    // New selection
    updatedHardware[uniqueKey][groupName] = {
      ...selectedOption,
      quantity: 1
    };
  }

  // Find matching category and get door count
  const category = Data.room_categories.find(cat =>
    cat.category === doorsType &&
    cat.room_category === room_category
  );
  const doorCount = Number(category?.room_category_count) || 0;

  // Calculate category hardware price with quantities
  const categoryHardwarePrice = Object.values(updatedHardware[uniqueKey])
    .reduce((total, hardware) => {
      if (hardware?.selling_price && hardware?.quantity) {
        return total + (Number(hardware.selling_price) * hardware.quantity * doorCount);
      }
      return total;
    }, 0);

  updatedHardware[uniqueKey].totalPrice = categoryHardwarePrice;

  // Update category data
  const updatedData = { ...Data };
  const targetCategoryIndex = updatedData.room_categories.findIndex(cat =>
    cat.category === doorsType &&
    cat.room_category === room_category
  );

  if (targetCategoryIndex !== -1) {
    updatedData.room_categories[targetCategoryIndex].hardware_price = categoryHardwarePrice;
  }

  // Calculate total hardware price
  const totalHardwarePrice = Object.entries(updatedHardware)
    .reduce((total, [key, value]) => {
      return total + (Number(value.totalPrice) || 0);
    }, 0);

  updatedData.total_hardware_price = totalHardwarePrice;

  setSelectedHardware(updatedHardware);
  setData(updatedData);
  //console.log(updatedHardware)
}; */

const handleHardwareSelection = (categoryIndex, productIndex, doorsType, groupName, selectedOption, room_category) => {
  setIsHardwarePriceLoading(true);
  const uniqueKey = `${doorsType}-${room_category}`;
  const updatedHardware = { ...selectedHardware };

  // Initialize hardware category
  if (!updatedHardware[uniqueKey]) {
    updatedHardware[uniqueKey] = {
      totalPrice: 0,
      doorType: doorsType // Track door type for each hardware selection
    };
  }

  // Handle quantity updates
  if (selectedOption.quantity !== undefined) {
    if (selectedOption.quantity === 0) {
      delete updatedHardware[uniqueKey][groupName];
    } else {
      updatedHardware[uniqueKey][groupName] = {
        ...selectedOption,
        quantity: selectedOption.quantity,
        doorType: doorsType
      };
    }
  } else {
    updatedHardware[uniqueKey][groupName] = {
      ...selectedOption,
      quantity: 1,
      doorType: doorsType
    };
  }

  // Find category and door count
  const category = Data.room_categories.find(cat =>
    cat.category === doorsType &&
    cat.room_category === room_category
  );
  const doorCount = Number(category?.room_category_count) || 0;

  // Calculate category hardware price
  const categoryHardwarePrice = Object.values(updatedHardware[uniqueKey])
    .reduce((total, hardware) => {
      if (hardware?.selling_price && hardware?.quantity) {
        return total + (Number(hardware.selling_price) * hardware.quantity * doorCount);
      }
      return total;
    }, 0);

  updatedHardware[uniqueKey].totalPrice = categoryHardwarePrice;

  // Update category data
  const updatedData = { ...Data };
  const targetCategoryIndex = updatedData.room_categories.findIndex(cat =>
    cat.category === doorsType &&
    cat.room_category === room_category
  );

  if (targetCategoryIndex !== -1) {
    updatedData.room_categories[targetCategoryIndex].hardware_price = categoryHardwarePrice;
  }

  // Calculate separate totals for each door type
  const preEngTotal = Object.entries(updatedHardware)
    .reduce((total, [key, value]) => {
      if (value.doorType === 'pre eng doors') {
        return total + (Number(value.totalPrice) || 0);
      }
      return total;
    }, 0);

  const onlyDoorsTotal = Object.entries(updatedHardware)
    .reduce((total, [key, value]) => {
      if (value.doorType !== 'pre eng doors') {
        return total + (Number(value.totalPrice) || 0);
      }
      return total;
    }, 0);

  // Update data with separate hardware totals
  updatedData.pre_eng_hardware_price = preEngTotal;
  updatedData.only_doors_hardware_price = onlyDoorsTotal;
  updatedData.total_hardware_price = preEngTotal + onlyDoorsTotal;

  setTimeout(() => {
  setSelectedHardware(updatedHardware);
  setIsModified(true)
  setData(updatedData);
  setIsHardwarePriceLoading(false);
  }, 1000);
  console.log('updatedHardware', updatedHardware)
  console.log('updatedData', updatedData)
};


/* const renderHeader = () => {
  return (
    <div className="bg-white p-3 shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between mb-2 relative">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <FontAwesomeIcon icon={faArrowLeft} className="text-[#736f70] text-2xl" />
        </button>
        <div className="flex-1 text-center">
          <h1 className="text-xl font-bold text-[#EA011F]">{projectName}</h1>
        </div>
        <button onClick={() => navigate('/consumer/app/home')} className="absolute right-0">
          <FontAwesomeIcon icon={faHome} className="text-[#736f70] text-2xl" />
        </button>
      </div>

      <div className="flex items-center justify-between bg-red-50 p-3 rounded-lg">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faTag} className="text-[#EA011F] text-sm" />
          <span className="text-sm font-bold text-gray-800">Doors</span>
        </div>

        <div className="flex flex-col items-center">
          <span className="text-sm font-semibold text-[#EA011F]">{Data?.package_name}</span>
          <span className="text-xs font-medium text-gray-500">Option</span>
        </div>

        <button 
          className="px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
         
        >
          <span className="text-sm font-medium text-[#EA011F]">Quick View</span>
        </button>
      </div>
    </div>
  );
}; */


const renderHeader = () => (
  <div className="bg-white sticky top-0 z-50 border-b border-gray-200">
    <div className="h-1 bg-gradient-to-r from-[#EA011F] via-red-400 to-[#EA011F]"></div>
    <div className="px-4 py-3">
      <div className="flex items-center justify-between gap-3">
        {/* Back Button */}
        <div className="flex-shrink-0">
          <button
            onClick={() => navigate(-1)}
            className="w-9 h-9 flex items-center justify-center bg-gradient-to-br from-red-50 to-white rounded-xl hover:shadow-md transition-all duration-300 group"
          >
            <FontAwesomeIcon 
              icon={faArrowLeft} 
              className="text-gray-600 group-hover:text-[#EA011F] transition-colors" 
            />
          </button>
        </div>

        {/* Project Name and Category Container */}
        <div className="flex-1 flex flex-col items-center">
          <h1 className="text-lg font-bold text-gray-800 truncate w-full text-center mb-0.5">
            {projectName}
          </h1>
          <div className="flex items-center gap-1.5">
            <div className="w-1 h-1 rounded-full bg-[#EA011F]"></div>
            <span className="text-xs font-medium text-gray-500">Doors</span>
            <div className="w-1 h-1 rounded-full bg-[#EA011F]"></div>
          </div>
        </div>

        {/* Home Button */}
        <div className="flex-shrink-0">
          <button
            onClick={() => navigate('/consumer/app/home')}
            className="w-9 h-9 flex items-center justify-center bg-gradient-to-br from-red-50 to-white rounded-xl hover:shadow-md transition-all duration-300 group"
          >
            <FontAwesomeIcon 
              icon={faHome} 
              className="text-gray-600 group-hover:text-[#EA011F] transition-colors" 
            />
          </button>
        </div>
      </div>
    </div>
  </div>
);



const renderFloatingButtons = () => {
  const totalPrice = (Data?.total_price || 0) +
    (preEngHardware ? (Data?.pre_eng_hardware_price || 0) : 0) +
    (onlyDoorsHardware ? (Data?.only_doors_hardware_price || 0) : 0);

    return (
      <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 shadow-xl z-50">
      <div className="px-4 py-3">
        <div className="flex items-center justify-between w-full gap-3">
          {/* Package/Quote Status Display - 30% width */}
          <div className="w-[30%] h-[48px] flex items-center">
            {isModified ? (
              <div className="relative group">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-red-50 to-white px-4 py-2 rounded-lg border-l-4 border-[#EA011F]">
                    <span className="text-sm font-semibold text-[#EA011F]">Customized Quote</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative group">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-gray-50 to-white px-4 py-2 rounded-lg border-l-4 border-green-500">
                    <span className="text-sm font-semibold text-gray-700">{Data?.package_name} Quote</span>
                  </div>
                </div>
              </div>
            )}
          </div>
   
          {/* Proceed Button */}
          <button
  onClick={() => {
    if (isModified) {
      setIsSavingQuote(true);
      handleSave();
    } else {
      setIsSavingQuote(true);
      navigateToQuoteSummary('')
      
    }
  }}
  disabled={isSavingQuote}
  className="w-[70%] h-[48px] rounded-xl bg-gradient-to-r from-red-500 to-red-600 hover:shadow-lg transition-all duration-300"
>
  <div className="flex items-center justify-between px-6 py-2">
    <span className="text-lg font-bold text-white">
      {(isPriceLoading || isHardwarePriceLoading || isVendorPriceLoading) ? (
        <div className="w-4 h-4 border-2 border-gray-300 border-t-[#EA011F] rounded-full animate-spin"/>
      ) : (
        `₹${totalPrice.toLocaleString('en-IN')}`
      )}
    </span>
    <div className="flex items-center gap-2">
      <span className="text-lg font-semibold text-white">
        {isSavingQuote ? 'Saving...' : 'Proceed'}
      </span>
      <FontAwesomeIcon
        icon={faChevronRight}
        className="text-white text-xs"
      />
    </div>
  </div>
</button>

        </div>
      </div>
    </div>
  );
};

  
  const renderCategoryCard = (category, categoryIndex, doorsType) => {
    const showHardwareForCategory = doorsType === 'pre eng doors' ? preEngHardware : onlyDoorsHardware;
   // console.log(category, categoryIndex, doorsType)
    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 2
      }).format(price);
    };
  
    const totalCategoryPrice = (category?.room_category_price || 0) + 
                             (category?.room_category_addon_price || 0);
  
                             return (
                              <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
                                <div className="p-2 border-b border-gray-100">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                      <FontAwesomeIcon icon={faDoorOpen} className="text-[#EA011F] text-sm sm:text-base" />
                                      <span className="text-lg font-semibold text-[#EA011F]">
                                        {category?.room_category || "Unnamed Category"}
                                      </span>
                                    </div>
                                    <div className="flex items-center gap-3">
                                      <button
                                        className="flex items-center gap-3"
                                        onClick={() => {
                                          setSelectedDoors({
                                            doors: category?.applied_doors || [],
                                            categoryName: category?.room_category || "Unknown"
                                          });
                                          setIsDetailsModalVisible(true);
                                        }}
                                      >
                                        <div className="flex flex-col mr-2">
                                          <span className="text-sm font-medium text-gray-600">
                                            {category.room_category_area} sq.ft
                                          </span>
                                          <span className="text-xs text-gray-500">
                                            {category.room_category_count} Doors
                                          </span>
                                        </div>
                                        <svg
                                          className="w-4 h-4 text-[#EA011F] group-hover:translate-x-1 transition-transform self-center"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                        >
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                            
                                {loadingCategories[category.room_category] ? (
                                  <div className="mt-1 bg-gray-50 rounded-lg p-3 shadow-sm flex justify-center items-center h-24">
                                    <div className="w-8 h-8 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin"></div>
                                  </div>
                                ) : (
                                  <div>
                                    {category?.products?.filter(product => product?.default).map((product, productIndex) => (
                                      <div key={product?._id || `${categoryIndex}-${productIndex}`} className="p-2">{console.log(product)}
                                        <div className="flex justify-between items-center mb-2">
                                          <div className="flex items-center gap-3">
                                            {product.vendor?.image && (
                                              <div className="w-8 h-8 rounded-full overflow-hidden">
                                                <img
                                                  src={`${BRICKBUCKETIMAGE}${product.vendor.image}`}
                                                  alt={product.vendor.company_name}
                                                  className="w-full h-full object-cover"
                                                />
                                              </div>
                                            )}
                                            <span className="text-base font-medium text-gray-800">
                                              {product?.product_name || "Unnamed Product"}
                                            </span>
                                          </div>
                                          <button
                                            onClick={() => {
                                              setCurrentCategory(category);
                                              setIsProductSelectionModalVisible(true);
                                            }}
                                            className="px-2 py-0 border border-[#EA011F] rounded-full"
                                          >
                                            <span className="text-[#EA011F] text-sm font-regular">Customize</span>
                                          </button>
                                        </div>
                            
                                        <div className="mt-1 bg-gray-50 rounded-lg p-3">
                                          <div className="grid grid-cols-3 gap-4">
                                            <div className="flex flex-col text-center">
                                              <span className="text-xs text-gray-500 mb-1">
                                                {UOMSData.find(uom => uom._id === product?.UOM_id)?.UOM.toLowerCase() === 'sq.ft' ? 'Area' : 'Quantity'}
                                              </span>
                                              <p className="text-sm font-semibold text-gray-700">
                                                {UOMSData.find(uom => uom._id === product?.UOM_id)?.UOM.toLowerCase() === 'sq.ft'
                                                  ? `${category?.room_category_area?.toFixed(2) || 0} Sq.ft`
                                                  : `${category?.room_category_count || 0} Doors`
                                                }
                                              </p>
                                            </div>
                            
                                            <div className="flex flex-col text-center">
                                              <span className="text-xs text-gray-500 mb-1">Unit Price</span>
                                              <p className="text-sm font-medium text-gray-700">
                                                ₹{product?.selling_price || 0}
                                                <span className="text-gray-500 text-xs"> per {UOMSData.find(uom => uom._id === product?.UOM_id)?.UOM || 'unit'}</span>
                                              </p>
                                            </div>
                            
                                            <div className="flex flex-col text-right">
                                              <span className="text-xs text-gray-500 mb-1">Price</span>
                                              <p className="text-base font-bold text-green-700">
                                                ₹{totalCategoryPrice.toLocaleString('en-IN')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                            
                                        {renderAddOns(product, categoryIndex, productIndex, doorsType, category)}
                                        {/* {renderHardware(product, categoryIndex, productIndex, doorsType, category.room_category)} */}
                                        {(doorsType?.toLowerCase() === 'pre eng doors' && preEngHardware) || 
                                          (doorsType?.toLowerCase() !== 'pre eng doors' && onlyDoorsHardware) ? 
                                              renderHardware(product, categoryIndex, productIndex, doorsType, category.room_category) 
                                              : null
                                          }

                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            );
                            
  };
   


  const renderAddOns = (product, categoryIndex, productIndex, doorsType) => {
    if (!product?.doorAddOns?.length) return null;
  
    const isOpen = isCustomizeOpen[`${categoryIndex}-${productIndex}-${doorsType}`];
    
    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 2
      }).format(price);
    };
  
    return (
      <div className="mt-3 border-t border-gray-100">
        <button
          onClick={() => handleChangeCustomization(categoryIndex, productIndex, doorsType)}
          className="flex items-center justify-between w-full py-3 hover:bg-gray-50 
                     transition-colors duration-200"
        >
          <div className="flex items-center">
            <div className="w-6 h-6 bg-red-50 rounded-full flex items-center 
                           justify-center">
              <FontAwesomeIcon icon={faPaintBrush} size="xs" color="#EA011F" />
            </div>
            <span className="ml-2 text-base font-medium text-gray-800">
            Specifications

            </span>
          </div>
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            size="sm"
            className="text-gray-400 transition-transform duration-200"
          />
        </button>
  
        {isOpen && (
          <div className="space-y-3 pt-2">
            {product.doorAddOns.map((addOn, addonIndex) => {
              const selectedProduct = addOn.products?.find(p => p?.default);
              return (
                <div 
                  key={addOn.id || `addon-${addonIndex}`}
                  className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 
                            transition-colors duration-200"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <span className="text-sm font-medium text-gray-700">
                        {addOn.name || 'Unnamed Add-on'}
                      </span>
                    </div>
                    {selectedProduct && (
                     <button
                        onClick={()=>HandleChangeAddonProduct (selectedProduct)}
                         className="text-xs text-[#EA011F] font-medium hover:underline"
                             >
                           Change
                          </button>
                          )}

                  </div>
  
                  {selectedProduct && (
                    <div className="flex items-center justify-between mt-2 bg-white 
                                  p-2 rounded-lg shadow-sm">
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-lg bg-red-50 flex items-center 
                                      justify-center mr-2">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-xs text-[#EA011F]"
                          />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-800 line-clamp-1">
                            {selectedProduct.product_name || 'Unnamed Product'}
                          </p>
                          {/* <p className="text-xs text-[#EA011F] font-medium">
                            +{formatPrice(selectedProduct.selling_price || 0)}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };
  


 const renderHardware = (product, categoryIndex, productIndex, doorsType, room_category) => {
  //const showHardwareForCategory = doorsType === 'pre eng doors' ? preEngHardware : onlyDoorsHardware;
  if (!showHardware || !hardwareData?.length) return null;

  const uniqueKey = `${doorsType}-${room_category}`;
  const isOpen = categorySpecificHardware[uniqueKey] || false;
  
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(price);
  };


  const handleQuantityChange = (groupName, option, action) => {
    const currentQty = selectedHardware[uniqueKey]?.[groupName]?.quantity || 0;
    let newQty = currentQty;

    if (action === 'increment') {
      newQty = currentQty + 1;
    } else if (action === 'decrement' && currentQty > 0) {
      newQty = currentQty - 1;
    }

    handleHardwareSelection(
      categoryIndex,
      productIndex,
      doorsType,
      groupName,
      { ...option, quantity: newQty },
      room_category
    );
  };

  return (
    <div className="mt-3 border-t border-gray-100 pt-2">
      <button
        onClick={() => {
          setCategorySpecificHardware({
            ...categorySpecificHardware,
            [uniqueKey]: !isOpen
          });
        }}
        className="flex items-center justify-between w-full py-3 hover:bg-gray-50 
                   transition-colors duration-200"
      >
        <div className="flex items-center">
          <div className="w-8 h-8 bg-red-50 rounded-full flex items-center 
                         justify-center transition-transform duration-200 
                         hover:scale-105">
            <FontAwesomeIcon icon={faWrench} className="text-[#EA011F] text-sm" />
          </div>
          <div className="ml-3">
            <h3 className="text-base font-semibold text-gray-800">Door Hardware</h3>
            <p className="text-xs text-gray-500">Select locks, handles & hinges</p>
          </div>
        </div>
        <div className="flex items-center gap-3">
  {selectedHardware[uniqueKey]?.totalPrice > 0 && (
    <span className="text-sm font-medium text-green-700">
      +{formatPrice(selectedHardware[uniqueKey]?.totalPrice)}
    </span>
  )}
  <div className="w-6 h-6 rounded-full border border-gray-400 flex items-center
                 justify-center transition-colors duration-200
                 hover:border-[#EA011F]">
    <FontAwesomeIcon
      icon={isOpen ? faMinus : faPlus}
      className="text-[#736f70] text-xs"
    />
  </div>
</div>

      </button>

      {isOpen && (
      <div className="pt-2">
        {hardwareData?.map((group) => (
          <div key={group.product_group_id} className="mb-6">
            <div className="flex items-center mb-3">
              <div className="w-1 h-4 bg-[#736f70] rounded-full mr-2" />
              <h4 className="text-sm font-semibold text-gray-700 uppercase tracking-wide">
                {group.product_group_name}
              </h4>
            </div>
            <div className="flex overflow-x-auto no-scrollbar">
              <div className="flex gap-3 px-4">
                {group.products.map((option) => (
                  <div
                    key={option._id}
                    className={`
                      flex-shrink-0 border rounded-lg p-3 w-36
                      transition-all duration-200 hover:shadow-md
                      ${selectedHardware[uniqueKey]?.[group.product_group_name]?._id === option._id
                        ? 'bg-red-50 border-[#EA011F]'
                        : 'border-gray-200 hover:border-gray-300'
                      }
                    `}
                  >
                    {option.product_image && (
                      <div className="w-full h-20 mb-2 rounded-md overflow-hidden">
                        <img
                          src={`${BRICKBUCKETIMAGE}${option.product_image}`}
                          alt={option.product_name}
                          className="w-full h-full object-cover"
                          loading="lazy"
                        />
                      </div>
                    )}
                    <h5 className="text-sm text-gray-800 font-medium mb-1 line-clamp-2">
                      {option.product_name}
                    </h5>
                    <span className="text-[#EA011F] text-sm font-bold">
                      {formatPrice(option.selling_price)}
                    </span>

                    {selectedHardware[uniqueKey]?.[group.product_group_name]?._id === option._id ? (
                      <div className="flex items-center justify-between mt-2 bg-gray-50 rounded-lg p-1">
                        <button
                          onClick={() => handleQuantityChange(group.product_group_name, option, 'decrement')}
                          className="w-8 h-8 flex items-center justify-center text-[#EA011F] 
                                   hover:bg-red-100 rounded-full transition-colors"
                        >
                          <FontAwesomeIcon icon={faMinus} className="text-xs" />
                        </button>
                        <span className="text-sm font-medium">
                          {selectedHardware[uniqueKey]?.[group.product_group_name]?.quantity || 0}
                        </span>
                        <button
                          onClick={() => handleQuantityChange(group.product_group_name, option, 'increment')}
                          className="w-8 h-8 flex items-center justify-center text-[#EA011F] 
                                   hover:bg-red-100 rounded-full transition-colors"
                        >
                          <FontAwesomeIcon icon={faPlus} className="text-xs" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => handleHardwareSelection(
                          categoryIndex,
                          productIndex,
                          doorsType,
                          group.product_group_name,
                          { ...option, quantity: 1 },
                          room_category
                        )}
                        className="w-full mt-2 py-2 bg-red-50 text-[#EA011F] rounded-lg text-sm font-medium
                                 hover:bg-red-100 transition-colors"
                      >
                        ADD
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
    </div>
  );
}; 



const HardwareToggle = ({ isEnabled, onToggle }) => (
  <div className="flex flex-col items-center gap-2 pl-1 border-l border-gray-100">
    <div className="flex ml-4 items-center w-full">
    {/*   <div className="w-7 h-7 bg-gradient-to-br from-red-50 to-red-100 rounded-lg flex items-center justify-center">
        <FontAwesomeIcon icon={faWrench} className="text-[#EA011F] text-xs"/>
      </div> */}
      <span className="text-xs font-bold text-gray-700">Include Hardware</span>
    </div>
    
    <label className="relative inline-flex items-center cursor-pointer">
  <input
    type="checkbox"
    className="sr-only peer"
    checked={isEnabled}
    onChange={onToggle}
  />
  <div className="w-12 h-5 bg-gray-100 rounded-full peer
                  peer-checked:bg-gradient-to-r from-red-50 to-red-100
                  peer-checked:after:translate-x-[27px]
                  after:content-['']
                  after:absolute
                  after:top-[2px]
                  after:left-[2px]
                  after:bg-white
                  after:rounded-full
                  after:h-4
                  after:w-4
                  after:shadow-sm
                  after:transition-all
                  peer-checked:after:bg-[#EA011F]
                  hover:after:scale-95
                  transition-all
                  duration-200">
  </div>
</label>

  </div>
);


return (
  <>
  {isRefreshing ? (
    <RefreshLoader />
  ) : (
  <div className="flex flex-col h-screen bg-gray-50">
    {renderHeader()}
    {/* {renderHardwareToggle()} */}
    
    <main className="flex-1 overflow-y-auto mt-1 mb-4 pb-3">
      <div className="p-4 pb-2 max-w-4xl mx-auto">
      {Data?.room_categories?.length > 0 && (
  <>
    {/* Pre Eng Doors Section */}
    {Data.room_categories.some(p => p.category === 'pre eng doors') && (
  <section className="mb-8 animate-fadeIn">
        <div className="sticky top-[0px] z-40 bg-gray-50 "> {/* Add sticky positioning */}
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 mb-3">
      <div className="px-1 py-4">
        <div className="flex items-start justify-between">
          {/* Left Section */}
          <div className="flex items-start">
            <div className="w-10 h-10 bg-gradient-to-br ml-1 from-red-50 to-red-100 rounded-xl flex items-center justify-center shadow-sm flex-shrink-0">
              <FontAwesomeIcon icon={faDoorClosed} className="text-[#EA011F] text-xl"/>
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-bold text-gray-800 mb-1">Pre Eng Doors</h2>
              <p className="text-xs text-gray-500">Frames Ready For Installation</p>
            </div>
          </div>

          {/* Right Section */}
          <HardwareToggle 
  isEnabled={preEngHardware}
  onToggle={() => {
    setIsHardwarePriceLoading(true);
    setPreEngHardware(!preEngHardware);
    setTimeout(() => {
      setIsHardwarePriceLoading(false);
    }, 1000);
  }}
/>
        </div>
      </div>
    </div>
    </div>

    <div className="space-y-3">
      {Data.room_categories
        .filter(p => p.category === 'pre eng doors')
        .map((category, categoryIndex) => (
          <div key={category.id || `pre-eng-${categoryIndex}`}
               className="transform transition-all duration-200 hover:translate-y-[-2px]">
            {renderCategoryCard(category, categoryIndex, category.category)}
          </div>
        ))}
    </div>
  </section>
)}
    {/* Only Doors Section */}
    {Data.room_categories.some(p => p.category !== 'pre eng doors') && (
  <section className="mb-8 animate-fadeIn">
     <div className="sticky top-[0px] z-40 bg-gray-50 "> {/* Add sticky positioning */}
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 mb-3">
      <div className="px-2 py-4">
        <div className="flex items-start justify-between">
          {/* Left Section */}
          <div className="flex items-start">
            <div className="w-12 h-12 bg-gradient-to-br from-red-50 to-red-100 rounded-xl flex items-center justify-center shadow-sm flex-shrink-0">
              <FontAwesomeIcon icon={faDoorClosed} className="text-[#EA011F] text-xl"/>
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-bold text-gray-800 mb-1">Only Doors</h2>
              <p className="text-xs text-gray-500"> Doors With Pre-Installed Frames</p>
            </div>
          </div>

          {/* Right Section */}
          <HardwareToggle 
  isEnabled={onlyDoorsHardware}
  onToggle={() => {
    setIsHardwarePriceLoading(true);
    setOnlyDoorsHardware(!onlyDoorsHardware);
    setTimeout(() => {
      setIsHardwarePriceLoading(false);
    }, 1000);
  }}
/>
        </div>
      </div>
    </div>
    </div>
    <div className="space-y-3">
      {Data.room_categories
        .filter(p => p.category !== 'pre eng doors')
        .map((category, categoryIndex) => (
          <div key={category.id || `other-${categoryIndex}`}
               className="transform transition-all duration-200 hover:translate-y-[-2px]">
            {renderCategoryCard(category, categoryIndex, category.category)}
          </div>
        ))}
    </div>
  </section>
)}

  </>
)}

<div className="flex items-center gap-2 px-4 py-2 bg-green-50 rounded-lg">
    <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
    </svg>
    <span className="text-sm font-medium text-green-700">
      Installation will be done by Professional Brickbucket Team
    </span>
  </div>

        {renderPackages()}
        
        {/* Modals */}
        <VendorChangeModal
          isVisible={CustamisationOfSubcategoryAddons}
          onClose={() => setCustamisationOfSubcategoryAddons(false)}
          subcategory={vendorChangeSubcategory}
          vendor={vendorChangeVendor}
        />
      </div>

     {/* Store Information */}
     {/* <div className="bg-white rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] p-8 mt-6 backdrop-blur-sm">
  <div className="max-w-2xl mx-auto">
   
    <div className="text-center mb-6">
      <h2 className="text-2xl font-bold bg-gradient-to-r from-[#EA011F] to-red-500 text-transparent bg-clip-text">
        Brickbucket Center
      </h2>
      <div className="h-1 w-20 bg-gradient-to-r from-[#EA011F] to-red-500 mx-auto mt-2 rounded-full"/>
    </div>

  
    <div className="flex items-start gap-3 mb-6">
      <div className="p-2 bg-red-50 rounded-full flex-shrink-0">
        <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
        </svg>
      </div>
      <p className="text-gray-600 text-sm leading-relaxed">
        1st Floor, Besides Rainbow Hospital, Swarna Grand, NH 16 Service Rd, 
        Nagarjuna Nagar, Sri Ramachandra Nagar, Vijayawada, Andhra Pradesh 520008.
      </p>
    </div>

   
    <a
      href="tel:+919701967272"
      className="group flex items-center justify-center gap-3 p-3 mb-8
        bg-gradient-to-r from-red-50 to-white
        hover:from-red-100 hover:to-red-50
        border border-red-100 rounded-xl
        transform hover:scale-[1.02] hover:shadow-lg
        transition-all duration-300"
    >
      <div className="p-2 bg-white rounded-full shadow-sm group-hover:shadow">
        <svg className="w-5 h-5 text-[#EA011F] group-hover:animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
        </svg>
      </div>
      <div className="flex flex-col">
        <span className="text-gray-800 font-medium">+91 9701967272</span>
        <span className="text-xs text-gray-500">Click to call now</span>
      </div>
      <svg className="w-5 h-5 text-gray-400 group-hover:translate-x-1 transition-transform" 
        fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
      </svg>
    </a>

 
    <div className="relative rounded-xl overflow-hidden shadow-lg group">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20 
        opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.8661790804444!2d80.67221177489614!3d16.518743088608843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35e59e85c4baa7%3A0x2b2d63f275dc3d6e!2sKRAFTO%20Tiles!5e0!3m2!1sen!2sin!4v1706521145617!5m2!1sen!2sin"
        className="w-full h-72 group-hover:scale-105 transition-transform duration-700"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  </div>
</div> */}
  
     
    </main>

    {renderFloatingButtons()}

    <DoorDetailsModal
      isVisible={isDetailsModalVisible && !!selectedDoors}
      onClose={() => setIsDetailsModalVisible(false)}
      doors={selectedDoors?.doors || []}
      categoryName={selectedDoors?.categoryName || ''}
    />
    
    <QuotationPDFModal
      isVisible={isPdfModalVisible && !!quotation}
      onClose={() => setIsPdfModalVisible(false)}
      quotationData={quotation}
      className="m-0 justify-start"
    />

<ProductSelectionModal
  isVisible={isProductSelectionModalVisible && !!currentCategory}
  onClose={() => setIsProductSelectionModalVisible(false)}
  category={currentCategory}
  onProductSelect={(selectedProduct) => {
    setLoadingCategories(prev => ({...prev, [currentCategory.room_category]: true}));
    handleProductChange(selectedProduct, currentCategory);
  }}
  isLoading={loadingCategories[currentCategory?.room_category]}
  packageName={Data?.package_name}

/>


    {/* Loading Overlay */}
  
    <LoaderModal 
    isVisible={isSavingQuote}
    title="Generating Custom Quote"
    packageName="Please wait..."
  />
 

{isChangingVendor && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-8 rounded-xl flex flex-col items-center w-72">
      <div className="w-12 h-12 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>      
      <p className="text-lg font-semibold text-gray-800 text-center">
        Changing {vendorChangeSubcategory.product_sub_category_name} Brand
      </p>
      <span className="text-sm text-gray-500 text-center mt-2">
        Changes will apply only to doors in this project
      </span>
    </div>  
  </div>
)}


<LoaderModal 
      isVisible={isPackageSwitching}
      title="Switching to"
      packageName={selectedPackage?.package_name}
    />



{isCallbackModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setIsCallbackModalVisible(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">Thanks for your interest</h2>
      <p className="text-gray-700 text-center mb-6">Our executive will call you shortly.</p>
      <button
        className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
        onClick={() => setIsCallbackModalVisible(false)}
      >
        Close
      </button>
    </div>
  </div>
)}


  </div>
  )}
  </>
);

}

   
export default DoorsQuote;
