import { faChevronLeft, faPhone, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';

const TilesOrderSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { items, totalAmount, salesOrderData, categoryId, vendor_id, productGroupName } = location.state;
  console.log(salesOrderData);
  console.log(items);
  const [isLoading, setIsLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, []);

  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors  = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}vendor/get-vendorsBy-productCategoryId?product_category_id=${categoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.data);
        const filteredVendors = data.data.filter(vendor => vendor.name !== "Any Vendor");
      setVendors(filteredVendors);
      //console.log(filteredVendors);
        return data.data;
      } else {
        if (response.status === 404) {
         // setProducts([]);
          setPopupMessage('No vendor found.');
          setPopupType('error');
        } else if (response.status === 500) {
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setRetryFunction(() => () => fetchVendors(attempt));
        }
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch vendors:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchVendors(attempt + 1), 1000);
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchVendors(attempt));
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto pt-16 pb-20">
      <div className="bg-white rounded-xl shadow-md max-w-4xl mx-auto">
        <div className="p-2 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Order Summary</h2>
        </div>

        <div className="">
          {/* Header */}
          <div className="grid grid-cols-5 gap-6 px-2 py-1 bg-gray-100 rounded-t-lg font-semibold text-gray-700">
            <div className='text-center'>Item</div>
            <div className="text-center">Boxes</div>
            <div className="text-center">Area <span className="text-xs">(Sq.Ft)</span></div>
            <div className="text-center">Price <span className="text-xs">(Sq.Ft)</span></div>
            <div className="text-center">Total</div>
          </div>

          {/* Items */}
          {items.map((item, index) => (
  <div key={index} className="border-b last:border-b-0">
    <div className={`grid grid-cols-5 gap-2 p-2 text-sm items-center ${index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}`}>
    <div className="font-medium text-md text-gray-800 text-center">
  <div className="text-lg font-bold text-blue-600 mb-1">
    {vendors.find(vendor => vendor._id === location.state.vendor_id)?.name}
  </div>
  <div className="flex flex-col gap-1">
    <div className="text-sm font-bold ">
      {location.state.productGroupName}
    </div>
    <div className="text-base font-medium">
      {item.item_name}
    </div>
  </div>
</div>

      <div className="text-center">{item.quantity}</div>
      <div className="text-center">{item.application_areas.area}</div>
      <div className="text-center">₹{item.selling_price}</div>
      <div className="font-bold text-blue-600 text-center">
        ₹{item.sales_total.toLocaleString('en-IN')}
      </div>
    </div>
  </div>
))}



          {/* Total Section */}
          <div className="mt-6 p-4 border-t bg-gray-50 rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <span className="text-xl font-bold">Total Amount:</span>
              <span className="text-2xl font-bold text-blue-600">
                ₹{totalAmount.toLocaleString('en-IN')}
              </span>
            </div>

            <div className="flex gap-4 justify-between items-center">
              <button
                onClick={() => navigate(-1)}
                className="px-2 py-1 font-semibold text-lg underline text-blue-600"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" /> Back
              </button>

              <div className="flex gap-4">
                <button className="p-2 rounded-full bg-blue-50 hover:bg-blue-100 text-blue-600">
                  <FontAwesomeIcon icon={faShare} className="w-5 h-5" />
                </button>
                <button className="p-2 rounded-full bg-green-50 hover:bg-green-100 text-green-600">
                  <FontAwesomeIcon icon={faPhone} className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TilesOrderSummary;
