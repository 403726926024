import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faTrash, faX , faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader, Spinner } from '../BBAdmin/Loader/Loader';
import TableSearchDropDown from '../BBAdmin/Components/tableSerchDropDown';
import TableSearchForAreaName from '../BBAdmin/Components/TabelSerchForAreaName';
import  '../BBAdmin/Css/Tooltip.css';
import { useNavigate } from 'react-router-dom';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../BBAdmin/Messages/Messages';
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const QuoteForm = ({ selectedCustomer, selectedQuoteType, selectedCategory, quote }) => {
  console.log(quote)
  console.log(selectedQuoteType)
  console.log(selectedCustomer)
  console.log(selectedCategory)
  const [selectedProject, setSelectedProject] = useState('');
const [selectedProjectId, setSelectedProjectId] = useState('');
const navigate = useNavigate()
const dropdownRef = useRef(null); // Define the dropdownRef variable
  const [quoteTypeOptions, setQuoteTypeOptions] = useState([
    { label: "General", value: "General" },
    { label: "Group", value: "Group" },
  ]);
  //const [selectedQuoteType, setSelectedQuoteType] = useState(); // Initial value
  const [TaxOptions] = useState([
    {label: "Tax Exclusive", value: "Tax Exclusive"},
    {label: "Tax Inclusive", value: "Tax Inclusive"},
  ]);
  const [selectedTax, setSelectedTax] = useState({label: "Tax Exclusive", value: "Tax Exclusive"});
  const [priceLists , setPriceLists] = useState([ ]);
  //console.log(priceLists);
  const[selectedPricList, setselectedPricList] = useState();
  const [UomOptions , setUomOptions] = useState([]);
  const [AreaMeasurementsData, setAreaMeasurementsData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
 // const [selectedCustomer, setSelectedCustomer] = useState();
  //console.log(selectedCustomer);
  const [quotationNumber, setQuotationNumber] = useState('');
  const [quotationDate, setQuotationDate] = useState(new Date());
  const [showDesignSelectPopup, setshowDesignSelectPopup] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [Summary , setSummary] =  useState({});
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit
  const [Taxes] = useState([
    {label: "GST", value: "GST"},
    {label: "IGST", value: "IGST"},
  ]);
  const [DefaultProductRatesOfPriceList,setDefaultProductRatesOfPriceList]= useState()
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPricelists, setRowPricelists] = useState({});
  const [rows, setRows] = useState([{ item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',},  quantity: '', UOM: '', price: '', tax: '', tax_type:'', amount: '', sub_items:[]}]);
  const [products, setProducts] = useState([]); // assume products is set to one of the arrays you provided
  const [amounts, setAmounts] = useState([]);
  const [items, setItems] = useState([{ tilesArea: '', doorsArea: '', windowsArea: '' }]);
  const [tilesOptions, setTilesOptions] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [adjustment, setAdjustment] = useState('');
  const[ attributes, setAttributes]= useState([])
  const [total, setTotal] = useState(0);
  const [amendedDiscount, setAmendedDiscount] = useState();
  const [amendedPrice, setAmendedPrice] = useState();
  const [allRowsSelectedPriceList, setAllRowsSelectedPriceList] = useState([]);
const [SelectedItemRow,setSelectedItemRow] = useState()
const [SelectedItemRowIndex ,setSelectedItemRowIndex] = useState()
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [subItems,setSubItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [selectedApplicationAreas, setSelectedApplicationAreas] = useState({});
    const [roleId, setRoleId] = useState(null);

    useEffect(() => {
      fetchRoleId();
      fetchAllApprovedCustomers();
      handleGetAllUoms();

    }, [fetchUrl]);

    useEffect(()=>{
      if(selectedCategory){
      fetchProductsbyProductCategoryID(selectedCategory)
    }
    },[selectedCategory])

    const fetchRoleId = () => {
      const roleId = JSON.parse(sessionStorage.getItem('roleId'));
      setRoleId(roleId);
    };
  
    const categoriesToDisplay = roleId === 6 
    ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
    : productCategories;
      
    const fetchAllApprovedCustomers = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `customer/get-all-approved-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
       // console.log(output)
        if (output) {
          // Format data for dropdown
          const formattedCustomers = output.map(customer => ({
            id: customer._id,  // Use customer ID as value
            label: customer.customer_name, // Use customer name as label
            value: customer.customer_name
          }));
    
          setCustomers(formattedCustomers); // Update state with formatted data
         // console.log(formattedCustomers);
        } else {
          if(response.status === 500){
            //console.log('retry enterd')
            
            setRetryFunction(() => () => fetchAllApprovedCustomers); 
          }
          //console.error(data.message);
        }
      }  catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAllApprovedCustomers(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAllApprovedCustomers(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

    const fetchAllprojectsbyCustomer = async (id, attempt = 1) => {
      //console.log('Fetching projects for customer_id:', id);
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${FetchProjectsUrl}/projects/get-projects-by-customer?customer_id=${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        //console.log(output);
        if (output) {
           const formattedProjects = output.map(project => ({
            id: project?._id,  // Use customer ID as value
            label: project?. projectName, // Use customer name as label
            value: project?.projectName
          }));
    
          setProjects(formattedProjects);

          //console.log(formattedProjects);
        } else {
          if (response.status === 500) {
           // console.log('retry entered');
            setRetryFunction(() => () => fetchAllprojectsbyCustomer(id));
          }
          //console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => fetchAllprojectsbyCustomer(id, attempt + 1), 1000);
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAllprojectsbyCustomer(id, attempt));
        }
      } finally {
        setIsLoading(false);
      }
    };

    const fetchProjectSummarybyProjectId = async (projectid, attempt = 1) => {
     // console.log(projectid)
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${FetchProjectsUrl}/project-reports/get-project-measurements-by-project-and-product-category?project_id=${projectid}&product_category_id=${selectedProductCategory.value}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        
        const data = await response.json();
        
        if (response.ok) {
           console.log(data?.data?.projectMeasurements);
          setAreaMeasurementsData(data?.data?.projectMeasurements || []);
          setSummary(data.data);
  
        } else {
          console.error('Failed to fetch projects:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      } 
    };
    
    const fetchProductCategories = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
          setProductCategories(output); // Update productCategories state
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
        }
      }/* finally {
        setIsLoading(false);
      } */
    };


    const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        //console.log(output)
        if (output) {
        //console.log(output)
         setAttributes(output)
        } else {
          setAttributes([])
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
        }
      }
      }

      const fetchProductsbyProductCategoryID = async (selectedCategory, attempt = 1) => {
        try {
          // First initialize rows with quote items if they exist
          if (quote?.items?.length > 0) {
            const initialRows = quote.items.map(item => ({
              item_name: item.item_name,
              item_id: item.item_id,
              application_area: item.area_types || {area_name:'', area: '', count:''}, 
              quantity: item.quantity,
              UOM: item.UOM,
              price: item.selling_price,
              tax: item.tax_rate,
              tax_type: item.tax_type,
              total: item.total,
              sub_items: item.required_order_breakup || []
            }));
            setRows(initialRows);
          }
      
          const token = sessionStorage.getItem('token');
          const userData = JSON.parse(sessionStorage.getItem('userData'));
          const roleId = userData ? userData.roleID : null;
      
          const response = await fetch(`${fetchUrl}product/get-products-by-product-category?product_category_id=${selectedCategory}&roleId=${roleId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
      
          const data = await response.json();
          const output = await handleGetResponse(response, data);
      
          if (output) {
            // Map products with quote items data if available
            const mappedProducts = output.map(product => {
              const quoteItem = quote?.items?.find(item => item.item_id === product.item_id);
              
              if (quoteItem) {
                return {
                  ...product,
                  quantity: quoteItem.quantity,
                  price: quoteItem.selling_price,
                  tax: quoteItem.tax_rate,
                  tax_type: quoteItem.tax_type,
                  total: quoteItem.total,
                  application_area: quoteItem.area_types,
                  sub_items: quoteItem.required_order_breakup || product.sub_items
                };
              }
              return product;
            });
      
            setProducts(mappedProducts);
          } else {
            setProducts([]);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => fetchProductsbyProductCategoryID(selectedCategory, attempt + 1), 1000);
          } else {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchProductsbyProductCategoryID(selectedCategory, attempt));
          }
        }
      };
      
      
      
    const handleGetAllUoms = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
    
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        if (output) {
          // Log the data to ensure it's fetched correctly
          //console.log('Fetched uoms:',output);
    
          const uomData = output.map((item) => ({
            id: item._id,
            value: item.UOM,
            label: item.UOM,
          }));
    
      setUomOptions(uomData)
        } else {
          console.error('Error:', data.message);
          return ([])
        
         
        }
      }catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

    const fetchAllPriceLists = async (ProductCategory,attempt = 1) => {
   
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + `pricelist/get-pricelists-with-productrates-by-product-category?product_category_id=${ProductCategory.value}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
         
          const output = await handleGetResponse(response , data)
          if (output) {
            //console.log(output);
            setPriceLists(output); // Update productCategories state
          } else {
            setPriceLists([]);
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchAllPriceLists(ProductCategory, attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchAllPriceLists(ProductCategory, attempt)); // Set retry function
          }
        }/* finally {
          setIsLoading(false);
        } */
      
      
    };

  const handleDateChange = (date) => {
    setQuotationDate(date);
  };

  const handleQuotationNumberChange = (e) => {
    setQuotationNumber(e.target.value);
  };

  const handleProjectSelect = (value, label, id) => {
    setSelectedProject(label);
  };

  const handlePriceListSelect = (option) => {
    setselectedPricList(option);
    setRowPricelists(option)
    const RequiredpriceList= priceLists.find(p=>p?.priceList._id===option?.value)
    setDefaultProductRatesOfPriceList(RequiredpriceList?.productRates )
    if (!selectedPricList) {
      const id = option?.value;
      const RequiredpriceList = priceLists.find(p => p.priceList._id === id);
      const productRates = RequiredpriceList?.productRates;
      
      const updatedRows = [...rows]; // Create a shallow copy of rows array
    
      // Loop through each row and update the price if a matching product rate is found
      rows.forEach((row, index) => {
        const requireproductRate = productRates?.find(p => p.price_list_item_id === row?.item_id);
        
        if (requireproductRate) {
          const custom_price = parseFloat(requireproductRate?.pricing?.custom_rate) || 0;
          const discount = parseFloat(requireproductRate?.pricing?.discount) || 0;
          const discountPrice = (discount / 100) * custom_price;
          const finalPrice = custom_price - discountPrice;
          
          updatedRows[index].price = finalPrice;
          const totaltaxOfproduct = calculateTaxAmount(updatedRows[index].tax || 0 , finalPrice, updatedRows[index].quantity);
          updatedRows[index].total_tax_amount = totaltaxOfproduct;
          updatedRows[index].price_list_id = id;
        }
      });
    
      // Set the updated rows
      setRows(updatedRows);
      
      // Set the allRowsSelectedPriceList with the option for all rows
      const TotalLengthOfItemsInrows = rows.length;
      const allRowsSelectedPriceList = new Array(TotalLengthOfItemsInrows).fill(option);
    
      setAllRowsSelectedPriceList(allRowsSelectedPriceList);
    }
    
  }

  const handleTaxSelect = (option) => {
    if(option===selectedTax){
      setSelectedTax()
    }else{
      setSelectedTax(option);
    }
    
  };

  const handleQuoteTypeChange = (newQuoteType) => {
    //console.log(newQuoteType)
   // setSelectedQuoteType(newQuoteType);
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', price: '', tax: '', total: '', sub_items: [] };
    setRows([ newRow]);
  };
  
  const handleInputChange = (value, index, key, selling_price) => {
   // console.log(selling_price)
    const updatedData = [...rows];
    
    // Update the specific key in the row data
    updatedData[index][key] = value;
  
    // If the key is 'quantity', calculate the total and update the 'amount' in the same row
    if (key === 'quantity') {
      const total = parseFloat(value) * parseFloat(selling_price);
     // console.log(total)
    /*   updatedData[index].amount = isNaN(total) ? "" : total; // Handle potential NaN values
  
      // Update the amounts array if needed (optional)
      const newAmounts = [...amounts];
      newAmounts[index] = updatedData[index].amount;
      setAmounts(newAmounts); */
      const totaltaxOfproduct = calculateTaxAmount(updatedData[index]?.tax || 0 , selling_price, value);
      updatedData[index].total_tax_amount = totaltaxOfproduct;
      updatedData[index].total = total;
    }
  
    // Update the rows state
    setRows(updatedData);
  };
  


 /*  const handleSelectDesignPopup = (index,) => {
    const selectedRow = rows[index];
    setSelectedItemRow(selectedRow)
    setSelectedItemRowIndex(index)
    setSubItems(selectedRow?.sub_items); 
    setshowDesignSelectPopup(true);
  }; */

  const handleClosePopup = () => {
    setshowDesignSelectPopup(false);
  }

  const handleSelectDesign = (design) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    const designIndex = selectedDesigns.findIndex((d) => d.sub_item_id === design.sub_item_id);
  
    if (designIndex !== -1) {
      // Design is already in the array, remove it
      selectedDesigns.splice(designIndex, 1);
    } else {
      // Design is not in the array, add it
      selectedDesigns.push({ ...design, quantity: 1 }); // Default quantity set to 1 when adding
    }
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = [...selectedDesigns];
    setRows(updatedRows); // Trigger a state update
  };
  
  
  const handleQuantityChangeForDesign = (subItemId, index, quantity) => {
    const updatedRows = [...rows];
    const selectedDesigns = updatedRows[SelectedItemRowIndex].selectedDesigns || [];
  
    updatedRows[SelectedItemRowIndex].selectedDesigns = selectedDesigns.map((item) =>
      item.sub_item_id === subItemId ? { ...item, quantity: quantity } : item
    );
  
    setRows(updatedRows); // Trigger a state update
  };
  
   
  
  const handleCustomerSelect = (option) => {
    setSelectedProject(null); // Reset the selected project
    setSelectedProjectId(null); // Reset the selected project ID
    setSelectedProductCategory(null); // Reset the selected product category
    setProductCategories([]); 
    //setSelectedCustomer(option); // Set the selected option object
    fetchProductCategories();
  };
  
 
  
  const handleProductCategorySelect = (selectedOption) => {
    fetchAllPriceLists(selectedOption);
    setSelectedProductCategory(selectedOption);
    setSelectedProject(null); // Reset the selected project
    setSelectedProjectId(null); // Reset the selected project ID
    fetchAllprojectsbyCustomer(selectedCustomer.id);
    fetchProductsbyProductCategoryID(selectedOption.value);
    fetchAttributesByproductCategory(selectedOption.value)
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', price: '', tax_type:'', tax: '', total: '', sub_items: [] };
    setRows([newRow]);
    const index = 0
    const option = {label: '' , value: ''}
    handleItemNameChange(index ,option)
  };



  const addRow = () => {
    const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', price: '', tax_type:'', tax: '', total: '', sub_items: [] };
    setRows([...rows, newRow]);
    if(selectedPricList){
      setAllRowsSelectedPriceList([...allRowsSelectedPriceList, selectedPricList])
    }
  };

  const deleteRow = (index) => {
    const updatedData = [...rows];
    updatedData.splice(index, 1);
    setRows(updatedData);
    if(selectedPricList){
     const updatedRowPriceLists = [...allRowsSelectedPriceList]
     updatedRowPriceLists.splice(index, 1);
     setAllRowsSelectedPriceList(updatedRowPriceLists)
    }
  };
  
 /*  const handleUomChange = (index, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    const uomid= selectedOption?selectedOption.id:null
      const updatedData = [...rows];
      updatedData[index].UOM = value;
      updatedData[index].UOM_id = uomid;
      //setData(updatedData);
      setRows(updatedData)
  
  }; */
/* 
  const handleItemNameChange = (index, selectedOption) => {
    //console.log(selectedOption)
    const updatedRows = [...rows];
  const selectedItem = products.find(product => product.item_id === selectedOption.value)|| null;

   // console.log(products)
   console.log(allRowsSelectedPriceList[index])
    
    if (selectedItem) {
      updatedRows[index].item_id = selectedItem?.item_id
      updatedRows[index].item_name = selectedItem?.item_name
      updatedRows[index].price = selectedItem?.selling_price
      updatedRows[index].item_image = selectedItem?.item_image
      
      const product = products.find(p => p.item_id === selectedItem.item_id)
      const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id )
      console.log(selectedUom)
      updatedRows[index].UOM = selectedUom?.value;
      updatedRows[index].UOM_id = selectedUom?.id;
      
      // If you want to set these values as well, uncomment the following lines
      // updatedRows[index].UOM_id = selectedItem.UOM_id
      // updatedRows[index].GST = selectedItem.GST
      // updatedRows[index].IGST = selectedItem.IGST
      // updatedRows[index].sub_items = selectedItem.sub_items
      handleInputChange(selectedItem.selling_price, index , 'price')
    
      setRows(updatedRows);
    }
  
  }
 */

  const handleItemNameChange = (index, selectedOption) => {
    console.log("entered handle item name change")
   // console.log("selected item:", selectedOption);
    const updatedRows = [...rows];
    const selectedItem = products.find(product => product.item_id === selectedOption.value) || null;
    const rowPriceListItem = allRowsSelectedPriceList[index];
  
    //console.log(allRowsSelectedPriceList);
  
    if (selectedItem) {
      updatedRows[index].item_id = selectedItem?.item_id;
      updatedRows[index].item_name = selectedItem?.item_name;
      updatedRows[index].item_image = selectedItem?.item_image;
      updatedRows[index].cost_price = selectedItem?.cost_price;
      updatedRows[index].selectedDesigns = [];

        // Set default tax to GST
  const defaultTax = Taxes.find(tax => tax.value === 'GST');
  if (defaultTax) {
    const taxValue = selectedItem.GST || 0;
    updatedRows[index].tax = taxValue;
    updatedRows[index].tax_type = 'GST';
    handleTaxChange(index, defaultTax, taxValue, updatedRows[index].price, updatedRows[index].quantity);
  }
  
      if (rowPriceListItem) {
        const id = rowPriceListItem?.value;
        const RequiredpriceList = priceLists.find(p => p.priceList._id === id);
        const productRates = RequiredpriceList?.productRates;
        const requireproductRate = productRates.find(p => p.price_list_item_id === selectedItem?.item_id);
        const custome_price = parseFloat(requireproductRate?.pricing?.custom_rate);
        const discount = parseFloat(requireproductRate?.pricing?.discount) || 0;
        const discounPrice = (discount / 100) * custome_price;
        const finalPrice = custome_price - discounPrice;
  
        if (requireproductRate) {
          updatedRows[index].price = finalPrice;
          updatedRows[index].price_list_id = id;
        } else {
          updatedRows[index].price = selectedItem.selling_price;
        }
      } else if (selectedItem.selling_price) {
       // console.log('selling price');
        updatedRows[index].price = selectedItem.selling_price;
      } else {
      //  console.log('');
      }
  
      const product = products.find(p => p.item_id === selectedItem.item_id);
      const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id);
      updatedRows[index].UOM = selectedUom?.value;
      updatedRows[index].UOM_id = selectedUom?.id;
  
      // Apply conversion factor logic
      if (selectedQuoteType.value === 'Group') {
        const product_category = categoriesToDisplay.find(p => p._id === selectedProductCategory.value);
        const category_mapping_id = parseFloat(product_category?.category_mapping_id);

          if (category_mapping_id === 3 && 
            (updatedRows[index].UOM === 'No' || updatedRows[index].UOM === 'Nos')) {
          // Use count as quantity for Windows with UOM as No or Nos
          handleInputChange(updatedRows[index].application_area.count, index, 'quantity', product?.selling_price);
            }

        if (category_mapping_id === 1) {
          const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
  
          let conversionFactor = 0;
          if (selectedItem.attribute_value_ids) {
            for (const id of selectedItem.attribute_value_ids) {
              const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
              if (attributevalue) {
                conversionFactor = parseFloat(attributevalue.attribute_value);
                break;
              }
            }
  
            if (conversionFactor > 0) {
              const currentArea = parseFloat(updatedRows[index].application_area.area) || 0;
              const quantity = Math.ceil(currentArea / conversionFactor);
              updatedRows[index].quantity = quantity;
            }
          }
        }
      }
  
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].sub_items = selectedItem.sub_items;
        const updatedSubItems = selectedItem.sub_items;
        setSubItems({
          subItems: updatedSubItems,
          UOM: selectedUom?.value,
          UOM_id: selectedItem.UOM_id
        });
        return updatedRows;
      });
  
      setRows(updatedRows);
      calculateTotal(updatedRows);
    }
  };
  
  const handleRowPriceListSelect = (index, option, item) => {
    const id = option?.value;
    const RequiredpriceList = priceLists.find(p => p.priceList._id === id);
    const productRates = RequiredpriceList?.productRates;
    const requireproductRate = productRates?.find(p => p.price_list_item_id === item?.item_id);
  
    const custom_price = parseFloat(requireproductRate?.pricing?.custom_rate) || 0;
    const discount = parseFloat(requireproductRate?.pricing?.discount) || 0;
    const discountPrice = (discount / 100) * custom_price;
    const finalPrice = custom_price - discountPrice;
  
    const updatedRows = [...rows]; // Use spread operator for correct shallow copy of rows array
  
    if (requireproductRate) {
      updatedRows[index].price = finalPrice;
      updatedRows[index].price_list_id = id;
      updatedRows[index].total= updatedRows[index].quantity * finalPrice
      const totaltaxOfproduct = calculateTaxAmount(updatedRows[index].tax || 0 , finalPrice, updatedRows[index].quantity);
          updatedRows[index].total_tax_amount = totaltaxOfproduct;
      setRows(updatedRows);
    }else{
      const itemData = products.find(p=>p.item_id===item?.item_id)
      updatedRows[index].price = itemData?.selling_price;
      updatedRows[index].price_list_id = id;
      const totaltaxOfproduct = calculateTaxAmount(updatedRows[index].tax || 0 , itemData?.selling_price, updatedRows[index].quantity);
     updatedRows[index].total_tax_amount = totaltaxOfproduct;
      setRows(updatedRows);
    }
  
    setAllRowsSelectedPriceList((prev) => {
      const newState = [...prev];
      newState[index] = option;
      return newState;
    });
  };
  

/*   const handleAreaChange = (index, selectedOption) => {
    const value = selectedOption ? selectedOption.area : '';
    const areaName = selectedOption ? selectedOption.areaName : '';
    const count = selectedOption ? selectedOption.count : '';
   
    const updatedData = [...rows];
    updatedData[index].application_area.area_name = areaName;
    updatedData[index].application_area.area = value;
    updatedData[index].application_area.count = count;
  
    setRows(updatedData);
    const product = products.find(p => p.item_id === updatedData[index].item_id);
    //console.log(selectedQuoteType);
  
    if (selectedQuoteType.value === 'Group') {
      const product_category = productCategories.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);
       
      if (category_mapping_id === 1) {
        
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
        let conversionFactor = 0;
  
        product.attribute_value_ids.forEach((id) => {
          const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
          conversionFactor = attributevalue;
        });
  
        const quantity = parseFloat(value )/parseFloat( conversionFactor);
        console.log(quantity)
        handleInputChange(quantity,index, 'quantity', product?.selling_price );
      }else{
        handleInputChange(value ,index, 'quantity', product?.selling_price );
      }
    }
  }; */

 /*  const handleAreaChange = (index, selectedOption) => {
    console.log(selectedOption)
    const value = selectedOption ? selectedOption.area : '';
    const areaName = selectedOption ? selectedOption.areaName : '';
    const count = selectedOption ? selectedOption.count : '';
  
    const updatedData = [...rows];
    updatedData[index].application_area.area_name = areaName;
    updatedData[index].application_area.area = value;
    updatedData[index].application_area.count = count;
  
    setRows(updatedData);
    const product = products.find(p => p.item_id === updatedData[index].item_id);
  
    if (selectedQuoteType.value === 'Group') {
      const product_category = productCategories.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);
  
      if (category_mapping_id === 1) {
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
        console.log(attributeValues)
        let conversionFactor = 0;
        if (product && product.attribute_value_ids) {
          for (const id of product.attribute_value_ids) {
            const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
            if (attributevalue) {
              conversionFactor = parseFloat(attributevalue.attribute_value);
              break; // Stop iterating once we find a match
            }
          }
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
            console.log(quantity)
            handleInputChange(quantity, index, 'quantity', product?.selling_price);
          } else {
            handleInputChange(value, index, 'quantity', product?.selling_price);
          }
        } else {
          handleInputChange(value, index, 'quantity', product?.selling_price);
        }
      } else {
        handleInputChange(value, index, 'quantity', product?.selling_price);
      }
    }
  }; */



  const handleAreaChange = (index, selectedOptionOrInputValue) => {
    console.log("handle area change entered")
    const updatedData = [...rows];
    const oldAreaName = updatedData[index].application_area.area_name;
  
    if (typeof selectedOptionOrInputValue === 'string') {
      updatedData[index].application_area.area_name = selectedOptionOrInputValue;
    } else if (selectedOptionOrInputValue) {
      updatedData[index].application_area = {
        area_name: selectedOptionOrInputValue.areaName,
        area: selectedOptionOrInputValue.area,
        count: selectedOptionOrInputValue.count
      };
    } else {
      updatedData[index].application_area = { area_name: '', area: '', count: '' };
    }
  
    setRows(updatedData);
  
    setSelectedApplicationAreas(prevSelected => {
      const newSelected = {...prevSelected};
      if (oldAreaName) delete newSelected[oldAreaName];
      if (selectedOptionOrInputValue && typeof selectedOptionOrInputValue !== 'string') {
        const newAreaName = selectedOptionOrInputValue.areaName;
        newSelected[newAreaName] = true;
  
        const category_mapping_id = parseFloat(categoriesToDisplay.find(p => p._id === selectedProductCategory.value)?.category_mapping_id);
  
        if (category_mapping_id === 3) { // Windows
          if (newAreaName === 'Bedroom Windows Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bedroom') && key !== 'Bedroom Windows Area') delete newSelected[key];
            });
          } else if (newAreaName === 'Ventilators Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bathroom') && key !== 'Ventilators Area') delete newSelected[key];
            });
          }
        } else if (category_mapping_id === 2) { // Doors
          if (newAreaName === 'Bedroom Doors Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bedroom') && key !== 'Bedroom Doors Area') delete newSelected[key];
            });
          } else if (newAreaName === 'Bathroom Doors Area') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Bathroom') && key !== 'Bathroom Doors Area') delete newSelected[key];
            });
          }
        }else if (category_mapping_id === 1) { // Tiles
          if (newAreaName === 'Balcony Flooring') {
            Object.keys(newSelected).forEach(key => {
              if (key.startsWith('Balcony') && key !== 'Balcony Flooring') delete newSelected[key];
            });
          }
          else if (newAreaName === 'Total Bedrooms Flooring') {
            Object.keys(newSelected).forEach(key => {
              if (key.includes('Bedroom') && key.includes('Flooring') && key !== 'Total Bedrooms Flooring') {
                delete newSelected[key];
              }
            });
          } else if (newAreaName === 'Total Bathrooms Walls') {
            Object.keys(newSelected).forEach(key => {
              if (key.includes('Bathroom') && key.includes('Walls') && key !== 'Total Bathrooms Walls') {
                delete newSelected[key];
              }
            });
          } else if (newAreaName === 'Total Bathrooms Flooring') {
            Object.keys(newSelected).forEach(key => {
              if (key.includes('Bathroom') && key.includes('Flooring') && key !== 'Total Bathrooms Flooring') {
                delete newSelected[key];
              }
            });
          } else if (newAreaName.includes('Bedroom') && newAreaName.includes('Flooring')) {
            delete newSelected['Total Bedrooms Flooring'];
          } else if (newAreaName.includes('Bathroom') && newAreaName.includes('Walls')) {
            delete newSelected['Total Bathrooms Walls'];
          } else if (newAreaName.includes('Bathroom') && newAreaName.includes('Flooring')) {
            delete newSelected['Total Bathrooms Flooring'];
          }
        }
        
      }
      return newSelected;
    });
  
    const product = products.find(p => p.item_id === updatedData[index].item_id);
  
    if (selectedQuoteType.value === 'Group') {
      const product_category = categoriesToDisplay.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);
      console.log(category_mapping_id)
  
      if (category_mapping_id === 3 &&
          (updatedData[index].UOM === 'No' || updatedData[index].UOM === 'Nos')) {
        handleInputChange(updatedData[index].application_area.count, index, 'quantity', product?.selling_price);
        console.log(updatedData[index].quantity);
      }
      else if (category_mapping_id === 1) {
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
        let conversionFactor = 0;
        if (product && product.attribute_value_ids) {
          for (const id of product.attribute_value_ids) {
            const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
            if (attributevalue) {
              conversionFactor = parseFloat(attributevalue.attribute_value);
              break;
            }
          }
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(updatedData[index].application_area.area) || 0) / conversionFactor);
            handleInputChange(quantity, index, 'quantity', product?.selling_price);
          } else {
            handleInputChange(updatedData[index].application_area.area, index, 'quantity', product?.selling_price);
          }
        } else {
          handleInputChange(updatedData[index].application_area.area, index, 'quantity', product?.selling_price);
        }
      } else {
        handleInputChange(updatedData[index].application_area.area, index, 'quantity', product?.selling_price);
      }
    }
  };



  
  const handleAreaValueChange = (value, index, key, item_id) => {
    const updatedRows = [...rows];
    const [parentKey, childKey] = key.split('.');
    updatedRows[index][parentKey][childKey] = value;
    setRows(updatedRows);
    const product_category = categoriesToDisplay.find(p => p._id === selectedProductCategory.value);
    const category_mapping_id = parseFloat(product_category?.category_mapping_id);

    const product = products.find(p => p.item_id === item_id);
    if (category_mapping_id === 1) {
      const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;
     // console.log(attributeValues)
      let conversionFactor = 0;
      if (product && product.attribute_value_ids) {
        for (const id of product.attribute_value_ids) {
          const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
          if (attributevalue) {
            conversionFactor = parseFloat(attributevalue.attribute_value);
            break; // Stop iterating once we find a match
          }
        }

        if (conversionFactor > 0) {
          const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
        //  console.log(quantity)
          handleInputChange(quantity, index, 'quantity', product?.selling_price);
        } else {
          handleInputChange(value, index, 'quantity', product?.selling_price);
        }
      } else {
        handleInputChange(value, index, 'quantity', product?.selling_price);
      }
    } else {
      handleInputChange(value, index, 'quantity', product?.selling_price);
    }
   
  }
  



 const  [SubTotal,setSubTotal]= useState(0)

useEffect(()=>{
  calculateSubtotal()
  calculateTax()
},[rows])

  const calculateSubtotal = () => {
    let subtotal = 0;
  
    rows.forEach(row => {
      const quantity = parseFloat(row?.quantity) || 0;
      const price = parseFloat(row?.price) || 0;
      const rowTotal = quantity * price;
      subtotal += rowTotal;
    });
   setSubTotal(subtotal)
    return subtotal;
  };
  
  
  const calculateTax = () => {
    let TotalTax = 0;
  
    rows.forEach((p) => {
      const price = parseFloat(p.price) || 0;  // Handle missing or invalid price
      const taxRate = parseFloat(p.tax) || 0;  // Handle missing or invalid tax rate
      const quantity = parseFloat(p.quantity) || 0;  // Handle missing or invalid quantity
  
      const tax = (taxRate / 100) * price;
      const SubTotalTax = tax * quantity;
  
      TotalTax += SubTotalTax;
    });
  
    return TotalTax;
  };

  

  const handleTaxChange = (index, selectedOption, value, price, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      tax: value,
      tax_type: selectedOption?.label,
    };
    const totaltaxOfproduct = calculateTaxAmount(value, price, quantity);
    updatedRows[index].total_tax_amount = totaltaxOfproduct;
    setRows(updatedRows);
  };
  
  
  const calculateTaxAmount = (taxRate, price, quantity) => {
    const tax = parseFloat(taxRate) || 0;
    const sellingPricePerQuantity = parseFloat(price) || 0;
    const totalQuantity = parseFloat(quantity) || 0; // Ensure quantity is a number
    const taxAmount = parseFloat(((tax / 100) * sellingPricePerQuantity) * totalQuantity) || 0;
    return taxAmount;
  };

  const calculateTotalTaxAmount = () => {
    
    let totalTaxAmount = 0;
  
    if (Array.isArray(rows)) {
      rows.forEach((row) => {
        totalTaxAmount += row?.total_tax_amount || 0;
      });
    }
   
    return totalTaxAmount || 0; // Update the total tax amount state
    
  };
  

  const calculateEachProductTotal = (discount, sellingPrice, quantity) => {
    // Ensure sellingPrice and quantity are numbers
    const price = parseFloat(sellingPrice) || 0;
    const qty = parseFloat(quantity) || 0;
    
    // Calculate the initial total without discount
    let total = price * qty;
  
    // If the discount is provided and is a valid number, apply it
    if (discount && !isNaN(discount) && parseFloat(discount) > 0) {
      total = total * (1 - parseFloat(discount) / 100);
    }
    return total;
  };
 

const handleTheItemsData = () => {
 // console.log(rows)
  const data = rows.map((p) => {
    const itemData = {
      item_name: p?.item_name,
      item_id: p?.item_id,
      quantity: p?.quantity || 0,
      cost_price:  p?.cost_price||0,
      selling_price: p?.price || 0,
      discount: parseFloat(p?.discount) || 0,
      tax_rate: parseFloat(p?.tax) || 0,
      tax_type: p?.tax_type,
      total_tax_amount: p?.total_tax_amount || 0,
      tax_amount: ((parseFloat(p?.price) * parseFloat(p?.tax / 100)) * parseFloat(p?.quantity)) || 0,
      total: calculateEachProductTotal(p?.discount, p?.price, p?.quantity) || 0,
      quantity_breakup: p?.quantity_breakup || {},
      UOM: p?.UOM || '',
      UOM_id: p?.UOM_id,
      selected_designs: p?.selected_designs || [],
    };
   
    if (p.price_list_id) {
      itemData.price_list_id = p.price_list_id;
    }
    if (p.sub_items && 
      p.sub_items.length > 0) {
    
      const subItems = p.selectedDesigns
        .map((item) => {
         
              return {
                sub_item_name: item.sub_item_name,
                sub_item_id: item.sub_item_id,
                sub_item_quantity: item.quantity,
              };
            
         
        })
    
      itemData.required_order_breakup = subItems;
    }
    

    if (selectedQuoteType?.value === 'Group') {
      itemData.area_types = p?.application_area ;
      //itemData.selected_designs = [...p?.selected_designs];
    }

    return itemData;
  });

  return data;
};


const calculateTotal = () => {
  const subtotal = calculateSubtotal() || 0; // Get the subtotal from the existing function
  const discount = (amendedDiscount / 100) * subtotal || 0; // Calculate the discount based on the amendedDiscount percentage
  const tax_amount = calculateTax() || 0; // Calculate the tax amount using the existing function
 //console.log(tax_amount)
  // Parse amendedPrice to ensure it's a valid number
  const parsedAmendedPrice = parseFloat(amendedPrice) || 0;

  // Adjust the subtotal based on amended price
  const adjustedSubtotal = subtotal - discount + parsedAmendedPrice;

  let total = adjustedSubtotal; // Start with the adjusted subtotal

  if (selectedTax) {
    if (selectedTax.value === 'Tax Exclusive') {
      total += tax_amount; // Add tax if it's exclusive
    }
    // For 'Tax Inclusive', no need to adjust since total is already calculated
  } else {
    // If selectedTax is not defined, assume tax is exclusive
    total += tax_amount;
  }

  return total;
};


  useEffect(() => {
    const calculatedTotal = calculateTotal();
    setTotal(calculatedTotal);
  }, [rows, amendedDiscount, amendedPrice, selectedTax]);


  const handleSaveQuotationData = async (attempt = 1) => {
    setIsSaving(true); // Set saving state at the beginning of the function
  
    try {
      const quotation = {
        customer_id: selectedCustomer || '',
        product_category_id: selectedCategory || '',
        quotation_number : quote?.quotation_number || '',
        quotation_date: quote.quotation_date || '',
        status: 'Sent',
        subtotal: await calculateSubtotal() || 0,
        tax_amount: await calculateTax() || 0,
        discount: amendedDiscount || 0,
        adjustment: amendedPrice || 0,
        terms_and_conditions: '', // Add actual terms and conditions if needed
        tax_prefrence: selectedTax?.value || '',
        total: await calculateTotal() || 0,
        organizationId:sessionStorage.getItem('organizationId'),
        
      };
  
      // Conditionally add project_id if a project is selected
      if (selectedProject?.id) {
        quotation.project_id = selectedProject.id;
      }
      if (selectedPricList) {
        quotation.default_price_list_id = selectedPricList.value;
      }
  
      // Handle item data
      const itemsData = await handleTheItemsData();
  
      // Combine quotation and item data
      const quotationData = {
        ...quotation,
        items: itemsData,
      };
   //console.log(quotationData)
      // Get token from session storage
      const token = sessionStorage.getItem('token');
      const QuoteID = quote._id
      // Send quotation data to the server
      const response = await fetch(`${fetchUrl}customer/quotations/update-quotation?quotation_id=${QuoteID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationData),
      });
  
      // Parse and handle the server response
      const data = await response.json();
      const output = await handleSaveResponse(response, data);
  
      // If the save was successful, navigate to the quote list after a short delay
      if (output) {
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/home');
        }, 2000);
      } else {
        console.error(data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      // Retry logic in case of failure
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleSaveQuotationData(attempt + 1), 1000); // Retry after 1 second
      } else {
        // If max retries reached, show an error popup
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleSaveQuotationData(attempt)); // Set retry function
      }
    } finally {
      setIsSaving(false); // Ensure saving state is reset after attempt
    }
  };
  

  

  const handleCloseError = () => {
    setErrorMessages([]);
  };
  
  const closePopup = () => {
   // console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
   // console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){
  
        await retryFunction ();
      }
  
      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
   // console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };
  
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }
  
  const handleCancel = () => {
    setShowCancelPopup(true);
  }
  
  const handleLeave = () => {
    navigate(-1);
  }

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
      // Format the number with commas and two decimal places
      return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };
  
  
  if (isLoading) {
    return <Loader />;
  }




  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">New Quote</h1>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                      {/* <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Customer Name<span className="text-red-500">*</span>
                        </label>
                        <div className='w-1/3'>
                        <CustomDropdown
        options={customers}
        selected={selectedCustomer}
        onSelect={handleCustomerSelect}
        placeholder='Select Customer'
        showAddNew={false}
        showSearch={true}
      />
      </div>
                      </div> */}
                      {/* <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="customername" className="block text-sm font-regular text-gray-700 w-1/6">
                          Quote Type
                        </label>
                        <div className='w-1/3'>
                        <CustomDropdown
            options={quoteTypeOptions}
            selected={selectedQuoteType}
            onSelect={handleQuoteTypeChange}
           placeholder='Select Quote Type'
            showAddNew={false}
            showSearch={false} // Disable search for Quote Type
          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                    {/* <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                        Quote Number
                      </label>
                      <input
                        type="text"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={quotationNumber}
                        onChange={handleQuotationNumberChange}
                      />
                    </div> */}
                    {/* <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="quotationDate" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Quote Date<span className="text-red-500">*</span>
                      </label>
                      <DatePicker
                        selected={quotationDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                      />
                    </div> */}
                   {/*  <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category<span className="text-red-500">*</span>
                </label>           
                    <div className='w-1/3'>
                    <CustomDropdown
  options={categoriesToDisplay.map(category => ({
    value: category._id,
    label: category.product_category_name
  }))}
  selected={selectedProductCategory}
  onSelect={handleProductCategorySelect}
  placeholder="Select Item Category"
  showAddNew={false}
  showSearch={true}
/>
                    </div>
                    
              </div> */}
              {/* <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                 Project
                </label> 
                <div className='w-1/3'>          
                <CustomDropdown
      options={projects}
      selected={selectedProject}
      onSelect={(selectedOption) => {
        setSelectedProject(selectedOption);
        setSelectedProjectId(selectedOption.id);
        fetchProjectSummarybyProjectId(selectedOption.id);
      }}
      placeholder="Select Project"
      showAddNew={false}
      showSearch={true}
    />
  </div>
              </div> */}
                  </div>
                </div>
                {/* <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} /> */}
              </div>
              <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
             {/*  <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
              <div className='w-1/6'>
              <CustomDropdown
        options={TaxOptions}
        selected={selectedTax}
        onSelect={handleTaxSelect}
        showAddNew={false}
        placeholder='Item Tax Prefrence'
        showSearch={false}
      />          
                </div>
                <div className='w-1/6'>
              <CustomDropdown
         options={priceLists.map(priceList => ({
          value: priceList?.priceList._id,
          label:  priceList?.priceList.price_list_name
        }))}
        selected={selectedPricList}
        onSelect={handlePriceListSelect}
        showAddNew={false}
        placeholder='Select Price List'
        showSearch={true}
      />          
                </div>
              </div> */}

              <div className="w-full container mx-auto px-4 mb-2 mt-4">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
  <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
        <thead>
          <tr className="font-semibold">
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">Item Details</th>
            {selectedQuoteType?.value === 'Group' && (
          <>
            {(selectedProductCategory?.label === 'Tiles' ||
              selectedProductCategory?.label === 'Doors' ||
              selectedProductCategory?.label === 'Windows') && (
              <>
                <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">Application Area</th>
                <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">
                  {selectedProductCategory?.label === 'Tiles'
                    ? 'Tiles Area'
                    : `${selectedProductCategory?.label} Count`}
                </th>
              </>
            )}
          </>
        )}
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Quantity</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">UOM</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Price</th>
           
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Tax</th>
            
            <th className="font-semibold border-b  border-r text-left text-sm py-2 px-2">Amount</th>
            {selectedQuoteType?.value === 'Group' && (
          <>
            {(selectedProductCategory?.label === 'Tiles' ||
              selectedProductCategory?.label === 'Doors' ||
              selectedProductCategory?.label === 'Windows') && (
              <>
                 <th className="font-semibold text-left border-b text-sm py-2 px-2"></th>
              </>
            )}
          </>
        )}
           
            
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr key={index}>
              <td className="border-r border-b px-2 py-2" ref={dropdownRef}>
              <TableSearchDropDown
            options={products.map(product => ({
              value: product.item_id,
              label: product.item_name,
            }))} // Map item_name options
            value={item?.item_name} // Use item_id as value
            onChange={(selectedOption) => handleItemNameChange(index, selectedOption )} // Handle item_name change
          />
              </td>
               {/* Render additional fields only for Group type and specific categories */}
          {selectedQuoteType?.value === 'Group' && (
            <>
              {(selectedProductCategory?.label === 'Tiles' ||
                selectedProductCategory?.label === 'Doors' ||
                selectedProductCategory?.label === 'Windows') && (
                <>

<td className="border-r border-b px-2 py-2">
<TableSearchForAreaName
  key={index}
  options={Object.keys(AreaMeasurementsData).map((key) => {
    const value = AreaMeasurementsData[key][0];
    const count = AreaMeasurementsData[key].length > 1 ? `${AreaMeasurementsData[key].length - 1}` : '';
    const quantity = AreaMeasurementsData[key][1];
    return {
      label: `${key} - ${value} ${count}`,
      area: value,
      areaName: key,
      count: quantity,
    };
  }).filter(option => {
    const category_mapping_id = parseFloat(categoriesToDisplay.find(p => p._id === selectedProductCategory.value)?.category_mapping_id);

    if (category_mapping_id === 3) { // Windows
      if (selectedApplicationAreas['Bedroom Windows Area'] && option.areaName.startsWith('Bedroom') && option.areaName !== 'Bedroom Windows Area') return false;
      if (selectedApplicationAreas['Ventilators Area'] && option.areaName.startsWith('Bathroom') && option.areaName !== 'Ventilators Area') return false;
    } else if (category_mapping_id === 2) { // Doors
      if (selectedApplicationAreas['Bedroom Doors Area'] && option.areaName.startsWith('Bedroom') && option.areaName !== 'Bedroom Doors Area') return false;
      if (selectedApplicationAreas['Bathroom Doors Area'] && option.areaName.startsWith('Bathroom') && option.areaName !== 'Bathroom Doors Area') return false;
    } else if (category_mapping_id === 1) { // Tiles
      if (selectedApplicationAreas['Balcony Flooring'] && option.areaName.startsWith('Balcony') && option.areaName !== 'Balcony Flooring') return false;
      if (selectedApplicationAreas['Total Bedrooms Flooring'] && option.areaName.includes('Bedroom') && option.areaName.includes('Flooring') && option.areaName !== 'Total Bedrooms Flooring') return false;
      if (selectedApplicationAreas['Total Bathrooms Walls'] && option.areaName.includes('Bathroom') && option.areaName.includes('Walls') && option.areaName !== 'Total Bathrooms Walls') return false;
      if (selectedApplicationAreas['Total Bathrooms Flooring'] && option.areaName.includes('Bathroom') && option.areaName.includes('Flooring') && option.areaName !== 'Total Bathrooms Flooring') return false;
    }

    return !selectedApplicationAreas[option.areaName] || option.areaName === item?.application_area?.area_name;
  })}
  value={item?.application_area?.area_name}
  onChange={(selectedOption) => handleAreaChange(index, selectedOption)}
  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
/>
</td>


                   {selectedProductCategory?.label === 'Tiles'?(
                  <td className="border-r border-b px-2 py-2">
                  <input
                    type="text"
                    value={item?.application_area?.area}
                    onChange={(e) => handleAreaValueChange(e.target.value, index, 'application_area.area', item?.item_id)}
                    className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                  />
                </td>
                  ):(
                    <td className="border-r border-b px-2 py-2">
                    <input
                      type="text"
                      value={item?.application_area?.count}
                      onChange={(e) => handleInputChange(e.target.value, index, 'application_area.count')}
                      className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                    />
                  </td>
                  ) }


                </>
              )}
            </>
          )}
       <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.quantity}
                  onChange={(e) => handleInputChange(e.target.value, index, 'quantity' , item?.price )}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border-r border-b px-2 py-2">
                {/* <input
                  type="text"
                  value={item.UOM}
                  onChange={(e) => handleInputChange(e, index, 'UOM')}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                /> */}
                <td className="w-20">
  {/* <TableSearchDropDown
    options={UomOptions}
    value={item.UOM}
    onChange={(selectedOption) => handleUomChange(index, selectedOption)}
  /> */}
   <input
                  type="text"
                  value={item?.UOM}
                  readOnly
                  //onChange={(e) => handleInputChange(e, index, 'UOM')}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
</td>
              </td>
              <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.price}
                  readOnly
                  //onChange={(e) => handleInputChange(products.find(p=>p.item_id===item.item_id)?.selling_price, index, 'price')}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
                {selectedPricList && (
                  <td className="px-2 py-2">
                    <div className="w-48">
                      <CustomDropdown
                      
                         options={priceLists.map(priceList => ({
                          value: priceList?.priceList?._id,
                          label: priceList?.priceList?.price_list_name
                        }))}
                        selected={allRowsSelectedPriceList[index]||rowPricelists}
                        onSelect={(option) => handleRowPriceListSelect(index, option , item)}
                        showAddNew={false}
                        placeholder="Apply Price List"
                        showSearch={true}
                      />
                    </div>
                  </td>
                )}
              </td>
              <td className="border-r border-b px-2 py-2">
                {item.item_name && (
               <div className="w-22 flex-row ">
               <TableSearchDropDown
                 options={Taxes.map(tax => ({
                   value: tax.value,
                   label: tax.label,
                 }))}
                 value={item?.tax_type}
                 onChange={(selectedOption) => {
                   // Find the corresponding product using item_id
                   const product = products.find(p => p.item_id === item.item_id);
                   
                   // Calculate the tax value
                   const taxValue = product ? product[selectedOption.value] : null;
           
                   // Update the item tax with the selected tax value
                   handleTaxChange(
                     index, 
                     selectedOption, 
                     taxValue, 
                     item.price, 
                     item.quantity
                   );
           
                   // Update both item.tax and item.tax_type
                   item.tax = taxValue;
                   item.tax_type = selectedOption.value;
                 }}
               />
              {item?.tax && (
               <p>{item?.tax || ''} % </p>
              )} 
             </div>
                )}
  
            </td>
 
              <td className="border-b border-r px-1 py-2">
  <input
    type="text"
    readOnly
    value={(formatNumber(item?.price * item?.quantity) || formatNumber(item?.total) || 0)}

    //onChange={(e) => handleAmountInputChange(e, index, 'amount')}
    className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
  />
</td>

              {selectedQuoteType?.value === 'Group' && (
            <>
              {(selectedProductCategory?.label === 'Tiles' ||
                selectedProductCategory?.label === 'Doors' ||
                selectedProductCategory?.label === 'Windows') && (
                <>
                 {/*  <td className="border-b  px-2 py-2">
                <div
                  className="px-2 py-1 border rounded-md cursor-pointer text-center"
                  onClick={() => handleSelectDesignPopup(index)}
                >
                  Select
                </div>
              </td> */}
             
                </>
              )}
            </>
          )}

<td className="px-2">  <button    type="button"
                                  onClick={() => deleteRow(index ,rows)}
                                  className="text-red-500">
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
            </tr>
          ))}
        </tbody>
      </table>
</div>
              </div>
              </div>
              </div>
              <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
                <div><button  onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'><FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1"  />
                Add New Row</button></div> 
                <div className='p-6 space-y-6 flex flex-col justify-between rounded-md w-1/2 bg-[#F7F7FE]'>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm font-semibold'>Sub Total</p>
                </div>
                <div><p className='text-sm font-semibold'>{/* calculateSubtotal() */formatNumber (SubTotal)}</p></div>
                </div>
                <div className='flex justify-between'>
                <div className='flex flex-row items-center'>
  <p className='text-sm'>Discount</p>
  <div className='flex justify-end w-full relative'>
  <input 
    type="text"
    value={amendedDiscount}
    onChange={(e) => {
      const currentValue = e.target.value;
      const newValue = currentValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
      setAmendedDiscount(newValue);
    }}
    className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-1/3 border-r-0 rounded-l-md'
  />
  <span className='bg-gray-200 p-2 text-sm border border-gray-300 border-l-0 rounded-r-md'>%</span>
</div>
</div>
<div>
  <p className='text-sm '>
    <span className='mr-1 font-bold text-xl text-red-500'>-</span>
    {formatNumber(Number.isFinite(parseFloat(SubTotal)) && Number.isFinite(parseFloat(amendedDiscount)) ? parseFloat(SubTotal) * (parseFloat(amendedDiscount / 100)) : 0)}
  </p>
</div>
                </div>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm '>Tax</p>
                </div>
                <div>
                  {selectedTax?.value === 'Tax Exclusive' && (
                     <p className='text-sm font-semibold'> + {formatNumber(calculateTotalTaxAmount())}</p>
                  )}
                  {selectedTax?.value === 'Tax Inclusive' &&(
                     <p className='text-sm font-semibold'>tax inclusive {formatNumber(calculateTotalTaxAmount())}</p>
                  )}
                 
                  </div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-sm '>Adjustment</p>
                <div className='flex justify-end w-full relative'>
                <input
  type="text"
  value={amendedPrice}
  onChange={(e) => {
    const regex = /^[-+]?[0-9]*$/; // allow optional - or +, followed by digits
    if (regex.test(e.target.value)) {
      setAmendedPrice(e.target.value);
    }
  }}
  className='px-2 w-[50%] text-right py-1 w-2/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
/>

  <button 
  className="mr-2 text-white px-1 py-0.5 rounded-md" 
  title="" info-tooltip="Add any other +ve or -ve value that need to be applied to adjust the total amount of the transaction Eg. +10 or -10."  style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faQuestionCircle}  className="word-break break-words mb-0.5 text-xs text-gray-500 mx-1 cursor-pointer" />
</button>
</div>
                </div>
                <div><p className='text-sm '>{amendedPrice ? amendedPrice : '0.00'}</p></div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-lg font-semibold '>Total ( ₹ )</p>
                </div>
                <div><p className='text-lg font-semibold'>{formatNumber(total) }</p></div>
                </div>
                </div>
                </div>
              </div>
              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] text-white border border-[#DDDDDD] rounded-md"
          onClick={handleSaveQuotationData} >
            Save & Send  {isSaving && <Spinner />}
            </button>
          <button onClick={handleCancel} className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>Cancel</button>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>

{/* popup starts */}
      {showDesignSelectPopup && (
      <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col">
          <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[18%] px-6'>
            <h2 className="text-md">Select Design</h2>
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">   
              <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer" />
            </button>
          </div>
          <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
         
         <div className="flex flex-wrap p-4 overflow-auto">
    {SelectedItemRow.sub_items && SelectedItemRow.sub_items
      .filter((design) =>
        design.sub_item_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((design) => {
        const isSelected = rows[SelectedItemRowIndex]?.selectedDesigns?.some(
          (d) => d.sub_item_id === design.sub_item_id
        );

        return (
          <div key={design.id} className="w-1/3 p-2">
            <div
              className={`border rounded-md p-2 ${
                isSelected ? "border-blue-500" : "border-gray-300"
              }`}
            >
              <img
                src={imageUrl + design?.sub_item_image}
                alt={design?.sub_item_image}
                className="w-full h-32 object-cover rounded-md"
              />
              <h3 className="text-center mt-2">{design?.sub_item_name}</h3>
              <div className="flex items-center justify-center mt-2">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => handleSelectDesign(design)}
                />
                <span className="ml-2">Select</span>
              </div>
              {isSelected && (
                <div className="flex justify-between items-center">
                  <label
                    htmlFor={`quantity-${design.id}`}
                    className="mt-2 block text-sm font-regular text-center text-gray-700"
                  >
                    Quantity:
                  </label>
                  <input
                    type="number"
                    id={`quantity-${design.id}`}
                    value={
                      rows[SelectedItemRowIndex]?.selectedDesigns?.find((d) => d.sub_item_id === design.sub_item_id)?.quantity || ''
                    }
                    onChange={(e) =>
                      handleQuantityChangeForDesign(
                        design.sub_item_id,
                        design.id,
                        parseInt(e.target.value, 10)
                      )
                    }
                    className="w-1/3 p-1 border rounded-md"
                    min="1"
                  />
                  {SelectedItemRow?.UOM}
                </div>
              )}
            </div>
          </div>
        );
      })}
  </div>


{/* 
          <div className="mt-auto w-full">
            <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
            <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
              <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md">
                Save
              </button>
              <button onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
            </div>
          </div> */}
        </div>
      </div>
    )}

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}

{/* POPup ends */}
    </div>
  );
};

export default QuoteForm;
