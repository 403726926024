import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faImage } from '@fortawesome/free-solid-svg-icons';

const ProductSelectionModal = ({ 
  isVisible, 
  onClose, 
  category,
  onProductSelect,
  packageName 
}) => {
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [onClose]);

  const handleProductSelection = (product) => {
    if (onProductSelect) {
      onProductSelect(product);
    }
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center">
      <div className="bg-white w-full max-w-2xl rounded-lg overflow-hidden">
        {/* Modal Header */}
        <div className="flex items-center p-4 border-b border-gray-200">
          <button onClick={onClose} className="mr-4">
            <FontAwesomeIcon icon={faArrowLeft} className="text-2xl text-[#736f70]" />
          </button>
          <h2 className="text-xl font-bold text-gray-800">
            Select {category?.room_category} Product
          </h2>
        </div>

        {/* Product List */}
        <div className="max-h-[70vh] overflow-y-auto">
          {category?.products.map((product, index) => (
            <button
              key={product._id || index}
              className="flex items-center p-4 border-b border-gray-100 w-full hover:bg-gray-50"
              onClick={() => handleProductSelection(product)}
            >
              {/* Product Image Placeholder */}
              <div className="w-20 h-20 bg-gray-200 rounded-lg mr-4 flex items-center justify-center">
                <FontAwesomeIcon icon={faImage} className="text-3xl text-[#736f70]" />
              </div>

              <div className="flex-1 text-left">
                <h3 className="text-base font-medium text-gray-800">
                  {product.product_name}
                </h3>
                <p className="text-sm text-gray-600 mt-1">
                  ₹{product.selling_price}/sq.ft
                </p>
                {product.default && (
            <span className="inline-block bg-green-100 px-2 py-1 rounded-full mt-2 text-xs text-green-800">
              {packageName || 'Default Package'}
            </span>
          )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSelectionModal;
