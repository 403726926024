import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect } from "react";
import React from 'react';


// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch, showMultiSelect  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    if (showMultiSelect) {
      const currentSelected = Array.isArray(selected) ? selected : [];
      const isSelected = currentSelected.find(item => item.value === option.value);
      
      if (isSelected) {
        onSelect(currentSelected.filter(item => item.value !== option.value));
      } else {
        onSelect([...currentSelected, option]);
      }
    } else {
      onSelect(option);
      setIsOpen(false);
    }
  };
  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.map((option, index) => (
      <div
        key={option.value}
        data-index={index}
        className={`p-2 cursor-pointer text-sm flex items-center ${
          index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''
        } hover:bg-blue-500 hover:text-white`}
        onClick={() => handleOptionClick(option)}
      >
        {showMultiSelect && (
          <input
            type="checkbox"
            checked={selected?.some(item => item.value === option.value)}
            onChange={() => handleOptionClick(option)}
            className="mr-2"
          />
        )}
        {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
        {option.label}
      </div>
    ))}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const WindowsData = [
    {    
      package_id: 1,
      package_name: "Economy",
      vendors: [
        {
          _id: "679e2a772beb1f4a0ca778ff",
          name: "Fenesta",
          default: true
        },
        {
          _id: "678e1bdf3ce3c1c267977a1e",
          name: "Krafto",
          default: false
        }
      ],
      product_mapping: [
        {
          category: "Sliding",
          products: [
            { _id: "67a05ec744f77126a0a16d24", name: "2 Track Sliding Window", default: true },
            { _id: "67a0637144f77126a0a171e7", name: "3 Track Sliding Window", default: false },
            { _id: "67a096e8b29a29762d96f314", name: "4 Track Sliding Window", default: false }
          ],
          applied_windows: ["Living", "Dining", "Kitchen", "Bedroom 1"]
        },
        {
          category: "Casement",
          products: [
            { _id: "67a0653a44f77126a0a174b5", name: "Single Casement Window", default: true },
            { _id: "67ab56169605272e7d5c40c2", name: "Double Casement Window", default: true }
          ],
          applied_windows: ["Living", "Dining", "Kitchen"]
        },
        {
          category: "French Door",
          products: [
            { _id: "67a067ab44f77126a0a17712", name: "Single French Door", default: true },
            { _id: "67ab56a19605272e7d5c4130", name: "Double French Door", default: false }
          ],
          applied_windows: ["Living", "Dining", "Kitchen", "Balcony"]
        },
        {
          category: "Ventilator",
          products: [
            { _id: "67a0683244f77126a0a17812", name: "Top Hung Ventilator", default: true },
            { _id: "67ab57089605272e7d5c41a4", name: "Bottom Hung Ventilator", default: false }
          ],
          applied_windows: ["Bathroom", "Kitchen", "Utility"]
        }
      ]
    }
  ];
  
  
  const EditWindowsTemplate = ({ selectedPackage, vendors, products, subCategories, packages, onVendorsSelect, onDefaultVendorSelect, onProductSelect, onDefaultProductSelect }) => {
    console.log(subCategories);
    console.log(products);
    ;
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [defaultVendor, setDefaultVendor] = useState(null);
    const [activeTab, setActiveTab] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    //console.log("selectedProducts", selectedProducts);
    const [selectedProductsByCategory, setSelectedProductsByCategory] = useState({});
    const [defaultProducts, setDefaultProducts] = useState({}); // Store defaults by category
    
    const [errorMessage, setErrorMessage] = useState('');
    
console.log("defaultProducts", defaultProducts);
    useEffect(() => {
      if (packages?.roomCategories?.Windows?.length > 0) {
        setActiveTab(packages.roomCategories.Windows[0]);
      }
    }, [packages]);

    useEffect(() => {
        if (selectedPackage) {
          // Initialize selected products from existing data
          const existingProducts = {};
          const existingDefaults = {};
      
          selectedPackage.product_mapping.forEach(category => {
            existingProducts[category.category] = category.products;
            
            // Set default products
            const defaultProds = category.products.filter(p => p.default);
            if (defaultProds.length > 0) {
              existingDefaults[category.category] = defaultProds.map(p => p._id).join(',');
            }
          });
      
          setSelectedProducts(existingProducts);
          setDefaultProducts(existingDefaults);
          
          // Set initial active tab
          if (selectedPackage.product_mapping.length > 0) {
            setActiveTab(selectedPackage.product_mapping[0].category);
          }
        }
      }, [selectedPackage]);
      
  
    useEffect(() => {
        if (selectedPackage?.vendors && vendors.length > 0) {
          const vendorSelections = selectedPackage.vendors.map(vendor => ({
            value: vendor._id,
            label: vendors.find(v => v._id === vendor._id)?.name || ''
          }));
          setSelectedVendors(vendorSelections);
          onVendorsSelect(vendorSelections);
    
          const defaultVendorId = selectedPackage.vendors.find(v => v.default)?._id;
          if (defaultVendorId) {
            setDefaultVendor(defaultVendorId);
            onDefaultVendorSelect(defaultVendorId);
          }
        }
      }, [selectedPackage, vendors]);

 // Update the products initialization useEffect
 useEffect(() => {
  if (selectedPackage?.product_mapping && products) {
    const initialProductsByCategory = {};
    const initialDefaults = {};

    selectedPackage.product_mapping.forEach(mapping => {
      const categoryProducts = mapping.products.map(product => {
        const productDetails = Object.values(products)
          .flat()
          .find(p => p._id === product._id);
          
        return {
          _id: product._id,
          product_name: productDetails?.product_name,
          selling_price: productDetails?.selling_price,
          GST: productDetails?.GST,
          product_image: productDetails?.product_image,
          default: product.default,
          vendor_id: productDetails?.vendor_id
        };
      }).filter(Boolean);

      initialProductsByCategory[mapping.category] = categoryProducts;
      
      const defaultProductIds = mapping.products
        .filter(product => product.default)
        .map(product => product._id)
        .join(',');
      
      initialDefaults[mapping.category] = defaultProductIds;
    });

    setSelectedProducts(initialProductsByCategory);
    setDefaultProducts(initialDefaults);
  }
}, [selectedPackage, products]);

  
      
      
  
    const handleVendorSelect = (selectedOptions) => {
      setSelectedVendors(selectedOptions);
      if (onVendorsSelect) {
        onVendorsSelect(selectedOptions);
      }
    };

    const handleDefaultVendorSelect = (vendorId) => {
      setDefaultVendor(vendorId);
      if (onDefaultVendorSelect) {
        onDefaultVendorSelect(vendorId);
      }
    };

    const vendorsList = Array.isArray(vendors) ? vendors : [];
    //console.log(vendorsList);


    const handleProductSelect = (product) => {
      setSelectedProducts(prev => {
        const currentTabProducts = prev[activeTab] || [];
        const isSelected = currentTabProducts.some(p => p._id === product._id);
    
        const updatedProducts = {
          ...prev,
          [activeTab]: isSelected
            ? currentTabProducts.filter(p => p._id !== product._id)
            : [...currentTabProducts, product]
        };
    
        // Only handle default product logic when deselecting
        if (isSelected) {
          setDefaultProducts(prev => ({
            ...prev,
            [activeTab]: prev[activeTab]?.split(',')
              .filter(id => id !== product._id)
              .join(',') || null
          }));
        }
    
        if (onProductSelect) {
          onProductSelect(activeTab, isSelected
            ? currentTabProducts.filter(p => p._id !== product._id)
            : [...currentTabProducts, product]
          );
        }
    
        return updatedProducts;
      });
    };
    
    
      
    const handleDefaultProductSelect = (productId, category, vendorId) => {
      // Get flattened products array
      const flatProducts = Object.values(products).flat();
    
      // First update the default products
      setDefaultProducts(prev => {
        const currentDefaults = prev[category]?.split(',') || [];
        const filteredDefaults = currentDefaults.filter(id => {
          const product = flatProducts.find(p => p._id === id);
          return product?.vendor_id !== vendorId;
        });
        filteredDefaults.push(productId);
        const newDefaults = filteredDefaults.join(',');
    
        if (onDefaultProductSelect) {
          onDefaultProductSelect(category, newDefaults);
        }
    
        return {
          ...prev,
          [category]: newDefaults
        };
      });
    
      const productsForCategory = selectedProducts[category] || [];
    
      const updatedProducts = productsForCategory.map(product => ({
        ...product,
        default: product._id === productId ||
                (defaultProducts[category]?.split(',').includes(product._id) &&
                 flatProducts.find(p => p._id === product._id)?.vendor_id !== vendorId)
      }));
    
      setSelectedProducts(prev => ({
        ...prev,
        [category]: updatedProducts
      }));
    
      if (onProductSelect) {
        onProductSelect(category, updatedProducts);
      }
    };
    
      
      
    const handleTabChange = (category) => {
      // First get available products for current tab/category
      const subcategory = subCategories.find(sc => 
        sc.product_sub_category_name.toLowerCase() === activeTab.toLowerCase()
      );
      const availableProducts = products[subcategory?._id] || [];
    
      // Filter only vendors who have products in this category
      const vendorsWithProducts = selectedVendors.filter(vendor => 
        availableProducts.some(p => p.vendor_id === vendor.value)
      );
    
      // Check product selection only for vendors with available products
      const vendorsWithoutSelection = vendorsWithProducts
        .filter(vendor => {
          const vendorProducts = selectedProducts[activeTab]?.filter(p => p.vendor_id === vendor.value) || [];
          return vendorProducts.length === 0;
        })
        .map(vendor => vendor.label);
    
      if (vendorsWithoutSelection.length > 0) {
        setErrorMessage(`Please select products for vendor(s): ${vendorsWithoutSelection.join(', ')} in ${activeTab} before switching tabs`);
        return;
      }
    
      // Check defaults only for vendors with products and selections
      const hasAllDefaults = vendorsWithProducts.every(vendor => {
        const vendorProducts = selectedProducts[activeTab]?.filter(p => p.vendor_id === vendor.value) || [];
        return vendorProducts.some(p => defaultProducts[activeTab]?.split(',').includes(p._id));
      });
    
      if (!hasAllDefaults) {
        setErrorMessage(`Please select default products for vendors in ${activeTab} before switching tabs`);
        return;
      }
    
      setErrorMessage('');
      setActiveTab(category);
    };
    


      const renderProducts = (category) => {
        console.log('Current Category:', category);
        console.log('Available Subcategories:', subCategories);
        const subcategory = subCategories.find(sc => 
          sc.product_sub_category_name.toLowerCase() === category.toLowerCase()
        );
        console.log('Matched Subcategory:', subcategory);
        console.log('Available Products:', products);
        if (!subcategory) return null;
      
        // Get products for this subcategory from the grouped products
        const categoryProducts = products[subcategory._id] || [];
        console.log("Category Products:", categoryProducts);
      
        return (
          <div className="mt-6">
            {categoryProducts.map(product => (
              <div key={product._id}      
                className="flex items-center justify-start space-x-10 p-2 border rounded hover:bg-gray-50 mb-2 cursor-pointer"
                onClick={() => handleProductSelect(product)}
              >
                <div className="flex items-center w-full">
                  <input
                    type="checkbox"
                    checked={selectedProducts[activeTab]?.some(p => p._id === product._id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleProductSelect(product)}
                    className="h-4 w-4 text-[#EA011F] rounded border-gray-300 focus:ring-[#EA011F] mr-6"
                  />
                  <div className="flex items-center space-x-4">
                    <img
                      src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${product.product_image}`}
                      alt={product.product_name}
                      className="w-10 h-10 rounded-lg object-cover"
                    />
                    <div>
                      <span className="text-base font-medium text-gray-800">{product.product_name}</span>
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-500">₹{product.selling_price}</span>
                        <span className="text-xs text-gray-400">
                          {product.tax_preference} • GST {product.GST}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      };
      
      
      
      const renderDefaultProductsSection = () => {
        if (!selectedProducts[activeTab]?.length) return null;
    
        return (
          <div className="mt-6 mb-8">
            <label className="block text-sm font-regular text-gray-700 mb-2">
              Default {activeTab} Product
            </label>
            {selectedVendors.map(vendor => {
              // Get products for this vendor from the grouped products object
              const vendorProducts = Object.values(products)
                .flat()
                .filter(product => 
                  product.vendor_id === vendor.value && 
                  selectedProducts[activeTab]?.some(sp => sp._id === product._id)
                );
              
              return (
                <div key={vendor.value} className="mb-6">
                  <div className="text-sm font-medium text-gray-700 mb-2">
                    {vendor.label}
                  </div>
                  <div className="flex gap-4">
                    {vendorProducts.map(product => (
                      <div key={product._id} className="flex items-center space-x-2 p-2 border rounded-md">
                        <input
                          type="radio"
                          id={`default-${product._id}`}
                          name={`default-${activeTab}-${vendor.value}`}
                          checked={defaultProducts[activeTab]?.split(',').includes(product._id)}
                          onChange={() => handleDefaultProductSelect(product._id, activeTab, vendor.value)}
                          className="text-[#EA011F] focus:ring-[#EA011F]"
                        />
                        <label htmlFor={`default-${product._id}`} className="text-gray-700">
                          {product.product_name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        );
      };
      
    
    return (
      <div className="w-full">
        {/* Vendors Dropdown Section */}
        <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label className="block text-sm font-regular text-gray-700 w-1/6">
    Vendors
  </label>  <div className="w-1/3">
  <CustomDropdown
  options={vendorsList.map(vendor => ({
    value: vendor._id,
    label: vendor.name
  }))}
  selected={selectedVendors}
  onSelect={handleVendorSelect}
  placeholder="Select Vendors"
  showSearch={true}
  showMultiSelect={true}
/>

  </div>
</div>

 {/* Selected Vendors Display */}
 {selectedVendors.length > 0 && (
  <div className="mb-8 flex items-center">
    <label className="block text-sm font-regular text-gray-700 w-1/6">
      Default Vendor Selection
    </label>
    <div className="flex gap-4">
      {selectedVendors.map(vendor => (
        <div key={vendor.value} className="flex items-center space-x-2 p-2 border rounded-md">
          <input
            type="radio"
            id={vendor.value}
            name="defaultVendor"
            checked={defaultVendor === vendor.value}
            onChange={() => handleDefaultVendorSelect(vendor.value)}
            className="text-[#EA011F] focus:ring-[#EA011F]"
          />
          <label htmlFor={vendor.value} className="text-gray-700">
            {vendor.label}
          </label>
        </div>
      ))}
    </div>
  </div>
)}


  
        {/* Categories Tabs */}
        <div className="border-b border-gray-200 mb-6">
        <div className="flex space-x-4">
          {packages?.roomCategories?.Windows.map((category) => (
            <button
              key={category}
              onClick={() => handleTabChange(category)}
              className={`py-2 px-4 ${
                activeTab === category
                  ? 'border-b-2 border-[#EA011F] text-[#EA011F] font-medium'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      {/* {products.map(product => ( */}

        {/* Products Display */}
        <div className="mt-6">
        {renderProducts(activeTab)}
</div>

{renderDefaultProductsSection()}



{errorMessage && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl transform transition-all">
      <div className="flex items-center mb-4">
        <svg className="w-6 h-6 text-red-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h3 className="text-lg font-semibold text-gray-900">Action Required</h3>
      </div>
      <p className="text-gray-700 mb-6">{errorMessage}</p>
      <div className="flex justify-center">
        <button 
          onClick={() => setErrorMessage('')}
          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          close
        </button>
      </div>
    </div>
  </div>
)}





      </div>
    );
  };
  
  

export default EditWindowsTemplate;
