import { faChevronDown, faChevronUp, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from 'react';



// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ 
  options = [], 
  selected = null, 
  onSelect, 
  placeholder = 'Select an option',
  showSearch = false,
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  
  const filteredOptions = options.filter(option => 
    option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
    setSearchTerm('');
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    onSelect(null);
    setSearchTerm('');
  };

  return (
    <div ref={dropdownRef} className={`relative ${className}`}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full p-2 border border-gray-300 rounded-md bg-white cursor-pointer"
      >
        <span className={`block truncate ${selected ? 'text-gray-900' : 'text-gray-500'}`}>
          {selected?.label || placeholder}
        </span>
        <div className="flex items-center">
          {selected && (
            <button
              onClick={clearSelection}
              className="p-1 hover:bg-gray-100 rounded-full mr-1"
            >
              <FontAwesomeIcon icon={faTimes} className="text-gray-400 text-sm" />
            </button>
          )}
          <FontAwesomeIcon 
            icon={isOpen ? faChevronUp : faChevronDown} 
            className="text-gray-400"
          />
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {showSearch && (
            <div className="p-2 border-b border-gray-200">
              <div className="relative">
                <FontAwesomeIcon 
                  icon={faSearch} 
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#EA011F] focus:border-[#EA011F]"
                  placeholder="Search..."
                />
              </div>
            </div>
          )}
          
          <div className="max-h-60 overflow-y-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className={`
                    px-4 py-2 cursor-pointer hover:bg-gray-100
                    ${selected?.value === option.value ? 'bg-red-50 text-[#EA011F]' : 'text-gray-900'}
                  `}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-gray-500 text-center">
                No options found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const DoorsTemplate = ({ 
  vendors = [], 
  products = {}, 
  onVendorsSelect = () => {}, 
  roomCategories = {},
  onAddonVendorSelect = () => {}, 
  setSelectedProductsData = () => {},
  onDefaultProductSelectData = () => {}
}) => {
  // Initialize addon selections
  const initialAddonSelections = (roomCategories?.DoorSubcategoryAddons || []).reduce((acc, addon) => {
    if (addon) acc[addon] = null;
    return acc;
  }, {});

  // Initialize default products state
  const initializeDefaultProducts = () => {
    if (Array.isArray(roomCategories?.Doors)) {
      return roomCategories.Doors.reduce((acc, room) => {
        if (room) {
          acc[room] = {
            preEngineeredDoors: null,
            onlyDoors: null
          };
        }
        return acc;
      }, {});
    }
    return {};
  };

  // Initialize selected products state
  const initializeSelectedProducts = () => {
    if (Array.isArray(roomCategories?.Doors)) {
      return roomCategories.Doors.reduce((acc, room) => {
        if (room) {
          acc[room] = {
            preEngineeredDoors: [],
            onlyDoors: []
          };
        }
        return acc;
      }, {});
    }
    return {};
  };

  const [selectedVendors, setSelectedVendors] = useState([]);
  const [defaultVendor, setDefaultVendor] = useState(null);
  const [defaultProducts, setDefaultProducts] = useState(initializeDefaultProducts);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [addonVendorSelections, setAddonVendorSelections] = useState(initialAddonSelections);
  const [activeTab, setActiveTab] = useState(Array.isArray(roomCategories?.Doors) ? roomCategories.Doors[0] : '');
  const [selectedProducts, setSelectedProducts] = useState(initializeSelectedProducts);
  const [errorMessage, setErrorMessage] = useState('');

  // Format vendor options
  useEffect(() => {
    if (Array.isArray(vendors)) {
      const formattedVendors = vendors
        .filter(vendor => vendor?._id && vendor?.name)
        .map(vendor => ({
          value: vendor._id,
          label: vendor.name
        }));
      setVendorOptions(formattedVendors);
    }
  }, [vendors]);

  // Handle vendor selection
  const handleVendorSelect = (selectedVendors) => {
    if (Array.isArray(selectedVendors)) {
      setSelectedVendors(selectedVendors);
      onVendorsSelect(selectedVendors);
    }
  };

  // Update default products
  useEffect(() => {
    if (defaultProducts && Object.keys(defaultProducts).length > 0) {
      onDefaultProductSelectData(defaultProducts);
    }
  }, [defaultProducts]);

  // Update selected products
  useEffect(() => {
    if (selectedProducts && Object.keys(selectedProducts).length > 0) {
      setSelectedProductsData(selectedProducts);
    }
  }, [selectedProducts]);

  // Handle product selection
  const handleProductSelect = (product, doorType) => {
    if (!product?._id || !doorType || !activeTab) return;

    setSelectedProducts(prev => {
      const currentSelection = prev[activeTab]?.[doorType] || [];
      const isSelected = currentSelection.some(p => p._id === product._id);

      const newSelection = isSelected
      ? currentSelection.filter(p => p._id !== product._id)
      : [...currentSelection, product];

    // Auto-select default if only one product
    if (newSelection.length === 1) {
      handleDefaultProductSelect(newSelection[0]._id, doorType);
    }
    // Clear default if no products selected
    if (newSelection.length === 0) {
      handleDefaultProductSelect(null, doorType);
    }

      return {
        ...prev,
        [activeTab]: {
          ...prev[activeTab],
          [doorType]: isSelected
            ? currentSelection.filter(p => p._id !== product._id)
            : [...currentSelection, product]
        }
      };
    });
  };

  // Handle default product selection
  const handleDefaultProductSelect = (productId, doorType) => {
    if (!productId || !doorType || !activeTab) return;

    setDefaultProducts(prev => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        [doorType]: productId
      }
    }));
  };

  // Handle addon vendor selection
  const handleAddonVendorSelect = (vendor, addonType) => {
    if (!vendor || !addonType) return;

    const newSelections = {
      ...addonVendorSelections,
      [addonType]: vendor
    };
    setAddonVendorSelections(newSelections);
    onAddonVendorSelect(newSelections);
  };

   
  const handleTabChange = (category) => {
    // Check if products exist for each type
    const hasPreEngAvailable = products?.preEngineeredDoors?.length > 0;
    const hasOnlyDoorsAvailable = products?.onlyDoors?.length > 0;
  
    // Current selections
    const hasPreEngProducts = selectedProducts[activeTab]?.preEngineeredDoors?.length > 0;
    const hasOnlyDoorProducts = selectedProducts[activeTab]?.onlyDoors?.length > 0;
    const hasPreEngDefault = defaultProducts[activeTab]?.preEngineeredDoors;
    const hasOnlyDoorsDefault = defaultProducts[activeTab]?.onlyDoors;
  
    // Validate only available product types
    if (hasPreEngAvailable) {
      if (!hasPreEngProducts || !hasPreEngDefault) {
        setErrorMessage('Please select products and default products for Pre-engineered Doors before changing tabs.');
        return;
      }
    }
  
    if (hasOnlyDoorsAvailable) {
      if (!hasOnlyDoorProducts || !hasOnlyDoorsDefault) {
        setErrorMessage('Please select products and default products for Only Doors before changing tabs.');
        return;
      }
    }
  
    setActiveTab(category);
  };
  
  
  // Render product item
  const renderProductItem = (product, doorType) => {
    if (!product?._id) return null;


    return (
      <div key={product._id} className="flex items-center justify-start space-x-10 p-2 border rounded hover:bg-gray-50 mb-2 cursor-pointer" 
      onClick={() => handleProductSelect(product, doorType)}
      >
        <div className="flex items-center w-full">
         <input
          type="checkbox"
          checked={selectedProducts[activeTab]?.[doorType]?.some?.(p => p._id === product._id) ?? false}
          onClick={(e) => e.stopPropagation()}
          onChange={() => handleProductSelect(product, doorType)}
          className="h-4 w-4 text-[#EA011F] rounded border-gray-300 focus:ring-[#EA011F] mr-6"
        />
        <div className="flex items-center space-x-4">
          {product.product_image && (
            <img
              src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${product.product_image}`}
              alt={product.product_name || 'Product'}
              className="w-10 h-10 rounded-lg object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'fallback-image-url';
              }}
            />
          )}
          <div>
            <span className="text-base font-medium text-gray-800">
              {product.product_name}
              {defaultProducts[activeTab]?.[doorType] === product._id && (
                <span className="ml-2 text-sm text-green-600">(Default)</span>
              )}
            </span>
            <div className="flex flex-col">
              <span className="text-sm text-gray-500">
                ₹{Number(product.selling_price).toLocaleString('en-IN')}
              </span>
              <span className="text-xs text-gray-400">
                {product.tax_preference || 'Tax Inclusive'} • GST {product.GST || 0}%
              </span>
            </div>
          </div>
        </div>
       </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      {/* Addon Products Section */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label className="block text-sm font-regular text-gray-700 w-1/6">
          Add-on Products
        </label>
      </div>

      {/* Addon Vendor Selection */}
      {Array.isArray(roomCategories?.DoorSubcategoryAddons) && 
        roomCategories.DoorSubcategoryAddons.map((item) => (
          item && (
            <div key={item} className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
              <label className="block text-sm font-regular text-gray-700 w-1/6">
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </label>
              <div className="w-1/3">
                <CustomDropdown
                  options={vendorOptions}
                  selected={addonVendorSelections[item]}
                  onSelect={(vendor) => handleAddonVendorSelect(vendor, item)}
                  placeholder="Select Vendor"
                  showSearch={true}
                />
              </div>
            </div>
          )
      ))}

      {/* Room Categories Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <div className="flex space-x-4">
          {Array.isArray(roomCategories?.Doors) && roomCategories.Doors.map((category) => (
            category && (
              <button
                key={category}
                onClick={() => handleTabChange(category)}
                className={`py-2 px-4 ${
                  activeTab === category
                    ? 'border-b-2 border-[#EA011F] text-[#EA011F] font-medium'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                {category}
              </button>
            )
          ))}
        </div>
      </div>

      {/* Pre-engineered Doors Section */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">Pre-engineered Doors</h3>
        {Array.isArray(products?.preEngineeredDoors) && 
          products.preEngineeredDoors.map(product => renderProductItem(product, 'preEngineeredDoors'))}
      </div>

      {/* Only Doors Section */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">Only Doors</h3>
        {Array.isArray(products?.onlyDoors) && 
          products.onlyDoors.map(product => renderProductItem(product, 'onlyDoors'))}
      </div>

      {/* Default Product Selection Sections */}
      {selectedProducts[activeTab]?.preEngineeredDoors?.length > 0 && (
        <div className="mt-6 mb-8">
          <label className="block text-sm font-regular text-gray-700 mb-2">
            Default Pre-engineered Door Product for {activeTab}
          </label>
          <div className="flex gap-4">
            {selectedProducts[activeTab].preEngineeredDoors.map(product => (
              <div key={product._id} className="flex items-center space-x-2 p-2 border rounded-md">
                <input
                  type="radio"
                  id={`default-pre-eng-${activeTab}-${product._id}`}
                  name={`default-pre-eng-doors-${activeTab}`}
                  checked={defaultProducts[activeTab]?.preEngineeredDoors === product._id}
                  onChange={() => handleDefaultProductSelect(product._id, 'preEngineeredDoors')}
                  className="text-[#EA011F] focus:ring-[#EA011F]"
                />
                <label htmlFor={`default-pre-eng-${activeTab}-${product._id}`} className="text-gray-700">
                  {product.product_name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedProducts[activeTab]?.onlyDoors?.length > 0 && (
        <div className="mt-6 mb-8">
          <label className="block text-sm font-regular text-gray-700 mb-2">
            Default Only Door Product for {activeTab}
          </label>
          <div className="flex gap-4">
            {selectedProducts[activeTab].onlyDoors.map(product => (
              <div key={product._id} className="flex items-center space-x-2 p-2 border rounded-md">
                <input
                  type="radio"
                  id={`default-only-${activeTab}-${product._id}`}
                  name={`default-only-doors-${activeTab}`}
                  checked={defaultProducts[activeTab]?.onlyDoors === product._id}
                  onChange={() => handleDefaultProductSelect(product._id, 'onlyDoors')}
                  className="text-[#EA011F] focus:ring-[#EA011F]"
                />
                <label htmlFor={`default-only-${activeTab}-${product._id}`} className="text-gray-700">
                  {product.product_name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}

{errorMessage && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl transform transition-all">
      <div className="flex items-center mb-4">
        <svg className="w-6 h-6 text-red-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h3 className="text-lg font-semibold text-gray-900">Action Required</h3>
      </div>
      <p className="text-gray-700 mb-6">{errorMessage}</p>
      <div className="flex justify-center">
        <button 
          onClick={() => setErrorMessage('')}
          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default DoorsTemplate;

