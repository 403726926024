import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect } from "react";
import React from 'react';


// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch, showMultiSelect  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    if (showMultiSelect) {
      const currentSelected = Array.isArray(selected) ? selected : [];
      const isSelected = currentSelected.find(item => item.value === option.value);
      
      if (isSelected) {
        onSelect(currentSelected.filter(item => item.value !== option.value));
      } else {
        onSelect([...currentSelected, option]);
      }
    } else {
      onSelect(option);
      setIsOpen(false);
    }
  };
  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.map((option, index) => (
      <div
        key={option.value}
        data-index={index}
        className={`p-2 cursor-pointer text-sm flex items-center ${
          index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''
        } hover:bg-blue-500 hover:text-white`}
        onClick={() => handleOptionClick(option)}
      >
        {showMultiSelect && (
          <input
            type="checkbox"
            checked={selected?.some(item => item.value === option.value)}
            onChange={() => handleOptionClick(option)}
            className="mr-2"
          />
        )}
        {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
        {option.label}
      </div>
    ))}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const TilesData = [
  {
    package_id: 1,
    package_name:"Economy",
    vendors: [
      {
        _id:"678e1bdf3ce3c1c267977a1e",
        name: "Kajaria",
        default: true
      },
      {
         _id:"679a0fdfe71a4a31d2b9fbc9",
         name: "Somany",
         default:false
      }
    ],
    room_categories: [
      {
        room_category: "Flat Flooring",
        products: [
          { _id: "678e1c2e3ce3c1c267977a71", name: "Premium Vitrified Tiles", default: true },
          { _id: "679b0dfc58a1f4530c84be9e", name: "Double Charged Vitrified", default: false }
        ],
        rooms: ["Living", "Dining", "Kitchen", "Bedroom 1", "Bedroom 2", "Bedroom 3", "Bedroom 4", "Storeroom", "Pooja Room"]
      },
      {
        room_category: "Bathroom Walls",
        products: [
          { _id: "678e1c2e3ce3c1c267977a71", name: "Digital Wall Tiles", default: true },
          { _id: "679b0dfc58a1f4530c84be9f", name: "Ceramic Wall Tiles", default: false }
        ],
        rooms: ["Bathroom 1", "Bathroom 2", "Bathroom 3", "Bathroom 4"]
      },
      {
        room_category: "Kitchen Walls",
        products: [
          { _id: "679b0dfc58a1f4530c84bea0", name: "Kitchen Wall Tiles", default: true },
          { _id: "679b0dfc58a1f4530c84bea1", name: "Designer Kitchen Tiles", default: false }
        ],
        rooms: ["Kitchen"]
      }
    ]
  }
];
  
  
const EditTilesTemplate = ({ selectedPackage, vendors, productGroups, packages, onVendorsSelect, onDefaultVendorSelect, onProductSelect, onDefaultProductSelect  }) => {
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [defaultVendor, setDefaultVendor] = useState(null);
    const [activeTab, setActiveTab] = useState(packages?.roomCategories?.Tiles[0]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductsByCategory, setSelectedProductsByCategory] = useState({});
    const [defaultProducts, setDefaultProducts] = useState({});
    const [errorMessage, setErrorMessage] = useState('');


    const handleVendorSelect = (vendors) => {
      setSelectedVendors(vendors);
      if (onVendorsSelect) {
        onVendorsSelect(vendors);
      }
    };

    useEffect(() => {
        if (selectedPackage?.vendors && vendors.length > 0) {
          // Set selected vendors
          const vendorSelections = selectedPackage.vendors.map(vendor => ({
            value: vendor._id,
            label: vendors.find(v => v._id === vendor._id)?.name || ''
          }));
          setSelectedVendors(vendorSelections);
          onVendorsSelect(vendorSelections);
    
          // Set default vendor
          const defaultVendorId = selectedPackage.vendors.find(v => v.default)?._id;
          if (defaultVendorId) {
            setDefaultVendor(defaultVendorId);
            onDefaultVendorSelect(defaultVendorId);
          }
        }
      }, [selectedPackage, vendors]);

      useEffect(() => {
        if (selectedPackage?.room_categories) {
          // Initialize selected products by category
          const initialProductsByCategory = {};
          selectedPackage.room_categories.forEach(category => {
            initialProductsByCategory[category.room_category] = category.products;
          });
          setSelectedProductsByCategory(initialProductsByCategory);
    
          // Initialize default products
          const initialDefaults = {};
          selectedPackage.room_categories.forEach(category => {
            const defaultProduct = category.products.find(p => p.default);
            if (defaultProduct) {
              initialDefaults[category.room_category] = defaultProduct._id;
              onDefaultProductSelect(category.room_category, defaultProduct._id);
            }
          });
          setDefaultProducts(initialDefaults);
        }
      }, [selectedPackage]);
    




      const handleProductSelect = (product) => {
        setSelectedProductsByCategory(prev => {
          const currentSelected = prev[activeTab] || [];
          const isSelected = currentSelected.some(p => p._id === product._id);
          
          // If unchecking default product, just clear the default selection
          if (isSelected && defaultProducts[activeTab] === product._id) {
            setDefaultProducts(prev => ({
              ...prev,
              [activeTab]: null
            }));
          }
      
          const updatedProducts = {
            ...prev,
            [activeTab]: isSelected 
              ? currentSelected.filter(p => p._id !== product._id)
              : [...currentSelected, product]
          };
      
          if (onProductSelect) {
            onProductSelect(activeTab, updatedProducts[activeTab]);
          }
      
          return updatedProducts;
        });
      };

      
    const handleDefaultProductSelect = (productId, category) => {
      // Update local state
      setDefaultProducts(prev => ({
        ...prev,
        [category]: productId
      }));
    
      // Force update to parent component even if products haven't changed
      const productsForCategory = selectedProductsByCategory[category] || [];
      
      // Update the products array with new default status
      const updatedProducts = productsForCategory.map(product => ({
        ...product,
        default: product._id === productId
      }));
    
      // Notify parent of both product and default changes
      if (onProductSelect) {
        onProductSelect(category, updatedProducts);
      }
      
      if (onDefaultProductSelect) {
        onDefaultProductSelect(category, productId);
      }
    };
    
    const handleTabChange = (category) => {
      // First check if products are selected
      if (!selectedProductsByCategory[activeTab] || selectedProductsByCategory[activeTab].length === 0) {
        setErrorMessage('Please select a product before changing tabs.');
        return;
      }
    
      // Then check for default product only if products are selected
      if (selectedProductsByCategory[activeTab]?.length > 0 && !defaultProducts[activeTab]) {
        setErrorMessage('Please select a default product before changing tabs.');
        return;
      }
    
      setErrorMessage('');
      setActiveTab(category);
    };
    

  const handleDefaultVendorSelect = (vendorId) => {
    setDefaultVendor(vendorId);
    if (onDefaultVendorSelect) {
      onDefaultVendorSelect(vendorId);
    }
  };
  

  
    return (
      <div className="w-full">
        <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
        <label className="block text-sm font-regular text-[#e54643] w-1/6">
            Vendors<span className="text-red-500">*</span>
          </label>
          <div className="w-1/3">
          <CustomDropdown
             options={vendors.map(vendor => ({
              value: vendor._id,
              label: vendor.name
            }))}
            selected={selectedVendors}
            onSelect={handleVendorSelect}
            placeholder="Select Vendors"
            showSearch={true}
            showMultiSelect={true}
          />
  

          </div>
        </div>
  
        {selectedVendors.length > 0 && (
  <div className="mb-8 flex items-center">
    <label className="block text-sm font-regular text-gray-700 w-1/6">
      Default Vendor Selection
    </label>
    <div className="flex gap-4">
      {selectedVendors.filter(vendor => vendor.value && vendor.label).map(vendor => (
        <div key={vendor.value} className="flex items-center space-x-2 p-2 border rounded-md">
          <input
            type="radio"
            id={vendor.value}
            name="defaultVendor"
            checked={defaultVendor === vendor.value}
            onChange={() => handleDefaultVendorSelect(vendor.value)}
            className="text-[#EA011F] focus:ring-[#EA011F]"
          />
          <label htmlFor={vendor.value} className="text-gray-700">
            {vendor.label}
          </label>
        </div>
      ))}
    </div>
  </div>
)}

  
  <div className="border-b border-gray-200 mb-6">
        <div className="flex space-x-4">
          {packages?.roomCategories?.Tiles.map((category) => (
            <button
              key={category}
              onClick={() => handleTabChange(category)}
              className={`py-2 px-4 ${
                activeTab === category
                  ? 'border-b-2 border-[#EA011F] text-[#EA011F] font-medium'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-6">
  {productGroups.map((product) => (
    <div 
      key={product._id} 
      className="flex items-center justify-start space-x-6 p-2 border rounded hover:bg-gray-50 mb-2 cursor-pointer"
      onClick={() => handleProductSelect(product)}
    >
      <div className="flex items-center w-full">
        <input
          type="checkbox"
          checked={(selectedProductsByCategory[activeTab] || []).some(p => p._id === product._id)}
          className="h-4 w-4 text-[#EA011F] rounded border-gray-300 focus:ring-[#EA011F] mr-4"
          onClick={(e) => e.stopPropagation()}
          onChange={() => handleProductSelect(product)}
        />
        <div className="flex items-center space-x-4">
          <img 
            src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${product.product_group_image}`}
            alt={product.product_group_name}
            className="w-12 h-12 object-cover rounded"
          />
          <div>
            <span className="text-base font-medium text-gray-800">{product.product_group_name}</span>
            <div className="flex flex-col">
              <span className="text-sm text-gray-500">₹{product.selling_price}</span>
              <span className="text-xs text-gray-400">
                {product.tax_preference} • GST {product.GST}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

         


      {selectedProductsByCategory[activeTab]?.length > 0 && (
        <div className="mt-6 mb-8">
          <label className="block text-sm font-regular text-gray-700 mb-2">
            Default {activeTab} Product
          </label>
          <div className="flex gap-4">
            {selectedProductsByCategory[activeTab].map(product => {
              const productDetails = productGroups.find(pg => pg._id === product._id);
              return (
                <div key={product._id} className="flex items-center space-x-2 p-2 border rounded-md">
                  <input
                    type="radio"
                    id={`default-${product._id}`}
                    name={`default-${activeTab}`}
                    checked={defaultProducts[activeTab] === product._id}
                    onChange={() => handleDefaultProductSelect(product._id, activeTab)}
                    className="text-[#EA011F] focus:ring-[#EA011F]"
                  />
                  <label htmlFor={`default-${product._id}`} className="text-gray-700">
                    {productDetails?.product_group_name}
                  </label>
                </div>
       );
    })}
  </div>
</div>
)}

{errorMessage && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl transform transition-all">
      <div className="flex items-center mb-4">
        <svg className="w-6 h-6 text-red-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h3 className="text-lg font-semibold text-gray-900">Action Required</h3>
      </div>
      <p className="text-gray-700 mb-6">{errorMessage}</p>
      <div className="flex justify-center">
        <button 
          onClick={() => setErrorMessage('')}
          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
      </div>
    );
  };
  

export default EditTilesTemplate;
