import React, { useRef ,useState, useEffect  } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faTrash,faTimes, faX , faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
 import TableSearchDropDown from '../Components/TableSearchDropdown';
 import TableSearchForAreaName from '../Components/TableSearchForAreaName'
import Doors from './Doors';
import Windows from './Windows';
import Tiles from './Tiles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import ProjectReports from '../../measurit/ProjectSummary/ProjectReports';



// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
             // autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const NewMeasurements = () => {

  const navigate = useNavigate();
  const tilesRef = useRef();
  const windowsRef = useRef();
  const doorsRef = useRef();
  const [blockOptions, setBlockOptions] = useState([]);
  const [itemCategoryOptions, setProductCategories] = useState([{
    value: 'allProductCategories',
    label: 'All Product Categories',
    category_mapping_id: 'all'
  }]);
  const [flatOptions , setFlatOptions] = useState([]);
 
  const floorOptions = [
    { value: '1', label: '1 ' },
    { value: '2', label: '2 ' },
    { value: '3', label: '3 ' },
  ];
 
  const location = useLocation()
  const {projectData } = location.state.projectData
  const {measurementsType} = location.state.projectData
   const blockData = location.state.projectData?.block || null;
   const flatData = location.state.projectData?.flat || null;
  const [selectedProject, setSelectedProject] = useState(null);

  // State for block dropdown
  const [selectedBlock, setSelectedBlock] = useState(null);
  // State for item category dropdown
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState({
    value: 'allProductCategories',
    label: 'All Product Categories',
    category_mapping_id: 'all'
  });
  
  // State for UOM dropdown

  const flatTypes = ['1BHK', '2BHK', '3BHK', '4BHK'];
  const facingOptions = ['East', 'West', 'North', 'South'];
  
  // State for number of floors dropdown
  const [selectedFloor, setSelectedFloor] = useState(null);

  // State for flat dropdown
  const [selectedFlat, setSelectedFlat] = useState(null);
            
  const [tileMeasurements , setTileMeasurements] = useState([
    { room: '', areatype: '', type: ' ', mtype: '', height: '', width: '', area: '' },
  ]);
  //console.log(tileMeasurements)

  const [doorMeasurements, setDoorMeasurements] = useState([
    { room: '', type: ' ', mtype: '', height: '', width: '', area: '' },
    // Add more sample door measurements as needed
  ]);

  const [windowMeasurements, setWindowMeasurements] = useState([
    { room: '',  mtype: '', height: '', width: '', area: ''  },
    // Add more sample window measurements as needed
  ]);
  
const [Rooms, setRooms] = useState([]);
const [Areas, setAreas] = useState([]);
const  [measurements, setMeasurements] = useState([]);
const  [electricals, setElectricals] = useState([]);
const [showPopup, setShowPopup] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
const [errorMessages, setErrorMessages] = useState([]);
const [retryFunction, setRetryFunction] = useState(null);
const [isLoading, setIsLoading] = useState(true);
const RETRY_LIMIT = 3; // Maximum number of retries
const [retryCount, setRetryCount] = useState(0); 
const [isSaving, setIsSaving] = useState(false); 
const [showSummaryPopup, setShowSummaryPopup] = useState(false);


  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb';
  }

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption); // Correctly update the state based on selection
  };


  useEffect(() => {
    if (projectData) {
      setSelectedProject(projectData);
      getProjectBlocks(projectData._id)
      //console.log(projectData)
    }
  },[projectData])

      useEffect(() => {
        if (measurements && measurements.length > 0) {
          // Filter and set tile measurements
         // console.log(measurements)
          setTileMeasurements(measurements.filter(m => [3, 4].includes(m.areaId)));

          // Filter and set door measurements
          setDoorMeasurements(measurements.filter(m => m.areaId === 1));

          // Filter and set window measurements
          setWindowMeasurements(measurements.filter(m => m.areaId === 2));
        }else{
          setTileMeasurements([])
          setDoorMeasurements([])
          setWindowMeasurements([])
        }
      }, [measurements]);
  
  
  
  const getProjectBlocks = async (projectId, retryCount = 0) => {
    if (!projectId) {
      console.error('Project ID is required');
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('No authentication token found');
        clearStorageAndRedirectToHome();
        return;
      }
  
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/get-all-blocks-of-project?projectId=${projectId}`, {
        method: 'GET',
        headers: {
          'x-token': token,
        },
      });
  
      switch (response.status) {
        case 200:
        case 201:
          const data = await response.json();
          if (Array.isArray(data)) {
            if (data.length > 0) {
              setBlockOptions(data.map(block => ({ value: block._id, label: block.blockNo })));
            } else {
              setBlockOptions([]);
              setSelectedBlock(null);
            }
            
          } else {
            console.error('Unexpected data format received');
           
            setBlockOptions([]);
            setSelectedBlock(null);
          }
          break;
        case 404:
          console.log('No blocks found for this project');
          setBlockOptions([]);
          setSelectedBlock(null);
          break;
        case 500:
          if (retryCount < 3) {
            console.log(`Server error. Retrying... (Attempt ${retryCount + 1})`);
            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
            return getProjectBlocks(projectId, retryCount + 1);
          } else {
            throw new Error('Server error after multiple retries');
          }
        default:
          throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching project blocks:', error.message);
      // Handle other types of errors
      clearStorageAndRedirectToHome();
      // Optionally, you can set an error state here to display to the user
      // setError(error.message);
    }
  };
  
  const getProductCategories = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('No authentication token found');
        clearStorageAndRedirectToHome();
        return;
      }
  
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product-category/get-all-productCategories`, {
        method: 'GET',
        headers: {
          'x-token': token,
          'Accept': 'application/json'
        },
      });
  
      if (!response.ok) {
        if (response.status === 404) {
          console.log('No product categories found');
          setProductCategories([]);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Oops! We haven't received a valid JSON response.");
      }
  
      const data = await response.json();
      console.log("product categories",data);
  
      if (data.success && Array.isArray(data.data)) {
        //setProductCategories(data.data);
        if (data.data.length > 0) {
          console.log(data.data);
          //setProductCategories(data.data.map(category => ({ value: category._id, label: category.product_category_name, category_mapping_id: category.category_mapping_id })));
          setProductCategories([
            {
              value: 'allProductCategories',
              label: 'All Product Categories',
              category_mapping_id: 'all'
            },
            ...data.data
              .filter(category => [1, 2, 3].includes(Number(category.category_mapping_id)))
              .map(category => ({
                value: category._id,
                label: category.product_category_name,
                category_mapping_id: category.category_mapping_id
              }))
          ]);
          console.log(itemCategoryOptions);
          
        } else {
          setProductCategories([]);
        }
        //console.log('Product categories fetched successfully:', data.data);
      } else {
        console.error('Unexpected data format received');
        setProductCategories([]);
      }
    } catch (error) {
      console.error('Error fetching product categories:', error.message);
      setProductCategories([]);
    }
  };
  
  useEffect(() => {
    if (blockData) {
      const block = {
        label: blockData.blockNo,
        value: blockData._id
      }
      //console.log(block)
      handleChangeBlock(block);
    }
  }, [blockData]);

  useEffect(() => {
    if (flatData && flatOptions) {
      const flat = flatOptions.find(p=>p.value === flatData._id)
      handleChangeFlat(flat)
    }
  }, [flatData , flatOptions]);

  
 const handleChangeBlock =  (selectedBlock) => {
  console.log('Selected Block:', selectedBlock);
  //console.log('Selected Block:', selectedBlock);
  setSelectedBlock(selectedBlock);
  getProductCategories()
  getFlats(selectedBlock.value);
 }

 const getFlats = async (blockId) => {
  console.log('Block ID:', blockId);
  if (!blockId) {
    console.error('Block ID is required');
    return;
  }

  try {
    const token = sessionStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found');
      clearStorageAndRedirectToHome();
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/flats/get-flats-by-blockId?blockId=${blockId}`, {
      method: 'GET',
      headers: {
        'x-token': token,
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        console.log('No flats found for this block');
        setFlatOptions([]);
        return;
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if (Array.isArray(data)) {
      //console.log(data);
      setFlatOptions(data.map((flat, index) => ({ value: flat._id, label: `Flat ${index + 1}`, flatNo: flat.flatNo, type: flat.type, facing: flat.facing, plinthArea: flat.plinthArea })));
      setSelectedFlat(null)
      setMeasurements([])
     // console.log('Flats data fetched successfully:', data);
    } else {
      console.error('Unexpected data format received');
      setFlatOptions([]);
    }
  } catch (error) {
    console.error('Error fetching flats:', error.message);
    setFlatOptions([]);
  }
};


    // Handler to update input field values for new row
    const handleInputChange = (field, value) => {
      setSelectedFlat(prevState => ({
        ...prevState,
        [field]: value
      }));
    };
    
  
 
const handleCancel = () => {
  navigate('/bb/app/projects/projectslist')
}
 const handleChangeFlat = (selectedFlat) => { 
  setSelectedFlat(selectedFlat);
  getAllRooms();
  getAreasAndMeasurements(selectedFlat);
 }
 
 const getAllRooms = async () => {
  const token = sessionStorage.getItem('token');
  const endpoint = `${process.env.REACT_APP_FORE_APILINK}/measurements/get-all-rooms`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-token': token
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (Array.isArray(data)) {
      //setRoomData(data);
      const filteredRoomData = data.filter((p) => p.display === 'yes');
      setRooms(filteredRoomData);
      console.log('Rooms data fetched successfully:', filteredRoomData);
    } else {
      console.error('Unexpected data format received');
      //setRoomData([]);
      setRooms([]);
    }
  } catch (error) {
    console.error('Error fetching rooms:', error.message);
    //setRoomData([]);
  }
}

const  getAreasAndMeasurements = async (selectedFlat) => {
  try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        console.error('No authentication token found');
        clearStorageAndRedirectToHome();
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/measurements/get-all-areas-and-measurements-of-flat?flatId=${selectedFlat.value}&measurementType=${measurementsType}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        }
      });

      if (!response.ok) {
        console.error('Failed to fetch areas');
        return;
      }

      const data = await response.json();
      console.log('Areas and Measurements:', data);
      
      const areas= data.data.areas;
      const measurements = data.data.measurements;
      const electricals = data.data.electricals;
      console.log('Areas:', areas);
      console.log('Measurements:', measurements);
      console.log('Electricals:', electricals);
      setAreas(areas);
      setMeasurements(measurements);
      setElectricals(electricals);
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
 };


 const handleSaveMeasurements = async (status) => {
  const token = sessionStorage.getItem('token');
  const endpoint = `${process.env.REACT_APP_FORE_APILINK}/measurements/save-flat-measurements`;
 
  const tilesData = tilesRef.current?.getTilesData() || [];
  const windowData = windowsRef.current?.getWindowsData() || [];
  const doorsData = doorsRef.current?.getDoorsData() || [];
  const flatData = {
    flatNo: selectedFlat.flatNo,
    type: selectedFlat.type,
    facing: selectedFlat.facing,
    plinthArea: selectedFlat.plinthArea,
  }
  const AllMeasurements = {
    tilesData,
    windowData,
    doorsData
  }
  
  const payload = {
    flatId: selectedFlat.value,
    blockId: selectedBlock.value,
    projectId: selectedProject._id,
    measurements: AllMeasurements,
    electricals: electricals,
    measurementType:measurementsType,
    status : status,
    flatData: flatData
    }

    console.log('Payload:', payload);
  
 try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    console.log('Response:', response);
    if (response.ok) {
      setShowPopup(true);
            setPopupType("200or201");
            setPopupMessage("Measuremets created successfully.");
            
            setTimeout(() => {
              navigate('/bb/app/projects/projectslist');
              setShowPopup(false);
            }, 3000);
     // console.log('Measurements saved successfully');
      
    } else if (response.status === 500) {
      setShowPopup(true);
    
      setRetryFunction(() => () => handleSaveMeasurements());
      setPopupType("500");
      setPopupMessage(data.message || "Internal Server Error");
    } else {
      setPopupMessage(data.message || "Failed to create Measuremets");
     
      setPopupType(`${response.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Error saving measurements:', error);
  }
 
};


const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};


const handleSummaryClick = (project) => {
  console.log('Summary clicked for project:', project); 
  setSelectedProject(project);
  setShowSummaryPopup(true);
};

const handleQuoteSummary = () => {
  setSelectedProject({...selectedProject, type: 'quotation'});
};

const handleProductionSummary = () => {
  setSelectedProject({...selectedProject, type: 'production'});
};

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden ">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold"> Measurements</h1>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="Projectname" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Project Name<span className="text-red-500">*</span>
                        </label>
                         <div className='w-1/3'>
                      {/*  <CustomDropdown
  options={projectOptions}
  selected={selectedProject}
  onSelect={setSelectedProject}
  placeholder="Select Project"
  showAddNew={false}
  showSearch={true}
/> */}
              <input
                      type="text"
                      id="projectName"
                      name="projectName"
                      value={projectData.projectName}
                      readOnly
                      className="w-full md:w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                      />
      </div> 
                      </div>
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="Block" className="block text-sm font-regular text-gray-700 w-1/6">
                          Block{/* <span className="text-red-500">*</span> */}
                        </label>
                         <div className='w-1/3'>
                         <CustomDropdown
                  options={blockOptions}
                  selected={selectedBlock}
                  onSelect={(value) => {
                    handleChangeBlock(value);
                   
                  }}
                  placeholder="Select Block"
                  showAddNew={false}
                  showSearch={true}
                />

                        </div> 
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full"> 
                    <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category<span className="text-red-500">*</span>
                </label>           
                     <div className='w-1/3'>
                     <CustomDropdown
        options={itemCategoryOptions}
        selected={ selectedCategory}
       // onSelect={setSelectedItemCategory}
       onSelect={handleCategoryChange}
        placeholder="Select Item Category"
        showAddNew={false}
        showSearch={true}
      />
                    </div> 
                    
              </div>
             
             {/*  <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="numberofFloor" className="block text-sm font-regular text-gray-700 w-1/6">
                 No of Floor
                </label> 
                 <div className='w-1/3'>          
                <CustomDropdown
  options={floorOptions}
  selected={selectedFloor}
  onSelect={setSelectedFloor}
  placeholder="Select Number of Floors"
  showAddNew={false}
  showSearch={true}
/>
  </div> 
              </div> */}
              <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="Flat" className="block text-sm font-regular text-gray-700 w-1/6">
                 Flat
                </label> 
               <div className='w-1/3'>          
                <CustomDropdown
  options={flatOptions}
  selected={selectedFlat}
  onSelect={(value) => {
    handleChangeFlat(value);
    
  }}
  placeholder="Select Flat"
  showAddNew={false}
  showSearch={true}
/>
  </div> 
              </div>
                  </div>
                </div>
                <div className="w-full flex justify-center items-center px-4 mb-2 mt-4">
      <table className="w-full">
        <thead>
          <tr className="text-[#6c7184] bg-[#fafafc]">
            <th className="font-semibold text-center text-sm py-2 px-2 border cursor-pointer border">Flat No</th>
            <th className="font-semibold text-center text-sm py-2 px-2 border cursor-pointer border">Flat Type</th>
            <th className="font-semibold text-center text-sm py-2 px-2 border cursor-pointer border">Facing</th>
            <th className="font-semibold text-center text-sm py-2 px-2 border cursor-pointer border">Plinth Area (sq ft)</th>
          </tr>
        </thead>
        <tbody>
          {selectedFlat && (
            <>
             <tr key={selectedFlat.value}>
              <td className="border p-2 w-1/4">
              <input
                  type="text"
                  value={selectedFlat?.flatNo}
                  onChange={(e) => handleInputChange( 'flatNo', e.target.value)}
                  className="outline-blue-500 text-sm  w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border p-2 w-1/4">
               <div className="w-full flex flex-row items-center  justify-center">
      <select
        id="type"
        value={selectedFlat?.type}
        onChange={(e) =>handleInputChange( 'type', e.target.value)}
        className="w-full py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      >
        <option value="">Select Flat Type</option>
        {flatTypes.map((flatType) => (
          <option key={flatType} value={flatType}>{flatType}</option>
        ))}
      </select>
    </div>

              </td>
              <td className="border p-2 w-1/4">
               {/*  <input
                  type="text"
                  value={selectedFlat?.facing}
                  onChange={(e) => handleInputChange( 'facing', e.target.value)}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                /> */}
                    <div className="w-full flex flex-row items-center  justify-center">
      <select
        id="type"
        value={selectedFlat?.facing}
        onChange={(e) => handleInputChange( 'facing', e.target.value)}
        className="w-full py-1 pl-2 pr-2 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
      >
        <option value="">Select Facing</option>
        {facingOptions.map((facingOption) => (
          <option key={facingOption} value={facingOption}>{facingOption}</option>
        ))}
      </select>
    </div>
              </td>
              <td className="border p-2 w-1/4">
                <input
                  type="text"
                  value={selectedFlat?.plinthArea}
                  onChange={(e) => handleInputChange( 'plinthArea', e.target.value)}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
             
            </tr>
            </>
          )}
            
        </tbody>
      </table>
    </div>
                <hr className="border-t border-gray-300 w-full mt-4" style={{ borderTopWidth: '1px' }} />
              </div>
{selectedCategory?.category_mapping_id === 'all' && Rooms.length > 0 && Areas.length > 0 && selectedFlat&& selectedBlock && projectData && (
        <>
          <Doors 
          rooms={Rooms}
          measurements={doorMeasurements}
          areas={Areas.filter(p => p.areaId === 1)}
          Block= {selectedBlock}
          flat= {selectedFlat}
          project= {projectData}
          //setDoorMeasurements = {setDoorMeasurements}
          ref={doorsRef}/>
          <Windows 
          rooms={Rooms}
          measurements={windowMeasurements}
          areas={Areas.filter(p => p.areaId === 2)} 
          Block= {selectedBlock}
          flat= {selectedFlat}
          project= {projectData}
          //setWindowMeasurements = {setWindowMeasurements}
          ref={windowsRef}/>
          <Tiles 
          rooms={Rooms}
          measurements={tileMeasurements}
          areas={Areas.filter(p => [3, 4].includes(p.areaId))}
          Block= {selectedBlock}
          flat= {selectedFlat}
          project= {projectData}
          ref={tilesRef}/>
        </>
      )}

{selectedCategory?.category_mapping_id === 1 && Rooms.length > 0 && Areas.length > 0 && selectedFlat&& selectedBlock && projectData &&(
                    <Tiles
                    rooms={Rooms}
                     measurements={tileMeasurements}
                     areas={Areas.filter(p => [3, 4].includes(p.areaId))} 
                     Block= {selectedBlock}
                     flat= {selectedFlat}
                     project= {projectData}
                     ref={tilesRef}/>
                  )}
                  {selectedCategory?.category_mapping_id === 2 && Rooms.length > 0 &&  Areas.length > 0 && selectedFlat&& selectedBlock && projectData &&(
                    <Doors
                    rooms={Rooms}
                     measurements={doorMeasurements} 
                     areas={Areas.filter(p => p.areaId === 1)}
                     Block= {selectedBlock}  
                     flat= {selectedFlat} 
                     project= {projectData}
                    //setDoorMeasurements = {setDoorMeasurements}
                     ref={doorsRef}/>
                  )}
                  {selectedCategory?.category_mapping_id === 3 && Rooms.length > 0 &&  Areas.length > 0 && selectedFlat&& selectedBlock && projectData &&(
                    <Windows
                    rooms={Rooms}
                     measurements={windowMeasurements}
                     areas={Areas.filter(p => p.areaId === 2)} 
                     Block= {selectedBlock}
                     flat= {selectedFlat}
                     project= {projectData}
                     //setWindowMeasurements = {setWindowMeasurements}
                     ref={windowsRef}/>
                     
                  )}

              <div className='mt-2 mb-4 px-6'>
              <button className="rounded-md px-2 py-2 bg-[#F7F7FE] text-sm"
             onClick={() => handleSummaryClick(selectedProject)}>
            View Flat Summary
            </button>
              </div>
              </div >
              

              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 rounded-md bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]"
            onClick={()=>handleSaveMeasurements('Draft')}>
            Save as Draft
            </button>
            <button className="px-2 py-1 bg-[#f7525a] text-white border rounded-md"
            onClick={()=>handleSaveMeasurements('completed')} >
            Save as Final
            </button>
          <button className={` px-2 py-1 rounded-md bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]}`} 
          onClick={handleCancel}
       >Cancel</button>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
      {showSummaryPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-4 rounded-lg max-w-4xl max-h-[90vh] overflow-y-auto relative">
    <button
        className="absolute top-2 right-2 bg-transparent text-gray-600 hover:text-gray-800"
        onClick={() => setShowSummaryPopup(false)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
            <h2 className="text-lg font-bold mb-4">Summary Options</h2>
            <div className="flex space-x-4 mb-4">
              <button 
                className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                onClick={handleQuoteSummary}
              >
                Quote Summary
              </button>
              <button 
              className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"                onClick={handleProductionSummary}
              >
                Production Summary
              </button>
            </div>
            <div className="mt-4">
  {selectedProject && selectedProject.type && (
    <ProjectReports project={selectedProject} type={selectedProject.type} flat={selectedFlat}
    />
  )}
</div>
          </div>
        </div>
      )}


      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)} 

    </div>
  );
};

export default NewMeasurements;
