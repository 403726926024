import React, { useState, useEffect } from "react";
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { UserIcon, ChartBarIcon, ClockIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import QuotationDashboard from './QuotationDashboard'
ChartJS.register(...registerables);

const Home = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterRole, setFilterRole] = useState('');
  const navigate = useNavigate();

  const getUserActivity = process.env.REACT_APP_FORE_APILINK + `/user-activity/get-user-activity?organizationId=${sessionStorage.getItem('organizationId')}`;

  useEffect(() => {
    const fetchUserActivities = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const response = await fetch(getUserActivity, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log(data);
        setActivities(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserActivities();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const formatDateTime = (isoDate) => {
    const dateObj = new Date(isoDate);
    const date = dateObj.toLocaleDateString();
    const time = dateObj.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
    return `${date} ${time}`;
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedActivities = activities
    .filter(activity => 
      activity.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activity.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activity.activity.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(activity => filterRole ? activity.role === filterRole : true)
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAndSortedActivities.slice(indexOfFirstItem, indexOfLastItem);

/*   const processActivityText = (text) => {
    const parts = text.split(/"([^"]*)"/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is a quoted part
        const [label] = part.split(':');
        return (
          <span
            key={index}
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => handleClickedWord(part)}
          >
            {label}
          </span>
        );
      }
      return part;
    });
  };
  
  
  const handleClickedWord = (word, activityData) => {
    console.log("Clicked word:", word);
    
    if (word.startsWith('P')) {
      navigate(`/bb/app/projects/projectdetails`, { state: { projectId: activityData.projectId } });
      console.log(activityData.projectId)
    } else if (word.startsWith('QT')) {
      navigate(`/bb/app/sales/quotedetails`, { state: { quotationId: activityData.quotationId } });
    } else if (word.startsWith('SO')) {
      navigate(`/bb/app/sales/salesorderdetails`, { state: { salesOrderId: activityData.salesorderId } });
    } else {
      navigate(`/bb/app/sales/customerdetails`, { state: { customerId: activityData.customer_id } });
      console.log(activityData.customer_id)
    }
  }; */
  
  const processActivityText = (text) => {
    const parts = text.split(/"([^"]*)"/);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const [label, idInfo] = part.split(/:\((.+)\)/);
        return (
          <span
            key={index}
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => handleClickedWord(idInfo, part)}
          >
            {label}
          </span>
        );
      }
      return part;
    });
  };
  
  const handleClickedWord = (idInfo, fullPart) => {
    const [idType, id] = idInfo.split(': ');
    switch (idType.trim()) {
      case 'projectId':
        navigate(`/bb/app/projects/projectdetails`, { state: { projectId: id.trim() } });
        break;
      case 'customerId':
        navigate(`/bb/app/sales/customerdetails`, { state: { customerId: id.trim() } });
        break;
      case 'quotationId':
        navigate(`/bb/app/sales/quotedetails`, { state: { quotationId: id.trim() } });
        break;
      case 'salesorderId':
        navigate(`/bb/app/sales/salesorderdetails`, { state: { salesOrderId: id.trim() } });
        break;
      default:
        console.log("Unknown ID type:", idType, "Full part:", fullPart);
    }
  };
  
  
  
  

  return (
    <div className="bg-white flex flex-col w-full min-h-screen">
      <div
        className=" w-full bg-contain bg-center border-b"
        style={{ backgroundImage: "url('/screenBacground.svg')" }}
      >
          {currentItems.length > 0 ? (

        (sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '0') && (
          <div className="flex-1 px-10 py-5">
            <div className="mt-24 bg-white rounded-lg border border-gray-200">
              <div className="relative">
                <div className="absolute inset-0 bg-[#F9F9FB] rounded-t-lg"></div>
                <div className="relative flex justify-between items-center p-2 border-b">
                  <h2 className="text-lg">User Activity</h2>
                  <div className="flex items-center">
                    <button
                      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                      className="px-2 py-1 text-blue-500 disabled:text-gray-300"
                    >
                      &lt;
                    </button>
                    <button
                      onClick={() => setCurrentPage(prev => prev + 1)}
                      disabled={filteredAndSortedActivities.length <= itemsPerPage || currentPage === Math.ceil(filteredAndSortedActivities.length / itemsPerPage)}
                      className="px-2 py-1 text-blue-500 disabled:text-gray-300"
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full p-8">
                <div className="flex justify-between items-center mb-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border rounded"
                  />
                <select
                  value={filterRole}
                  onChange={(e) => setFilterRole(e.target.value)}
                  className="p-2 border rounded"
                >
                  <option value="">All Roles</option>
                  <option value="Super Admin">Super Admin</option>
                  <option value="Admin">Admin</option>
                  <option value="Marketing Associate">Marketing Associate</option>
                  <option value="Field Associate">Field Associate</option>
                  <option value="Customer">Customer</option>
                </select>
                </div>
                <table className="w-full text-center">
                  <thead>
                    <tr className="border">
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('createdAt')}>
                        Date {sortField === 'createdAt' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('username')}>
                        User Name {sortField === 'username' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('role')}>
                        Role {sortField === 'role' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('activity')}>
                        Activity {sortField === 'activity' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((activity, index) => (
                      <tr key={activity.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                        <td className="p-3 text-sm border">{formatDateTime(activity.createdAt)}</td>
                        <td className="p-3 text-sm border">{activity.username}</td>
                        <td className="p-3 text-sm border">{activity.role}</td>
                        <td className="p-3 text-sm border">{processActivityText(activity.activity, activity.data)}</td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )) : (
<div className="flex-1 px-10 py-5 flex justify-center items-center">
  <table className="w-full">
    <tbody>
      <tr>
        <td colSpan="4" className="p-4 text-center border border-grey-500 text-gray-500">
          No User Activities
        </td>
      </tr>
    </tbody>
  </table>
</div>

        )}
      </div>
      <div className="flex-1 px-10 py-5">

<QuotationDashboard />

</div>
    </div>
  );
};

export default Home;
