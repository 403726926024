import React from 'react';
import Select from 'react-select';
import { useState, useRef } from 'react';
import { useEffect } from 'react';

const TableSearchDropDown = ({ options, value, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [lastInputLength, setLastInputLength] = useState(0);
  const [displayedOptions, setDisplayedOptions] = useState(options);
  const [hasSelection, setHasSelection] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    const currentLength = newValue.length;
    setInputValue(newValue);
    setHasSelection(false);
    setMenuIsOpen(true);

    if (currentLength < lastInputLength) {
      setDisplayedOptions(options);
    } else {
      const filtered = options.filter(option =>
        option.label.toLowerCase().includes(newValue.toLowerCase())
      );
      setDisplayedOptions(filtered);
    }

    setLastInputLength(currentLength);
    onChange(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setMenuIsOpen(true);
      setHighlightedIndex(prev => 
        prev < displayedOptions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      const selectedOption = displayedOptions[highlightedIndex];
      handleOptionSelect(selectedOption);
    }
  };

  const handleOptionSelect = (option) => {
    setInputValue(option.label);
    onChange(option);
    setMenuIsOpen(false);
    setHighlightedIndex(-1);
    setHasSelection(true);
  };

  const handleFocus = () => {
    if (!hasSelection) {
      setMenuIsOpen(true);
      setDisplayedOptions(options);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setMenuIsOpen(false);
      setHighlightedIndex(-1);
    }, 200);
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
        ref={inputRef}
      />
      {menuIsOpen && (
        <div 
          ref={optionsRef}
          className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-50 max-h-48 overflow-y-auto"
        >
          {displayedOptions.map((option, index) => (
            <div
              key={option.value}
              className={`px-3 py-2 cursor-pointer ${
                index === highlightedIndex ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
              }`}
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableSearchDropDown;
