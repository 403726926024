import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faTimes } from '@fortawesome/free-solid-svg-icons';

function DoorQuotationTemplate() {
  const [selectedRoom, setSelectedRoom] = useState("Living");
  const [selectedDoors, setSelectedDoors] = useState({});

  const rooms = ["Living", "Kitchen", "Bedroom", "Bathroom"];
  
  const onlyDoorProducts = [
    { name: "B.S Veneer 32mm", default: false },
    { name: "B.S Veneer 36mm", default: true }
  ];

  const preEngDoorProducts = [
    { name: "Pre Engineered Door", default: false },
    { name: "HDPC Pre Eng Door", default: false },
    { name: "Wooden Pre Eng Door", default: true }
  ];

  return (
<div className="bg-white flex flex-row w-full justify-left h-screen overflow-hidden">
<div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              {/* Header */}
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Door Quotation Template</h1>
                </div>
              </div>

              <div className="flex flex-col justify-start items-start w-full flex-grow h-full overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4">
                  {/* Product Category */}
                  {/* <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label className="block text-sm font-regular text-[#e54643] w-1/6">
                      Product Category<span className="text-red-500">*</span>
                    </label>
                    <select className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700">
                      <option>Select Product Category</option>
                    </select>
                  </div> */}

                  {/* Package Type */}
                  {/* <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label className="block text-sm font-regular text-[#e54643] w-1/6">
                      Package Type<span className="text-red-500">*</span>
                    </label>
                    <select className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700">
                      <option>Select Package</option>
                      <option>Economy</option>
                      <option>Value</option>
                      <option>Premium</option>
                    </select>
                  </div> */}

                  {/* Add-on Products */}
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label className="block text-sm font-regular text-gray-700 w-1/6">
                      Add-on Products
                    </label>
                  </div>

                  {['Flush Door', 'Veneer', 'Laminate'].map((item) => (
                    <div key={item} className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                      <label className="block text-sm font-regular text-gray-700 w-1/6">
                        {item}
                      </label>
                      <select className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700">
                        <option>Select Vendor</option>
                      </select>
                    </div>
                  ))}
                </div>

                {/* Room Navigation */}
                {/* Room Navigation */}
                <div className="flex w-full border-b mb-4">
                {rooms.map((room) => (
                  <button
                    key={room}
                    className={`px-6 py-3 text-sm font-medium ${
                      selectedRoom === room
                        ? 'text-[#e54643] border-b-2 border-[#e54643]'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                    onClick={() => setSelectedRoom(room)}
                  >
                    {room}
                  </button>
                ))}
              </div>
  
              {/* Products Content */}
              <div className="grid grid-cols-2 gap-6">
  {/* Only Doors Section */}
  <div className="border rounded-lg p-4">
    <h3 className="text-base font-medium text-gray-800 mb-4">Only Doors</h3>
    <div className="space-y-2">
      {onlyDoorProducts.map((product, idx) => (
        <label key={idx} className="flex items-center p-3 border rounded-lg hover:bg-gray-50">
          <input
            type="checkbox"
            name="onlyDoor"
            className="mr-3"
            checked={selectedDoors[selectedRoom]?.includes(product.name)}
            onChange={() => {
              setSelectedDoors((prev) => {
                const currentSelection = prev[selectedRoom] || [];
                if (currentSelection.includes(product.name)) {
                  // Remove the product if it's already selected
                  return {
                    ...prev,
                    [selectedRoom]: currentSelection.filter((door) => door !== product.name),
                  };
                } else {
                  // Add the product to the selection
                  return {
                    ...prev,
                    [selectedRoom]: [...currentSelection, product.name],
                  };
                }
              });
            }}
          />
          <div>
            <span className="text-sm font-medium">{product.name}</span>
            {product.default && (
              <span className="ml-2 text-xs text-[#e54643]">(Default)</span>
            )}
          </div>
        </label>
      ))}
    </div>
  </div>

  
                {/* Pre-Engineered Doors Section */}
             {/* Pre-Engineered Doors Section */}
<div className="border rounded-lg p-4">
  <h3 className="text-base font-medium text-gray-800 mb-4">Pre-Engineered Doors</h3>
  <div className="space-y-2">
    {preEngDoorProducts.map((product, idx) => (
      <label key={idx} className="flex items-center p-3 border rounded-lg hover:bg-gray-50">
        <input
          type="checkbox"
          name="preEngDoor"
          className="mr-3"
          checked={selectedDoors[selectedRoom]?.includes(product.name)}
          onChange={() => {
            setSelectedDoors((prev) => {
              const currentSelection = prev[selectedRoom] || [];
              if (currentSelection.includes(product.name)) {
                return {
                  ...prev,
                  [selectedRoom]: currentSelection.filter((door) => door !== product.name),
                };
              } else {
                return {
                  ...prev,
                  [selectedRoom]: [...currentSelection, product.name],
                };
              }
            });
          }}
        />
        <div>
          <span className="text-sm font-medium">{product.name}</span>
          {product.default && (
            <span className="ml-2 text-xs text-[#e54643]">(Default)</span>
          )}
        </div>
      </label>
    ))}
  </div>
  
</div>

              </div>
            </div>
  
            {/* Footer with Save Button */}
            <div className="border-t p-4 bg-white flex justify-start space-x-3">
              <button className="px-4 py-2 border rounded-md hover:bg-gray-50">Cancel</button>
              <button className="px-4 py-2 bg-[#e54643] text-white rounded-md hover:bg-[#d03e3e]">
                Save Template
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
  
}

export default DoorQuotationTemplate;
