import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faTrash, faX, faPlusCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import {Loader , Spinner} from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages'
import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  React.useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [ref, callback]);
};

const CustomDropdown = ({ options = [], selected, onSelect, labelField, valueField, placeholder, onAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);


  useOutsideClick(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
    }
}, [isOpen]);

  // Filter options based on the search term
  const filteredOptions = options.filter(option => {
    const label = option[labelField] || '';
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (name, id) => {
    onSelect(name, id);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
         break;
      case 'ArrowUp':
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        }
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption[labelField], selectedOption[valueField]);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };


  return (
    <div className="relative w-1/3" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected || placeholder}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
       <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          <input
            type="text"
            className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus={isOpen}

          />
          <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length ? (
            filteredOptions.map((option, index) => (
              <div
                key={option[valueField]}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option[labelField], option[valueField])}
              >
                {option[labelField]}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No results found</div>
          )}
          </div>
          {onAddNew && (
            <button
              type="button"
              className="flex items-center border-t p-2 mt-2 text-blue-500 "
              onClick={onAddNew}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
              Add New
            </button>
          )}
        </div>
      )}
    </div>
  );
};
const ProductAttributes = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryId , setSelectedCategoryId] = useState();
  const [attributes, setAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [showNewAttributePopup, setShowNewAttributePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
  const [attributeName, setAttributeName] = useState('');
  const [productCategoryId, setProductCategoryId] = useState('');
  const [SelectedAttributeNameId,setSelectedAttributeNameId] = useState()
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [errors, setErrors] = useState({});
    const [rows, setRows] = useState([{ SNo: '', Name: '', Values: '', attribute_name_id: ''}]);
    const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [retryFunction, setRetryFunction] = useState(null);
  const [roleId, setRoleId] = useState(null);

    const handleInputChange = (index, field, value) => {
      const updatedRows = [...rows];
      updatedRows[index][field] = value;
      setRows(updatedRows);
    };
  
    const addRow = () => {
      if(selectedCategoryId && SelectedAttributeNameId){
        setRows([...rows, { SNo: '', Name: selectedAttribute, Values: '', attribute_name_id: SelectedAttributeNameId }]);
      }else{
        console.log (' you need to select the item Category and attribute name ')
      }
      
    };

    const deleteRow = (index ,row) => {
      if(row?.attribute_value_id && row?.attribute_value_delete){
        handleDeleteAttributeValue(index, row)
        
      }else{
        const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
        setRows(updatedRows);
      }
     
    };

    const handleDeleteAttributeValue = async (index, row,attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product-attributes/delete-attribute-value?attribute_value_id=${row.attribute_value_id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
    
        const output = await handleDeleteResponse(response);
        if (output) {
          const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
          setRows(updatedRows);
        }
    
      } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDeleteAttributeValue(index, row,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDeleteAttributeValue(index, row,attempt)); // Set retry function
      }
    }
    }
    
    const handleDeleteResponse = (response) => {
      if (response.status === 204) {
        // No content, but successful deletion
        setIsSaving(false);
        setPopupMessage('Attribute value deleted successfully.');
        setPopupType('204');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        return true;
      } else if (response.status === 200 || response.status === 201) {
        setIsSaving(false);
        setPopupMessage('Attribute value deleted successfully.');
        setPopupType('200or201');
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
        return true;
      } else {
        response.json().then(data => {
          setIsSaving(false);
          setPopupMessage(data.message || 'An error occurred.');
          setPopupType(`${response.status}`);
          setShowPopup(true);
        });
        return false;
      }
    }
    

    const fetchProductCategories = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });         
        const data = await response.json();        
        if (data.status === 200 || data.status === 201 || data.status === 204) {
          // Map data to expected format
          const categories = data.data.map(cat => ({
            product_category_id: cat._id,
            product_category_name: cat.product_category_name
          }));
          setProductCategories(categories);
        } else {
          setIsSaving(false);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
        }
      }finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchRoleId();
      fetchProductCategories();
    }, [fetchUrl]);

    const fetchRoleId = () => {
      const roleId = JSON.parse(sessionStorage.getItem('roleId'));
      setRoleId(roleId);
    };

    const categoriesToDisplay = roleId === 6 
    ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
    : productCategories;


  const handleCategoryChange = async (name, id) => {
    //console.log(id);
    setSelectedCategory(name);
    setSelectedCategoryId(id);
    const attributes = await fetchAttributes(id);
  };

  const handleAttributeSelect = async (name, id)=>{
    setSelectedAttribute(name);
    setSelectedAttributeNameId(id)
    getattributeValuesByAttributeNameId(id , name)
  }

  const getattributeValuesByAttributeNameId = async (id,name,attempt = 1) => {
    const token = sessionStorage.getItem('token');
    const saveUrl = `${fetchUrl}product-attributes/get-all-attribute-values-by-name-id?attribute_name_id=${id}`;
  
    try {
      const response = await fetch(saveUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const Data = await response.json();
        const output = await getResponseHandling(response,Data)
      if (output) {
      
        const data = output.map ((row , index)=>({
          SNo: index + 1,
          Name: name,
          Values: row.attribute_value,
          attribute_name_id: row.attribute_name_id,
          attribute_value_id:row._id,        
          attribute_value_delete : row.attribute_value_delete
        }))
        setRows(data)
      } else {
        const data= [{
          SNo:  1,
          Name: name,
          Values: '' ,
          attribute_name_id: id,
        }];
        setRows(data) ;       
      }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => getattributeValuesByAttributeNameId(id,name,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => getattributeValuesByAttributeNameId(id,name,attempt)); // Set retry function
      }
    }
  }
  const getResponseHandling = async (response, data)=>{
    if (response.status === 200 || response.status === 201) {
      setPopupType('200or201');
        return (data.data)
    } else if (response.status === 204) {
      setPopupType('204');
      return (data.data)
    } 
 
    if (response.status === 400) {
      setPopupType('400');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else if (response.status === 401) {
      setPopupType('401');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else if (response.status === 403) {
     
      setPopupType('403');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else if (response.status === 404) {
      return null
    } 
     else if (response.status === 500) {
     
      setPopupType('500');
      setPopupMessage(data.message);
      setShowPopup(true);
      return null
    } else {
      setErrorMessages([...errorMessages, data.message]);
    }
  
}

  const fetchAttributes = async (categoryId,attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${categoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      console.log(data);
      const output = await getResponseHandling(response,data)
            if (output) {
     /*    const formattedAttributes = output.map(attr => ({
          attribute_name: attr.attribute_name,
          attribute_name_id: attr.attribute_name_id
        })); */
        const formattedAttributes = output.map(attr => ({
          attribute_name: attr.attribute_name,
          attribute_name_id: attr.attribute_name_id
        })).sort((a, b) => a.attribute_name.localeCompare(b.attribute_name));
        
        setAttributes(formattedAttributes);
        setRows([{ SNo: 1, Name: '', Values: '', attribute_name_id: '' }])
        setSelectedAttribute('')
        return formattedAttributes;
      } else {
        setAttributes([])
        setSelectedAttribute('')
        setSelectedAttributeNameId()
        setRows([{ SNo: 1, Name: '', Values: '', attribute_name_id: '' }])
        return [];
      }
    }catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchAttributes(categoryId,attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchAttributes(categoryId,attempt)); // Set retry function
      }
    }
  };
  
 
  const handleAddNewAttribute = () => {
    const fields = [ 
      { name: 'product_category', value: selectedCategoryId, required: true,  message: 'select a product category ', usage: 'product category'},  
    ];
    const validationErrors = validate(fields);
    setErrors(validationErrors);

    // Proceed only if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
    setShowNewAttributePopup(true);
    setAttributeName('')
    }
    }
  
  const handleClosePopup = () => {
    setShowPopup(false);
    setShowNewAttributePopup(false);
  };
 
  
  
  
  const handleSave = async (attempt = 1) => {
    const data = {
      product_category_id: selectedCategoryId,
      attribute_name: attributeName,
    };
    const errorMessages = [];
    if (!data.attribute_name) {
      errorMessages.push('Please enter Attribute Name');
    }
 
    if (errorMessages.length > 0) {
      setErrorMessages(errorMessages);
      return;
    }

    setIsSaving(true);
    try {
      const token = sessionStorage.getItem('token');
      const saveUrl = `${fetchUrl}product-attributes/create-attribute`;
  
      const response = await fetch(saveUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(data),
      });
      const Data = await response.json();
      const output = await SaveResponseHandling (response ,Data)
      if (output) {
       fetchAttributes(selectedCategoryId)
       //setShowPopup(false);
       setShowNewAttributePopup(false);
      } else {
        if(response.status === 409){
          errorMessages.push(`Attribute ${data.attribute_name} is alredy exist`);
          setErrorMessages(errorMessages)
        }
        
        console.error(Data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleSave(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleSave(attempt)); // Set retry function
      }
    }
  }

  const SaveResponseHandling = (response, data)=>{
    if (response.status === 200 || response.status === 201) {
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      return data.data
    } else if (response.status === 204) {
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType('204');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);

      return data.data
    } else {
      setIsSaving(false);
      setPopupMessage(data.message);
      setPopupType(`${response.status}`);
      setShowPopup(true);
      return null
    }
  }
  
  
const handleAttributesData = (data) => {
  return data.map((item) => ({
    attribute_name_id: item.attribute_name_id,
    attribute_value: item.Values,
    attribute_value_id: item.attribute_value_id || null // Ensure attribute_value_id is present or null
  }));
}

const handleSaveAttributeValues = async (attempt = 1) => {
  const hasMissingName =  rows.some((item) => !item.Values || item.Values.trim() === '');
    
    const fields = [
      { name: 'attribute_value', value: !hasMissingName, required: true,  message: 'attribute value is required', usage: 'attribute value', },
      { name: 'product_category', value: selectedCategoryId, required: true,  message: 'select a Item category ', usage: 'product category'},
      { name: 'attribute_name', value: selectedAttribute, required: true,  message: 'select a Attribute', usage: 'Attribute'},
    ];

    const validationErrors = validate(fields);
    setErrors(validationErrors);

    // Proceed only if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
  const attributesData = handleAttributesData(rows)
  const token = sessionStorage.getItem('token');
  const url = fetchUrl + 'product-attributes/create-attribute-values-by-name-id'; // Update '/your-endpoint' to the correct endpoint
 console.log(attributesData)
 setIsSaving(true);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify({ attribute_values: attributesData }) // Wrap rows in an object
    });
    const Data = await response.json();
    if (Data.status === 200 || Data.status === 201) {
      setIsSaving(false);
      getattributeValuesByAttributeNameId(SelectedAttributeNameId,selectedAttribute )
      setPopupMessage(Data.message);
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/items/productgroupslist');
      }, 3000);
      
    } else if (Data.status === 204) {
      setIsSaving(false);
      setPopupMessage(Data.message);
      setPopupType('204');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/items/productgroupslist');
      }, 3000);
    } else if (Data.status === 409) {
      if(response.status === 409){
        const error = {
          duplication : Data.message
        }
        setErrors(error)
        setIsSaving(false);
      }
    
    } else {
      setIsSaving(false);
      setPopupMessage(Data.message);
      setPopupType(`${Data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleSaveAttributeValues(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleSaveAttributeValues(attempt)); // Set retry function
    }
  }
}
}

const handleCloseError = () => {
  setErrorMessages([]);
};

const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

const handleStayHere = () => {
  setShowCancelPopup(false);
}

const handleCancel = () => {
  setShowCancelPopup(true);
}

const handleLeave = () => {
  navigate(-1);
}

if (isLoading) {
  return <Loader />;
}


  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Item Attributes</h1>
                </div>
              </div>
              {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors.attribute_name && (
              <span className="text-sm">
                • {errors.attribute_name}
              </span>
            )}
            {errors.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors.attribute_value && (
              <span className="text-sm">
                • {errors.attribute_value}
              </span>
            )}

          {errors.duplication && (
              <span className="text-sm">
                • {errors.duplication}
              </span>
            )}
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">            
                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="categoryname" className="block text-sm font-regular text-[#e54643] w-1/6">Item Category <span className="text-red-500">*</span></label>
                    <CustomDropdown
                      options={categoriesToDisplay}
                      selected={selectedCategory}
                      onSelect={handleCategoryChange}
                      labelField="product_category_name"
                      valueField="product_category_id"
                      placeholder="Select Product Category"
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="Attribute" className="block text-sm font-regular text-[#e54643] w-1/6">Attribute <span className="text-red-500">*</span></label>
                    <CustomDropdown
        options={attributes}
        selected={selectedAttribute}
        onSelect={handleAttributeSelect}
        labelField="attribute_name"
        valueField="attribute_name_id"
        placeholder="Select an Attribute"
        onAddNew={handleAddNewAttribute} // Pass the onAddNew handler
      />
                  </div>
                </div>

                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-md font-semibold">Item Attributes</h1>
                  
                </div>

                <div className="w-full container mx-auto px-4 mb-2 mt-4">
                  {rows.length > 0 ? (
                  <table className="w-full">
                    <thead>
                      <tr className="text-[#6c7184] bg-[#fafafc]">
                        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">SNo</th>
                        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Name</th>
                        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer border">Values</th>
                      </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                            <tr key={index}>
                              <td className="py-2 px-4 border">{index + 1}</td>
                              <td className="border">
                                <input
                                  type="text"
                                  value={row.Name}
                                  //onChange={(e) => handleInputChange(index, 'Name', e.target.value)}
                                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                                />
                              </td>
                              <td className="border">
                                <input
                                  type="text"
                                  value={row.Values}
                                  onChange={(e) => handleInputChange(index, 'Values', e.target.value)}
                                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                                />
                              </td>
                              <td className="px-2">
                                <button
                                  type="button"
                                  onClick={() => deleteRow(index ,row)}
                                  className="text-red-500"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  ):(
                    <div className="text-center py-4">
                    <p>No Attributes</p>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <button
                    onClick={addRow}
                    className="text-blue-500 py-2 px-4"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                    Add Item Attribute Value
                  </button>
                </div>
                </div>

                <div className="mt-5 w-full">
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                  <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    onClick={handleSaveAttributeValues}                  >
                    <span>Save</span>
                    {isSaving && ( <Spinner/>)}
                  </button>
                  <button onClick={handleCancel}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
                </div>
                </div>   
            </div>
          </div>
        </div>

         {/* Popup starts */}

         {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  <span>Stay Here</span>
    {isSaving && (<Spinner/>)}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
         {showNewAttributePopup && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col animate-slide-in">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">New Attribute</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {errorMessages.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-6">
        <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">
          Attribute<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="Attribute"
          className="w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={attributeName}
                onChange={(e) => setAttributeName(e.target.value)}
       />
      </div>
      {/* Description Label and Textarea */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 px-6">
        <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
        <textarea
          id="Description"
          name="Description"
          className="mt-1 w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          /* value={description}
          onChange={(e)=>{setdescription(e.target.value)}} */
        />
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
           onClick={handleSave} >
           <span>Save</span>
            {isSaving && <Spinner />}
            </button>
          <button onClick={handleClosePopup}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}
{/* Popup ends */}
      </div>
       
    </div>
  );
};

export default ProductAttributes;
