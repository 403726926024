import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPlus, faPen, faExchangeAlt, faEllipsisV,  faEnvelope, faSms, faPaperPlane, faMessage, faEnvelopeCircleCheck} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import  '../Css/Tooltip.css';
import {Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QuotePdfDocument from './QuotePdfDocument'; // Import the PDF document component
import PdfViewer from './PdfViewer'; // Import PdfViewer component
// import { generatePDF } from './GeneratePdf'; 
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className="relative w-1/6" ref={dropdownRef}>
      {trigger}
      {isOpen && (
        <div className="absolute mt-1 w-full h-40 bg-white border border-gray-300 rounded-md shadow-lg z-10 flex flex-col">
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {options.map((option) => (
              option.value !== 'add-new' && (
                <div className='p-1'>
                  <div
                    key={option.value}
                    className="py-2 pl-1 cursor-pointer text-sm hover:bg-blue-500 hover:rounded-md  hover:text-white"
                    onClick={() => handleOptionClick(option.value)}
                  >
                    {option.icon && <FontAwesomeIcon icon={option.icon} className="" />}
                    {option.label}
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


const CustomPopup = ({ message, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl">
      <p className="text-lg font-semibold mb-4">{message}</p>
      <button 
        onClick={onClose}
        className="px-2 py-1 text-xs text-white border bg-[#f7525a] border-[#f7525a] rounded-md"
      >
        Close
      </button>
    </div>
  </div>
);


const QuoteDetails = () => {
  const navigate = useNavigate ();
  const location= useLocation();
  const { QuoteData } = location.state || {};
  const quotationId = location.state?.quotationId;
  const {package_id, project_id, product_category_id} = location.state || {};
 // console.log(package_id, project_id, product_category_id)
 const [quotations , setquotations] = useState ([])
 const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [isLoading, setIsLoading] = useState(true);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
 // const selectedQuotation = quotations(); // Assuming you want to generate a PDF for the first quote
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [showConvertPopup, setShowConvertPopup] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
 // console.log(quoteId);
  const [Items , setItems] = useState();
  const { 
    showPopup, popupMessage, popupType, retryFunction,
    isSaving ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
    setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
    const [roleId, setRoleId] = useState(null);

    const sendOptionsRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sendOptionsRef.current && !sendOptionsRef.current.contains(event.target)) {
          setShowSendOptions(false);
          setShowConvertPopup(false);
        }
      };
    
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);


  useEffect(() => {
    fetchRoleId();
    fetchQuotations(QuoteData);
    fetchQuotationById(QuoteData?._id)
  }, [QuoteData]);


  useEffect(() => {
    fetchRoleId();
    fetchQuotations(QuoteData);
    fetchQuotationById(quotationId)
    if (quotationId) {
      fetchQuotationById(quotationId);
    }
  }, [quotationId]);

  useEffect(() => {
    if (QuoteData) {
      setSelectedQuote(QuoteData);
      setQuoteId(QuoteData._id);
      fetchQuotationById(QuoteData._id);
    }
  }, [QuoteData]);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const fetchQuotations = async (selectedQuote ,attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-all-quotations?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();
if (response.ok) {
  setquotations(data.data);
  //setSelectedQuote(selectedQuote);
  handleQuotationClick(selectedQuote || quotationId);
  setShowPopup(false);
  /* const FindQuote = data.data.find(p => p._id===QuoteData._id)
  handleQuotationClick(FindQuote);
  setQuoteId(FindQuote?._id);
  setItems(); */
}
   else {
  console.error(data.message);
}


      if (response.status === 500) {
        setRetryFunction(() => () => fetchQuotations(attempt));
      }
    } catch (error) {
      console.error('Failed to fetch quotations:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchQuotations(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchQuotations(attempt));
      }
    } finally {
      setIsLoading(false);
    }
  };


  const fetchQuotationById = async (id ,attempt = 0) => {
  //  console.log(id);
/*     const quoteIdToUse = id || quotationId;
    if (!quoteIdToUse) {
      console.log(id,quotationId)
      setPopupMessage('No valid quotation ID provided');
      setPopupType('400');
      setShowPopup(true);
      return;
    } */
   //console.log('quotationId',id)
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotation?quotation_id=${id || quotationId}&&package_id=${package_id}&&project_id=${project_id}&&product_category_id=${product_category_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();
      //console.log(data);

  // Process the items array to calculate the total if it's 0
 

      const output =  await handleGetResponse(response , data)
      if(output){
        console.log(output)
       /*  if (output.items && Array.isArray(output.items)) {
          output.items = output.items.map(item => {
            if (item.total === 0) {
              item.total = item.quantity * item.unit_price;
            }
            return item;
          });
        } */
        setSelectedQuote(output);
       
      }else{
      
      }
      }  catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchQuotationById(id,attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchQuotationById(id,attempt)); // Set retry function
        }
      }finally{
        //setProductGroupLoader(false)
      }
    };
  

  const  handleQuotationClick = (quotation) => {
    setShowPopup(false);
    const quoteId = quotation?._id || quotation;
    setSelectedQuote(quotation);
    setQuoteId(quoteId);
    fetchQuotationById(quoteId);
   // console.log(quotation);
  }


  if (isLoading) {
    return <Loader />;
  }

  const handleSendClick = () => {
    setShowSendOptions(!showSendOptions);
    setShowConvertPopup(false);
  };
  
  const handleConvertClick = () => {
    setShowConvertPopup(!showConvertPopup);
    setShowSendOptions(false);
  };


 const handleCreateSalesOrder = () => {
  if (selectedQuote.status.toLowerCase() === 'approved') {
    setShowConvertPopup(!showConvertPopup);
    setShowSendOptions(false);
    setShowCustomPopup(true);
    setPopupMessage("Approved Quotation Cannot be Converted into Sales Order");
  } else {
  navigate("/bb/app/sales/newsalesorder", {state:{QuoteID: quoteId}}); 
 }
};

 const handleEdit = () => {
  navigate("/bb/app/sales/editquote", {state:{QuoteID: quoteId}});
 }


/*  const sendQuotationToUser = async () => {
  setShowSendOptions(false); 
  //console.log(selectedQuote);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/send-quotation-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify({
        quotationId: selectedQuote._id,
        customerId: selectedQuote.customer_id._id
      })
    });

    const data = await response.json();

    if (response.ok) {
      setPopupMessage('Quotation sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    } else {
      throw new Error(data.message || 'Failed to send quotation');
    }
  } catch (error) {
    console.error('Error sending quotation:', error);
    setPopupMessage('Failed to send quotation');
    setPopupType('400');
  }
}; */

const sendQuotationToUser = async () => {
  setIsSaving(true);
  setShowSendOptions(false);
  try {
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/send-quotation-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': sessionStorage.getItem('token'),
      },
      body: JSON.stringify({
        customerId: selectedQuote.customer_id._id,
        documentData: {
          documentType: 'Quotation',
          documentNumber: selectedQuote.quotation_number,
          date: selectedQuote.quotation_date,
          status: selectedQuote.status,
          items: selectedQuote.items.map(item => ({
            item_name: item.item_name,
            quantity: item.quantity,
            UOM : item.UOM,
            price: item.selling_price,
            tax_rate: item.tax_rate,
            total: item.total
          })),
          additionalInfo: {
            'Sub Total': selectedQuote.subtotal,
            'Tax': selectedQuote.tax_amount,
            'Total': selectedQuote.total
          }}
      }),
    });

    const data = await response.json();

    if (data.success) {
      setPopupMessage('Quotation sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      }, 2000);
    } else {
      //throw new Error(data.message || 'Failed to send Quotation');
     if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      }
    }
  } catch (error) {
    console.error('Error sending Quotation:', error);
    setPopupMessage('Failed to send Quotation');
    setPopupType('500');
    setShowPopup(true);
  }finally{
  setIsSaving(false);
  }
};
 
const closePopup = () => {
  //console.log("closePopup called");
  setShowPopup(false);
  setPopupMessage('');
        setPopupType('');
};

const Retry = async() => {
 // console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};

const BackToLoginPage = () => {
 // console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};
const handleClose = () => {
  navigate('/bb/app/sales/quotelists');
}

const handleCreateQuote = () => {
  navigate('/bb/app/sales/newquote');
}

const quotationsToDisplay = roleId === 6 
? quotations.filter(quotation => (quotation.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
: quotations;
console.log(quotationsToDisplay);

const handleMarkAsSent = async () => {
//  console.log("quotation id:",quoteId)
  try {
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/mark-as-sent?quotation_id=${quoteId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token': sessionStorage.getItem('token'),
      },
    });
    const data = await response.json();

    if (data.success) {
      setPopupMessage('Quotation marked as sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      }, 2000);
    } else {
      //throw new Error(data.message || 'Failed to send Quotation');
     if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      }
    }
  } catch (error) {
    console.error('Error sending Quotation:', error);
    setPopupMessage('Failed to send Quotation');
    setPopupType('500');
    setShowPopup(true);
  }finally{
  setIsSaving(false);
  }
}


return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
{/* List of Vendors Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Quotes
     {/* <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " /> */}
    </h5>
    </div>
    <div className=''>
    <button 
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"  data-tooltip="Create Quote" title=""  style={{ '--tooltip-bg': 'black' }}
  onClick={handleCreateQuote} >
  <FontAwesomeIcon icon={faPlus} /* onClick={handleCreateVendor} */ className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className="flex justify-center overflow-y-hidden flex-col">
  <div className="flex flex-col h-[80vh] overflow-y-auto">
    {quotationsToDisplay.map((quotation, index) => (
      <div
        key={quotation._id}
        className={`border-b text-sm flex items-center px-4 py-2 ${quoteId === quotation?._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
        onClick={() => handleQuotationClick(quotation)}
      >
        <div className="flex flex-row">
          <div>
            <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
          </div>
          <div className="flex flex-col">
            <p className="text-md font-semibold">{quotation.quotation_number}</p>
            <p className="text-gray-600 text-sm flex items-center">
  {quotation?.status}
  <span className="mx-1 text-gray-600">&middot;</span>
  {new Date(quotation.quotation_date).toLocaleDateString()}
</p>
            <p className="text-gray-600 text-sm"></p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>
</div>
{/* List of Vendors Div end */}

<div className="border-l  w-full flex flex-col ">

<div className="border-l w-full flex flex-col">
        <div className="border-b h-30">
          <div className="p-4 flex justify-between items-center">
            <span className="text-md font-semibold">{selectedQuote?.quotation_number}</span>
            <button className="text-white px-1 py-1 rounded-md"
              title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>
              <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer" />
            </button>
          </div>
          <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
            <button className="border-r px-4 py-2 text-sm mr-2" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
              Edit
            </button>
            <div  ref={sendOptionsRef} className="relative">
            <button 
  onClick={handleSendClick} 
  className={`px-4 py-2 border-r text-sm mr-2 ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
  disabled={isSaving}
>
  <div className="flex items-center justify-center space-x-2">
    <FontAwesomeIcon icon={faPaperPlane} className="mr-1 w-3 h-3" />
    <span>Send</span> {isSaving && <Spinner />}
  </div>
</button>
  {showSendOptions && (
    <div className="p-0.5 absolute left-0 top-full mt-1 bg-white border rounded shadow-lg z-10">
      <button onClick={sendQuotationToUser} className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Email
      </button>
      <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faSms} className="mr-2" />
        SMS
      </button>
    </div>
  )}
</div>

           {/*  <button className="text-sm border-r px-4 py-2 mr-2">
              <FontAwesomeIcon icon={faShareAlt} className="mr-2 w-3 h-3" />
              Share
            </button> */}
            {/* <PDFDownloadLink
            
            document={<QuotePdfDocument quote={selectedQuote} />}
            fileName={`quotation_${selectedQuote?.quotation_number}.pdf`}
            
            className="text-sm px-4 border-r py-2 mr-2">
              <FontAwesomeIcon icon={faFilePdf} className="mr-2 w-3 h-3" />
              PDF/Print
            {({ loading }) =>
              loading ? 'Generating PDF...' : 'Download PDF'
            }
          </PDFDownloadLink> */}
            {/* <button onClick={() => generatePDF(selectedQuote)}>Generate PDF</button>   */}
            <div ref={sendOptionsRef} className="relative">
            <button onClick={handleConvertClick} className="text-sm border-r px-4 py-2 mr-2">
  <FontAwesomeIcon icon={faExchangeAlt} className="mr-2 w-3 h-3" />
  Convert
  <FontAwesomeIcon icon={faChevronDown} className="ml-2 w-3 h-3" />
</button>
  {showConvertPopup && (
    <div
      className="absolute left-0 top-full mt-1 w-42 bg-white shadow-md p-2 z-10"
    >
      <button onClick={handleCreateSalesOrder} className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">Convert to Sales Order</button>
    </div>
  )}
</div>

<button className="text-sm border-r px-4 py-2 mr-2" onClick={handleMarkAsSent}>
              <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="mr-1 w-4 h-4" />
              Mark as Sent  
            </button>
            {/* <button className="text-sm px-6 py-2 mr-2">
              
              Download PDF
            </button> */}
           
          </div>
        </div>
        <div className="w-full h-full bg-white rounded-xs overflow-hidden">
  <div className="flex flex-col h-[80vh] overflow-hidden">
    <div className="overflow-y-auto flex-1 p-6">
      {/* "What's Next?" section */}
      {selectedQuote && (
        <>
         {/*  <div className="mt-4 mx-10 px-2 border p-2 flex items-center">
            <div className="flex-1">
              <p className="text-sm font-semibold">What's Next?</p>
              <p className="mt-1 mb-2 text-sm text-gray-700">
                Go ahead and email this quote to your customer or simply mark it as sent.
              </p>
            </div>
            <div className="flex-shrink-0 flex space-x-2">
              <button className="px-2 py-1 text-xs bg-[#f7525a] border-[#f7525a] text-white rounded-md">
                Send Quote
              </button>
              <button className="px-2 py-1 text-xs bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
                Mark as Sent
              </button>
            </div>
          </div> */}
          <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{selectedQuote?.status}</h1>
          <div className="mt-4">
            <PdfViewer quote={selectedQuote} />
          </div>
        </>
      )}
    </div>
  </div>
</div>
      </div>
    </div>

    {showCustomPopup && (
  <CustomPopup 
    message={popupMessage}
    onClose={() => setShowCustomPopup(false)}
  />
)}
    {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}
   </div>
  );
};
export default QuoteDetails;