import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { faExclamationTriangle, faX, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';


function EditOrganization() {
  
  const [data, setData] = useState({
    organization_name: '',
    company_name: '',
    email: '',
    mobile_number: '',
    gst: '',
    pan: '',
    address1: '',
    city: '',
    pincode: '',
    state: '',
    website_url: '',
  });
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const location = useLocation();
  const { OrganizationData: initialOrganizationData } = location.state;
  const [OrganizationData, setOrganizationData] = useState(initialOrganizationData);
  console.log("OrganizationData:", OrganizationData);

  const [isSaving, setIsSaving] = useState(false);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const RETRY_LIMIT = 3;
  const [retryCount, setRetryCount] = useState(0);
  const [organizations, setOrganizations] = useState([]);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
const [showPopup, setShowPopup] = useState(false);
const [retryFunction, setRetryFunction] = useState(null);
const [imagePreview, setImagePreview] = useState(null);
const [image,setImage] = useState('');
const [showCancelPopup, setShowCancelPopup] = useState(false);
const [closePopup, setClosePopup] = useState(false);
const [BackToLoginPage, setBackToLoginPage] = useState(false);
const [Retry, setRetry] = useState(false);
const navigate = useNavigate();
const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;


const handleOrganizationClick = (organization) => {
  // handle organization click event
};

useEffect(() => {
  if (OrganizationData) {
    setImagePreview(OrganizationData.logo ? `${imageUrl}${OrganizationData.logo}` : null);
  }
}, [OrganizationData]);


  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  useEffect(() => {
    fetchOrganizationsByOrganizationId(OrganizationData._id);
  }, []);
  
  const fetchOrganizationsByOrganizationId = async (organizationId, attempt = 1) => {
    console.log(organizationId);
    try {
      const token = sessionStorage.getItem('token');
      console.log(token);
      const getOrganizations = `${fetchUrl}organization/get-organizations-by-Id?organizationId=${organizationId}`;
      console.log(getOrganizations);
      const response = await fetch(getOrganizations, {
    
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-token': token
        },
      });
      const data = await response.json();
      console.log(data);
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setOrganizations(data.data || []);
      } else if (data.status === 404) {
        setOrganizations([]);
        setPopupMessage(data.message);
        setPopupType('404');
        setShowPopup(true);
      } else if (data.status === 500) {
        setPopupMessage(data.message);
        setPopupType('500');
        setRetryFunction(() => fetchOrganizationsByOrganizationId);
        setShowPopup(true);
      } else {
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchOrganizationsByOrganizationId(organizationId, attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchOrganizationsByOrganizationId(organizationId, attempt));
      }
    }
  };
  
    const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevState) => ({ ...prevState, [name]: value }));

    let error = '';
    if (name === 'mobile_number') {
      if (value.length > 10 || !/^[0-9]*$/.test(value)) {
        return;
      }
      if (value.length < 10 && value.length > 0) {
        error = 'Enter 10 Digits Mobile Number please';
      }
    }
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (value && !emailRegex.test(value)) {
        error = 'Please enter a valid email address';
      }
    }
    if (name === 'gst') {
      if (value.length > 15) {
        return;
      }
    }
    if (name === 'pan') {
      if (value.length > 10) {
        return;
      }
    }

    setErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  

  const handleLogoRemove = () => {
    setLogo(null);
    setLogoPreview(null);
  };

  const handleCloseError = () => {
    setErrorMessages([]);
  };

  const handleSubmit = async (_id, attempt = 1) => {
   
    try {
      const token = sessionStorage.getItem('token');
      const formData = new FormData();
  
      // Use OrganizationData instead of data
      Object.keys(OrganizationData).forEach((key) => formData.append(key, OrganizationData[key]));
      if (image) {
        formData.append('logo', image); // Use 'file' as the key to match the backend expectation
      }
  
     
      const response = await fetch(`${fetchUrl}organization/update-organization?_id=${OrganizationData._id}`, {
      
        method: 'PUT',
        headers: {
          'x-token': token,
        },
        body: formData,
      }); 
  
  
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        setPopupType('200or201');
        setPopupMessage('Organization updated successfully');
        setShowPopup(true);
        
        setOrganizationData(responseData.data);

        setTimeout(() => {
          navigate('/bb/app/settings/organizationlist');
        }, 3000);      } else {
        setErrorMessages([responseData.message || 'Failed to update organization']);
      }
    } catch (error) {
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => handleSubmit(attempt + 1), 1000);
      } else {
        setErrorMessages(['Internal Server Error. Kindly Retry.']);
      }
    } finally {
      setIsLoading(false);
      setIsSaving(false);
    }
  };
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }
  
  const handleCancel = () => {
    setShowCancelPopup(true);
  }
  
  const handleLeave = () => {
    navigate(-1);
  }
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  return (
    <div className="bg-white flex flex-row w-full h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Edit Organization</h1>
                  
                  <span className=" absolute right-32  text-md font-semibold text-gray-600">Organization ID : {OrganizationData.organization_number}</span>
                  


                </div>
              </div>
              {errorMessages.length > 0 && (
                <div className="flex justify-between items-center px-auto py-3 bg-[#fddcde] rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                        • {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className="px-4">
                    <button
                      className="text-gray-700 hover:text-red-500"
                      onClick={handleCloseError}
                    >
                      <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                    </button>
                  </div>
                </div>
              )}

              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                <div className='w-full flex flex-row'>
                <div className="flex flex-col w-full">

                  <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="organization_name" className="block text-sm font-regular text-red-500 w-1/6">
                      Organization Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="organization_name"
                      name="organization_name"
                      value={OrganizationData.organization_name || ''}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                    {errors.organization_name && <p className="text-red-500 text-sm">{errors.organization_name}</p>}
                  </div>

                  <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="company_name" className="block text-sm font-regular text-red-500 w-1/6">
                      Company Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="company_name"
                      name="company_name"
                      value={OrganizationData.company_name || ''}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                    {errors.company_name && <p className="text-red-500 text-sm">{errors.company_name}</p>}
                  </div>

                  <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="email" className="block text-sm font-regular text-red-500 w-1/6">
                      Email <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={OrganizationData.email || ''}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                  </div>

                  <div className="w-full flex flex-row items-center mb-4 space-x-20 mt-5">
                    <label htmlFor="mobile_number" className="block text-sm font-regular text-red-500 w-1/6">
                      Mobile Number <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="mobile_number"
                      name="mobile_number"
                      value={OrganizationData.mobile_number || ''}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                    {errors.mobile_number && <p className="text-red-500 text-sm">{errors.mobile_number}</p>}
                  </div>
                  </div>
                  <div className='w-1/2 justify-start items-start mt-5'>

                  {/* Logo Upload Section */}
                  <div className=" w-1/2 h-[50%] border-2 border-dashed border-gray-300 rounded-lg flex justify-center items-center relative">
                <input
                  type="file"
                  id="itemImage"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleImageUpload}
                />
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="text-gray-400 text-center">Click to upload image</div>
                )}
              </div>
              </div>
              </div>
              </div>
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="gst" className="block text-sm font-regular text-red-500 w-1/6">
    GST <span> *</span>
  </label>
  <input
    type="text"
    id="gst"
    name="gst"
    value={OrganizationData.gst}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
  {errors.gst && <p className="text-red-500 text-sm">{errors.gst}</p>}
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="pan" className="block text-sm font-regular text-red-500 w-1/6">
    PAN <span> *</span>
  </label>
  <input
    type="text"
    id="pan"
    name="pan"
    value={OrganizationData.pan}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
  {errors.pan && <p className="text-red-500 text-sm">{errors.pan}</p>}
</div>

  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="address1" className="block text-sm font-regular text-gray-700 w-1/6">
    Address
  </label>
  <input
    type="text"
    id="address1"
    name="address1"
    value={OrganizationData.address1}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
  {errors.address1 && <p className="text-red-500 text-sm">{errors.address1}</p>}
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="city" className="block text-sm font-regular text-gray-700 w-1/6">
    City
  </label>
  <input
    type="text"
    id="city"
    name="city"
    value={OrganizationData.city}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
  {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="pincode" className="block text-sm font-regular text-gray-700 w-1/6">
    Pincode
  </label>
  <input
    type="text"
    id="pincode"
    name="pincode"
    value={OrganizationData.pincode}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
  {errors.pincode && <p className="text-red-500 text-sm">{errors.pincode}</p>}
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="state" className="block text-sm font-regular text-gray-700 w-1/6">
    State
  </label>
  <input
    type="text"
    id="state"
    name="state"
    value={OrganizationData.state}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
  {errors.state && <p className="text-red-500 text-sm">{errors.state}</p>}
</div>

<div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-4">
  <label htmlFor="website_url" className="block text-sm font-regular text-gray-700 w-1/6">
    Website URL
  </label>
  <input
    type="text"
    id="website_url"
    name="website_url"
    value={OrganizationData.website_url}
    onChange={handleChange}
    className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  />
{errors.website_url && <p className='text-red-500 text-sm'>{errors.website_url}</p>}
</div>


                </div>
              </div>

              <div className="mt-5 w-full">
<hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                    <button
                      className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md inline-flex items-center"
                      onClick={() => handleSubmit(OrganizationData._id)}

                      disabled={isSaving}
                    >
                      <span>Update</span>
                      {isSaving && <Spinner />}
                    </button>
                    <button
                      className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
                        isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
                      }`}
                      disabled={isSaving}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                  </div>

            </div>
          </div>
        </div>
        {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}

      </div>
    
  );
}

export default EditOrganization;
