
  import React, { useEffect, useState } from 'react';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { 
    faDoorClosed, 
    faChevronDown,
    faChevronUp,
    faTools,
    faRuler,
    faArrowLeft
  } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNaviagte, useNavigate} from 'react-router-dom';

 /*  const quotationData = {
    quotation_number: "QT-000049",
    quotation_date: new Date(),
    items: [
      {
        item_name: "Sharan HDPC pre engineared 40 mm Door",
        category: "Pre Eng Doors",
        quantity: 10,
        selling_price: 19500,
        tax_rate: 18,
        total: 195000,
        total_tax_amount: 35100,
        UOM: "No",
        hardware: [
          {
            name: "Hettich Soft Close Hinge",
            price: 320,
            quantity: 2,
            tax_rate: 18
          },
          {
            name: "BEK 10 inch Glass Door Pull Handle",
            price: 1200,
            quantity: 3,
            tax_rate: 18
          }
        ],
        area_types: {
          area_name: "Main Doors Area",
          area: "230",
          count: "10"
        },
        applied_areas: [
          {
            door_name: "F1-Liv-D1",
            room_name: "Living",
            height: 43,
            width: 77,
            area: 22.99
          },
          {
            door_name: "F2-Liv-D1",
            room_name: "Living",
            height: 77,
            width: 43,
            area: 22.99
          }
        ]
      },
      {
        item_name: "Flush door bs veneer 34mm",
        category: "Only Doors",
        quantity: 477,
        selling_price: 400,
        tax_rate: 18,
        total: 190800,
        total_tax_amount: 34344,
        UOM: "Sq.ft",
        hardware: [
          {
            name: "Hansure veneer 1mm",
            price: 40,
            quantity: 1,
            tax_rate: 18
          },
          {
            name: "Hettich Soft Close Hinge",
            price: 320,
            quantity: 2,
            tax_rate: 18
          }
        ],
        area_types: {
          area_name: "Bathroom Doors Area",
          area: "477",
          count: "30"
        },
        applied_areas: [
          {
            door_name: "F1-Bat1-D1",
            room_name: "Bathroom 1",
            height: 80,
            width: 29,
            area: 16.11
          }
        ]
      },
      {
        item_name: "Flush door both side veneer 32mm",
        category: "Only Doors",
        quantity: 326,
        selling_price: 380,
        tax_rate: 18,
        total: 123880,
        total_tax_amount: 22298.40,
        UOM: "Sq.ft",
        hardware: [
          {
            name: "Hansure veneer 1mm",
            price: 40,
            quantity: 1,
            tax_rate: 18
          }
        ],
        area_types: {
          area_name: "Utility Doors Area",
          area: "326",
          count: "15"
        },
        applied_areas: [
          {
            door_name: "F1-Was-D1",
            room_name: "Wash Area",
            height: 32,
            width: 83,
            area: 18.44
          },
          {
            door_name: "F1-Was-D2",
            room_name: "Wash Area",
            height: 86,
            width: 48,
            area: 28.67
          }
        ]
      }
    ],
    subtotal: 1423049,
    tax_amount: 25614,
    total: 16791979,
    discount: 0,
    adjustment: 0,
    tax_prefrence: "Tax Inclusive"
  }; */
  
  const DoorQuotationSummary = () => {
    const [expandedItems, setExpandedItems] = useState({});
          const navigate = useNavigate();
    const location = useLocation()
    const {quotation_id, product_category_id , project_id , package_id} = location.state
          const [quotationData, setQuotationData] = useState({});
          const fetchUrl = process.env.REACT_APP_FORE_APILINK;

    const toggleItem = (index) => {
        setExpandedItems(prev => ({
          ...prev,
          [index]: !prev[index]
        }));
      };
   
       const fetchQuotationSummary = async () => {
              const queryParams = new URLSearchParams({
                product_category_id,
                project_id,
                package_id,
                quotation_id
              }).toString();
            
              try {
                const response = await fetch(`${fetchUrl}/summary/get-quote-summary?${queryParams}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                });
               console.log(response)
                if (!response.ok) {
                  let errorMessage = 'Failed to fetch quotation summary';
                  
                  switch (response.status) {
                    case 400:
                      errorMessage = 'Invalid query parameters';
                      break;
                    case 404:
                      errorMessage = 'Quotation not found';
                      break;
                    case 500:
                      errorMessage = 'Server error occurred';
                      break;
                    default:
                      const data = await response.json();
                      errorMessage = data.message || errorMessage;
                  }
                  throw new Error(errorMessage);
                }
            
                const data = await response.json();
                console.log('data' , data)
                setQuotationData(data);
            
              } catch (error) {
                console.error('Error fetching quotation:', error.message);
              }
            };
            
            useEffect(() => {
              fetchQuotationSummary();
            }, [product_category_id, project_id, package_id,  quotation_id ]);
      
     
      
            const renderQuotationHeader = () => {
              const quotationReference = quotationData?.referance || 'N/A';
              const quoteType = quotationData?.quote_type || 'N/A';
            
              return (
                <div className="px-6 py-4 bg-white border-b border-gray-100">
                  <div className="flex items-center justify-between">
                     <button
                                        onClick={() => navigate(-1)}
                                        className="w-8 h-8 flex items-center justify-center hover:bg-red-50 rounded-full transition-all duration-200"
                                      >
                                        <FontAwesomeIcon icon={faArrowLeft} className="text-gray-700 text-lg" />
                                      </button>    
                    <div className="flex items-center gap-4">
                      <div className="w-12 h-12 bg-gradient-to-br from-red-50 to-white rounded-xl flex items-center justify-center shadow-sm">
                        <FontAwesomeIcon icon={faDoorClosed} className="text-[#EA011F] text-xl" />
                      </div>
                      <div>
                        <h1 className="text-xl font-bold text-gray-800">Doors</h1>
                        <span className="text-sm text-gray-500"></span>
                      </div>
                    </div>
            
                    <div className="text-right flex flex-col space-y-1">
                      <div className="inline-block bg-red-50 px-2 py-1 rounded-full">
                        <h2 className="text-sm font-medium text-[#EA011F]">{quoteType}</h2>
                      </div>
                      <div className="inline-block px-2">
                        <p className="text-sm text-gray-600 mt-1">
                          Ref: <span className="font-medium">{quotationReference}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            };
            
      
      const tableHeader = () => {
        return (
          <div className="sticky top-0 z-10 bg-gray-100">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Item Details
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        );
      };
          

      const ItemsTable = () => {
        if (!quotationData?.items?.length) {
          return (
            <div className="px-4 py-2 text-center text-gray-500">
              No items available
            </div>
          );
        }
      
        const calculateHardwareTotal = (hardware) => {
          if (!Array.isArray(hardware)) return 0;
          return hardware.reduce((total, hw) => total + ((hw?.selling_price || 0) * (hw?.quantity || 0)), 0);
        };
      
        return (
          <div className="px-4 py-2 space-y-6">
            {quotationData.items.map((item, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm overflow-hidden">
                {/* Category Badge */}
                <div className="px-4 py-2 bg-gradient-to-r from-red-50 to-white border-b">
                  <span className="text-xs font-semibold text-[#EA011F] bg-white px-3 py-1 rounded-full shadow-sm">
                    {item?.door_type || 'Uncategorized'}
                  </span>
                </div>
      
                {/* Main Item Info */}
                <div className="p-4">
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <h3 className="text-base font-semibold text-gray-800">
                        {item?.item_name || 'Unnamed Item'}
                      </h3>
                      <div className="mt-2 pl-3 border-l-2 border-red-100">
                        <div className="text-sm text-gray-600">
                          ₹{(item?.selling_price || 0).toLocaleString()} × {item?.quantity || 0} {item?.UOM || 'units'}
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          Including {item?.tax_rate || 0}% GST
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="text-lg font-bold text-[#EA011F]">
                        ₹{(item?.total || 0).toLocaleString()}
                      </div>
                     {/*  <div className="text-xs text-gray-500 mt-1">
                        Tax: ₹{(item?.total_tax_amount || 0).toLocaleString()}
                      </div> */}
                    </div>
                  </div>
      
                  {/* Hardware Section */}
                  {Array.isArray(item?.hardware) && item.hardware.length > 0 && (
                    <div className="mt-3 border-t border-gray-100">
                      <div className="flex items-center justify-between py-2">
                        <div className="flex items-center gap-2">
                          <div className="w-6 h-6 bg-red-50 rounded-full flex items-center justify-center">
                            <FontAwesomeIcon icon={faTools} className="text-[#EA011F] text-xs" />
                          </div>
                          <span className="text-sm font-medium text-gray-700">Hardware</span>
                        </div>
                        <div className="text-right">
                          <span className="text-sm font-semibold text-[#EA011F]">
                            ₹{calculateHardwareTotal(item.hardware).toLocaleString()} {/* X {item.hardware} */}
                          </span>
                          <span className="text-xs text-gray-500 block">Total Hardware</span>
                        </div>
                      </div>
      
                      <div className="pl-2 space-y-1">
                        {item.hardware.map((hw, idx) => (
                          <div key={idx} className="flex justify-between items-center text-xs">
                            <span className="text-gray-600">{hw?.sub_item_name || 'Unknown Hardware'}</span>
                            <div className="flex items-center gap-1">
                              <span className="text-gray-500">{hw?.quantity || 0}x</span>
                              <span className="font-medium">₹{(hw?.selling_price || 0).toLocaleString()}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
      
                  {/* Room Details Section */}
                  <div className="mt-3">
                    <button
                      onClick={() => toggleItem(index)}
                      className="w-full px-3 py-2 bg-gray-50 rounded-lg flex items-center justify-between hover:bg-gray-100 transition-colors"
                    >
                      <div className="flex items-center gap-2">
                        <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center">
                          <FontAwesomeIcon icon={faRuler} className="text-[#EA011F] text-xs" />
                        </div>
                        <span className="text-sm font-medium text-gray-700">Room Details</span>
                      </div>
                      <div className="flex items-center gap-2">
                        {/* <span className="text-xs text-gray-500">{item?.room_category_area || 0} sq.ft</span> */}
                        <FontAwesomeIcon
                          icon={expandedItems[index] ? faChevronUp : faChevronDown}
                          className="text-gray-400 text-xs"
                        />
                      </div>
                    </button>
      
                    {expandedItems[index] && Array.isArray(item?.applied_areas_breakup) && (
                      <div className="mt-2 pl-8">
                        {item.applied_areas_breakup.map((door, doorIndex) => (
                          <div key={doorIndex} className="py-2 border-b border-gray-100 last:border-0">
                            <div className="flex justify-between items-center">
                              <span className="text-sm text-gray-700">{door?.door_name || 'Unnamed Room'}</span>
                              <span className="text-sm font-medium text-[#EA011F]">{door?.area || 0} sq.ft</span>
                            </div>
                            <div className="text-xs text-gray-500 mt-0.5">
                              {door?.height || 0}' × {door?.width || 0}'
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      };
      

      
      
      

      const PriceFooter = () => {
        const total = quotationData?.total || 0;
        const subtotal = quotationData?.subtotal || 0;
        const taxAmount = quotationData?.tax_amount || 0;
      
        return (
          <div className="sticky bottom-0 bg-white shadow-lg border-t border-gray-100">
            <div className="max-w-md mx-auto px-4 py-3">
              <div className="flex justify-between items-center">
                {/* Price Section */}
                <div className="flex flex-col">
                  <div className="flex items-baseline gap-1">
                    <span className="text-lg font-bold text-[#EA011F]">
                      ₹{total.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex items-center text-xs text-gray-400">
                    ₹{subtotal.toLocaleString()} + ₹{taxAmount.toLocaleString()} tax
                  </div>
                </div>
      
                {/* Action Button */}
                <button 
                  //onClick={() => handleInterestClick()}
                  className="group flex items-center gap-3 bg-gradient-to-r from-red-50 to-white border-2 border-[#EA011F] text-[#EA011F] px-4 py-3 rounded-md transition-all duration-300 hover:bg-[#EA011F] hover:text-white hover:shadow-lg hover:shadow-red-100 active:scale-[0.98]"
                >
                  <span className="font-semibold text-sm tracking-wide">I'm Interested</span>
                  <div className="relative">
                    <svg
                      className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 group-hover:opacity-0"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                    </svg>
                    <svg
                      className="w-4 h-4 absolute top-0 left-0 transition-all duration-300 -translate-x-3 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        );
      };
      
      
          
  
      return (
        <div className="flex flex-col h-screen bg-gray-50">
          {/* Sticky Header */}
          <div className="sticky top-0 z-10 bg-white shadow-sm">
            {renderQuotationHeader()}
             {tableHeader()} 
          </div>
      
          {/* Main Content - Scrollable */}
          <main className="flex-1 overflow-y-auto mt-1 mb-4 pb-3">
            <div className="p-1 pb-2 max-w-4xl mx-auto">
              {ItemsTable()}
            </div>
          </main>
      
          {/* Sticky Footer */}
          {PriceFooter()}
        </div>
      );
      
      
  };
  
  export default DoorQuotationSummary;
  

