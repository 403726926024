import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSortUp, faSortDown, faEllipsisV, faX, faChevronDown, faPlus, faPlusCircle,    } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (value, label, id) => {
    onSelect({ value, label, id }); // Pass the entire option object
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div className="relative w-1/3" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || 'Select'}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          <input
            type="text"
            className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <div
                  key={option.value}
                  className="p-2 cursor-pointer text-sm hover:bg-blue-500 hover:text-white"
                  onClick={() => handleOptionClick(option.value, option.label, option.id)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick('add-new', 'Add New')}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ViewPriceLists = () => {
  const  [Pricelists , setPriceLists] = useState ([])
    const navigate = useNavigate()
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [showOpen, setshowOpen] = useState(false);
  const [showExportItemPopup, setshowExportItemPopup] = useState(false);
  const [showAddNewPopup, setShowAddNewPopup] =useState(false);
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedFormat, setSelectedFormat] = useState('');

  const [selectedItems, setSelectedItems] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const [retryFunction, setRetryFunction] = useState(null);
  const dropdownOptions = [
    { value: 'option1', label: 'Option 1', id: '1', },
    { value: 'option2', label: 'Option 2', id: '2', },
    { value: 'option3', label: 'Option 3', id: '3',  },
    { value: 'option4', label: 'Option 4', id: '4', },
  
  ];
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts


  
  useEffect(() => {
    fetchPriceLists();
  }, []);

  const fetchPriceLists = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token'); 
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `pricelist/get-all-pricelists?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token 
        }
      });
      const data = await response.json();
      if (data?.status === 200 || data?.status === 201 || data?.status === 204) {
        setPriceLists(data?.data);
        console.log(data?.data)
      } else if (data.status === 400) {
        setShowPopup(true);
        setPopupType('400');
        setPopupMessage('Bad Request!');
      } else if (data.status === 401) {
        setShowPopup(true);
        setPopupType('401');
        setPopupMessage('Unauthorized!');
      } else if (data.status === 403) {
        setShowPopup(true);
        setPopupType('403');
        setPopupMessage('Forbidden!');
      } else if (data.status === 404) {
        setShowPopup(true);
        setPopupType('404');
        setPopupMessage('Not Found!');
      } else if (data.status === 500) {
        setShowPopup(true);
        setPopupType('500');
        setPopupMessage('Internal Server Error!');
      } else {
        setShowPopup(true);
        setPopupType('unknown');
        setPopupMessage('Unknown error!');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchPriceLists(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchPriceLists(attempt)); // Set retry function
      }
    }finally {
      setIsLoading(false);
    }
  };

if (isLoading) {
  return <Loader />;
}





 const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};



const BackToLoginPage = () => {
  console.log("BackToLoginPage called");
  setShowPopup(false);
};







const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};


const handleCloseAddNewPopup = () => {
  setShowAddNewPopup(false);
  setshowExportItemPopup(true);
};

const handleCreate = () => {
  navigate("/bb/app/items/newpricelist");
};

const handleOpenToggle = () => {
  setshowOpen(!showOpen);
};

const handleNavigatePriceListDetails = (pricelist) => {
  navigate("/bb/app/items/EditPriceList", { state: { PriceListData: pricelist } });
};
 const handleNavigateNewPriceList = ()=>{
  navigate("/bb/app/items/newpriceList");
 }

  return (
    <div className=" bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
        <div className="flex w-full flex-row">
          <div className="w-full flex-1">
              <div className="flex flex-col w-full h-full justify-center items-center">
              <div className="w-full h-full flex flex-col justify-between ">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-6 items-center">
    {selectedItems.length > 0 ? (
      <button /* onClick={handleDelete} */
        className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md ">Delete</button>
    ) : (
      <>
        <h1 className="text-lg font-bold">All Price Lists</h1>
        <div className="flex items-center space-x-2">
          <button onClick={handleCreate} className="bg-[#f7525a] text-white z-20 px-2 py-1 rounded"
            title="" data-tooltip="Create PriceList"  style={{ '--tooltip-bg': 'black' }}>+ New</button>
         {/*  <button onClick={handleOpenToggle} className='bg-gray-100 border border-gray-300 rounded-md px-4 py-1'>
            <FontAwesomeIcon 
              icon={faEllipsisV} 
              className=" cursor-pointer " 
            />
          </button> */}
          {/* {showOpen && (
            <div className="absolute z-20 right-4 mt-28 w-32 bg-white border border-gray-300 rounded-md shadow-lg">
              <button onClick={handleImport} className="block w-full text-left px-2 py-1 text-gray-700 hover:bg-gray-100">Import Items</button>
              <button onClick={handleExport} className="block w-full text-left px-2 py-1 text-gray-700 hover:bg-gray-100">Export Items</button>
            </div>
          )} */}
        </div>
      </>
    )}
  </div>


</div>
                 <div className="w-full h-full bg-white rounded-xs overflow-hidden ">
      <div className="overflow-y-auto h-[80vh] ">
      {Pricelists.length > 0 ? (
        <table className="w-full mb-5 ">
          <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b ">
            <tr className = 'border-b'>
             
              <th className="text-xs py-3 px-4 text-left cursor-pointer">
             NAME
              </th>
              <th className="text-xs py-3 text-left cursor-pointer" /* onClick={() => sortArray('product_name')} */>
              CURRENCY {/* {getSortIcon('product_name') && <FontAwesomeIcon icon={getSortIcon('product_name')} />} */}
              </th>
              <th className="text-xs py-3 text-left cursor-pointer" /* onClick={() => sortArray('product_name')} */>
              DETAILS {/* {getSortIcon('product_name') && <FontAwesomeIcon icon={getSortIcon('product_name')} />} */}
              </th>
              <th className="text-xs py-3 text-left cursor-pointer" /* onClick={() => sortArray('product_name')} */>
              PRICING TYPE {/* {getSortIcon('product_name') && <FontAwesomeIcon icon={getSortIcon('product_name')} />} */}
              </th>
              
            </tr>
          </thead>
          <tbody className='border-b border-t'>
  {Pricelists?.map((pricelist, index) => (
    <tr key={index} className="text-sm border-b">
     
      <td
        className="py-2 px-4 text-left text-[#408dfb] font-500 cursor-pointer"
        onClick={() => handleNavigatePriceListDetails(pricelist)}
      >
        {pricelist?.price_list_name}
      </td>
      <td className="py-2 text-left text-gray-700 font-500 cursor-pointer">
  INR
</td>
<td className="py-2 text-left text-gray-700 font-500 cursor-pointer">
{pricelist?.applied_type}
</td>

<td
  className="py-2  text-left text-gray-700 font-500 cursor-pointer"
>
  {pricelist.pricing_type ? pricelist.pricing_type : '-'}
</td>

      </tr>
  ))}
</tbody>

        </table>
      ):(
        <>
        <div className="m-4 p-4 text-center border border-grey-500 text-gray-500">
            <h1 className="text-lg">No Price Lists Available</h1>
        </div>
        <div className="flex justify-center items-center mb-4">
            <h1 className="text-xl font-bold">Create  New Price List </h1>
        </div>
        <div className="flex justify-center items-center">
            <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleNavigateNewPriceList} >Create</button>
        </div>
    </>
      )}
      </div>
    </div>
              </div>
            </div>
          </div>
      {/*   </div> */}
      </div>



{showAddNewPopup && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 overflow-y-auto">
    <div className="bg-white rounded-md shadow-md w-1/2 h-3/4 flex flex-col"> {/* Increased height */}
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-16 px-6'> {/* Adjusted header height */}
        <h2 className="text-md">New Export Template</h2>
        <button onClick={handleCloseAddNewPopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="px-6 py-4">
        <label htmlFor="newTemplate" className="block text-sm font-regular text-[#e54643]">
          Template Name<span className="text-red-500">*</span>
        </label>
        <input type="text" id="newTemplate" className="border border-gray-300 rounded-md shadow-sm p-1 w-1/3" />
      </div>
      <div className="px-6 py-4 flex-grow">
        {/* Table Content */}
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border-b border-t px-2 py-1 text-gray-500 font-regular text-sm text-left">Field Name</th>
              <th className="border-b border-t px-2 py-1 text-gray-500 font-regular text-sm text-left">Field Name in Export File</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td className="border-b w-1/2">
                {/* <CustomDropdown 
                  
                /> */}
              </td>
              <td className="border-b">
                <input 
                  type="text" 
                  className="border border-gray-300 rounded-md shadow-sm p-1 w-1/2" 
                />
              </td>
            </tr>
            {/* Repeat above <tr> block for more rows as needed */}
          </tbody>
        </table>
        <div className="mt-4">
                    <button   /* onClick={addRow} */   className="text-blue-500 py-2 px-4"  >
                      <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                      Add a New Field     </button>
                  </div>
      </div>
      <div className="mt-auto flex flex-col w-full">
  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
    <button 
      // onClick={handleCloseAddNewPopup} 
      className="px-2 py-1 bg-[#f7525a] text-white border border-[#f7525a] rounded-md"
    >
      Save and Select
    </button>
    <button 
      onClick={handleCloseAddNewPopup} 
      className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md"
    >
      Cancel
    </button>
  </div>
</div>

    </div>
  </div>
)}



      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
 </div>
    
  );
};

export default ViewPriceLists;