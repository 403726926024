import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faDotCircle, faFutbolBall, faX, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import OtherDetails from '../Components/OtherDetails';
import Address from '../Components/Address';
import ContactPersons from '../Components/ContactPersons';
import BankDetails from '../Components/BankDetails';
import { Loader ,Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

function EditVendor() {
  const navigate = useNavigate();
  const location = useLocation();
  const { VendorData: initialVendorData } = location.state;

  const [VendorData, setVendorData] = useState(initialVendorData);
  console.log(VendorData);
  const [showOtherDetails, setShowOtherDetails] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [showContactPersons, setShowContactPersons] = useState(false);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
const [ VendorContactPersons , setVendorContactPersons] = useState([]);
const RETRY_LIMIT = 3; // Maximum number of retries
const [retryCount, setRetryCount] = useState(0); // Track retry attempts
const [retryFunction, setRetryFunction] = useState(null);
const [isLoading, setIsLoading] = useState(null);
const [errors, setErrors] = useState({ email:'', mobile_number: '',gstin: '', pan: '' });
const [image,setImage] = useState('');
const [imagePreview, setImagePreview] = useState(null);
const [isSubmitting, setIsSubmitting] = useState(false);

const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
const [loadingContacts, setLoadingContacts] = useState(true);


  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  const [data, setData] = useState({
    name: '',
    company_name: '',
    state: '',
    email: '',
    mobile_number: '',
    image: '',
  });

  console.log(data);

  const [otherDetails, setOtherDetails] = useState({
    gstin: '',
    pan: '',
    city: '',
  });

  console.log(otherDetails);

  const [address, setAddress] = useState({
    address: '',
    city: '',
    state: '',
    pincode: '',
  });

  const [contactPersons, setContactPersons] = useState([
    { name: '', email: '', mobile_number: '', designation: '' }
  ]);

  const [bankDetails, setBankDetails] = useState({
    bank_name: '',
    bank_IFSC: '',
    account_number: '',
    account_holder_name: ''
  });

 /*  const handleChangeOtherDetails = (e) => {
    const { name, value } = e.target;
    setVendorData((prevState) => ({ ...prevState, [name]: value }));
  };
 */
  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setVendorData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeContact = (updatedContacts) => {
    setContactPersons(updatedContacts);
  };

  const addContactPerson = () => {
    setContactPersons([...contactPersons, { name: '', email: '', mobile_number: '', designation: '' }]);
  };

  const removeContactPerson = (index) => {
    const updatedContacts = contactPersons.filter((_, i) => i !== index);
    setContactPersons(updatedContacts);
  };

  const handleChangeBankDetails = (e) => {
    const { name, value } = e.target;
    setVendorData((prevState) => ({ ...prevState, [name]: value }));
  };

 /*  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendorData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };  */

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'mobile_number') {
      if (value.length > 10) {
        return;
      }
      
      if (value.length === 1 && !['6','7','8','9'].includes(value)) {
        setErrors((prevState) => ({
          ...prevState,
          mobile_number: 'Mobile number must start with 6, 7, 8 or 9'
        }));
        return;
      }

      if (!/^[0-9]*$/.test(value)) {
        return;
      }

      setVendorData((prevState) => ({...prevState, [name]: value }));
      setData((prevState) => ({...prevState, [name]: value }));

      if (value.length < 10 && value.length > 0) {
        setErrors((prevState) => ({...prevState, mobile_number: 'Enter 10 Digits Mobile Number please' }));
      } else {
        setErrors((prevState) => ({...prevState, mobile_number: '' }));
      }
    }

    if (name === 'email') {
      if (value === '') {
        setErrors((prevState) => ({...prevState, email: '' }));
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrors((prevState) => ({...prevState, email: 'Please enter a valid email address' }));
        } else {
          setErrors((prevState) => ({...prevState, email: '' }));
        }
      }
      setVendorData((prevState) => ({...prevState, [name]: value }));
      setData((prevState) => ({...prevState, [name]: value }));
    }

    if (name === 'name' || name === 'company_name') {
      setVendorData((prevState) => ({...prevState, [name]: value }));
      setData((prevState) => ({...prevState, [name]: value }));
    }
};

  
  

  const handleChangeOtherDetails = (e) => {
    const { name, value } = e.target;
  
    // Prevent GSTIN from exceeding 15 characters
    if (name === 'gstin' && value.length > 15) {
      return;
    }
  
    // Prevent PAN from exceeding 10 characters
    if (name === 'pan' && value.length > 10) {
      return;
    }
  
    // Validate GSTIN
    if (name === 'gstin') {
      if (value === '') {
        setErrors((prevState) => ({...prevState, gstin: '' }));
      } else {
        const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (!gstinRegex.test(value)) {
          setErrors((prevState) => ({...prevState, gstin: 'Invalid GSTIN format' }));
        } else {
          setErrors((prevState) => ({...prevState, gstin: '' }));
        }
      }
    }
  
    // Validate PAN
    if (name === 'pan') {
      if (value === '') {
        setErrors((prevState) => ({...prevState, pan: '' }));
      } else {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (!panRegex.test(value)) {
          setErrors((prevState) => ({...prevState, pan: 'Invalid PAN format' }));
        } else {
          setErrors((prevState) => ({...prevState, pan: '' }));
        }
      }
    }
  
    setVendorData((prevState) => ({...prevState, [name]: value }));
    setOtherDetails((prevState) => ({...prevState, [name]: value }));
  };

  const updateState = (value, label) => {
    setVendorData((prevState) => ({
      ...prevState,
      state: label,
    }));
  };

  const updateAddressState = (value, label) => {
    setVendorData((prevState) => ({
      ...prevState,
      state: label,
    }));
  };


  useEffect(() => {
   
      fetchContactPersonsByVendorId(VendorData._id)
   
  }, []);


useEffect(() => {
  if (VendorData && VendorData.image) {
    setImagePreview(`${imageUrl}${VendorData.image}`);
  } else {
    setImagePreview(null); 
  }
}, [VendorData]);

const fetchContactPersonsByVendorId = async (vendorId, attempt = 1) => {
  setLoadingContacts(true);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}vendor/get-vendor-and-contacts?vendor_id=${vendorId}`, {
      headers: { 'x-token': token }
    });
    
    const data = await response.json();
    
    if (response.ok) {
      setContactPersons(data.data.contacts || []);
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    if (attempt < RETRY_LIMIT) {
      setTimeout(() => fetchContactPersonsByVendorId(vendorId, attempt + 1), 1000);
    } else {
      setPopupMessage('Failed to load contacts. Please try again.');
      setPopupType('500');
      setShowPopup(true);
    }
  } finally {
    setLoadingContacts(false);
  }
};

  
const handleSubmit = async (_id, attempt = 1) => {
  // Prevent multiple submissions
  if (isSaving) return;

  const errorMessages = [];
  
  // Required field validations
  const requiredFields = {
    name: 'Vendor Name',
    gstin: 'GSTIN',
    pan: 'PAN'
  };

  Object.entries(requiredFields).forEach(([field, label]) => {
    if (!VendorData[field]) {
      errorMessages.push(`${label} is required.`);
    }
  });

  // Validate mobile number if provided
  if (VendorData.mobile_number) {
    if (VendorData.mobile_number.length !== 10 || !/^[0-9]+$/.test(VendorData.mobile_number)) {
      errorMessages.push('Enter 10 Digits Mobile Number please.');
    }
  }

  // Validate email if provided
  if (VendorData.email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(VendorData.email)) {
      errorMessages.push('Please enter a valid email address.');
    }
  }

  // Validate GSTIN format
  if (VendorData.gstin && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(VendorData.gstin)) {
    errorMessages.push('Invalid GSTIN format.');
  }

  // Validate PAN format
  if (VendorData.pan && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(VendorData.pan)) {
    errorMessages.push('Invalid PAN format.');
  }

  if (errorMessages.length > 0) {
    setErrorMessages(errorMessages);
    return;
  }

  setIsSaving(true);

  const formData = new FormData();

  // Append all vendor data
  Object.keys(VendorData).forEach(key => {
    if (VendorData[key] !== undefined && VendorData[key] !== null) {
      formData.append(key, VendorData[key]);
    }
  });

  // Append image if exists
  if (image) {
    formData.append('image', image);
  }

  // Append contact persons
  formData.append('vendorContacts', JSON.stringify(contactPersons));

  try {
    const token = sessionStorage.getItem('token');
    const UpdateUrl = `${fetchUrl}vendor/update-vendor-and-contacts`;

    const response = await fetch(UpdateUrl, {
      method: 'PUT',
      headers: {
        'x-token': token,
      },
      body: formData,
    });

    const responseData = await response.json();

    const statusHandlers = {
      200: () => {
        setShowPopup(true);
        setPopupType('200or201');
        setPopupMessage(responseData.message);
        setTimeout(() => {
          setShowPopup(false);
          navigate('/bb/app/purchases/vendordetails', { 
            state: { VendorData: responseData.data.vendor } 
          });
        }, 2000);
      },
      403: () => {
        setPopupType('403');
        setPopupMessage('Your session has expired');
      },
      404: () => {
        setPopupType('404');
        setPopupMessage('Vendor not found. Please try updating a different vendor.');
      },
      500: () => {
        setPopupType('500');
        setPopupMessage('Internal Server Error. Please try again later.');
      }
    };

    const handler = statusHandlers[response.status] || (() => {
      setPopupType('400');
      setPopupMessage(responseData.message || 'Unknown error occurred');
    });

    handler();
    setShowPopup(true);

  } catch (error) {
    console.error('Failed to update vendor:', error);

    if (attempt < RETRY_LIMIT) {
      setTimeout(() => handleSubmit(_id, attempt + 1), 1000);
    } else {
      setPopupType('500');
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setShowPopup(true);
      setRetryFunction(() => () => handleSubmit(_id, attempt));
    }
  } finally {
    setIsSaving(false);
    setIsLoading(false);
  }
};

  const handleCancel = () => {
    setShowCancelPopup(true);
  }
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }
  const handleLeave = () => {
    navigate(-1);
  }

  const handleCloseError = () => {
    setErrorMessages([]);
  };
  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  

  if (isLoading) {
    return <Loader/>;
  }

  const Retry = async () => {
    console.log("Retry called");
    setIsLoading(true); // Show loader
    setTimeout(async () => {
      if (retryFunction) {
        await retryFunction();
      }
      setIsLoading(false); // Hide loader after retry
    }, 5000); // 5-second delay
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Edit Vendor</h1>
                  {/* <button className="text-white px-1 py-1 rounded-md">
                    <FontAwesomeIcon icon={faX} className="text-md text-[#F7525A] cursor-pointer" />
                  </button> */}
                </div>
              </div>
              {errorMessages.length > 0 && (
                <div className="flex justify-between items-center  px-auto py-3  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )}
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4">
                <div className='w-full flex flex-row'>
                 <div className="flex flex-col w-full">

                    {/* Company Name Label and Input */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="companyName" className="block text-sm font-regular text-gray-700 w-1/6">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="company_name"
                      value={VendorData.company_name}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                  </div>

                  {/* Vendor Name Label and Input */}
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="name" className="block text-sm font-regular text-[#e54643] w-1/6">
                      Vendor Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={VendorData.name}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                  </div>
                   {/* Company Name Label and Input */}
                   <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="email" className="block text-sm font-regular text-gray-700 w-1/6">
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={VendorData.email}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                  </div>

                   {/* Company Name Label and Input */}
                   <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="mobile" className="block text-sm font-regular text-gray-700 w-1/6">
                      Mobile
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile_number"
                      value={VendorData.mobile_number}
                      onChange={handleChange}
                      className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                    />
                     {errors.mobile_number && 
    <p className="text-red-500 text-sm ml-2">{errors.mobile_number}</p>
  }
                  </div>
                  </div>
                  <div className='w-1/2 justify-start items-start mt-5'>
              {/* Image Upload Box */}
              <div className=" w-1/2 h-[50%] border-2 border-dashed border-gray-300 rounded-lg flex justify-center items-center relative">
                <input
                  type="file"
                  id="itemImage"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleImageUpload}
                />
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="text-gray-400 text-center">Click to upload image</div>
                )}
              </div>
              </div>
                    </div>
                    

                  <div className='flex w-full items-center border-b'>
                    <div className="w-full flex flex-row space-x-2">
                      <button
                        className={`w-1/6 p-2 text-sm ${showOtherDetails ? 'text-[#21263C] font-medium border-b-2 border-blue-500' : ''}`}
                        onClick={() => {
                          setShowOtherDetails(true);
                          setShowAddress(false);
                          setShowContactPersons(false);
                          setShowBankDetails(false);
                        }}
                      >
                        Other Details
                      </button>
                      <button
                        className={`w-1/6 p-2 text-sm ${showAddress ? 'text-[#21263C] font-medium border-b-2 border-blue-500' : ''}`}
                        onClick={() => {
                          setShowOtherDetails(false);
                          setShowAddress(true);
                          setShowContactPersons(false);
                          setShowBankDetails(false);
                        }}
                      >
                        Address
                      </button>
                      <button
                        className={`w-1/6 p-2 text-sm ${showContactPersons ? 'text-[#21263C] font-medium border-b-2 border-blue-500' : ''}`}
                        onClick={() => {
                          setShowOtherDetails(false);
                          setShowAddress(false);
                          setShowContactPersons(true);
                          setShowBankDetails(false);
                        }}
                      >
                        Contact Persons
                      </button>
                      <button
                        className={`w-1/6 p-2 text-sm ${showBankDetails ? 'text-[#21263C] font-medium border-b-2 border-blue-500' : ''}`}
                        onClick={() => {
                          setShowOtherDetails(false);
                          setShowAddress(false);
                          setShowContactPersons(false);
                          setShowBankDetails(true);
                        }}
                      >
                        Bank Details
                      </button>
                    </div>
                  </div>

                  {/* Other Details Section */}
                  {showOtherDetails &&  <OtherDetails
          gstin={VendorData.gstin}
          pan={VendorData.pan}
          city={VendorData.city}
          state={VendorData.state}
          onChange={handleChangeOtherDetails}
          errors={errors}
          updateState={updateState}
        />}

                  {/* Address Section */}
                  {showAddress && <Address
          address={VendorData.address}
          city={VendorData.city}
          state={VendorData.state}
          pincode={VendorData.pincode}
          onChange={handleChangeAddress}
          updateAddressState={updateAddressState}
        />}

                  {/* Contact Persons Section */}
                  {showContactPersons && <ContactPersons
                  contactPersons={contactPersons}
                  onChange={handleChangeContact}
                  addContactPerson={addContactPerson}
                  removeContactPerson={removeContactPerson}
                />
                }

                 {/* Contact Persons Section */}
                 {showBankDetails && <BankDetails
                    account_holder_name={VendorData.account_holder_name}
                    bank_name={VendorData.bank_name}
                    bank_branch={VendorData.bank_branch}
                    bank_IFSC={VendorData.bank_IFSC}
                    account_number={VendorData.account_number}
                    onChange={handleChangeBankDetails}
                  />}


                </div>
                </div>

                <div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
    <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md inline-flex items-center" onClick={handleSubmit}>
      <span>Save</span>
      {isSaving && <Spinner />}
    </button>
    <button  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}
         onClick={handleCancel}>
                      Cancel
                    </button>

                </div>
                </div>

              </div>
            </div>
        </div>
      </div>

      {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
    </div>
  );
}

export default EditVendor;
