import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faTrash, faExclamationTriangle, faX, faPlusCircle, faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons';
import '../Css/Animation.css';
import { Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import Dropdown from './DropDown';
import TableSearchDropDown from '../Components/tableSerchDropDown';

// Custom hook for handling outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);
  
    const filteredOptions = options.filter(option => {
      if (!option || !option.label) return false;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  
    const handleOptionClick = (value , id) => {
      onSelect(value , id);
      setIsOpen(false);
      setFocusedOptionIndex(null);
    };
  
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedOptionIndex !== null) {
            const selectedOption = filteredOptions[focusedOptionIndex];
            handleOptionClick(selectedOption.value, selectedOption.id);
          }
          break;
        default:
          break;
      }
    };
  
    const scrollToOption = (index) => {
      const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
      if (optionElements && optionElements[index]) {
        optionElements[index].scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    };
  
    useOutsideClick(dropdownRef, () => setIsOpen(false));
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);
  
  
    return (
      <div className="relative w-1/3" ref={dropdownRef}>
        <div
          className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
            {selected || 'Select'}
          </span>
          <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
        </div>
        {isOpen && (
          <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoFocus
            />
            <div className="overflow-y-auto scrollbar-visible flex-grow">
              {options.filter(option => 
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
              ).map((option) => (
                <div
                  key={option.value}
                  className="p-2 cursor-pointer text-sm hover:bg-blue-500 hover:text-white"
                  onClick={() => {
                    onSelect(option.value, option.id);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
            {showAddNew && (
              <button
                type="button"
                className="flex items-center border-t p-2 mt-2 text-blue-500"
                onClick={showAddNew}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
                New UOM
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  
  const EditProductCategory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const productCategoryData = location.state?.ProductCategoryData;
  
    const [ProductSubCategory, setProductSubCategory] = useState([]);
    const [product_category_name, setProductCategoryName] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [showNewUOMPopup, setShowNewUOMPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedUOM, setSelectedUOM] = useState('');
    const [SelectdPCUomid, setSelectdPCUomid] = useState('');
    const [UOM, setUOM] = useState('');
    const [description, setdescription] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [PCErrorMessage, setPCErrorMessage] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState('');
    const [UomName, setUomName] = useState('');
    const [UomIndex, setUomIndex] = useState();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [ProductCategoryDescription, setProductCategoryDescription] = useState('');
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [uomOptions, setUomOptions] = useState([]);
    const [DropDownUoms, setDropDownUoms] = useState([]);
    const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const [deletedSubCategoryIds, setDeletedSubCategoryIds] = useState([]);
  
    useEffect(() => {
      if (productCategoryData) {
        setProductCategoryName(productCategoryData.product_category_name);
        setProductCategoryDescription(productCategoryData.product_category_description);
        
        if (productCategoryData.UOM_id) {
          setSelectedUOM(productCategoryData.UOM_id.UOM);
          setSelectdPCUomid(productCategoryData.UOM_id._id);
        }
    
        const formattedSubCategories = productCategoryData.product_sub_categories?.map(subCat => ({
            _id: subCat._id, 
          product_sub_category_name: subCat.product_sub_category_name,
          UOM_type: subCat.uom,
          UOM_id: subCat.UOM_id,
          product_sub_category_description: subCat.product_sub_category_description,
          product_sub_category_image: subCat.product_sub_category_image
        })) || [];
    
        setProductSubCategory(formattedSubCategories);
      }
    }, [productCategoryData]);
           
       
       
    const getUoms = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        
        const data = await response.json();
        if (data.status === 200 || data.status === 201) {
          // Format UOMs for dropdown
          const formattedUoms = data.data.map(uom => ({
            label: uom.UOM,
            value: uom.UOM,
            id: uom._id
          }));
          setDropDownUoms(formattedUoms);
          setUomOptions(formattedUoms);
        }
      } catch (error) {
        console.error('Failed to fetch UOMs:', error);
      }
    };
    
    useEffect(() => {
      getUoms();
    }, []);

    const handleUomChange = (value, id) => {
      setSelectedUOM(value);
      setSelectdPCUomid(id);
    };
    
  
    const HandleChangeUom = (option, index) => {
      const updatedData = [...ProductSubCategory];
      if (index !== null && option) {
        updatedData[index] = {
          ...updatedData[index],
          UOM_type: option.label,
          UOM_id: option.id
        };
      }
      setProductSubCategory(updatedData);
      setIsDropdownVisible(false);
    };
  
    const handleProductSubCategoryChange = (e, index, field) => {
      const updatedData = [...ProductSubCategory];
      updatedData[index][field] = e.target.value;
      setProductSubCategory(updatedData);
  
      if (field === 'UOM_type') {
        setUomName(e.target.value);
        setIsDropdownVisible(true);
        setUomIndex(index);
      }
    };
  
    const handleImageChange = (index, event) => {
      const file = event.target.files[0];
      if (file) {
        const updatedData = [...ProductSubCategory];
        updatedData[index].product_sub_category_image = file;
        setProductSubCategory(updatedData);
      }
    };
  
    const addRow = () => {
      setUomIndex();
      const newRow = { product_sub_category_name: '', UOM_type: '', product_sub_category_description: '', UOM_id: '' };
      setProductSubCategory([...ProductSubCategory, newRow]);
    };
  
    const deleteRow = (index) => {
      const updatedData = [...ProductSubCategory];
      const deletedSubCategory = updatedData[index];
      
      // If the subcategory has an ID, add it to deletedSubCategoryIds
      if (deletedSubCategory._id) {
          setDeletedSubCategoryIds(prev => [...prev, deletedSubCategory._id]);
      }
      
      // Remove from UI
      updatedData.splice(index, 1);
      setProductSubCategory(updatedData);
  };
  
    const sortArray = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
  
      const sortedArray = [...ProductSubCategory].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
        return 0;
      });
      setProductSubCategory(sortedArray);
    };
  
    const getSortIcon = (key) => {
      if (sortConfig.key === key) {
        return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
      }
      return null;
    };
    
   
   
    const handleSubmitProductCategory = async () => {
      const PCErrorMessage = [];
      if (!product_category_name) {
        PCErrorMessage.push('Item Category Name is Mandatory');
      }
      if (PCErrorMessage.length > 0) {
        setPCErrorMessage(PCErrorMessage);
        return;
      }

      const formData = new FormData();
      formData.append('product_category_name', product_category_name);
      formData.append('product_category_description', ProductCategoryDescription);
      if(SelectdPCUomid){
        formData.append('UOM_id', SelectdPCUomid);
      }
     
      // Convert ProductSubCategory array to string before appending
      const subCategoriesString = JSON.stringify(ProductSubCategory.map(subCategory => ({
          product_sub_category_name: subCategory.product_sub_category_name,
          product_sub_category_description: subCategory.product_sub_category_description,
          UOM_id: subCategory.UOM_id,
          _id: subCategory._id,
          //product_sub_category_image: subCategory.product_sub_category_image instanceof File ? null : subCategory.product_sub_category_image

      })));
      formData.append('product_sub_categories', subCategoriesString);
  
      
      formData.append('deleted_sub_category_ids', JSON.stringify(deletedSubCategoryIds));
  
      
      const subCategoryIds = ProductSubCategory.map(subCategory => subCategory._id).filter(id => id); // Filter out undefined IDs
  
      ProductSubCategory.forEach((subCategory) => {
        if (subCategory.product_sub_category_image instanceof File) {
            formData.append(`files[${subCategory._id}]`, subCategory.product_sub_category_image);
        }
    });
    

      try {
          setIsSaving(true);
          const token = sessionStorage.getItem('token');
  
          const response = await fetch(`${fetchUrl}product-category/update-productCategory?product_category_id=${productCategoryData._id}`, {
              method: 'PUT',
              headers: {
                  'x-token': token // Do not set 'Content-Type' header
              },
              body: formData // FormData will automatically set the correct content type
          });
  
          const data = await response.json();
  
          if (data.status === 200 || data.status === 201) {
              setPopupMessage('Product category updated successfully');
              setPopupType('200or201');
              setShowPopup(true);
              setTimeout(() => {
                  navigate('/bb/app/items/productcategorydetails', { 
                      state: { ProductCategoryData: data.data } 
                  });
              }, 2000);
          } else {
              // Handle non-successful response status
              setPopupMessage('Failed to update category: ' + data.message);
              setPopupType('error');
              setShowPopup(true);
          }
      } catch (error) {
          console.error("Error updating category:", error);
          setPopupMessage('Failed to update category');
          setPopupType('500');
          setShowPopup(true);
      } finally {
          setIsSaving(false);
      }
  };
  


const handleClosePopup = () => {
  setShowNewUOMPopup(false);
  setUOM('');
  setdescription('');
  setErrorMessages([]);
  setPCErrorMessage([]);
};

const handleSubmitUomData = async () => {
  const errorMessages = [];
  if (!UOM) {
    errorMessages.push('Please enter UOM');
  }
  if (errorMessages.length > 0) {
    setErrorMessages(errorMessages);
    return;
  }
  setIsSaving(true);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}UOMs/create-UOM`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify({
        UOM: UOM,
        UOM_description: description,
      }),
    });
    const data = await response.json();
    if (data.status === 200 || data.status === 201) {
      setIsSaving(false);
      setPopupMessage('UOM created successfully');
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      setShowNewUOMPopup(false);
      setUOM('');
      setdescription('');
      getUoms();
    }
  } catch (error) {
    setIsSaving(false);
    setPopupMessage('Internal Server Error. Kindly Retry.');
    setPopupType('500');
    setShowPopup(true);
  }
};

  const handleCloseError = () => {
    setErrorMessages([]);
    setPCErrorMessage([]);
  };

  const closePopup = () => {
   
    setShowPopup(false);
  };
  
  const Retry = () => {
  
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {

    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleCancel = () => {
    setShowCancelPopup(true);
  }

  const handleLeave = () => {
    navigate(-1);
  }

  return (
    <div className=" bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
         <div className="flex flex-row w-full"> 
          <div className="w-full flex-1">
            <div className="flex flex-col w-full h-full justify-center items-center">
              <div className="w-full h-full flex flex-col justify-between ">
              <div className="w-full h-16 border-b flex items-center">
  <div className="flex w-full justify-between px-4 items-center">
    <h1 className="text-lg font-semibold">Edit Item Categories</h1>
  </div>
</div>
              {PCErrorMessage.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {PCErrorMessage.map((PCErrorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {PCErrorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}
                <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                  <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                    {/* Product Category Name Label and Input */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                      <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Item Category Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="productCategoryName"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                       value={product_category_name}
                       onChange={(e)=>setProductCategoryName(e.target.value)}
                      />
                    </div>

                    <div className="w-full flex flex-row items-center mb-4 space-x-2">
                      <label htmlFor="uom" className="block text-sm font-regular text-gray-700 w-1/6">UOM</label>
                      <CustomDropdown
  options={uomOptions}
  selected={selectedUOM} // This should now display correctly
  onSelect={handleUomChange}
  labelField="UOM"
  valueField="_id"
  placeholder="Select UOM"
  showAddNew={() => setShowNewUOMPopup(true)}
/>

                    </div>
      
                    {/* Description Label and Textarea */}
                    <div className="w-full flex flex-row items-center mb-4 space-x-2">
                      <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
                      <textarea
                        id="Description"
                        name="Description"
                        className="mt-1 w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={ProductCategoryDescription}
                        onChange={(e)=>setProductCategoryDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-start items-start mb-4 mt-4 px-4">
                    <h1 className="text-md font-semibold">Item Sub-Categories</h1>
                  </div>
                  <div className="w-full container mx-auto px-4 mb-2 mt-4">
                  {ProductSubCategory.length > 0 ? (

           <table className="w-full">
                      <thead>
                        <tr>
                        <th className="text-xs font-medium py-2 px-4 border-t border-r border-gray-200 cursor-pointer" onClick={() => sortArray('productSubCategory')}>
                            ITEM SUB-CATEGORY IMAGE {getSortIcon('productSubCategory') && <FontAwesomeIcon icon={getSortIcon('productSubCategory')} />}
                          </th>
                          <th className="text-xs font-medium py-2 px-4 border-t border-r border-gray-200 cursor-pointer" onClick={() => sortArray('productSubCategory')}>
                            ITEM SUB-CATEGORY NAME {getSortIcon('productSubCategory') && <FontAwesomeIcon icon={getSortIcon('productSubCategory')} />}
                          </th>
                          <th className="text-xs font-medium py-2 px-4 border-t border-r border-gray-200 cursor-pointer" onClick={() => sortArray('uom')}>
                            UOM {getSortIcon('uom') && <FontAwesomeIcon icon={getSortIcon('uom')} />}
                          </th>
                          <th className="text-xs font-medium py-2 px-4 border-t border-gray-200 cursor-pointer" onClick={() => sortArray('description')}>
                            DESCRIPTION {getSortIcon('description') && <FontAwesomeIcon icon={getSortIcon('description')} />}
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
          {ProductSubCategory.map((productsubcategory, index) => (
       <tr key={index}>
       <td className="border-t border-r border-b border-gray-200">
         <input
           type="file"
           accept="image/*"
           id={`file-input-${index}`}
           onChange={(e) => handleImageChange(index, e)}
           className="hidden"
         />
         <label htmlFor={`file-input-${index}`} className="cursor-pointer flex justify-center items-center">
           {productsubcategory.product_sub_category_image ? (
             typeof productsubcategory.product_sub_category_image === 'string' ? (
               <img 
                 src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${productsubcategory.product_sub_category_image}`}
                 alt="Subcategory" 
                 className="w-10 h-10 object-cover" 
               />
             ) : (
               <img 
                 src={URL.createObjectURL(productsubcategory.product_sub_category_image)}
                 alt="Uploaded" 
                 className="w-10 h-10 object-cover" 
               />
             )
           ) : (
             <FontAwesomeIcon icon={faImage} className="text-blue-500" />
           )}
         </label>
       </td>
     

       <td className="border-t border-r border-b border-gray-200">
        
           <input
             type="text"
             value={productsubcategory?.product_sub_category_name}
             onChange={(e) => handleProductSubCategoryChange(e, index, 'product_sub_category_name')}
             className="outline-blue-500 text-sm w-full bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 p-4"
           />
      
       </td>
       <td className="border-t border-r border-b border-gray-200">
       <TableSearchDropDown
      options={DropDownUoms}
      value={productsubcategory.UOM_type}
      onChange={(selectedOption) => HandleChangeUom(selectedOption, index)}
      index={index}
      getFilteredOptions={(options, inputValue) => 
        options.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      }
      setEditUOM={(value) => setUomName(value)}
    />
  </td>
       
       <td className="border-t border-b border-gray-200 ">
         
           <textarea
             value={productsubcategory?.product_sub_category_description}
             onChange={(e) => handleProductSubCategoryChange(e, index, 'product_sub_category_description')}
             placeholder="Description"
             className="outline-blue-500 text-sm px-4 w-full h-full bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
           />
        
       </td>
       <td className="py-2 px-4 text-center">
         <button
           onClick={() => deleteRow(index)}
           className="text-red-500 hover:text-red-700 focus:outline-none"     >
           <FontAwesomeIcon icon={faTrash} />
         </button>
       </td>
     </tr>
          ))}
        </tbody>
                    </table>
  ) : (
    <div className="flex justify-center items-center p-4">
    <p className="text-gray-500">No subcategories available for this product category</p>
  </div>
)}

             
                
{ /* Button to add another row */}
          <div className="mt-4">
                    <button   onClick={addRow}   className="text-blue-500 py-2 px-4"  >
                      <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                      Add Item Sub-Category     </button>
                  </div>
                  </div>
                  </div>
                  <div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
          onClick={handleSubmitProductCategory}>
           <span>Save</span>
            {isSaving && ( <Spinner/>)}
            </button>
          <button onClick={handleCancel}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
        </div>
        </div>
                </div>
              </div>  
          </div>
         </div> 

         {/* Popup starts */}

        {showNewUOMPopup && (
  <div className="fixed inset-0 z-50 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col animate-slide-in ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">New UOM</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {errorMessages.length > 0 && (
        <div className='p-1'>
                <div className="mt-1 flex justify-between items-center  px-auto py-1  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
                </div>
              )}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-6">
        <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">
          UOM<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="productCategoryName"
          className="w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={UOM}
          onChange={(e)=>{setUOM(e.target.value)}}
       />
      </div>
      {/* Description Label and Textarea */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 px-6">
        <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
        <textarea
          id="Description"
          name="Description"
          className="mt-1 w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={description}
          onChange={(e)=>{setdescription(e.target.value)}}
        />
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
          onClick={handleSubmitUomData}>
           <span>Save</span>
            {isSaving && <Spinner />}
            </button>
          <button onClick={handleClosePopup}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
        Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}

{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* Popup ends */}

      </div>
  
  );
};

export default EditProductCategory;
