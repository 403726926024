import { faChevronLeft, faPhone, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';

const DoorQuotationSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quotationData, projectMeasurements, customerId, projectId, categoryId, quotationId } = location.state;
  console.log(quotationId)
  const [isLoading, setIsLoading] = useState(true);
  const [UOMS , setUOMS] = useState([]);

  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const calculateRoomTotal = (roomName) => {
        const doorItems = quotationData.items.filter(item => 
          item.area_types.area_name.trim() === roomName.trim() && 
          item.UOM === "Sq.Ft"
        );
        return doorItems.reduce((sum, item) => sum + item.total, 0);
      };
    
      const getHardwareBreakup = () => {
        const hardwareItems = quotationData.items.filter(item => item.UOM === "No");
        const uniqueHardware = hardwareItems.reduce((acc, item) => {
          if (!acc[item.item_name]) {
            acc[item.item_name] = {
              name: item.item_name,
              price: item.selling_price,
              UOM: item.UOM,
              quantity: 0,
              total: 0
            };
          }
          acc[item.item_name].quantity += Number(item.quantity);
          acc[item.item_name].total += item.total;
          return acc;
        }, {});
        return Object.values(uniqueHardware);
      };

      const getItemsByUOM = () => {
        const groupedItems = quotationData.items.reduce((acc, item) => {
          if (!acc[item.UOM]) {
            acc[item.UOM] = [];
          }
          acc[item.UOM].push(item);
          return acc;
        }, {});
        return groupedItems;
      };
      

  const calculateTotalHardware = () => {
    const hardwareItems = quotationData.items.filter(item => item.UOM === "No");
    return hardwareItems.reduce((sum, item) => sum + item.total, 0);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleDeleteQuotation = async (quotationId) => {
    console.log('Deleting quotation with ID:', quotationId);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}customer/quotations/delete-quotation?quotation_id=${quotationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
  
      const data = await response.json();
      console.log(data);
      
      if (data.success) {
        // Show success message
        setPopupMessage('Quotation deleted successfully');
        setPopupType('success');
        setShowPopup(true);
        
        // Refresh quotations list or navigate back
        navigate(-1);
      } else {
        setPopupMessage(data.message || 'Failed to delete quotation');
        setPopupType('error');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error deleting quotation:', error);
      setPopupMessage('Failed to delete quotation');
      setPopupType('error');
      setShowPopup(true);
    }
  };

  useEffect(() => {
    getUoms();
 }, []);


  const getUoms =  async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await fetch(fetchUrl + `UOMs/get-all-UOMs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      //console.log(data)
      if(output) {
        setUOMS(output);
      }else{
        setUOMS([]);
      }

    } catch (error) {
      console.error('Error fetching UOMs:', error);
    }
  };

  const SkeletonLoader = () => (
    <div className="animate-pulse">
      {/* Header */}
      <div className="p-2 border-b">
        <div className="h-8 bg-gray-200 rounded w-48 mb-4"></div>
      </div>
  
      {/* Door Items Section */}
      <div className="p-4">
        {/* Table Header */}
        <div className="grid grid-cols-24 gap-2 px-2 py-1 bg-gray-50 rounded-t-lg mb-2">
          <div className="col-span-10 h-5 bg-gray-200 rounded"></div>
          <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
          <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
          <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
          <div className="col-span-5 h-5 bg-gray-200 rounded"></div>
        </div>
  
        {/* Door Items */}
        {[1, 2].map((item) => (
          <div key={item} className="mb-2">
            <div className={`grid grid-cols-24 gap-2 p-2 ${item % 2 === 0 ? 'bg-white' : 'bg-blue-50'}`}>
              <div className="col-span-10 h-5 bg-gray-200 rounded"></div>
              <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
              <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
              <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
              <div className="col-span-5 h-5 bg-gray-200 rounded"></div>
            </div>
          </div>
        ))}
  
        {/* Hardware Section */}
        <div className="mt-8">
          <div className="h-6 bg-gray-200 rounded w-36 mb-4"></div>
          
          {/* Hardware Table Header */}
          <div className="grid grid-cols-24 gap-2 px-2 py-1 bg-gray-50 rounded-t-lg mb-2">
            <div className="col-span-10 h-5 bg-gray-200 rounded"></div>
            <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
            <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
            <div className="col-span-8 h-5 bg-gray-200 rounded"></div>
          </div>
  
          {/* Hardware Items */}
          {[1, 2].map((item) => (
            <div key={item} className="mb-2">
              <div className={`grid grid-cols-24 gap-2 p-2 ${item % 2 === 0 ? 'bg-white' : 'bg-blue-50'}`}>
                <div className="col-span-10 h-5 bg-gray-200 rounded"></div>
                <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
                <div className="col-span-3 h-5 bg-gray-200 rounded"></div>
                <div className="col-span-8 h-5 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}
        </div>
  
        {/* Total Section */}
        <div className="mt-8 p-4 border-t bg-gray-50 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <div className="h-6 bg-gray-200 rounded w-32"></div>
            <div className="h-8 bg-gray-200 rounded w-40"></div>
          </div>
          <div className="flex justify-between items-center">
            <div className="h-6 bg-gray-200 rounded w-24"></div>
            <div className="flex gap-4">
              <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
              <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  

  return (
    
        <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto pt-16 pb-20">
          <div className="bg-white rounded-xl shadow-lg max-w-4xl mx-auto">
            <div className=" p-2 border-b">
              <h2 className="text-2xl font-bold text-gray-800">Quotation Summary</h2>
            </div>
      
            <div className="">
              {isLoading ? (
                <SkeletonLoader />
              ) : (
                <div className="">
                  {/* Header */}
                  <div className="grid grid-cols-5 gap-6 px-2 py-1 bg-gray-100 rounded-t-lg font-semibold text-gray-700">
  <div className='text-center'>Type</div>
  <div className="text-center">No</div>
  <div className="text-center">Area <span className="text-xs">(Sq.Ft)</span></div>
<div className="text-center">Price <span className="text-xs">(Sq.Ft)</span></div>
  <div className="text-center">Total</div>
</div>

{/* Door Items */}
{Array.from(new Set(quotationData.items.map(item => item.area_types.area_name))).map((roomName, index) => {
  const doorItem = quotationData.items.find(item => 
    item.area_types.area_name === roomName && 
    item.UOM === "Sq.Ft"
  );
  const roomTotal = calculateRoomTotal(roomName);

  return (
<div key={index} className="border-b last:border-b-0">
  <div className={`grid grid-cols-5 gap-2 p-2 text-sm items-center ${index % 2 === 0 ? 'bg-white' : 'bg-blue-50'} `}>
    <div className="font-medium text-gray-800 text-center">{doorItem?.item_name}</div>
    <div className="text-center">{doorItem?.area_types?.count}</div>
    <div className="text-center">{doorItem?.area_types?.area}</div>
    <div className="text-center">₹{doorItem?.selling_price}</div>
    <div className="font-bold text-blue-600 break-words overflow-hidden text-center">
  ₹{Math.round(roomTotal).toLocaleString('en-IN')}
</div>

  </div>


      {/* Applied products row */}
      {/* {doorItem?.required_order_breakup?.length > 0 && (
  <div className="p-2 border-t border-gray-200">
    <div className="flex flex-col gap-1 text-sm text-gray-600">
      {doorItem.required_order_breakup.map((product, idx) => (
        <div key={idx} className="ml-2 flex justify-between items-center">
          <span>• {product.sub_item_name}</span>
          <span className={`font-medium ${product.price === 0 ? 'text-orange-600' : 'text-blue-600'}`}>
            {product.price === 0 ? 'Included' : `₹${product.price}`}
          </span>
        </div>
      ))}
    </div>
  </div>
)} */}


    </div>
  );
})}
      
                  {/* Hardware Summary */}
                  <div className="mt-6">
  <h3 className="font-semibold text-lg text-gray-800 p-2">Door Hardware</h3>
  
  {/* Hardware Table Header */}
  <div className="grid grid-cols-4 gap-6 px-2 py-1 bg-gray-50 rounded-t-lg font-semibold text-gray-700">
    <div className='text-center'>Type</div>
    <div className="text-center">No</div>
    {/* <div className="text-center">Unit</div> */}
    <div className="text-center">Price <span className="text-xs">(No)</span></div>
    <div className="text-center">Total</div>
  </div>

  {/* Hardware Items */}
  {getHardwareBreakup().map((hardware, index) => (
    <div key={index} className="border-b last:border-b-0">
    <div className={`grid grid-cols-4 gap-2 p-2 text-sm items-center ${index % 2 === 0 ? 'bg-white' : 'bg-blue-50'} `}>
      <div className="font-medium text-gray-800 text-center">{hardware.name}</div>
      <div className="text-center">{hardware.quantity}</div>
      <div className="text-center">₹{hardware.price}</div>
      <div className="font-bold text-blue-600 break-words overflow-hidden text-center">
        ₹{hardware.total.toLocaleString('en-IN')}
      </div>
    </div>
  </div>  
  ))}
</div>
      
                  {/* Total Section */}
                  <div className="mt-6 p-4 border-t bg-gray-50 rounded-lg">
                    <div className="flex justify-between items-center mb-4">
                      <span className="text-xl font-bold">Total Amount:</span>
                      <span className="text-2xl font-bold text-blue-600">
                        ₹{quotationData.total.toLocaleString('en-IN')}
                      </span>
                    </div>
      
                    <div className="flex gap-4 justify-between items-center">
                      <button
                        onClick={() => handleDeleteQuotation(quotationId)}
                        className="px-2 py-1 font-semibold text-lg underline text-blue-600"
                      >
                        <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" /> Back
                      </button>
      
                      <div className="flex gap-4">
                        <button className="p-2 rounded-full bg-blue-50 hover:bg-blue-100 text-blue-600">
                          <FontAwesomeIcon icon={faShare} className="w-5 h-5" />
                        </button>
                        <button className="p-2 rounded-full bg-green-50 hover:bg-green-100 text-green-600">
                          <FontAwesomeIcon icon={faPhone} className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
      
  
};

export default DoorQuotationSummary;