import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { jwtDecode } from "jwt-decode";


const DoorSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectMeasurements, customerId, categoryId, projectId } = location.state;
  const [expandedSections, setExpandedSections] = useState({});
  const [showCustomizationPopup, setShowCustomizationPopup] = useState(false);
const [selectedDoorDetails, setSelectedDoorDetails] = useState(null);
const [showSelectedDoor, setShowSelectedDoor] = useState(false);
const [Products,setProducts] = useState([]);
const [UOMS , setUOMS] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const searchParams = new URLSearchParams(location.search);
// const projectId = searchParams.get('project');
// const categoryId = searchParams.get('category');
// const customerId = searchParams.get('customer');
const urlToken = searchParams.get('token');
const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const [DoorHardware, setDoorHardware] = useState([]);
    const [ProjectMeasurements, setProjectMeasurements]= useState();
    const [currentProjectMeasurements, setCurrentProjectMeasurements] = useState(projectMeasurements);
    const [isGenerating, setIsGenerating] = useState(false);


    useEffect(() => {
        if (urlToken) {
          try {
            // Decode the token
            const decodedToken = jwtDecode(urlToken);

            // Set session data
            sessionStorage.setItem('token', urlToken);
            sessionStorage.setItem('userId', decodedToken.userId);
            sessionStorage.setItem('username', decodedToken.username);
            sessionStorage.setItem('roleId', decodedToken.roleId);
            sessionStorage.setItem('organizationId', decodedToken.organizationId);
            
          } catch (error) {
            console.error('Error decoding token:', error);
            // Handle error (e.g., redirect to error page)
          }
        }
      }, [urlToken, searchParams]);

      useEffect(() => {
       // window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);

  const toggleSection = (categoryName) => {
    setExpandedSections(prev => ({
      ...prev,
      [categoryName]: !prev[categoryName]
    }));
  };

  const handleCustomization = async (category) => {
    await handleGetProductsOfItemGroup(category.selectedDoor._id);
    await fetchDoorsHardWareGroupsAndProducts(category.selectedDoor._id);
    setSelectedDoorDetails({
      ...category.selectedDoor,
      categoryDetails: category
    });
    setShowCustomizationPopup(true);
  };
  
  const handleClosePopup = () => {
    setShowSelectedDoor(false);
    setSelectedDoorDetails(null);
  };



  const handleGetProductsOfItemGroup = async (selectItemId) => {
    const tokn = urlToken;
    try {
      const response = await fetch(fetchUrl2 + `product/get-door-category-products-by-product-group-along-with-flush-door-group-products?product_group_id=${selectItemId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': tokn,
        },
      });
      const data = await response.json();
      //console.log(data)
      const output = await handleGetResponse(response, data);
      if(output) {
        setProducts(output);
        const selectedProducts = output.filter(p=>p.selling_price === 0);
        return selectedProducts;
      }else{
        setProducts([]);
      }
    }catch (error) {
      console.error('Error fetching products:', error);
      return [];
    }
  }

  const fetchDoorsHardWareGroupsAndProducts = async (doorId) => {
    const tokn = sessionStorage.getItem('token');
      try {
        const response = await fetch(fetchUrl2 + `product-groups/get-door-hardware-groups-and-products?product_group_id=${doorId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': tokn,
          },
        });
        const data = await response.json();
        
        const output = await handleGetResponse(response, data);
        if(output) {
          setDoorHardware(output);
          return output;
        }else{
          setDoorHardware([]);
          return [];
        }
      
       
      } catch (error) {
        console.error('Error fetching product groups:', error);
      }
    };


  const handleAddProduct = (product) => {
    if (!product || typeof product !== 'object') {
      console.error('Invalid product object');
      return;
    }
  
    const groupId = product.item_sub_category_id;
  
    setSelectedDoorDetails(prevDetails => {
      if (!prevDetails || !prevDetails.categoryDetails) {
        console.error('Invalid selectedDoorDetails structure');
        return prevDetails;
      }
  
      const currentProducts = Array.isArray(prevDetails.categoryDetails.selectedProducts) 
        ? prevDetails.categoryDetails.selectedProducts 
        : [];
  
      const filteredProducts = currentProducts.filter(p => p && p.item_sub_category_id !== groupId);
      const updatedProducts = [...filteredProducts, { ...product, quantity: 1 }];
  
      return {
        ...prevDetails,
        categoryDetails: {
          ...prevDetails.categoryDetails,
          selectedProducts: updatedProducts
        }
      };
    });
  };
  
  const handleAddDoorHardware = (product) => {
    setSelectedDoorDetails(prevDetails => {
      const currentHardware = prevDetails.categoryDetails.selectedHardware || [];
      
      // Remove any existing product from the same group
      const filteredHardware = currentHardware.filter(
        item => item.product_sub_category_id !== product.product_sub_category_id
      );
  
      // Add the new product
      const updatedHardware = [
        ...filteredHardware,
        { ...product, quantity: prevDetails.categoryDetails.MainAreaCount || 1 }
      ];
  
      return {
        ...prevDetails,
        categoryDetails: {
          ...prevDetails.categoryDetails,
          selectedHardware: updatedHardware
        }
      };
    });
  };
  
  
  const handleRemoveDoorHardware = (product) => {
  /*   setSelectedDoorDetails(prevDetails => ({
      ...prevDetails,
      categoryDetails: {
        ...prevDetails.categoryDetails,
        selectedHardware: prevDetails.categoryDetails.selectedHardware.filter(
          item => item._id !== product._id
        )
      }
    })); */
  };

  const calculateSelectedDoorPrice = () => {
    const doorPrice = selectedDoorDetails?.selling_price || 0;
    const area = selectedDoorDetails?.categoryDetails?.MainAreaValue || 0;
    const addOnsPrice = selectedDoorDetails?.categoryDetails?.selectedProducts?.reduce((total, product) => {
      return total + (parseFloat(product.selling_price) || 0);
    }, 0) || 0;
    return Math.round((doorPrice + addOnsPrice) * area);
  };
  
  const calculateHardwarePrice = () => {
    const mainAreaCount = selectedDoorDetails?.categoryDetails?.MainAreaCount || 1;
    return Math.round(selectedDoorDetails?.categoryDetails?.selectedHardware?.reduce((total, hardware) => {
      return total + ((parseFloat(hardware.selling_price) || 0) * mainAreaCount);
    }, 0) || 0);
  };
  
  const calculateTotalPrice = () => {
    const doorPrice = calculateSelectedDoorPrice();
    const hardwarePrice = calculateHardwarePrice();
    return Math.round(doorPrice + hardwarePrice);
  };

  const handleTotalPriceInfo = () => {
   // setShowPriceBreakdown(true);
  };

  const calculateDoorPrice = (addOns, groupPrice, area) => {
    let mainDoorPrice = parseFloat(groupPrice) || 0;
  
    if (Array.isArray(addOns) && addOns.length > 0) {
      const addOnsPrice = addOns.reduce((total, addOn) => {
        const price = parseFloat(addOn.selling_price) || 0;
        return total + price;
      }, 0);
      mainDoorPrice += addOnsPrice;
    }
  
    const totalArea = parseFloat(area) || 1;
    const finalPrice = mainDoorPrice * totalArea;
    
    return Math.ceil(finalPrice);
  };

  const handleApplyProductsAndGroupToTheAreaType = () => {
    const doorPrice = calculateDoorPrice(
      selectedDoorDetails?.categoryDetails?.selectedProducts,
      selectedDoorDetails?.selling_price,
      selectedDoorDetails?.categoryDetails?.MainAreaValue
    );
    const hardwarePrice = calculateHardwarePrice();
    const totalPrice = calculateTotalPrice();
  
    const updatedProjectMeasurements = {
      ...currentProjectMeasurements,
      projectMeasurements: currentProjectMeasurements.projectMeasurements.map(category => {
        if (category.name === selectedDoorDetails?.categoryDetails?.name) {
          return {
            ...category,
            selectedDoor: selectedDoorDetails,
            selectedProducts: selectedDoorDetails?.categoryDetails?.selectedProducts,
            selectedHardware: selectedDoorDetails?.categoryDetails?.selectedHardware,
            doorPrice,
            hardwarePrice,
            totalPrice,
            IsDoorApplied: true
          };
        }
        return category;
      })
    };

    setExpandedSections(prev => ({
        ...prev,
        [selectedDoorDetails?.categoryDetails?.name]: true
      }));
  
    setCurrentProjectMeasurements(updatedProjectMeasurements);
    setShowCustomizationPopup(false);
  };

  const calculateDoorPricePerSquareFeet = (addOns, groupPrice) => {
    let mainDoorPrice = parseFloat(groupPrice) || 0;
  
    if (Array.isArray(addOns) && addOns.length > 0) {
      const addOnsPrice = addOns.reduce((total, addOn) => {
        const price = parseFloat(addOn.selling_price) || 0;
        return total + price;
      }, 0);
      mainDoorPrice += addOnsPrice;
    }
    
    return Math.ceil(mainDoorPrice);
  };


  
  useEffect(() => {
    getUoms();
 }, []);


  const getUoms =  async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await fetch(fetchUrl2 + `UOMs/get-all-UOMs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);
      //console.log(data)
      if(output) {
        setUOMS(output);
      }else{
        setUOMS([]);
      }

    } catch (error) {
      console.error('Error fetching UOMs:', error);
    }
  };

  const generateQuotation = () => {
    const organizationId = sessionStorage.getItem('organizationId');
    let items = [];
    let subtotal = 0;
  
    currentProjectMeasurements.projectMeasurements.forEach(category => {
      // Calculate door price with selected products
      const doorPricePerSqFt = calculateDoorPricePerSquareFeet(
        category.selectedProducts, 
        category.selectedDoor.selling_price
      );

      const doorItem = {
        item_id: category.selectedDoor._id,
        item_name: category.selectedDoor.product_group_name,
        quantity: category.MainAreaValue,
        selling_price: doorPricePerSqFt,
        total: doorPricePerSqFt * category.MainAreaValue,
        UOM: "Sq.Ft",
        UOM_id: category.selectedDoor.UOM_id,
        tax_type: "GST",
        required_order_breakup: category.selectedProducts.map(product => ({
          sub_item_name: product.item_name,
          sub_item_id: product._id,
          sub_item_quantity: category.MainAreaValue,
          price: product.selling_price,
          item_sub_category_id: product.item_sub_category_id,
          item_sub_category_name: product.item_sub_category_name,
          item_image: product.item_image
        })),
        area_types: {
          area_name: category.name,
          area: category.MainAreaValue.toString(),
          count: category.MainAreaCount.toString()
        }
      };
      
      items.push(doorItem);
      subtotal += doorItem.total;

      // Add hardware items
      category.selectedHardware.forEach(hardware => {
        const hardwareItem = {
          item_id: hardware._id,
          item_name: hardware.product_name,
          quantity: category.MainAreaCount,
          selling_price: hardware.selling_price,
          total: hardware.selling_price * category.MainAreaCount,
          UOM: "No",
          UOM_id: hardware.UOM_id,
          tax_type: "GST",
          required_order_breakup: [],
          area_types: {
            area_name: category.name,
            count: category.MainAreaCount.toString()
          }
        };
        
        items.push(hardwareItem);
        subtotal += hardwareItem.total;
      });
    });
  
    const taxAmount = subtotal * 0.18;
  
    return {
      quotation_date: new Date(),
      expiry_date: new Date(new Date().setDate(new Date().getDate() + 30)),
      status: "Draft",
      quotation_type: "Group",
      customer_id: customerId,
      project_id: projectId,
      product_category_id: categoryId,
      tax_prefrence: "Taxable",
      organizationId,
      items,
      subtotal,
      tax_amount: taxAmount,
      total: subtotal + taxAmount,
      files: []
    };
};



  
  
  
  const saveDoorQuotation = async () => {
    setIsGenerating(true);

    await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      const quotation = generateQuotation();
      const tokn = sessionStorage.getItem('token') || '';
      const organizationId = sessionStorage.getItem('organizationId') || '';
      const response = await fetch(fetchUrl2 + `customer/quotations/save-quotation?organizationId=${organizationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': tokn,
        },
        body: JSON.stringify(quotation),
      });
  
      const data = await response.json();
      const output = await handleSaveResponse(response, data);
      console.log(data)
  
      if (output) {
        setShowPopup(true);
        setPopupType('success');
        setPopupMessage('Quotation saved successfully');
        setTimeout(() => {
          //setShowPopup(false);
        //  setShowQuotationPopup(true);
          //navigate('/consumer/app');
        }, 500);
        navigate('/consumer/app/doorquotationsummary', {
          state: {
            quotationData: output,
            projectMeasurements: currentProjectMeasurements,
            customerId,
            projectId,
            categoryId,
            username: sessionStorage.getItem('username'),
            quotationId: data.data._id
          }
        });
        
      } else {
        throw new Error('Failed to save quotation');
      }
    } catch (error) {
      console.error('Error saving quotation:', error);
      setShowPopup(true);
      setPopupType('error');
      setPopupMessage('An error occurred while saving the quotation');
      setRetryFunction(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      });
    } finally {
      setIsGenerating(false);
    }
  };
  

  const SelectionLoader = () => (
    <div className="bg-white rounded-xl shadow-lg animate-pulse">
      <div className="p-4 border-b">
        <div className="h-8 bg-gray-200 rounded w-1/3"></div>
      </div>
      
      <div className="p-4">
        {[1, 2, 3].map((item) => (
          <div key={item} className="mb-4 bg-gray-100 rounded-lg border border-gray-200">
            <div className="p-4">
              <div className="flex justify-between items-center">
                <div className="w-1/4">
                  <div className="h-6 bg-gray-200 rounded mb-2"></div>
                  <div className="h-5 bg-gray-200 rounded w-3/4"></div>
                </div>
              </div>
              
              <div className="mt-4 grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                  <div className="h-5 bg-gray-200 rounded w-16"></div>
                  <div className="h-5 bg-gray-200 rounded w-8"></div>
                </div>
                <div className="flex items-center">
                  <div className="h-5 bg-gray-200 rounded w-24"></div>
                </div>
                <div className="col-span-2 flex justify-between items-center">
                  <div className="h-6 bg-gray-200 rounded w-24"></div>
                  <div className="h-6 bg-gray-200 rounded w-20"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
  
      <div className="mt-8 p-2 flex justify-center items-center">
        <div className="h-10 bg-gray-200 rounded w-40"></div>
      </div>
    </div>
  );

  return (
    <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      <div className="bg-white rounded-xl shadow-lg">
      {isLoading ? (
                <SelectionLoader />
              ) : (
                <>
        <div className="p-4 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Selection Summary</h2>
        </div>
        
        <div className="p-4">
        {currentProjectMeasurements?.projectMeasurements?.map((category) => (
        <div key={category.name} className="mb-2 bg-gray-100 rounded-lg border border-gray-200">
        <div className="p-4 cursor-pointer w-full" >
          <div className="flex justify-between items-center w-full">
            <div className="w-full">
              <div className="flex items-center ">
                <h2 className="text-lg font-bold">{category.name}</h2>
              </div>
              <div className="flex items-center justify-between" onClick={() => toggleSection(category.name)}>
                <p className="font-semibold text-lg">{category.selectedDoor?.product_group_name}</p>
                {expandedSections[category.name] ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              <div className="flex flex-col text-sm">
  <div className="mt-2 grid grid-cols-2 gap-2">
    <div className="flex items-center gap-2">
      <span className="text-md">Doors:</span>
      <span className="font-semibold text-md">{category.MainAreaCount}</span>
    </div>
    <div className="flex items-center flex-col">
     <p className='text-md'>Area:
      <span className="font-semibold">{category.MainAreaValue} sq.ft</span></p>
    </div>
    <div className="col-span-2 flex justify-between items-center">
    <span className="font-semibold text-[#2980B9] text-lg">
        ₹{category.selectedDoor?.selling_price}/sq.ft
      </span>
      <button
        onClick={() => handleCustomization(category)}
        className="text-[#E8021D] font-semibold text-md"
      >
       + Customize
      </button>
      
    </div>
  </div>
</div>


            </div>
            
           
          </div>
        </div>
    
      
          
          


        {expandedSections[category.name] && (
  <div className="border-t border-gray-200 bg-white p-4">
    <div className="space-y-3">
      <div className="flex justify-between text-sm">
        <span className="text-gray-600">{category.selectedDoor?.product_group_name}</span>
        <span>₹{category.selectedDoor?.selling_price} / sq.ft</span>
      </div>

      {/* Selected Products/Customizations */}
      {category.selectedProducts?.some(product => product.selling_price > 0) && (
  <p className="font-medium">Customizations</p>
)}
{category.selectedProducts?.map((product, idx) => (
  <div key={idx} className="flex items-center justify-between py-3 text-sm border-b border-gray-100 last:border-0">
    <div className="flex items-center gap-6 flex-1">
      <span className="text-gray-600 w-[150px]">{product.item_name}</span>
      <div className="w-12 h-12 rounded-md overflow-hidden bg-gray-50 p-1 flex-shrink-0">
        <img
          src={ImageUrl + product.item_image}
          alt={product.item_name}
          className="w-full h-full object-contain"
        />
      </div>
    </div>
    <div className="text-right w-24">
      <span className={`font-medium ${product.selling_price === 0 ? 'text-orange-600' : ''}`}>
        {product.selling_price === 0 ? 'Included' : `₹${product.selling_price}`}
      </span>
    </div>
  </div>
))}



      {/* Door Price Calculation */}
      {/* <div className="pt-2 border-t">
        <div className="flex justify-between text-sm">
          <span className="text-gray-600">Door Price per sq.ft</span>
          <span>₹{calculateDoorPricePerSquareFeet(category.selectedProducts, category.selectedDoor?.selling_price)}</span>
        </div>
        <div className="flex justify-between text-sm">
          <span className="text-gray-600">Total Door Price ({category.MainAreaValue} sq.ft)</span>
          <span className="text-[#2980B9]">
            ₹{calculateDoorPrice(category.selectedProducts, category.selectedDoor?.selling_price, category.MainAreaValue)}
          </span>
        </div>
      </div> */}

      {/* Hardware Section */}
      {category.selectedHardware?.length > 0 && (
        <div className="pt-2 border-t">
           <p className="font-medium mb-2">Door Hardware</p>
          {category.selectedHardware.map((hardware, idx) => (
            <div key={idx} className="flex items-center justify-between py-2 text-sm border-b border-gray-100 last:border-0">            
                <div className="flex items-center gap-6 flex-1">
                    <span className="text-gray-600 min-w-[150px]">{hardware.product_name}</span>
                        <div className="w-12 h-12 rounded-md overflow-hidden bg-gray-50 p-1 flex-shrink-0">
                            <img src={ImageUrl + hardware.product_image} alt={hardware.product_name} className="w-full h-full object-cover" />
                        </div>
                </div>
                <div className="text-right w-24">
                    <span>₹{hardware.selling_price}</span>
                {/* <span className="block text-[#2980B9]">
                  ₹{hardware.selling_price * category.MainAreaCount}
                </span> */}
                </div>
            </div>
          ))}
          {/* <div className="flex justify-between text-sm font-medium mt-2">
            <span>Total Hardware</span>
            <span className="text-[#2980B9]">
              ₹{category.selectedHardware.reduce((sum, hw) => sum + (hw.selling_price * category.MainAreaCount), 0)}
            </span>
          </div> */}
        </div>
      )}

      {/* Final Total */}
     {/*  <div className="pt-2 border-t">
        <div className="flex justify-between font-semibold text-lg">
          <span>Grand Total</span>
          <span className="text-[#2980B9]">
            ₹{(
              calculateDoorPrice(category.selectedProducts, category.selectedDoor?.selling_price, category.MainAreaValue) +
              (category.selectedHardware?.reduce((sum, hw) => sum + (hw.selling_price * category.MainAreaCount), 0) || 0)
            ).toLocaleString('en-IN')}
          </span>
        </div>
      </div> */}
    </div>
  </div>
)}
            </div>
          ))}
        </div>

        <div className="mt-8 p-2 flex justify-center items-center">
         {/*  <button
            onClick={() => navigate(-1)}
            className="px-2 py-1 font-semibold"
          >
            Back
          </button> */}
           {isGenerating && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-xl shadow-2xl">
          <div className="flex flex-col items-center gap-4">
            <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            <span className="text-lg font-semibold">Generating Quote...</span>
          </div>
        </div>
      </div>
    )}
          <button
            className="px-6 py-2 bg-[#E74C3C] text-white rounded-lg font-semibold hover:bg-[##E74C3C]"
          onClick={saveDoorQuotation}
          >
            Confirm Selections
          </button>
        </div>
        </>
              )}
      </div>

      {/* customization popup */}

      {showCustomizationPopup && selectedDoorDetails && (
  <div className="fixed inset-0 bg-[#F0F4F8] bg-opacity-95 overflow-hidden h-full w-full z-50 flex flex-col">
    <div className="relative flex-grow overflow-y-auto p-4">
      <div className="flex gap-6 items-center mb-4">
        <button onClick={handleClosePopup} className="text-[#E74C3C]">  
          <FontAwesomeIcon icon={faArrowLeft} className="text-xl" />
        </button>
        <h2 className="text-2xl font-bold text-[#2C3E50]">{selectedDoorDetails?.categoryDetails?.name || 'Door'} </h2>
      </div>

      <div className="mt-4">
        {(() => {
          const matchingProducts = Products.filter(p => p?.item_sub_category_id === selectedDoorDetails?.product_sub_category_id._id);
          const nonMatchingProducts = Products.filter(p => p?.item_sub_category_id !== selectedDoorDetails?.product_sub_category_id._id);
          return (
            <>
              {matchingProducts.length > 0 && (
                <div className="mb-6">
                  <h4 className="text-lg font-bold text-[#2C3E50] mb-3">{selectedDoorDetails?.product_sub_category_id.product_sub_category_name}</h4>
                  <div className="overflow-x-auto">
                    <div className="flex gap-4 pb-4">
                      {matchingProducts.map((product) => (
                        <div key={product._id} className={`flex-shrink-0 w-64 bg-white rounded-lg shadow-md overflow-hidden ${
                          selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                            ? 'border border-[#BDC3C7]'
                            : 'border border-[#BDC3C7]'
                        }`}>
                          <div className="h-24 overflow-hidden">
                            <img src={ImageUrl + product.item_image} alt={product.item_name} className="w-full h-full object-cover" />
                          </div>
                          <div className="p-3">
                            <h4 className="text-md font-semibold text-[#2C3E50] mb-1">{product.item_name}</h4>
                            <p className="text-md text-[#7F8C8D]">
                              {product.selling_price === 0 ? (
                                <span className="text-[#E67E22] text-md font-semibold">Included</span>
                              ) : (
                                <span className="text-[#3498DB] font-semibold">+₹{product.selling_price}</span>
                              )}
                            </p>
                            <button
                              className={`mt-2 px-2 py-1 rounded text-sm font-bold w-full ${
                                selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                                  ? 'bg-[#4CAF50] text-white'
                                  : 'bg-[#ECF0F1] text-[#2C3E50]'
                              }`}
                              onClick={() => handleAddProduct(product)}
                            >
                              {selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id) ? 'Selected' : 'Select'}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {Object.entries(
                nonMatchingProducts.reduce((acc, product) => {
                  const subCategoryId = product.item_sub_category_id;
                  if (!acc[subCategoryId]) {
                    acc[subCategoryId] = [];
                  }
                  acc[subCategoryId].push(product);
                  return acc;
                }, {})
              ).map(([subCategoryId, subCategoryProducts]) => (
                <div key={subCategoryId} className="mb-6">
                  <h4 className="text-lg font-bold text-[#2C3E50] mb-3">
                    {subCategoryProducts[0]?.item_sub_category_name}
                  </h4>
                  <div className="overflow-x-auto">
                    <div className="flex gap-4 pb-4">
                      {subCategoryProducts.map((product) => (
                        <div key={product._id} className={`flex-shrink-0 w-64 bg-white rounded-lg shadow-md overflow-hidden ${
                          selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                            ? 'border border-[#BDC3C7]'
                            : 'border border-[#BDC3C7]'
                        }`}>
                          <div className="w-full h-32 relative">
                            <img src={ImageUrl + product.item_image} alt={product.item_name} className="w-full h-full object-cover" />
                          </div>
                          <div className="p-3">
                            <h4 className="text-md font-semibold text-[#2C3E50] mb-1">{product.item_name}</h4>
                            <p className="text-md text-[#7F8C8D]">
                              {product.selling_price === 0 ? (
                                <span className="text-[#E67E22] text-md font-medium">Included</span>
                              ) : (
                                <span className="text-[#3498DB] font-semibold">+₹{product.selling_price}</span>
                              )}
                            </p>
                            <button
                              className={`mt-2 px-4 py-1 rounded text-xs font-bold w-full ${
                                selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id)
                                  ? 'bg-[#4CAF50] text-white'
                                  : 'bg-[#ECF0F1] text-[#2C3E50]'
                              }`}
                              onClick={() => handleAddProduct(product)}
                            >
                              {selectedDoorDetails?.categoryDetails?.selectedProducts?.some(p => p?.item_id === product?.item_id) ? 'Selected' : 'Select'}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </>
          );
        })()}
      </div>

      {/* Hardware section with horizontal scroll */}
      <div className="relative flex-grow overflow-y-auto p-4 pb-48"> {/* Added pb-48 for bottom padding */}

      {DoorHardware.length > 0 && (
  <>
    <h2 className="text-2xl font-bold mb-4 text-[#2C3E50]">Door Hardware</h2>
    {DoorHardware.map((hardwareGroup) => (
      <div key={hardwareGroup._id} className="">
        <h3 className="text-lg font-bold mb-3 text-[#34495E]">{hardwareGroup.product_sub_category_name}</h3>
        <div className="overflow-x-auto">
          <div className="flex gap-4 pb-4">
            {hardwareGroup.products.map((hardware) => {
              const isSelected = selectedDoorDetails?.categoryDetails?.selectedHardware?.some(
                h => h._id === hardware._id
              );
              return (
                <div key={hardware._id} className={`flex-shrink-0 w-64 bg-white rounded-lg shadow-md overflow-hidden ${
                  isSelected ? 'border border-[#BDC3C7]' : 'border border-[#BDC3C7]'
                }`}>
                   <div className="w-full h-32 relative">
                <img 
                  src={ImageUrl + hardware.product_image} 
                  alt={hardware.product_name} 
                  className="absolute inset-0 w-full h-full object-contain p-2"
                />
              </div>
                  <div className="p-3">
                    <h4 className="text-md font-semibold text-[#2C3E50] mb-1">{hardware.product_name}</h4>
                    <p className="text-md text-[#3498DB]">₹{hardware.selling_price}</p>
                    <button
                      className={`mt-2 px-4 py-1 rounded text-md font-bold w-full ${
                        isSelected ? 'bg-[#4CAF50] text-white' : 'bg-[#ECF0F1] text-[#2C3E50]'
                      }`}
                      onClick={() => isSelected ? handleRemoveDoorHardware(hardware) : handleAddDoorHardware(hardware)}
                    >
                      {isSelected ? 'Selected' : 'Select'}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ))}
  </>
)}
</div>


<div className="fixed bottom-0 left-0 right-0 bg-white border-t border-[#BDC3C7] px-4 py-4">
  <div className="flex justify-between items-center mb-2">
    <span className="text-sm font-semibold text-[#34495E]">Doors Price:</span>
    <span className="text-sm font-semibold text-[#2980B9]">₹ {calculateSelectedDoorPrice()}</span>
  </div>
  <div className="flex justify-between items-center mb-2">
    <span className="text-sm font-semibold text-[#34495E]">Hardware Price ({selectedDoorDetails?.categoryDetails?.MainAreaCount || 0} Doors):</span>
    <span className="text-sm font-semibold text-[#2980B9]">₹ {calculateHardwarePrice()}</span>
  </div>
 {/*  <div className="flex justify-between items-center mb-4">
    <span className="text-lg font-semibold text-[#2C3E50]">Total Price:</span>
    <div className="flex items-center">
      <span className="text-lg font-bold text-[#2980B9] mr-2">₹ {calculateTotalPrice()}</span>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="text-[#3498DB] cursor-pointer"
        onClick={() => handleTotalPriceInfo()}
      />
    </div>
  </div> */}

  <div className="flex justify-between items-center">
    <span className="text-md font-semibold text-[#2C3E50]">Apply to {selectedDoorDetails?.categoryDetails?.name || 'Door'}:</span>
    <button
      className="bg-[#3498DB] text-white py-2 px-6 rounded-lg font-semibold hover:bg-[#2980B9] transition-colors duration-200"
      onClick={() => handleApplyProductsAndGroupToTheAreaType()}
    >
      Apply
    </button>
  </div>
</div>

    </div>
  </div>
)}
   </div>
  );
};

export default DoorSummary;
