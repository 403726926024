import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faTrash, faX , faExclamationTriangle , faArrowRightLong  } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader ,Spinner } from '../Loader/Loader';
import TableSearchDropDown from '../Components/tableSerchDropDown';
import TableSearchForAreaName from '../Components/TabelSerchForAreaName'
import  '../Css/Tooltip.css';
import {useLocation, useNavigate } from 'react-router-dom';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const EditInvoice = () => {

 
const navigate = useNavigate()
const dropdownRef = useRef(null); // Define the dropdownRef variable
  const [selectedQuoteType, setSelectedQuoteType] = useState(); // Initial value
 
  const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit
 
  const [DefaultProductRatesOfPriceList,setDefaultProductRatesOfPriceList]= useState()
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPricelists, setRowPricelists] = useState({});
  const [rows, setRows] = useState([{ item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',},  quantity: '', UOM: '', price: '', tax: '', tax_type:'', amount: '', sub_items:[]}]);
  const [products, setProducts] = useState([]); // assume products is set to one of the arrays you provided
  const [amounts, setAmounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [amendedDiscount, setAmendedDiscount] = useState();
  const [amendedPrice, setAmendedPrice] = useState();
  const [allRowsSelectedPriceList, setAllRowsSelectedPriceList] = useState([]);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [selectedApplicationAreas, setSelectedApplicationAreas] = useState({});
    const [roleId, setRoleId] = useState(null);
const [filteredProducts, setFilteredProducts] = useState([]);
const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

const toggleDropdown = (index) => {
  setOpenDropdownIndex(openDropdownIndex === index ? null : index);
}
const [dropdownOpen, setDropdownOpen] = useState({});
const [selectedProducts, setSelectedProducts] = useState({});
const [dueDate, setDueDate] = useState(new Date());
const [terms, setTerms] = useState('');
const [subject, setSubject] = useState('');
const [customerNotes, setCustomerNotes] = useState('');
const [files, setFiles] = useState([]);
const [isFileDropdownOpen, setIsFileDropdownOpen] = useState(false);
const [shippingCharges, setShippingCharges] = useState('');
const [taxType, setTaxType] = useState('TDS');
const [taxValue, setTaxValue] = useState('');
const [showDropdown, setShowDropdown] = useState(false);
const [invoiceDate, setInvoiceDate] = useState(new Date());
const [orderNumber, setOrderNumber] = useState('');
const [customersLoaded, setCustomersLoaded] = useState(false);

const location = useLocation();

const invoiceId = location.state?.invoiceId;


useEffect(() => {
  fetchAllApprovedCustomers();
 // handleGetAllUoms();
 fetchProducts();
}, [fetchUrl]);

const fetchProducts = async (attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}product/get-all-products?organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    const output = await handleGetResponse(response , data)
console.log(data)
    if (output) {

      const productsWithItemName = output.map((product) => {
        // Match the item_id with the rows and get the corresponding item_name
        const matchedRow = rows.find(row => row.item_id === product.item_id);
        const itemName = matchedRow ? product.item_name : ''; // Map the item_name

        return {
            ...product,
            itemName, // Add itemName directly to the product object
        };
    });
    setProducts(output);
     // setProducts(output); // Update productCategories state
    } else {
      console.error(data.message);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchProducts(attempt + 1), 1000); // Retry after 1 second
    } else {
     
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchProducts(attempt)); // Set retry function
    }
  }/* finally {
    setIsLoading(false);
  } */
};



const fetchAllApprovedCustomers = async (attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(fetchUrl + `customer/get-all-approved-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    const output = await handleGetResponse(response, data);
    if (output) {
      // Format data for dropdown
      const formattedCustomers = output.map(customer => ({
        id: customer._id,  // Use customer ID as value
        label: customer.customer_name, // Use customer name as label
        value: customer.customer_name
      }));

      setCustomers(formattedCustomers); // Update state with formatted data
     // console.log(formattedCustomers);
     setCustomersLoaded(true);
    } else {
      handleFetchError(response, attempt, fetchAllApprovedCustomers);
    }
  } catch (error) {
    handleErrorWithRetry(error, attempt, fetchAllApprovedCustomers);
  }
};

   // Centralized error handling
   const handleFetchError = (response, attempt, retryFunction) => {
    if (response.status === 500) {
      setRetryFunction(() => retryFunction); 
    }
  };
      
      const handleErrorWithRetry = (error, attempt, retryFunction, ...args) => {
        console.error('Failed to fetch:', error);
      
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => retryFunction(...args, attempt + 1), 1000);
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => retryFunction(...args, attempt));
        }
      };

useEffect(() => {
  const fetchInvoiceData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${fetchUrl}invoice/get-invoice?invoice_id=${invoiceId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        const responseData = await response.json();
        const data = responseData.data; // Access the data object from response
        
        // Safely parse dates
        const parsedInvoiceDate = data.invoice_date ? new Date(data.invoice_date) : new Date();
        const parsedDueDate = data.due_date ? new Date(data.due_date) : new Date();
        
        // Format customer data
        const customerData = {
          id: data.customer_id._id,
          label: data.customer_id.customer_name,
          value: data.customer_id._id
        };
  
        // Map items data
        const formattedItems = data.items.map(item => ({
          item_id: item.item_id,
          item_name: item.item_name,
          quantity: item.quantity,
          price: item.selling_price,
          tax: item.tax_rate,
          tax_type: item.tax_type,
          UOM: item.UOM,
          total: item.total,
          amount: item.total
        }));
  
        setSelectedCustomer(customerData);
        setInvoiceNumber(data.invoice_number);
        setOrderNumber(data.order_number || '');
        setInvoiceDate(parsedInvoiceDate);
        setDueDate(parsedDueDate);
        setTerms(data.terms_and_conditions);
        setSubject(data.subject || '');
        setRows(formattedItems);
        setCustomerNotes(data.terms_and_conditions || '');
        setShippingCharges(data.shipping_charges || '');
        setAmendedDiscount(data.discount);
        setAmendedPrice(data.adjustment);
        setTotal(data.total);
        setSubTotal(data.sub_total);
      }
    } catch (error) {
      setPopupMessage('Error fetching invoice data');
      setPopupType('400');
    } finally {
      setIsLoading(false);
    }
  };
  


  if (invoiceId) {
    fetchInvoiceData();
  }
}, [invoiceId]);

const [termsOptions] = useState([
    { label: "Net 15", value: "Net 15" },
    { label: "Net 30", value: "Net 30" },
    { label: "Net 45", value: "Net 45" },
    { label: "Net 60", value: "Net 60" },
    { label: "Due on Receipt", value: "Due on Receipt" },
    { label: "Due end of the month", value: "Due end of the month" },
    { label: "Due end of next month", value: "Due end of next month" },
    { label: "Custom", value: "Custom" }
  ]);
const handleTermsChange = (selectedOption) => {
  setTerms(selectedOption);
};


const handleTaxSelect = (value) => {
  setTaxValue(value);
  setShowDropdown(false);
};


const calculateTaxAmount = (taxRate, price, quantity) => {
  const tax = parseFloat(taxRate) || 0;
  const sellingPricePerQuantity = parseFloat(price) || 0;
  const totalQuantity = parseFloat(quantity) || 0;
  const taxAmount = parseFloat(((tax / 100) * sellingPricePerQuantity) * totalQuantity) || 0;
  return taxAmount;
};
      
   
   
const handleCustomerSelect = (customer) => {
  console.log("Selected Customer:", customer);
  setSelectedCustomer(customer);
};

const handleInvoiceNumberChange = (e) => {
  setInvoiceNumber(e.target.value);
};

const handleOrderNumberChange = (e) => {
  setOrderNumber(e.target.value);
};

const handleDateChange = (date) => {
  setInvoiceDate(date);
};

const handleInputChange = (value, index, field, price) => {
  const updatedRows = [...rows];
  updatedRows[index][field] = value;
  
  // Recalculate total when quantity changes
  if (field === 'quantity') {
    const quantity = parseFloat(value) || 0;
    const itemPrice = parseFloat(updatedRows[index].price) || 0;
    const taxRate = parseFloat(updatedRows[index].tax) || 0;
    
    // Calculate subtotal for this item
    const subtotal = quantity * itemPrice;
    
    // Calculate tax amount
    const taxAmount = (subtotal * taxRate) / 100;
    
    // Set total for this item including tax
    updatedRows[index].total = subtotal + taxAmount;
    updatedRows[index].amount = subtotal + taxAmount;
  }
  
  setRows(updatedRows);
  
  // Recalculate overall totals
  const newSubTotal = updatedRows.reduce((sum, row) => {
    const quantity = parseFloat(row.quantity) || 0;
    const price = parseFloat(row.price) || 0;
    return sum + (quantity * price);
  }, 0);
  
  setSubTotal(newSubTotal);
  
  // Calculate final total including discount and adjustments
  const discountAmount = (newSubTotal * (parseFloat(amendedDiscount) || 0)) / 100;
  const adjustmentAmount = parseFloat(amendedPrice) || 0;
  const shippingAmount = parseFloat(shippingCharges) || 0;
  
  const finalTotal = newSubTotal - discountAmount + adjustmentAmount + shippingAmount;
  setTotal(finalTotal);
};


const Taxes = [
  { value: 'igst', label: 'IGST' },
  { value: 'cgst', label: 'CGST' },
  { value: 'sgst', label: 'SGST' }
];

const addRow = () => {
  setRows([...rows, { item_name: '', quantity: '', UOM: '', price: '', tax: '', tax_type: '', amount: '' }]);
};

const deleteRow = (index, rows) => {
  const updatedRows = rows.filter((_, idx) => idx !== index);
  setRows(updatedRows);
};

const handleSaveInvoiceData = async () => {
  setIsSaving(true);
  try {
    const invoiceData = {
      customer_id: selectedCustomer.id,
      invoice_date: invoiceDate,
      due_date: dueDate,
      balance_due:total,
      items: rows.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name,
        quantity: parseFloat(item.quantity),
        selling_price: parseFloat(item.price),
        tax_rate: parseFloat(item.tax),
        tax_type: item.tax_type,
        total_tax_amount: item.total_tax_amount,
        total: parseFloat(item.price * item.quantity),
        UOM: item.UOM,
        UOM_id: item.UOM_id
      })),
      sub_total: SubTotal,
      tax_amount: calculateTax(),
      total: total,
      discount: parseFloat(amendedDiscount) || 0,
      adjustment: parseFloat(amendedPrice) || 0,
      terms_and_conditions: customerNotes,
      files: files,
      organizationId: sessionStorage?.getItem('organizationId'),
    };

    const response = await fetch(`${fetchUrl}invoice/update-invoice?invoiceId=${invoiceId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(invoiceData)
    });

    const data = await response.json();
    console.log(data)
    if (response.ok) {
      console.log(response)
      setPopupMessage('Invoice updated successfully');
      setPopupType('200or201');
      navigate('/bb/app/sales/invoiceslist');
    }
  } catch (error) {
    setPopupMessage('Error updating invoice');
    setPopupType('400');
  } finally {
    setIsSaving(false);
  }
};
    

    
   
   

  
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  
  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    if (files.length + uploadedFiles.length <= 10) {
      setFiles(prev => [...prev, ...uploadedFiles]);
    }
  };
  

 
  
 
   

 const  [SubTotal,setSubTotal]= useState(0)

useEffect(()=>{
  calculateSubtotal()
  calculateTax()
},[rows])

  const calculateSubtotal = () => {
    let subtotal = 0;
  
    rows.forEach(row => {
      const quantity = parseFloat(row?.quantity) || 0;
      const price = parseFloat(row?.price) || 0;
      const rowTotal = quantity * price;
      subtotal += rowTotal;
    });
   setSubTotal(subtotal)
    return subtotal;
  };
  
  
  const calculateTax = () => {
    let TotalTax = 0;
  
    rows.forEach((p) => {
      const price = parseFloat(p.price) || 0;  
      const taxRate = parseFloat(p.tax) || 0;  
      const quantity = parseFloat(p.quantity) || 0;  
  
      const tax = (taxRate / 100) * price;
      const SubTotalTax = tax * quantity;
  
      TotalTax += SubTotalTax;
    });
  
    return TotalTax;
  };

  

  const handleTaxChange = (index, selectedOption, value, price, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      tax: value,
      tax_type: selectedOption?.label,
    };
    const totaltaxOfproduct = calculateTaxAmount(value, price, quantity);
    updatedRows[index].total_tax_amount = totaltaxOfproduct;
    setRows(updatedRows);
  };
  
  

  const calculateTotalTaxAmount = () => { 
    let totalTaxAmount = 0;
    if (Array.isArray(rows)) {
      rows.forEach((row) => {
        totalTaxAmount += row?.total_tax_amount || 0;
      });
    }
   
    return totalTaxAmount || 0; // Update the total tax amount state
    
  };
  

  

const [invoiceNumber, setInvoiceNumber] = useState('');

// Add this useEffect to generate invoice number on component mount
useEffect(() => {
  const generateInvoiceNumber = () => {
    const prefix = 'INV';
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000);
    return `${prefix}-${timestamp}-${random}`;
  };
  
  setInvoiceNumber(generateInvoiceNumber());
}, []);


  
  
  
  const closePopup = () => {
   // console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
   // console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){
  
        await retryFunction ();
      }
  
      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
   // console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };
  
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }
  
  const handleCancel = () => {
    setShowCancelPopup(true);
  }
  
  const handleLeave = () => {
    navigate(-1);
  }

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
      // Format the number with commas and two decimal places
      return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };
  
  
  // if (isLoading) {
  //   return <Loader />;
  // }




  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Edit Invoice</h1>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Customer Name<span className="text-red-500">*</span>
                        </label>
                        <div className='w-1/3'>
                        <CustomDropdown
        options={customers}
        selected={selectedCustomer}
        onSelect={handleCustomerSelect}
        placeholder='Select Customer'
        showAddNew={false}
        showSearch={true}
      />
      </div>
                      </div>
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="invoice" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Invoice#<span className="text-red-500">*</span> 
                        </label>
                        <input
                        type="text"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                         value={invoiceNumber}
                         onChange={handleInvoiceNumberChange}
                      />
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="order number" className="block text-sm font-regular text-gray-700 w-1/6">
                        Order Number
                      </label>
                      <input
                        type="text"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={orderNumber}
                        onChange={handleOrderNumberChange}
                      />
                    </div> 
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-12">
  <div className="flex flex-row items-center w-1/2">
    <label htmlFor="invoiceDate" className="block text-sm font-regular text-[#e54643] w-1/3">
      Invoice Date<span className="text-red-500">*</span>
    </label>
    <DatePicker
      selected={invoiceDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      className="w-full ml-4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    />
  </div>

  <div className="flex flex-row items-center w-1/4">
    <label htmlFor="terms" className="block text-sm font-regular text-gray-700 w-1/4">
      Terms
    </label>
    <CustomDropdown
      options={termsOptions}
      selected={terms}
      onSelect={handleTermsChange}
      placeholder="Select Terms"
      showSearch={true}
      showAddNew={false}
      className="w-3/4 rounded-md border-2 border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700 border-dotted"
    />
  </div>

<div className="flex flex-row items-center w-1/4 whitespace-nowrap">
    <label htmlFor="dueDate" className="block text-sm font-regular text-gray-700 w-1/4">
      Due Date
    </label>
    <DatePicker
      selected={dueDate}
      onChange={handleDueDateChange}
      dateFormat="dd/MM/yyyy"
      className="w-3/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    />
  </div>
</div> 
                  </div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              </div>
              <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
              <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
              
<div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
  <label htmlFor="subject" className="block text-sm font-regular text-gray-700 w-1/6">
    Subject
  </label>
  <textarea
  id="subject"
  className="w-1/3 h-10 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  placeholder="Let your customers know what this invoice is for"
>
</textarea>

                </div> 
              </div>
              <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              <div className="w-full container mx-auto px-4 mb-2 mt-8">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
  <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
        <thead>
          <tr className="font-semibold">
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">Item Details</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Quantity</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">UOM</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Price</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Tax</th> 
            <th className="font-semibold border-b  border-r text-left text-sm py-2 px-2">Amount</th>
           
           
            
          </tr>
        </thead>
        <tbody>
          {rows?.map((item, index) => (
            <tr key={index}>

<td className="border-r border-b px-2 py-2" ref={dropdownRef}>
  <div className="relative">
    <div 
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setDropdownOpen(prev => ({...prev, [index]: !prev[index]}))}
    >
      <span className="text-sm">{selectedProducts[index]?.item_name || item.item_name || 'Select Item'}</span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    
    {dropdownOpen[index] && (
      <div className="absolute z-50 left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="p-2 border-b">
          <input
            type="text"
            className="w-full p-1 border rounded focus:outline-none focus:border-blue-500"
            placeholder="Search products..."
            onChange={(e) => {
              const searchTerm = e.target.value.toLowerCase();
              const filtered = products.filter(product => 
                product.item_name.toLowerCase().includes(searchTerm)
              );
              setFilteredProducts(filtered);
            }}
            autoFocus
          />
        </div>
        
       
      </div>
    )}
  </div>
</td>


               {/* Render additional fields only for Group type and specific categories */}
               <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.quantity}
                  onChange={(e) => handleInputChange(e.target.value, index, 'quantity' , item?.price )}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border-r border-b px-2 py-2">
                
                <td className="w-20">

                 <input
                  type="text"
                  value={item?.UOM}
                  readOnly
                  //onChange={(e) => handleInputChange(e, index, 'UOM')}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
               </td>
              </td>
              <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.price}
                  readOnly
                  //onChange={(e) => handleInputChange(products.find(p=>p.item_id===item.item_id)?.selling_price, index, 'price')}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
                             </td>
              <td className="border-r border-b px-2 py-2">
                {item.item_name && (
               <div className="w-22 flex-row ">
               <TableSearchDropDown
                 options={Taxes.map(tax => ({
                   value: tax.value,
                   label: tax.label,
                 }))}
                 value={item?.tax_type}
                 onChange={(selectedOption) => {
                   // Find the corresponding product using item_id
                   const product = products.find(p => p.item_id === item.item_id);
                   
                   // Calculate the tax value
                   const taxValue = product ? product[selectedOption.value] : null;
           
                   // Update the item tax with the selected tax value
                   handleTaxChange(
                     index, 
                     selectedOption, 
                     taxValue, 
                     item.price, 
                     item.quantity
                   );
           
                   // Update both item.tax and item.tax_type
                   item.tax = taxValue;
                   item.tax_type = selectedOption.value;
                 }}
               />
              {item?.tax && (
               <p>{item?.tax || ''} % </p>
              )} 
             </div>
                )}
  
            </td>
 
              <td className="border-b border-r px-1 py-2">
  <input
    type="text"
    readOnly
    value={(formatNumber(item?.price * item?.quantity) || formatNumber(item?.total) || 0)}

    //onChange={(e) => handleAmountInputChange(e, index, 'amount')}
    className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
  />
</td>

           

<td className="px-2">  <button    type="button"
                                  onClick={() => deleteRow(index ,rows)}
                                  className="text-red-500">
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
            </tr>
          ))}
        </tbody>
      </table>
</div>
              </div>
              </div>
              </div>
              <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
  <div className="flex flex-col w-1/3">
    <div className="flex flex-row space-x-2">
      <button onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'>
        <FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1" />
        Add New Row
      </button>
      <button className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'>
        <FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1" />
        Add Items in Bulk
      </button>
    </div>

    <div className="w-full flex flex-col mt-40 ">
      <div className="w-full flex flex-row items-center space-x-2">
        <label htmlFor="customerNotes" className="block text-sm font-regular text-gray-700 w-full">
          Customer Notes
        </label>
      </div>
      <div className="w-full flex flex-row item-start items-center space-x-2">
        <textarea
          id="customerNotes"
          name="customerNotes"
          className="mt-1 w-full h-20 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700 placeholder-black"
          value={customerNotes}
          onChange={(e) => setCustomerNotes(e.target.value)}
          placeholder="Thanks for business."
        />
      </div>
      <span className="mt-1 text-xs text-gray-500">Will be displayed on the invoice</span>
    </div>
  </div>

  

                <div className='p-6 space-y-6 flex flex-col justify-between rounded-md w-1/2 bg-[#F7F7FE]'>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm font-semibold'>Sub Total</p>
                </div>
                <div><p className='text-sm font-semibold'>{/* calculateSubtotal() */formatNumber (SubTotal)}</p></div>
                </div>
                <div className='flex justify-between'>
                <div className='flex flex-row items-center'>
  <p className='text-sm'>Discount</p>
  <div className='flex justify-end w-full relative'>
  <input 
    type="text"
    value={amendedDiscount}
    onChange={(e) => {
      const currentValue = e.target.value;
      const newValue = currentValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
      setAmendedDiscount(newValue);
    }}
    className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-1/3 border-r-0 rounded-l-md'
  />
  <span className='bg-gray-200 p-2 text-sm border border-gray-300 border-l-0 rounded-r-md'>%</span>
</div>
</div>
<div>
  <p className='text-sm '>
    <span className='mr-1 font-bold text-xl text-red-500'>-</span>
    {formatNumber(Number.isFinite(parseFloat(SubTotal)) && Number.isFinite(parseFloat(amendedDiscount)) ? parseFloat(SubTotal) * (parseFloat(amendedDiscount / 100)) : 0)}
  </p>
</div>
                </div>
                <div className='flex justify-between items-center'>
  <div className='flex flex-row items-center'>
    <p className='text-sm w-[100px]'>Shipping Charges</p>
    <div className='flex justify-end w-full relative ml-[85px]'>
      <input
        type="text"
        value={shippingCharges}
        onChange={(e) => {
          const regex = /^[0-9]*$/;
          if (regex.test(e.target.value)) {
            setShippingCharges(e.target.value);
          }
        }}
        className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-24 rounded-md'
      />
      <FontAwesomeIcon 
        icon={faQuestionCircle} 
        className="ml-4 text-gray-400 cursor-help"
        title="Enter shipping charges amount"
      />
    </div>
  </div>
  <div>
    <p className='text-sm'>{formatNumber(shippingCharges || 0)}</p>
  </div>
  
</div>

<div className='flex flex-row items-center space-x-10 mt-4'>
  <div className='flex items-center space-x-2'>
    <input
      type="radio"
      id="tds"
      name="taxType"
      value="TDS"
      checked={taxType === 'TDS'}
      onChange={(e) => setTaxType(e.target.value)}
      className='text-blue-500 focus:ring-blue-500'
    />
    <label htmlFor="tds" className='text-sm'>TDS</label>
  </div>
  
  <div className='flex items-center space-x-2'>
    <input
      type="radio"
      id="tcs"
      name="taxType"
      value="TCS"
      checked={taxType === 'TCS'}
      onChange={(e) => setTaxType(e.target.value)}
      className='text-blue-500 focus:ring-blue-500'
    />
    <label htmlFor="tcs" className='text-sm'>TCS</label>
  </div>

  <div className='relative ml-16'>
    <div className='flex items-center'>
      <input
        type="text"
        value={taxValue}
        placeholder="Select a tax"
        onChange={(e) => setTaxValue(e.target.value)}
        className='px-6 pr-10 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-24 rounded-md'
      />
      <FontAwesomeIcon 
        icon={faChevronDown}
        className="absolute right-2 text-gray-400 cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      />
    </div>
    
    {showDropdown && (
      <div className='absolute top-full mt-1 w-24 bg-white border rounded-md shadow-lg z-10'>
        {/* <div className='py-1 px-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaxSelect('0.1')}>Commission on brokarage [5%]</div>
        <div className='py-1 px-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaxSelect('1')}>Commission or Brokerage (Reduced) [3.75%]</div>
        <div className='py-1 px-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaxSelect('2')}>Dividend [10%]</div>
        <div className='py-1 px-2 hover:bg-gray-100 cursor-pointer' onClick={() => handleTaxSelect('5')}>Dividend (Reduced) [7.5%]</div> */}
      </div>
    )}
  </div>
  <div classname ='relative flex justify-end'>
  <p className='text-sm text-right'>{formatNumber(calculateTax())}</p>
</div>
</div>




                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                    <p className='text-sm border border-gray-500 border-dashed px-4 py-1 rounded'>Adjustment</p>
                <div className='flex justify-end w-full relative'>
                <input
  type="text"
  value={amendedPrice}
  onChange={(e) => {
    const regex = /^[-+]?[0-9]*$/; // allow optional - or +, followed by digits
    if (regex.test(e.target.value)) {
      setAmendedPrice(e.target.value);
    }
  }}
  className='px-2 w-[50%] text-right py-1 w-2/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
/>

  <button 
  className="mr-2 text-white px-1 py-0.5 rounded-md" 
  title="" info-tooltip="Add any other +ve or -ve value that need to be applied to adjust the total amount of the transaction Eg. +10 or -10."  style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faQuestionCircle}  className="word-break break-words mb-0.5 text-xs text-gray-500 mx-1 cursor-pointer" />
</button>
</div>
                </div>
                <div><p className='text-sm '>{amendedPrice ? amendedPrice : '0.00'}</p></div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-lg font-semibold '>Total ( ₹ )</p>
                </div>
                <div><p className='text-lg font-semibold'>{formatNumber(total) }</p></div>
                </div>
                </div>
                </div>
               
                <div className="w-full flex flex-col px-4 bg-[#F9F9FB] border border-gray-300 rounded-md p-4">
  <div className="flex flex-row justify-between space-x-4">
    {/* Terms & Conditions Section */}
    <div className="w-1/2 border-r border-gray-300 pr-12 mr-12">
      <label htmlFor="termsAndConditions" className="block text-sm font-regular text-[#e54643] mb-2">
        Terms & Conditions<span className="text-red-500">*</span>
      </label>
      <textarea
        id="termsAndConditions"
        name="termsAndConditions"
        className="w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        value={customerNotes}
        onChange={(e) => setCustomerNotes(e.target.value)}
        placeholder="Enter the terms and conditions of your business to be displayed in your transaction."
        rows={4}
      />
    </div>

    {/* Upload File Section */}
    <div className="w-5/12 pl-24">
      <label className="block text-sm font-regular text-[#e54643] mb-2">
        Attach File(s) to Invoice<span className="text-red-500">*</span>
      </label>
      <div className="border-2 border-dashed border-gray-300 rounded-md p-2 flex items-center justify-between w-48">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1" />
          <span className="text-sm text-gray-600">Upload File</span>
        </div>
        <div className="border-l border-gray-300 h-6 mx-2"></div>
        <FontAwesomeIcon icon={faChevronDown} className="text-gray-500" />
      </div>
      <span className="mt-1 text-xs text-gray-500">
        You can upload maximum of 10 files, 10 MB
      </span>
    </div>
  </div>
  <hr className="border-t border-gray-200 w-full mt-8 mb-4" style={{ borderTopWidth: '1px' }} />
 
  {/* Payment Gateway Section */}
  <div className="mt-4 px-4">
  <div className="flex items-center">
    <h3 className="text-lg font-medium">Want to get paid faster?</h3>
    <div className="flex items-center ml-2 relative">
      <div className="w-4 h-4 rounded-full bg-red-500"></div>
      <div className="w-4 h-4 rounded-full bg-yellow-500 -ml-2"></div>
    </div>
    <span className="text-[#408dfb] ml-2 font-semibold">VISA</span>
  </div>
  <div className="flex items-center mt-2">
    <span className="text-sm text-gray-600">Configure payment gateways and receive payments online.</span>
    <span className="text-[#408dfb] ml-2 cursor-pointer">Set up Payment Gateway</span>
  </div>
</div>


</div>

<div className="mt-10 px-4 mb-20">
  <div className="text-sm text-gray-600">
    <span className="font-semibold">Additional Fields:</span> Start adding custom fields for your invoices by going to    
    <span className="font-medium">  Settings</span>
    <FontAwesomeIcon icon={faArrowRightLong} className="mx-1 text-gray-400" />
    <span className="font-medium">Sales</span>
    <FontAwesomeIcon icon={faArrowRightLong} className="mx-1 text-gray-400" />
    <span className="font-medium">Invoices</span>
  </div>
</div>


              </div>



              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] text-white border border-[#DDDDDD] rounded-md"
          onClick={handleSaveInvoiceData} >
            Save as Draft  {isSaving && <Spinner />}
            </button>
          <button onClick={handleCancel} className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>Cancel</button>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>


{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}

{/* POPup ends */}
    </div>
  );
};

export default EditInvoice;
