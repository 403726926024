import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle, faX } from '@fortawesome/free-solid-svg-icons';

const Msg200or201 = ({ message }) => {
  return (
    <div className="mt-8 fixed inset-0 flex items-top justify-center">
      <div className="items-center justify-evenly bg-green-100 rounded-md shadow-md w-[300px] h-[48px] flex flex-row">
        <div className="p-1 rounded-md bg-[#ef5364]">
          <p><FontAwesomeIcon icon={faCheckCircle} className="text-md text-white" /></p>
        </div>
        <h2 className="text-md text-black-500">{message}</h2>
      </div>
    </div>
  );
};

const Msg204 = ({ message }) => {
  return (
    <div className="mt-8 fixed inset-0 flex items-top justify-center">
      <div className="items-center justify-evenly bg-green-100 rounded-md shadow-md w-[300px] h-[48px] flex flex-row">
        <div className="p-1 rounded-md bg-[#ef5364]">
          <p><FontAwesomeIcon icon={faCheckCircle} className="text-md text-white" /></p>
        </div>
        <h2 className="text-md text-black-500">{message}</h2>
      </div>
    </div>
  );
};

const Msg400 = ({ message, closePopup }) => {
  return (
    <div className="fixed inset-0 flex z-50 items-start justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-md shadow-md w-[550px] flex flex-col">
        <hr className="border-t border-gray-200 w-full" />
        <div className='pt-4 pl-4'>
          <div className="w-full items-center p-5">
            <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529]">{message}</label>
          </div>
        </div>
        <div className="mt-auto w-full">
          <hr className="border-t border-gray-200 w-full" />
          <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
            <button onClick={closePopup} className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};



const Msg401 = ({ message, closePopup }) => {
  return (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-b-md shadow-md w-[550px] h-[150px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '150px' }}>
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className='pt-4 pl-4'>
          <div className="w-full items-center p-5">
            <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529]">Credentials are not Valid</label>
          </div>
        </div>
        <div className="mt-auto w-full">
          <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
          <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
            <button onClick={closePopup} className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Msg403 = ({ message, closePopup }) => {
  return (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-b-md shadow-md w-[550px] h-[150px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '150px' }}>
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className='pt-4 pl-4'>
          <div className="w-full items-center p-5">
            <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529]">{message} </label>
          </div>
        </div>
        <div className="mt-auto w-full">
          <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
          <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
            <button onClick={closePopup} className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md">Back to Login Page</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Msg500 = ({ message, closePopup, cancelPopup }) => {
  return (
    <div className="z-60 fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-b-md shadow-md w-[550px] h-[150px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '150px' }}>
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className='pt-4 pl-4'>
          <div className="w-full items-center p-5">
            <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529]">Something went wrong. Please try again. If the issue persists, kindly reach out to support@brickbucket.com</label>
          </div>
        </div>
        <div className="mt-auto w-full">
          <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
          <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
            <button onClick={closePopup} className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md">Retry</button>
            <button onClick={cancelPopup} className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ErrorPopup = ({message, closePopup}) => {
  return (
    <div className='fixed top-12 left-0 w-full h-screen flex items-top justify-center'>
    <div className="bg-[#FEEDEE] rounded-md shadow-md w-[300px] h-[48px] flex flex-row justify-center items-center space-x-2">
      <div className="p-1 rounded-md bg-[#ef5364]">
        <p><FontAwesomeIcon icon={faExclamationTriangle} className="text-md text-white" /></p>
      </div>
      <h2 className="text-md text-black-500">{message}</h2>
      <p><FontAwesomeIcon icon={faX} className='text-[#F7525A] w-3 h-3 cursor-pointer' onClick={closePopup} /></p>
    </div>
  </div>
  );
};

export { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500, ErrorPopup };
