import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  faArrowLeft, 
  faTag, 
  
} from '@fortawesome/free-solid-svg-icons';
const QuotationDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quotationId } = location.state || {};
  console.log('Quotation ID:', quotationId);

  const [quotationData, setQuotationData] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
const [showNoChangesModal, setShowNoChangesModal] = useState(false);

  const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [categories, setCategories] = useState(null);
  const [isSavingQuote, setIsSavingQuote] = useState(false);
  
useEffect(() => {
    fetchQuotationDetails();
  }, []);

  const fetchQuotationDetails = async () => {
    try {
      const token = await sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotation?quotation_id=${quotationId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });

      console.log(response);
      const data = await response.json();
      setQuotationData(data.data);
      console.log(data.data);
    } catch (error) {
      console.error('Error fetching quotation:', error);
    }
  };

  useEffect(() => {
    if (quotationData) {
      fetchAttributesByproductCategory();
    }
  }, [quotationData]);

  const fetchAttributesByproductCategory = async ()=>{
    try {
      const token = await sessionStorage.getItem('token');
      const ProductCategoryId = quotationData.product_category_id._id;
      const response = await fetch(`${fetchUrl2}product-attributes/get-all-attributes-by-productCategory?product_category_id=${ProductCategoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      //console.log(data);
      if (data.success) {
        setAttributes(data.data);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
    };

  const handleChangeProduct = async (categoryName, currentProductId) => {
    setSelectedCategory(categoryName);
    setSelectedProductId(currentProductId);
    setIsProductModalVisible(true);
    try {
      const token = await sessionStorage.getItem('token');
      const organizationId = await sessionStorage.getItem('organizationId');
      const ProductCategoryId = quotationData.product_category_id._id;
      // Make sure the URL is correctly formatted
      const response = await fetch(fetchUrl2 + `product-groups/get-productGroups-by-productCategory?product_category_id=${ProductCategoryId}&organizationId=${organizationId}`, {
        method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-token': token
    }
  });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Fetched group data:', data);
      if (data) {
        setAvailableProducts(data.data);
      }
    } catch (error) {
      console.log('Error fetching group data:', error.message);
      // Handle the error appropriately - maybe set an error state
    }
  };
  
  const handleProductSelection = (selectedProduct) => {
    const sqFtPerBoxAttribute = attributes.find(attr => attr.attribute_name === "Sq Ft per Box");
    const selectedSqFtValue = sqFtPerBoxAttribute?.attribute_values.find(
      val => selectedProduct.attribute_value_ids?.includes(val.attribute_value_id)
    );
    
    const sqFtPerBox = selectedSqFtValue ? parseFloat(selectedSqFtValue.attribute_value) : 1;
    const updatedItems = quotationData.items.map(item => {
      if (item?.area_types?.area_name === selectedCategory) {
        // Calculate new boxes based on selected product's sq ft per box
        const totalBoxes = Math.ceil(parseFloat(item?.area_types?.area) / sqFtPerBox);
        // Calculate new total price based on updated quantity and selected product's price
        const newTotal = totalBoxes * selectedProduct.selling_price;
        // Calculate new tax amount
        const newTaxAmount = (newTotal * (selectedProduct.GST / 100));
  
        return {
          ...item,
          item_id: selectedProduct._id,
          item_name: selectedProduct.product_group_name,
          price_per_sqft:selectedProduct.price_per_sqft,
          quantity: totalBoxes,
          selling_price: selectedProduct.selling_price,
          total: newTotal,
          tax_rate: selectedProduct.GST || 0,
          tax_amount: newTaxAmount,
          total_tax_amount: newTaxAmount,
          isModified: true
        };
      }
      return item;
    });
  
    // Update quotation data with new totals
    const newSubtotal = updatedItems.reduce((sum, item) => sum + item.total, 0);
    const newTotalTax = updatedItems.reduce((sum, item) => sum + item.total_tax_amount, 0);
  
    setQuotationData({
      ...quotationData,
      items: updatedItems,
      subtotal: newSubtotal,
      tax_amount: newTotalTax,
      total: newSubtotal + newTotalTax
    });
  
    setHasUnsavedChanges(true);
    setIsProductModalVisible(false);
  };
  

  const handleSaveQuotation = async () => {
    setIsSavingQuote(true);

    try {
      const token = await sessionStorage.getItem('token');
      const organizationId = await sessionStorage.getItem('organizationId');
  
      const hasModifiedProducts = quotationData?.items?.some(item => item.isModified);
  
      if (!hasModifiedProducts) {
        setShowNoChangesModal(true);
        return;
      }
    
      

      // Create new quotation payload without quotation_number
      const { _id, quotation_number, quotation_date, ...quotationWithoutNumber } = quotationData;
  
      const quotationPayload = {
        ...quotationWithoutNumber,
        quotation_date: new Date().toISOString(),
        organizationId,
        selected: false,
        items: quotationData.items.map(item => ({
          item_id: item.item_id,
          item_name: item.item_name,
          price_per_sqft: item.price_per_sqft,
          quantity: item.quantity,
          selling_price: item.selling_price,
          cost_price: item.cost_price || 0,
          discount: item.discount || 0,
          tax_rate: item.tax_rate,
          tax_type: item.tax_type || 'GST', 
          total_tax_amount: item.total_tax_amount,
          tax_amount: item.tax_amount,
          total: item.total,
          UOM: item.UOM,
          UOM_id: item.UOM_id,
          area_types: item?.area_types,
          quantity_breakup: item.quantity_breakup

        }))
      };
     console.log('Quotation Payload:', quotationPayload);
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationPayload),
      });
  
      const data = await response.json();
      console.log('Quotation saved:', data);
      if (data.success) {
        navigateToQuoteSummary(data.data)
        //navigate('/consumer/app/home');
      }
    } catch (error) {
      console.error('Failed to save quotation:', error);
    }
  };
   const navigateToQuoteSummary = (quotation) => {
    try {
      const navigationData = {
        quotation_id: quotation._id || '',
        product_category_id: quotation.product_category_id || '',
        project_id: quotation.project_id || '',
        package_id: '',
        product_category_name : 'Tiles',
        projectName:  '',
        timestamp: Date.now()
      };

      navigate('/consumer/app/summarydetails', {
        state: navigationData,
        replace: true 
      });
 
    } catch (error) {
      console.error('Navigation failed:', error);
      // Fallback navigation
      navigate('/consumer/app/summarydetails');
    }
  }; 
  
  //   <div className="flex-1">
  //     <div className="bg-[#F5F7FA] flex-1 p-4 pb-24 overflow-y-auto">
  //       <div className="bg-white rounded-lg shadow-sm">
  //         <div className="bg-[#EA011F] flex items-center p-4 rounded-lg">
  //           <span className="text-xl font-bold text-white">
  //             {quotationData?.quotation_number}
  //           </span>
  //           <div className="flex-1" />
  //           <span className="text-xl font-bold text-white text-right mr-4">
  //             ₹{quotationData?.total?.toFixed(0)}
  //           </span>
  //         </div>
  
  //         <div className="p-4">
  //           {quotationData?.items?.filter(item => parseFloat(item.area_types.area) > 0).map((item) => (
  //             <div key={item._id} className="mb-6 border-b border-gray-200 pb-4">
  //               <div className="flex justify-between items-center">
  //                 <div className="flex-1">
  //                   <h3 className="text-lg font-semibold text-[#EA011F]">
  //                     {item.area_types.area_name}
  //                   </h3>
  //                   <p className="text-base font-medium text-gray-700 mt-1">
  //                     {item.item_name}
  //                   </p>
  //                 </div>
  //                 <span className="px-2 py-1 rounded-full text-sm">
  //                   <span className="text-gray-700">{item.quantity}</span>
  //                   <span className="text-gray-600"> {item.UOM}</span>
  //                 </span>
  //               </div>
  
  //               <div className="bg-gray-50 p-2 rounded-lg mt-2">
  //                 <div className="py-2 flex justify-between items-start">
  //                   <div className="flex-1">
  //                     <p className="text-gray-700 font-bold text-lg mb-2">
  //                       ₹{item.selling_price}/{item.UOM}
  //                     </p>
  //                     <button
  //                       onClick={() => handleChangeProduct(item.area_types.area_name, item.item_id)}
  //                       className="mt-1 border-b border-[#EA011F] mb-2"
  //                     >
  //                       <span className="text-[#EA011F] font-medium">Change Product</span>
  //                     </button>
  //                     <p className="text-gray-600">
  //                       Total Area: {parseFloat(item.area_types.area).toFixed(0)} sq.ft
  //                     </p>
  //                   </div>
  //                   <div className="text-right">
  //                     <p className="font-bold text-green-700 text-lg">
  //                       ₹{item.total}
  //                     </p>
  //                     <p className="text-gray-600 text-sm">
  //                       {item.tax_rate}% {item.tax_type}
  //                     </p>
  //                     <p className="text-gray-600 text-sm">
  //                       {quotationData?.tax_prefrence}
  //                     </p>
  //                   </div>
  //                 </div>
  //               </div>
  
  //               <button
  //                 onClick={() => {
  //                   setSelectedDetails(item.quantity_breakup);
  //                   setIsDetailsModalVisible(true);
  //                 }}
  //                 className="mt-4 flex justify-between items-center p-3 bg-gray-100 rounded-lg w-full"
  //               >
  //                 <span className="font-bold text-gray-800">View Area Details</span>
  //                 <span className="text-gray-600">{item.area_types.area_name}</span>
  //               </button>
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  
  //     <div className="fixed bottom-0 left-0 right-0 bg-white flex items-center justify-between px-4 py-4 z-50">
  //       <button
  //         className="w-full bg-[#EA011F] rounded-lg shadow-sm p-3"
  //         onClick={handleSaveQuotation}
  //       >
  //         <span className="text-white text-center font-semibold text-lg">Save Changes</span>
  //       </button>
  //     </div>
  
  //     {isDetailsModalVisible && (
  //       <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
  //         <div className="bg-white p-4 rounded-lg w-11/12 max-h-[80%] overflow-auto">
  //           <h2 className="text-xl font-bold text-[#EA011F] mb-4">Area Details</h2>
  //           <div className="mb-4">
  //             {Object.entries(selectedDetails || {}).map(([roomName, details]) => (
  //               <div key={roomName} className="bg-gray-50 p-3 rounded-lg mb-3">
  //                 <h3 className="text-lg font-semibold text-gray-800 mb-2">
  //                   {roomName}
  //                 </h3>
  //                 <div className="flex justify-between">
  //                   <span className="text-gray-600">
  //                     Area: {details.area.toFixed(2)} sq.ft
  //                   </span>
  //                   <span className="text-gray-600 font-medium">
  //                     Boxes: {details.boxes}
  //                   </span>
  //                 </div>
  //               </div>
  //             ))}
  //           </div>
  //           <button
  //             className="bg-[#EA011F] py-2 px-6 rounded mt-4 w-full"
  //             onClick={() => setIsDetailsModalVisible(false)}
  //           >
  //             <span className="text-white text-center font-semibold">Close</span>
  //           </button>
  //         </div>
  //       </div>
  //     )}
  
  //     {isProductModalVisible && (
  //       <div className="fixed inset-0 bg-white z-50">
  //         <div className="p-4 h-full">
  //           <div className="flex justify-between items-center mb-4">
  //             <h2 className="text-xl font-bold text-gray-800">Select Product Group</h2>
  //             <button onClick={() => setIsProductModalVisible(false)}>
  //               <FontAwesomeIcon icon={faTimes} className="text-gray-600 text-2xl" />
  //             </button>
  //           </div>
  
  //           <div className="overflow-auto h-full">
  //             {availableProducts.map(product => (
  //               <button
  //                 key={product._id}
  //                 className="flex items-center p-4 border-b border-gray-200 w-full"
  //                 onClick={() => handleProductSelection(product)}
  //               >
  //                 <div className="mr-3">
  //                   <div className="h-5 w-5 rounded-full border-2 border-[#EA011F] flex justify-center items-center">
  //                     {selectedProductId === product._id && (
  //                       <div className="h-3 w-3 rounded-full bg-[#EA011F]" />
  //                     )}
  //                   </div>
  //                 </div>
  //                 <img
  //                   src={`${imageUrl}${product.product_group_image}`}
  //                   alt={product.product_group_name}
  //                   className="w-14 h-14 rounded-lg object-cover"
  //                 />
  //                 <div className="flex-1 ml-4">
  //                   <p className="text-lg font-bold text-gray-800">
  //                     {product.product_group_name}
  //                   </p>
  //                 </div>
  //               </button>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
  
  const renderHeader = () => (
    <div className="bg-white p-5 shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between mb-2 relative">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <FontAwesomeIcon icon={faArrowLeft} className="text-[#736f70] text-2xl" />
        </button>
        <div className="flex-1 text-center">
          <h1 className="text-xl font-bold text-[#EA011F]">{quotationData?.quotation_number}</h1>
        </div>   
      </div>
      <div className="flex items-center justify-between bg-red-50 p-2 rounded-sm">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faTag} className="text-[#EA011F] mr-2" />
          <span className="text-md font-semibold text-gray-700">Quotation Details</span>
          </div>
          <div className="justify-right items-right">
  <span className="text-md text-[#EA011F] font-bold">
    ₹{Number(quotationData?.total?.toFixed(0)).toLocaleString('en-IN')}
  </span>
  <div className="justify-right items-right">
  <span className="text-[10px] text-gray-500 font-bold  block -mt-1">GST inclusive</span>
  </div>
</div>
        
      </div>
    </div>
  );
  

return (
  <div className="flex-1 bg-gray-50 min-h-screen">
    {renderHeader()}
    <div className="bg-[#F5F7FA] flex-1 p-4 pt-2 pb-12 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-4">
          {quotationData?.items?.filter(item => parseFloat(item?.area_types?.area) > 0).map((item) => (
         <div key={item?._id} className="mx-0 mt-4 lg:mx-8 bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
         <div className="p-2 border-b border-gray-100">
           <div className="flex justify-between items-center">
             <span className="text-lg font-semibold text-[#EA011F]">{item?.area_types?.area_name}</span>
             <span className="text-sm font-medium text-gray-600">
               {/* {parseFloat(item.area_types.area).toFixed(2)} sq.ft */}
             </span>
           </div>
         </div>
       
         <div className="p-2">
           <div className="flex justify-between items-center mb-2">
             <span className="text-base font-medium text-gray-800">{item.item_name}</span>
             <div className="flex items-center gap-6">
               {/* <span className="text-sm text-gray-600">{item.quantity} {item.UOM}</span> */}
               <button
                 onClick={() => handleChangeProduct(item?.area_types?.area_name, item.item_id)}
                 className="px-2 py-0 border border-[#EA011F] rounded-full"
               >
                 <span className="text-[#EA011F] text-sm font-regular">Change</span>
               </button>
             </div>
           </div>
           <div className="grid grid-cols-3 items-center">
          <p className="text-sm text-gray-500">₹{item.price_per_sqft}/sq.ft</p>
          <p className="text-sm font-medium text-gray-500 text-center">{parseFloat(item?.area_types?.area).toFixed(2)} sq.ft</p>
           </div>

           <div className="flex justify-between bg-gray-100 items-center">     
           <p className="text-sm text-gray-500">₹{item.selling_price}/{item.UOM}</p>
           <p className="text-sm text-red-500">
             <span className="font-bold ">{item.quantity}</span> {item.UOM}
             </p>

             <p className="font-bold text-md text-green-700">₹{item.total.toLocaleString('en-IN')}</p>
             </div>
             <div className="flex justify-end mt-1">
    <div className="text-right">
      <p className="text-xs text-gray-500">{item.tax_rate}% {item.tax_type}</p>
      <p className="text-xs text-gray-500">{quotationData?.tax_prefrence}</p>
    </div>
  </div>

           <button
  onClick={() => {
    setSelectedDetails(item.quantity_breakup);
    setIsDetailsModalVisible(true);
  }}
  className="mt-4 flex justify-between items-center p-3 bg-gray-100 rounded-lg w-full"
>
  <span className="font-bold text-gray-800">View Area Details</span>
  <span className="text-gray-600">{item?.area_types?.area_name}</span>
</button>

         </div>
       </div>
       
         
          ))}
        </div>
      </div>
    </div>

    <div className="sticky bottom-0 left-0 right-0 p-3 shadow-lg bg-gray-100">
      <div className="max-w-7xl mx-auto flex gap-4">    
      <button
  onClick={() => {
    const hasModifiedProducts = quotationData?.items?.some(item => item.isModified);
    
    if (hasModifiedProducts) {
      setIsSavingQuote(true);
      handleSaveQuotation();
    } else {
      setShowNoChangesModal(true);
    }
  }}
  className="w-full md:w-1/8 bg-red-500 py-2 px-4 rounded-lg text-white font-regular text-md hover:bg-red-700 whitespace-nowrap"
>
  Save Changes
</button>

      </div>
    </div>


    {showNoChangesModal && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setShowNoChangesModal(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">No Changes Found</h2>
      <p className="text-gray-700 text-center mb-6">There are no modifications to save.</p>
      <button
        className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
        onClick={() => setShowNoChangesModal(false)}
      >
        Close
      </button>
    </div>
  </div>
)}

    {isDetailsModalVisible && (
  <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg w-11/12 max-w-md relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-[#EA011F]">Area Details</h2>
        <button 
          onClick={() => setIsDetailsModalVisible(false)}
          className="text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} className="text-xl" />
        </button>
      </div>
      
      <div className="max-h-[60vh] overflow-y-auto">
        {Object.entries(selectedDetails || {}).map(([roomName, details]) => (
          <div key={roomName} className="bg-gray-50 p-3 rounded-lg mb-3">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{roomName}</h3>
            <div className="flex justify-between">
              <span className="text-gray-600">Area: {details.area.toFixed(2)} sq.ft</span>
              <span className="text-gray-600 font-medium">Boxes: {details.boxes}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}


{isProductModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white w-full max-w-2xl rounded-lg shadow-lg mx-4">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-red-500">Select Product Group</h2>
          <button 
            onClick={() => setIsProductModalVisible(false)}
            className="text-gray-600 hover:text-gray-800"
          >
            <FontAwesomeIcon icon={faTimes} className="text-2xl" />
          </button>
        </div>

        <div className="max-h-[70vh] overflow-y-auto">
          {availableProducts.map(product => (
            <button
              key={product._id}
              className="flex items-center p-4 border-b border-gray-200 w-full hover:bg-gray-50"
              onClick={() => handleProductSelection(product)}
            >
              <div className="mr-3">
                <div className="h-5 w-5 rounded-full border-2 border-[#EA011F] flex justify-center items-center">
                  {selectedProductId === product._id && (
                    <div className="h-3 w-3 rounded-full bg-[#EA011F]" />
                  )}
                </div>
              </div>
              <img
                src={`${imageUrl}${product.product_group_image}`}
                alt={product.product_group_name}
                className="w-14 h-14 rounded-lg object-cover"
              />
              <div className="flex-1 ml-4">
               
               <p className="text-lg font-bold text-gray-800 text-left">{product.product_group_name}</p>
               ₹{product.price_per_sqft}/sq.ft
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  </div>
)}



  </div>
);


};
export default QuotationDetails;