import React, { useEffect, useState, useCallback } from "react";
import SummaryHeader from "./SummaryHeader";
import SummaryFooter from "./SummaryFooter";
import QuoteSummaryContent from './QuoteSummaryContent';
import { useLocation, useNavigate } from "react-router-dom";
import LoaderModal from '../BBAdmin/Components/Loader';

const SummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quotationData, setQuotationData] = useState(null);
  const [mockData, setMockData] = useState({
    packageData: {},
    totalPrice: { total: 0, subtotal: 0, tax: 0 }
  });

  // Safely extract location state with defaults
  const {
    product_category_name = '',
    product_category_id = '',
    project_id = '',
    package_id = '',
    quotation_id = '',
    projectName = ''
  } = location.state || {};

  const fetchUrl = process.env.REACT_APP_FORE_APILINK;

  // Transform quotation data to mock data


  // Fetch quotation data
  const fetchQuotationSummary = useCallback(async () => {
    if (!product_category_id || !project_id) {
      setError('Missing required parameters');
      setIsLoading(false);
      return;
    }

    const queryParams = new URLSearchParams({
      product_category_id,
      project_id,
      package_id,
      quotation_id
    }).toString();

    try {
      setIsLoading(true);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 30000); // 30s timeout

      const response = await fetch(`${fetchUrl}/summary/get-quote-summary?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        },
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorMessages = {
          400: 'Invalid request parameters',
          401: 'Unauthorized access',
          403: 'Access forbidden',
          404: 'Quotation not found',
          500: 'Internal server error'
        };

        const errorMessage = errorMessages[response.status] || 'Failed to fetch quotation';
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log(data)
      const transformedData = {
        packageData: {
          packageName: data.quote_type || 'Standard',
          reference: data.referance || 'N/A',
          category: data.product_category_name || product_category_name || '',
          projectName: data.project_name || ''
        },
        totalPrice: {
          total: Number(data.total) || 0,
          subtotal: Number(data.subtotal) || 0,
          tax: Number(data.tax_amount) || 0
        }
      };
      setMockData(transformedData);
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid data received from server');
      }

      setQuotationData(data);
      setError(null);

    } catch (error) {
      console.error('Quotation fetch error:', error);
      setError(error.message);
      
      if (error.name === 'AbortError') {
        setError('Request timeout - please try again');
      }

    } finally {
      setIsLoading(false);
    }
  }, [fetchUrl, product_category_id, project_id, package_id, quotation_id]);
  console.log(mockData)

  useEffect(() => {
    fetchQuotationSummary();
  }, [fetchQuotationSummary]);

  const handleUpdateQuoteSelectionStatus = async () => {
    const token = sessionStorage.getItem('token');
    const BRICKBUCKET = process.env.REACT_APP_FORE_BRICKBUCKET;
    const queryParams = new URLSearchParams({
      product_category_id,
      project_id,
      package_id,
      quotation_id
    }).toString();
    try {
      const response = await fetch(`${BRICKBUCKET}customer/quotations/update-quote-selection-status?${queryParams}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
          'Cache-Control': 'no-cache'
        },
        body: JSON.stringify({
          product_category_id:product_category_id,
          project_id: project_id,
          package_id:package_id,
          quotation_id:quotation_id,
          status: 'Selected'
        })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Handle success
       // setQuotationStatus('Selected');
       navigate('/consumer/app/home')
        return data;
      } else {
        throw new Error(data.message || 'Failed to update status');
      }
  
    } catch (error) {
      console.error('Error updating quotation status:', error);
      throw error;
    }
  };
  

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center p-8 bg-white rounded-xl shadow-lg">
          <h2 className="text-xl font-bold text-red-600 mb-4">Error Loading Summary</h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {isLoading ? (
        <LoaderModal 
          isVisible={true}
          title="Loading Summary"
          packageName="Please wait..."
        />
      ) : (
        <>
        {mockData?.packageData && (
          <SummaryHeader
          packageData={mockData.packageData}
          totalPrice={mockData.totalPrice}
        />
        )}
         {quotationData && (
        <QuoteSummaryContent 
        quotationData={quotationData} 
        onInterested={() => {
          handleUpdateQuoteSelectionStatus();
        }}
        />
         )}
          
          <SummaryFooter
          onCallBack={() => {
            //console.log('Callback requested');
          }}
          onInterested={() => {
            handleUpdateQuoteSelectionStatus();
          }}
        />

        </>
      )}
    </div>
  );
};

export default SummaryPage;
