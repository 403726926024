import React, {useEffect ,useState} from "react";
import { FaArrowRight, FaBox, FaChevronDown, FaChevronUp, FaImage, FaInfoCircle, FaRuler, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { faChevronLeft, faPhone, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemsforQuote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detailedSelection  = location.state?.detailedSelection  || [];
  const {categoryId} = location.state || '';
  const {customerId} = location.state || '';
  const {projectId} = location.state || '';
  const {quotationId} = location.state || '';
  const {vendor_id} = location.state || '';

  //console.log(quotationId);
  //console.log(detailedSelection);
  const [localDetailedSelection, setLocalDetailedSelection] = useState(
    location.state?.detailedSelection.map(item => ({...item, selectedItem: null})) || []
  );
 // console.log(localDetailedSelection);
const [UomOptions , setUomOptions] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [showSelectItemPopup, setShowSelectItemPopup] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [items , setItems] = useState([]);
const [selectedCardItems, setSelectedCardItems] = useState({});
//console.log(selectedCardItems);
const [currentCardIndex, setCurrentCardIndex] = useState(null);
//console.log(currentCardIndex);
const[ attributes, setAttributes]= useState([])
const { 
  showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
  isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
  setPopupType } = useCommonResponseHandler();
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [subItems,setSubItems] = useState([]);
  const [AlertofSelectingMoreItems , setAlertofSelectingMoreItems] = useState(false);
  const [selectedSubRooms, setSelectedSubRooms] = useState({});
  const [expandedGroups, setExpandedGroups] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState({});
  console.log('Current selected products:', selectedProducts);

  const [showProductSelectionModal, setShowProductSelectionModal] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  //const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [isGeneratingOrder, setIsGeneratingOrder] = useState(false);


  

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  useEffect(() => {
    const initialSubRooms = {};
    localDetailedSelection.forEach(selection => {
      if (selection.area.includes('Bedroom') || selection.area.includes('Bathroom')) {
        getSubRooms(selection.area).forEach(subRoom => {
          initialSubRooms[`${selection.area}-${subRoom}`] = true;
        });
      }
    });
    setSelectedSubRooms(initialSubRooms);
  }, [localDetailedSelection]);

  const getSubRooms = (roomKey) => {
    if (roomKey.includes('Bedroom')) {
      return ['Bedroom 1', 'Bedroom 2', 'Bedroom 3'];
    }
    if (roomKey.includes('Bathroom')) {
      return ['Bathroom 1', 'Bathroom 2', 'Bathroom 3'];
    }
    return [];
  };


  const fetchItemsofSelectedGroup = async (productGroupId ,attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}product/get-products-by-product-group?product_group_id=${productGroupId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response , data)
        if (output) {
          console.log(output)
          setItems(output);
        } else {
          setItems([]);
          console.error(data.message);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
  
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchItemsofSelectedGroup(productGroupId, attempt + 1), 1000); // Retry after 1 second
        } else {
         
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchItemsofSelectedGroup(productGroupId ,attempt)); // Set retry function
        }
      }/* finally {
        setIsLoading(false);
      } */
    };
    
    useEffect(() => {
      const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          //console.log(data)
          const output = await handleGetResponse(response , data)
          //console.log(output)
          if (output) {
          //console.log(output)
           setAttributes(output)
          } else {
            setAttributes([])
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
          } else {
           
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
          }
        }
        }
        if(categoryId){
        fetchAttributesByproductCategory(categoryId);
        }
    }, [categoryId]);


    const handleSelectItems = (compositeId, index) => {
      const baseGroupId = compositeId.split('-')[0];
      fetchItemsofSelectedGroup(baseGroupId);
      setCurrentGroupId(compositeId);
      setCurrentCardIndex(index);
      setShowSelectItemPopup(true);
    };
    
    const areAllRoomsSelected = (groupId, data) => {
      // Check if there's a group-level selection
      if (selectedProducts[groupId]) return true;
      
      // Check if all individual rooms have selections
      return data.rooms.every(room => 
        selectedProducts[`${groupId}-${room.name}`]
      );
    };
    
    
  
  const handleConfirm = () => {
    setShowSelectItemPopup(false);
    setShowConfirmPopup(true);
  };

  const handleClickOkay = () => {
    setAlertofSelectingMoreItems(false);
    navigate('/consumer/app');
  }

  const handleItemSelection = (tile, index) => {
    fetchItemsofSelectedGroup(tile.productGroup._id);
    setCurrentCardIndex(index);
    setShowSelectItemPopup(true);
  };
  
  

      useEffect(() => {
        FetchUoms();
    }, []);
    
      const FetchUoms = async (attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          if (output) {
            // Log the data to ensure it's fetched correctly
            //console.log('Fetched uoms:',output);
      
            const uomData = output.map((item) => ({
              id: item._id,
              value: item.UOM,
              label: item.UOM,
            }));
     // console.log(data);
        setUomOptions(uomData)
          } else {
            console.error('Error:', data.message);
            return ([])
    
          }
        }catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => FetchUoms(attempt + 1), 1000); // Retry after 1 second
          } else {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => FetchUoms(attempt)); // Set retry function
          }
        }
      };

      const findMatchingUOM = (uomId) => {
        const matchedUOM = UomOptions.find(uom => uom.id === uomId);
        return matchedUOM ? matchedUOM.value : ' '; // Default to 'Box' if no match found
      };

      const handleSaveSalesOrder = async () => {
        setIsGeneratingOrder(true);

          await new Promise(resolve => setTimeout(resolve, 2000));


        try {
          const token = sessionStorage.getItem('token');
          
          // Prepare items array from selected products
          const items = [];
    
          Object.entries(getProductGroupSummary()).forEach(([groupId, data]) => {
            if (selectedProducts[groupId]) {
              const quantity = data.boxes; // Use boxes directly from summary
              const cost = data.boxes * selectedProducts[groupId].selling_price;
              const taxAmount = (selectedProducts[groupId].GST / 100) * cost;
              
              items.push({
                item_id: selectedProducts[groupId].item_id,
                item_name: selectedProducts[groupId].item_name,
                quantity: quantity,
                selling_price: selectedProducts[groupId].selling_price,
                tax_rate: selectedProducts[groupId].GST,
                tax_type: 'GST',
                cost_price: selectedProducts[groupId].cost_price,
                UOM_id: selectedProducts[groupId].UOM_id,
                UOM: findMatchingUOM(selectedProducts[groupId].UOM_id),
                total_tax_amount: taxAmount,
                sales_total: cost,
                application_areas: {
                  area_name: 'All Rooms',
                  area: data.totalArea.toString(),
                  count: quantity.toString()
                },
                organizationId: sessionStorage.getItem('organizationId')
              });
            } else {
              data.rooms.forEach(room => {
                const roomKey = `${groupId}-${room.name}`;
                if (selectedProducts[roomKey]) {
                  const roomBoxes = handletheQuantity(room.area, data);
                  const roomCost = roomBoxes * selectedProducts[roomKey].selling_price;
                  const roomTaxAmount = (selectedProducts[roomKey].GST / 100) * roomCost;
                  
                  items.push({
                    item_id: selectedProducts[roomKey].item_id,
                    item_name: selectedProducts[roomKey].item_name,
                    quantity: roomBoxes,
                    selling_price: selectedProducts[roomKey].selling_price,
                    tax_rate: selectedProducts[roomKey].GST,
                    tax_type: 'GST',
                    cost_price: selectedProducts[roomKey].cost_price,
                    UOM_id: selectedProducts[roomKey].UOM_id,
                    UOM: findMatchingUOM(selectedProducts[roomKey].UOM_id),
                    total_tax_amount: roomTaxAmount,
                    sales_total: roomCost,
                    application_areas: {
                      area_name: room.name,
                      area: room.area.toString(),
                      count: roomBoxes.toString()
                    },
                    organizationId: sessionStorage.getItem('organizationId')
                  });
                }
              });
            }
          });
      
          const subtotal = items.reduce((sum, item) => sum + item.sales_total, 0);
          const totalTax = items.reduce((sum, item) => sum + item.total_tax_amount, 0);
      
          const salesorderData = {
            customer_id: customerId,
            product_category_id: categoryId,
            salesorder_date: new Date().toISOString(),
            project_id: projectId,
            status: 'Confirmed',
            subtotal: subtotal,
            tax_amount: totalTax,
            total: subtotal + totalTax,
            tax_prefrence: 'Tax Exclusive',
            organizationId: sessionStorage.getItem('organizationId')
          };
      
          const response = await fetch(`${fetchUrl}customer/salesorder/create-salesorder`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
            body: JSON.stringify({ ...salesorderData, items })
          });
      
          const data = await response.json();
          
          if (response.ok) {
            setShowConfirmPopup(false);
            setIsGeneratingOrder(false);
            console.log('Sales order created successfully:', data);
            navigate('/consumer/app/tilesordersummary', {
              state: {
                items: items,
                totalAmount: subtotal + totalTax,
                salesOrderData: data.data, // Assuming the API returns the created order data
                vendor_id: vendor_id,
                categoryId: categoryId,
                productGroupName: localDetailedSelection[0]?.productGroup?.product_group_name // Add product group name

              }
            });
          } else {
            console.error('Error creating sales order:', data);
            setPopupMessage('Failed to create sales order');
            setPopupType('error');
            setShowPopup(true);
          }
        } catch (error) {
          console.error('Error:', error);
          setPopupMessage('An error occurred while creating the sales order');
          setPopupType('error');
          setShowPopup(true);
        } finally {
          setIsGeneratingOrder(false);
        }
      };
      
      
      // Corrected function to calculate total tax amount
      const calculateSaleTaxAmount = (items) => {
        return items.reduce((totalTax, element) => {
          const taxAmount = parseFloat(element.total_tax_amount || 0); // Ensure value is a number
          return totalTax + taxAmount;
        }, 0);
      };
      
      // Corrected function to calculate the total sales of each line item
      const calculateSalesTotalOfEachLineItem = (items) => {
        return items.reduce((total, element) => {
          const salesTotal = parseFloat(element.sales_total || 0); // Ensure value is a number
          return total + salesTotal;
        }, 0);
      };
      
      // Corrected tax calculation function
      const calculateItemTax = (GST, cost) => {
        const lineItemTax = (parseFloat(GST) / 100) * parseFloat(cost);
        return lineItemTax;
      };
      
      
   
      const handletheQuantity = ( value , productGroup) => {
  
        const productGroupData = productGroup;
        //console.log('productGroupData', productGroupData)
        const attributeValueIds = productGroupData?.attribute_value_ids || [];
        const sqFtPerBoxAttribute = attributes.find(p => p.attribute_name === 'Sq Ft per Box');
        
        if (sqFtPerBoxAttribute) {
          let conversionFactor = 0;
    
          for (const valueId of attributeValueIds) {
            const attributeValue = sqFtPerBoxAttribute.attribute_values.find(p => p.attribute_value_id === valueId);
            if (attributeValue) {
              conversionFactor = parseFloat(attributeValue.attribute_value);
              break;
            }
          }
    
          if (conversionFactor > 0) {
            const quantity = Math.ceil((parseFloat(value) || 0) / conversionFactor);
           // console.log('quantity', quantity);
            return quantity;
          }
        }
      
      return 0; // Return 0 if no valid conversion can be made
    };
    
    const calculeteCost = (Boxes, price) => {
      // Convert Boxes and price to numbers and handle undefined or invalid values
      const numBoxes = parseFloat(Boxes) || 0; // Default to 0 if Boxes is not a valid number
      const numPrice = parseFloat(price) || 0; // Default to 0 if price is not a valid number
    
      const totalPrice = numBoxes * numPrice;
      return totalPrice.toFixed(2); // Ensure it returns a string with two decimal places
    };
      

    const getProductGroupSummary = () => {
      const summary = {};
      
      localDetailedSelection.forEach(selection => {
        const groupId = selection.productGroup._id;
        if (!summary[groupId]) {
          summary[groupId] = {
            name: selection.productGroup.product_group_name,
            totalArea: 0,
            price: selection.productGroup.selling_price,
            rooms: [],
            boxes: 0,
            UOM_id: selection.productGroup.UOM_id
          };
        }
       // console.log('selection', groupId)
    
        // Add room details
        summary[groupId].rooms.push({
          name: selection.area,
          area: parseFloat(selection.value)
        });
        
        // Update total area
        summary[groupId].totalArea += parseFloat(selection.value);
        
        // Calculate boxes for this area and add to total
        const areaBoxes = handletheQuantity(selection.value, selection.productGroup);
        summary[groupId].boxes += areaBoxes;
      });
    
      return summary;
    };

    const handleDeleteQuotation = async (quotationId) => {
     // console.log('Deleting quotation with ID:', quotationId);
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}customer/quotations/delete-quotation?quotation_id=${quotationId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
    
        const data = await response.json();
        //console.log(data);
        
        if (data.success) {
          // Show success message
          setPopupMessage('Quotation deleted successfully');
          setPopupType('success');
          setShowPopup(true);
          
          // Refresh quotations list or navigate back
          navigate(-1);
        } else {
          setPopupMessage(data.message || 'Failed to delete quotation');
          setPopupType('error');
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Error deleting quotation:', error);
        setPopupMessage('Failed to delete quotation');
        setPopupType('error');
        setShowPopup(true);
      }
    };

    const calculateTotalAmount = () => {
      const summary = getProductGroupSummary();
      return Object.values(summary).reduce((total, group) => {
        const subtotal = group.boxes * group.price;
        const tax = (group.productGroup?.GST || 18) / 100 * subtotal; // Default 18% if GST not specified
        return total + subtotal + tax;
      }, 0);
    };

    const handleProductSelection = (groupId, productGroupId) => {
      fetchItemsofSelectedGroup(productGroupId);
      setShowProductSelectionModal(true);
      setCurrentGroupId(groupId);
    };
    
    
    const SkeletonLoader = () => (
      <div className="animate-pulse">
        {[1, 2, 3].map((item) => (
          <div key={item} className="mb-4 p-4 bg-blue-50 rounded-lg">
            <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
            <div className="grid grid-cols-2 gap-2">
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-6 bg-gray-200 rounded col-span-2 mt-2"></div>
            </div>
          </div>
        ))}
        <div className="mt-6 border-t-2 border-blue-100 pt-4">
          <div className="bg-blue-50 rounded-lg p-4">
            <div className="flex justify-between items-center">
              <div className="h-8 bg-gray-200 rounded w-1/4"></div>
              <div className="h-8 bg-gray-200 rounded w-1/3"></div>
            </div>
          </div>
        </div>
      </div>
    );
    


    return (
      <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      <div className="bg-white rounded-xl shadow-lg max-w-4xl mx-auto">
        <div className="p-4 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Quotation Summary</h2>
        </div>
    
        <div className="p-4">
        
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
          <div className="">
          {Object.entries(getProductGroupSummary()).map(([groupId, data,index]) => (
           <div key={groupId} className="mb-4 p-4 bg-blue-50 rounded-lg border border-gray-200">
           <div className="flex items-center justify-between w-full">
             <div className="flex-1">
               <h3 className="font-semibold text-lg text-gray-800">
                 {data.name}
               </h3>
             </div>
             <div className="flex items-center gap-2">
             
               <button 
                 onClick={() => setExpandedGroups(prev => ({...prev, [groupId]: !prev[groupId]}))}
                 className="p-1 hover:bg-gray-100 rounded-full"
               >
                 {expandedGroups[groupId] ? 
                   <FaChevronUp className="text-blue-600 w-4 h-4" /> : 
                   <FaChevronDown className="text-blue-600 w-4 h-4" />
                 }
               </button>
             </div>
           </div>

           
    
              <div className="mt-2 grid grid-cols-2 gap-2 whitespace-nowrap">
  <p>Total Boxes: <span className="font-semibold text-[#1a237e]">{data.boxes}</span></p>
  <p>Area: <span className="font-semibold ">{data.totalArea.toFixed(2)} sq.ft</span></p>
  <p className="col-span-2 flex justify-between items-center text-lg font-bold">
    <span className="text-[#1a237e]">₹{data.price}/{findMatchingUOM(data.UOM_id)}</span>
    <span className="text-blue-600">₹{(data.boxes * data.price).toLocaleString('en-IN')}</span>
  </p>
</div>

    
{expandedGroups[groupId] && (
  <div className="mt-4 space-y-2">
    {data.rooms.map((room, index) => (
      <div key={index} className="p-2 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <span className="text-[#34495E]">{room.name}</span>
          <span className="font-semibold whitespace-nowrap">{room.area.toFixed(2)} sq.ft</span>
        </div>
        
        <div className="mt-2 flex items-center justify-between">
  <div className="flex items-center gap-3">
    {(selectedProducts[`${groupId}-${room.name}`] || selectedProducts[groupId]) && (
      <>
        <div className="border border-blue-100">
          <div className="flex bg-white rounded p-2">
            <img
              src={`${imageUrl}${selectedProducts[`${groupId}-${room.name}`]?.item_image ||
                    selectedProducts[groupId]?.item_image}`}
              alt="Selected Tile"
              className="w-8 h-8 object-cover rounded"
            />
          </div>
        </div>
        <span>
          {selectedProducts[`${groupId}-${room.name}`]?.item_name ||
           selectedProducts[groupId]?.item_name}
        </span>
      </>
    ) 
      // <span>No Design Selected</span>
    }
  </div>
  <button
    onClick={() => handleSelectItems(`${groupId}-${room.name}`, index)}
    className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
  >
    {(selectedProducts[`${groupId}-${room.name}`] || selectedProducts[groupId]) ? (
      <>
        Change
        {/* <FaArrowRight className="w-4 h-4" /> */}
      </>
    ) : (
      <>
        Select Design
        {/* <FaArrowRight className="w-4 h-4" /> */}
      </>
    )}
  </button>
</div>

      </div>
    ))}
  </div>
)}



              
<div className="mt-4 pt-4 border-t border-gray-200">
  <button
    onClick={() => handleSelectItems(groupId, index)}
    className="w-full px-2 py-1 flex items-center justify-between gap-2"
  >
    <div className="flex items-center gap-3">
      {areAllRoomsSelected(groupId, data) ? (
        <>
          <span className="text-green-600">✓ Design Selected for All Areas</span>
          <span className="text-blue-600">(Change)</span>
        </>
      ) : (
        <>
        <span>You can choose design for all Areas</span>
        {/* <span>Choose Design</span> */}
        </>
      )}
    </div>
    <FaArrowRight className="w-4 h-4" />
  </button>
</div>


         
       </div>
       
            
      ))
    }
    </div>
    <div className="p-4 border-t bg-gray-50">
          <div className="flex justify-between items-center mb-4">
            <span className="text-xl font-bold">Total Amount:</span>
            <span className="text-2xl font-bold text-blue-600">
            ₹{calculateTotalAmount().toLocaleString('en-IN')}
            </span>
        </div>
        <div className="flex gap-4 justify-between items-center">
  <button
     onClick={() => handleDeleteQuotation(quotationId)}
    className="px-2 py-1 font-semibold text-lg underline text-blue-600"
  >
    <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" />
    Back
  </button>
  
  <div className="flex gap-4">
    <button 
      onClick={() => {/* Share handler */}}
      className="p-2 rounded-full bg-blue-50 hover:bg-blue-100 text-blue-600"
    >
      <FontAwesomeIcon icon={faShare} className="w-5 h-5" />
    </button>

    <button 
      onClick={() => {/* Call handler */}}
      className="p-2 rounded-full bg-green-50 hover:bg-green-100 text-green-600"
    >
      <FontAwesomeIcon icon={faPhone} className="w-5 h-5" />
    </button>
  </div>
</div>
       </div>
       {isGeneratingOrder && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-xl shadow-2xl">
      <div className="flex flex-col items-center gap-4">
        <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        <span className="text-lg font-semibold">Generating Order...</span>
      </div>
    </div>
  </div>
)}
       <div className="mt-8 flex justify-center">
  <button
    onClick={handleSaveSalesOrder}
    //disabled={isGeneratingQuote}
    className="bg-[#2196F3] text-white px-2 py-1 rounded-lg text-lg font-semibold hover:bg-[#1976D2] transition-colors shadow-md"
  >
    
      Confirm Order
   
  </button>
</div>

      
      </>
      )}
      </div>
      {showSelectItemPopup  && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg w-full max-w-2xl">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-bold">Select Product</h3>
        <button onClick={() => setShowSelectItemPopup(false)}>
          <FaTimes className="text-gray-500" />
        </button>
      </div>
      <div className="grid grid-cols-2 gap-4 max-h-[60vh] overflow-y-auto">
        {items.map((product) => (
          <div
            key={product._id}
            className="p-4 border rounded-lg cursor-pointer hover:border-blue-500"
           // In your popup onClick handler:
           onClick={() => {
            const summaryData = getProductGroupSummary()[currentGroupId.split('-')[0]];
            const baseGroupId = currentGroupId.split('-')[0];
            
            if (currentGroupId.includes('-')) {
              setSelectedProducts(prev => ({
                ...prev,
                [currentGroupId]: product
              }));
            } else {
              const newSelections = { ...selectedProducts };
              newSelections[currentGroupId] = product;
              
              summaryData.rooms.forEach(room => {
                const roomKey = `${currentGroupId}-${room.name}`;
                newSelections[roomKey] = product;
              });
              
              setSelectedProducts(newSelections);
              // Automatically expand the group after selection
              setExpandedGroups(prev => ({
                ...prev,
                [baseGroupId]: true
              }));
            }
            setShowSelectItemPopup(false);
          }}
          

          >
            <p className="font-medium">{product.item_name}</p>
            <img
                src={`${imageUrl}${product.item_image}`}
                alt="Selected Tile"
                className="w-24 h-24 object-cover rounded"
              />
            <p className="text-blue-600 font-semibold mt-2">₹{product.selling_price}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

{showConfirmPopup && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
      <div className="text-center">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h3 className="mt-4 text-lg font-medium text-gray-900">Order Confirmed!</h3>
        <p className="mt-2 text-sm text-gray-500">Your order has been successfully placed.</p>
        <div className="mt-4">
          <button
            onClick={() => {
              setShowConfirmPopup(false);
              navigate('/consumer/app');
            }}
            className="bg-[#2196F3] text-white px-4 py-2 rounded-md hover:bg-[#1976D2]"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
    </div>
    
    );
    
    
  };

export default ItemsforQuote;
