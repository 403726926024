import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faWindowMaximize, 
  faChevronDown,
  faChevronUp,
  faRuler,
  faTools,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate} from 'react-router-dom';

/* const quotationData = {
    quotation_number: "QT-000050",
    quotation_date: new Date(),
    items: [
      {
        item_name: "2.5 T 94mm sliding",
        quantity: 1156,
        selling_price: 450,
        tax_rate: 18,
        total: 520200,
        total_tax_amount: 93636,
        UOM: "Sq.ft",
        quantity_breakup: {
          "F1-Living-W1": {
            units: 5,
            area: 20
          },
          "F1-Living-W2": {
            units: 5,
            area: 20
          },
          "F1-Kitchen-W1": {
            units: 5,
            area: 7.5
          },
          "F1-Bedroom 1-W1": {
            units: 5,
            area: 20
          },
          "F1-Bedroom 1-W2": {
            units: 5,
            area: 12
          },
          "F2-Living-W1": {
            units: 5,
            area: 20
          },
          "F2-Living-W2": {
            units: 5,
            area: 20
          },
          "F2-Kitchen-W1": {
            units: 5,
            area: 7.5
          }
        }
      },
      {
        item_name: "3 Track UPVC Sliding Window",
        quantity: 856,
        selling_price: 520,
        tax_rate: 18,
        total: 445120,
        total_tax_amount: 80121.60,
        UOM: "Sq.ft",
        quantity_breakup: {
          "F2-Bedroom 1-W1": {
            units: 4,
            area: 16
          },
          "F2-Bedroom 2-W1": {
            units: 4,
            area: 16
          },
          "F2-Bedroom 3-W1": {
            units: 4,
            area: 16
          },
          "F2-Dining-W1": {
            units: 4,
            area: 16
          }
        }
      }
    ],
    subtotal: 520200,
    tax_amount: 93636,
    total: 613836,
    discount: 0,
    adjustment: 0,
    tax_prefrence: "Tax Inclusive"
  }; */
  

const WindowsQuotationSummary = () => {
  const [expandedItems, setExpandedItems] = useState({});
      const navigate = useNavigate();
  const location = useLocation()
  const {quotation_id, product_category_id , project_id , package_id} = location.state || {}
   /* const  product_category_id = '67a055a444f77126a0a12770'
      const project_id = '678e51cb656185900e5eccbd'
      const package_id = 1
      const quotation_id  = '67c59811dc6bf0fdbc67a4d9' */
      const [quotationData, setQuotationData] = useState({});
      const fetchUrl = process.env.REACT_APP_FORE_APILINK;

  const toggleItem = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const fetchQuotationSummary = async (signal) => {
    const queryParams = new URLSearchParams({
      product_category_id,
      project_id,
      package_id,
      quotation_id
    }).toString();
  
    try {
      const response = await fetch(`${fetchUrl}/summary/get-quote-summary?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        let errorMessage = 'Failed to fetch quotation summary';
        switch (response.status) {
          case 400: errorMessage = 'Invalid query parameters'; break;
          case 404: errorMessage = 'Quotation not found'; break;
          case 500: errorMessage = 'Server error occurred'; break;
          default:
            const data = await response.json();
            errorMessage = data.message || errorMessage;
        }
        throw new Error(errorMessage);
      }
  
      const data = await response.json();
      console.log(data)
      setQuotationData(data);
    } catch (error) {
      if (!error.name === 'AbortError') {
        console.error('Error fetching quotation:', error.message);
      }
    }
  };
  useEffect(() => {
    const controller = new AbortController();
  
    const loadQuotationData = async () => {
      try {
        if (!product_category_id || !project_id || !package_id) {
          console.log('Missing required parameters');
          return;
        }
        
        await fetchQuotationSummary(controller.signal);
      } catch (error) {
        if (!error.name === 'AbortError') {
          console.error('Failed to fetch quotation:', error);
        }
      }
    };
  
    loadQuotationData();
  
    return () => {
      controller.abort();
    };
  }, [product_category_id, project_id, package_id, quotation_id]);
  
      

  const renderQuotationHeader = () => {
    // Safely extract and provide fallback values
    const quotationReference = quotationData?.referance || quotationData?.quotation_number || 'QT-000000';
    const quoteType = quotationData?.quote_type || 'Standard Quote';
    const productTitle = quotationData?.product_category_name || 'Windows';
  
    return (
      <div className="px-6 py-4 bg-white border-b border-gray-100">
        <div className="flex items-center justify-between w-full">
             <button
                    onClick={() => navigate(-1)}
                    className="w-8 h-8 flex items-center justify-center hover:bg-red-50 rounded-full transition-all duration-200"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-gray-700 text-lg" />
                  </button>    
          {/* Left side - Icon and Title */}
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 bg-gradient-to-br from-red-50 to-white rounded-xl flex items-center justify-center shadow-sm">
              <FontAwesomeIcon 
                icon={faWindowMaximize} 
                className="text-[#EA011F] text-xl"
                aria-label="Windows Icon" 
              />
            </div>
            <div>
              <h1 className="text-xl font-bold text-gray-800">{productTitle}</h1>
              <span className="text-sm text-gray-500"></span>
            </div>
          </div>
  
          {/* Right side - Quote Info */}
          <div className="text-right flex flex-col space-y-1">
            <div className="inline-block bg-red-50 px-2 py-1 rounded-full">
              <h2 className="text-sm font-medium text-[#EA011F]">
                {quoteType}
              </h2>
            </div>
            <div className="inline-block px-2">
              <p className="text-sm text-gray-600 mt-1">
                Ref: <span className="font-medium">{quotationReference}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

  const tableHeader = () => {
    return (
      <div className="sticky top-0 z-10 bg-gray-50">
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Item Details
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  };

  const ItemsTable = () => {
    // Early return if no data
    if (!quotationData?.items?.length) {
      return (
        <div className="px-2 py-2 text-center text-gray-500">
          No items available
        </div>
      );
    }
  
    return (
      <div className="px-2 py-2 space-y-6">
        {quotationData.items.map((item, index) => (
          <div key={index} className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="p-4">
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <h3 className="text-base font-semibold text-gray-800">
                    {item?.item_name || 'Unnamed Item'}
                  </h3>
                  <div className="mt-2 pl-3 border-l-2 border-red-100">
                    <div className="text-sm text-gray-600">
                      ₹{item?.selling_price || 0}/{item?.UOM} × {item?.quantity || 0} {item?.UOM}
                    </div>
                    <div className="text-xs text-gray-500 mt-1">
                      Including {item?.tax_rate || 0}% GST
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-lg font-bold text-[#EA011F]">
                    ₹{(item?.total || 0).toLocaleString()}
                  </div>
                  {/* <div className="text-xs text-gray-500 mt-1">
                    Tax: ₹{(item?.total_tax_amount || 0).toLocaleString()}
                  </div> */}
                </div>
              </div>
  
              <div className="mt-3">
                <button
                  onClick={() => toggleItem(index)}
                  className="w-full px-3 py-2 bg-gray-50 rounded-lg flex items-center justify-between hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faRuler} className="text-[#EA011F] text-xs" />
                    </div>
                    <span className="text-sm font-medium text-gray-700">
                      Window Details
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-xs text-gray-500">{item?.quantity || 0} sq.ft</span>
                    <FontAwesomeIcon
                      icon={expandedItems[index] ? faChevronUp : faChevronDown}
                      className="text-gray-400 text-xs"
                    />
                  </div>
                </button>
  
                {expandedItems[index] && item?.applied_areas_breakup && (
                  <div className="mt-2 pl-8">
                    {Object.entries(item.applied_areas_breakup).map(([windowId, details], idx) => (
                      <div key={idx} className="py-2 border-b border-gray-100 last:border-0">
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-gray-700">{details.area_name || 'Unknown Window'}</span>
                          <span className="text-sm font-medium text-[#EA011F]">{details?.area || 0} sq.ft</span>
                        </div>
                        <div className="text-xs text-gray-500 mt-0.5">
                          Units: {details?.units || details?.count || 0 }
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  

  const PriceFooter = () => {
    const total = quotationData?.total || 0;
    const subtotal = quotationData?.subtotal || 0;
    const taxAmount = quotationData?.tax_amount || 0;
  
    return (
      <div className="sticky bottom-0 bg-white shadow-lg border-t border-gray-100">
        <div className="max-w-md mx-auto px-4 py-3">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <div className="flex items-baseline gap-1">
                <span className="text-lg font-bold text-[#EA011F]">
                  ₹{total.toLocaleString()}
                </span>
              </div>
              <div className="flex items-center text-xs text-gray-400">
                ₹{subtotal.toLocaleString()} + ₹{taxAmount.toLocaleString()} tax
              </div>
            </div>
  
            <button className="group flex items-center gap-3 bg-gradient-to-r from-red-50 to-white border-2 border-[#EA011F] text-[#EA011F] px-4 py-3 rounded-md transition-all duration-300 hover:bg-[#EA011F] hover:text-white hover:shadow-lg hover:shadow-red-100 active:scale-[0.98]">
              <span className="font-semibold text-sm tracking-wide">I'm Interested</span>
              <div className="relative">
                <svg
                  className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 group-hover:opacity-0"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                </svg>
                <svg
                  className="w-4 h-4 absolute top-0 left-0 transition-all duration-300 -translate-x-3 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      <div className="sticky top-0 z-10 bg-white shadow-sm">
        {renderQuotationHeader()}
        {tableHeader()}
      </div>

      <main className="flex-1 overflow-y-auto mt-1 mb-4 pb-3">
        <div className="p-2 pb-2 max-w-4xl mx-auto">
          {ItemsTable()}
        </div>
      </main>

      {PriceFooter()}
    </div>
  );
};

export default WindowsQuotationSummary;
