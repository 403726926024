import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSortUp, faSortDown,    } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {Loader} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import Search from '../Components/Search';

const AllCustomers = () => {
    const  [customers , setCustomers] = useState ([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const getStatusColor = (status) => {
    switch (status) {
      case 'DRAFT':
        return 'text-gray-500';
      case 'INVOICED':
        return 'text-green-500';
      case 'SENT':
        return 'text-blue-500';
      default:
        return '';
    }
  };

  const [filteredCustomers, setFilteredCustomers] = useState([]);
  console.log(filteredCustomers);
const searchTypes = ['Customer Name', 'Company Name', 'Mobile Number'];

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...customers].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setCustomers(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  useEffect(() => {
    handleSearch();
  }, [customers]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredCustomers(customers);
      return;
    }
  
    const filtered = customers.filter((customer) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Customer Name':
          return customer.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Company Name':
          return customer.company_name?.toLowerCase().includes(lowercaseQuery);
        case 'Mobile Number':
          return customer.mobile_number?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredCustomers(filtered);
  };
  
 
    const fetchCustomers = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `customer/get-all-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {  
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        if (data.status === 200 || data.status === 201 || data.status === 204 ) {
          setCustomers(data.data); // Update customers state
          setIsLoading(false);
        }else if (data.status === 500) {
          setIsLoading(false);
          setRetryFunction(() => fetchCustomers);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        } 
        else {
          setIsLoading(false);
          setPopupMessage(data.message);
          setPopupType(`${data.status}`);
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
    
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1); // Increment retry count
          setTimeout(() => fetchCustomers(attempt + 1), 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchCustomers(attempt)); // Set retry function
        }
      }
    }

    useEffect(() => {
    fetchCustomers();
  }, []);

if (isLoading) {
  return <Loader />;
}

const handleCheckboxChange = (customer) => {
  if (selectedCustomers.includes(customer)) {
    setSelectedCustomers(selectedCustomers.filter((c) => c !== customer));
    setSelectAll(false); // Update selectAll state when deselecting a checkbox
  } else {
    setSelectedCustomers([...selectedCustomers, customer]);
    if (selectedCustomers.length === customers.length - 1) {
      setSelectAll(true); // Update selectAll state when selecting all checkboxes
    }
  }
};

const handleSelectAll = () => {
  if (selectAll) {
    setSelectedCustomers([]);
    setSelectAll(false);
  } else {
    setSelectedCustomers(customers);
    setSelectAll(true);
  }
};

const handleDelete = async (attempt = 1) => {
  const selectedCustomerIds = selectedCustomers.map((customer) => customer._id);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `customer/delete-customers?customer_ids=${selectedCustomerIds}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      // Update the customers state
      setCustomers(customers.filter((customer) => !selectedCustomerIds.includes(customer._id)));
      setSelectedCustomers([]);
      setSelectAll(false);
      
      // Show success popup
      setPopupMessage(data.message);
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate("/bb/app/sales/customerslist")
      }, 3000); 
      // Fetch updated customer list
      //fetchCustomers();
    } else {
      console.log(data.message)
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleDelete(attempt + 1), 1000);
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleDelete(attempt));
    }
  }
};


const handleCreate = () => {
  navigate("/bb/app/sales/newcustomer");
};
 const handleNavigateCustomerDetails = (customer) =>{
  navigate("/bb/app/sales/customerdetails", {state:{CustomerData:customer}})
 }

 const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};

const formatNumber = (value) => {
  // Parse the input as a number
  const parsedValue = parseFloat(value);

  // Check if the parsed value is a finite number
  if (Number.isFinite(parsedValue)) {
    // Round to two decimal places
    const roundedValue = (Math.round(parsedValue * 100) / 100);
  // Format the number with commas and two decimal places
  return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
  }

  // Return '0.00' if the value is not a valid number
  return '0.00';
};

  return (
    <div className=" bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
        <div className="flex w-full flex-row">
          <div className="w-full flex-1">
              <div className="flex flex-col w-full h-full justify-center items-center">
              <div className="w-full h-full flex flex-col justify-between ">
                   <div className="w-full h-16 border-b flex items-center">
                   <div className="flex w-full justify-between px-7 items-center">
  {selectedCustomers.length > 0 ? (
    <button onClick={handleDelete}
      className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md ">Delete</button>
  ) : (
    <>
    <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Customers</h1>
      <Search onSearch={handleSearch} searchTypes={searchTypes} />
      </div>
      <button onClick={handleCreate}
        className="bg-[#E54643] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Customer"  style={{ '--tooltip-bg': 'black' }}>+ New</button>
    </>
  )}
</div>
</div>
                  <div className="w-full h-full bg-white rounded-xs overflow-hidden ">
      <div className="overflow-y-auto h-[80vh] ">
      {customers.length > 0 ? (
        <table className="w-full mb-5 ">
          <thead className="sticky top-0  bg-[#fafafc] text-[#6c7184] border-b ">
            <tr className = 'border-b'>
              <th className="text-xs py-3 text-center ">
              <input
        type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      />
    </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('customer_name')}>
                CUSTOMER NAME {getSortIcon('customer_name') && <FontAwesomeIcon icon={getSortIcon('customer_name')} />}
              </th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('company_name')}>
                COMPANY NAME {getSortIcon('company_name') && <FontAwesomeIcon icon={getSortIcon('company_name')} />}
              </th>
              <th className="text-xs py-3 text-center">EMAIL</th>
              <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('mobile_number')}>
                MOBILE {getSortIcon('mobile_number') && <FontAwesomeIcon icon={getSortIcon('mobile_number')} />}
              </th>
              <th className="text-xs py-3 text-center">AMOUNT</th>
            </tr>
          </thead>
          <tbody className='border-b border-t'>
          {filteredCustomers.length > 0 ? (
    filteredCustomers.map((customer) => (
              <tr key={customer._id} className="text-sm border-b">
                <td className="py-2 text-center">
                <input
                               type="checkbox"
                               className="mr-2"
                               onChange={(e) => handleCheckboxChange(customer)}
                               checked={selectedCustomers.includes(customer) || selectAll}
                             />
                </td>
                <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer"
                 onClick={()=>handleNavigateCustomerDetails(customer)}>
                  {customer.customer_name}</td>
                <td className="py-2 text-center">{customer.company_name}</td>
                <td className="py-2 text-center">{customer.email}</td>
                <td className={`py-2 text-center ${getStatusColor(customer.mobile_number)}`}>{customer.mobile_number}</td>
                <td className="py-2 text-center">{customer?.total_orders_amount ? `₹ ${formatNumber(customer.total_orders_amount)}` : ''}</td>
</tr>
            )) 
          ) : (
            <tr>
            <td colSpan="10" className="text-center text-[#6c7184] py-4">
              Not found
            </td>
          </tr>
    )}
          </tbody>
        </table>
      ):(
        <>
        <div className="m-4 p-4 text-center border border-grey-500 text-gray-500">
          No Customers Found.
        </div>
        <div className="flex justify-center items-center mb-4">
            <h1 className="text-xl font-bold">Create New customer</h1>
        </div>
        <div className="flex justify-center items-center">
            <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
        </div>
    </>
      )}
      </div>
    </div>
              </div>
            </div>
          </div>
      {/*   </div> */}
      </div>
      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
      </div>
    
  );
};

export default AllCustomers;