import React, { useState, useRef, useEffect } from "react";
import { faCamera, faEdit, faTimes, faTrash, faTrashAlt,faPlusCircle,faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FaFileUpload, FaRegTrashAlt } from "react-icons/fa";
import { FaArrowRight, FaImage } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NewQuote from '../BBAdmin/Quotations/NewQuote'; // Import NewQuote component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
          callback();
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
          document.removeEventListener('click', handleClick);
      };
  }, []);
};
 
const CustomDropdown = ({ options, selected, onSelect,showAddNew }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);
  
    const filteredOptions = options?.filter(option => {
      if (!option || !option.label) return false;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  
    const handleOptionClick = (value, label, id) => {
      onSelect(value, label, id);
      setIsOpen(false);
      setFocusedOptionIndex(null);
    };
  
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (!isOpen) { 
            setIsOpen(true);
          }
          setFocusedOptionIndex(prevIndex => {
            const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
            scrollToOption(newIndex);
            return newIndex;
          });
          break;
        case 'Enter':
          e.preventDefault();
          if (focusedOptionIndex !== null) {
            const selectedOption = filteredOptions[focusedOptionIndex];
            handleOptionClick(selectedOption.value, selectedOption.label, selectedOption.id);
          }
          break;
        default:
          break;
      }
    };
  
    const scrollToOption = (index) => {
      const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
      if (optionElements && optionElements[index]) {
        optionElements[index].scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    };
  
    useOutsideClick(dropdownRef, () => setIsOpen(false));
    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);
  

  return (
    <div className="relative w-1/3" ref={dropdownRef}>
    <div
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
        {selected || 'Select'}
      </span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    {isOpen && (
      <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
        <input
          type="text"
          className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus={isOpen}
        />
        <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option,index) => (
              <div
                key={option.value}
                data-index={index}
                className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                onClick={() => handleOptionClick(option.value, option.label,option.id)}
              >
                {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                {option.label}
              </div>
            ))
          ) : (
            <div className="p-2 text-sm text-gray-500">
              Not available
            </div>
          )}
        </div>
        {showAddNew && (
          <button
          type="button"
          className="flex items-center border-t p-2 mt-2 text-blue-500 "
          onClick={showAddNew}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
           New UOM
        </button>
        )}
      </div>
    )}
  </div>
  );
};

const MaterialReq = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [Popup, setPopup] = useState(false);
  const [image, setImage] = useState();
  const [displayedImage, setDisplayedImage] = useState(false);
  const [CreatedmaterialAlert, setCreatedmaterialAlert] = useState(false);
  const [customer, setCustomer] = useState ()
  const [MaterialRequirements, setMaterialRequirements] = useState ([])
  const [DeleteAlert, setDeleteAlert] = useState (false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES2;
  const [ocrResults, setOcrResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [allProductsSelected, setAllProductsSelected] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState({ id: '', name: '' });
  const [resImage,setResImage] = useState(null);
console.log(resImage)
const [OCRProducts,setOCRProducts] = useState([]);
 
const [isUploading, setIsUploading] = useState(false);

const handleIconClick = async () => {
  if (isUploading) return; // Prevent multiple uploads

  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  
  fileInput.onchange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setIsUploading(true);
      
      // Set image states synchronously
      setImage(file);
      setSelectedImage(URL.createObjectURL(file));
      setDisplayedImage(URL.createObjectURL(file));

      // Create FormData and upload immediately
      const formData = new FormData();
      if (selectedProductCategory?.id) {
        formData.append('category', selectedProductCategory?.id);
      }
      formData.append('image', file);

      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(
          `${backendApiUrl}customer/material-requirement/add-customer-material-requirements?category=${selectedProductCategory?.id}&organizationId=${sessionStorage.getItem('organizationId')}`, 
          {  
            method: 'POST',
            headers: {
              'x-token': token,
            },
            body: formData
          }
        );

        const data = await response.json();
        if (response.ok) {
          setOcrResults(data?.ocrResults);
          setResImage(data?.annotatedImagePath);
          setOCRProducts(data?.productQuantityTable);
          setCreatedmaterialAlert(true);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  fileInput.click();
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file && file.type.startsWith('image/')) {
      setImage(file);
      setSelectedImage(URL.createObjectURL(file));
      
      if(image){
      // Call handleCreate immediately after setting the image
      handleCreate();
    }
      // Handle display image separately
      const reader = new FileReader();
      reader.onload = (e) => {
        setDisplayedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
};




const handleCreate = async () => {
  try {
    const formData = new FormData();
    
    // Add category first
    if (selectedProductCategory?.id) {
      console.log('Selected Category:', selectedProductCategory);
      formData.append('category', selectedProductCategory?.id);
    }

    // Check and append image
    if (image && image instanceof File) {
      console.log('Image:', image);
      formData.append('image', image);
      
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}customer/material-requirement/add-customer-material-requirements?category=${selectedProductCategory?.id}&organizationId=${sessionStorage.getItem('organizationId')}`, {  
        method: 'POST',
        headers: {
          'x-token': token,
          // Remove Content-Type header to let browser set it with boundary
        },
        body: formData
      });

      const data = await response.json();
      
      if (response.ok) {
        console.log('Image uploaded successfully:', data);
        setOcrResults(data?.ocrResults);
        setResImage(data?.annotatedImagePath)
        setOCRProducts(data?.productQuantityTable)
        setCreatedmaterialAlert(true);
      } else {
        throw new Error(data.error || 'Failed to upload image');
      }
    } else {
      throw new Error('Please select a valid image file');
    }
  } catch (error) {
    console.error('Error creating material:', error.message);
  }
};


  useEffect(() => {
    fetchRoleId();
     fetchProductCategories();
   }, [backendApiUrl]);


   const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };


  const categoriesToDisplay = roleId === 6 
  ? productCategories.filter(category => (category?.product_category_name.toLowerCase()) === 'tiles')
  : productCategories;

  const fetchProductCategories = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(backendApiUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      if (data) {
        console.log(data);
        setProductCategories(data?.data); // Update productCategories state
      } else {
        console.error(data?.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

    }
  };


  const handleProductCategorySelect = (value, label) => {

    setSelectedProductCategory({ id: value, name: label }); // Store ID and label correctly
    
  };

  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      {/* Top Section - Image Upload */}
      <div className="mb-8 flex items-center justify-between gap-4 border-b p-2">
        <h2 className="text-2xl font-bold">Upload Your Requirement</h2>

        <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-20">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category <span className="text-red-500">*</span>
                </label>           
                  <CustomDropdown
                    options={categoriesToDisplay?.map(category => ({
                      value: category._id,
                      label: category.product_category_name
                    }))}
                    selected={selectedProductCategory ? selectedProductCategory.name : ''}
                    onSelect={(value, label) => handleProductCategorySelect(value, label)}
                    showAddNew={false}                 />
              </div>

              <div className="border border-gray-300 p-4 mb-4">
  {!displayedImage ? (
    <div className="flex justify-center items-center">
      {isUploading ? (
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-red-500 border-t-transparent"></div>
      ) : (
        <FontAwesomeIcon 
          icon={faCamera} 
          className="text-gray-400 text-6xl cursor-pointer" 
          onClick={handleIconClick} 
        />
      )}
    </div>
  ) : (
    <div className="flex justify-center">
      <img 
        src={displayedImage} 
        alt="Selected Image" 
        className="max-w-full border border-gray-200 max-h-32 cursor-pointer"
        onClick={handleIconClick}
      />
      {isUploading && (
        <div className="absolute">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-red-500 border-t-transparent"></div>
        </div>
      )}
    </div>
  )}
</div>
      </div>

      {/* Bottom Section - Two Columns */}
      <div className="flex gap-4">
        {/* Left Column - NewQuote Component */}
        <div className="flex-1 border rounded-lg p-4 shadow-md bg-white">
          {console.log(ocrResults)}
          {ocrResults ? (
            <NewQuote
              quoteType={'General'}
              productQuantityTable={OCRProducts}
              ocrResults={ocrResults}
              categoryId={selectedProductCategory?.id}
            />
          ) : (
            <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-4 border-dotted border-red-500"></div>
          </div>
          )}
        </div>


        {/* Right Column */}
        <div className="flex-1 bg-white rounded-lg p-4 border shadow-md">
          <h3 className="text-xl font-semibold mb-4">Uploaded Requirement</h3>
          {resImage && resImage ? (
            <img
              src={ImageUrl + resImage}
              alt="Uploaded design"
              className="w-full h-84 object-contain rounded-lg"
            />
          ) : (
            <div className="h-64 flex items-center justify-center text-gray-400">
              No image uploaded yet
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialReq;
