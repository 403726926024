import React, { useState,useContext ,useEffect, useRef } from 'react';
import { json, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX ,faExclamationTriangle, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import OtherDetails from '../Components/OtherDetails';
import Address from '../Components/Address';
import ContactPersons from '../Components/ContactPersons';
import { Loader ,Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import {  useLocation } from 'react-router-dom'
  import TilesTemplate from '../Templates/TilesTemplate';
import EditTilesTemplate from '../Templates/EditTilesTemplate';
 import EditDoorsTemplate from '../Templates/EditDoorsTemplate';
import WindowsTemplate from '../Templates/WindowsTemplate';
import EditWindowsTemplate from '../Templates/EditWindowsTemplate';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



function EditTemplate() {

  const location = useLocation();
  const templateData = location.state?.templateData;
console.log(templateData)

  const navigate = useNavigate();
  const [showOtherDetails, setShowOtherDetails] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [showContactPersons, setShowContactPersons] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
const [popupType, setPopupType] = useState('');
const [errors, setErrors] = useState({ email:'', mobile_number: '',gstin: '', pan: '' });
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const fetchUrl2 = process.env.REACT_APP_FORE_APILINK;
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [sidebarClicked, setSidebarClicked] = useState(false);
    const [DoorsSelectedProducts , setDoorsSelectedProducts] = useState({})
    const [DoorsSelectedDefaultProducts , setDoorsSelectedDefaultProducts] = useState({})
  
  
  const [data, setData] = useState({
    mobile_number: '',
    email: '',
    customer_name: '',
    company_name: '',
    type_of_firm: '',
    gstin: '',
    pan: '',
    city: '',
    association: '',
    address: '',
    state:''
  });

  const [otherDetails, setOtherDetails] = useState({
    gstin: '',
    pan: '',
    city: '',
    state:''
  });

  const [address, setAddress] = useState({
    address: '',
    city: '',
    state: '',
    pincode: '',
  });

  const [contactPersons, setContactPersons] = useState([
    { name: '', email: '', mobile_number: '', designation: '' }
  ]);
  const [activeTab, setActiveTab] = useState('Tiles');
  const [selectedPackage, setSelectedPackage] = useState(null);
const [selectedVendor, setSelectedVendor] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);
  const [selectedPackageType, setSelectedPackageType] = useState(null);
  const packageTypes = [
    {
      id: 1,
      name: "Economy",
      description: "Basic package with essential features"
    },
    {
      id: 2, 
      name: "Value",
      description: "Mid-range package with balanced features"
    },
    {
      id: 3,
      name: "Luxury", 
      description: "Premium package with high-end features"
    }
  ];
  const vendorData = [
    {
      id: "679e2a772beb1f4a0ca778ff",
      name: "Vendor A",
      type: ["Tiles", "Windows"],
      image: "vendor_a.jpg",
      isDefault: true
    },
    {
      id: "678e1bdf3ce3c1c267977a1e",
      name: "Vendor B",
      type: ["Tiles"],
      image: "vendor_b.jpg",
      isDefault: false
    },
    {
      id: "679e2a772beb1f4a0ca7790f",
      name: "Vendor C",
      type: ["Windows"],
      image: "vendor_c.jpg",
      isDefault: false
    }
  ];
  
  // Add to your Template component
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);

  const [EdittemplateData, setEditTemplateData] = useState(null);
const [defaultVendor, setDefaultVendor] = useState(null);
const [selectedProductsByCategory, setSelectedProductsByCategory] = useState({});
const [defaultProducts, setDefaultProducts] = useState({});
//console.log("DP",defaultProducts);
const [selectedProducts, setSelectedProducts] = useState({});
const [addonVendorSelections, setAddonVendorSelections] = useState({});
const [subCategories, setSubCategories] = useState([]);

useEffect(() => {
    fetchProductCategories();
    fetchPackages();
  },[])

  
/*   useEffect(() => {
    if (templateData && productCategories.length > 0) {
      // Set initial category and package selections
      const category = productCategories.find(cat => cat._id === templateData.category_id);
      if (category) {
        setSelectedProductCategory({
          value: category._id,
          label: category.product_category_name
        });
      }

      setSelectedPackageType({
        value: templateData.package_id,
        label: templateData.package_name
      });

      if(category.product_category_name.toLowerCase() === 'doors'){
        const vendorids = []
        fetchProduct(category._id , vendorids);
      }

      // Fetch and set vendors
      const fetchVendors = async () => {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}vendor/get-vendorsBy-productCategoryId?product_category_id=${templateData.category_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const vendorData = await response.json();
        if (vendorData.success) {
          setVendors(vendorData.data);
          
          const existingVendors = templateData.vendors.map(vendor => ({
            value: vendor._id,
            label: vendorData.data.find(v => v._id === vendor._id)?.name || '',
            default: vendor.default
          }));
          setSelectedVendors(existingVendors);
          
          const defaultVendor = templateData.vendors.find(v => v.default);
          if (defaultVendor) {
            setDefaultVendor(defaultVendor._id);
          }
        }
      };
      fetchVendors();
    }



  }, [templateData, productCategories]); */


  useEffect(() => {
    const initializeTemplate = async () => {
      if (!templateData || !productCategories.length) return;
     
  
      try {
        // Set initial category
        const category = productCategories.find(cat => cat?._id === templateData?.category_id);
        if (category) {
          setSelectedProductCategory({
            value: category._id,
            label: category.product_category_name
          });
  
          // Fetch products if category is doors
          if (category.product_category_name?.toLowerCase() === 'doors') {
            const vendorIds = [];
            await fetchProduct(category._id, vendorIds);
          }
        }

        await fetchsubcategories(category._id);
  
        // Set package type
        if (templateData?.package_id && templateData?.package_name) {
          setSelectedPackageType({
            value: templateData.package_id,
            label: templateData.package_name
          });
        }
  
        // Fetch vendors
        const token = sessionStorage.getItem('token');
        if (!token || !templateData?.category_id) {
          return;
        }
  
        const response = await fetch(
          `${fetchUrl}vendor/get-vendorsBy-productCategoryId?product_category_id=${templateData.category_id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          }
        );
  
        const vendorData = await response.json();
        
        if (vendorData?.success) {
          setVendors(vendorData.data);
  
          const existingVendors = templateData.vendors?.map(vendor => ({
            value: vendor._id,
            label: vendorData.data.find(v => v._id === vendor._id)?.name || '',
            default: vendor.default
          }));
  
          if (existingVendors?.length) {
            setSelectedVendors(existingVendors);
            
            const defaultVendor = templateData.vendors.find(v => v.default);
            if (defaultVendor?._id) {
              setDefaultVendor(defaultVendor._id);
            }

            await fetchProduct(category._id, existingVendors);

          }
        }
      } catch (error) {
        console.error('Error initializing template:', error);
      }
    };
  
    initializeTemplate();
  }, [templateData, productCategories]);
  


  const renderTemplateContent = () => {
    if (!selectedPackage) return null;
  
    switch(activeTab) {
      
      
       case 'Tiles':
         return <EditTilesTemplate selectedPackage={selectedPackage} onVendorSelect={setSelectedVendor} selectedVendor={selectedVendor} />;
       case 'Doors':
        return <EditDoorsTemplate selectedPackage={selectedPackage} onVendorSelect={setSelectedVendor} selectedVendor={selectedVendor} />;
      case 'Windows':
        return <EditWindowsTemplate selectedPackage={selectedPackage} onVendorSelect={setSelectedVendor} selectedVendor={selectedVendor} />;
      default:
        return null;
    }
  };
 

  

  const fetchProductCategories = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      
      //console.log(data.data)
      if (data.data) {
        setProductCategories(data.data); // Update productCategories state
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
       
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }/* finally {
      setIsLoading(false);
    } */
  };


  const handleProductCategorySelect = async (option) => {
    if(option.label.toLowerCase() === 'doors'){
      const vendorids = []
      fetchProduct(option.value , vendorids);
    }
    fetchsubcategories(option.value);
    setSelectedProductCategory(option);
    setSelectedPackageType(null); // Reset package selection when category changes
   // Fetch vendors when category is selected
   try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}vendor/get-vendorsBy-productCategoryId?product_category_id=${option.value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    //console.log(data.data);
    if (data.success) {
      const filteredVendors = data.data.filter(vendor => 
        option.label === 'Windows' ? vendor.name.toLowerCase() !== 'any vendor' : true
      );
      setVendors(filteredVendors);
    }
  } catch (error) {
    console.error('Failed to fetch vendors:', error);
  }
 
 };
 

 const fetchProductGroups = async (categoryId, vendorIds) => {
  try {
    const token = sessionStorage.getItem('token');
    const vendorIdsArray = Array.isArray(vendorIds) ? vendorIds : [vendorIds];
    const vendorIdsString = JSON.stringify(vendorIdsArray.map(vendor => vendor.value));
   // console.log(vendorIdsArray)
    //console.log(vendorIdsString)

    const response = await fetch(`${fetchUrl}product-groups/get-productGroups-by-vendors-and-productCategory?product_category_id=${categoryId}&vendor_ids=${vendorIdsString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
  });

    const data = await response.json();
    //console.log('Product groups response:', data);
    if (data.success) {
      setProductGroups(data.data);
    }
  } catch (error) {
    console.error('Error fetching products:', error);
    setPopupMessage('Internal Server Error. Kindly Retry.');
    setPopupType('500');
    setShowPopup(true);
  }
};




const fetchProduct = async (categoryId, vendorIds) => {
  try {
    const token = sessionStorage.getItem('token');
    const vendorIdsArray = vendorIds.map(vendor => vendor.value);
    const vendorIdsString = JSON.stringify(vendorIdsArray);
   // console.log(vendorIdsString);
    //console.log(vendorIdsArray);

    const response = await fetch(`${fetchUrl}product/get-products-by-category-and-vendors?product_category_id=${categoryId}&vendor_ids=${vendorIdsString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    //console.log(data.data);
    if (data.success) {
      if (selectedProductCategory?.label === 'Windows') {
        const groupedProducts = data.data.reduce((acc, product) => {
          const subCategoryId = product.product_sub_category_id;
          if (!acc[subCategoryId]) {
            acc[subCategoryId] = [];
          }
          acc[subCategoryId].push(product);
          return acc;
        }, {});
        console.log('Grouped Windows Products:', groupedProducts);
        setProducts(groupedProducts);
      } else {
        console.log('Products:', data.data);
        setProducts(data.data);
      }
    }
  } catch (error) {
    console.error('Error fetching products:', error);
    setPopupMessage('Internal Server Error. Kindly Retry.');
    setPopupType('500');
    setShowPopup(true);
  }
};


const fetchsubcategories = async (categoryId) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${categoryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    console.log(data.data);
    if (data.success) {
     
      // Store subcategories data in state
      setSubCategories(data.data);
    }
  } catch (error) {
    console.error('Error fetching subcategories:', error);
    setPopupMessage('Internal Server Error. Kindly Retry.');
    setPopupType('500');
    setShowPopup(true);
  }
};


const fetchPackages = async () => {


  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl2}/summary/get-packages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    //console.log(data.data);
    if (data.success) {
      setPackages(data.data);
    }
  } catch (error) {
    console.error('Failed to fetch packages:', error);
  }
};


const handleSaveTemplate = async () => {
  const category = selectedProductCategory.label;

  if (selectedProductCategory?.label === 'Tiles') {
    let missingSelections = [];
    packages?.roomCategories?.[category]?.forEach(roomCategory => {
      // Check both new selections and existing template data
      const hasNewProducts = selectedProducts[roomCategory]?.length > 0;
      const hasNewDefaultProduct = defaultProducts[roomCategory];
      
      // Check existing template data
      const existingCategory = templateData?.room_categories?.find(rc => rc.room_category === roomCategory);
      const hasExistingProducts = existingCategory?.products?.length > 0;
      const hasExistingDefault = existingCategory?.products?.some(p => p.default);
  
      // Check if either new or existing selections are complete
      const hasCompleteSelection = 
        (hasNewProducts && hasNewDefaultProduct) || 
        (hasExistingProducts && hasExistingDefault);
  
      if (!hasCompleteSelection) {
        missingSelections.push(`${roomCategory}`);
      }
    });
  
    if (missingSelections.length > 0) {
      setPopupMessage(
        <span>
          <span className="font-semi-bold text-black">Please select products and default products for : </span>
          <span className="text-[#EA011F]">{missingSelections.join(', ')}</span>
        </span>
      );
      
      setPopupType('400');
      setShowPopup(true);
      return;
    }
  }
  
  

  if (selectedProductCategory?.label === 'Windows') {
    for (const category of packages?.roomCategories?.Windows || []) {
      const selectedCategoryProducts = selectedProducts[category] || [];
      const existingMapping = templateData?.product_mapping?.find(
        mapping => mapping.category === category
      );
  
      // Get all products (both selected and existing)
      const allProducts = [...selectedCategoryProducts, ...(existingMapping?.products || [])];
      const uniqueProducts = Array.from(new Set(allProducts.map(p => p._id))).map(id => 
        allProducts.find(p => p._id === id)
      );
  
      // Check if products exist for this category
      if (uniqueProducts.length === 0) {
        setPopupMessage(
          <span>
            <span className="font-semi-bold text-black">Please select products for </span>
            <span className="text-[#EA011F]">{category}</span>
          </span>
        );
        setPopupType('400');
        setShowPopup(true);
        return;
      }
  
      // Check defaults for each vendor
      const vendorsWithProducts = selectedVendors.filter(vendor =>
        uniqueProducts.some(product => product.vendor_id === vendor.value)
      );
  
      const hasAllDefaults = vendorsWithProducts.every(vendor => {
        const vendorProducts = uniqueProducts.filter(
          product => product.vendor_id === vendor.value
        );
        return vendorProducts.some(product => 
          defaultProducts[category]?.split(',').includes(product._id) ||
          product.default
        );
      });
  
      if (!hasAllDefaults) {
        setPopupMessage(
          <span>
            <span className="font-semi-bold text-black">Please select default product for </span>
            <span className="text-[#EA011F]">{category}</span>
          </span>
        );
        setPopupType('400');
        setShowPopup(true);
        return;
      }
    }
  }
  
  
  

  if (selectedProductCategory?.label === 'Doors') {
    let missingPreEngineered = [];
    let missingOnlyDoors = [];
    let missingPreEngineeredDefaults = [];
    let missingOnlyDoorsDefaults = [];
    
    // Check if products exist for each type
    const hasPreEngProducts = products?.preEngineeredDoors?.length > 0;
    const hasOnlyDoorsProducts = products?.onlyDoors?.length > 0;
  
    packages?.roomCategories?.Doors.forEach(room => {
      // Only validate pre-engineered doors if products exist
      if (hasPreEngProducts) {
        const hasPreEngSelected = DoorsSelectedProducts[room]?.preEngineeredDoors?.length > 0;
        const hasPreEngDefault = DoorsSelectedDefaultProducts[room]?.preEngineeredDoors;
        
        if (!hasPreEngSelected) {
          missingPreEngineered.push(room);
        }
        if (hasPreEngSelected && !hasPreEngDefault) {
          missingPreEngineeredDefaults.push(room);
        }
      }
  
      // Only validate only doors if products exist
      if (hasOnlyDoorsProducts) {
        const hasOnlyDoorSelected = DoorsSelectedProducts[room]?.onlyDoors?.length > 0;
        const hasOnlyDoorsDefault = DoorsSelectedDefaultProducts[room]?.onlyDoors;
        
        if (!hasOnlyDoorSelected) {
          missingOnlyDoors.push(room);
        }
        if (hasOnlyDoorSelected && !hasOnlyDoorsDefault) {
          missingOnlyDoorsDefaults.push(room);
        }
      }
    });
  
    if (missingPreEngineered.length > 0 || missingOnlyDoors.length > 0 || 
        missingPreEngineeredDefaults.length > 0 || missingOnlyDoorsDefaults.length > 0) {
      setPopupMessage(
        <div className="flex flex-col gap-3">
          {hasPreEngProducts && missingPreEngineered.length > 0 && (
            <div>
              <span className="font-semibold text-black">Pre-engineered Doors selection missing in: </span>
              <span className="text-[#EA011F]">{missingPreEngineered.join(', ')}</span>
            </div>
          )}
          {hasPreEngProducts && missingPreEngineeredDefaults.length > 0 && (
            <div>
              <span className="font-semibold text-black">Pre-engineered Doors default selection missing in: </span>
              <span className="text-[#EA011F]">{missingPreEngineeredDefaults.join(', ')}</span>
            </div>
          )}
          {hasOnlyDoorsProducts && missingOnlyDoors.length > 0 && (
            <div>
              <span className="font-semibold text-black">Only Doors selection missing in: </span>
              <span className="text-[#EA011F]">{missingOnlyDoors.join(', ')}</span>
            </div>
          )}
          {hasOnlyDoorsProducts && missingOnlyDoorsDefaults.length > 0 && (
            <div>
              <span className="font-semibold text-black">Only Doors default selection missing in: </span>
              <span className="text-[#EA011F]">{missingOnlyDoorsDefaults.join(', ')}</span>
            </div>
          )}
        </div>
      );
      setPopupType('400');
      setShowPopup(true);
      return;
    }
  }


  
  let packageData = {
    package_id: selectedPackageType.value,
    package_name: selectedPackageType.label,
    category_id: selectedProductCategory.value, // Add category_id
    vendors: selectedVendors.map(vendor => ({
      _id: vendor.value,
      name: vendor.label,
      default: vendor.value === defaultVendor
    }))
  };

  // Add door-specific data if category is Doors
  if (category === 'Doors') {
    packageData = buildDoorsPackageData(category, packageData);
    console.log(packageData);
  } else {
    // Existing logic for other categories
    
    if (category === 'Windows') {
      packageData.room_categories = packages?.roomCategories?.[category]?.map(roomCategory => {
        const defaultProductIds = defaultProducts[roomCategory]?.split(',') || [];
        const existingMapping = templateData?.product_mapping?.find(
          mapping => mapping.category === roomCategory
        );
    
        return {
          room_category: roomCategory,
          products: (selectedProducts[roomCategory] || existingMapping?.products || []).map(product => ({
            _id: product._id,
            name: product.product_name || product.name,
            default: defaultProductIds.includes(product._id) || product.default || false
          }))
        };
      });
    }
    
    
    
    
  
   else {
        // Existing Tiles logic
        packageData.room_categories = packages?.roomCategories?.[category]?.map(category => {
          const defaultProductIds = defaultProducts[category]?.split(',') || [];
          
          if (selectedProducts[category]) {
            return {
              room_category: category,
              products: selectedProducts[category].map(product => ({
                _id: product._id,
                name: product.product_group_name,
                default: defaultProductIds.includes(product._id)
              }))
            };
          }
          
          const existingCategory = templateData?.room_categories?.find(rc => rc.room_category === category);
          return {
            room_category: category,
            products: existingCategory?.products || []
          };
        });
      }
    }
  
  
  console.log(packageData);

  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl2}/summary/quotation-template`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify({
        category: selectedProductCategory.label,
        packageData
      })
    });

    const data = await response.json();
    //console.log(data);
    if (data.success) {
      setPopupMessage('Template saved successfully');
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/templates/templatelist');
      }, 3000);
    }
  } catch (error) {
    console.error('Error saving template:', error);
    setPopupMessage('Failed to save template');
    setPopupType('500');
    setShowPopup(true);
  }
};

const buildDoorsPackageData = (category, packageData) => {
  try {
    // Validate addon vendor selections
    const defaultAddonProuctsByVendor = {
      "flush door": addonVendorSelections?.["flush door"]?.value || null,
      "veneer": addonVendorSelections?.["veneer"]?.value || null,
      "laminate": addonVendorSelections?.["laminate"]?.value || null
    };

    // Validate and map room categories
    const roomCategories = Array.isArray(packages?.roomCategories?.[category]) 
      ? packages.roomCategories[category].map(room_category => {
          if (!room_category) return null;

          const doorProducts = {
            "pre eng doors": Array.isArray(DoorsSelectedProducts?.[room_category]?.preEngineeredDoors)
              ? DoorsSelectedProducts[room_category].preEngineeredDoors.map(product => ({
                  _id: product?._id || null,
                  default: DoorsSelectedDefaultProducts?.[room_category]?.['preEngineeredDoors'] === product?._id
                })).filter(product => product._id)
              : [],

            "only doors": Array.isArray(DoorsSelectedProducts?.[room_category]?.onlyDoors)
              ? DoorsSelectedProducts[room_category].onlyDoors.map(product => ({
                  _id: product?._id || null,
                  default: DoorsSelectedDefaultProducts?.[room_category]?.['onlyDoors'] === product?._id
                })).filter(product => product._id)
              : []
          };

          return {
            room_category: room_category,
            products: doorProducts
          };
        }).filter(Boolean)
      : [];

    return {
      ...packageData,
      defaultAddonProuctsByVendor,
      room_categories: roomCategories
    };
  } catch (error) {
    console.error('Error building package data:', error);
    return {
      ...packageData,
      defaultAddonProuctsByVendor: {},
      room_categories: []
    };
  }
};


  
  const handlePackageSelect = (option) => {
    setSelectedPackageType(option);
    setSelectedPackage(packageTypes.find(pkg => pkg.package_id === option.value));
  };
  

  const handleVendorSelect = (vendors) => {
    setSelectedVendors(vendors);
    if (selectedProductCategory && vendors.length > 0) {
      // Fetch product groups for each selected vendor
      vendors.forEach(vendor => {
        fetchProductGroups(selectedProductCategory.value, vendor.value);
      });
    }
  };

  const packageTypeOptions = packages?.packageTypes ? 
  Object.entries(packages.packageTypes).map(([name, value]) => ({
    value: value,
    label: name
  })) : [];



  if (isLoading) {
    return <Loader />;
  }

  const handleCloseError = () => {
    setErrorMessages([]);
  };

  const closePopup = () => {

    setShowPopup(false);
  };
  
  const Retry = async () => {

    setIsLoading(true); // Show loader
    setTimeout(async () => {
      if (retryFunction) {
        await retryFunction(); // Call retry function
      }
      setIsLoading(false); // Hide loader after retry
    }, 5000); // 5-second delay
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {

    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleCancel = () =>  {
      setShowCancelPopup(true)

  };
  const handleStayHere = () => {
    setShowCancelPopup(false);

  };
  
  const handleLeave = () => {
    setShowCancelPopup(false);  
    navigate(-1);
    
  };

  //console.log(packages)
    return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Edit Template</h1>
                </div>
              </div>
              {errorMessages.length > 0 && (
                <div className="flex justify-between items-center  px-auto py-3  bg-[#fddcde]  rounded-md">
                  <div className="px-6 flex flex-col justify-start items-start">
                    {errorMessages.map((errorMessage, index) => (
                      <span key={index} className="text-sm">
                         •   {errorMessage}
                      </span>
                    ))}
                  </div>
                  <div className='px-4'>
                  <button
                    className="text-gray-700 hover:text-red-500"
                    onClick={handleCloseError}
                  >
                    <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
                  </button>
                  </div>
                </div>
              )}
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4">
                  {/* Customer Name Label and Input */}
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
  <label htmlFor="customerName" className="block text-sm font-regular text-[#e54643] w-1/6">
    Product Category Name<span className="text-red-500">*</span>
  </label>
  <div className='w-1/3'>
    <CustomDropdown
      options={productCategories.map(category => ({
        value: category._id,
        label: category.product_category_name
      }))}
      selected={selectedProductCategory}
      onSelect={handleProductCategorySelect}
      placeholder="Select Product Category"
      showSearch={true}
    />
  </div>
</div>

                  {/* Company Name Label and Input */}
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
  <label htmlFor="packageType" className="block text-sm font-regular text-gray-700 w-1/6">
    Package Type
  </label>
  <div className='w-1/3'>
  <CustomDropdown
  options={packageTypeOptions}
  selected={selectedPackageType}
  onSelect={handlePackageSelect}
  placeholder="Select Package Type"
/>
  </div>
</div>


                  {/* Company Type Dropdown */}
                 

                  {/* Association Dropdown */}
                 
                  {selectedProductCategory?.label === 'Tiles' && selectedPackageType && (
  <EditTilesTemplate 
    selectedPackage={templateData}
    vendors={vendors || []}
    productGroups={productGroups}
    packages={packages}
    onVendorsSelect={(vendors) => {
      setSelectedVendors(vendors);
      if (vendors.length > 0) {
        fetchProductGroups(selectedProductCategory.value, vendors);
      }
    }}
    onDefaultVendorSelect={setDefaultVendor}
    onProductSelect={(category, products) => {
      const productsWithDetails = products.map(product => ({
        ...product,
        product_group_name: productGroups.find(pg => pg._id === product._id)?.product_group_name
      }));
      setSelectedProducts(prev => ({
        ...prev,
        [category]: productsWithDetails
      }));
    }}
    onDefaultProductSelect={(category, productId) => {
      setDefaultProducts(prev => ({
        ...prev,
        [category]: productId
      }));
    }}
  
  />
)}



{selectedProductCategory?.label === 'Windows' && selectedPackageType && (
  <>
 {console.log('All Subcategories:', subCategories)}
 {console.log('Products:', selectedProducts)}
  <EditWindowsTemplate 
    selectedPackage={templateData}
    vendors={vendors || []}
    packages={packages} 
    products={products}
    subCategories={subCategories}
    onVendorsSelect={(selectedVendors) => {
      setSelectedVendors(selectedVendors);
      if (selectedVendors.length > 0) {
        fetchProduct(selectedProductCategory.value, selectedVendors);
      }
    }}
    onDefaultVendorSelect={setDefaultVendor}
    onProductSelect={(category, products) => {
      console.log('onProductSelect called with:', {category, products});
      setSelectedProducts(prev => ({
        ...prev,
        [category]: products
      }));
    }}
    onDefaultProductSelect={(category, productId) => {
      setDefaultProducts(prev => ({
        ...prev,
        [category]: productId
      }));
    }}
  />
  </>
)}




{selectedProductCategory?.label === 'Doors' && selectedPackageType && vendors.length>0 &&(
  <EditDoorsTemplate
  selectedPackage={templateData} // Pass the full template data
  vendors={vendors || []}
    packages={packages}
    roomCategories={{
      Doors: packages?.roomCategories?.Doors || [],
      DoorSubcategoryAddons: packages?.roomCategories?.DoorSubcategoryAddons || []
    }}
    products={products || []}
    onVendorsSelect={(selectedVendors) => {
      setSelectedVendors(selectedVendors);
      if (selectedVendors.length > 0) {
        fetchProduct(selectedProductCategory.value, selectedVendors);
      }
    }}
    setSelectedProductsData ={setDoorsSelectedProducts}
    onDefaultProductSelectData= {setDoorsSelectedDefaultProducts}
    onAddonVendorSelect={setAddonVendorSelections}
    templateData = {templateData}
  />
 
)}




   
               {selectedPackage && renderTemplateContent()}

                </div>
                </div>
                <div className="mt-5 w-full">
                  <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                  <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                    <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center" 
                    onClick={handleSaveTemplate}>
                      <span>Save</span>
                      {isSaving && <Spinner />}
                    </button>
                    <button  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}
         onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>

      {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
    </div>
  );
}

export default EditTemplate;
