import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTh,
  faChevronDown,
  faChevronUp,
  faRuler,
  faBoxOpen,
  faArrowLeft 
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
/* 
  const tilesData = {
    quotation_number: "QT-000048",
    items: [
      {
        item_name: "Kajaria Floor Tiles 600x600mm",
        quantity: 500,
        selling_price: 85,
        tax_rate: 18,
        subtotal: 42500,
        area_types: {
          area_name: "Living Room Floor",
          area: "500",
          boxes: "42"
        },
        applied_areas_breakup: [
          {
            room_name: "Living Room",
            length: 20,
            width: 25,
            area: 500
          },
          {
            room_name: "Dining Room",
            length: 15,
            width: 20,
            area: 300
          }
        ]
      },
      {
        item_name: "Somany Wall Tiles 300x600mm",
        quantity: 300,
        selling_price: 75,
        tax_rate: 18,
        subtotal: 22500,
        area_types: {
          area_name: "Kitchen Wall",
          area: "300",
          boxes: "25"
        },
        applied_areas_breakup: [
          {
            room_name: "Kitchen",
            length: 15,
            width: 10,
            area: 150
          }
        ]
      }
    ]
  }; */

  const TilesQuotationSummary = () => {
    const [expandedItems, setExpandedItems] = useState({});
    const navigate = useNavigate();
    const location = useLocation()
        const {quotation_id, product_category_id , project_id , package_id} = location.state || {}
   /*  const  product_category_id = '66fa554b8d86946ffaf4c868'
    const project_id = '678e51cb656185900e5eccbd'
    const package_id = 1
    const quotation_id  = '' */
    const [tilesData, setTilesData] = useState({});
    const fetchUrl = process.env.REACT_APP_FORE_APILINK;
  
    const toggleItem = (index) => {
      setExpandedItems(prev => ({
        ...prev,
        [index]: !prev[index]
      }));
    };
  
    // Header Component
    const renderQuotationHeader = () => {
  const quotationReference = tilesData?.referance || tilesData?.quotation_number || 'QT-000000';
  const quoteType = tilesData?.quote_type || 'Standard Quote';
  const productTitle = tilesData?.product_category_name || 'Tiles';

      return (
        <div className="px-6 py-4 bg-white border-b border-gray-100">   
          <div className="flex items-center justify-between">
          <button
          onClick={() => navigate(-1)}
          className="w-8 h-8 flex items-center justify-center hover:bg-red-50 rounded-full transition-all duration-200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-gray-700 text-lg" />
        </button>    
            <div className="flex items-center gap-4">
              <div className="w-12 h-12 bg-gradient-to-br from-red-50 to-white rounded-xl flex items-center justify-center shadow-sm">
                <FontAwesomeIcon icon={faTh} className="text-[#EA011F] text-xl" />
              </div>
              <div>
                <h1 className="text-xl font-bold text-gray-800">{productTitle}</h1>
                <span className="text-sm text-gray-500"></span>
              </div>
            </div>
            <div className="text-right flex flex-col space-y-1">
              <div className="inline-block bg-red-50 px-2 py-1 rounded-full">
                <h2 className="text-sm font-medium text-[#EA011F]">{quoteType}</h2>
              </div>
              <div className="inline-block  px-2  ">
              <p className="text-sm text-gray-600 mt-1">
                Ref: <span className="font-medium">{quotationReference}</span> 
              </p>
              </div>
            </div>
          </div>
        </div>
      );
    };
  
    // Table Header Component
    const tableHeader = () => {
      return (
        <div className="sticky top-0 z-10 bg-gray-50">
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Item Details
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
              </tr>
            </thead>
          </table>
        </div>
      );
    };
  
    // Price Footer Component
    const PriceFooter = () => {
      // Extract values with fallbacks and formatting
      const formatPrice = (value) => {
        return Number(value || 0).toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        });
      };
    
      const total = formatPrice(tilesData?.total);
      const subtotal = formatPrice(tilesData?.subtotal);
      const taxAmount = formatPrice(tilesData?.tax_amount);
    
      const handleInterestClick = () => {
        try {
          // Add your interest click handler logic here
          console.log('Interest clicked');
        } catch (error) {
          console.log('Interest action failed:', error);
        }
      };
    
      return (
        <div className="sticky bottom-0 bg-white shadow-lg border-t border-gray-100">
          <div className="max-w-md mx-auto px-4 py-3">
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <div className="flex items-baseline gap-1">
                  <span className="text-lg font-bold text-[#EA011F]">
                    ₹{total}
                  </span>
                </div>
                <div className="flex items-center text-xs text-gray-400">
                  ₹{subtotal} + ₹{taxAmount} tax
                </div>
              </div>
    
              <button 
                onClick={handleInterestClick}
                className="group flex items-center gap-3 bg-gradient-to-r from-red-50 to-white border-2 border-[#EA011F] text-[#EA011F] px-4 py-3 rounded-md transition-all duration-300 hover:bg-[#EA011F] hover:text-white hover:shadow-lg hover:shadow-red-100 active:scale-[0.98]"
              >
                <span className="font-semibold text-sm tracking-wide">I'm Interested</span>
                <div className="relative">
                  <svg
                    className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 group-hover:opacity-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                  </svg>
                  <svg
                    className="w-4 h-4 absolute top-0 left-0 transition-all duration-300 -translate-x-3 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      );
    };
    
    
    const fetchQuotationSummary = async () => {
      const queryParams = new URLSearchParams({
        product_category_id,
        project_id,
        package_id,
        quotation_id
      }).toString();
    
      try {
        const response = await fetch(`${fetchUrl}/summary/get-quote-summary?${queryParams}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
          let errorMessage = 'Failed to fetch quotation summary';
          
          switch (response.status) {
            case 400:
              errorMessage = 'Invalid query parameters';
              break;
            case 404:
              errorMessage = 'Quotation not found';
              break;
            case 500:
              errorMessage = 'Server error occurred';
              break;
            default:
              const data = await response.json();
              errorMessage = data.message || errorMessage;
          }
          throw new Error(errorMessage);
        }
    
        const data = await response.json();
        console.log('data' , data)
        setTilesData(data);
    
      } catch (error) {
        console.error('Error fetching quotation:', error.message);
      }
    };
    
    useEffect(() => {
      fetchQuotationSummary();
    }, [product_category_id, project_id, package_id, /* quotation_id */]);
    
    
    

    // Items Table Component
    const ItemsTable = () => {
      // Early return if tilesData or items is not available
      if (!tilesData?.items?.length) {
        return (
          <div className="px-4 py-2 text-center text-gray-500">
            No items available
          </div>
        );
      }
    
      return (
        <div className="px-4 py-2 space-y-6">
          {tilesData.items.map((item, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="p-4">
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <h3 className="text-base font-semibold text-gray-800">{item?.item_name || 'Unnamed Item'}</h3>
                    <div className="mt-2 pl-3 border-l-2 border-red-100">
                      <div className="text-sm text-gray-600">
                        ₹{item?.selling_price || 0} × {item?.quantity || 0} {item.UOM}
                      </div>
                      <div className="text-xs text-gray-500 mt-1">
                        Including {item?.tax_rate || 0}% GST
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-lg font-bold text-[#EA011F]">
                      ₹{(item?.total || 0).toLocaleString()}
                    </div>
                    {/* <div className="text-xs text-gray-500 mt-1">
                      Tax: ₹{item.total_tax_amount || 0}
                    </div> */}
                  </div>
                </div>
    
                {/* Room Details Section */}
                <div className="mt-3">
                  <button
                    onClick={() => toggleItem(index)}
                    className="w-full px-3 py-2 bg-gray-50 rounded-lg flex items-center justify-between hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center">
                        <FontAwesomeIcon icon={faRuler} className="text-[#EA011F] text-xs" />
                      </div>
                      <span className="text-sm font-medium text-gray-700">
                        Room Details {/* ({item?.area_types?.boxes || 0} boxes) */}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      {/* <span className="text-xs text-gray-500">{item?.area_types?.area || 0} sq.ft</span> */}
                      <FontAwesomeIcon
                        icon={expandedItems[index] ? faChevronUp : faChevronDown}
                        className="text-gray-400 text-xs"
                      />
                    </div>
                  </button>
    
                  {expandedItems[index] && item?.applied_areas_breakup?.length > 0 && (
                    <div className="mt-2 pl-8">
                      {item.applied_areas_breakup.map((room, roomIndex) => (
                        <div key={roomIndex} className="py-2 border-b border-gray-100 last:border-0">
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-700">{room?.area_name || 'Unnamed Room'}</span>
                            <span className="text-sm font-medium text-[#EA011F]">{room?.area || 0} sq.ft</span>
                          </div>
                          {/* <div className="text-xs text-gray-500 mt-0.5">
                            {room?.height || 0}' × {room?.width || 0}'
                          </div> */}
                        </div>
                      ))}
                      {/* <div className="flex items-center gap-2 mt-2 text-xs text-gray-500">
                        <FontAwesomeIcon icon={faBoxOpen} className="text-[#EA011F]" />
                        <span>Total {item?.area_types?.boxes || 0} boxes required</span>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    };
    
  
    return (
      <div className="bg-gray-50 flex flex-col min-h-screen">
        {/* Sticky Header */}
        <div className="sticky top-0 z-10 bg-white shadow-sm">
          {renderQuotationHeader()}
          {tableHeader()}
        </div>
        
        {/* Main Content */}
        <div className="flex-1">
          <ItemsTable />
        </div>
        
        {/* Footer */}
        <PriceFooter />
      </div>
    );
    
    
  };
  
  export default TilesQuotationSummary;
  

 