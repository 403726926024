import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileText, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';

const QuotationPDFModal = ({ isOpen, onClose, quotationData }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const renderTableHeader = () => (
    <div className="flex flex-row bg-gray-100 p-3 border-b border-gray-200">
      <div className="flex-[2] font-bold text-gray-700">Description</div>
      <div className="flex-1 font-bold text-gray-700 text-center">Area (sq.ft)</div>
      <div className="flex-1 font-bold text-gray-700 text-right">Rate</div>
      <div className="flex-1 font-bold text-gray-700 text-right">Amount</div>
    </div>
  );

  const renderProductRow = (product) => (
    <div className="border-b border-gray-200">
      <div className="flex flex-row p-4 bg-white items-center">
        <div className="w-1/2">
          <div className="font-medium text-gray-800">{product.item_name}</div>
          <div className="text-sm text-gray-600 mt-1">{product.category_name}</div>
        </div>
        <div className="w-[15%] text-center">{product.total_area.toFixed(2)}</div>
        <div className="w-[15%] text-right">₹{product.base_price}</div>
        <div className="w-[20%] text-right font-medium">
          ₹{(product.base_price * product.total_area).toFixed(2)}
        </div>
      </div>

      {Object.keys(product.doorAddOns).length > 0 && (
        <div className="w-full bg-gray-50 p-4 border-t border-gray-100">
          <div className="font-medium text-gray-700 mb-2">Add-ons</div>
          <div className="flex flex-wrap">
            {Object.entries(product.doorAddOns).map(([category, addons], index) => (
              <div key={index} className="w-full mb-2">
                <div className="text-sm font-semibold text-gray-800 mb-1">{category}</div>
                <div className="flex flex-wrap">
                  {addons.map((addon) => (
                    <div key={addon.id} className="w-1/3 pr-4 mb-2">
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-700">{addon.item_name}</span>
                        <span className="text-sm text-[#EA011F] font-medium">₹{addon.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="w-full bg-gray-50 p-4 border-t border-gray-100">
        <div className="font-medium text-gray-700 mb-2">Hardware</div>
        <div className="flex flex-wrap">
          {Object.entries(product.doorHardware).map(([category, hardwares], index) => (
            <div key={index} className="w-full mb-2">
              <div className="text-sm font-semibold text-gray-800 mb-1">{category}</div>
              <div className="flex flex-wrap">
                {hardwares.map((hardware) => (
                  <div key={hardware.id} className="w-1/3 pr-4 mb-2">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-700">{hardware.item_name}</span>
                      <span className="text-sm text-[#EA011F] font-medium">₹{hardware.price}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full bg-gray-50 p-4 border-t border-gray-100">
        <div className="font-medium text-gray-700 mb-2">Applied Doors</div>
        <div className="flex flex-wrap">
          {product.applied_doors.map((door, index) => (
            <div key={index} className="w-1/3 pr-4 mb-2">
              <div className="text-sm text-gray-700">{door.door_name}</div>
              <div className="text-xs text-gray-500">{door.room_name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center">
      <div className="bg-white w-full max-w-4xl h-[90vh] rounded-lg flex flex-col">
        <div className="bg-white p-4 flex justify-between items-center border-b border-gray-200">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faFileText} className="text-[#EA011F] mr-3 text-2xl" />
            <span className="text-xl font-bold text-[#EA011F]">Quotation</span>
          </div>
          <div className="flex items-center">
            <button className="mr-4">
              <FontAwesomeIcon icon={faDownload} className="text-gray-600" />
            </button>
            <button onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} className="text-gray-600 text-xl" />
            </button>
          </div>
        </div>

        <div className="bg-gray-50 p-4 border-b border-gray-200">
          <div className="text-sm text-gray-600">Package ID: {quotationData.product_category_id}</div>
          <div className="text-sm text-gray-600 mt-1">Date: {new Date().toLocaleDateString()}</div>
        </div>

        <div className="flex-1 overflow-auto">
          {renderTableHeader()}
          {quotationData.products.map((product, index) => renderProductRow(product))}

          <div className="p-4 bg-white border-t border-gray-200">
            <div className="flex justify-between mb-2">
              <span className="text-gray-600">Sub Total:</span>
              <span className="font-medium">₹{quotationData.sub_total}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-gray-600">{quotationData.tax_type}:</span>
              <span className="font-medium">₹{quotationData.total_tax}</span>
            </div>
            <div className="flex justify-between pt-2 border-t border-gray-200">
              <span className="font-bold text-gray-800">Total Amount:</span>
              <span className="font-bold text-[#EA011F]">
                ₹{quotationData.sub_total + quotationData.total_tax}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationPDFModal;
