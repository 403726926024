import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown, faX , faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader, Spinner} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import Search from '../Components/Search';

const PaymentReceivedList = () => {
  const navigate = useNavigate();
 const [productCategories, setProductCategories] = useState([]);
 const [searchQuery, setSearchQuery] = useState('');
 const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);

    const [salesOrders, setSalesOrders] = useState([]);
    //console.log(salesOrders);
  const [isLoading, setIsLoading] = useState(true);
  const url = process.env.REACT_APP_FORE_BRICKBUCKET;
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  //const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
 // const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
  const [SOItemQty, setSOItemQty] = useState(false);
  const [ReceivableQty, setReceivableQty] = useState(false);
  const [PendingQty, setPendingQty] = useState(false);
  const [createPOButtonEnabled, setCreatePOButtonEnabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState(null);
const [showCancelPopup , setShowCancelPopup] = useState(false);
const [showSOItemStatusPopup ,setShowSOItemStatusPopup] = useState(false);
const [orderItemsDataOfSO,setOrderItemsDataOfSO] = useState([]);
const [roleId, setRoleId] = useState(null);
const [filteredSO, setFilteredSO] = useState([]);
const searchTypes = ['SO Number', 'Customers', 'Item Category', 'SO Status'];
const [payments, setPayments] = useState([]);

const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;


  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

  // Function to get nested value using key
  const getNestedValue = (item, key) => {
    return key.split('.').reduce((value, keyPart) => {
      return value ? value[keyPart] : undefined;
    }, item);
  };

  const sortedArray = [...salesOrders].sort((a, b) => {
    const aValue = getNestedValue(a, key) || ''; // Treat undefined as empty string
    const bValue = getNestedValue(b, key) || ''; // Treat undefined as empty string

    // Convert to string for comparison and handle case insensitivity
    const aString = aValue.toString().toLowerCase();
    const bString = bValue.toString().toLowerCase();

 

    if (aString < bString) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (aString > bString) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  setSalesOrders(sortedArray);
};

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

 /*  const handleCheckboxChange = (salesOrder) => {
    
  
    // Check if the combination is already selected
    const isAlreadySelected = selectedCategories.some(
      (selected) =>
        selected.salesOrderId === salesOrder.salesorder_id._id &&
        selected.itemId === salesOrder.item_id &&
        selected.productCategoryId === salesOrder.salesorder_id.product_category_id._id && selected.vendorId === salesOrder.vendor_id &&
        selected.salesOrderType === 'converted'
    );
  
    // Update the selected categories
    const updatedSelectedCategories = isAlreadySelected
      ? selectedCategories.filter(
          (selected) =>
            !(selected.salesOrderId === salesOrder.salesorder_id._id &&
              selected.itemId === salesOrder.item_id &&
              selected.productCategoryId === salesOrder.salesorder_id.product_category_id._id && selected.vendorId === salesOrder.vendor_id,
              selected.salesOrderType === 'converted')
        )
      : [
          ...selectedCategories,
          {
            salesOrderId: salesOrder.salesorder_id._id,
            itemId: salesOrder.item_id,
            productCategoryId: salesOrder.salesorder_id.product_category_id._id, // Add productCategoryId here
            vendorId: salesOrder.vendor_id,
            salesOrderType: 'converted' 

          }
        ];
  
    // Update the state
    setSelectedCategories(updatedSelectedCategories);
  
    // Update the selectAll state if all items are selected
    setSelectAll(updatedSelectedCategories.length === salesOrders.length);
  
    // Enable the Create Purchase Order button if at least one item is selected
    setCreatePOButtonEnabled(updatedSelectedCategories.length > 0);
  }; */

  useEffect(() => {
    handleSearch();
  }, [salesOrders]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredSO(salesOrders);
      return;
    }
  
    const filtered = salesOrders.filter((salesOrder) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'SO Number':
          return salesOrder.salesorder_number?.toLowerCase().includes(lowercaseQuery);
        case 'Customers':
          return salesOrder?.customer_id?.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Item Category':
          return salesOrder.product_category_id?.product_category_name?.toLowerCase().includes(lowercaseQuery);
        case 'SO Status':
          return salesOrder.salesorder_status?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredSO(filtered);
  };
  

  async function getAllPayments() {
    try {
      const response = await fetch(`${fetchUrl}payments/get-all-payments`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          console.log('payments:', data.data);
          setPayments(data.data)
        } else {
          console.error('Error:', data.message);
          alert(`Failed to fetch payments: ${data.message}`);
        }
      } else {
        console.error('HTTP Error:', response.status);
        alert(`HTTP Error: ${response.status}`);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      alert('Failed to fetch invoices due to a network error');
    }
  }
  
  
  
  useEffect(() => {
    getAllPayments();
  }, [fetchUrl]);
  
  

  const handleCreate = () => {
    navigate("/bb/app/sales/newpaymentreceived");
  };



  useEffect(() => {
    fetchRoleId();
    // fetchSalesOrder();
  }, []);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const salesOrdersToDisplay = salesOrders && roleId === 6 
  ? salesOrders.filter(salesorder => (salesorder.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
  : salesOrders || [];
  console.log(salesOrdersToDisplay);

 
  // if (isLoading) {
  //   return <Loader />;
  // }




 /* Still Modifications have to be done for the below fetch call */




  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };



  function formatSalesOrderDate(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // add 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function calculateAgingDays(dateStr) {
    const salesOrderDate = new Date(dateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - salesOrderDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
    return diffInDays;
  }

  
  const handleStayHere = () => {
    setShowCancelPopup(false);
    setShowSOItemStatusPopup(false);
  }

  const handleNavigateToPaymentDetails = (payment) => {
    navigate("/bb/app/sales/paymentreceiveddetails", {state:{PaymentData: payment}});
  };
  
  
  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-4 items-center">
  {selectedCategories.length > 0 ? (
    <>
 <div className="flex space-x-2">
        <button
          // onClick={handleDelete}
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
        >
          Delete
        </button>
       <button
          // onClick={handleCreatePurchaseOrder}  // Ensure this function is defined
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
          // disabled={!createPOButtonEnabled}
        >
          Create Purchase Order
        </button>
      </div>
    </>
  ) : (
    <>
    <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Received Payments</h1>
     <Search onSearch={handleSearch} searchTypes={searchTypes}/>
      </div>
      <button
        onClick={handleCreate}
        className="bg-[#F7525A] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Payments"  style={{ '--tooltip-bg': 'black' }}>
        + New
      </button>
    </>
  )}
</div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                {payments.length > 0 ? (
  <table className="w-full mb-5">
    <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
      <tr className='border-b'>
        <th className="text-xs py-3 text-center">
          <input type="checkbox" className="mr-2" />
        </th>
        <th className="text-xs py-3 text-center">Date</th>
        <th className="text-xs py-3 text-center">Payment #</th>
        <th className="text-xs py-3 text-center">Reference Number</th>
        <th className="text-xs py-3 text-center">Customer Name</th>
        <th className="text-xs py-3 text-center">Invoice #</th>
        <th className="text-xs py-3 text-center">Mode</th>
        {/* <th className="text-xs py-3 text-center">Item Category</th> */}
        <th className="text-xs py-3 text-center">Amount</th>
        <th className="text-xs py-3 text-center">Unused Amount</th>
      </tr>
    </thead>
    <tbody className="border-b border-t">
      {payments.map((payment) => (
        <tr key={payment._id} className="text-sm border-b hover:bg-gray-50">
          <td className="py-2 text-center">
            <input type="checkbox" className="mr-2" />
          </td>
          <td className="py-2 text-center">{formatSalesOrderDate(payment.payment_date)}</td>
          <td 
          className="py-2 text-center text-[#408dfb] cursor-pointer"
          onClick={() => handleNavigateToPaymentDetails(payment)}
          >
         {payment.payment_number}
         </td>

          <td className="py-2 text-center">{payment.reference_number}</td>
          <td className="py-2 text-center">{payment.customer_id.customer_name}</td>
          <td className="py-2 text-center">{payment.invoice_number}</td>
          <td className="py-2 text-center">{payment.payment_mode}</td>
          {/* <td className="py-2 text-center">{payment.product_category_id.product_category_name}</td> */}
          <td className="py-2 text-center">₹{payment?.amount?.toFixed(2)}</td>
          <td className="py-2 text-center">₹{payment?.unused_amount?.toFixed(2) || 0}</td>
        </tr>
      ))}
    </tbody>
  </table>
                  ) : (
                    <>
                            <div className="m-4 p-4 text-center border border-grey-500 text-gray-500">
          No Payments Found.
        </div>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create Payments</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     {/*  pop up starts */}
   
      {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[200px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '200px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Unable to select?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      Kindly select Sales Order with same Item Category.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Okay
  </button>
        </div>
      </div>
    </div>
  </div>
)}

      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    {/*   pop up end */}
    </div>
  );
};

export default PaymentReceivedList;
