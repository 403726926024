
import React, { useState ,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faEye,faEyeSlash, faBuilding } from '@fortawesome/free-solid-svg-icons';
/* import logoImage from "/BB.png"; */
import { useNavigate } from 'react-router-dom';
import LoginSuccessModal from './LoginSuccess';

function Login() {
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const navigate = useNavigate();
    const [InvalideEmail,setInvalidEmail]=useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [EmptyLoginData, setEmptyLoginData]= useState(false);
    const [UserNotFound,setUserNotFound]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [organizations, setOrganizations] = useState([]);

  
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
      setInvalidEmail(false); // Reset invalide password state when password changes 
        setEmptyLoginData(false); // Reset Empty login data state when password changes
        setUserNotFound(false);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      setInvalidPassword(false); // Reset invalide password state when password changes 
        setEmptyLoginData(false); // Reset Empty login data state when password changes
        setUserNotFound(false);
    };
  
    const validateEmail = (email) => {
      const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailValidation.test(email);
    };
    const handlePasswordFieldFocus = () => {
      if (validateEmail(Username)) {
        fetchOrganizations();
      }
    };
    
  
    // Password validation logic
    const validatePassword = (password) => {
      const passwordValidation = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordValidation.test(password);
    };
   const [LoginNavigation , setLoginNavigation]= useState()
   const [showSuccessModal, setShowSuccessModal] = useState(false);
   const [loginDetails, setLoginDetails] = useState(null);
   const [OrganisationError , setOrganisationError] = useState('')

    const handleLogin = async () => {
      const LoginUrl= process.env.REACT_APP_FORE_APILINK + '/user/user-login';
      console.log(LoginUrl);
      if (!Username || !Password) {
        setEmptyLoginData("Please enter Email and Password.");//Error meassage display when inputfield are in empty state
          return;
        }  
        //validation of email and password function are get called 
        else if (!validateEmail(Username) || !validatePassword(Password)) {
          // Set the appropriate error states
          setInvalidEmail(!validateEmail(Username));
          setInvalidPassword(!validatePassword(Password));
          return;
        }
        if (!selectedOrganization) {
          setOrganisationError("Please select the Organisation.");//Error meassage display when inputfield are in empty state
            return;
          }  
        else{
          try {
           const LoginData= {
            username:Username,
            password:Password,
            organizationId: selectedOrganization
           }
           console.log(LoginData);
            const response = await fetch(LoginUrl, {
              method: 'POST',
              headers: {
            'Content-Type': 'application/json',
              },
              body: JSON.stringify(LoginData),
            });
        if(response.ok){
          console.log(response);
          const data = await response.json();
       
              localStorage.setItem('LoginData',JSON.stringify(data))
              sessionStorage.setItem('userData', JSON.stringify(data));
              sessionStorage.setItem('userId',data.userId);
              sessionStorage.setItem('token',data.token); 
              sessionStorage.setItem('organizationId',data.organizationId);
              console.log(data.roleID,data.organizationId,data.roleName,data.organizationName);
              sessionStorage.setItem('organizationName',data.organizationName); 
              sessionStorage.setItem('organizationNumber',data.organizationNumber); 
              sessionStorage.setItem('roleId',data.roleID);     
              sessionStorage.setItem('roleName',data.roleName);      
              console.log('organizationCity',data.organizationCity);
              sessionStorage.setItem('organizationCity',data.organizationCity);
              let navigationPath = ''
              if (data.forcePasswordChange===true) {
                navigationPath = '/bb/reset-password'
                //setLoginNavigation(navigate('/bb/reset-password', { state: { LoginData: data } }))//Navigate to ResetPassword page 
              }
              //If he alredy reset the password it check the role of the use he is an admin are user
              else if (data.roleID === 0) {
                navigationPath = '/bb/app/home';
                //setLoginNavigation(navigate('/bb/app/home', { state: { LoginData: data } }))  // if he is admin navigate to AdminDashbordPage
              } else if(data.roleID === 1) {
                 navigationPath = '/bb/app/home'
                //setLoginNavigation(navigate('/bb/app/home', { state: { LoginData: data } }))//if he is user navigate to UserHomePage
              }
              else if(data.roleID === 2) {
                 navigationPath = '/bb/app/home'
                //setLoginNavigation( navigate('/bb/app/home', { state: { LoginData: data } }))//if he is user navigate to UserHomePage
              }
              else if(data.roleID === 3) {
                 navigationPath = '/bb/app/home'
                //setLoginNavigation(navigate('/bb/app/home', { state: { LoginData: data } }))//if he is user navigate to UserHomePage
              }
              else if(data.roleID === 4) {
                 navigationPath = '/bb/app/home'
                //setLoginNavigation(navigate('/bb/app/home', { state: { LoginData: data } }))//if he is user navigate to UserHomePage
              }
              else if(data.roleID === 6) {
                 navigationPath = '/bb/app/home'
                //setLoginNavigation(navigate('/bb/app/home', { state: { LoginData: data } })) ;//if he is user navigate to UserHomePage
              }
              setLoginNavigation(() => () => navigate(navigationPath, { state: { LoginData: data } }));
              setLoginDetails(data);
              setShowSuccessModal(true);
            } else {
              console.error('Login failed',response);
              setUserNotFound('User not found')
            }
          } catch (error) {
            console.error('Error:', error);
          }
        }
    };

    const handleContinue = () => {
      setShowSuccessModal(false);
        if (LoginNavigation) {
            LoginNavigation();
        }
    };
    
    useEffect(() => {
      const loginData = JSON.parse(localStorage.getItem('LoginData'));
      if (loginData) {
        localStorage.setItem('LoginData', JSON.stringify(loginData));
        sessionStorage.setItem('userData', JSON.stringify(loginData));
        sessionStorage.setItem('userId', loginData.userId);
        sessionStorage.setItem('token', loginData.token);
     
    
        if (loginData.forcePasswordChange === true) {
       
          navigate('/ResetPassword', { state: { LoginData: loginData } }); // Navigate to ResetPassword page
        } else if (loginData.roleID === 0) {
          //setrole(loginData.roleID);
          navigate('/bb/app/home', { state: { LoginData: loginData } }); // Navigate to AdminDashboardPage for admin
        } else if (loginData.roleID === 2) {
          /* setrole(loginData.roleID); */
          navigate('/bb/app/home', { state: { LoginData: loginData } }); // Navigate to UserHomePage for user
        }
      } else {
        
       // setUserNotFound('User not found');
      }
    }, []);
  
    const handleForgotPassword = () => {
      navigate('/bb/forgot-password');
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };

    
      const fetchOrganizations = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/user/get-user-organizations?username=${Username}`);
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            setOrganizations(data.userOrganizations);
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
        }
      };
    
      
    

    const handleOrganizationChange = (e) => {
      setSelectedOrganization(e.target.value);
    };
    


    return (
      
      <div className="w-full flex justify-center items-center h-screen font-roboto overflow-hidden relative">
       <div className={`absolute inset-0 z-10 flex justify-center items-center h-screen font-roboto overflow-hidden transition-all duration-300 ${showSuccessModal ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                <div className='flex flex-row justify-center items-center' >
                <img src={'/BB.png'} alt="Logo" className='h-16 w-16 sm:w-14 sm:h-14'/>
                <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
            </div>
                    <div className="flex-col w-1/2 sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4 sm:p-0">
                        <p className="text-2xl pt-4">LOGIN</p>
                        <div className="p-6 sm:px-0 relative">
                            <div className="flex-col w-full">
                            <div className='flex justify-center'>
                                <p className="text-md">User Name</p>
                                </div>
                                <div className="flex-row flex items-center justify-center space-x-2 pl-2 pr-2 w-full relative">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={faUser} className="text-gray-400" />
                                        <div className="ml-1 mr-4  flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    type="text"
                                    placeholder="Username"
                                        className="w-5/6 sm:w-full md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                        value={Username}
                                      onChange={handleUsernameChange}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          e.preventDefault();
                                          const passwordInput = document.querySelector('input[type="password"]');
                                          if (passwordInput) {
                                            passwordInput.focus();
                                          }
                                        }
                                      }}
                                     
                                   />
                                    {InvalideEmail && (
                                    <div className="text-red-500 text-[12px]">
                                        <p className="flex justify-center">Enter the correct Email</p>
                                    </div>
                                    )}
                                    </div>
                                                                </div>
                                <div className='flex flex-col p-2 sm:p-0 sm:mt-4'>
                                    <div className='flex justify-center'>
                                <p className="text-md">Password</p>
                                </div>
                                <div className="flex-row flex items-center space-x-2 relative justify-center pl-2 pr-2 w-full">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    onChange={handlePasswordChange}
                                    onFocus={handlePasswordFieldFocus}
                                     type={showPassword ? "text" : "password"}
                                     placeholder="Password"
                                     value={Password}
                                    className="w-5/6 sm:w-full md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B]  text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleLogin(); // Call the handleLogin function when Enter key is pressed
                                      }
                                    }}
                                     />
                                    {invalidPassword && (
                                        <div className="text-red-500 text-[12px]">
                                            <p>Password is incorrect</p>
                                        </div>
                                        )}
                                </div>
                                {EmptyLoginData || UserNotFound && (
                                <div className="text-red-500 text-[12px] flex justify-center items-center">
                                <p className="text-red-500">
                                    {EmptyLoginData}
                                    
                                </p>
                                </div>
                            )}
                            </div>

                            <div className='flex flex-col p-2 sm:p-0 sm:mt-4'>
                              <div className='flex justify-center'>
                                <p className="text-md">Organization</p>
                              </div>
                              <div className="flex-row flex items-center space-x-2 relative pl-2 pr-2 w-full">
                              <span className="flex justify-center items-center absolute left-5 top-1/2 transform -translate-y-1/2">
                              <FontAwesomeIcon icon={faBuilding} className="text-gray-400 w-4 h-4" />
                              <div className="ml-1 mr-2 flex-grow border-r border-gray-300 h-5"></div>
                            </span>
                            <select
                            value={selectedOrganization}
                            onChange={handleOrganizationChange}
                            className="w-5/6 sm:w-full  md:w-full rounded-sm focus:outline-none focus:ring-[1px] focus:ring-[#EB001B] text-gray-600 bg-white text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300 appearance-none"
                            >
                            <option value="">Select Organization</option>
                            {organizations && organizations.map((org) => (
                              <option key={org.organizationId} value={org.organizationId}>
                                {org.organizationName}
                              </option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                              </svg>
                            </div>
                              </div>
                            </div>
                            <div className="flex justify-center items-center mt-3">
                            <p className="text-red-500 justify-center ">
                            {OrganisationError}
                            </p>
                                </div>


                            
                            <div className="flex justify-center items-center mt-3">
                                <p className="underline text-[#2158E5] hover:text-[#EB001B] cursor-pointer text-[14px]" onClick={handleForgotPassword}>Forgot Password?</p>
                            </div>
                            <div className="flex justify-center items-center mt-3">
                                <button className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                                onClick={handleLogin}>Login</button>
                            </div>
                            <div className="flex justify-center items-center mt-3">
                            <p className="text-red-500 justify-center ">
                            {UserNotFound}
                                    
                                </p>
                                </div>

                            
                        </div>
                    </div>
                </div>
            </div>

            {showSuccessModal && loginDetails &&(
        <LoginSuccessModal 
          userName={loginDetails.userName}
          organizationName={loginDetails.organizationName}
          onClose={handleContinue}
        />
      )}
        </div>
    );
}

export default Login;
