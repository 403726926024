import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaWindowMaximize, FaRuler, FaCompass, FaWindows } from 'react-icons/fa';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader } from '../BBAdmin/Loader/Loader';
import { FaChevronUp, FaChevronDown, FaTimes } from 'react-icons/fa';
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft, 
  faTag, 
  faListAlt, 
  faPhone, 
  faTimes, 
  faWindowRestore,
  faWindowClose,
  faWindowMaximize,
  faWindowMinimize,
  faHome,
  faInfoCircle,
  faHeadset,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import windowsOpenImage from '../ConsumerApp/images/windoesOpen.png';
import { BiRefresh } from 'react-icons/bi';
import LoaderModal from '../BBAdmin/Components/Loader';
import { ElectricalServices } from '@mui/icons-material';




const WindowsQuote = ()=> {
  const location = useLocation();
  const navigate = useNavigate();

  const { 
    projectId, 
    categoryId, 
    packageId,
    customerId, 
    token, 
    organizationId,  
    packages  
  } = location.state;

  // console.log('Received params:', {
  //   projectId,
  //   categoryId,
  //   packageId,
  //   customerId,
  //   organizationId,
  //   packages
  // });
 
//   const { projectId, token, categoryId, customerId } = route.params;
const [projectData, setProjectData] = useState({
  projectId: '',
  projectName: '',
  rooms: []
});

  
 
const [isLoading, setIsLoading] = useState(true);
const fetchUrl = process.env.REACT_APP_FORE_APILINK;
const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
const [isProductModalVisible, setIsProductModalVisible] = useState(false);
const [selectedCategory, setSelectedCategory] = useState(null);

const RETRY_LIMIT = 3;
const [retryCount, setRetryCount] = useState(0);
const [windows, setwindows] = useState([]);
const [isCallbackModalVisible, setIsCallbackModalVisible] = useState(false);

const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
// const [packageId, setPackageId] = useState(1);
const [isPackageChangeModalVisible, setIsPackageChangeModalVisible] = useState(false);
const [pendingPackage, setPendingPackage] = useState(null);
const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
const [selectedDetails, setSelectedDetails] = useState(null);
const [uoms, setUoms] = useState([]);
const [isSavingQuote, setIsSavingQuote] = useState(false);
const [selectedVendor, setSelectedVendor] = useState(null);
const [showNoChangesModal, setShowNoChangesModal] = useState(false);
const [hasModifications, setHasModifications] = useState(false);
const [showNoProductsModal, setShowNoProductsModal] = useState(false);
const modalRef = useRef(null);
const [isDragging, setIsDragging] = useState(false);
const [dragPosition, setDragPosition] = useState(0);
const dragThreshold = 100; // Pixels to drag before closing
const [isChangingVendor, setIsChangingVendor] = useState(false);
const [showTermsModal, setShowTermsModal] = useState(false);
const [isPackageSwitching, setIsPackageSwitching] = useState(false);
const [selectedPackage, setSelectedPackage] = useState(null);
 const [isPriceLoading, setIsPriceLoading] = useState(false);
 const [isRefreshing, setIsRefreshing] = useState(false);
 const [loadingCategories, setLoadingCategories] = useState({});
 const [isQuotationSaving, setIsQuotationSaving] = useState(false);
 
  useEffect(() => {  
    window.scrollTo(0, 0);
  }, []);


    useEffect(() => {
      fetchProjectData();
      fetchWindowsData();
    }, []);

    useEffect(() => {
      const handleBeforeUnload = () => {
        if (!isPackageSwitching) {
        setIsRefreshing(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    };
    
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isPackageSwitching]);
    

    useEffect(() => {
      // Set default vendor on initial load
      if (windows[0]?.vendors) {
        const defaultVendor = windows[0].vendors.find(v => v.default === true);
        setSelectedVendor(defaultVendor);
      }
    }, [windows]);

    useEffect(() => {
      if (isDetailsModalVisible || isProductModalVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [isDetailsModalVisible, isProductModalVisible]);
    
    
    const fetchProjectData = async (attempt = 0) => {
      setIsLoading(true);
      try {
        const token = await sessionStorage.getItem('token');
  
        if (!token) {
          navigate.replace('Login');
          return;
        }
  
        //console.log('Fetching project data with:', { projectId, token }); // Debug log
  
        const response = await fetch(`${fetchUrl}/measurements/get-flat-wise-window-measurements-of-floor?project_id=${projectId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
  
        if (response.status === 401) {
          await sessionStorage.clear();
          navigate.replace('Login');
          return;
        }
        
        const data = await response.json();
        //console.log('Received project data:', data);
        if (data) {
          setProjectData(data);
          //console.log('Fetched project data:', data);
        } else {
          if (response.status === 404) {
            window.alert('Error', 'Project not found');
          } else if (response.status === 500) {
            window.alert('Error', 'Internal Server Error. Kindly Retry.');
          }
        }
      } catch (error) {
        console.error('Failed to fetch:', error);
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => fetchProjectData(attempt + 1), 1000);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
  
    const fetchWindowsData = async () => {
      try {
        const token = await sessionStorage.getItem('token');
        const organizationId = await sessionStorage.getItem('organizationId');
        
        // Make sure the URL is correctly formatted
        const response = await fetch(`${fetchUrl}/summary/package-details?product_category_id=${categoryId}&project_id=${projectId}&package_id=${packageId}`, {
          method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log('Fetched windows data:', data);
        if (data.success && data.data) {
          setwindows([data.data]); // Wrap in array since UI expects array of packages 
        //console.log('Remaining Packages:', remainingPackages);
      }
      } catch (error) {
        console.log('Error fetching windoes data:', error.message);
        // Handle the error appropriately - maybe set an error state
      }
    };
  
    useEffect(() => {
      getUoms();
    }, []);
  
    const getUoms = async () => {
      try {
        const token = await sessionStorage.getItem('token');
        const getUomUrl = `${backendApiUrl}UOMs/get-all-UOMs`;
    
        const response = await fetch(getUomUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
        });
        const data = await response.json();
       
        if (data.data) {
          setUoms(data.data);
        }
      } catch (error) {
       // console.error('Failed to fetch:', error);
    
      
      }
    };
    
  
    const getUOMText = (uomId) => {
      const foundUom = uoms.find(uom => uom._id === uomId);
      return foundUom?.UOM ;
    };
  
    const handleViewDetails = (category) => {
      setSelectedDetails(category);
      setIsDetailsModalVisible(true);
    };
  
   const handlePackageChange = async (newPackageId) => {
  if (hasUnsavedChanges) {
    setPendingPackage(newPackageId);
    setIsPackageChangeModalVisible(true);
  } else {
    const token = sessionStorage.getItem('token');
    
    const response = await fetch(`${fetchUrl}/summary/package-details?product_category_id=${categoryId}&project_id=${projectId}&package_id=${newPackageId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    if (data.success && data.data) {
      setwindows([data.data]);
    }
  }
};

  
  
    const handleDirectPackageChange = async (packageId = 1) => {
      setHasUnsavedChanges(false);
      await fetchWindowsData(packageId);
      setIsPackageChangeModalVisible(false);
    };
    
  
    // Handler for change button click
  const handleChangeClick = (category) => {
    const vendorProducts = category.products.filter(
      product => product.vendor?.id === selectedVendor?._id
    );
    
    setSelectedCategory({
      ...category,
      products: vendorProducts // Only pass the filtered products
    });

    setIsProductModalVisible(true);
  };
  
  const handleProductSelection = (product) => {
    const categoryKey = selectedCategory.category || selectedCategory;
    setLoadingCategories(prev => ({
      ...prev,
      [categoryKey]: true
    }));
    setIsPriceLoading(true);
    setHasModifications(true); 
    const updatedWindows = windows.map(pkg => ({
      ...pkg,
      room_categories: pkg.room_categories.map(cat => {
        if (cat.category === selectedCategory.category) {
          // Get UOM text for the product
          const uomText = getUOMText(product.UOM_id);
          
          // Calculate new price based on UOM
          const newPrice = uomText === 'No' ? 
            product.selling_price * cat.room_category_count :
            product.selling_price * cat.room_category_area;
          
          return {
            ...cat,
            room_category_price: newPrice,
            products: cat.products.map(p => ({
              ...p,
              default: p._id === product._id,
              isModified: p._id === product._id // Add this line
            }))
          };
        }
        return cat;
      }),
      // Recalculate total package price considering UOM
      total_price: pkg.room_categories.reduce((sum, cat) => {
        if (cat.category === selectedCategory.category) {
          const uomText = getUOMText(product.UOM_id);
          return sum + (uomText === 'No' ? 
            product.selling_price * cat.room_category_count :
            product.selling_price * cat.room_category_area);
        }
        return sum + cat.room_category_price;
      }, 0)
    }));
  
   
    console.log('Updated Windows:', updatedWindows);
    setIsProductModalVisible(false);


    setTimeout(() => {
      setwindows(updatedWindows);
      // Clear loading states after data is updated 
        setLoadingCategories(prev => ({
          ...prev,
          [categoryKey]: false
        }));
     setIsPriceLoading(false); 
    }, 1000);
  };

  const handleVendorChange = (vendor) => {
    const hasProducts = windows[0].room_categories.some(cat => 
      cat.products.some(p => p.vendor?.id === vendor._id)
    );
  
    if (!hasProducts) {
      setShowNoProductsModal(true);
      return;
    }

    setIsChangingVendor(true);

    setSelectedVendor(vendor);
    
    const updatedWindows = windows.map(pkg => ({
      ...pkg,
      room_categories: pkg.room_categories.map(cat => {
        // Find products for this vendor
        const vendorProducts = cat.products.filter(p => p.vendor?.id === vendor._id);
        // Get default product for this vendor
        const defaultProduct = vendorProducts.find(p => p.default === true) || vendorProducts[0];
        
        if (defaultProduct) {
          const uomText = getUOMText(defaultProduct.UOM_id);
          const newPrice = uomText === 'No' ? 
            defaultProduct.selling_price * cat.room_category_count :
            defaultProduct.selling_price * cat.room_category_area;
  
          return {
            ...cat,
            room_category_price: newPrice,
            products: cat.products.map(p => ({
              ...p,
              default: p._id === defaultProduct._id,
              isModified: p._id === defaultProduct._id // Add this line
            }))
          };
        }
        return cat;
      }),
      vendors: pkg.vendors.map(v => ({
        ...v,
        default: v._id === vendor._id
      }))
    }));
  
    // Recalculate total package price
    updatedWindows[0].total_price = updatedWindows[0].room_categories.reduce(
      (sum, cat) => sum + cat.room_category_price, 
      0
    );
  
    console.log('Updated Windows with new vendor:', updatedWindows);
    setTimeout(() => {
      setwindows(updatedWindows);
      setIsChangingVendor(false);
    }, 1000);
  };
  

  const handleSaveClick = () => {
    if (!hasModifications) {
      setIsQuotationSaving(true); 
      navigateToQuoteSummary('')
    
    }else{
    setIsQuotationSaving(true); 
     handleSaveQuote();
    }
    
  };
  
 /*  const handleSaveQuote = async () => {
    try {
      const token = await sessionStorage.getItem('token');
      const organizationId = await sessionStorage.getItem('organizationId');
  
      const itemsData = [];
      
      windows[0].room_categories.forEach(category => {
        const selectedProduct = category.products.find(p => p.default === true);
        const uomText = getUOMText(selectedProduct.UOM_id);
        
        // Group by rooms while maintaining original pricing
        const roomGroups = category.applied_windows.reduce((rooms, window) => {
          const roomName = window.room_name;
          if (!rooms[roomName]) {
            rooms[roomName] = {
              windows: [],
              area: 0,
              count: 0,
              breakup: {}
            };
          }
          rooms[roomName].windows.push(window);
          rooms[roomName].area += Number(window.area);
          rooms[roomName].count += Number(window.count);
          rooms[roomName].breakup[window.window_name] = {
            units: Number(window.count),
            area: Number(window.area)
          };
          return rooms;
        }, {});
  
        // Calculate for each room while maintaining category total
        const totalRooms = Object.keys(roomGroups).length;
        const pricePerRoom = category.room_category_price / totalRooms;
        
        Object.entries(roomGroups).forEach(([roomName, data]) => {
          const quantity = uomText === 'No' ? data.count : data.area;
          const basePrice = quantity * selectedProduct.selling_price; 
          const taxAmount = (basePrice * selectedProduct.GST) / 100;
  
          itemsData.push({
            item_id: selectedProduct._id,
            item_name: selectedProduct.product_name,
            quantity: quantity,
            selling_price: selectedProduct.selling_price,
            cost_price: selectedProduct.cost_price || 0,
            discount: 0,
            tax_rate: selectedProduct.GST,
            tax_type: 'GST',
            total_tax_amount: taxAmount,
            tax_amount: taxAmount,
            total: basePrice,
            UOM: uomText,
            UOM_id: selectedProduct.UOM_id,
            area_types: {
              area_name: roomName,
              area: data.area.toString(),
              count: data.count
            },
            applied_windows: data.windows,
            quantity_breakup: data.breakup
          });
        });
      });
      console.log('Items Data:', itemsData);
      const subtotal = itemsData.reduce((sum, item) => sum + item.total, 0);
      const totalTax = itemsData.reduce((sum, item) => sum + item.total_tax_amount, 0);
  
      const quotationData = {
        customer_id: customerId,
        product_category_id: categoryId,
        quotation_date: new Date(),
        status: 'Draft',
        quotation_type: 'Group',
        project_id: projectId,
        items: itemsData,
        subtotal: subtotal,
        tax_amount: totalTax,
        discount: 0,
        adjustment: 0,
        tax_prefrence: 'Tax Exclusive',
        total: subtotal + totalTax,
        organizationId
      };
      console.log('Quotation Data:', quotationData);
  
      const response = await fetch(`${backendApiUrl}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationData),
      });
  
      const data = await response.json();
      if (data.success) {
        setTimeout(() => {
          setIsSavingQuote(false);
          navigate('/consumer/app/windowsquotationDetails', {
            state: { quotationId: data.data._id }
          });
        }, 3000);
      }
    } catch (error) {
      console.error('Failed to save quotation:', error);
    }
  }; */

  const handleSaveQuote = async () => {
    setIsQuotationSaving(true); 

    try {
      const token = await sessionStorage.getItem('token');
      const organizationId = await sessionStorage.getItem('organizationId');
  
      const itemsData = windows[0].room_categories.map(category => {
        const selectedProduct = category.products.find(p => p.default === true);
        const uomText = getUOMText(selectedProduct.UOM_id);
        
        // Calculate base price based on UOM
        const quantity = uomText === 'No' ? category.room_category_count : category.room_category_area;
        const basePrice = selectedProduct.selling_price * quantity;
        const taxAmount = (basePrice * selectedProduct.GST) / 100;
  
        return {
          item_id: selectedProduct._id,
          item_name: selectedProduct.product_name,
          quantity: quantity,
          selling_price: selectedProduct.selling_price,
          cost_price: selectedProduct.cost_price || 0,
          discount: 0,
          tax_rate: selectedProduct.GST,
          tax_type: 'GST',
          total_tax_amount: taxAmount,
          tax_amount: taxAmount,
          total: basePrice,
          UOM: uomText,
          UOM_id: selectedProduct.UOM_id,
          /* area_types: {
            area_name: category.category,
            area: category.room_category_area.toString(),
            count: category.room_category_count
          }, */
          applied_windows: category.applied_windows,
          quantity_breakup: category.applied_windows.reduce((acc, window) => {
            acc[window.window_name] = {
              units: window.count,
              area: window.area
            };
            return acc;
          }, {})
        };
      });
  console.log('Items Data:', itemsData);
      const subtotal = windows[0].total_price;
      const totalTax = itemsData.reduce((sum, item) => sum + item.total_tax_amount, 0);
  
      const quotationData = {
        customer_id: customerId,
        product_category_id: categoryId,
        quotation_date: new Date(),
        status: 'Draft',
        quotation_type: 'General',
        project_id: projectId,
        items: itemsData,
        subtotal: subtotal,
        tax_amount: totalTax,
        discount: 0,
        adjustment: 0,
        tax_prefrence: 'Tax Exclusive',
        total: subtotal + totalTax,
        organizationId
      };
  
      const response = await fetch(`${backendApiUrl}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationData),
      });
  
      const data = await response.json();
      if (data.success) {
      
          /* navigate('/consumer/app/windowsquotationDetails', {
            state: { quotationId: data.data._id }
          }); */
          navigateToQuoteSummary(data.data._id )
      
         
      }
    } catch (error) {
      console.error('Failed to save quotation:', error);
    }
  };

  const navigateToQuoteSummary = (quotation_id) => {
    try {

      const navigationData = {
        quotation_id: quotation_id || '',
        product_category_id: categoryId || '',
        project_id: projectId || '',
        package_id: packageId || '',
        product_category_name : 'Windows',
        projectName: projectData.projectName,
        timestamp: Date.now()
      };
      setTimeout(() => {
        setIsQuotationSaving(false); 
      navigate('/consumer/app/summarydetails', {
        state: navigationData,
        replace: true // Ensures clean navigation history
      });
    }, 2000);
    } catch (error) {
      console.error('Navigation failed:', error);
      // Fallback navigation
      navigate('/consumer/app/summarydetails');
    }
  };

  
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setDragPosition(e.touches[0].clientY);
  };
  
  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const currentPosition = e.touches[0].clientY;
    const diff = currentPosition - dragPosition;
    
    if (diff > 0) { // Only allow downward drag
      modalRef.current.style.transform = `translateY(${diff}px)`;
    }
  };

  const handleTouchEnd = (e) => {
    if (!isDragging) return;
    
    const currentPosition = e.changedTouches[0].clientY;
    const diff = currentPosition - dragPosition;
    
    if (diff > dragThreshold) {
      // Close the modal with animation
      modalRef.current.style.transform = `translateY(100%)`;
      setTimeout(() => setIsDetailsModalVisible(false),
      setIsProductModalVisible(false),
      300);
    } else {
      // Reset position
      modalRef.current.style.transform = 'translateY(0)';
    }
    
    setIsDragging(false);
  };
  const handlePackageSwitch = (pkg) => {
    setSelectedPackage(pkg);
    setIsPackageSwitching(true);
  
    setTimeout(() => {
      navigate(`/consumer/app/windowQuote`, {
        state: {
          projectId,
          categoryId, 
          packageId: pkg.package_id,
          customerId,
          token,
          organizationId,
          packages,
          timestamp: Date.now()
        },
        replace: true
      });
      
      setTimeout(() => window.location.reload(), 100);
    }, 1000);
  };
  

if (isLoading) {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#EB001B]"></div>
    </div>
  );
}
const RefreshLoader = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
    <div className="p-8 rounded-lg flex flex-col items-center">
      <div className="w-12 h-12 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
      <div className="text-center">
        <p className="text-lg font-medium text-white mb-1">Refreshing</p>
        <h3 className="text-sm font-bold text-white">Please wait...</h3>
      </div>
    </div>
  </div>
);

  const SkeletonLoader = () => (
    <div className="animate-pulse p-4">
      <div className="h-8 bg-gray-200 w-3/4 mx-auto mb-8 rounded"></div>
      
      <div className="mb-6">
        <div className="h-6 bg-gray-200 w-1/2 mb-3 rounded"></div>
        <div className="h-5 bg-gray-200 w-1/3 rounded"></div>
      </div>
  
      {[1, 2].map((flat) => (
        <div key={flat} className="mb-8 bg-white p-4 rounded-lg">
          <div className="h-6 bg-gray-200 w-1/4 mx-auto mb-6 rounded"></div>
          
          <div className="flex gap-4 overflow-x-auto">
            {[1, 2, 3].map((room) => (
              <div key={room} className="flex-shrink-0 w-[200px]">
                <div className="h-5 bg-gray-200 w-3/4 mb-4 rounded"></div>
                <div className="grid grid-cols-1 gap-4">
                  {[1, 2].map((window) => (
                    <div key={window} className="border rounded-lg p-4">
                      <div className="h-24 bg-gray-200 mb-3 rounded"></div>
                      <div className="h-4 bg-gray-200 w-2/3 mb-2 rounded"></div>
                      <div className="h-4 bg-gray-200 w-1/2 rounded"></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
  
 const renderHeader = () => (
   <div className="bg-white sticky top-0 z-50 border-b border-gray-200">
     <div className="h-1 bg-gradient-to-r from-[#EA011F] via-red-400 to-[#EA011F]"></div>
     <div className="px-4 py-3">
       <div className="flex items-center justify-between gap-3">
         {/* Back Button */}
         <div className="flex-shrink-0">
           <button
             onClick={() => navigate(-1)}
             className="w-9 h-9 flex items-center justify-center bg-gradient-to-br from-red-50 to-white rounded-xl hover:shadow-md transition-all duration-300 group"
           >
             <FontAwesomeIcon 
               icon={faArrowLeft} 
               className="text-gray-600 group-hover:text-[#EA011F] transition-colors" 
             />
           </button>
         </div>
 
         {/* Project Name and Category Container */}
         <div className="flex-1 flex flex-col items-center">
           <h1 className="text-lg font-bold text-gray-800 truncate w-full text-center mb-0.5">
             {projectData.projectName}
           </h1>
           <div className="flex items-center gap-1.5">
             <div className="w-1 h-1 rounded-full bg-[#EA011F]"></div>
             <span className="text-xs font-medium text-gray-500">Windows</span>
             <div className="w-1 h-1 rounded-full bg-[#EA011F]"></div>
           </div>
         </div>
 
         {/* Home Button */}
         <div className="flex-shrink-0">
           <button
             onClick={() => navigate('/consumer/app/home')}
             className="w-9 h-9 flex items-center justify-center bg-gradient-to-br from-red-50 to-white rounded-xl hover:shadow-md transition-all duration-300 group"
           >
             <FontAwesomeIcon 
               icon={faHome} 
               className="text-gray-600 group-hover:text-[#EA011F] transition-colors" 
             />
           </button>
         </div>
       </div>
     </div>
   </div>
 );
  
  
  
  const renderFloatingButtons = () => {
    const hasChanges = windows[0]?.room_categories?.some(cat =>
      cat.products.some(prod => prod.isModified)
    );
  
    return (
      <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 shadow-xl z-10">
      <div className="px-4 py-3">
        <div className="flex items-center justify-between w-full gap-3">
          {/* Stylish Package/Quote Status Display - 30% width */}
          <div className="w-[30%] h-[48px] flex items-center">
            {hasChanges ? (
              <div className="relative group">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-red-50 to-white px-4 py-2 rounded-lg border-l-4 border-[#EA011F]">
                    <span className="text-sm font-semibold text-[#EA011F]">Customized Quote</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative group">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-gray-50 to-white px-4 py-2 rounded-lg border-l-4 border-green-500">
                    <span className="text-sm font-semibold text-gray-700">{windows[0]?.package_name} Quote</span>
                  </div>
                </div>
              </div>
            )}
          </div>
            {/* Proceed Button */}
            <button
  onClick={handleSaveClick}
  className="w-[70%] h-[48px] rounded-xl bg-gradient-to-r from-red-500 to-red-600 hover:shadow-lg transition-all duration-300"
>
  <div className="flex items-center justify-between px-6 py-2">
    <span className="text-lg font-bold text-white">
      {isPriceLoading ? (
        <div className="w-4 h-4 border-2 border-gray-300 border-t-[#EA011F] rounded-full animate-spin"/>
      ) : (
        `₹${windows[0]?.total_price?.toLocaleString('en-IN')}`
      )}
    </span>
    <div className="flex items-center gap-2">
      <span className="text-lg font-semibold text-white">
        Proceed
      </span>
      <FontAwesomeIcon
        icon={faChevronRight}
        className="text-white text-xs"
      />
    </div>
  </div>
</button>

          </div>
        </div>
      </div>
    );
  };
  
  

  return (
    <>
    {isRefreshing ? (
      <RefreshLoader />
    ) : (
    <div className="flex-1 bg-gray-50 min-h-screen">
      {/* Header */}
     
{renderHeader()}

      {/* Main Content */}
      <div className="bg-[#F5F7FA] flex-1 p-1 pt-2 pb-12 overflow-y-auto">
         {/* Brand Selection */}

         <div className="bg-white shadow-sm">
  <div className="px-3 py-2">
    <div className="flex items-center justify-between mb-2">
      <h3 className="text-sm font-medium text-gray-700">Select Brand</h3>
    </div>

    <div className="flex gap-3">
      {windows[0]?.vendors?.map((vendor) => (
        <div key={vendor._id} className="relative">
          <button
            onClick={() => handleVendorChange(vendor)}
            className={`flex items-center px-3 py-1.5 rounded-lg transition-all ${
              selectedVendor?._id === vendor._id
                ? 'border-2 border-gray-700 bg-gray-50'
                : 'bg-gray-50 border border-gray-200 hover:border-gray-400'
            }`}
          >
            <div className="w-6 h-6 rounded-full overflow-hidden border border-gray-200">
              {vendor.image ? (
                <img src={`${imageUrl}${vendor.image}`} alt={vendor.name} className="w-full h-full object-cover"/>
              ) : (
                <div className="w-full h-full bg-white flex items-center justify-center">
                  <span className="text-xs font-bold text-gray-500">{vendor.name?.[0]?.toUpperCase() || "V"}</span>
                </div>
              )}
            </div>
            <span className={`ml-2 text-xs font-medium whitespace-nowrap ${
              selectedVendor?._id === vendor._id ? 'text-gray-800' : 'text-gray-600'
            }`}>
              {vendor.name.toUpperCase() }
            </span>
            {selectedVendor?._id === vendor._id && (
              <div className="absolute -top-1 -right-1 w-4 h-4 bg-gray-700 rounded-full flex items-center justify-center">
                <svg className="w-2.5 h-2.5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"/>
                </svg>
              </div>
            )}
          </button>
        </div>
      ))}
    </div>
  </div>
</div>



        <div className="">
        <div className="p-2">
        {windows[0]?.room_categories?.map((category) => (
  <div key={category.category} className="mx-0 mt-2 lg:mx-8 bg-white rounded-md overflow-hidden border border-gray-200 hover:shadow-md transition-all duration-300">
    <div className="p-3 border-b border-gray-100">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="text-lg font-semibold text-[#EA011F] hover:text-[#c40119] transition-colors">
          {category.category}
          </span>
        </div>
        
        <div className="flex items-center gap-3">
          <button
            onClick={() => handleViewDetails(category)}
            className="flex items-center gap-3 group"
            title="Click to view area details"
          >
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-600">
                {category.room_category_area} sq.ft
              </span>
              <span className="text-xs text-gray-500">
                {category.room_category_count} No
              </span>
            </div>
            <svg
              className="w-4 h-4 text-[#EA011F] group-hover:translate-x-1 transition-transform self-center"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    {loadingCategories[category.category] ? (
      <div className="mt-1 bg-gray-50 rounded-lg p-3 shadow-sm flex justify-center items-center h-24">
        <div className="w-8 h-8 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin"></div>
      </div>
    ) : (
      category.products
        .filter(product => product.default === true && product.vendor?.id === selectedVendor?._id)
        .map((product) => (
          <div key={product._id} className="p-4 hover:bg-gray-50 transition-colors rounded-lg border border-gray-100">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"/>
                </svg>
                <span className="text-base font-semibold text-gray-800 hover:text-[#EA011F] transition-colors cursor-pointer">
                  {product.product_name}
                </span>
              </div>

              <button
                onClick={() => handleChangeClick(category)}
                className="px-4 py-1.5 border-2 border-[#EA011F] rounded-full hover:bg-[#EA011F] group transition-all duration-300"
              >
                <span className="text-sm font-medium text-[#EA011F] group-hover:text-white">Customize</span>
              </button>
            </div>

            <div className="mt-1 bg-gray-50 rounded-lg p-3 shadow-sm">
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col">
                  <span className="text-xs text-gray-500 mb-1">
                    {['sq.ft', 'SQ.FT', 'Sq.Ft', 'Sq.ft'].includes(getUOMText(product.UOM_id)) ? 'Area' : 'Quantity'}
                  </span>
                  <p className="text-sm font-medium text-gray-700">
                    {['sq.ft', 'SQ.FT', 'Sq.Ft', 'Sq.ft'].includes(getUOMText(product.UOM_id))
                      ? `${category.room_category_area} ${getUOMText(product.UOM_id)}`
                      : `${category.room_category_count} ${getUOMText(product.UOM_id)}`
                    }
                  </p>
                </div>

                <div className="flex flex-col text-center">
                  <span className="text-xs text-gray-500 mb-1">Unit Price</span>
                  <p className="text-sm font-medium text-gray-700">
                    ₹{product.selling_price}
                    <span className="text-gray-500 text-xs"> per {getUOMText(product.UOM_id)}</span>
                  </p>
                </div>

                <div className="flex flex-col text-right">
                  <span className="text-xs text-gray-500 mb-1">Price</span>
                  <p className="text-base font-bold text-green-700">
                    ₹{category.room_category_price.toLocaleString('en-IN')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
    )}
  </div>
))}

          </div>
      </div>
  
        <div className="flex items-center gap-2 px-4 py-2 bg-green-50 rounded-lg">
    <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
    </svg>
    <span className="text-sm font-medium text-green-700">
      Installation will be done by Professional Brickbucket Team
    </span>
  </div>
  
  
 {/*  Other Options  */}
 <div className="p-6 mt-6 mb-16 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-sm">
  <div className="flex items-center gap-3 mb-6">
    <div className="bg-red-100 p-2 rounded-lg">
      <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
      </svg>
    </div>
    <h3 className="text-base font-bold text-gray-800">Explore More Options</h3>
  </div>

  <div className="grid grid-cols-2 gap-4">
    {packages?.filter(pkg =>
      pkg.product_category_id === categoryId &&
      pkg.project_id === projectId &&
      pkg.package_id !== packageId
    )
    .sort((a, b) => a.package_id - b.package_id) 
    .map(pkg => (
      <button
        key={pkg.package_id}
        className="group relative flex flex-col p-5 bg-white hover:bg-red-50 border-2 border-gray-100 hover:border-[#EA011F] rounded-xl transition-all duration-300 transform hover:-translate-y-1 hover:shadow-md"
        onClick={() => handlePackageSwitch(pkg)}
      >
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <div className="p-1.5 bg-red-50 rounded-md">
              <svg className="w-4 h-4 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
              </svg>
            </div>
            <span className="text-lg font-bold text-gray-800 group-hover:text-[#EA011F]">
              {pkg.package_name}
            </span>
          </div>
          
          <div className="flex items-baseline gap-2">
            <span className="text-base font-bold text-[#EA011F]">
              ₹{pkg.total_price.toLocaleString('en-IN')}
            </span>
            <svg className="w-4 h-4 text-gray-400 group-hover:text-[#EA011F] group-hover:translate-x-1 transition-all" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
            </svg>
          </div>
        </div>
      </button>
    ))}
  </div>
</div>

  
    {/* Store Information */}
    {/* <div className="bg-white rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] p-8 mt-6 backdrop-blur-sm">
  <div className="max-w-2xl mx-auto">
  
    <div className="text-center mb-6">
      <h2 className="text-2xl font-bold bg-gradient-to-r from-[#EA011F] to-red-500 text-transparent bg-clip-text">
        Brickbucket Center
      </h2>
      <div className="h-1 w-20 bg-gradient-to-r from-[#EA011F] to-red-500 mx-auto mt-2 rounded-full"/>
    </div>

  
    <div className="flex items-start gap-3 mb-6">
      <div className="p-2 bg-red-50 rounded-full flex-shrink-0">
        <svg className="w-5 h-5 text-[#EA011F]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
        </svg>
      </div>
      <p className="text-gray-600 text-sm leading-relaxed">
        1st Floor, Besides Rainbow Hospital, Swarna Grand, NH 16 Service Rd, 
        Nagarjuna Nagar, Sri Ramachandra Nagar, Vijayawada, Andhra Pradesh 520008.
      </p>
    </div>

   
    <a
      href="tel:+919701967272"
      className="group flex items-center justify-center gap-3 p-3 mb-8
        bg-gradient-to-r from-red-50 to-white
        hover:from-red-100 hover:to-red-50
        border border-red-100 rounded-xl
        transform hover:scale-[1.02] hover:shadow-lg
        transition-all duration-300"
    >
      <div className="p-2 bg-white rounded-full shadow-sm group-hover:shadow">
        <svg className="w-5 h-5 text-[#EA011F] group-hover:animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
        </svg>
      </div>
      <div className="flex flex-col">
        <span className="text-gray-800 font-medium">+91 9701967272</span>
        <span className="text-xs text-gray-500">Click to call now</span>
      </div>
      <svg className="w-5 h-5 text-gray-400 group-hover:translate-x-1 transition-transform" 
        fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
      </svg>
    </a>

   
    <div className="relative rounded-xl overflow-hidden shadow-lg group">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20 
        opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.8661790804444!2d80.67221177489614!3d16.518743088608843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35e59e85c4baa7%3A0x2b2d63f275dc3d6e!2sKRAFTO%20Tiles!5e0!3m2!1sen!2sin!4v1706521145617!5m2!1sen!2sin"
        className="w-full h-72 group-hover:scale-105 transition-transform duration-700"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
      <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
        <button className="bg-white/90 backdrop-blur-sm px-4 py-2 rounded-lg shadow-lg 
          text-sm font-medium text-gray-700 hover:bg-white transition-colors">
          Open in Google Maps
        </button>
      </div>
    </div>
  </div>
</div> */}

   

      </div>
  
      {/* Fixed Bottom Buttons */}
      {renderFloatingButtons()}
  {/* Callback Modal */}
{isCallbackModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setIsCallbackModalVisible(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">Thanks for your interest</h2>
      <p className="text-gray-700 text-center mb-6">Our executive will call you shortly.</p>
      <button
        className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
        onClick={() => setIsCallbackModalVisible(false)}
      >
        Close
      </button>
    </div>
  </div>
)}

{/* Package Change Modal */}
{isPackageChangeModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setIsPackageChangeModalVisible(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold mb-4 text-center">Save Changes?</h2>
      <p className="text-gray-700 text-center mb-6">
        Would you like to save your changes before switching packages?
      </p>
      <div className="flex gap-4">
        <button
          className="flex-1 bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
          onClick={async () => {
            await handleSaveClick();
            await handleDirectPackageChange(pendingPackage);
          }}
        >
          Save
        </button>
        <button
          className="flex-1 bg-gray-500 text-white py-2 rounded-lg font-semibold"
          onClick={() => handleDirectPackageChange(pendingPackage)}
        >
          Don't Save
        </button>
      </div>
    </div>
  </div>
)}

{/* Details Modal */}
{isDetailsModalVisible && (
  <div className="fixed inset-0 flex items-end justify-center z-50 ">
    <div className="bg-black/50 backdrop-blur-xs absolute inset-0" onClick={() => setIsDetailsModalVisible(false)} />
    <div
    ref={modalRef}
     className="bg-white rounded-t-2xl p-6 w-full max-w-2xl relative z-10 animate-slide-up" style={{height: '70vh'}}
     onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
     >
      <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
      
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-[#EA011F]">Room Details</h2>
        <FontAwesomeIcon 
          icon={faTimes} 
          className="text-2xl text-gray-600 cursor-pointer hover:text-[#EA011F]" 
          onClick={() => setIsDetailsModalVisible(false)}
        />
      </div>

      <div className="h-[55vh] overflow-y-auto no-scrollbar">
        {selectedDetails?.applied_windows?.map((window) => (
          <div key={window.window_name} className="py-3 border-b border-gray-200">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-bold text-gray-900">{window.window_name}</h3>
                <p className="text-gray-600">{window.room_name}</p>
              </div>
              <div className="text-right">
                <span className="text-gray-700 mr-4">{window.height}" × {window.width}"</span>
                <span className="font-bold text-gray-900">{window.area} sq.ft</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

{/* Product Selection Modal */}
{isProductModalVisible && (
  <div className="fixed inset-0 flex items-end justify-center z-50">
    <div className="bg-black/50 backdrop-blur-xs absolute inset-0 transition-opacity" onClick={() => setIsProductModalVisible(false)} />
    <div
      ref={modalRef}
      className="bg-white rounded-t-[20px] p-6 w-full max-w-2xl relative z-10 animate-slide-up"
      style={{height: '70vh'}}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
      
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-gray-800">Select Product</h2>
        <FontAwesomeIcon
          icon={faTimes}
          className="text-2xl text-gray-600 cursor-pointer hover:text-[#EA011F]"
          onClick={() => setIsProductModalVisible(false)}
        />
      </div>

      <div className="h-[55vh] overflow-y-auto no-scrollbar">
        {selectedCategory?.products.map((product) => (
          <button
            key={product._id}
            className="w-full p-4 border-b border-gray-100 flex items-center gap-3"
            onClick={() => handleProductSelection(product)}
          >
            <div className="flex-shrink-0">
              <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                product.default ? 'border-[#EA011F]' : 'border-gray-300'
              }`}>
                {product.default && (
                  <div className="w-3 h-3 rounded-full bg-[#EA011F]" />
                )}
              </div>
            </div>
            <div className="flex-1 text-left">
              <h3 className="text-lg font-bold text-gray-800">{product.product_name}</h3>
              <p className="text-gray-600">₹{product.selling_price}/sq.ft</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  </div>
)}




{showNoProductsModal && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setShowNoProductsModal(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">No Products Available</h2>
      <p className="text-gray-700 text-center mb-6">This vendor currently has no products available.</p>
      <button
        className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
        onClick={() => setShowNoProductsModal(false)}
      >
        Close
      </button>
    </div>
  </div>
)}

{showNoChangesModal && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setShowNoChangesModal(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">No Changes Found</h2>
      <p className="text-gray-700 text-center mb-6">There are no modifications to save.</p>
      <button
        className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
        onClick={() => setShowNoChangesModal(false)}
      >
        Close
      </button>
    </div>
  </div>
)}



{isChangingVendor && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
    <div className=" p-8 rounded-lg flex flex-col items-center">
      <div className="w-12 h-12 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
      <p className="text-lg font-medium text-white">Changing Vendor...</p>
    </div>
  </div>
)}

{isPackageSwitching && selectedPackage && (
  <LoaderModal 
    isVisible={isPackageSwitching}
    title="Switching to"
    packageName={selectedPackage.package_name}
  />
)}

<LoaderModal 
  isVisible={isQuotationSaving}
  title="Generating Custom Quote"
  packageName="Please wait..."
/>
    </div>
    
)}
</>
  );
  
  
  
};

export default WindowsQuote;
