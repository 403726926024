import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import buildImage from './images/build.png';
import { jwtDecode } from "jwt-decode";
import { useLocation } from 'react-router-dom';
import {  faCrown, faFile, faFileAlt, faFileInvoice, faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons';
import LoaderModal from '../BBAdmin/Components/Loader';
const Home = () => {
   const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [project , setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [productCategories, setProductCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState({
    windows: '',
    tiles: '',
    doors: '',
    adhesives: '',
  });
  const searchParams = new URLSearchParams(location.search);
  const ProjectId = searchParams.get('project') || sessionStorage.getItem('ProjectId');
 //console.log('Project Params',searchParams.get('project'));
 //console.log('Project session',sessionStorage.getItem('ProjectId'));
  const customerId = searchParams.get('customer');
  //console.log(customerId);
  const urlToken = searchParams.get('token');
  const [blocksData, setBlocksData] = useState({});
  const [flatsData, setFlatsData] = useState({});
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
const [quotations, setQuotations] = useState([]);
console.log('Quotations:', quotations);
const [isImageOpen, setIsImageOpen] = useState(false);
const [showAllQuotations, setShowAllQuotations] = useState(false);
const [activeTab, setActiveTab] = useState('packages');
const imageUrl = process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL;
const [selectedCategory, setSelectedCategory] = useState(null);
const [selectedPackage, setSelectedPackage] = useState(null);
const [isPackageSwitching, setIsPackageSwitching] = useState(false);
const [isRefreshing, setIsRefreshing] = useState(false);
const [isQuotationLoading, setIsQuotationLoading] = useState(false);
const categoryOrder = ['Tiles', 'Doors', 'Windows'];
useEffect(() => {
  if (urlToken) {
    try {
      const decodedToken = jwtDecode(urlToken);
      if(decodedToken){
        sessionStorage.setItem('token', urlToken);
        sessionStorage.setItem('userId', decodedToken.userId);
        sessionStorage.setItem('username', decodedToken.username);
        sessionStorage.setItem('roleId', decodedToken.roleId);
        sessionStorage.setItem('organizationId', decodedToken.organizationId);
        sessionStorage.setItem('customerId', customerId);
        sessionStorage.setItem('ProjectId', ProjectId); // Added ProjectId to session storage
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }
}, [urlToken, searchParams, ProjectId, customerId]);


  useEffect(() => {
    if (urlToken) {
      sessionStorage.setItem('token', urlToken);
      sessionStorage.setItem('customerId', customerId);
      fetchProjects();
      fetchProductCategories();
       fetchBlocksByProjectid();
      fetchFlatsByBlockid();
    }
  }, [urlToken, customerId]);
  useEffect(() => {
    fetchProject();
    fetchPackagesByProjectId();
    //fetchProjects();
    //fetchPackagesByCustomerid();
    fetchProductCategories();
    fetchBlocksByProjectid();
    fetchFlatsByBlockid();
    handlegetQuote();
  }, []);


  useEffect(() => {
    const handleBeforeUnload = () => {
      setIsRefreshing(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    };
      
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);
  
  useEffect(() => {
    if (packages?.data && productCategories.length > 0) {
      const categoriesWithPackages = productCategories.filter(category =>
        packages.data.some(pkg => pkg.product_category_id === category._id)
      );
      const tilesCategory = categoriesWithPackages.find(cat => 
        cat.product_category_name === 'Tiles'
      );
      if (tilesCategory) {
        setSelectedCategory(tilesCategory._id);
      } else { 
        setSelectedCategory(categoriesWithPackages[0]?._id);
      }
    }
  }, [packages, productCategories]);


  const fetchProjects = async () => {
    try {
      const token = sessionStorage.getItem('token');
      console.log(token);
      const customerId = await sessionStorage.getItem('customerId');
      
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/projects/get-projects-by-customer?customer_id=${customerId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      
      const data = await response.json();
      const sortedProjects = data.data.sort((a, b) => {
        const dateA = new Date(a.timestamp).getTime();
        const dateB = new Date(b.timestamp).getTime();
        return dateB - dateA;
      });
      
      //console.log('Sorted dates:', sortedProjects.map(p => ({ id: p._id, timestamp: p.timestamp })));
      setProjects(sortedProjects || []);
      sortedProjects.forEach(project => {
        fetchBlocksByProjectid(project._id);
      });
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProject = async () => {
    try {
      const token = sessionStorage.getItem('token');    
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/projects/get-project?projectId=${ProjectId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      
      const data = await response.json();
     console.log(data);
      setProject(data|| []);
        fetchBlocksByProjectid(data._id);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBlocksByProjectid = async (projectId) => {
    try {
      const token = await sessionStorage.getItem('token');
      
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/get-all-blocks-of-project?projectId=${projectId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-token': token
        },
      });
  
      const data = await response.json();
      console.log(data);
      // Store blocks data
      setBlocksData(data);
 
      return data;
      
    } catch (error) {
      console.error('Failed to fetch blocks:', error);
      return null;
    }
  };

  const fetchPackagesByProjectId = async () => {
    try {
      const token = await sessionStorage.getItem('token');

      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/Summary/get-packages-by-project?project_id=${ProjectId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-token': token
        },
      });
  //console.log(response);
      const data = await response.json();
      console.log('packages data ' , data);
      setPackages(data);  
      //console.log('packages data ' , data);
      return data;
      
    } catch (error) {
      console.error('Failed to fetch packages:', error);
      return null;
    }
  };  
  
  const fetchFlatsByBlockid = async (blockId) => {
    try {
      const token = await sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/flats/get-flats-by-blockId?blockId=${blockId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'x-token': token
        },
      });
  
      const data = await response.json();
    console.log(data);
      setFlatsData(prev => ({
        ...prev,
        [blockId]: data
      }));
      return data;
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };
  

  const fetchProductCategories = async () => {
    try {
      const token = await sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}product-category/get-all-productCategories`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
       
      if (data.data) {
        const categories = data.data;
        const ids = {
          windows: categories.find(cat => cat.product_category_name.toLowerCase() === 'windows')?._id,
          tiles: categories.find(cat => cat.product_category_name.toLowerCase() === 'tiles')?._id,
          doors: categories.find(cat => cat.product_category_name.toLowerCase() === 'doors')?._id,
          adhesives: categories.find(cat => cat.product_category_name.toLowerCase() === 'adhesives')?._id,
        };
        setCategoryIds(ids);
        setProductCategories(data.data);
        console.log('Product Categories:', data.data);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  const handlegetQuote = async () => {
    console.log('Fetching quote groups...');

    try {
      const token = await sessionStorage.getItem('token');
      const customerId = await sessionStorage.getItem('customerId');
      
      // Make sure the URL is correctly formatted
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotations-by-project?project_id=${ProjectId}`, {
        method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-token': token
    }
  });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Fetched quote data:', data);

      if (data.data) {
        setQuotations(data.data);
      }
     
    } catch (error) {
      console.log('Error fetching quote data:', error.message);
      // Handle the error appropriately - maybe set an error state
    }
  };

  const handleTilesClick = async (project, packageId) => { 
  console.log('project ID:', project._id);
  console.log('packageId:', packageId);

    const token = sessionStorage.getItem('token');
    const customerId = sessionStorage.getItem('customerId');
    
    if (packageId === 1) {
      navigate('/consumer/app/tilesQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.tiles,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,     
          packages: packages.data
        }     
      });
    } else if (packageId === 2) {
      navigate('/consumer/app/tilesQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.tiles,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,
          packages: packages.data
        }
      });
    } else if (packageId === 3) {
      navigate('/consumer/app/tilesQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.tiles,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,
          packages: packages.data
        }
      });    
    }
  };
  
  const RefreshLoader = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
      <div className="p-8 rounded-lg flex flex-col items-center">
        <div className="w-12 h-12 border-4 border-gray-200 border-t-[#EA011F] rounded-full animate-spin mb-4"></div>
        <div className="text-center">
          <p className="text-lg font-medium text-white mb-1">Refreshing</p>
          <h3 className="text-sm font-bold text-white">Please wait...</h3>
        </div>
      </div>
    </div>
  );

  const handleDoorsClick = async (project, packageId , product_category_id) => {
   
    const pakageData= packages.data.filter(p=>p.product_category_id === product_category_id)
    
    const token = await sessionStorage.getItem('token');
    const customerId = await sessionStorage.getItem('customerId');
    
    if (packageId === 1) {
      navigate('/consumer/app/doorsQuote', {
        state: {
          projectId: project._id,
          projectName:project.projectName,
          categoryId: categoryIds.doors,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,  
          packages: pakageData 
   
        }     
      });
    } else if (packageId === 2) {
      navigate('/consumer/app/doorsQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.doors,
          projectName:project.projectName,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,
          packages: pakageData
        }
      });
    } else if (packageId === 3) {
      navigate('/consumer/app/doorsQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.doors,
          projectName:project.projectName,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,
          packages: pakageData
        }
      });    
    }
  };


  
  const handleWindowsClick = async (project, packageId) => {
 
    
    const token = await sessionStorage.getItem('token');
    const customerId = await sessionStorage.getItem('customerId');
    
    if (packageId === 1) {
      navigate('/consumer/app/windowQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.windows,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,  
          packages: packages.data 
   
        }     
      });
    } else if (packageId === 2) {
      navigate('/consumer/app/windowQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.windows,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,
          packages: packages.data
        }
      });
    } else if (packageId === 3) {
      navigate('/consumer/app/windowQuote', {
        state: {
          projectId: project._id,
          categoryId: categoryIds.windows,
          customerId,
          token,
          organizationId: project.organizationId,
          packageId,
          packages: packages.data
        }
      });    
    }
  };

  const handleAdhesivesClick = async (project) => { 
    const token = await sessionStorage.getItem('token');
    const customerId = await sessionStorage.getItem('customerId');
    
     navigate('Adhesives', {
      projectId: project._id,
      categoryId: categoryIds.adhesives,
      customerId,
      token,
      organizationId: project.organizationId
    });
  };

  const handleQuotationClick = (quotation) => {
    setIsQuotationLoading(true); 

    const categoryName = productCategories.find(
      
      cat => cat._id === quotation.product_category_id
    )?.product_category_name;
console.log("category name:--",categoryName)
console.log("product categories:---",productCategories)
//console.log("cat",cat)
//console.log("p",quotation.product_category_id)
    setTimeout(() => {

    if (categoryName?.toLowerCase() === 'windows') {
      navigate('/consumer/app/windowsquotationDetails', {
        state: {
          quotationId: quotation._id
        }
      });
    }else  if (categoryName?.toLowerCase() === 'doors') {
      navigate('/consumer/app/doorsQuotationDetails', {
        state: {
          quotationId: quotation._id
        }
      });
    }else {
      navigate('/consumer/app/quotationDetails', {
        state: {
          quotationId: quotation._id
        }
      });
     }
  }, 2000);
};
  
  

  const openInGoogleMaps = (address) => {
    const encodedAddress = encodeURIComponent(address);
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(mapsUrl, '_blank');
  };


 
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-600"></div>
      </div>
    );
  }

  const ImagePopup = ({ imageUrl, projectName, onClose }) => (
    <div 
      className="fixed inset-0 bg-black/70 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div className="relative max-w-4xl w-full">
        <img
          src={imageUrl}
          alt={projectName}
          className="w-full h-auto rounded-lg"
        />
        <button 
          className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2 hover:bg-black/70"
          onClick={onClose}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );

  const handlePackageClick = (project, packageId, category) => {
    const packageDetails = packages.data.find(pkg => pkg.package_id === packageId);
    setSelectedPackage(packageDetails);
    setIsPackageSwitching(true);
    setTimeout(() => {
    if (category.product_category_name === 'Tiles') {
      handleTilesClick(project, packageId);
    } else if (category.product_category_name === 'Doors') {
      handleDoorsClick(project, packageId, category._id);
    } else if (category.product_category_name === 'Windows') {
      handleWindowsClick(project, packageId);
    }
  }, 2000);
};

// For Economy Package (Reliable & Affordable)
const economyStyles = {
  card: "border-teal-200 hover:border-teal-400 hover:shadow-teal-100",
  badge: "bg-gradient-to-r from-teal-500 to-teal-600",
  title: "text-teal-700",
  price: "bg-teal-50 text-teal-700",
  button: "bg-teal-600 hover:bg-teal-700 text-white"
};

// For Value Package (Popular Choice)
const valueStyles = {
  card: "border-violet-200 hover:border-violet-400 hover:shadow-violet-100 scale-105",
  badge: "bg-gradient-to-r from-violet-500 to-violet-600",
  title: "text-violet-700",
  price: "bg-violet-50 text-violet-700",
  button: "bg-violet-600 hover:bg-violet-700 text-white"
};

// For Premium Package (Luxury Experience)
const premiumStyles = {
  card: "border-amber-200 hover:border-amber-400 hover:shadow-amber-100",
  badge: "bg-gradient-to-r from-amber-500 to-amber-600",
  title: "text-amber-700",
  price: "bg-amber-50 text-amber-700",
  button: "bg-amber-600 hover:bg-amber-700 text-white"
};

// Apply these styles based on package_id:
const getPackageStyles = (packageId) => {
  switch(packageId) {
    case 1: return economyStyles;
    case 2: return valueStyles;
    case 3: return premiumStyles;
    default: return economyStyles;
  }
};

  


  return (
    <>
    {isRefreshing ? (
      <RefreshLoader />
    ) : (
    <div className="bg-gray-50 min-h-screen">
  <div className="relative h-[40vh]">
  {project?.projectImage?.length > 0 && imageUrl ? (
    <>
      <img
        src={`${imageUrl}${project?.projectImage}`}
        alt={project?.projectName}
        className="w-full h-full object-cover cursor-zoom-in"
        onClick={() => setIsImageOpen(true)}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-[#1A1F2E] to-transparent"/>
      <div className="absolute top-2 right-2 bg-white/20 backdrop-blur-sm px-3 py-1.5 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-center gap-2 cursor-pointer" onClick={() => setIsImageOpen(true)}>
        <svg className="w-4 h-4 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 3h6m0 0v6m0-6L14 10m-4 4L3 21m0 0h6m-6 0v-6" />
        </svg>
        <span className="text-xs text-white font-medium">Click to expand</span>
      </div>
    </>
  ) : (
    <div className="w-full h-full bg-gray-50">
      <svg
        className="w-16 h-16 text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        />
      </svg>
      <div className="absolute inset-0 bg-gradient-to-t from-[#1A1F2E] to-transparent"/>
    </div>
  )}

  <div className="absolute bottom-3 lg:bottom-4 left-3 lg:left-4 right-3 lg:right-4">
    <h2 className="text-xl lg:text-2xl font-bold text-white mb-1">{project?.projectName}</h2>
    <button
      onClick={() => openInGoogleMaps(project?.siteAddress)}
      className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors duration-300 group w-full"
      title={project?.siteAddress}
    >
      <svg
        className="w-4 h-4 flex-shrink-0 group-hover:animate-bounce"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
        />
      </svg>
      <span className="text-xs lg:text-sm truncate underline-offset-2 hover:underline max-w-full">
        {project?.siteAddress}
      </span>
    </button>
  </div>

  {isImageOpen && (
    <ImagePopup
      imageUrl={`${imageUrl}${project?.projectImage}`}
      projectName={project?.projectName}
      onClose={() => setIsImageOpen(false)}
    />
  )}
</div>


<div className="px-4 -mt-6">
<div className="shadow-2xl bg-white rounded-xl shadow-sm p-4 mt-8 mb-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="text-center">
              <span className="block text-2xl font-bold text-[#EA011F]">{blocksData[0]?.blockNo}</span>
              <span className="text-xs text-gray-500"> Block</span>
            </div>
            <div className="text-center border-x border-gray-100">
              <span className="block text-2xl font-bold text-[#EA011F]"> {blocksData[0]?.numFloors || '--'}</span>
              <span className="text-xs text-gray-500">Floors</span>
            </div>
            <div className="text-center">
              <span className="block text-2xl font-bold text-[#EA011F]">{blocksData[0]?.numFlats || '--'}</span>
              <span className="text-xs text-gray-500">Flats</span>
            </div>
          </div>
        </div>

{/* Quotations Grid starts*/}
<div className="mb-8">
  <h2 className="text-lg font-bold text-gray-800 mb-4">Customized Quotations</h2>
  <div className="space-y-3">
  {quotations?.filter(q => q.project_id._id === project._id).length === 0 ? (
      // No quotations message
      <div className="bg-white p-6 rounded-xl border border-gray-100 text-center">
        <div className="text-gray-400 mb-2">
        </div>
        <p className="text-gray-600 font-medium">Customized Quotations not available </p>
      </div>
    ) : (
      // Existing quotations display
      <>
        {quotations
      ?.filter(q => q.project_id._id === project._id)
      .slice(0, showAllQuotations ? undefined : 2)
      .map(quotation => {           
            return (
              <div 
              key={quotation._id}
              className={`relative bg-white p-2 rounded-xl transition-all duration-300 cursor-pointer
                ${quotation.selected 
                  ? 'border-2 border-[#EA011F] shadow-sm' 
                  : 'border border-gray-100 hover:border-red-200'}`}
              onClick={() => handleQuotationClick(quotation)}
            >
              {quotation.selected && (
                <div className="absolute -top-3 -left-1">
                  <div className="bg-green-500 text-white text-[10px] font-medium px-3 py-1 rounded-full shadow-sm flex items-center gap-1.5">
                    <div className="w-1 h-1 rounded-full bg-white animate-pulse"/>
                    Interested 
                  </div>
                </div>
              )}
            
            <div className="flex items-center justify-between mt-2">
  <div className="flex items-center gap-3">
    <div className={`w-5 h-5 rounded-xl flex items-center justify-center transition-colors
      ${quotation.selected ? 'bg-gradient-to-br from-red-50 to-red-100' : 'bg-gray-50'}`}
    >
      <FontAwesomeIcon
        icon={faFileAlt}
        className={`text-[#EA011F] text-xs ${quotation.selected && 'animate-pulse'}`}
      />
    </div>
    <div>
      <div className="flex items-center min-w-[200px]">
        <span className="text-sm font-semibold text-gray-800 ">
          {quotation?.quotation_number}
        </span>
        <span className="text-xs font-medium text-[#EA011F] bg-red-50 px-3 py-1 rounded-full mx-auto">
          {quotation?.product_category_id.product_category_name}
        </span>
      </div>
      <span className="text-xs text-gray-500 mt-0.5 block">
        {new Date(quotation?.quotation_date).toLocaleDateString()}
      </span>
    </div>
  </div>

  <div className="text-right">
    <span className={`text-md font-bold ${quotation.selected ? 'text-[#EA011F]' : 'text-gray-700'}`}>
      ₹{Math.round(quotation?.subtotal || 0).toLocaleString('en-IN')}
    </span>
  </div>
</div>

            </div>
            

            
            );
        })}

        {quotations?.filter(q => q.project_id._id === project._id).length > 2 && (
          <button
            onClick={() => setShowAllQuotations(!showAllQuotations)}
            className="w-full mt-2 py-2 text-sm font-medium bg-red-50 text-red-600 rounded-lg transition-colors"
>
            {showAllQuotations ? 'Show Less' : 'Show More'}
          </button>
        )}
      </>
    )}
  </div>
</div>
{/* Quotations Grid ends*/}

{/* Standard Quotations Div */}
<div className="relative">
  <div className="sticky top-0 z-20 bg-gray-50 pt-4 pb-2">
    <h2 className="text-lg font-bold text-gray-800 mb-4">Standard Quotations</h2>
    
    <div className="flex gap-4 overflow-x-auto no-scrollbar">
      {productCategories
        .filter(category => packages?.data?.some(pkg => pkg.product_category_id === category._id))
        .sort((a, b) => {
          const aIndex = categoryOrder.indexOf(a.product_category_name);
          const bIndex = categoryOrder.indexOf(b.product_category_name); 
          if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
          }
          if (aIndex !== -1) return -1;
       
          if (bIndex !== -1) return 1;
          return 0;
        })
        .map(category => (
          <div key={category._id} className="relative">
            <button
              onClick={() => setSelectedCategory(category._id)}
              className={`flex-shrink-0 px-6 py-3 rounded-xl transition-all duration-300
                ${selectedCategory === category._id
                  ? 'bg-gradient-to-r from-[#EA011F] to-red-500 text-white shadow-lg transform -translate-y-1'
                  : 'bg-white text-gray-700 hover:bg-red-50'}`}
            >
              <span className="font-semibold whitespace-nowrap">{category.product_category_name}</span>
            </button>
          </div>
        ))}
    </div>
  </div>

  <div className="mt-5 bg-gradient-to-b from-red-50/50 rounded-3xl p-4">
    {selectedCategory && (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {packages?.data
          ?.filter(pkg => pkg.product_category_id === selectedCategory)
          .sort((a, b) => a.package_id - b.package_id)
          .map(pkg => (
            <div key={pkg._id}
              className={`group relative bg-white rounded-2xl p-6 transition-all duration-500
                ${pkg.package_id === 1 ? 'hover:border-[#EA011F]/30 hover:shadow-[#EA011F]/10' : ''}
                ${pkg.package_id === 2 ? 'hover:border-[#EA011F]/60 hover:shadow-[#EA011F]/20 md:scale-105' : ''}
                ${pkg.package_id === 3 ? 'hover:border-[#EA011F] hover:shadow-[#EA011F]/30' : ''}
                ${pkg.selected ? 'ring-2 ring-[#EA011F] ring-offset-2 shadow-lg' : 'border border-gray-100'}
                transform hover:-translate-y-2 hover:shadow-xl`}
            >
              <div className={`absolute -top-3 -right-0 px-4 py-1 rounded-full text-xs font-bold
                ${pkg.package_id === 1 ? 'bg-[#EA011F]/70' : ''}
                ${pkg.package_id === 2 ? 'bg-[#EA011F]/85' : ''}
                ${pkg.package_id === 3 ? 'bg-[#EA011F]' : ''}
                text-white shadow-lg`}>
                {pkg.package_id === 1 && "Economy Choice"}
                {pkg.package_id === 2 && "✨ Best Value"}
                {pkg.package_id === 3 && "★ Premium"}
              </div>

              {pkg.selected && (
                <div className="absolute -top-3 -left-1 flex items-center gap-1.5 rounded-full shadow-lg px-4 py-1.5 bg-[#EA011F]">
                  <div className="flex items-center gap-2">
                    <span className="w-2 h-2 rounded-full bg-white animate-pulse"/>
                    <span className="text-white text-xs font-medium">Interested</span>
                    <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                    </svg>
                  </div>
                </div>
              )}

              <div className='flex flex-col gap-4'>
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-bold tracking-tight text-black">
                    {pkg.package_name}
                  </h3>
                  <div className={`text-xl font-bold px-4 py-2 rounded-xl
                    ${pkg.selected ? 'bg-[#EA011F] text-white' : 'bg-[#EA011F]/10 text-[#EA011F]'}`}>
                    ₹{Math.round(pkg.total_price).toLocaleString('en-IN')}/-
                  </div>
                </div>

                <div className="space-y-3">
                  <ul className="space-y-2">
                    {Object.entries(pkg.roomCategories).map(([type, rooms]) => (
                      rooms.length > 0 && (
                        <li key={type} className="relative bg-white rounded-lg p-3 shadow-sm
                          hover:shadow-md transition-shadow duration-300">
                          <div className="flex items-center gap-2">
                            <div className={`w-5 h-5 rounded-md flex items-center justify-center
                              ${pkg.selected ? 'bg-[#EA011F]' : 'bg-[#EA011F]/10'}`}>
                              <svg className={`w-3.5 h-3.5 ${pkg.selected ? 'text-white' : 'text-[#EA011F]'}`}
                                fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2.5" d="M5 13l4 4L19 7"/>
                              </svg>
                            </div>
                            <div>
                              <h4 className="text-sm font-semibold text-gray-800">{type}</h4>
                              <div className="ml-0">
                                <span className="text-xs text-gray-600">
                                  {pkg.product_category_id === categoryIds.windows
                                    ? <span className="font-medium">{rooms.length} rooms</span>
                                    : rooms.join(', ')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    ))}
                  </ul>
                </div>

                <button
  onClick={() => handlePackageClick(project, pkg.package_id,
    productCategories.find(cat => cat._id === selectedCategory))}
  className="w-full py-3 rounded-xl text-sm font-medium
    transition-all duration-300 flex items-center justify-center gap-2
    border border-[#EA011F] hover:border-[#EA011F]/90
    text-black shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
>
  View Details
  <svg className="w-4 h-4 transition-transform group-hover:translate-x-1"
    fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round"
      strokeWidth="2" d="M9 5l7 7-7 7"/>
  </svg>
</button>

              </div>
            </div>
          ))}
      </div>
    )}
  </div>
</div>


{/* Standard Quotations ends */}




</div>

<LoaderModal 
      isVisible={isPackageSwitching}
      title="Loading Package"
      packageName={selectedPackage?.package_name}
    />
    <LoaderModal 
  isVisible={isQuotationLoading}
  title="Loading Quotation Details"
  packageName="Please wait..."
/>
  </div>
     )}
  </>
  );
};

export default Home;
