import React, { useState, useEffect } from "react";
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { UserIcon, ChartBarIcon, ClockIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
ChartJS.register(...registerables);

const QuotationDashboard = () => {
  const [activities, setActivities] = useState([]);
  console.log(activities);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterRole, setFilterRole] = useState('');
  const navigate = useNavigate();

  const getAllQuotations = process.env.REACT_APP_FORE_BRICKBUCKET + `customer/quotations/get-all-quotations?organizationId=${sessionStorage.getItem('organizationId')}`;

  useEffect(() => {
    const fetchAllQuotations = async () => {
        const token = sessionStorage.getItem('token');
        try {
          const response = await fetch(getAllQuotations, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
          // First log the data structure
          console.log('Response structure:', data);
          
          // Access the correct array property if data is an object
          const quotationsArray = data.quotations || data.data || [];
          console.log('Quotations array:', quotationsArray);
          const draftQuotations = quotationsArray.filter(quotation => quotation.ai_generated === true && quotation.status === 'Draft');
          
          console.log('Draft quotations:', draftQuotations);
          setActivities(draftQuotations);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
      
      

    fetchAllQuotations();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const formatDateTime = (isoDate) => {
    const dateObj = new Date(isoDate);
    const date = dateObj.toLocaleDateString();
    const time = dateObj.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
    return `${date} ${time}`;
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredAndSortedActivities = activities
    .filter(activity => 
      activity?.customer_id.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activity?.customer_id.mobile_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activity?.activity.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(activity => filterRole ? activity.role === filterRole : true)
    .sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAndSortedActivities.slice(indexOfFirstItem, indexOfLastItem);


  
  const processActivityText = (text) => {
    const parts = text?.split(/"([^"]*)"/);
    return parts?.map((part, index) => {
      if (index % 2 === 1) {
        const [label, idInfo] = part.split(/:\((.+)\)/);
        return (
          <span
            key={index}
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => handleClickedWord(idInfo, part)}
          >
            {label}
          </span>
        );
      }
      return part;
    });
  };
  
  const handleClickedWord = (idInfo, fullPart) => {
    const [idType, id] = idInfo.split(': ');
    switch (idType.trim()) {
      case 'projectId':
        navigate(`/bb/app/projects/projectdetails`, { state: { projectId: id.trim() } });
        break;
      case 'customerId':
        navigate(`/bb/app/sales/customerdetails`, { state: { customerId: id.trim() } });
        break;
      case 'quotationId':
        navigate(`/bb/app/sales/quotedetails`, { state: { quotationId: id.trim() } });
        break;
      case 'salesorderId':
        navigate(`/bb/app/sales/salesorderdetails`, { state: { salesOrderId: id.trim() } });
        break;
      default:
        console.log("Unknown ID type:", idType, "Full part:", fullPart);
    }
  };

return (
<div>
{currentItems.length > 0 ? (
        (sessionStorage.getItem('roleId') === '1' || sessionStorage.getItem('roleId') === '0') && (
          <div className="flex-1 px-10 py-5">
            <div className="mt-24 bg-white rounded-lg border border-gray-200">
              <div className="relative">
                <div className="absolute inset-0 bg-[#F9F9FB] rounded-t-lg"></div>
                <div className="relative flex justify-between items-center p-2 border-b">
                  <h2 className="text-lg">Quotations</h2>
                  <div className="flex items-center">
                    <button
                      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                      className="px-2 py-1 text-blue-500 disabled:text-gray-300"
                    >
                      &lt;
                    </button>
                    <button
                      onClick={() => setCurrentPage(prev => prev + 1)}
                      disabled={filteredAndSortedActivities.length <= itemsPerPage || currentPage === Math.ceil(filteredAndSortedActivities.length / itemsPerPage)}
                      className="px-2 py-1 text-blue-500 disabled:text-gray-300"
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full p-8">
                <div className="flex justify-between items-center mb-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border rounded"
                  />
                </div>
                <table className="w-full text-center">
                  <thead>
                    <tr className="border">
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('createdAt')}>
                        Date {sortField === 'createdAt' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('username')}>
                        Customer Name {sortField === 'username' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('role')}>
                        Mobile Number {sortField === 'role' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                      <th className="p-3 text-sm border font-semibold cursor-pointer" onClick={() => handleSort('activity')}>
                        Quotation {sortField === 'activity' && (sortDirection === 'asc' ? '▲' : '▼')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((activity, index) => (
                      <tr key={activity?.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                        <td className="p-3 text-sm border">{formatDateTime(activity?.quotation_date)}</td>
                        <td className="p-3 text-sm border">{activity?.customer_id.customer_name}</td>
                        <td className="p-3 text-sm border">{activity?.customer_id.mobile_number}</td>
                        <td className="p-3 text-sm border">
                        <span 
                            className="text-blue-500 cursor-pointer hover:underline"
                            onClick={() => navigate(`/bb/app/materialreq2`, { 
                            state: { quotationId: activity._id } 
                            })}
                        >
                            {activity.quotation_number}
                        </span>
                        </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="p-4 text-center border border-grey-500 text-gray-500">
          No quotations found
        </div>
      )}
      </div>
       );
    };
    
    export default QuotationDashboard;
    