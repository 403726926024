import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faDoorOpen,faDoorClosed, faChevronRight, faTimes, faListAlt, faTag , faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

const DoorQuotationDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quotationId } =  location.state ||  {};
  
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  
  const [quotationData, setQuotationData] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [SelectedItem, setSelectedItem]= useState({})
  const [showDoorDetails,setShowDoorDetails] = useState(false)

  useEffect(() => {
    if (!quotationId) {
      console.log('quotationId is missing')
      navigate(-1);
      return;
    }
    fetchQuotationDetails();
  }, [quotationId, navigate]);


  const fetchQuotationDetails = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const response = await fetch(
        `${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotation?quotation_id=${quotationId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data.success) {
        setQuotationData(data.data);
      } else {
        throw new Error(data.message || 'Failed to fetch quotation details');
      }

    } catch (error) {
      setError(error.message);
      console.error('Error fetching quotation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border-4 border-gray-200 border-t-[#EA011F] 
                         rounded-full animate-spin mb-4">
          </div>
          <p className="text-lg font-medium text-gray-800">
            Loading Quotation Details...
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="text-center p-8 max-w-md">
          <div className="text-red-500 text-5xl mb-4">
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Failed to Load Quotation
          </h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => navigate(-1)}
            className="px-6 py-2 bg-[#EA011F] text-white rounded-md 
                     hover:bg-red-600 transition-colors duration-200"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  const renderHeader = () => {
    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0
      }).format(price);
    };
  
    return (
      <div className="bg-white shadow-md sticky top-0 z-50">
        <div className="p-2">
          <div className="flex items-center justify-between relative">
            <button 
              onClick={() => navigate(-1)} 
              className="absolute left-0 p-2 hover:bg-gray-50 rounded-full 
                       transition-colors duration-200"
            >
              <FontAwesomeIcon 
                icon={faArrowLeft} 
                className="text-[#736f70] text-xl" 
              />
            </button>
            <div className="flex-1 text-center">
              <h1 className="text-xl font-bold text-[#EA011F]">
                {quotationData?.quotation_number || 'Quotation Details'}
              </h1>
            </div>
          </div>
        </div>
  
        <div className="mx-4 mb-4">
          <div className="flex items-center justify-between bg-red-50 p-4 rounded-lg">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 bg-white rounded-full flex items-center 
                            justify-center">
                <FontAwesomeIcon 
                  icon={faTag} 
                  className="text-[#EA011F]" 
                />
              </div>
              <div className="flex flex-col">
                <span className="text-base font-semibold text-gray-700">
                  Door Quotation Details
                </span>
                <span className="text-xs text-gray-500 font-medium">
                  {quotationData?.tax_prefrence}
                </span>
              </div>
            </div>
            <div className="text-right">
              <span className="text-lg text-[#EA011F] font-bold">
                {formatPrice(quotationData?.total || 0)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const renderDoorItem = (item) => {
    if (!item) return null;
  
    const formatPrice = (price) => {
      const numPrice = Number(price) || 0;
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0
      }).format(numPrice);
    };
  
    const validateItem = {
      id: item?._id?.$oid || `item-${Math.random()}`,
      name: item?.item_name || 'Door Item',
      areaName: item?.area_types?.area_name || 'Area',
      sellingPrice: Number(item?.selling_price) || 0,
      uom: item?.UOM || 'unit',
      area: parseFloat(item?.area_types?.area) || 0,
      count: Number(item?.area_types?.count) || 0,
      total: Number(item?.total) || 0
    };
  
    const customizationTotal = item?.required_order_breakup?.reduce((sum, subItem) => {
      const price = Number(subItem?.selling_price) || 0;
      const quantity = Number(subItem?.sub_item_quantity) || 0;
      return sum + (price * quantity);
    }, 0) || 0;
  
    const validateSubItem = (subItem) => ({
      id: subItem?._id?.$oid || `subitem-${Math.random()}`,
      name: subItem?.sub_item_name || 'Customization Item',
      image: subItem?.sub_item_image || '',
      price: Number(subItem?.selling_price) || 0,
      quantity: Number(subItem?.sub_item_quantity) || 0,
      uom: subItem?.UOM || validateItem.uom
    });
  
    return (
      <div key={validateItem.id} className="mx-0 mt-4 lg:mx-8 bg-white rounded-lg shadow-sm overflow-hidden border border-gray-200">
        <div className="p-2 border-b border-gray-100">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faDoorOpen} className="text-[#EA011F] text-sm" />
              <span className="text-lg font-semibold text-[#EA011F]">
                {validateItem.areaName}
              </span>
            </div>
          </div>
        </div>
  
        <div className="p-2">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-3">
              <span className="text-base font-medium text-gray-800">{validateItem.name}</span>
            </div>
          </div>
  
          <div className="grid grid-cols-3 items-center">
            <p className="text-sm text-gray-500">
              {formatPrice(validateItem.sellingPrice)}/{validateItem.uom}
            </p>
            <p className="text-sm font-medium text-gray-500 text-center">
              {validateItem.uom.toLowerCase() === 'sq.ft'
                ? `${validateItem.area.toFixed(2)} sq.ft`
                : `${validateItem.count} Doors`
              }
            </p>
            <p className="font-bold text-md text-green-700 text-right">
              {formatPrice(validateItem.total)}
            </p>
          </div>
  
          {Array.isArray(item?.required_order_breakup) && item.required_order_breakup.length > 0 && (
            <div className="mt-3 border-t border-gray-100 pt-3">
              <div className="flex items-center justify-between mb-2">
                <h4 className="text-sm font-semibold text-gray-700">Customizations</h4>
                <span className="text-sm font-medium text-green-700">
                  +{formatPrice(customizationTotal)}
                </span>
              </div>
              <div className="space-y-2">
                {item.required_order_breakup.map((subItem) => {
                  const validSubItem = validateSubItem(subItem);
                  return (
                    <div key={validSubItem.id}
                         className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                      <div className="flex items-center">
                        {validSubItem.image && (
                          <div className="w-10 h-10 rounded-lg overflow-hidden mr-3">
                            <img
                              src={`${imageUrl}${validSubItem.image}`}
                              alt={validSubItem.name}
                              className="w-full h-full object-cover"
                              loading="lazy"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'fallback-image-url';
                              }}
                            />
                          </div>
                        )}
                        <div>
                          <p className="text-sm font-medium text-gray-800">
                            {validSubItem.name}
                          </p>
                          <p className="text-xs text-gray-500">
                            {formatPrice(validSubItem.price)} × {validSubItem.quantity.toFixed(2)} {validSubItem.uom}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
  
          <button
            onClick={() => {
              if (item) {
                setSelectedItem(item);
                setShowDoorDetails(true);
              }
            }}
            className="mt-3 w-full flex justify-between items-center p-3 bg-gray-50
                       rounded-lg hover:bg-gray-100 transition-colors duration-200"
          >
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faListAlt} className="text-gray-600" />
              <span className="font-medium text-gray-800">View Door Details</span>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
          </button>
        </div>
      </div>
    );
  };
  
  


  const DoorDetailsModal = ({ show, onClose, item }) => {
    if (!show || !item) return null;
  
    const validateDoorData = (door) => {
      const height = parseFloat(door?.height) || 0;
      const width = parseFloat(door?.width) || 0;
      const area = parseFloat(door?.area) || 0;
      
      return {
        height,
        width,
        area,
        unit: door?.unit || 'ft',
        door_name: door?.door_name || 'Door',
        room_name: door?.room_name || 'Room'
      };
    };
  
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-50 animate-fadeIn"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <div
          className="fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl max-h-[80vh] 
                     overflow-y-auto transform transition-all duration-300 ease-out"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="sticky top-0 bg-white border-b border-gray-200 p-4">
            <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faDoorOpen} className="text-[#EA011F]" />
                <h3 className="text-lg font-semibold text-[#EA011F]">
                  {item?.area_types?.area_name || 'Door Details'}
                </h3>
              </div>
              <button 
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <FontAwesomeIcon icon={faTimes} className="text-gray-500" />
              </button>
            </div>
          </div>
  
          <div className="p-4">
            {Array.isArray(item?.applied_areas_breakup) && item.applied_areas_breakup.length > 0 ? (
              item.applied_areas_breakup.map((door) => {
                const validatedDoor = validateDoorData(door);
                return (
                  <div key={door?._id || Math.random()} className="mb-3 bg-gray-50 rounded-lg overflow-hidden">
                    <div className="p-3 border-b border-gray-100">
                      <div className="flex justify-between items-start">
                        <div className="flex items-center gap-2">
                          <div className="w-8 h-8 bg-red-50 rounded-full flex items-center justify-center">
                            <FontAwesomeIcon icon={faDoorClosed} className="text-[#EA011F] text-sm" />
                          </div>
                          <div>
                            <h4 className="text-base font-semibold text-gray-800">
                              {validatedDoor.door_name}
                            </h4>
                            <p className="text-sm text-gray-600">
                              {validatedDoor.room_name}
                            </p>
                          </div>
                        </div>
                        <span className="text-sm font-medium text-green-700">
                          {validatedDoor.area.toFixed(2)} sq.ft
                        </span>
                      </div>
                    </div>
  
                    <div className="p-3">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="bg-white p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <span className="text-sm text-gray-600">Height</span>
                            <span className="text-sm font-medium text-gray-800">
                              {validatedDoor.height} {validatedDoor.unit}
                            </span>
                          </div>
                        </div>
                        <div className="bg-white p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <span className="text-sm text-gray-600">Width</span>
                            <span className="text-sm font-medium text-gray-800">
                              {validatedDoor.width} {validatedDoor.unit}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col items-center justify-center py-8">
                <FontAwesomeIcon icon={faDoorClosed} className="text-gray-400 text-4xl mb-2" />
                <p className="text-gray-500">No door details available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  
  
  return (
    <div className="flex-1 bg-gray-50 min-h-screen">
      {renderHeader()}
      
      <div className="p-4 pb-32">
        {Array.isArray(quotationData?.items) && quotationData.items.length > 0 ? (
          quotationData.items.map((item, index) => (
            <div key={item?._id || `item-${index}`}>
              {renderDoorItem(item)}
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center py-8">
            <FontAwesomeIcon icon={faDoorClosed} className="text-gray-400 text-4xl mb-2" />
            <p className="text-gray-500">No items available</p>
          </div>
        )}
      </div>
  
      <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg z-40">
  <div className="max-w-md mx-auto">
    <div className="grid grid-cols-2 gap-2">
      <div className="col-span-2 flex flex-col space-y-1">
        <div className="flex justify-between items-center p-1">
          <span className="text-sm text-gray-600">Base Amount</span>
          <span className="text-sm font-medium text-gray-700">
            {new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(quotationData?.subtotal || 0)}
          </span>
        </div>
        
        <div className="flex justify-between items-center p-1">
          <span className="text-sm text-gray-600">Tax Amount</span>
          <span className="text-sm font-medium text-gray-700">
            +{new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(quotationData?.tax_amount || 0)}
          </span>
        </div>

        <div className="flex justify-between items-center p-1 bg-gray-50 rounded-lg">
          <span className="text-base font-medium text-gray-700">Total Amount</span>
          <span className="text-lg font-bold text-green-700">
            {new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(quotationData?.total || 0)}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

  
      <DoorDetailsModal
        show={showDoorDetails}
        onClose={() => setShowDoorDetails(false)}
        item={SelectedItem}
      />
    </div>
  );
  
  
};

export default DoorQuotationDetails;
