import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHandshake, faPhone, faStore, faArrowRight,
  faMapMarkerAlt, faClock, faShield, faStar,
  faGem, faUserTie, faTools, faChevronRight 
} from '@fortawesome/free-solid-svg-icons';



  const QuoteSummaryContent = ({onInterested}) => {
    const [showTermsModal, setShowTermsModal] = useState(false);

    const handleGoogleMapsClick = () => {
    const mapUrl = "https://www.google.com/maps?q=KRAFTO+Tiles,+Vijayawada";
    window.open(mapUrl, '_blank');
  };
  
  const WhatNext = () => {
    return (
      <div className="bg-white rounded-xl p-5 mb-6 shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-xl font-bold text-gray-800">What's Next?</h2>
            <p className="text-sm text-gray-500">Follow the steps below</p>
          </div>
          <div className="w-2 h-8 bg-gradient-to-b from-[#EA011F] to-red-400 rounded-full"/>
        </div>
      </div>
    );
  };
  
  const termsContent = (
    <>
      <h4 className="font-semibold mb-3">1. Acceptance of Terms</h4>
      <p className="mb-4">By accessing and using Brickbucket's services, you agree to these terms and conditions.</p>
  
      <h4 className="font-semibold mb-3">2. Installation Services</h4>
      <p className="mb-4">All installations are carried out by certified Brickbucket professionals following industry standards.</p>
  
      <h4 className="font-semibold mb-3">3. Warranty</h4>
      <p className="mb-4">Products come with manufacturer warranty. Installation warranty provided by Brickbucket.</p>
  
      <h4 className="font-semibold mb-3">4. Payment Terms</h4>
      <p className="mb-4">Payment schedules and terms as agreed in the quotation.</p>
  
      <h4 className="font-semibold mb-3">5. Cancellation Policy</h4>
      <p>Details about order modifications and cancellations.</p>
    </>
  );
  
  const VisitStore = () => {
    return (
      <div className="relative bg-white rounded-xl p-5 shadow-sm border border-gray-100">
        <span className="absolute -top-2 right-3 px-2 py-1 bg-red-50 rounded-full text-xs text-[#EA011F] font-medium">
          Recommended
        </span>
  
        <div className="flex items-center gap-3 mb-4">
          <div className="w-12 h-12 bg-red-50 rounded-lg flex items-center justify-center">
            <FontAwesomeIcon icon={faStore} className="text-[#EA011F] text-lg" />
          </div>
          <div>
            <h3 className="text-base font-semibold text-gray-800">Visit Center</h3>
            <p className="text-xs text-gray-500">Touch & feel premium products</p>
          </div>
        </div>
  
        <div className="grid grid-cols-2 gap-2 mb-4">
          {[
            "See actual designs",
            "Expert consultation",
            "Compare materials",
            "Live demo"
          ].map((benefit, index) => (
            <div key={index} className="flex items-center gap-2 bg-gray-50 p-2 rounded-lg">
              <div className="w-0.5 h-4 bg-gray-400 rounded-full"/>
              <span className="text-xs text-gray-600">{benefit}</span>
            </div>
          ))}
        </div>
  
        <div className="bg-gray-50 p-3 rounded-lg mb-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faClock} className="text-[#EA011F] text-xs" />
              <span className="text-xs text-gray-600">10 AM - 8 PM</span>
            </div>
            <span className="w-1.5 h-1.5 bg-green-500 rounded-full animate-pulse"/>
          </div>
          <div className="flex gap-2">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#EA011F] text-xs mt-1" />
            <p className="text-xs text-gray-600 leading-tight">
              1st Floor, Swarna Grand, NH 16 Service Rd, Nagarjuna Nagar, Vijayawada
            </p>
          </div>
        </div>
      
  
        <button 
  onClick={handleGoogleMapsClick}
  className="group w-full flex items-center justify-center gap-3 bg-gradient-to-r from-red-50 to-red-100 border-2 border-[#EA011F] text-[#EA011F] px-4 py-2.5 rounded-lg transition-all duration-300 hover:bg-[#EA011F] hover:text-red-100 hover:shadow-lg hover:shadow-red-100 active:scale-[0.98]"
>
  <span className="font-medium text-sm tracking-wide">View In Google Maps</span>
  <div className="relative">
    <svg
      className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 group-hover:opacity-0"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
    </svg>
    <svg
      className="w-4 h-4 absolute top-0 left-0 transition-all duration-300 -translate-x-3 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
    </svg>
  </div>
</button>

      </div>
    );
  };
   

  const ImInterested = () => {
    return (
      <div className="bg-white rounded-xl p-5 shadow-sm border border-gray-100">
        <div className="flex items-center gap-3 mb-4">
          <div className="w-12 h-12 bg-red-50 rounded-lg flex items-center justify-center">
            <FontAwesomeIcon icon={faHandshake} className="text-[#EA011F] text-lg" />
          </div>
          <div>
            <h3 className="text-base font-semibold text-gray-800">Mark As Interested</h3>
            <p className="text-xs text-gray-500">Stay updated with best offers</p>
          </div>
        </div>
  
        <div className="grid grid-cols-1 gap-2 mb-4">
          {[
            "Get instant price drop notifications",
            "Compare with premium collections",
            "Priority customer support access"
          ].map((benefit, index) => (
            <div key={index} className="flex items-center gap-2 bg-gray-50 p-2 rounded-lg">
              <div className="w-0.5 h-4 bg-gray-400 rounded-full"/>
              <span className="text-xs text-gray-600">{benefit}</span>
            </div>
          ))}
        </div>

        
  
        <button className="group w-full flex items-center justify-center gap-3 bg-gradient-to-r from-red-50 to-white border-2 border-[#EA011F] text-[#EA011F] px-4 py-2.5 rounded-lg transition-all duration-300 hover:bg-[#EA011F] hover:text-white hover:shadow-lg hover:shadow-red-100 active:scale-[0.98]"
         onClick={onInterested}>
          <span className="font-medium text-sm tracking-wide">Im Interested</span>
          <div className="relative">
            <svg
              className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 group-hover:opacity-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
            </svg>
            <svg
              className="w-4 h-4 absolute top-0 left-0 transition-all duration-300 -translate-x-3 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
            </svg>
          </div>
        </button>
      </div>
    );
  };
  
  const requestCallback = () =>{
    return (
      <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
  <div className="flex items-center gap-4 mb-6">
    <div className="w-14 h-14 bg-gradient-to-br from-red-50 to-red-100 rounded-xl flex items-center justify-center">
      <FontAwesomeIcon icon={faPhone} className="text-[#EA011F] text-xl" />
    </div>
    <div>
      <h3 className="text-lg font-bold text-gray-800">Expert Consultation</h3>
      <p className="text-sm text-gray-500">Get personalized design guidance</p>
    </div>
  </div>

  <div className="space-y-3 mb-6">
    {[
      "Discuss with our door design experts",
      "Get detailed product specifications",
      "Custom solutions for your space"
    ].map((benefit, index) => (
      <div key={index} className="flex items-center gap-3 bg-gray-50 p-3 rounded-lg">
        <FontAwesomeIcon icon={faStar} className="text-yellow-400 text-sm" />
        <span className="text-sm text-gray-700">{benefit}</span>
      </div>
    ))}
  </div>

  <div className="bg-gradient-to-r from-red-50 to-white p-4 rounded-xl mb-6">
    <div className="flex items-center justify-between">
      <div>
        <div className="flex items-center gap-2 mb-1">
          <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse"/>
          <span className="text-xs text-gray-500">Expert available now</span>
        </div>
        <p className="text-base font-semibold text-gray-800">+91 9701967272</p>
        <p className="text-xs text-gray-500 mt-1">Mon-Sat, 10 AM - 8 PM</p>
      </div>
      <a href="tel:+919701967272" className="w-10 h-10 bg-[#EA011F] rounded-full flex items-center justify-center shadow-sm hover:scale-105 transition-transform">
        <FontAwesomeIcon icon={faPhone} className="text-white" />
      </a>
    </div>
  </div>

<button className="group w-full flex items-center justify-center gap-3 bg-gradient-to-r from-red-50 to-white border-2 border-[#EA011F] text-[#EA011F] px-4 py-3.5 rounded-xl transition-all duration-300 hover:bg-[#EA011F] hover:text-white hover:shadow-lg hover:shadow-red-100 active:scale-[0.98]">
  <span className="font-medium text-sm tracking-wide">Request Callback</span>
  <div className="relative">
    <svg
      className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1 group-hover:opacity-0"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
    </svg>
    <svg
      className="w-4 h-4 absolute top-0 left-0 transition-all duration-300 -translate-x-3 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
    </svg>
  </div>
</button>


</div>

    )
  }
  
  return (
    <div className="px-4 py-6 mb-20 max-w-2xl mx-auto">
      {/* Premium Welcome Banner */}
      {WhatNext()}
    
      {/* Trust Indicators */}
      {/* <div className="flex justify-between mb-8">
        {[
          { icon: faShield, text: "Quality Assured" },
          { icon: faUserTie, text: "Expert Support" },
          { icon: faTools, text: "Pro Installation" }
        ].map((item, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="w-10 h-10 bg-red-50 rounded-full flex items-center justify-center mb-2">
              <FontAwesomeIcon icon={item.icon} className="text-[#EA011F]" />
            </div>
            <span className="text-xs text-gray-600">{item.text}</span>
          </div>
        ))}
      </div> */}

      {/* Next Steps Cards */}
      <div className="space-y-6">
        {/* Save & Track Card */}
      {ImInterested()}

      {VisitStore()}

        {/* Expert Call Card */}
      
        {requestCallback()}

        {/* Experience Center Card */}
        <div className="bg-white rounded-2xl overflow-hidden shadow-sm border border-gray-100">
    <div className="relative h-48">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.8661790804444!2d80.67221177489614!3d16.518743088608843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35e59e85c4baa7%3A0x2b2d63f275dc3d6e!2sKRAFTO%20Tiles!5e0!3m2!1sen!2sin!4v1706521145617!5m2!1sen!2sin"
        className="w-full h-full"
        allowFullScreen=""
        loading="lazy"
      />
    </div>
    <div className="p-4">
      <div className="grid grid-cols-2 gap-3">
        <button onClick={handleGoogleMapsClick}
        className="bg-gray-50 hover:bg-gray-100 rounded-xl py-3.5 px-4 text-gray-700 text-sm font-medium transition-colors flex items-center justify-center gap-2">
          Get Directions
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-sm" />
        </button>
        <a
          href="tel:+919701967272"
          className="bg-gray-50 hover:bg-gray-100 rounded-xl py-3.5 px-4 text-gray-700 text-sm font-medium transition-colors flex items-center justify-center gap-2"
        >
          Call Store
          <FontAwesomeIcon icon={faPhone} className="text-sm" />
        </a>
      </div>
    </div>
  </div>
  <div className="bg-gray-100 py-4 mt-8 rounded-lg mb-6">
  <div className="flex flex-col items-center gap-3">
    <button 
      onClick={() => setShowTermsModal(true)} 
      className="text-sm text-gray-600 hover:text-[#EA011F] transition-colors flex items-center gap-1"
    >
      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
      </svg>
      Terms & Conditions
    </button>
    <p className="text-center text-gray-600 text-sm">
      © Brickbucket Build Solutions Pvt Ltd
    </p>
  </div>
</div>

  {showTermsModal && (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-[100]">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[80vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-bold text-gray-800">Terms & Conditions</h3>
            <button 
              onClick={() => setShowTermsModal(false)}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
        <div className="p-6">
          {/* Terms content goes here */}
          <div className="prose prose-sm max-w-none">
            {termsContent}
          </div>
        </div>
      </div>
    </div>
  )}

      </div>
    </div>
  );
};

export default QuoteSummaryContent;