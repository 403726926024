import { faArrowLeft, faBackspace, faBackward, faBalanceScale, faChevronDown, faChevronUp, faDoorOpen, faInfo, faInfoCircle, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { jwtDecode } from "jwt-decode";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FaWindowMaximize, FaRuler, FaCompass, FaInfoCircle, FaBalanceScale } from 'react-icons/fa';

const ViewQuotationDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    categoryId, 
    allFlatsData,
    customerId,
    projectId 
  } = location.state || {};

  //const { allFlatsData } = location.state || {};
  const [selectedData, setSelectedData] = useState(location.state?.selectedData || []);
  const [remainingFlats, setRemainingFlats] = useState(selectedData?.slice(1));
  const [currentFlatIndex, setCurrentFlatIndex] = useState(0);

 console.log(selectedData);
 console.log(remainingFlats);
 console.log(currentFlatIndex);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [products, setProducts] = useState([]);
  const [showComparison, setShowComparison] = useState(false);
  const [vendorProducts, setVendorProducts] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [activeSelection, setActiveSelection] = useState(null);
  const [selectedVendorProducts, setSelectedVendorProducts] = useState({});
  //const [showComparison, setShowComparison] = useState(false);
  const [bulkSelectionMode, setBulkSelectionMode] = useState(false);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [isBulkMode, setIsBulkMode] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showAffectedRooms, setShowAffectedRooms] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
const [tempSelection, setTempSelection] = useState(null);
const [popupSelections, setPopupSelections] = useState({});
const [productGroups, setProductGroups] = useState({});
const [selectedGroups, setSelectedGroups] = useState({});
const [showSummary, setShowSummary] = useState(false);
const [selectionSummary, setSelectionSummary] = useState([]);
const [showSelectionView, setShowSelectionView] = useState(true);





  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const [isInitializing, setIsInitializing] = useState(true);


  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES

  useEffect(() => {
    const loadData = async () => {
      setIsInitializing(true);
      
      if (categoryId) {
        await fetchVendorsByProductCategory();
      }
      
      // Ensure minimum 2 second loading time
      setTimeout(() => {
        setIsInitializing(false);
      }, 2000);
    };
    
    loadData();
  }, [categoryId]);
  
  

  useEffect(() => {
    if (vendors && vendors.length > 0) {
      fetchProductGroups(vendors);
    }
  }, [vendors]);

  const fetchVendorsByProductCategory = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}vendor/get-vendorsBy-productCategoryId?product_category_id=${categoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      if (response.ok) {
       // console.log(data.data);
        const filteredVendors = data.data.filter(vendor => vendor.name !== "Any Vendor");
      setVendors(filteredVendors);
      fetchProductGroups(filteredVendors);
      //console.log(filteredVendors);
        return data.data;
      } else {
        if (response.status === 404) {
          setProducts([]);
          setPopupMessage('No vendor found.');
          setPopupType('error');
        } else if (response.status === 500) {
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setRetryFunction(() => () => fetchVendorsByProductCategory(attempt));
        }
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch vendors:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchVendorsByProductCategory(attempt + 1), 1000);
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchVendorsByProductCategory(attempt));
      }
    } finally {
      setIsLoading(false);
    }
  };

 const fetchProductsForVendors  = async (vendors, attempt = 1) => {
    try {
      const productsData = {};
      const token = sessionStorage.getItem('token');
  
      for (const vendor of vendors) {
        const response = await fetch(`${fetchUrl}product/get-products-by-category-and-vendor?product_category_id=${categoryId}&vendor_id=${vendor._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
  
        const productData  = await response.json();
        
        if (response.ok) {
          console.log(productData.data);
          productsData[vendor._id] = productData.data;
        } else {
          if (response.status === 404) {
            productsData[vendor._id] = [];
            setPopupMessage('No products found for selected vendor');
            setPopupType('error');
          } else if (response.status === 500) {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setRetryFunction(() => () => fetchProductsForVendors (attempt));
          }
          setShowPopup(true);
        }
      }
      
      setProducts(productsData);
      return productsData;
  
    } catch (error) {
      console.error('Failed to fetch products:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchProductsForVendors (attempt + 1), 1000);
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductsForVendors (attempt));
      }
    } finally {
      setIsLoading(false);
    }
  };

  
  const fetchProductGroups = async (vendorsList, attempt = 1) => {
    try {
      const groupsData = {};
      const token = sessionStorage.getItem('token');
  
      for (const vendor of vendorsList) {
        const response = await fetch(
          `${fetchUrl}product-groups/get-productGroups-by-vendor-and-productCategory?product_category_id=${categoryId}&vendor_id=${vendor._id}`, 
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          }
        );
  
        const groupData = await response.json();
       // console.log(groupData.data);
        
        if (response.ok) {
          groupsData[vendor._id] = groupData.data;
        } else {
          if (response.status === 404) {
            groupsData[vendor._id] = [];
          } else if (response.status === 500) {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setRetryFunction(() => () => fetchProductGroups(vendorsList, attempt));
          }
          setShowPopup(true);
        }
      }
      
      setProductGroups(groupsData);
      return groupsData;
  
    } catch (error) {
      console.error('Failed to fetch product groups:', error);
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchProductGroups(vendorsList, attempt + 1), 1000);
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductGroups(vendorsList, attempt));
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  

  

/*   const handleVendorSelect = (vendor) => {
    setSelectedVendor(currentSelected => {
      const currentSelectedArray = Array.isArray(currentSelected) ? currentSelected : [];
      const isSelected = currentSelectedArray.find(v => v._id === vendor._id);
      
      if (isSelected) {
        return currentSelectedArray.filter(v => v._id !== vendor._id);
      }
      return [...currentSelectedArray, vendor];
    });
  };

  const handleNext = async () => {
    if (selectedVendor.length > 0) {
      setShowComparison(true);
      await fetchAllVendorProducts();
    }
  }; */

  const handleProductSelect = (vendorId, product) => {
    const key = `${vendorId}-${product._id}`;
    setPopupSelections(prev => {
      const newSelection = { ...prev };
      if (newSelection[key]) {
        delete newSelection[key];
      } else {
        newSelection[key] = {
          vendorId,
          product
        };
      }
      return newSelection;
    });
  };
    
    const handleBulkProductSelect = (vendorId, product) => {
      selectedData.forEach((flat) => {
        flat.selections.forEach((selection) => {
          if (selection.selectedSubcategory._id === activeSelection.selection.selectedSubcategory._id) {
            const key = `${vendorId}-${product._id}`;
            setSelectedProducts(prev => ({
              ...prev,
              [key]: {
                vendorId,
                product
              }
            }));
          }
        });
      });
      
      // Close the selection modal
      setActiveSelection(null);
      
      // Show success message
      setShowSuccessMessage(true);
    };

    const getSimilarWindows = () => {
      return selectedData.flatMap(flat => 
        flat.selections.filter(selection => 
          selection.selectedSubcategory._id === activeSelection.selection.selectedSubcategory._id
        ).map(selection => ({
          flatNo: flat.flatNo,
          roomName: selection.roomName
        }))
      );
    };
    
    const handleBulkApply = () => {
      selectedData.forEach((flat) => {
        flat.selections.forEach((selection) => {
          // Check if it's the same subcategory
          if (selection.selectedSubcategory._id === activeSelection.selection.selectedSubcategory._id) {
            // Create unique key for this window
            const existingKey = `${flat.flatNo}-${selection.roomName}-${selection.windowIndex}`;
            
            // Only apply if no selection exists for this window
            if (!Object.keys(selectedProducts).some(key => key.startsWith(existingKey))) {
              Object.values(popupSelections).forEach(({ vendorId, product }) => {
                const key = `${flat.flatNo}-${selection.roomName}-${selection.windowIndex}-${vendorId}-${product._id}`;
                setSelectedProducts(prev => ({
                  ...prev,
                  [key]: {
                    vendorId,
                    product,
                    flatIndex: flat.flatNo,
                    roomName: selection.roomName,
                    windowIndex: selection.windowIndex
                  }
                }));
              });
            }
          }
        });
      });
      setShowComparison(false);
      setPopupSelections({});
      setShowSuccessMessage(true);
    };

    // Handle initial selection click
    const handleSelectClick = (vendorId, product) => {
      setTempSelection({
        vendorId,
        product,
        flatNo: activeSelection.flatIndex,
        roomName: activeSelection.selection.roomName,
        windowIndex: activeSelection.selection.windowIndex,
        subcategoryId: activeSelection.selection.selectedSubcategory._id
      });
      setShowConfirmation(true);
    };
    
    const handleSelectionConfirm = (vendorId, groupData) => {

    const allSelections = [];
      
      // Loop through all flats
      selectedData.forEach((flat) => {
        const flatSelections = flat.selections.map(selection => {
          const matchingProduct = groupData.products.find(product => 
            product.product_sub_category_id === selection.selectedSubcategory._id
          );
          
          if (matchingProduct) {
            return {
              flatNo: flat.flatNo,
              roomName: selection.roomName,
              windowSize: selection.windowSize,
              areaInSFT: selection.areaInSFT,
              product: matchingProduct,
              vendor: vendors.find(v => v._id === vendorId),
              subcategory: selection.selectedSubcategory.product_sub_category_name,
              selectedSubcategory: selection.selectedSubcategory,
              windowIndex: selection.windowIndex
            };
          }
        }).filter(Boolean);
        
        allSelections.push(...flatSelections);
      
      });
    
      navigate('/consumer/app/windowsummary', {
        state: {
          selectionSummary: allSelections,
          categoryId,
          vendorId,
          allProducts: products[vendorId] || [],
          selectedGroups,
          customerId,
          projectId,
          username: sessionStorage.getItem('username')
        }
      });
    
    };
    
    
    
    
    
    
 
const handleOpenSelection = (flatIndex, selection, windowIndex) => {
  setActiveSelection({ flatIndex, selection, windowIndex });
  // Clear previous selections for comparison
  setPopupSelections({});
  setShowComparison(false);
};

const handleGroupSelect = (vendorId, group) => {
  const key = `${vendorId}-${group._id}`;
  setSelectedGroups(prev => {
    const newSelection = { ...prev };
    if (newSelection[key]) {
      delete newSelection[key];
    } else {
      newSelection[key] = {
        vendorId,
        ...group
      };
    }
    return newSelection;
  });
};

/* const handleCompareClick = async () => {
  await fetchProductsForVendors(vendors);
  setShowComparison(true);
}; */

const handleCompareClick = async () => {
  await fetchProductsForVendors(vendors);
  setShowSelectionView(false);
};

const getSelectedProducts = () => {
  const selectedProductsData = {};
  
  if (!selectedGroups || !products) return selectedProductsData;

  // Group products by type first
  const productTypes = ['Casement', 'Sliding', 'French', 'Ventilator'];
  
  Object.entries(selectedGroups).forEach(([key, group]) => {
    const [vendorId] = key.split('-');
    if (products[vendorId]) {
      const vendorProducts = products[vendorId].filter(product => 
        product.product_group_id === group._id
      );

      // Sort products by type and series
      vendorProducts.sort((a, b) => {
        // First sort by type
        const typeA = productTypes.findIndex(type => a.product_name.includes(type));
        const typeB = productTypes.findIndex(type => b.product_name.includes(type));
        if (typeA !== typeB) return typeA - typeB;

        // Then sort by series number
        const seriesA = parseInt(a.product_name.match(/(\d+)\s*Series/)?.[1] || '0');
        const seriesB = parseInt(b.product_name.match(/(\d+)\s*Series/)?.[1] || '0');
        return seriesB - seriesA;
      });

      selectedProductsData[key] = {
        vendorId,
        ...group,
        products: vendorProducts
      };
    }
  });
  
  return selectedProductsData;
};


const handleApplyToNextFlat = () => {
  const nextFlat = remainingFlats[0];
  const currentSelections = selectionSummary.filter(
    selection => selection.flatNo === selectedData[currentFlatIndex].flatNo
  );

  const nextFlatSelections = currentSelections.map(selection => ({
    ...selection,
    flatNo: nextFlat.flatNo
  }));

  setSelectionSummary(prev => [...prev, ...nextFlatSelections]);
  setRemainingFlats(prev => prev.slice(1));
  setCurrentFlatIndex(prev => prev + 1);
  setShowComparison(false);
};



const handleConfirmSelections = () => {
  const currentSelections = selectionSummary;
  
  if (remainingFlats?.length > 0) {
    // Update state for next flat
    setSelectedData([remainingFlats[0]]);
    setRemainingFlats(remainingFlats.slice(1));
    
    // Reset UI for next flat
    setSelectionSummary([]);
    setSelectedGroups({});
    setShowSummary(false);
    
    // Show success message
    setPopupMessage(`Selections confirmed for Flat ${selectedData[0].flatNo}. Please make selections for Flat ${remainingFlats[0].flatNo}`);
    setPopupType('success');
    setShowPopup(true);
  } else {
    // All flats completed
    setPopupMessage('All flat selections completed!');
    setPopupType('success');
    setShowPopup(true);
    setShowSummary(false);
  }
};


const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="p-4 border-b">
      <div className="h-10 bg-gray-200 rounded w-1/3 mb-4"></div>
    </div>

    {[1, 2, 3].map((item) => (
      <div key={item} className="mb-4 p-4 bg-gray-100 rounded-lg">
        <div className="h-7 bg-gray-200 rounded w-3/4 mb-3"></div>
        <div className="grid grid-cols-2 gap-4">
          <div className="h-5 bg-gray-200 rounded"></div>
          <div className="h-5 bg-gray-200 rounded"></div>
          <div className="h-6 bg-gray-200 rounded col-span-2 mt-2"></div>
        </div>
      </div>
    ))}

    <div className="p-4 border-t bg-gray-50">
      <div className="flex justify-between items-center mb-6">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="h-10 bg-gray-200 rounded w-1/3"></div>
      </div>
      <div className="flex justify-center">
        <div className="h-12 bg-gray-200 rounded w-1/3"></div>
      </div>
    </div>
  </div>
);


  return (
    <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">

<div className="bg-white rounded-xl shadow-lg">
      {isInitializing ? (
        <SkeletonLoader />
      ) : (

      showSelectionView ? (
        <div className="bg-white rounded-xl shadow-lg">
          <div className="p-4 border-b">
            <h2 className="text-3xl font-bold text-[#1E3A8A]">Select any Series</h2>
          </div>
  
          <div className="bg-blue-50 p-4 mb-4 rounded-lg">
            <h3 className="text-lg font-bold mb-2">How to use:</h3>
            <ol className="list-decimal ml-4 space-y-2">
              <li>Select any series from each vendor you're interested in</li>
              <li>Click "Compare & Select" to view detailed comparisons</li>
              <li>Choose the best option for your needs</li>
            </ol>
          </div>
  
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  {vendors.map(vendor => (
                    <th key={vendor._id} className="p-3 border-b border-r last:border-r-0 min-w-[160px]">
                      <div className="flex items-center justify-center gap-2">
                        <img 
                          src={`${imageUrl}${vendor.image}`}
                          alt={vendor.name}
                          className="w-8 h-8 rounded-full object-cover ring-2 ring-white"
                        />
                        <span className="font-semibold text-md">{vendor.name}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {vendors.map(vendor => (
                    <td key={vendor._id} className="p-2 border-r last:border-r-0 align-top">
                      <div className="space-y-3">
                        {productGroups[vendor._id]?.map(group => (
                          <div
                            key={group._id}
                            onClick={() => handleGroupSelect(vendor._id, group)}
                            className="p-5 rounded-lg border bg-white hover:border-blue-500 transition-colors cursor-pointer"
                          >
                            <div className="flex items-center justify-between gap-2">
                              <div className="flex-1">
                                <h3 className="font-medium text-sm whitespace-nowrap">
                                  {group.product_group_name}
                                </h3>
                              </div>
                              <input
                                type="checkbox"
                                checked={selectedGroups[`${vendor._id}-${group._id}`]}
                                onChange={() => handleGroupSelect(vendor._id, group)}
                                className="w-4 h-4 text-blue-600 rounded cursor-pointer"
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
  
          <div className="fixed bottom-16 right-4 z-50">
            <button
              onClick={handleCompareClick}
              className="bg-[#2196F3] text-white px-2 py-1 rounded-lg shadow-lg hover:bg-[#1976D2] text-lg"
            >
              <div className="flex items-center gap-3">
                <span>Compare & Select</span>
                <span className="bg-white text-blue-600 rounded-full w-6 h-6 flex items-center justify-center text-md">
                  {Object.keys(selectedGroups).length}
                </span>
              </div>
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-lg">
          <div className="p-4 flex justify-between items-center z-10 border-b bg-white">
            <h2 className="text-xl font-bold text-gray-800">Compare Products</h2>
          </div>
  
          <div className="overflow-x-auto overflow-y-auto max-h-[calc(85vh-80px)]">
            <div className="flex min-w-[600px]">
              {Object.entries(getSelectedProducts()).map(([key, groupData]) => {
                const vendor = vendors.find(v => v._id === groupData.vendorId);

                return (
                  <div key={key} className="flex-1 border-l first:border-l-0 flex flex-col">
                    <div className="sticky top-0 z-10">
                    <div className="p-2 bg-gray-50 border-b">
                      <div className="flex items-center justify-center gap-3">
                        <img
                          src={`${imageUrl}${vendor.image}`}
                          alt={vendor.name}
                          className="w-10 h-10 rounded-full object-cover ring-2 ring-white"
                        />
                        <span className="font-medium text-lg">{vendor.name}</span>
                      </div>
                    </div>

                    <div className="bg-blue-50 p-3 border-b text-center">
                <span className="font-medium text-blue-700">
                {groupData.product_group_name}
                </span>
              </div>
              </div>
  
                    <div className="flex-1 p-4 space-y-4">
                      {groupData.products?.length > 0 ? (
                        groupData.products.map(product => (
                          <div key={product._id} className="bg-white rounded-lg shadow-sm border p-2 w-64 hover:shadow-md transition-shadow">
                            <div className="flex flex-col">
                              <div className="flex items-center gap-4">
                                <img
                                  src={`${imageUrl}${product.product_image}`}
                                  alt={product.product_name}
                                  className="w-16 h-16 rounded-lg object-cover flex-shrink-0"
                                />
                                <div>
                                  <h3 className="font-medium text-gray-900">{product.product_name}</h3>
                                  <div className="text-blue-600 font-bold mt-1">₹{product.selling_price}</div>
                                </div>
                              </div>
  
                              <div className="border-t pt-3 mt-4 min-h-[200px]">
                                <table className="w-full text-sm">
                                  <tbody>
                                    {product.specifications ?
                                      JSON.parse(product.specifications).map((spec, idx) => {
                                        const key = Object.keys(spec)[0];
                                        let value = spec[key];
                                        
                                        if (!key || !value) return null;
                                        
                                        const values = value.split(',').map(v => v.trim());
                                        
                                        return (
                                          <tr key={idx} className="border-b last:border-b-0">
                                            <td className="py-2 text-gray-500 w-1/3">{key}:</td>
                                            <td className="py-2 px-2">
                                              <div className="flex gap-2">
                                                {values.map((v, i) => (
                                                  <span key={i} className="bg-gray-100 px-2 py-1 rounded">
                                                    {v}
                                                  </span>
                                                ))}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })
                                      : <tr><td className="py-2"></td></tr>
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center text-gray-400">
                          No products available
                        </div>
                      )}
                    </div>
  
                    <div className="p-4 bg-gray-50 mt-auto border-t">
                      <button
                        onClick={() => handleSelectionConfirm(groupData.vendorId, groupData)}
                        className="w-full text-lg bg-[#4CAF50] text-white px-2 py-1 rounded hover:bg-[#45a049] transition-colors"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )
    )}
    </div>
    </div>
  );
  

};
export default ViewQuotationDetails;


