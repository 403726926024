import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash, faExclamationTriangle, faX, faPlusCircle, faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons';
 import { useLocation, useNavigate } from 'react-router-dom'; 
import '../Css/Animation.css';
import TableSearchDropDown from '../Components/tableSerchDropDown';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages'
import { validate } from '../CommenResponseAndErrorHandlers/commenUiErrorHandlers';
import { Loader ,Spinner } from '../Loader/Loader';
//import Dropdown from './DropDown'
// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  React.useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [ref, callback]);
};

const CustomDropdown = ({ options = [], selected, onSelect, labelField, valueField, placeholder, onAddNew }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  // Filter options based on the search term
  const filteredOptions = options.filter(option => {
    const label = option[labelField] || '';
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (name, id) => {
    onSelect(name, id);
    setIsOpen(false);
  };

  return (
    <div className="relative w-1/3" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected || placeholder}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-60 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          <input
            type="text"
            className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {filteredOptions.length ? (
            filteredOptions.map((option) => (
              <div
                key={option[valueField]}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleOptionClick(option[labelField], option[valueField])}
              >
                {option[labelField]}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No results found</div>
          )}
          {onAddNew && (
            <button
              type="button"
              className="flex items-center border-t p-2 mt-2 text-blue-500 "
              onClick={onAddNew}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="w-4 h-4 mr-2" />
              Add New
            </button>
          )}
        </div>
      )}
    </div>
  );
};
const EditProductGroups = () => {
    const location = useLocation();
    /* const {ItemGroupData} = location.state; */
    const { ItemGroupData } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryId , setSelectedCategoryId] = useState();
  const [attributes, setAttributes] = useState([]);
  const navigate= useNavigate()
  const [showAttributePopup, setAttributePopup] = useState(false);
  const [attributeName, setAttributeName] = useState('');
  const [productCategoryId, setProductCategoryId] = useState('');
  const [SelectedAttributeNameId,setSelectedAttributeNameId] = useState()
  const [ProductSubCategories,setProductSubCategories] = useState([])
  const [vendors,setVendors]= useState([])
  const [SubCategoryName,setSubCategoryName]= useState()
const [SubCategoryId,setSubCategoryId]= useState()
const [selectedVendorName , setSelectedVendorName] = useState()
const [selectedVendorId, setSelectedVendorId] = useState()
const [selectedSubCategoryId,setSelectedSubCategoryId]= useState()
const [selectedSubCategoryName,setSelectedSubCategoryName]= useState()
const [showCancelPopup, setShowCancelPopup] = useState(false);
const [selectedTax, setSelectedTax] = useState();
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [GroupImage, setGroupImage] = useState('');
  const [ProductGroupName, setProductGroupName] = useState('');
  const [imagePreviews, setImagePreviews] = useState({});
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    tax_preference: '',
    GST: '',
    IGST: ''
  });
  const [roleId, setRoleId] = useState(null);

    const [rows, setRows] = useState([
{ SNo: '', product_group_image:'', product_group_name: '', UOM: '', UOM_id: ' ',  cost_price: '',selling_price: '', product_group_type: 'main', product_group_description:''}
    ]);
    const [UomOptions , setUomOptions] = useState([]);
    const [SelectedUOM, setSelectedUOM] = useState('');
    const { 
      showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
      isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
      setPopupType } = useCommonResponseHandler();

      const RETRY_LIMIT = 3; // Maximum number of retries
      const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  
     

      useEffect(() => {
        const { ItemGroupData } = location.state;
        if (ItemGroupData && UomOptions.length > 0) {
          const selectedUOM = UomOptions.find(option => option._id === ItemGroupData.UOM_id._id);
          setSelectedUOM(selectedUOM);
          setRows([{
            ...ItemGroupData,
            UOM: selectedUOM ? selectedUOM.label : '',
            UOM: selectedUOM ? selectedUOM.value : '',
            UOM_id: ItemGroupData.UOM_id._id || '',
          }]);
        }
      }, [location.state, UomOptions]);
      
      const handleGetAllUoms = async (attempt = 1) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
      
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          if (output) {
            // Log the data to ensure it's fetched correctly
                  
            const uomData = output.map((item) => ({
              _id: item._id,
              value: item.UOM,
              label: item.UOM,
            }));
            setUomOptions(uomData);
           return uomData
           
          } else {
            console.error('Error:', data.message);
            return ([])
          
           
          }
        }catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
          }
        }finally {
          setIsLoading(false);
        }
      };

      useEffect(() => {
        handleGetAllUoms();
      }, []);
      

    const handleInputChange = (index, field, value) => {
      const updatedRows = [...rows];
      updatedRows[index][field] = value;
      setRows(updatedRows);
    };
   
    const addRow = () => {
      if(selectedCategoryId){
        setRows([...rows, { SNo: '',product_group_image:'', product_group_name: '', UOM: '', UOM_id: ' ',  cost_price: '',selling_price: '', product_group_type: 'main', product_group_description:''}]);
      }else{
        console.log (' you need to select the item Category and attribute name ')
      }
      
    };

    const Taxoptions = [
      { label: 'Taxable', value: 'taxable' },
      { label: 'Non-Taxable', value: 'nontaxable' },
      { label: 'Non-GST supply', value: 'nongstsupply' }
    ];

    const handleTaxDropdownChange = (value, label) => {
      setSelectedTax(label);
    };
    
    const handleGetProductSubCategories = async (id,attempt = 1)=>{
      try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(fetchUrl + `product-sub-category/get-product-subCategories-and-vendors-by-productCategory?product_category_id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
          const data = await response.json();
          const output = await handleGetResponse(response, data);
          if (output) {
           
            const vendorsData = output.AllAssignedVendors.map(vendor => ({
              _id: vendor._id,
              vendor_name: vendor.vendor_name,
            }));

            const categories = output.subCategories.map(cat => ({
              _id: cat._id,
              product_sub_category_name: cat.product_sub_category_name,
            }));
            // Map data to expected format
           /*  const categories = data.subCategories.map(cat => ({
              product_sub_category_id: cat._id,
              product_sub_category_name: cat.product_sub_category_name
            })); */
            setProductSubCategories(categories);
            setVendors(vendorsData)
            
          } else {
              setProductSubCategories([]);
              setVendors([]);
            console.error(data.message);
          }
        } catch (error) {
          console.error('Failed to fetch:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1); // Increment retry count
            setTimeout(() => handleGetProductSubCategories(id,attempt + 1), 1000); // Retry after 1 second
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => handleGetProductSubCategories(id,attempt)); // Set retry function
          }
        }finally {
          setIsLoading(false);
        }
    }

    useEffect(() => {
      if (ItemGroupData) {
        setSelectedCategory(ItemGroupData.product_category_id.product_category_name);
        setSelectedCategoryId(ItemGroupData.product_category_id._id);
        
        if (ItemGroupData.product_sub_category_id) {
          setSubCategoryName(ItemGroupData.product_sub_category_id.product_sub_category_name);
          setSubCategoryId(ItemGroupData.product_sub_category_id._id);
        }
        
        if (ItemGroupData.vendor_id) {
          setSelectedVendorName(ItemGroupData.vendor_id.name);
          setSelectedVendorId(ItemGroupData.vendor_id._id);
        }
        if(ItemGroupData.tax_preference){
          setSelectedTax(ItemGroupData.tax_preference)
          setData({
            tax_preference: ItemGroupData.tax_preference,
            GST: ItemGroupData.GST,
            IGST: ItemGroupData.IGST
            })
        }

        
        handleGetProductSubCategories(ItemGroupData.product_category_id._id);
      }
    }, [ItemGroupData]);
    
    
   useEffect(() => {
    fetchRoleId();
    fetchProductCategories();
   }, []) ;
    
   const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const categoriesToDisplay = roleId === 6 
  ? productCategories.filter(category => (category?.name.toLowerCase()) === 'tiles')
  : productCategories;

  const fetchProductCategories = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      const output = await handleGetResponse(response, data);  
      if (output) {
        // Log the data to ensure it's fetched correctly
        // Map data to expected format
        const categories = output.map(cat => ({
          id: cat._id,
          name: cat.product_category_name,
          value: cat.product_category_name,
        }));
        setProductCategories(categories);
        
      } else {
        if(response.status === 500){
          
          setRetryFunction(() => () => fetchProductCategories); 
        }
        console.error(data.message);
      }
    }  catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = async (name, id) => {
 
      setSelectedCategory(name);
      setSelectedCategoryId(id);
  }
  const closePopup = () => {
    setShowPopup(false);
  };
  
  const Retry = async() => {
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };
 
  if (isLoading) {
    return <Loader />;
  }
  

 /*  const fetchAttributes = async (categoryId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${categoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        const formattedAttributes = data.map(attr => ({
          attribute_name: attr.attribute_name,
          attribute_name_id: attr.attribute_name_id
        }));
        setAttributes(formattedAttributes);
        setRows([{ SNo: 1, Name: '', Values: '', attribute_name_id: '' }])
        setSelectedAttribute('')
        return formattedAttributes;
      } else {
        setAttributes([])
        setSelectedAttribute('')
        setSelectedAttributeNameId()
        setRows([{ SNo: 1, Name: '', Values: '', attribute_name_id: '' }])
        console.error(data.message);
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
      return [];
    }
  }; */

 

 
  

 
  const handleClosePopup = () => {
    setAttributePopup(false);
    
  };
  
  
 

//  const handleSave = async (attempt = 1) => {
//   const ProductGroupsData = handleProductGroupsData(rows);
//   const formData = new FormData();

//   // Ensure we're sending string IDs
//   formData.append('product_category_id', selectedCategoryId);
//   formData.append('vendor_id', selectedVendorId);

//   if (SubCategoryId) {
//     formData.append('product_sub_category_id', SubCategoryId);
//   }

//   ProductGroupsData.forEach((group, index) => {
//     if (group.product_group_image instanceof File) {
//       formData.append(`product_groups[${index}][product_group_image]`, group.product_group_image);
//     } else {
//       formData.append(`product_groups[${index}][product_group_image]`, group.product_group_image || '');
//     }
//     formData.append(`product_groups[${index}][UOM_id]`, group.UOM_id);
//     formData.append(`product_groups[${index}][product_group_description]`, group.product_group_description || '');
//     formData.append(`product_groups[${index}][product_group_name]`, group.product_group_name);
//     formData.append(`product_groups[${index}][cost_price]`, group.cost_price);
//     formData.append(`product_groups[${index}][selling_price]`, group.selling_price);
//   });

//   try {
//     const token = sessionStorage.getItem('token');
//     const saveUrl = `${fetchUrl}product-groups/create-productGroups`;

//     const response = await fetch(saveUrl, {
//       method: 'POST',
//       headers: {
//         'x-token': token,
//       },
//       body: formData,
//     });

//     const responseData = await response.json();
//     const output = await handleSaveResponse(response, responseData);
//     if (output) {
// 
//       setAttributePopup(false);
//     } else {
//       console.error(responseData.message);
//     }
//   } catch (error) {
//     console.error('Failed to fetch:', error);
//     if (attempt < RETRY_LIMIT) {
//       setRetryCount(attempt + 1);
//       setTimeout(() => handleSave(attempt + 1), 1000);
//     } else {
//       setIsLoading(false);
//       setPopupMessage('Internal Server Error. Kindly Retry.');
//       setPopupType('500');
//       setShowPopup(true);
//       setRetryFunction(() => () => handleSave(attempt));
//     }
//   }
// };

  
  

  
const handleProductGroupsData = (data) => {

  return data.map((item) => ({  
    product_group_image: item.product_group_image, // Image URL or identifier
    UOM_id: item.UOM_id,
    product_group_description: item.product_group_description,
    product_group_name: item.product_group_name,
    cost_price: item.cost_price,
    selling_price: item.selling_price,
    _id: item._id,
    product_group_type: item.product_group_type,
  }));
}

const handleSaveProductGroups = async (attempt = 1) => {
  const hasMissingName =  rows.some((item) => !item.product_group_name || item.product_group_name.trim() === '');
  
  const fields = [
    { name: 'product_group_name', value: !hasMissingName, required: true,  message: 'Item group name is required', usage: 'product group name' },
    { name: 'product_category', value: selectedCategoryId, required: true,  message: 'select a Item category ', usage: 'product category'},
    { name: 'vendor', value: selectedVendorId, required: true,  message: 'select a vendor ', usage: 'vendor'},
  ];

  const validationErrors = validate(fields);
  setErrors(validationErrors);

  // Proceed only if there are no validation errors
  if (Object.keys(validationErrors).length === 0) {
    setIsSaving(true)
  const ProductGroupsData = handleProductGroupsData(rows);
  const GroupsData = {
    product_category_id: selectedCategoryId,
    vendor_id: selectedVendorId,
    product_sub_category_id: SubCategoryId || null,
    product_groups: ProductGroupsData
  };
  

  const formData = new FormData();

  // Append the data to FormData
  formData.append('product_category_id', GroupsData.product_category_id);
  formData.append('vendor_id', GroupsData.vendor_id);
  formData.append('tax_preference',selectedTax);
  formData.append('GST',data.GST);
  formData.append('IGST',data.IGST)
  if(GroupsData?.product_sub_category_id){
  formData.append('product_sub_category_id', GroupsData?.product_sub_category_id);
  }
  ProductGroupsData.forEach((group, index) => {
    formData.append(`product_groups[${index}][_id]`, group._id); // Assuming product_group_image is a file
    formData.append(`product_groups[${index}][product_group_image]`, group.product_group_image); // Assuming product_group_image is a file
    formData.append(`product_groups[${index}][UOM_id]`, group.UOM_id);
    formData.append(`product_groups[${index}][product_group_description]`, group.product_group_description);
    formData.append(`product_groups[${index}][product_group_name]`, group.product_group_name);
    formData.append(`product_groups[${index}][cost_price]`, group.cost_price);
    formData.append(`product_groups[${index}][selling_price]`, group.selling_price);
    formData.append(`product_groups[${index}][product_group_type]`, group.product_group_type);
    formData.append(`product_groups[${index}][organizationId]`, sessionStorage.getItem('organizationId'));
  });
  try {
    const token = sessionStorage.getItem('token');
    const url = fetchUrl + 'product-groups/update-productGroups'; // Update to your actual endpoint

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'x-token': token,
      },
      body: formData, // Send FormData object
    });
    const result = await response.json(); // Parse the response as JSON
    const output = await handleSaveResponse(response , result)
    if (output) {
      setTimeout(() => {
        setShowPopup(false);
        navigate('/bb/app/items/productgroupslist');
      }, 2000);
    } else {
     
      console.error('Error:', result.message);
    }

  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleSaveProductGroups(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleSaveProductGroups(attempt)); // Set retry function
    }
  } finally{
    setIsSaving(false)
  }
}
}
  
  

const handleSubCategoryChange = (name , id ) =>{
setSubCategoryName(name);
setSubCategoryId(id)
}
const handleVendorselecet = (name ,id)=>{
setSelectedVendorName(name);
setSelectedVendorId(id)
}


const handleUomChange = (index, selectedOption) => {
  setRows(prevRows => {
    const newRows = [...prevRows];
    newRows[index].UOM = selectedOption.label;
    newRows[index].UOM = selectedOption.value;
    newRows[index].UOM_id = selectedOption._id;
    return newRows;
  });
  setSelectedUOM(selectedOption);
};


const getFilteredUomOptions = (index) => {
 /*  const selectedValues = Object.values(selectedUoms).filter((_, i) => i !== index);
  return UomOptions.filter(option => !selectedValues.includes(option.value)); */
  return UomOptions
};

/* const handleImageChange = (index, event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Update the specific row's product_group_image with the data URL
      setRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[index].product_group_image = reader.result; // Use reader.result to get the data URL
        return newRows;
      });

      // Optionally, update selectedImages state if needed elsewhere
      setSelectedImages((prevSelectedImages) => ({
        ...prevSelectedImages,
        [index]: file
      }));
    };

    reader.readAsDataURL(file); // Read the file as a data URL
  }
}; */

/* const handleImageChange = (index, event) => {
  const file = event.target.files[0];
  if (file) {
    setRows((prevRows) => {
        setGroupImage(URL.createObjectURL(file));
      const newRows = [...prevRows];
      newRows[index].product_group_image = file;
      return newRows;
    });
  }
}; */

const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a preview URL for the selected file
      const previewUrl = URL.createObjectURL(file);

      // Update the local state for image previews
      setImagePreviews(prev => ({
        ...prev,
        [index]: previewUrl
      }));

      // Update the rows' state with the new file
      setRows(prevRows => {
        const newRows = [...prevRows];
        newRows[index].product_group_image = file; // Store the file itself
        return newRows;
      });
    }
}

const handleCancel = () => {
  setShowCancelPopup(true);
}

const handleStayHere = () => {
  setShowCancelPopup(false);
}
const handleLeave = () => {
  navigate(-1);
}

const handleChange = (e) => {
  const { id, value } = e.target;
  if (id === 'cost_price' || id === 'selling_price') {
    setData((prevState) => ({ ...prevState, [id]: parseFloat(value) }));
  } else {
    setData((prevState) => ({ ...prevState, [id]: value }));
  }
};

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
        
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">Edit Item Groups</h1>
                  
                </div>
              </div>
              {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors.vendor && (
              <span className="text-sm">
                • {errors.vendor}
              </span>
            )}
            {errors.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors.product_group_name && (
              <span className="text-sm">
                • {errors.product_group_name}
              </span>
            )}
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="selectedCategory" className="block text-sm font-regular text-[#e54643] w-1/6">Item Category Name<span className="text-red-500">*</span></label>
                    <CustomDropdown
                      options={categoriesToDisplay}
                      selected={selectedCategory}
                      onSelect={handleCategoryChange}
                      labelField="name"
                      valueField="id"
                      placeholder="Select Product Category"
                    />
                  </div>
                </div>

               

                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="SubCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">Item Sub Category Name</label>
                    <CustomDropdown
  options={ProductSubCategories}
  selected={SubCategoryName || ''}
  onSelect={handleSubCategoryChange}
  labelField="product_sub_category_name"
  valueField="_id"
  placeholder="Select Product Sub Category"
/>

                  </div>
                </div>

                <div className="w-full flex flex-col justify-start items-start px-4 ">
                  <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5">
                    <label htmlFor="Attribute" className="block text-sm font-regular text-[#e54643] w-1/6">Vendors<span className="text-red-500">*</span></label>
                    <CustomDropdown
  options={(vendors || []).map(vendor => ({
    id: vendor._id,
    vendor_name: vendor.vendor_name,
  }))}
  selected={selectedVendorName || ''}
  onSelect={handleVendorselecet}
  labelField="vendor_name"
  valueField="_id"
  placeholder="Select a vendor"
/>

                  </div>
                </div>
                <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2 px-4">
                  <label htmlFor="tax" className="block text-sm font-regular text-gray-700 w-1/6">Tax Preference</label>
                  <CustomDropdown
              options={Taxoptions}
              selected={selectedTax}
              labelField="label"
              valueField="value"
              onSelect={handleTaxDropdownChange}
              showAddNew={false}
              placeholder="Select"
            />
                </div>

     {/* Conditionally Render GST and IGST Fields */}
     {selectedTax === 'taxable' && (
        <>
          <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2 px-4">
            <label htmlFor="GST" className="block text-sm font-regular text-gray-700 w-1/6">GST (%)</label>
            <input
              type="text"
              id="GST"
              className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
               value={data.GST}
               onChange={ handleChange}
            />
          </div>
          <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2 px-4">
            <label htmlFor="IGST" className="block text-sm font-regular text-gray-700 w-1/6">IGST (%)</label>
            <input
              type="text"
              id="IGST"
              className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
               value={data.IGST}
               onChange={ handleChange}
            />
          </div>
        </>
      )}
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-md font-semibold">Item Groups</h1>
                  
                </div>

                <div className="w-full container mx-auto px-4 mb-2 mt-4">
  <table className="w-full">
    <thead>
      <tr className="text-[#6c7184] bg-[#fafafc]">
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">SNO</th>
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">ITEM GROUP IMAGE</th>
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">ITEM GROUP NAME</th>
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">UOM</th>
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">COST PRICE</th>
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">SELLING PRICE</th>
        <th className="font-semibold text-left text-xs py-2 px-2 border cursor-pointer">ITEM GROUP ADD-ON</th>
        <th className="font-semibold text-left text-sm py-2 px-2 border cursor-pointer">DESCRIPTION</th>
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => (
        <tr key={index}>
          <td className="py-2 px-4 border">{index + 1}</td>
          <td className="border w-1/6 text-center">
            <input
              type="file"
              id={`file-input-${index}`}
              onChange={(e) => handleImageChange(index, e)}
              className="hidden"
            />
            {/* <label htmlFor={`file-input-${index}`} className="cursor-pointer flex justify-center items-center">
            {GroupImage ? (
          <img src={imageUrl + GroupImage} alt="Uploaded" className="w-32 h-32 object-cover" />
        ) : (
          <FontAwesomeIcon icon={faImage} className="text-blue-500 text-4xl" />
        )}
            </label> */}
             <label htmlFor={`file-input-${index}`} className="cursor-pointer flex justify-center items-center">
                {imagePreviews[index] ? (
                  <img
                    src={imagePreviews[index]} // Use the preview URL for the new image
                    alt={`Uploaded ${index}`}
                    className="w-24 h-24 object-cover"
                  />
                ) : row.product_group_image ? (
                  <img
                    src={`${imageUrl}${row.product_group_image}`} // Use the backend image URL for the initial image
                    alt={row.product_group_image || 'Product Image'}
                    className="w-24 h-24 object-cover"
                  />
                ) : (
                  <FontAwesomeIcon icon={faImage} className="text-blue-500 text-4xl" />
                )}
              </label>
          </td>
          <td className="w-1/6 border">
            <input
              type="text"
              value={row.product_group_name}
              onChange={(e) => handleInputChange(index, 'product_group_name', e.target.value)}
              className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
            />
          </td>
          <td className="border w-1/6">
          <TableSearchDropDown
                options={UomOptions}
                value={SelectedUOM ? SelectedUOM.label : ''}
                onChange={(selectedOption) => handleUomChange(index, selectedOption)}
              />
</td>

          <td className="border w-1/6">
            <input
              type="text"
              value={row.cost_price}
              onChange={(e) => handleInputChange(index, 'cost_price', e.target.value)}
              className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
            />
          </td>
          <td className="border w-1/6">
            <input
              type="text"
              value={row.selling_price}
              onChange={(e) => handleInputChange(index, 'selling_price', e.target.value)}
              className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
            />
          </td>

          <td className="border w-1/9">
  <label className="flex items-center justify-center">
    <input
      type="checkbox"
      checked={row.product_group_type === 'AddOn'}
      onChange={(e) => handleInputChange(index, 'product_group_type', e.target.checked ? 'AddOn' : 'Main')}
      className="form-checkbox h-4 w-4 text-blue-600"
    />
    
  </label>
</td>

          <td className="border-t border-b border-gray-200 relative">
            <div className="w-full h-full">
              <textarea
                value={row.description}
                onChange={(e) => handleInputChange(index, 'product_group_description', e.target.value)}
                placeholder="Description"
                className="outline-blue-500 text-sm px-4 w-[250px] h-full bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
              />
            </div>
          </td>
          <td className="px-2">
            <button
              type="button"
              onClick={() => {
                const newRows = rows.filter((_, i) => i !== index);
                setRows(newRows);
              }}
              className="text-red-500"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

                <div className="mt-4">
                  <button
                    onClick={addRow}
                    className="text-blue-500 py-2 px-4"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                    Add Item Groups
                  </button>
                </div>

                <div className="mt-5 w-full">
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
                  <button className="px-2 py-1  flex-row bg-[#f7525a] border-[#f7525a] text-white rounded-md space-x-2 flex items-center"
                    onClick={handleSaveProductGroups}
                  >
                   <span>Save</span>
                    {isSaving && <Spinner />}
                  </button>
                  <button onClick={handleCancel} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
                </div>
                </div>
              </div>
            </div>
          </div>

          {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
        </div>
         {/* Popup starts */}

         {showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}

         {showAttributePopup && (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-b-md shadow-md w-1/2 h-2/3 flex flex-col ">
      <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-[10%] px-6'>
        <h2 className="text-md">New Attribute</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md ">   
          <FontAwesomeIcon icon={faX} className="text-xs text-[#e54643] cursor-pointer " />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      {Object.keys(errors).length > 0 && (
        <div className="flex justify-between  w-full p-2 items-center px-auto py-3 bg-[#fddcde] rounded-md">
          <div className="px-6 flex flex-col space-y-2 justify-start items-start">
            {errors.product_category && (
              <span className="text-sm">
                • {errors.product_category}
              </span>
            )}
            {errors.attribute_name && (
              <span className="text-sm">
                • {errors.attribute_name}
              </span>
            )}
        
          </div>
          <div className='px-4'>
            <button
              className="text-gray-700 hover:text-red-500"
              onClick={() => setErrors({})}
            >
              <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
            </button>
          </div>
        </div>
      )}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 mt-5 px-6">
        <label htmlFor="uom" className="block text-sm font-regular text-[#e54643] w-1/6">
          Attribute<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="Attribute"
          className="w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          value={attributeName}
                onChange={(e) => setAttributeName(e.target.value)}
       />
      </div>
      {/* Description Label and Textarea */}
      <div className="w-full flex flex-row items-center mb-4 space-x-2 px-6">
        <label htmlFor="Description" className="block text-sm font-regular text-gray-700 w-1/6">Description</label>
        <textarea
          id="Description"
          name="Description"
          className="mt-1 w-[40%] outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
          /* value={description}
          onChange={(e)=>{setdescription(e.target.value)}} */
        />
      </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
           /* onClick={handleSave} */ >
            Save  {isSaving && <Spinner />}
            </button>
          <button onClick={handleClosePopup} className="px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}

{/* Popup ends */}
      </div>
       
    </div>
  );
};

export default EditProductGroups;
