import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faTrash, faX , faExclamationTriangle , faArrowRightLong  } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader ,Spinner } from '../Loader/Loader';
import TableSearchDropDown from '../Components/tableSerchDropDown';
import TableSearchForAreaName from '../Components/TabelSerchForAreaName'
import  '../Css/Tooltip.css';
import {useLocation, useNavigate } from 'react-router-dom';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const filteredOptions = options.filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOptionClick = (option) => {
    onSelect(option); // Pass the entire option object
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };
  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false));
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  data-index={index}
                  className={`p-2 cursor-pointer text-sm ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} hover:bg-blue-500 hover:text-white`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};



const NewInvoice = () => {

 
const navigate = useNavigate()
const dropdownRef = useRef(null); // Define the dropdownRef variable
  const [selectedQuoteType, setSelectedQuoteType] = useState(); // Initial value
 
  const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit
 
  const [DefaultProductRatesOfPriceList,setDefaultProductRatesOfPriceList]= useState()
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowPricelists, setRowPricelists] = useState({});
  const [rows, setRows] = useState([{ item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',},  quantity: '', UOM: '', price: '', tax: '', tax_type:'', amount: '', sub_items:[]}]);
  const [products, setProducts] = useState([]); // assume products is set to one of the arrays you provided
  const [amounts, setAmounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [amendedDiscount, setAmendedDiscount] = useState();
  const [amendedPrice, setAmendedPrice] = useState();
  const [allRowsSelectedPriceList, setAllRowsSelectedPriceList] = useState([]);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [selectedApplicationAreas, setSelectedApplicationAreas] = useState({});
    const [roleId, setRoleId] = useState(null);
const [filteredProducts, setFilteredProducts] = useState([]);
const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

const toggleDropdown = (index) => {
  setOpenDropdownIndex(openDropdownIndex === index ? null : index);
}
const [dropdownOpen, setDropdownOpen] = useState({});
const [selectedProducts, setSelectedProducts] = useState({});
const [dueDate, setDueDate] = useState(new Date());
const [terms, setTerms] = useState('');
const [subject, setSubject] = useState('');
const [customerNotes, setCustomerNotes] = useState('');
const [files, setFiles] = useState([]);
const [isFileDropdownOpen, setIsFileDropdownOpen] = useState(false);
const [shippingCharges, setShippingCharges] = useState('');
const [taxValue, setTaxValue] = useState('');
const [showDropdown, setShowDropdown] = useState(false);
const [invoiceDate, setInvoiceDate] = useState(new Date());
const [orderNumber, setOrderNumber] = useState('');
const [productCategories, setProductCategories] = useState([]);
const [customersLoaded, setCustomersLoaded] = useState(false);
const [productCategoriesLoaded, setProductCategoriesLoaded] = useState(false);
const [UomOptions , setUomOptions] = useState([]);
const [selectedProductCategory, setSelectedProductCategory] = useState();
const [selectedTax, setSelectedTax] = useState({label: "Tax Exclusive", value: "Tax Exclusive"});
const [TaxOptions] = useState([
  {label: "Tax Exclusive", value: "Tax Exclusive"},
  {label: "Tax Inclusive", value: "Tax Inclusive"},
]);
const[ attributes, setAttributes]= useState([])
const [selectedTDS, setSelectedTDS] = useState('');
const [tdsAmount, setTdsAmount] = useState(0);
const [taxType, setTaxType] = useState('TDS');
const [selectedTaxValue, setSelectedTaxValue] = useState('');
const [taxAmount, setTaxAmount] = useState(0);

const location = useLocation();
  const { salesOrderData, fromSalesOrder } = location.state || {};

  useEffect(() => {
    if (fromSalesOrder && salesOrderData) {
      // Map sales order data to invoice fields
      setSelectedCustomer({
        id: salesOrderData.customer_id._id,
        label: salesOrderData.customer_id.customer_name,
        value: salesOrderData.customer_id.customer_name
      });
      
      setSelectedProductCategory({
        value: salesOrderData.product_category_id._id,
        label: salesOrderData.product_category_id.product_category_name
      });

      // Map the items
      const mappedRows = salesOrderData.items.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name,
        quantity: item.quantity,
        UOM: item.UOM,
        UOM_id: item.UOM_id,
        price: item.selling_price,
        tax: item.tax_rate,
        tax_type: item.tax_type,
        amount: item.sales_total
      }));
      
      setRows(mappedRows);
      
      // Set other relevant fields
      setCustomerNotes(salesOrderData.terms_and_conditions);
      setOrderNumber(salesOrderData.salesorder_number);
    }
  }, [salesOrderData, fromSalesOrder]);

useEffect(() => {
  fetchRoleId();
  fetchAllApprovedCustomers();
  handleGetAllUoms();
}, [fetchUrl]);


const handleItemNameChange = (index, selectedOption) => {
  console.log("selected item:", selectedOption);
  const updatedRows = [...rows];
  const selectedItem = products.find(product => product.item_id === selectedOption.value) || null;
  console.log("Found Product:", selectedItem);
  console.log("Product UOM:", selectedItem?.UOM);
  console.log("UOM Options:", UomOptions);
  const rowPriceListItem = allRowsSelectedPriceList[index];

  console.log(allRowsSelectedPriceList);

  if (selectedItem) {
    updatedRows[index].item_id = selectedItem?.item_id;
    updatedRows[index].item_name = selectedItem?.item_name;
    updatedRows[index].item_image = selectedItem?.item_image;
    updatedRows[index].cost_price = selectedItem?.cost_price;
    updatedRows[index].selectedDesigns = [];

            // Set default tax to GST
const defaultTax = Taxes.find(tax => tax.value === 'GST');
if (defaultTax) {
  const taxValue = selectedItem.GST || 0;
  updatedRows[index].tax = taxValue;
  updatedRows[index].tax_type = 'GST';
  handleTaxChange(index, defaultTax, taxValue, updatedRows[index].price, updatedRows[index].quantity);
}

if (selectedItem.selling_price) {
      console.log('selling price');
      updatedRows[index].price = selectedItem.selling_price;
    } else {
      console.log('');
    }

    const product = products.find(p => p.item_id === selectedItem.item_id);
    const selectedUom = UomOptions.find(p => p?.id === product?.UOM_id);
    updatedRows[index].UOM = selectedUom?.value;
    updatedRows[index].UOM_id = selectedUom?.id;

    // Apply conversion factor logic
    if (selectedQuoteType.value === 'Group') {
      const product_category = categoriesToDisplay.find(p => p._id === selectedProductCategory.value);
      const category_mapping_id = parseFloat(product_category?.category_mapping_id);

      if (category_mapping_id === 3 && 
        (updatedRows[index].UOM === 'No' || updatedRows[index].UOM === 'Nos')) {
      // Use count as quantity for Windows with UOM as No or Nos
      handleInputChange(updatedRows[index].application_area.count, index, 'quantity', product?.selling_price);
      console.log(updatedRows[index].quantity);
        }

      if (category_mapping_id === 1) {
        const attributeValues = attributes.find(p => p.attribute_name === 'Sq Ft per Box')?.attribute_values;

        let conversionFactor = 0;
        if (selectedItem.attribute_value_ids) {
          for (const id of selectedItem.attribute_value_ids) {
            const attributevalue = attributeValues.find(p => p.attribute_value_id === id);
            if (attributevalue) {
              conversionFactor = parseFloat(attributevalue.attribute_value);
              break;
            }
          }

          if (conversionFactor > 0) {
            const currentArea = parseFloat(updatedRows[index].application_area.area) || 0;
            const quantity = Math.ceil(currentArea / conversionFactor);
            updatedRows[index].quantity = quantity;
          }
        }
      }
    }

    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].sub_items = selectedItem.sub_items;
      const updatedSubItems = selectedItem.sub_items;
/*         setSubItems({
        subItems: updatedSubItems,
        UOM: selectedUom?.value,
        UOM_id: selectedItem.UOM_id
      }); */
      return updatedRows;
    });

    setRows(updatedRows);
    calculateTotal(updatedRows);
  }
};


const calculateTotal = () => {
  const subtotal = calculateSubtotal() || 0; // Get the subtotal from the existing function
  const discount = (amendedDiscount / 100) * subtotal || 0; // Calculate the discount based on the amendedDiscount percentage
  const tax_amount = calculateTax() || 0; // Calculate the tax amount using the existing function
 //console.log(tax_amount)
  // Parse amendedPrice to ensure it's a valid number
  const parsedAmendedPrice = parseFloat(amendedPrice) || 0;

  // Adjust the subtotal based on amended price
  const adjustedSubtotal = subtotal - discount + parsedAmendedPrice;

  let total = adjustedSubtotal; // Start with the adjusted subtotal

  if (selectedTax) {
    if (selectedTax.value === 'Tax Exclusive') {
      total += tax_amount; // Add tax if it's exclusive
    }
    // For 'Tax Inclusive', no need to adjust since total is already calculated
  } else {
    // If selectedTax is not defined, assume tax is exclusive
    total += tax_amount;
  }

  return total;
};

useEffect(() => {
  const calculatedTotal = calculateTotal();
  setTotal(calculatedTotal);
}, [rows, amendedDiscount, amendedPrice, selectedTax]);
const handleProductCategorySelect = (selectedOption) => {
  console.log(selectedOption);
  setSelectedProductCategory(selectedOption);
  fetchProductsbyProductCategoryID(selectedOption.value);
  fetchAttributesByproductCategory(selectedOption.value)
  const newRow = { item_name: '', item_id:'', application_area: {area_name:'',area: '', count:'',}, measured_area: '', quantity: '', UOM: '', unit_price: '', tax: '', total: '', designs: [] };
  setRows([newRow]);
  const index = 0
  const option = {label: '' , value: ''}
  handleItemNameChange(index ,option)
};

const fetchRoleId = () => {
  const roleId = JSON.parse(sessionStorage.getItem('roleId'));
  setRoleId(roleId);
};

const categoriesToDisplay = roleId === 6 
? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
: productCategories;

useEffect(() => {
  if (customersLoaded && productCategoriesLoaded) {
    //fetchQuotationById(customers);
  }
}, [customersLoaded, productCategoriesLoaded]);
  

 
   // Centralized error handling
   const handleFetchError = (response, attempt, retryFunction) => {
    if (response.status === 500) {
      setRetryFunction(() => retryFunction); 
    }
  };
      
      const handleErrorWithRetry = (error, attempt, retryFunction, ...args) => {
        console.error('Failed to fetch:', error);
      
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => retryFunction(...args, attempt + 1), 1000);
        } else {
          setIsLoading(false);
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => retryFunction(...args, attempt));
        }
      };

const fetchAllApprovedCustomers = async (attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(fetchUrl + `customer/get-all-approved-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    const output = await handleGetResponse(response, data);
    if (output) {
      // Format data for dropdown
      const formattedCustomers = output.map(customer => ({
        id: customer._id,  // Use customer ID as value
        label: customer.customer_name, // Use customer name as label
        value: customer.customer_name
      }));

      setCustomers(formattedCustomers); // Update state with formatted data
     // console.log(formattedCustomers);
     setCustomersLoaded(true);
    } else {
      handleFetchError(response, attempt, fetchAllApprovedCustomers);
    }
  } catch (error) {
    handleErrorWithRetry(error, attempt, fetchAllApprovedCustomers);
  }
};

const fetchAttributesByproductCategory = async (product_category, attempt=1)=>{
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}product-attributes/get-all-attributes-by-productCategory?product_category_id=${product_category}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    const output = await handleGetResponse(response , data)
    //console.log(output)
    if (output) {
    //console.log(output)
     setAttributes(output)
    } else {
      setAttributes([])
      console.error(data.message);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchAttributesByproductCategory( product_category , attempt + 1), 1000); // Retry after 1 second
    } else {
     
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchAttributesByproductCategory(product_category, attempt)); // Set retry function
    }
  }
  }

const fetchProductsbyProductCategoryID = async (product_category, attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}product/get-products-by-product-category?product_category_id=${product_category}&organizationId=${sessionStorage.getItem('organizationId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    const output = await handleGetResponse(response , data)
    console.log(output)
    if (output) {
     // console.log(output)
     const productsWithItemName = output.map((product) => {
      // Match the item_id with the rows and get the corresponding item_name
      const matchedRow = rows.find(row => row.item_id === product.item_id);
      
      // Use the matched row's item_name if available, otherwise use product's item_name
      const itemName = matchedRow ? matchedRow.item_name : product.item_name;
  
      return {
          ...product,
          itemName,
          price: product.selling_price || product.price || 0,
          quantity: matchedRow?.quantity || 0,
          UOM: matchedRow?.UOM || product.UOM || '',
          tax: product.GST || 0,
          tax_type: 'GST'
      };
  });
  
    //console.log(productsWithItemName)
    setProducts(output);
     // setProducts(output); // Update productCategories state
    } else {
      setProducts([])
      console.error(data.message);
    }
  } catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => fetchProductsbyProductCategoryID(attempt + 1), 1000); // Retry after 1 second
    } else {
     
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => fetchProductsbyProductCategoryID(attempt)); // Set retry function
    }
  }/* finally {
    setIsLoading(false);
  } */
};

const handleGetAllUoms = async (attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
    });

    const data = await response.json();
    const output = await handleGetResponse(response, data);
    if (output) {
      // Log the data to ensure it's fetched correctly
      //console.log('Fetched uoms:',output);

      const uomData = output.map((item) => ({
        id: item._id,
        value: item.UOM,
        label: item.UOM,
      }));

  setUomOptions(uomData)
    } else {
      console.error('Error:', data.message);
      return ([])
    
     
    }
  }catch (error) {
    console.error('Failed to fetch:', error);

    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1); // Increment retry count
      setTimeout(() => handleGetAllUoms(attempt + 1), 1000); // Retry after 1 second
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleGetAllUoms(attempt)); // Set retry function
    }
  }finally {
    setIsLoading(false);
  }
};

const [termsOptions] = useState([
    { label: "Net 15", value: "Net 15" },
    { label: "Net 30", value: "Net 30" },
    { label: "Net 45", value: "Net 45" },
    { label: "Net 60", value: "Net 60" },
    { label: "Due on Receipt", value: "Due on Receipt" },
    { label: "Due end of the month", value: "Due end of the month" },
    { label: "Due end of next month", value: "Due end of next month" },
    { label: "Custom", value: "Custom" }
  ]);
const handleTermsChange = (selectedOption) => {
  setTerms(selectedOption);
};

const [TDS_OPTIONS] = useState([
  { label: "Commission or brokerage [2%]", value: "Commission or brokerage [2%]" },
  { label: "Commission or Brokerage(reduced) [3.75%]", value: "Commission or Brokerage(reduced) [3.75%]" },
  { label: "Dividend [10%]", value: "Dividend [10%]" },
  { label: "Dividend (reduced) [7.5%]", value: "Dividend (reduced) [7.5%]" },
  { label: "Other interests than securities [10%]", value: "Other interests than securities [10%]" },
  { label: "Other interest than securities (reduced) [7.5%]", value: "Other interest than securities (reduced) [7.5%]" },
  { label: "Payments of contractors for others [2%]", value: "Payments of contractors for others [2%]" },
  { label: "Payments of contractors for others (reduced) [1.5%]", value: "Payments of contractors for others (reduced) [1.5%]" },
  { label: "Payment of contractors HCF/indiv (reduced) [1.5%]", value: "Payment of contractors HCF/indiv (reduced) [1.5%]" },
  { label: "Payments of contractors HCF/indiv [1%]", value: "Payments of contractors HCF/indiv [1%]" },
  { label: "Payments of contractors HCF/indiv(reduced) [0.75%]", value: "Payments of contractors HCF/indiv(reduced) [0.75%]" },
  { label: "Professional Fees [10%]", value: "Professional Fees [10%]" },
  { label: "Professional fees(reduced) [7.5%]", value: "Professional fees(reduced) [7.5%]" },
  { label: "Rent on land or furniture etc [10%]", value: "Rent on land or furniture etc [10%]" },
  { label: "Rent on land or furniture etc (reduced) [0.75%]", value: "Rent on land or furniture etc (reduced) [0.75%]" },
  { label: "Technical Fees [2%]", value: "Technical Fees [2%]" }
]);



const handleTaxSelect = (value) => {
  setTaxValue(value);
  setShowDropdown(false);
};


const calculateTaxAmount = (taxRate, price, quantity) => {
  const tax = parseFloat(taxRate) || 0;
  const sellingPricePerQuantity = parseFloat(price) || 0;
  const totalQuantity = parseFloat(quantity) || 0;
  const taxAmount = parseFloat(((tax / 100) * sellingPricePerQuantity) * totalQuantity) || 0;
  return taxAmount;
};
      
const fetchProductCategories = async (attempt = 1) => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });
    const data = await response.json();
    const output = await handleGetResponse(response , data)
    //console.log(output)
    if (output) {
      setProductCategories(output); // Update productCategories state
      setProductCategoriesLoaded(true);
    }  else {
      handleFetchError(response, attempt, fetchProductCategories);
    }
  } catch (error) {
    handleErrorWithRetry(error, attempt, fetchProductCategories);
  }
}; 
   
const handleCustomerSelect = (customer) => {
  setSelectedCustomer(customer);
  setSelectedProductCategory(null); 
  setProductCategories([]); 
  fetchProductCategories();
};

const handleInvoiceNumberChange = (e) => {
  setInvoiceNumber(e.target.value);
};

const handleOrderNumberChange = (e) => {
  setOrderNumber(e.target.value);
};

const handleDateChange = (date) => {
  setInvoiceDate(date);
};

const handleInputChange = (value, index, field, price) => {
  const updatedRows = [...rows];
  updatedRows[index][field] = value;
  if (field === 'quantity') {
    updatedRows[index].total = value * price;
  }
  setRows(updatedRows);
};

const Taxes = [
  { value: 'igst', label: 'IGST' },
  { value: 'gst', label: 'GST' }
];

const addRow = () => {
  setRows([...rows, { item_name: '', quantity: '', UOM: '', price: '', tax: '', tax_type: '', amount: '' }]);
};

const deleteRow = (index, rows) => {
  const updatedRows = rows.filter((_, idx) => idx !== index);
  setRows(updatedRows);
};

const handleSaveInvoiceData = async () => {
  setIsSaving(true);
  try {
    const invoiceData = {
      customer_id: selectedCustomer.id,
      invoice_date: invoiceDate,
      status: 'Draft',
      due_date: dueDate,
      balance_due:total,
      items: rows.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name,
        quantity: parseFloat(item.quantity),
        selling_price: parseFloat(item.price),
        tax_rate: parseFloat(item.tax),
        tax_type: item.tax_type,
        total_tax_amount: item.total_tax_amount,
        total: parseFloat(item.price * item.quantity),
        UOM: item.UOM,
        UOM_id: item.UOM_id
      })),
      sub_total: SubTotal,
      tax_amount: calculateTax(),
      total: total,
      discount: parseFloat(amendedDiscount) || 0,
      adjustment: parseFloat(amendedPrice) || 0,
      terms_and_conditions: customerNotes,
      files: files,
      organizationId: sessionStorage?.getItem('organizationId'),
      tax_prefrence: selectedTax.value
    };
console.log(invoiceData)
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl}invoice/save-invoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(invoiceData)
    });

    const data = await response.json();
    const output = await handleSaveResponse(response, data);
    
    if (output) {
      navigate('/bb/app/sales/invoiceslist');
    }
  } catch (error) {
    setPopupMessage('Error saving invoice');
    setPopupType('400');
  } finally {
    setIsSaving(false);
  }
};

    

    
   
   

  
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  
  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    if (files.length + uploadedFiles.length <= 10) {
      setFiles(prev => [...prev, ...uploadedFiles]);
    }
  };
  

 
  
 
   

 const  [SubTotal,setSubTotal]= useState(0)

useEffect(()=>{
  calculateSubtotal()
  calculateTax()
},[rows])

  const calculateSubtotal = () => {
    let subtotal = 0;
  
    rows.forEach(row => {
      const quantity = parseFloat(row?.quantity) || 0;
      const price = parseFloat(row?.price) || 0;
      const rowTotal = quantity * price;
      subtotal += rowTotal;
    });
   setSubTotal(subtotal)
    return subtotal;
  };
  
  
  const calculateTax = () => {
    let TotalTax = 0;
  
    rows.forEach((p) => {
      const price = parseFloat(p.price) || 0;  
      const taxRate = parseFloat(p.tax) || 0;  
      const quantity = parseFloat(p.quantity) || 0;  
  
      const tax = (taxRate / 100) * price;
      const SubTotalTax = tax * quantity;
  
      TotalTax += SubTotalTax;
    });
  
    return TotalTax;
  };

  

  const handleTaxChange = (index, selectedOption, value, price, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      tax: value,
      tax_type: selectedOption?.label,
    };
    const totaltaxOfproduct = calculateTaxAmount(value, price, quantity);
    updatedRows[index].total_tax_amount = totaltaxOfproduct;
    setRows(updatedRows);
  };
  
  

  const calculateTotalTaxAmount = () => { 
    let totalTaxAmount = 0;
    if (Array.isArray(rows)) {
      rows.forEach((row) => {
        totalTaxAmount += row?.total_tax_amount || 0;
      });
    }
   
    return totalTaxAmount || 0; // Update the total tax amount state
    
  };
  

  

const [invoiceNumber, setInvoiceNumber] = useState('');

// Add this useEffect to generate invoice number on component mount
useEffect(() => {
  const generateInvoiceNumber = () => {
    const prefix = 'INV';
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000);
    return `${prefix}-${timestamp}-${random}`;
  };
  
  setInvoiceNumber(generateInvoiceNumber());
}, []);


  
  
  
  const closePopup = () => {
   // console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
   // console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){
  
        await retryFunction ();
      }
  
      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
   // console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };
  
  const handleStayHere = () => {
    setShowCancelPopup(false);
  }
  
  const handleCancel = () => {
    setShowCancelPopup(true);
  }
  
  const handleLeave = () => {
    navigate(-1);
  }

  const formatNumber = (value) => {
    // Parse the input as a number
    const parsedValue = parseFloat(value);
  
    // Check if the parsed value is a finite number
    if (Number.isFinite(parsedValue)) {
      // Round to two decimal places
      const roundedValue = (Math.round(parsedValue * 100) / 100);
      // Format the number with commas and two decimal places
      return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
    }
  
    // Return '0.00' if the value is not a valid number
    return '0.00';
  };
  
  
  // if (isLoading) {
  //   return <Loader />;
  // }




  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-4 items-center">
                  <h1 className="text-lg font-semibold">New Invoice</h1>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                      <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                        <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
                          Customer Name<span className="text-red-500">*</span>
                        </label>
                        <div className='w-1/3'>
                        <CustomDropdown
        options={customers}
        selected={selectedCustomer}
        onSelect={handleCustomerSelect}
        placeholder='Select Customer'
        showAddNew={false}
        showSearch={true}
      />
      </div>
                      </div>
                      <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                  Item Category<span className="text-red-500">*</span>
                </label>           
                    <div className='w-1/3'>
                    <CustomDropdown
  options={categoriesToDisplay.map(category => ({
    value: category._id,
    label: category.product_category_name
  }))}
  selected={selectedProductCategory}
  onSelect={handleProductCategorySelect}
  placeholder="Select Item Category"
  showAddNew={false}
  showSearch={true}
/>
                    </div>
                    
              </div>
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="order number" className="block text-sm font-regular text-gray-700 w-1/6">
                        Order Number
                      </label>
                      <input
                        type="text"
                        className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                        value={orderNumber}
                        onChange={handleOrderNumberChange}
                      />
                    </div> 
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-12">
  <div className="flex flex-row items-center w-1/2">
    <label htmlFor="invoiceDate" className="block text-sm font-regular text-[#e54643] w-1/3">
      Invoice Date<span className="text-red-500">*</span>
    </label>
    <DatePicker
      selected={invoiceDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      className="w-full ml-4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    />
  </div>

  <div className="flex flex-row items-center w-1/4">
    <label htmlFor="terms" className="block text-sm font-regular text-gray-700 w-1/4">
      Terms
    </label>
    <div className="w-3/4 ">
    <CustomDropdown
      options={termsOptions}
      selected={terms}
      onSelect={handleTermsChange}
      placeholder="Select Terms"
      showSearch={true}
      showAddNew={false}
      className="w-3/4 rounded-md border-2 border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700 border-dotted"
    />
    </div>
  </div>

<div className="flex flex-row items-center w-1/4 whitespace-nowrap">
    <label htmlFor="dueDate" className="block text-sm font-regular text-gray-700 w-1/4">
      Due Date
    </label>
    <DatePicker
      selected={dueDate}
      onChange={handleDueDateChange}
      dateFormat="dd/MM/yyyy"
      className="w-3/4 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
    />
  </div>
</div> 
                  </div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              </div>
              <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
              <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
              
<div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
  <label htmlFor="subject" className="block text-sm font-regular text-gray-700 w-1/6">
    Subject
  </label>
  <textarea
  id="subject"
  className="w-1/3 h-10 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
  placeholder="Let your customers know what this invoice is for"
>
</textarea>

                </div> 
              </div>
              <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
              <div className="w-full container mx-auto px-4 mb-2 mt-8">
  <div className='border border-gray-300 rounded-md bg-[#fafafc]'>
  <h2 className="text-lg font-semibold py-1 px-2">Item Table</h2>
  </div>
  <table className="w-full">
        <thead>
          <tr className="font-semibold">
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2 w-1/4">Item Details</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Quantity</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">UOM</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Price</th>
            <th className="font-semibold border-r border-b text-left text-sm py-2 px-2">Tax</th> 
            <th className="font-semibold border-b  border-r text-left text-sm py-2 px-2">Amount</th>
           
           
            
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr key={index}>

<td className="border-r border-b px-2 py-2" ref={dropdownRef}>
  <div className="relative">
    <div 
      className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
      onClick={() => setDropdownOpen(prev => ({...prev, [index]: !prev[index]}))}
    >
      <span className="text-sm">{selectedProducts[index]?.item_name || item.item_name || 'Select Item'}</span>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
    </div>
    
    {dropdownOpen[index] && (
  <div className="absolute z-50 left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
    <div className="p-2 border-b">
      <input
        type="text"
        className="w-full p-1 border rounded focus:outline-none focus:border-blue-500"
        placeholder="Search products..."
        onChange={(e) => {
          const searchTerm = e.target.value.toLowerCase();
          const filtered = products.filter(product => 
            product.item_name.toLowerCase().includes(searchTerm)
          );
          setFilteredProducts(filtered);
        }}
        autoFocus
      />
    </div>
    
    <div className="max-h-48 overflow-y-auto">
    {(filteredProducts.length > 0 ? filteredProducts : products).map((product) => (
  <div
    key={product.item_id}
    className="p-2 hover:bg-blue-50 cursor-pointer"
    onClick={() => {
      // Update selected products state
      const updatedProducts = {...selectedProducts};
      updatedProducts[index] = product;
      setSelectedProducts(updatedProducts);
      setDropdownOpen(prev => ({...prev, [index]: false}));

      const uom = UomOptions.find(option => option.id === product.UOM_id);
      console.log("Found UOM:", uom);
    
      // Update rows with the selected product data
      const updatedRows = [...rows];
      updatedRows[index] = {
        ...updatedRows[index],
        item_id: product.item_id,
        item_name: product.item_name,
        price: product.selling_price || 0,
        UOM: uom?.value || '',
        UOM_id: product.UOM_id,
        tax: product.GST || 0,
        tax_type: 'GST'
      };
      setRows(updatedRows);
    }}
  >
    <span className="text-sm">{product.item_name}</span>
  </div>
))}


    </div>
  </div>
)}

  </div>
</td>


               {/* Render additional fields only for Group type and specific categories */}
               <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.quantity}
                  onChange={(e) => handleInputChange(e.target.value, index, 'quantity' , item?.price )}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
              </td>
              <td className="border-r border-b px-2 py-2">
                
                <td className="w-20">

                 <input
                  type="text"
                  value={item?.UOM || ''}
                  readOnly
                  //onChange={(e) => handleInputChange(e, index, 'UOM')}
                  className="outline-blue-500 text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
               </td>
              </td>
              <td className="border-r border-b px-2 py-2">
                <input
                  type="text"
                  value={item?.price}
                  readOnly
                  //onChange={(e) => handleInputChange(products.find(p=>p.item_id===item.item_id)?.selling_price, index, 'price')}
                  className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
                />
                             </td>
              <td className="border-r border-b px-2 py-2">
                {item.item_name && (
               <div className="w-22 flex-row ">
               <TableSearchDropDown
                 options={Taxes.map(tax => ({
                   value: tax.value,
                   label: tax.label,
                 }))}
                 value={item?.tax_type}
                 onChange={(selectedOption) => {
                   // Find the corresponding product using item_id
                   const product = products.find(p => p.item_id === item.item_id);
                   
                   // Calculate the tax value
                   const taxValue = product ? product[selectedOption.value] : null;
           
                   // Update the item tax with the selected tax value
                   handleTaxChange(
                     index, 
                     selectedOption, 
                     taxValue, 
                     item.price, 
                     item.quantity
                   );
           
                   // Update both item.tax and item.tax_type
                   item.tax = taxValue;
                   item.tax_type = selectedOption.value;
                 }}
               />
              {item?.tax && (
               <p>{item?.tax || ''} % </p>
              )} 
             </div>
                )}
  
            </td>
 
              <td className="border-b border-r px-1 py-2">
  <input
    type="text"
    readOnly
    value={(formatNumber(item?.price * item?.quantity) || formatNumber(item?.total) || 0)}

    //onChange={(e) => handleAmountInputChange(e, index, 'amount')}
    className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
  />
</td>

           

<td className="px-2">  <button    type="button"
                                  onClick={() => deleteRow(index ,rows)}
                                  className="text-red-500">
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
            </tr>
          ))}
        </tbody>
      </table>
</div>
              </div>
              </div>
              </div>
              <div className='pt-2 px-6 py-6 flex flex-row justify-between w-full'>
  <div className="flex flex-col w-1/3">
    <div className="flex flex-row space-x-2">
      <button onClick={addRow} className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'>
        <FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1" />
        Add New Row
      </button>
      <button className='rounded-md px-2 py-2 bg-[#F7F7FE] text-sm'>
        <FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1" />
        Add Items in Bulk
      </button>
    </div>

    <div className="w-full flex flex-col mt-40 ">
      <div className="w-full flex flex-row items-center space-x-2">
        <label htmlFor="customerNotes" className="block text-sm font-regular text-gray-700 w-full">
          Customer Notes
        </label>
      </div>
      <div className="w-full flex flex-row item-start items-center space-x-2">
        <textarea
          id="customerNotes"
          name="customerNotes"
          className="mt-1 w-full h-20 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700 placeholder-black"
          value={customerNotes}
          onChange={(e) => setCustomerNotes(e.target.value)}
          placeholder="Thanks for business."
        />
      </div>
      <span className="mt-1 text-xs text-gray-500">Will be displayed on the invoice</span>
    </div>
  </div>

  

                <div className='p-6 space-y-6 flex flex-col justify-between rounded-md w-1/2 bg-[#F7F7FE]'>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm font-semibold'>Sub Total</p>
                </div>
                <div><p className='text-sm font-semibold'>{/* calculateSubtotal() */formatNumber (SubTotal)}</p></div>
                </div>
                <div className='flex justify-between'>
                <div className='flex flex-row items-center'>
  <p className='text-sm'>Discount</p>
  <div className='flex justify-end w-full relative'>
  <input 
    type="text"
    value={amendedDiscount}
    onChange={(e) => {
      const currentValue = e.target.value;
      const newValue = currentValue.replace(/[^0-9]/g, ''); // remove non-numeric characters
      setAmendedDiscount(newValue);
    }}
    className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-1/3 border-r-0 rounded-l-md'
  />
  <span className='bg-gray-200 p-2 text-sm border border-gray-300 border-l-0 rounded-r-md'>%</span>
</div>
</div>
<div>
  <p className='text-sm '>
    <span className='mr-1 font-bold text-xl text-red-500'>-</span>
    {formatNumber(Number.isFinite(parseFloat(SubTotal)) && Number.isFinite(parseFloat(amendedDiscount)) ? parseFloat(SubTotal) * (parseFloat(amendedDiscount / 100)) : 0)}
  </p>
</div>
                </div>
                <div className='flex justify-between items-center'>
  <div className='flex flex-row items-center'>
    <p className='text-sm w-[100px]'>Shipping Charges</p>
    <div className='flex justify-end w-full relative ml-[85px]'>
      <input
        type="text"
        value={shippingCharges}
        onChange={(e) => {
          const regex = /^[0-9]*$/;
          if (regex.test(e.target.value)) {
            setShippingCharges(e.target.value);
          }
        }}
        className='px-2 text-right border border-gray-300 outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90 py-1 w-24 rounded-md'
      />
      <FontAwesomeIcon 
        icon={faQuestionCircle} 
        className="ml-4 text-gray-400 cursor-help"
        title="Enter shipping charges amount"
      />
    </div>
  </div>
  <div>
    <p className='text-sm'>{formatNumber(shippingCharges || 0)}</p>
  </div>
  
</div>

<div className='flex flex-row items-center-justify-between w-full space-x-12 mt-4'>
  <div className='flex items-center space-x-2'>
    <input
      type="radio"
      id="tds"
      name="taxType"
      value="TDS"
      checked={taxType === 'TDS'}
      onChange={(e) => setTaxType(e.target.value)}
      className='text-blue-500 focus:ring-blue-500'
    />
    <label htmlFor="tds" className='text-sm'>TDS</label>
  </div>
  
  <div className='flex items-center space-x-2'>
    <input
      type="radio"
      id="tcs"
      name="taxType"
      value="TCS"
      checked={taxType === 'TCS'}
      onChange={(e) =>{ setTaxType(e.target.value);
        setShowDropdown(false); 
      }}
      className='text-blue-500 focus:ring-blue-500'
    />
    <label htmlFor="tcs" className='text-sm'>TCS</label>
  </div>

  {/* Move the input box to the right */}
  <div className='relative flex justify-end'>
    <input
  
      type="text"
      value={taxValue}
      placeholder="Enter tax value"
      onChange={(e) => setTaxValue(e.target.value)}
      className='px-4 py-1 border border-gray-300 rounded-md outline-blue-500 hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
    />
   {taxType === 'TDS' && (
    <FontAwesomeIcon
      icon={faChevronDown}
      className="absolute  right-2 top-2 text-gray-400 cursor-pointer"
      onClick={() => setShowDropdown(!showDropdown)}
    />
  )}
  {showDropdown && taxType === 'TDS' && (
     <div className='absolute top-full mt-1 w-80 bg-white border rounded-md shadow-lg z-10 max-h-60 overflow-y-auto'>
     {TDS_OPTIONS.map((option) => (
       <div
         key={option.label}
         className='px-4 py-2 hover:bg-blue-50 cursor-pointer text-sm'
         onClick={() => {
           setTaxValue(option.value);
           setShowDropdown(false);
           setTaxValue('');
         }}
       >
         {option.label}
       </div>
     ))}
   </div>

    )}
  </div>
  <div classname ='relative flex justify-end'>
  <p className='text-sm text-right'>{formatNumber(calculateTax())}</p>
</div>

</div>



                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                    <p className='text-sm border border-gray-500 border-dashed px-4 py-1 rounded'>Adjustment</p>
                <div className='flex justify-end w-full relative'>
                <input
  type="text"
  value={amendedPrice}
  onChange={(e) => {
    const regex = /^[-+]?[0-9]*$/; // allow optional - or +, followed by digits
    if (regex.test(e.target.value)) {
      setAmendedPrice(e.target.value);
    }
  }}
  className='px-2 w-[50%] text-right py-1 w-2/3 outline-blue-500 border border-gray-300 rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90'
/>

  <button 
  className="mr-2 text-white px-1 py-0.5 rounded-md" 
  title="" info-tooltip="Add any other +ve or -ve value that need to be applied to adjust the total amount of the transaction Eg. +10 or -10."  style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faQuestionCircle}  className="word-break break-words mb-0.5 text-xs text-gray-500 mx-1 cursor-pointer" />
</button>
</div>
                </div>
                <div><p className='text-sm '>{amendedPrice ? amendedPrice : '0.00'}</p></div>
                </div>
                <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
                <div className='flex justify-between'>
                    <div className='flex flex-row justify-between'>
                <p className='text-lg font-semibold '>Total ( ₹ )</p>
                </div>
                <div><p className='text-lg font-semibold'>{formatNumber(total) }</p></div>
                </div>
                </div>
                </div>
               
                <div className="w-full flex flex-col px-4 bg-[#F9F9FB] border border-gray-300 rounded-md p-4">
  <div className="flex flex-row justify-between space-x-4">
    {/* Terms & Conditions Section */}
    <div className="w-1/2 border-r border-gray-300 pr-12 mr-12">
      <label htmlFor="termsAndConditions" className="block text-sm font-regular text-[#e54643] mb-2">
        Terms & Conditions<span className="text-red-500">*</span>
      </label>
      <textarea
        id="termsAndConditions"
        name="termsAndConditions"
        className="w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
        value={customerNotes}
        onChange={(e) => setCustomerNotes(e.target.value)}
        placeholder="Enter the terms and conditions of your business to be displayed in your transaction."
        rows={4}
      />
    </div>

    {/* Upload File Section */}
    <div className="w-5/12 pl-24">
      <label className="block text-sm font-regular text-[#e54643] mb-2">
        Attach File(s) to Invoice<span className="text-red-500">*</span>
      </label>
      <div className="border-2 border-dashed border-gray-300 rounded-md p-2 flex items-center justify-between w-48">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faPlusCircle} className="text-[#408dfb] mx-1" />
          <span className="text-sm text-gray-600">Upload File</span>
        </div>
        <div className="border-l border-gray-300 h-6 mx-2"></div>
        <FontAwesomeIcon icon={faChevronDown} className="text-gray-500" />
      </div>
      <span className="mt-1 text-xs text-gray-500">
        You can upload maximum of 10 files, 10 MB
      </span>
    </div>
  </div>
  <hr className="border-t border-gray-200 w-full mt-8 mb-4" style={{ borderTopWidth: '1px' }} />
 
  {/* Payment Gateway Section */}
  <div className="mt-4 px-4">
  <div className="flex items-center">
    <h3 className="text-lg font-medium">Want to get paid faster?</h3>
    <div className="flex items-center ml-2 relative">
      <div className="w-4 h-4 rounded-full bg-red-500"></div>
      <div className="w-4 h-4 rounded-full bg-yellow-500 -ml-2"></div>
    </div>
    <span className="text-[#408dfb] ml-2 font-semibold">VISA</span>
  </div>
  <div className="flex items-center mt-2">
    <span className="text-sm text-gray-600">Configure payment gateways and receive payments online.</span>
    <span className="text-[#408dfb] ml-2 cursor-pointer">Set up Payment Gateway</span>
  </div>
</div>


</div>

<div className="mt-10 px-4 mb-20">
  <div className="text-sm text-gray-600">
    <span className="font-semibold">Additional Fields:</span> Start adding custom fields for your invoices by going to    
    <span className="font-medium">  Settings</span>
    <FontAwesomeIcon icon={faArrowRightLong} className="mx-1 text-gray-400" />
    <span className="font-medium">Sales</span>
    <FontAwesomeIcon icon={faArrowRightLong} className="mx-1 text-gray-400" />
    <span className="font-medium">Invoices</span>
  </div>
</div>


              </div>



              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button 
  className="px-2 py-1 bg-[#f7525a] text-white border border-[#DDDDDD] rounded-md"
  onClick={handleSaveInvoiceData}
>
  Save as Draft {isSaving && <Spinner />}
</button>

          <button onClick={handleCancel} className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>Cancel</button>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>


{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
{showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}

{/* POPup ends */}
    </div>
  );
};

export default NewInvoice;
