import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { faArrowRight, faArrowUp, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';
import { jwtDecode } from "jwt-decode";


const WindowSummary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { vendorId, categoryId,  allProducts, selectedGroups, customerId, projectId, username } = location.state || {};
    const [selectionSummary, setSelectionSummary] = useState(location.state?.selectionSummary || []);
    const [expandedProduct, setExpandedProduct] = useState(null);
    const [products, setProducts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    console.log(selectionSummary)
    const [activeFlat, setActiveFlat] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [currentVendorProducts, setCurrentVendorProducts] = useState([]);
    const [showBackToTop, setShowBackToTop] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    //const projectId = searchParams.get('project');
   // const categoryId = searchParams.get('category');
    //const customerId = searchParams.get('customer');
    console.log(projectId);
    console.log(customerId);
    const urlToken = searchParams.get('token');
    const [showQuotationPopup, setShowQuotationPopup] = useState(false);
    const [uoms, setUoms] = useState([]);
    const [isVendorChanging, setIsVendorChanging] = useState(false);
    const [isFlatChanging, setIsFlatChanging] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);




   
    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0);
    const { 
      showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
      isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
      setPopupType } = useCommonResponseHandler();
      const [isPageLoading, setIsPageLoading] = useState(true);


    
    const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  
    useEffect(() => {
      if (urlToken) {
        try {
          // Decode the token
          const decodedToken = jwtDecode(urlToken);

          // Set session data
          sessionStorage.setItem('token', urlToken);
          sessionStorage.setItem('userId', decodedToken.userId);
          sessionStorage.setItem('username', decodedToken.username);
          sessionStorage.setItem('roleId', decodedToken.roleId);
          sessionStorage.setItem('organizationId', decodedToken.organizationId);
          
        } catch (error) {
          console.error('Error decoding token:', error);
          // Handle error (e.g., redirect to error page)
        }
      }
    }, [urlToken, searchParams]);


    useEffect(() => {
      console.log('Received selection summary:', selectionSummary);
      console.log('Vendor ID:', vendorId);
      console.log('Category ID:', categoryId);
  }, []);

    const fetchProductsForVendor = async (vendorId, subcategoryId) => {
        setIsLoading(true);
        try {
          const token = sessionStorage.getItem('token');
          console.log('Fetching products with:', {
            categoryId,
            vendorId,
            subcategoryId
          });
          
          const response = await fetch(
            `${fetchUrl}product/get-products-by-category-and-vendor?product_category_id=${categoryId}&vendor_id=${vendorId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'x-token': token
              }
            }
          );
          
          const data = await response.json();
          console.log(data);
          console.log('API Response:', data);
          
        
          if (response.ok && data.data) {
        
            
            console.log('Current subcategoryId:', subcategoryId);
            console.log('All products:', data.data);
            
            
            // Update with new filtered products
            setCurrentVendorProducts(data.data);
     
          
        }
        } catch (error) {
          console.error('Failed to fetch products:', error);
        } finally {
          setIsLoading(false);
        }
      };
  
      useEffect(() => {
        if (expandedProduct) {
          const [flatNo, idx] = expandedProduct.split('-');
          const selection = selectionSummary.find(s => 
            s.flatNo && idx && 
            s.flatNo.toString() === flatNo && 
            s.windowIndex?.toString() === idx
          );
          
          if (selection?.vendor?._id && selection?.selectedSubcategory?._id) {
            console.log('Selection changed:', selection);
            fetchProductsForVendor(
              selection.vendor._id, 
              selection.selectedSubcategory._id
            );
          }
        }
    }, [expandedProduct, selectionSummary]); // Added selectionSummary as dependency
      

    useEffect(() => {
      const loadInitialData = async () => {
        setIsPageLoading(true);
        await Promise.all([
          fetchVendors(),
          getUoms()
        ]);
        // Add artificial delay for smooth transition
        await new Promise(resolve => setTimeout(resolve, 1500));
        setIsPageLoading(false);
      };
    
      loadInitialData();
    }, []);
    

    const fetchVendors  = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${fetchUrl}vendor/get-vendorsBy-productCategoryId?product_category_id=${categoryId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        if (response.ok) {
         // console.log(data.data);
          const filteredVendors = data.data.filter(vendor => vendor.name !== "Any Vendor");
        setVendors(filteredVendors);
        //console.log(filteredVendors);
          return data.data;
        } else {
          if (response.status === 404) {
            setProducts([]);
            setPopupMessage('No vendor found.');
            setPopupType('error');
          } else if (response.status === 500) {
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setRetryFunction(() => () => fetchVendors(attempt));
          }
          setShowPopup(true);
        }
      } catch (error) {
        console.error('Failed to fetch vendors:', error);
        if (attempt < RETRY_LIMIT) {
          setRetryCount(attempt + 1);
          setTimeout(() => fetchVendors(attempt + 1), 1000);
        } else {
          setPopupMessage('Internal Server Error. Kindly Retry.');
          setPopupType('500');
          setShowPopup(true);
          setRetryFunction(() => () => fetchVendors(attempt));
        }
      } finally {
        setIsLoading(false);
      }
    };


    const handleVendorChange = async (newVendorId) => {
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsVendorChanging(true);

      const token = sessionStorage.getItem('token');
      
      try {
        const response = await fetch(
          `${fetchUrl}product/get-products-by-category-and-vendor?product_category_id=${categoryId}&vendor_id=${newVendorId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          }
        );
    
        const data = await response.json();

        await new Promise(resolve => setTimeout(resolve, 3000));

        if (response.ok && data.data) {
          const newVendor = vendors.find(v => v._id === newVendorId);
          
          // Update current vendor products
          setCurrentVendorProducts(data.data);
    
          // Create new selection summary with updated products
          const updatedSelections = selectionSummary.map(selection => {
            // Extract series number and type from current product
            const currentName = selection.product.product_name.toLowerCase();
            const seriesMatch = currentName.match(/(\d+)/);
            const series = seriesMatch ? seriesMatch[0] : '';
            
            // Find matching product from new vendor
            const matchingProduct = data.data.find(product => {
              const newProductName = product.product_name.toLowerCase();
              return (
                newProductName.includes(series) && 
                ((currentName.includes('sliding') && newProductName.includes('sliding')) ||
                 (currentName.includes('casement') && newProductName.includes('casement')) ||
                 (currentName.includes('ventilator') && newProductName.includes('ventilator')) ||
                 (currentName.includes('french') && newProductName.includes('french')))
              );
            });
    
            if (matchingProduct) {
              return {
                ...selection,
                product: matchingProduct,
                vendor: newVendor
              };
            }
            return selection;
          });
    
          // Update state with new selections
          setSelectionSummary(updatedSelections);
        }
      } catch (error) {
        console.error('Error changing vendor:', error);
        setPopupMessage('Failed to change vendor. Please try again.');
        setPopupType('error');
        setShowPopup(true);
      } finally {
        setIsVendorChanging(false);
      }
    };
    
    
    
  

  
    const toggleProductExpand = (flatNo, idx) => {
      const key = `${flatNo}-${idx}`;
      const newExpandedProduct = expandedProduct === key ? null : key;
      setExpandedProduct(newExpandedProduct);
      
      if (newExpandedProduct) {
          const selection = selectionSummary.find(s => 
              s.flatNo.toString() === flatNo && 
              s.windowIndex?.toString() === idx
          );
          
          if (selection?.vendor?._id && selection?.selectedSubcategory?._id) {
              console.log('Selection changed:', selection);
              fetchProductsForVendor(
                  selection.vendor._id, 
                  selection.selectedSubcategory._id
              );
          }
      }
  };
  
  const handleProductChange = (idx, newProduct) => {
    setSelectionSummary(prev => 
      prev.map(selection => {
        if (selection.flatNo.toString() === activeFlat && selection.windowIndex === idx) {
          return {
            ...selection,
            product: newProduct
          };
        }
        return selection;
      })
    );
    setExpandedProduct(null);
  };
  

  const getFilteredProducts = (selection) => {
    if (!Array.isArray(currentVendorProducts)) return [];
    
    const currentType = selection.product.product_name.toLowerCase();
   
    
    return currentVendorProducts
      .filter(product => {
        const productName = product.product_name.toLowerCase();
        
        // Strict type matching for ventilator and french
        if (currentType.includes('sliding')) {
          return productName.includes('sliding') || productName.includes('casement');
        }
        
        // For casement windows
        if (currentType.includes('casement')) {
          return productName.includes('casement') || productName.includes('sliding');
        }
        
        // For other types, show exact matches only
        if (currentType.includes('ventilator')) {
          return productName.includes('ventilator');
        }
        if (currentType.includes('french')) {
          return productName.includes('french');
        }
        return false;
      })
      .sort((a, b) => {
        const aName = a.product_name.toLowerCase();
        const bName = b.product_name.toLowerCase();
        
        // Match current type first
        if (currentType.includes('ventilator')) {
          if (aName.includes('ventilator') && !bName.includes('ventilator')) return -1;
          if (!aName.includes('ventilator') && bName.includes('ventilator')) return 1;
        }
        if (currentType.includes('french')) {
          if (aName.includes('french') && !bName.includes('french')) return -1;
          if (!aName.includes('french') && bName.includes('french')) return 1;
        }

        if (currentType.includes('sliding')) {
          if (aName.includes('sliding') && !bName.includes('sliding')) return -1;
          if (!aName.includes('sliding') && bName.includes('sliding')) return 1;
        }
        if (currentType.includes('casement')) {
          if (aName.includes('casement') && !bName.includes('casement')) return -1;
          if (!aName.includes('casement') && bName.includes('casement')) return 1;
        }
        
        // Sort by series number
        const aSeriesMatch = a.product_name.match(/(\d+)\s*Series/);
        const bSeriesMatch = b.product_name.match(/(\d+)\s*Series/);
        const aSeries = aSeriesMatch ? parseInt(aSeriesMatch[1]) : 0;
        const bSeries = bSeriesMatch ? parseInt(bSeriesMatch[1]) : 0;
        return bSeries - aSeries;
      });
  };
  
  

  useEffect(() => {
    if (selectionSummary.length > 0) {
      const firstSelection = selectionSummary[0];
      fetchProductsForVendor(firstSelection.vendor._id);
    }
  }, []);



  const flatSelections = selectionSummary.reduce((acc, curr) => {
    if (!acc[curr.flatNo]) acc[curr.flatNo] = [];
    acc[curr.flatNo].push(curr);
    return acc;
  }, {});

  useEffect(() => {
    if (Object.keys(flatSelections).length > 0 && !activeFlat) {
      setActiveFlat(Object.keys(flatSelections)[0]);
    }
  }, [selectionSummary]);

  const calculateSubtotal = (selection) => {
    const price = selection.product.selling_price;
    const area = selection.areaInSFT;
    return price * area;
  };
  
  const calculateFlatTotals = (selections) => {
    const subtotal = selections.reduce((sum, selection) => sum + calculateSubtotal(selection), 0);
    const tax = subtotal * 0.18; // 18% GST
    const total = subtotal + tax;
    return { subtotal, tax, total };
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const generateWindowQuotation = () => {
    const organizationId = sessionStorage.getItem('organizationId') || '';
    const username = sessionStorage.getItem('username'); // Get username from session

    
    const quotation = {
      quotation_date: new Date(),
      expiry_date: new Date(new Date().setDate(new Date().getDate() + 30)),
      status: 'Draft',
      quotation_type: 'Group',
      customer_id: customerId,
      project_id: projectId,
      product_category_id: categoryId || '',
      tax_prefrence: 'Taxable',
      organizationId: organizationId,
      items: []
    };
  
    let subtotal = 0;
    let taxAmount = 0;
  
    selectionSummary.forEach(selection => {
      const windowItem = {
        item_id: selection.product._id,
        item_name: selection.product.product_name,
        quantity: selection.areaInSFT,
        selling_price: selection.product.selling_price,
        total: selection.product.selling_price * selection.areaInSFT,
        UOM: 'Sq Ft',
        tax_type: 'GST',
        tax_rate: selection.product.GST,
        vendor_id: selection.vendor._id,
        vendor_name: selection.vendor.name,
        subcategory_id: selection.selectedSubcategory._id,
        subcategory_name: selection.selectedSubcategory.product_sub_category_name,
        specifications: selection.product.specifications,
        area_types: {
          area_name: selection.roomName,
          area: selection.areaInSFT.toString(),
          window_size: selection.windowSize,
          flat_no: selection.flatNo.toString(),
          window_index: selection.windowIndex.toString()
        }
      };
  
      quotation.items.push(windowItem);
      subtotal += windowItem.total;
    });
  
    taxAmount = subtotal * 0.18;
    quotation.subtotal = subtotal;
    quotation.tax_amount = taxAmount;
    quotation.total = subtotal + taxAmount;
  
    return quotation;
  };


  const saveWindowQuotation = async () => {
    setIsGenerating(true);

    //setShowQuotationPopup(true);

    try {
      const quotation = generateWindowQuotation();
      const token = sessionStorage.getItem('token');
      const organizationId = sessionStorage.getItem('organizationId');
  
      const response = await fetch(
        `${fetchUrl}customer/quotations/save-quotation?organizationId=${organizationId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token,
          },
          body: JSON.stringify(quotation),
        }
      );
  
      const data = await response.json();
      console.log('Quotation saved:', data);
      if (response.ok) {
        setShowPopup(true);
        setPopupType('success');
        setPopupMessage('Quotation saved successfully');
         setTimeout(() => {
          setShowQuotationPopup(true);
        }, 500); 
      } 

      await new Promise(resolve => setTimeout(resolve, 2000)); // Add delay for loading effect

      if (data.success) {
        navigate('/consumer/app/quotationsummary', { 
          state: { 
            quotation,
            selectionSummary,
            totalAmount: quotation.total,
            categoryId,
            customerId,
            projectId,
            username: sessionStorage.getItem('username'),
            quotationId: data.data._id  // Add the quotation ID from response

          } 
          
        });
      }

    } catch (error) {
      console.error('Error saving quotation:', error);
      setShowPopup(true);
      setPopupType('error');
      setPopupMessage('Failed to save quotation');
    }
  };


  const getUoms = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const getUomUrl = `${fetchUrl}UOMs/get-all-UOMs`;
  
      const response = await fetch(getUomUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      const data = await response.json();
      const output = await handleGetResponse(response , data)
      console.log('Uoms:', data);
      if (output) {
        setUoms(data.data);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => getUoms(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => getUoms(attempt)); // Set retry function
      }
    }
  };

  useEffect(() => {
    getUoms();
  }, []);
  
  const getUOMText = (uomId) => {
    const foundUom = uoms.find(uom => uom._id === uomId);
    return foundUom?.UOM || 'Sq.Ft';
  };

  const handleFlatChange = async (flatNo) => {
    // Immediately update the selected tab
    setActiveFlat(flatNo);
    
    // Then show skeleton loader
    setIsFlatChanging(true);
    
    // Wait for animation
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    // Hide loader and show content
    setIsFlatChanging(false);
  };
  
    return (
      <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      {isPageLoading ? (
  <div className="bg-white rounded-xl shadow-lg animate-pulse">
    {/* Header Skeleton */}
    <div className="p-4 border-b">
      <div className="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
      <div className="h-16 bg-gray-100 rounded w-full"></div>
    </div>

    {/* Vendor Selection Skeleton */}
    <div className="p-4 border-b">
      <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
      <div className="flex gap-4 overflow-x-auto pb-2">
        {[1, 2, 3, 4].map(i => (
          <div key={i} className="flex flex-col items-center min-w-[100px]">
            <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
            <div className="h-4 bg-gray-200 rounded w-16 mt-2"></div>
          </div>
        ))}
      </div>
    </div>

    {/* Flat Tabs Skeleton */}
    <div className="flex border-b">
      {[1, 2, 3].map(i => (
        <div key={i} className="px-8 py-4">
          <div className="h-6 bg-gray-200 rounded w-20"></div>
        </div>
      ))}
    </div>

    {/* Content Skeleton */}
    <div className="p-4">
      {[1, 2, 3].map(i => (
        <div key={i} className="mb-4 bg-gray-100 rounded-lg p-4">
          <div className="h-6 bg-gray-200 rounded w-1/4 mb-3"></div>
          <div className="h-5 bg-gray-200 rounded w-3/4 mb-3"></div>
          <div className="flex justify-between">
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          </div>
        </div>
      ))}
    </div>
  </div>
      ) : (
        <div className="bg-white rounded-xl shadow-lg">
  <div className="p-4 border-b">
    <h2 className="text-2xl font-bold text-gray-800">Confirm Selections</h2>
    <div className="flex items-center p-3 bg-blue-50 gap-2 mt-4 rounded-lg">
      <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 text-xl" />
      <p className="text-md text-blue-700">
        Click on any product details to change
      </p>
    </div>
  </div>

   {/* Vendor selection with clear labeling */}
   <div className=" border-b p-4">
  <h3 className="text-xl font-semibold text-blue-700 mb-4">
    Select Window Brand
  </h3>
  <div className="overflow-x-auto">
    <div className="flex gap-4 pb-2" style={{ minWidth: 'max-content' }}>
      {vendors.map(vendor => (
        <div
          key={vendor._id}
          className={`flex flex-col items-center p-2 border-2 rounded-lg cursor-pointer min-w-[100px]
            ${selectionSummary[0]?.vendor._id === vendor._id ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}
            hover:border-blue-500 hover:shadow-md transition-all`}
          onClick={() => handleVendorChange(vendor._id)}
        >
          <img
            src={`${imageUrl}${vendor.image}`}
            alt={vendor.name}
            className="w-10 h-10 rounded-full object-cover shadow-sm"
          />
          <span className="mt-2 text-sm font-medium text-center">{vendor.name}</span>
        </div>
      ))}
    </div>
  </div>
   {/* Add loading overlay in the JSX where vendor selection is displayed */}
   {isVendorChanging && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-xl shadow-2xl">
      <div className="flex flex-col items-center gap-4">
        <div className="relative">
          <div className="w-12 h-12 rounded-full border-4 border-gray-200"></div>
          <div className="w-12 h-12 rounded-full border-4 border-blue-500 border-t-transparent animate-spin absolute top-0"></div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <span className="text-lg font-semibold text-gray-800"></span>
          <span className="text-lg text-gray-800">Please wait a moment...</span>
        </div>
      </div>
    </div>
  </div>
)}



</div>

       {/* Flat Tabs */}
       <div className="overflow-x-auto">
  <div className="flex border-b" style={{ minWidth: 'max-content' }}>
    {Object.keys(flatSelections).map((flatNo) => (
      <button
        key={flatNo}
        onClick={() => handleFlatChange(flatNo)}
        className={`px-8 py-4 text-lg font-medium transition-colors whitespace-nowrap
          ${activeFlat === flatNo 
            ? 'bg-blue-50 border-b-4 border-blue-500 text-blue-700' 
            : 'text-gray-600 hover:bg-gray-50'}`}
      >
        Flat {flatNo}
      </button>
    ))}
  </div>
</div>

{isFlatChanging ? (
  <div className="p-4 space-y-4">
    {[1, 2, 3].map((item) => (
      <div key={item} className="bg-gray-100 rounded-lg p-4 animate-pulse">
        <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="flex justify-between">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          </div>
          <div className="flex justify-between">
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          </div>
        </div>
      </div>
    ))}
  </div>
) : (




          
          <div className="p-4">
          {activeFlat && flatSelections[activeFlat].map((selection, idx) => (
    <div key={idx} className="mb-3">
      <div className="bg-gray-100 rounded-lg p-4">
        <div className="flex flex-col">
          <div className="mb-2">
            <p className="text-lg font-bold">{selection.roomName}</p>
          </div>
                        
                        <div 
                          className="flex items-center justify-between mb-2 cursor-pointer hover:bg-gray-100 rounded-lg transition-colors"
                          onClick={() => toggleProductExpand(activeFlat, idx)}
                        >
                          <p className="text-lg font-semibold">{selection.product.product_name}</p>
                          {expandedProduct === `${activeFlat}-${idx}` ? 
                            <FaChevronUp className="text-gray-500 ml-2" /> : 
                            <FaChevronDown className="text-gray-500 ml-2" />
                          }
                        </div>
                        
                        <div className="relative flex flex-col">
  <p className="text-md">
    Size: <span className="font-semibold">{selection.windowSize}</span>
  </p>

  <div className="absolute right-0 top-1/2 -translate-y-1/2">
  <p className="text-blue-600 font-bold text-lg">
    ₹{selection.product.selling_price}/{getUOMText(selection.product.UOM_id)}
  </p>
</div>

  <p className="text-md">
    Area: <span className="font-semibold">{selection.areaInSFT} Sq.Ft</span> 
  </p>
</div>

                         {/* <p className="text-md ">
  <span className="font-semibold">Type:</span> {selection.subcategory}
</p> */}
                        {/* <div className="mt-2 border-t pt-2">
  <p className="text-right text-blue-600 font-semibold">
    Subtotal: ₹{calculateSubtotal(selection).toLocaleString()}
  </p>
</div> */}
                      </div>

                      {expandedProduct === `${activeFlat}-${idx}` && (
  <div className="fixed inset-0 z-50 md:relative md:inset-auto">
    <div className="fixed inset-0 bg-black bg-opacity-50 md:hidden" 
         onClick={() => setExpandedProduct(null)} />
    <div className="absolute bottom-0 left-0 right-0 md:relative md:bottom-auto md:mt-2 
                    bg-white rounded-t-xl md:rounded-lg shadow-lg border max-h-[80vh] 
                    overflow-y-auto md:w-full transform transition-transform 
                    duration-300 ease-out">
      <div className="sticky top-0 bg-white p-3 border-b md:hidden">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold">Select Product</h3>
          <button onClick={() => setExpandedProduct(null)} 
                  className="p-1 rounded-full hover:bg-gray-100">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      
      {getFilteredProducts(selection).map((product) => (
  <div
    key={product._id}
    className={`p-4 cursor-pointer border-b last:border-b-0 transition-colors
      ${product._id === selection.product._id 
        ? 'bg-blue-50 border-l-4 border-l-blue-500' 
        : 'hover:bg-gray-50'}`}
    onClick={() => handleProductChange(selection.windowIndex, product)}
  >
    <div className="flex items-start gap-4">
      <img
        src={`${imageUrl}${product.product_image}`}
        alt={product.product_name}
        className="w-20 h-20 rounded-lg object-cover flex-shrink-0"
      />
      <div className="flex-1 min-w-0">
        <p className="font-medium text-base mb-1">{product.product_name}</p>
        <p className="text-blue-600 font-bold text-lg mb-2">₹{product.selling_price}/{getUOMText(selection.product.UOM_id)}</p>
        {product.specifications && (
          <div className="text-sm text-gray-600 space-y-1">
            {JSON.parse(product.specifications).map((spec, index) => {
              const [key, value] = Object.entries(spec)[0];
              return value ? (
                <p key={index} className="truncate">
                  <span className="font-medium">{key}:</span> {value}
                </p>
              ) : null;
            })}
          </div>
        )}
      </div>
    </div>
  </div>
))}

    </div>
  </div>
)}

                          </div>
                       
                     
                   
                </div>
             
            ))}




          </div>
           )}
      
     


      

      {showBackToTop && (
  <button
    onClick={scrollToTop}
    className="fixed bottom-24 right-2 bg-red-600 text-white w-10 h-10 rounded-full shadow-lg hover:bg-red-700 transition-colors z-50 flex items-center justify-center"
  >
    <FontAwesomeIcon className='w-6 h-6' icon={faChevronUp} />
  </button>
)}


{/* {showQuotationPopup && (
 <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center z-50">
 <div className="bg-white rounded-t-xl shadow-xl w-full max-h-[90vh] animate-slide-up">
   <div className="p-4 flex justify-between items-center z-10 border-b bg-white">
     <h2 className="text-xl font-bold text-gray-800">Quotation Summary</h2>
     </div>

      <div className="max-h-[50vh] overflow-y-auto p-4">
        {Object.entries(selectionSummary.reduce((acc, selection) => {
          const key = selection.product.product_name;
          if (!acc[key]) {
            acc[key] = {
              totalArea: 0,
              totalPrice: 0,
              count: 0
            };
          }
          acc[key].totalArea += selection.areaInSFT;
          acc[key].totalPrice += selection.areaInSFT * selection.product.selling_price;
          acc[key].count += 1;
          return acc;
        }, {})).map(([productName, data]) => (
          <div key={productName} className="mb-3 p-3 bg-gray-100 border border-gray-300 rounded-lg">
            <h3 className="font-semibold text-lg ">
              {productName}
            </h3>
            <div className="mt-2 grid grid-cols-2 gap-2 text-lg ">
            <p>Total Windows: <span className="font-semibold text-[#1a237e]">{data.count}</span></p>
              <p>Area: <span className="font-semibold">{data.totalArea.toFixed(2)} sq.ft</span></p>
              <p className="col-span-2 text-right text-lg font-bold text-blue-600">
                ₹{data.totalPrice.toLocaleString('en-IN')}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-3 p-3 border-t">
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg sm:text-xl font-bold">Total Amount:</span>
          <span className="text-xl sm:text-2xl font-bold text-blue-600">
            ₹{selectionSummary.reduce((total, selection) =>
              total + (selection.areaInSFT * selection.product.selling_price), 0
            ).toLocaleString('en-IN')}
          </span>
        </div>

        <button
          className="w-full bg-[#E74C3C] text-white py-2.5 rounded-lg font-semibold hover:bg-[#C0392B] transition-colors text-base"
          onClick={() => {
            setShowQuotationPopup(false);
          }}
        >
          OK
        </button>
      </div>
    </div>
  </div>
)} */}


</div>
      )}
{activeFlat && (

<div className="mt-4 p-4">
{/* <h3 className="text-xl font-semibold mb-4">Price Summary</h3>
<div className="space-y-4 text-lg">
  <div className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
    <span className="font-medium">Total Items Cost:</span>
    <span>₹{calculateFlatTotals(flatSelections[activeFlat]).subtotal.toLocaleString()}</span>
  </div>
  <div className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
    <span className="font-medium">GST (18%):</span>
    <span>₹{calculateFlatTotals(flatSelections[activeFlat]).tax.toLocaleString()}</span>
  </div>
  <div className="flex justify-between items-center p-4 bg-blue-50 rounded-lg text-xl font-bold text-blue-700">
    <span>Final Amount:</span>
    <span>₹{calculateFlatTotals(flatSelections[activeFlat]).total.toLocaleString()}</span>
  </div>
</div> */}

{/* Clear call-to-action */}
{isGenerating ? (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-xl shadow-2xl">
      <div className="flex flex-col items-center gap-4">
        <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        <span className="text-lg font-semibold">Generating Quote...</span>
      </div>
    </div>
  </div>
) : (
  <div className="flex justify-center">
    <button
      onClick={saveWindowQuotation}
      className="w-2/3 md:w-1/2 lg:w-1/3 text-white font-semibold py-1 px-2 rounded-lg bg-[#E74C3C] hover:bg-[#C0392B] transition-colors duration-200 text-xl shadow-lg"
    >
      Confirm
    </button>
  </div>
)}

</div>
)}
      </div>
    );
  };
  

export default WindowSummary;
