import * as React from "react";
 import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import '.../custom.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function ForgotPassword () {
     const Navigate = useNavigate();
     const [email ,setemail]=useState();
     const [Emptyemail,setEmptyemail]=useState(false);
     const [InvalidEmail,setInvalidEmail]=useState(false);
     const [checkEmailAllert,setcheckEmailAllert]=useState(false);
     const [UnregisterdEmail,setUnregisterdEmail]=useState(false);
     const [isLoading, setIsLoading] = useState(false);

     const ForgotPassword = process.env.REACT_APP_FORE_APILINK + '/user/user-forgot-password';
     console.log(ForgotPassword);

     //This function calls when an onchnge event occures in email input field 
    const HandlechangeEmail= async(event)=>{
     const emailEnterd= event.target.value
     setemail(emailEnterd);
     setInvalidEmail(false);
     setEmptyemail(false);
     setUnregisterdEmail(false);
    }; 
    //This function calls when submit button clicked 
    const  handlesubmitEmail=async()=>{
   //It check the email is in empty or not 
      if (!email) {
        setEmptyemail("Please enter your email.");//when email have an empty data 
          return;
      }else if(!validateEmail(email)){
        setInvalidEmail('Enter a valid Email');
      }else {
        setIsLoading(true);
        const Email={
        email:email
        }
        try {
          const res = await fetch(ForgotPassword, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(Email),
          });
          if(res.ok){
         setcheckEmailAllert(true);
         setUnregisterdEmail(false);
          }else{
            setUnregisterdEmail('Email you entred is not registerd');
          }
        }catch(error){
        }
        finally {
          setIsLoading(false);
       }
      }
      
      
    };

    const validateEmail = async (email) => {
      const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailValidation.test(email);
    };

    const handleResetPasswordAlert= async()=>{
      setcheckEmailAllert(false);
      Navigate('/bb');
    }
    
    return (
      <div className="w-full flex justify-center items-center h-screen font-roboto overflow-hidden">
          <div className="flex w-full justify-center items-center">
              <div className="flex flex-col space-y-4 justify-center w-full items-center">
                  <div className='flex flex-row justify-center items-center'>
                      <img src={'/BB.png'} alt="Logo" className='h-16 w-16 sm:w-14 sm:h-14'/>
                      <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
                  </div>
                  <div className="flex-col w-1/3 sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4 sm:p-0">
                      <p className="text-2xl pt-4">Forgot Password</p>
                      <div className="p-6 sm:px-0 relative">
                      <p className="text-sm text-center mb-4">
                   Enter the email address you used when you joined <br />
                  and we'll send you instructions to reset your password
                </p>

                          <div className="flex-col w-full">
                              <div className='flex justify-center'>
                                  <p className="text-md">Email</p>
                              </div>
                              <div className="flex-row flex items-center justify-center space-x-2 pl-2 pr-2 w-1/2 mx-auto">
                              <input
                               type="email"
                               placeholder="Enter your Email"
                               className="w-full rounded-sm focus:outline-none focus:ring-[1px] focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                               value={email}
                               onChange={HandlechangeEmail}
                               />
                       </div>

                              {(Emptyemail || InvalidEmail || UnregisterdEmail) && (
                                  <div className="text-red-500 text-[12px] text-center mt-2">
                                      <p>{Emptyemail || InvalidEmail || UnregisterdEmail}</p>
                                  </div>
                              )}
                          </div>
                          <div className="flex justify-center items-center mt-6">
                              <button 
                                  className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1"
                                  onClick={handlesubmitEmail}
                              >
                                  Submit
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          {isLoading && (
              <div className="fixed top-0 left-0 w-screen h-screen bg-gray-200 opacity-65 flex justify-center items-center z-50">
                  <FontAwesomeIcon icon={faSpinner} spin size="3x" /> Loading
              </div>
          )}

          {checkEmailAllert && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-sm shadow-lg max-w-md">
                      <p className="text-center text-md mb-4">
                          Reset password link has been sent to your registered Email.
                          <br/> Kindly check your Email Inbox.
                      </p>
                      <div className="flex justify-center">
                          <button 
                              className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1"
                              onClick={handleResetPasswordAlert}
                          >
                              OK
                          </button>
                      </div>
                  </div>
              </div>
          )}
      </div>
  );
}



export default ForgotPassword;
