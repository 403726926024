import React, { useEffect, useState } from "react";
import { FaImage } from "react-icons/fa";
import CustomDropdown from "../BBAdmin/Components/CustomDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QuoteForm from "./QuoteForm";
import { useLocation } from 'react-router-dom';


const MaterialReq = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedQuoteType, setSelectedQuoteType] = useState({ label: "Quote", value: "Quote" });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId,setSelectedCategoryId] = useState(null);
  const [quoteDate, setQuoteDate] = useState(new Date());
  const [quote,setQuote] = useState(null);
  const location = useLocation();
  const  {quotationId}  = location.state || {};
  console.log(quotationId)
  const quoteTypes = [
    { label: "Invoive", value: "Invoive" },
    { label: "Quote", value: "Quote" }
  ];

  const categories = [
    { label: "Tiles", value: "tiles" },
    { label: "Doors", value: "doors" },
    { label: "Windows", value: "windows" }
  ];

  const customers = [
    { label: "Customer 1", value: "1" },
    { label: "Customer 2", value: "2" }
  ];

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const fetchQuotationById = async (quotationId ) => {
    console.log(quotationId);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotation?quotation_id=${quotationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();
      console.log(data?.data);

  
          setQuote(data?.data);
          setSelectedImage(`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES2}${data?.data?.files[0]}`);
          console.log(`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES2}${data?.data?.files[0]}`)
          setSelectedCustomer(data?.data?.customer_id?.customer_name)
          setSelectedCategory(data?.data?.product_category_id?.product_category_name)
          setSelectedCategoryId(data?.data?.product_category_id?._id)
          setSelectedCustomerId(data?.data?.customer_id?._id)
      }  catch (error) {
        console.error('Failed to fetch:', error);
      }
    };

    useEffect(() => {
      fetchQuotationById(quotationId);
    }, []);



  return (
    <div className="bg-[#FAFAFA] flex flex-col w-full h-full overflow-y-auto p-4 pt-16 pb-20">
      {/* Top Selection Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-2">Customer Name<span className="text-red-500">*</span></label>
            <CustomDropdown
              options={customers}
              selected={selectedCustomer}
              onSelect={setSelectedCustomer}
              placeholder="Select Customer"
              showSearch={true}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-2">Type<span className="text-red-500">*</span></label>
            <CustomDropdown
              options={quoteTypes}
              selected={selectedQuoteType?.value || ''}
              onSelect={setSelectedQuoteType}
              placeholder="Select Type"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-2">Item Category<span className="text-red-500">*</span></label>
            <CustomDropdown
              options={categories}
              selected={selectedCategory}
              onSelect={setSelectedCategory}
              placeholder="Select Category"
              showSearch={true}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium text-gray-700 mb-2">Quote Date<span className="text-red-500">*</span></label>
            <DatePicker
              selected={quoteDate}
              onChange={(date) => setQuoteDate(date)}
              className="w-full p-2 border rounded-md"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex gap-4">
        {/* Left Column - Quote Form */}
        <div className="flex-1 border rounded-lg p-4 shadow-md bg-white">
        <QuoteForm 
  selectedCustomer={selectedCustomerId}
  selectedQuoteType={selectedQuoteType}
  selectedCategory={selectedCategoryId}
  quote={quote}
  
/>

        </div>

        {/* Right Column - Design Upload */}
        <div className="flex-1 bg-white rounded-lg p-4 border shadow-md h-[calc(100vh-100px)]">
  <h3 className="text-xl font-semibold mb-4">Design Upload</h3>
  <div
    className="border-2 border-dashed border-gray-300 rounded-lg p-4 h-[calc(100%-3rem)] flex flex-col items-center justify-center cursor-pointer"
    onDragOver={handleDragOver}
    onDrop={handleDrop}
    onClick={() => document.getElementById('imageUpload').click()}
  >
    {selectedImage ? (
      <img
        src={selectedImage}
        alt="Uploaded design"
        className="w-full h-full object-contain rounded-lg"
      />
    ) : (
      <>
        <FaImage className="text-4xl text-gray-400 mb-2" />
        <p className="text-gray-500 text-center">
          Drag and drop your image here<br />
          or click to select
        </p>
      </>
    )}
    <input
      type="file"
      accept="image/*"
      onChange={handleImageUpload}
      className="hidden"
      id="imageUpload"
    />
  </div>
</div>

      </div>
    </div>
  );
};

export default MaterialReq;
