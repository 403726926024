        import * as React from "react";
        import { useState,useEffect } from "react";
        import { useNavigate, useParams } from "react-router-dom"; 
        import { useLocation } from "react-router-dom";
        import { FaEye, FaEyeSlash } from 'react-icons/fa';
        //import '.../custom.css';
        import { Link } from "react-router-dom";
        
        function ResetPassword() {
          const Navigate = useNavigate();
          const location = useLocation();
          const UserDetails = location.state;
          // const UserDetails= {_id:'21288n'}
          const [password, setPassword] = useState("");
          const [confirmPassword, setConfirmPassword] = useState("");
          const [passwordError, setPasswordError] = useState(false);
          const [confirmPasswordError,setConfirmPasswordError]= useState('');
          const [EmptyData,setEmptyData]=useState(false);
          const [showPassword, setShowPassword] = useState(false);
          const [ShowConfimPassword, setShowConfimPassword] = useState(false);
          const {resetToken}= useParams();

          const ResetPassword = process.env.REACT_APP_FORE_APILINK + '/user/user-change-password';
          //password validation logic 
          const validatePassword = (password) => {
            const passwordValidation = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            return passwordValidation.test(password);
          };
//This function calls when password inputfield changes 

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setEmptyData(false);
    setConfirmPasswordError(false);

    // Validate password function called and check if password dose not satisfy the requiremnents it enters an error message shown.
    
    if (!validatePassword(newPassword)) {
      setPasswordError(true);
    } else {
      //
      setPasswordError(false);
    }
  };
//This function calls when confimpassword inputfield changes 
  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setEmptyData(false);
    // Validate password match
    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError(false);
    }
  };
 //when submit button clicked this function called 
 const handleSubmit = async() => {
   
  // For example, you can check if the passwords are valid and proceed accordingly
  if (!password || !confirmPassword) {
    setEmptyData("Please fill all password inputfields.");
      return;
    }else if (confirmPassword !== password) {
      setConfirmPasswordError("Both Passwords you enterd do not match.");

    }
  else if (validatePassword(password) && password === confirmPassword) {
   
    const resetData = UserDetails
    ? {
        newPassword: password,
        userId: UserDetails?.userId || '',
      }
    : {
        newPassword: password,
        resetToken: resetToken,
      };
      //console.log(resetData)
    const res = await fetch(ResetPassword, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetData),
    });
    if(res.ok){
      //if response ok it navigates to login page 
       Navigate('/measurit');
      
      localStorage.removeItem('LoginData');
        const loginPageUrl = '/measurit';
    
        // Push multiple states to the history to prevent going back to the homepage
        if (window.history && window.history.pushState) {
          for (let i = 0; i < 40; i++) {
            window.history.pushState({}, document.title, loginPageUrl);
          }
    
          window.addEventListener('popstate', function (event) {
            if (event.state === null) {
              for (let i = 0; i < 41; i++) {
                window.history.pushState({}, document.title, loginPageUrl);
              }
            }
          });
        }
    
        // Redirect the user to the login page
        window.location.replace(loginPageUrl);
    
    }
  } else {
    // Show an error message or prevent submission
  }
};
  const PasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const ConfimPasswordVisibility = () => {
    setShowConfimPassword((prev) => !prev);
  };

         // const [UserToken,setUserToken]= useState('');
          /* useEffect (()=>{
        const Token =  localStorage.getItem('UserToken');
        setUserToken(Token);
          },[]); */

          return (
            <div className="w-full flex justify-center items-center h-screen font-roboto overflow-hidden">
                <div className="flex w-full justify-center items-center">
                    <div className="flex flex-col space-y-4 justify-center w-full items-center">
                        <div className='flex flex-row justify-center items-center'>
                            <img src={'/BB.png'} alt="Logo" className='h-16 w-16 sm:w-14 sm:h-14'/>
                            <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
                        </div>
                        <div className="flex-col w-1/2 sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4 sm:p-0">
                            <p className="text-2xl pt-4">RESET PASSWORD</p>
                            <div className="p-6 sm:px-0 relative">
                                <div className="flex-col w-full">
                                    <div className='flex justify-center'>
                                        <p className="text-md">New Password</p>
                                    </div>
                                    <div className="flex-row flex items-center justify-center space-x-2 pl-2 pr-2 w-full relative">
                                        <span className="flex justify-center items-center absolute left-5">
                                            {showPassword ? (
                                                <FaEye className="text-gray-400 cursor-pointer" onClick={PasswordVisibility} />
                                            ) : (
                                                <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={PasswordVisibility} />
                                            )}
                                            <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                        </span>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="New Password"
                                            className="w-5/6 sm:w-full md:w-full rounded-sm focus:outline-none focus:ring-[1px] focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>
                                    {passwordError && (
                                        <div className="text-red-500 text-[12px] text-center mt-1">
                                            Password must be 8 characters and include at least one uppercase (A), one digit(1), and one special character(@).
                                        </div>
                                    )}
                                </div>
        
                                <div className='flex flex-col p-2 sm:p-0 sm:mt-4'>
                                    <div className='flex justify-center'>
                                        <p className="text-md">Confirm Password</p>
                                    </div>
                                    <div className="flex-row flex items-center space-x-2 relative justify-center pl-2 pr-2 w-full">
                                        <span className="flex justify-center items-center absolute left-5">
                                            {ShowConfimPassword ? (
                                                <FaEye className="text-gray-400 cursor-pointer" onClick={ConfimPasswordVisibility} />
                                            ) : (
                                                <FaEyeSlash className="text-gray-400 cursor-pointer" onClick={ConfimPasswordVisibility} />
                                            )}
                                            <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                        </span>
                                        <input
                                            type={ShowConfimPassword ? "text" : "password"}
                                            placeholder="Confirm Password"
                                            className="w-5/6 sm:w-full md:w-full rounded-sm focus:outline-none focus:ring-[1px] focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                        />
                                    </div>
                                    {confirmPasswordError && (
                                        <div className="text-red-500 text-[12px] text-center mt-1">
                                            {confirmPasswordError}
                                        </div>
                                    )}
                                </div>
        
                                {EmptyData && (
                                    <div className="text-red-500 text-[12px] text-center mt-2">
                                        {EmptyData}
                                    </div>
                                )}
        
                                <div className="flex justify-center items-center mt-auto pt-8">
                                    <button 
                                        className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1 mb-4"
                                        onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        
   }
      export default ResetPassword;
