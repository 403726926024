import React from 'react';
import { Outlet } from 'react-router-dom';
// import TopandBottomMenu from './TopandBottomMenu';

function Main_Page() {
  return (
    // <TopandBottomMenu>
      <Outlet />
    // </TopandBottomMenu>
  );
}

export default Main_Page;
