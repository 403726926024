import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaTimes, FaRuler } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faListAlt, faTag, faTimes, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { 
  faBed, 
  faBath, 
  faCouch, 
  faUtensils, 
  faPray,
  faWarehouse,
  faChair,
  faUmbrellaBeach,
  faTools,
  faDoorOpen
} from '@fortawesome/free-solid-svg-icons';

const WindowsQuotationDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { quotationId } = location.state || {};

  const [quotationData, setQuotationData] = useState(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
const [showNoChangesModal, setShowNoChangesModal] = useState(false);
  const [isSavingQuote, setIsSavingQuote] = useState(false);
  const modalRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState(0);
  const dragThreshold = 100; // Pixels to drag before closing
  const [selectedIndex, setSelectedIndex] = useState(null);
  const fetchUrl2 = process.env.REACT_APP_FORE_BRICKBUCKET;
  const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;

  useEffect(() => {
    fetchQuotationDetails();
  }, []);

   useEffect(() => {
        if (isDetailsModalVisible || isProductModalVisible) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
        
        return () => {
          document.body.style.overflow = 'unset';
        };
      }, [isDetailsModalVisible, isProductModalVisible]);
      

  const fetchQuotationDetails = async () => {
    try {
      const token = await sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/get-quotation?quotation_id=${quotationId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });

      console.log(response);
      const data = await response.json();
      setQuotationData(data.data);
      console.log(data.data);
    } catch (error) {
      console.error('Error fetching quotation:', error);
    }
  };

  useEffect(() => {
    if (quotationData) {
      fetchAttributesByproductCategory();
    }
  }, [quotationData]);

  const fetchAttributesByproductCategory = async ()=>{
    try {
      const token = await sessionStorage.getItem('token');
      const ProductCategoryId = quotationData.product_category_id._id;
      const response = await fetch(`${fetchUrl2}product-attributes/get-all-attributes-by-productCategory?product_category_id=${ProductCategoryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      //console.log(data);
      if (data.success) {
        setAttributes(data.data);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
    };

    const handleChangeProduct = async (categoryName, currentProductId, index) => {
      setSelectedCategory(categoryName);
      setSelectedProductId(currentProductId);
      setSelectedIndex(index); // Add this state variable
      setIsProductModalVisible(true);
      
      try {
        const token = await sessionStorage.getItem('token');
        const vendorId = quotationData.vendor._id;
        const ProductCategoryId = quotationData.product_category_id._id;
    
        const response = await fetch(`${fetchUrl2}product/get-products-by-category-and-vendor?product_category_id=${ProductCategoryId}&vendor_id=${vendorId}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
    
        const data = await response.json();
        if (data.success && data.data) {
          setAvailableProducts(data.data);
        }
      } catch (error) {
        console.log('Error fetching product data:', error.message);
      }
    };
    
    const handleProductSelection = (selectedProduct) => {
      const updatedItems = quotationData.items.map((item, idx) => {
        if (idx === selectedIndex) {
          const newTotal = item.quantity * selectedProduct.selling_price;
          const newTaxAmount = (newTotal * selectedProduct.GST) / 100;
          
          return {
            ...item,
            item_id: selectedProduct._id,
            item_name: selectedProduct.product_name,
            selling_price: selectedProduct.selling_price,
            total: newTotal,
            tax_rate: selectedProduct.GST,
            total_tax_amount: newTaxAmount,
            isModified: true
          };
        }
        return item;
      });
    
      const newSubtotal = updatedItems.reduce((sum, item) => sum + item.total, 0);
      const newTaxAmount = updatedItems.reduce((sum, item) => sum + item.total_tax_amount, 0);
      const newTotal = newSubtotal + newTaxAmount;
    
      setQuotationData({
        ...quotationData,
        items: updatedItems,
        subtotal: newSubtotal,
        tax_amount: newTaxAmount,
        total: newTotal
      });
    
      setHasUnsavedChanges(true);
      setIsProductModalVisible(false);
    };
  

  const handleSaveQuotation = async () => {
    try {
      const token = await sessionStorage.getItem('token');
      const organizationId = await sessionStorage.getItem('organizationId');
  
      const hasModifiedItems = quotationData.items.some(item => item.isModified);
  
      if (!hasModifiedItems) {
        setShowNoChangesModal(true);
        return;
      }
  
      // Create new quotation payload without quotation_number
      const { _id, quotation_number, quotation_date, ...quotationWithoutNumber } = quotationData;
  
      const quotationPayload = {
        ...quotationWithoutNumber,
        quotation_date: new Date().toISOString(),
        organizationId,
        selected:false,
        items: quotationData.items.map(item => ({
          item_id: item.item_id,
          item_name: item.item_name,
          quantity: item.quantity,
          selling_price: item.selling_price,
          cost_price: item.cost_price || 0,
          discount: item.discount || 0,
          tax_rate: item.tax_rate,
          tax_type: item.tax_type || 'GST', 
          total_tax_amount: item.total_tax_amount,
          tax_amount: item.tax_amount,
          total: item.total,
          UOM: item.UOM,
          UOM_id: item.UOM_id,
          quantity_breakup: item.quantity_breakup,
          area_types: item.area_types
        }))
      };
  console.log('Quotation Payload:', quotationPayload);
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/quotations/save-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(quotationPayload),
      });
  
      const data = await response.json();
      console.log('Quotation saved:', data);
      if (data.success) {
        navigateToQuoteSummary(data.data)
        //navigate('/consumer/app/home');
      }
    } catch (error) {
      console.error('Failed to save quotation:', error);
    }
  };

  const navigateToQuoteSummary = (quotation) => {
    try {
      const navigationData = {
        quotation_id: quotation._id || '',
        product_category_id: quotation.product_category_id || '',
        project_id: quotation.project_id || '',
        package_id: '',
        product_category_name : 'Windows',
        projectName:  '',
        timestamp: Date.now()
      };

      navigate('/consumer/app/summarydetails', {
        state: navigationData,
        replace: true 
      });
 
    } catch (error) {
      console.error('Navigation failed:', error);
      // Fallback navigation
      navigate('/consumer/app/summarydetails');
    }
  }; 

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setDragPosition(e.touches[0].clientY);
  };
  
  const handleTouchMove = (e) => {
    if (!isDragging) return;
    
    const currentPosition = e.touches[0].clientY;
    const diff = currentPosition - dragPosition;
    
    if (diff > 0) { // Only allow downward drag
      modalRef.current.style.transform = `translateY(${diff}px)`;
    }
  };

  const handleTouchEnd = (e) => {
    if (!isDragging) return;
    
    const currentPosition = e.changedTouches[0].clientY;
    const diff = currentPosition - dragPosition;
    
    if (diff > dragThreshold) {
      // Close the modal with animation
      modalRef.current.style.transform = `translateY(100%)`;
      setTimeout(() => setIsDetailsModalVisible(false),
      setIsProductModalVisible(false),
      300);
    } else {
      // Reset position
      modalRef.current.style.transform = 'translateY(0)';
    }
    
    setIsDragging(false);
  };

  const getRoomIcon = (roomName) => {
    const roomTypes = {
      bedroom: faBed,
      bathroom: faBath,
      living: faCouch,
      kitchen: faUtensils,
      pooja: faPray,
      store: faWarehouse,
      dining: faChair,
      balcony: faUmbrellaBeach,
      utility: faTools,
      other: faDoorOpen
    };
    const roomType = Object.keys(roomTypes).find(type => 
      roomName.toLowerCase().includes(type)
    );
  
    return roomTypes[roomType] || faDoorOpen;
  };
  
  
  const renderHeader = () => (
    <div className="bg-white p-3 shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between mb-2 relative">
        <button onClick={() => navigate(-1)} className="absolute left-0">
          <FontAwesomeIcon icon={faArrowLeft} className="text-[#736f70] text-2xl" />
        </button>
        <div className="flex-1 text-center">
          <h1 className="text-xl font-bold text-[#EA011F]">{quotationData?.quotation_number}</h1>
        </div>   
      </div>
      <div className="flex items-center justify-between bg-red-50 p-2 rounded-lg">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faTag} className="text-[#EA011F] mr-2" />
          <span className="text-md font-semibold text-gray-700">Quotation Details</span>
        </div>
        <span className="text-md text-[#EA011F] font-bold">
          ₹{Number(quotationData?.total?.toFixed(0)).toLocaleString('en-IN')}
        </span>
      </div>
    </div>
  );

return (
  <div className="flex-1 bg-gray-50 min-h-screen">
    {renderHeader()}
    <div className="bg-[#F5F7FA] flex-1 p-1 pt-2 pb-12 overflow-y-auto">

    <div className="flex-1 bg-white">
  <div className="flex items-center px-2">
    <span className="text-lg font-semibold text-gray-700 mr-4">Selected Brand: </span>
    <div className="flex items-center">
      <div className="flex items-center p-3 bg-white rounded-lg">
        <div className="w-10 h-10 rounded-full overflow-hidden border border-gray-200">
          {quotationData?.vendor?.image ? (
            <img
              src={`${imageUrl}${quotationData.vendor.image}`}
              alt={quotationData.vendor.company_name}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-100 flex items-center justify-center">
              <span className="text-lg font-bold text-gray-400">
                {quotationData?.vendor?.name?.[0]?.toUpperCase() || "V"}
              </span>
            </div>
          )}
        </div>
        <span className="ml-3 text-base font-medium text-[#EA011F]">
          {quotationData?.vendor?.name || ""}
        </span>
      </div>
    </div>
  </div>
</div>
      <div className="">
        <div className="p-2">

         {/* Add this after the header section */}
        


         {quotationData?.items?.map((item, index) => (
  <div key={`${item._id}-${index}`} className="mx-0 mt-4 lg:mx-8 bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
    <div className="p-2 border-b border-gray-100">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon 
            icon={faWindowMaximize} 
            className="text-[#EA011F] text-sm sm:text-base" 
          />
          <span className="text-lg font-semibold text-[#EA011F]">
            {item.item_name}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <FaRuler className="text-gray-500" />
          <span className="text-sm font-medium text-gray-600">
            {item.quantity} {item.UOM}
          </span>
        </div>
      </div>
    </div>
              
    <div className="p-2">
      <div className="flex justify-between items-center mb-2">
        <span className="text-base font-medium text-gray-800">{item.item_name}</span>
        <div className="flex items-center gap-6">
        <button
      onClick={() => handleChangeProduct(item.item_name, item.item_id, index)}
      className="px-2 py-0 border border-[#EA011F] rounded-full"
    >
            <span className="text-[#EA011F] text-sm font-regular">Change</span>
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center bg-gray-100 p-2">
        <p className="text-sm text-gray-500">₹{item.selling_price}/{item.UOM}</p>
        <p className="font-bold text-md text-green-700">₹{item.total.toLocaleString('en-IN')}</p>
      </div>
      <div className="flex justify-end mt-1">
        <div className="text-right">
          <p className="text-xs text-gray-500">{item.tax_rate}% {item.tax_type}</p>
          <p className="text-xs text-gray-500">{quotationData?.tax_prefrence}</p>
        </div>
      </div>

      <div className="px-2 sm:px-4 pb-2">
        <button
          onClick={() => {
            setSelectedDetails(item.quantity_breakup);
            setIsDetailsModalVisible(true);
          }}
          className="w-full flex justify-between items-center p-2 sm:p-3 bg-gray-50 rounded-lg"
        >
          <div className="flex items-center gap-3">
            <FontAwesomeIcon icon={faListAlt} className="text-[#736f70]" />
            <span className="font-bold text-gray-800">View Area Details</span>
          </div>
         {/*  <span className="text-gray-600">{Object.keys(item.quantity_breakup).length} Units</span> */}
        </button>
      </div>
    </div>
  </div>
))}
        </div>
      </div>
    </div>


    {/* Area Details Modal */}
    {isDetailsModalVisible && (
  <div className="fixed inset-0 flex items-end justify-center z-50">
    <div className="backdrop-blur-sm absolute inset-0" onClick={() => setIsDetailsModalVisible(false)} />
    <div
      ref={modalRef}
      className="bg-white border-t-2 border-gray-500 rounded-t-2xl p-6 w-full max-w-2xl relative z-10 animate-slide-up"
      style={{height: '70vh'}}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
      
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-[#EA011F]">Area Details</h2>
        <FontAwesomeIcon
          icon={faTimes}
          className="text-2xl text-gray-600 cursor-pointer hover:text-[#EA011F]"
          onClick={() => setIsDetailsModalVisible(false)}
        />
      </div>

      <div className="h-[55vh] overflow-y-auto no-scrollbar">
        {Object.entries(selectedDetails || {}).map(([windowName, details]) => (
          <div key={windowName} className="bg-gray-50 p-3 rounded-lg mb-3">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{windowName}</h3>
            <div className="flex justify-between">
              <span className="text-gray-600">Area: {details.area} sq.ft</span>
              <span className="text-gray-600 font-medium">Units: {details.units}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}


    {/* Product Selection Modal */}
    {isProductModalVisible && (
  <div className="fixed inset-0 flex items-end justify-center z-50">
    <div className="backdrop-blur-sm absolute inset-0" onClick={() => setIsProductModalVisible(false)} />
    <div
      ref={modalRef}
      className="bg-white border-t-2 border-gray-500 rounded-t-2xl p-6 w-full max-w-2xl relative z-10 animate-slide-up"
      style={{height: '70vh'}}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
      
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-[#EA011F]">Select Product</h2>
        <FontAwesomeIcon
          icon={faTimes}
          className="text-2xl text-gray-600 cursor-pointer hover:text-[#EA011F]"
          onClick={() => setIsProductModalVisible(false)}
        />
      </div>

      <div className="h-[55vh] overflow-y-auto no-scrollbar">
        {availableProducts.map(product => (
          <button
            key={product._id || product.item_id}
            className="flex items-center p-4 border-b border-gray-200 w-full hover:bg-gray-50"
            onClick={() => handleProductSelection(product)}
          >
            <div className="mr-3">
              <div className={`h-4 w-4 rounded-full border-2 border-[#EA011F] flex justify-center items-center ${
                selectedProductId === product._id ? 'bg-[#EA011F]' : ''
              }`}>
                {selectedProductId === product._id && <div className="h-2 w-2 rounded-full bg-white" />}
              </div>
            </div>
            <img
              src={`${imageUrl}${product.product_image}`}
              alt={product.product_name}
              className="w-10 h-10 rounded-lg object-cover"
            />
            <div className="flex flex-1 items-center justify-between ml-4">
              <p className="text-lg font-bold text-gray-800">{product.product_name}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  </div>
)}

{showNoChangesModal && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-black bg-opacity-50 absolute inset-0" onClick={() => setShowNoChangesModal(false)} />
    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md relative z-10">
      <h2 className="text-xl font-bold text-[#EA011F] mb-4 text-center">No Changes Found</h2>
      <p className="text-gray-700 text-center mb-6">There are no modifications to save.</p>
      <button
        className="w-full bg-[#EA011F] text-white py-2 rounded-lg font-semibold"
        onClick={() => setShowNoChangesModal(false)}
      >
        Close
      </button>
    </div>
  </div>
)}

   {/* Fixed Bottom Button */}
<div className="fixed bottom-0 left-0 right-0 p-3 shadow-lg bg-gray-100">
  <div className="max-w-7xl mx-auto">    
  <button
  onClick={() => {
    const hasModifiedProducts = quotationData?.items?.some(item => item.isModified);
    
    if (hasModifiedProducts) {
      setIsSavingQuote(true);
      handleSaveQuotation();
    } else {
      setShowNoChangesModal(true);
    }
  }}
  className="w-full md:w-1/8 bg-red-500 py-2 px-4 rounded-lg text-white font-regular text-md hover:bg-red-700 whitespace-nowrap"
>
  Save Changes
</button>
  </div>
</div>

  </div>
);

}
  export default WindowsQuotationDetails;
   