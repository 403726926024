import React, { useEffect, useState } from 'react';
import { useLocation ,useNavigate } from 'react-router-dom';
import { faSortUp, faSortDown, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Loader} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import Search from '../Components/Search';

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => {
    // Handle optional chaining
    if (part.includes('?')) {
      const [mainPart, subPart] = part.split('?.');
      return acc && acc[mainPart] ? acc[mainPart][subPart] : undefined;
    }
    return acc && acc[part] !== undefined ? acc[part] : undefined;
  }, obj);
};

const PurchaseOrdersItemWIse = () => {
  const navigate = useNavigate();
  const location= useLocation();
  const { PurchaseOrderData } = location.state || {};
  const [productCategories, setProductCategories] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

    const [PurchaseOrders, setPurchaseOrders] = useState();
    //console.log(PurchaseOrders);
  const [isLoading, setIsLoading] = useState(true);
  const url = process.env.REACT_APP_FORE_BRICKBUCKET;
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
  //const [popupType, setPopupType] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
 // const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();
    const [SalesOrderPopup, setSalesOrderPopup] = useState(false);
    const [SalesOrdersData, setSalesOrdersData] = useState();
  const [SOItemQty, setSOItemQty] = useState(false);
  const [ReceivableQty, setReceivableQty] = useState(false);
  const [DeliveryQty, setDeliveryQty] = useState(false);
  const [createPOButtonEnabled, setCreatePOButtonEnabled] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [filteredPOItemwise, setFilteredPOItemwise] = useState([]);
  const searchTypes = ['PO Number', 'Vendors', 'Item Name', 'SO Number'];
  
  const sortArray = (key) => {
    let direction = 'ascending';

    // Toggle direction if the same key is clicked
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Update sort configuration
    setSortConfig({ key, direction });

    const sortedArray = [...filteredPOItemwise].sort((a, b) => {
      // Special handling for different sorting scenarios
      switch (key) {
        case 'PurchaseOrder.purchaseorder_date':
          const dateA = new Date(a.purchaseorder_date);
          const dateB = new Date(b.purchaseorder_date);
          return direction === 'ascending'
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();

        case 'purchaseorder_number':
          const numberA = a.purchaseorder_number;
          const numberB = b.purchaseorder_number;
          return direction === 'ascending'
            ? numberA.localeCompare(numberB)
            : numberB.localeCompare(numberA);

        case 'PurchaseOrder.vendor_id.city':
          const cityA = a.vendor_id.city;
          const cityB = b.vendor_id.city;
          return direction === 'ascending'
            ? cityA.localeCompare(cityB)
            : cityB.localeCompare(cityA);

        case 'PurchaseOrder.vendor_id.name':
          const vendorA = a.vendor_id.name;
          const vendorB = b.vendor_id.name;
          return direction === 'ascending'
            ? vendorA.localeCompare(vendorB)
            : vendorB.localeCompare(vendorA);

        default:
          // Fallback for item-based sorting
          if (key.startsWith('item.')) {
            const itemKey = key.replace('item.', '');
            
            // Sort purchase orders based on their first item's value
            const valueA = a.items.length > 0 
              ? getNestedValue(a.items[0], itemKey) 
              : undefined;
            const valueB = b.items.length > 0 
              ? getNestedValue(b.items[0], itemKey) 
              : undefined;

            // Handle null/undefined values
            if (valueA === undefined) return direction === 'ascending' ? 1 : -1;
            if (valueB === undefined) return direction === 'ascending' ? -1 : 1;

            // String comparison
            if (typeof valueA === 'string' && typeof valueB === 'string') {
              return direction === 'ascending'
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
            }

            // Numeric comparison
            if (typeof valueA === 'number' && typeof valueB === 'number') {
              return direction === 'ascending'
                ? valueA - valueB
                : valueB - valueA;
            }

            // Fallback comparison
            if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
            if (valueA > valueB) return direction === 'ascending' ? 1 : -1;

            return 0;
          }

          return 0;
      }
    });

    // Sort items within each purchase order
    const finalSortedArray = sortedArray.map(purchaseOrder => ({
      ...purchaseOrder,
      items: [...purchaseOrder.items].sort((a, b) => {
        const valueA = getNestedValue(a, key.replace('item.', ''));
        const valueB = getNestedValue(b, key.replace('item.', ''));

        // Handle null/undefined values
        if (valueA === undefined) return direction === 'ascending' ? 1 : -1;
        if (valueB === undefined) return direction === 'ascending' ? -1 : 1;

        // String comparison
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return direction === 'ascending'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        // Numeric comparison
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return direction === 'ascending'
            ? valueA - valueB
            : valueB - valueA;
        }

        // Fallback comparison
        if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
        if (valueA > valueB) return direction === 'ascending' ? 1 : -1;

        return 0;
      })
    }));

    // Update the filtered purchase orders
    setFilteredPOItemwise(finalSortedArray);
  };

  // Sorting icon logic
  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  
  
  
  const handleCheckboxChange = (id) => {
    const isAlreadySelected = selectedCategories.includes(id);
    const updatedSelectedCategories = isAlreadySelected
      ? selectedCategories.filter((c) => c !== id)
      : [...selectedCategories, id];
  
    setSelectedCategories(updatedSelectedCategories);
  
    // Update the selectAll state if all items are selected
    if (updatedSelectedCategories.length === PurchaseOrders.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  
    // Enable the Create Purchase Order button if at least one item is selected
    setCreatePOButtonEnabled(updatedSelectedCategories.length >= 1);
  };
  

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(PurchaseOrders.map(order => order.id));
    }
    setSelectAll(!selectAll); // Toggle the "Select All" checkbox state
  };

  const handleCreatePurchaseOrder = () => {
    // Your logic for creating a purchase order
     navigate("/bb/app/purchases/newpurchaseorder"); 
   // console.log("Creating Purchase Order for:", selectedCategories);
  };
  

  const handleCreate = () => {
    navigate("/bb/app/purchases/newpurchaseorder");
  };

  const handlenavigateToPurchaseOrderDetails = (purchaseOrder) => {
    navigate("/bb/app/purchases/purchaseorderdetails", {state:{PurchaseOrderData:purchaseOrder}});

  }


  useEffect(() => {
    handleSearch();
  }, [PurchaseOrders]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredPOItemwise(PurchaseOrders);
      return;
    }
   
    const filtered = PurchaseOrders.filter((PurchaseOrder) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'PO Number':
          return PurchaseOrder.purchaseorder_number?.toLowerCase().includes(lowercaseQuery);
        case 'Vendors':
          return PurchaseOrder.vendor_id?.name?.toLowerCase().includes(lowercaseQuery);
          case 'Item Name':
            return PurchaseOrder.items.some((item) => {
              const itemName = item.item_name?.toLowerCase() || '';
              return (
                itemName.includes(lowercaseQuery) || // Partial match
                itemName.startsWith(lowercaseQuery) || // Starts with
                lowercaseQuery.split(' ').every(word => itemName.includes(word)) // Multiple word match
              );
            });
            case 'SO Number':
              return PurchaseOrder.items.some((item) => {
                if (item.sales_orders_data && Array.isArray(item.sales_orders_data)) {
                  return item.sales_orders_data.some((salesOrder) => {
                    const soNumber = salesOrder.salesorder_number?.toLowerCase() || '';
                    return (
                      soNumber.includes(lowercaseQuery) || // Partial match
                      soNumber.startsWith(lowercaseQuery) // Starts with
                    );
                  });
                }
                return false;
              });
          
        default:
          return false;
      }
    });

    setFilteredPOItemwise(filtered);
  };

   useEffect(() => {
    fetchRoleId();
    fetchPurchaseOrders();
  }, []);

  const fetchRoleId = () => {
    const roleId = JSON.parse(sessionStorage.getItem('roleId'));
    setRoleId(roleId);
  };

  const purchaseOrderItemsToDisplay = PurchaseOrders && roleId === 6 
  ? PurchaseOrders.filter(purchaseOrderItem => (purchaseOrderItem?.product_category_id?.product_category_name?.toLowerCase()) === 'tiles')
  : PurchaseOrders || [];


  const fetchPurchaseOrders = async (attempt = 0) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/get-all-purchaseorders?organizationId=${sessionStorage.getItem('organizationId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });

      const data = await response.json();

if (response.ok) {
  setPurchaseOrders(data.data);

} else {
  console.error(data.message);
}


      if (response.status === 500) {
        setRetryFunction(() => () => fetchPurchaseOrders(attempt));
      }
    } catch (error) {
      console.error('Failed to fetch quotations:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => fetchPurchaseOrders(attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchPurchaseOrders(attempt));
      }
    } finally {
      setIsLoading(false);
    }
  }; 


  const handleDelete = async (attempt = 1) => {
    const selectedCategoryIds = selectedCategories.map((category) => category._id);

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "product-category/delete-product-categories", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        },
        body: JSON.stringify({ productCategoryIds: selectedCategoryIds })
      });
  
      const data = await response.json();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        // Update the vendors state to reflect the deleted vendors
        setProductCategories(productCategories.filter((category) => !selectedCategoryIds.includes(category._id)));
        setSelectedCategories([]);
        setSelectAll(false);
      } else {
        // Handle error cases
        setPopupMessage(data.message);
        setPopupType(`${data.status}`);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => handleDelete(attempt + 1), 1000); // Retry after 1 second
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleDelete(attempt)); // Set retry function
      }
    }
  };

  const closePopup = () => {

    setShowPopup(false);
  };
  
  const Retry = async() => {

    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {

    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleSalesOrder = (ItemData) => {

    setSalesOrderPopup(true);
    setSalesOrdersData(ItemData);
  }

  const handleClickQuantity = () => {
    setSOItemQty(true);
  }

  const handleClickReceivableQty = () => {
    setReceivableQty(true);
  }

  const handleClosePopup = () => {
    setSOItemQty(false);
    setSalesOrderPopup(false);
    setReceivableQty(false);
    setDeliveryQty(false);
  };

  function formatPurchaseOrderDate(dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // add 1 because getMonth() returns 0-11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function calculateAgingDays(dateStr) {
    const salesOrderDate = new Date(dateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - salesOrderDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
    return diffInDays;
  }
  
  const handlenavigateToSalesOrderDetails = (salesOrder) => {

    const salesorderData = {
      _id: salesOrder._id,
    }
    navigate("/bb/app/sales/salesorderdetails", {state:{SalesOrderData:salesorderData}});

  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
              <div className="flex w-full justify-between px-4 items-center">
  {selectedCategories.length > 0 ? (
    <>
 <div className="flex space-x-2">
        <button
          onClick={handleDelete}
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
        >
          Delete
        </button>
       {/*  <button
          onClick={handleCreatePurchaseOrder}  // Ensure this function is defined
          className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md"
          disabled={!createPOButtonEnabled}
        >
          Create Purchase Order
        </button> */}
      </div>
    </>
  ) : (
    <>
     <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Purchase Orders Items</h1>
     <Search onSearch={handleSearch} searchTypes={searchTypes} />
        </div>
      <button
        onClick={handleCreate}
        className="bg-[#F7525A] text-white px-2 py-1 rounded"
      >
        + New
      </button>
    </>
  )}
</div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                  {purchaseOrderItemsToDisplay && purchaseOrderItemsToDisplay.length > 0 ? (

                    <table className="w-full mb-5">
                      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
                        <tr className='border-b'>
                          <th className="text-xs py-3 text-center">
                          <input   type="checkbox"
        className="mr-2"
        onChange={handleSelectAll}
        checked={selectAll}
      />
    </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('PurchaseOrder.purchaseorder_date')}>
                            Date {getSortIcon('PurchaseOrder.purchaseorder_date') && <FontAwesomeIcon icon={getSortIcon('PurchaseOrder.purchaseorder_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('PurchaseOrder.purchaseorder_date')}>
                            Aging {getSortIcon('PurchaseOrder.purchaseorder_date') && <FontAwesomeIcon icon={getSortIcon('PurchaseOrder.purchaseorder_date')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('purchaseorder_number')}>
                            Purchase Order {getSortIcon('purchaseorder_number') && <FontAwesomeIcon icon={getSortIcon('purchaseorder_number')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('PurchaseOrder.vendor_id.city')}>
                            Location {getSortIcon('PurchaseOrder.vendor_id.city') && <FontAwesomeIcon icon={getSortIcon('PurchaseOrder.vendor_id.city')} />}
                          </th>
                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('item.item_name')}>
                            Item Name {getSortIcon('item.item_name') && <FontAwesomeIcon icon={getSortIcon('item.item_name')} />}
                          </th>

                          <th className="text-xs py-3 text-center cursor-pointer" onClick={() => sortArray('PurchaseOrder.vendor_id.name')}>
                            Vendor {getSortIcon('PurchaseOrder.vendor_id.name') && <FontAwesomeIcon icon={getSortIcon('PurchaseOrder.vendor_id.name')} />}
                          </th>
                          <th className="text-xs py-3 text-center" >
                            Sales Order
                          </th>
                          <th className="text-xs py-3 text-center " >
                           Ordered Qty 
                          </th>
                          <th className="text-xs py-3 text-center " >
                          Receivable Qty 
                          </th>
                          <th className="text-xs py-3 text-center " >
                          Shortfall Qty 
                          </th>
                          <th className="text-xs py-3 text-center " >
                          Excess Qty 
                          </th>
                          <th className="text-xs py-3 text-center " >
                          Allocated Qty 
                          </th>
                          <th className="text-xs py-3 text-center " >
                          Unallocated Qty 
                          </th>
                        </tr>

                      </thead>
                      <tbody className="border-b border-t">
  {filteredPOItemwise && filteredPOItemwise.length > 0 ? (
    filteredPOItemwise.flatMap((PurchaseOrder ) =>
      PurchaseOrder.items.map((item, index) => {
        const uniqueKey = `${PurchaseOrder._id}-${index}-${item.item_name}-${JSON.stringify(item)}`;
        return (
        <tr key={uniqueKey} className="text-sm border-b">
          <td className="py-2 text-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={selectedCategories.includes(PurchaseOrder)}
              onChange={() => handleCheckboxChange(PurchaseOrder)}
            />
          </td>
          <td className="py-2 text-center">
            {formatPurchaseOrderDate(PurchaseOrder.purchaseorder_date)}
          </td>
          <td className="py-2 text-center">
            {calculateAgingDays(PurchaseOrder.purchaseorder_date)} days
          </td>
          <td className="py-2 text-center text-[#408dfb] font-500 cursor-pointer" onClick={() => handlenavigateToPurchaseOrderDetails(PurchaseOrder)}>
            {PurchaseOrder.purchaseorder_number}
          </td>
          <td className="py-2 text-center">
            {PurchaseOrder.vendor_id.city}
          </td>
          <td className="py-2 text-center">
            {item.item_name}
          </td>
          <td className="py-2 text-center">
            {PurchaseOrder.vendor_id.name}
          </td>
          <td className="py-2 text-center font-500 cursor-pointer text-[#408dfb]" onClick={() => handleSalesOrder(item)}>
            View
          </td>
          <td className="py-2 text-center">
            {item.quantity.toFixed(2)} {item.UOM}
          </td>
          <td className="py-2 text-center">
            {item.receivable_quantity.toFixed(2)} {item.UOM}
          </td>
          <td className='py-2 text-center'>
            {Math.max(item.quantity - item.receivable_quantity, 0).toFixed(2)} {item.UOM}
          </td>
          <td className='py-2 text-center'>
            {Math.max(item.receivable_quantity - item.quantity, 0).toFixed(2)} {item.UOM}
          </td>
          <td className='text-center'>
            {item.allocated_quantity.toFixed(2)} {item.UOM}
          </td>
          <td className='text-center'>
            {item.unallocated_quantity.toFixed(2)} {item.UOM}
          </td>
        </tr>
      );
})
    )
  ) : (
    <tr>
      <td colSpan="14" className="text-center text-[#6c7184] py-4">
        Not found
      </td>
    </tr>
  )}
</tbody>

                    </table>
                    
                  ) : (
                    <>
                                          <div className="m-4 p-4 text-center border border-grey-500 text-gray-500">
                        No PO Items Found.
                      </div>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create Purchase Order</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>

                {SalesOrderPopup && SalesOrdersData &&  (
  <div className="fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Sales Order Details</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="px-6 py-2">
        <p className="text-md font-semibold">{selectedPurchaseOrder?._id?.item_name}</p>
      </div>
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
            
            <th className="border text-sm px-2 py-1 text-left">Date</th>
              <th className="border text-sm px-2 py-1 text-left">Aging</th>
              <th className="border text-sm px-2 py-1 text-left">SO Number</th>
{/*               <th className="border text-sm px-2 py-1 text-left">Quantity</th>
              <th className="border text-sm px-2 py-1 text-left">SO Item Status</th> */}
            </tr>
          </thead>
           <tbody>
            {SalesOrdersData.sales_orders_data.map((salesOrder, index) => (
              <tr key={index}>
             
              
               <td className="border text-sm px-2 py-1 text-left">{formatPurchaseOrderDate(salesOrder.salesorder_date)}</td>
                <td className="border text-sm px-2 py-1 text-left">{calculateAgingDays(salesOrder.salesorder_date)}</td>
                <td className="border text-sm px-2  text-center text-[#408dfb] font-500 cursor-pointer text-left"   onClick={()=> handlenavigateToSalesOrderDetails(salesOrder)}  >{salesOrder.salesorder_number}</td>
{/*                 <td className="border text-sm px-2 py-1 text-left">{selectedPurchaseOrder.quantity}</td>
                <td className="border text-sm px-2 py-1 text-left">{selectedPurchaseOrder.item_SO_status}</td> */}
              </tr>
            ))}
          </tbody> 
        </table>
      </div>
    </div>
  </div>
)}
 

                 {SOItemQty && selectedPurchaseOrder && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">SO Item Quantity</h2>
        <button onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className="px-6 py-2">
        <p className="text-md font-semibold">{selectedPurchaseOrder.item_name}</p>
      </div>
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design Name</th>
              <th className="border px-4 py-2 text-left">{selectedPurchaseOrder.UOM}</th>
            </tr>
          </thead>
          <tbody>
       
              {selectedPurchaseOrder.required_order_breakup && selectedPurchaseOrder.required_order_breakup.map((design, index) => (
                <tr key={`${selectedPurchaseOrder.id}-${design.sub_item_id}-${index}`}>
                  <td className="border px-4 py-2">{design.sub_item_name || "N/A"}</td>
                  <td className="border px-4 py-2">{design.sub_item_quantity}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}



{ReceivableQty && purchaseOrderItemsToDisplay && purchaseOrderItemsToDisplay.length > 0 && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Receivable Quantity</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          {purchaseOrderItemsToDisplay.map((PurchaseOrder) => (
              PurchaseOrder.selected_designs && PurchaseOrder.selected_designs.map((design, index) => (
                <tr key={`${PurchaseOrder.id}-${design.subitem_id}-${index}`}>
                  <td className="border px-4 py-2">Design {index + 1}</td>
                  <td className="border px-4 py-2">{design.sub_item_receivable_quantity}</td>
                </tr>
              ))
            ))}
        </table>
      </div>
    </div>
  </div>
)}

{DeliveryQty && purchaseOrderItemsToDisplay && purchaseOrderItemsToDisplay.length > 0 && (
  <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col"
      style={{ maxWidth: '35vw', maxHeight: '60vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Delivery Quantity</h2>
        <button  onClick={handleClosePopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer" />
        </button>
      </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      
      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2 text-left">Design</th>
              <th className="border px-4 py-2 text-left">Boxes</th>
            </tr>
          </thead>
          {purchaseOrderItemsToDisplay.map((PurchaseOrder) => (
              PurchaseOrder.selected_designs && PurchaseOrder.selected_designs.map((design, index) => (
                <tr key={`${PurchaseOrder.id}-${design.subitem_id}-${index}`}>
                  <td className="border px-4 py-2">Design {index + 1}</td>
                  <td className="border px-4 py-2">{design.sub_item_pending_quantity}</td>
                </tr>
              ))
            ))}
        </table>
      </div>
    </div>
  </div>
)} 

              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div>
          {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
        </div>
      )}
    </div>
  );
};

export default PurchaseOrdersItemWIse;
