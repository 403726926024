import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSortUp, faSortDown,    } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {Loader} from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import Search from '../Components/Search';

const TemplateList = () => {
    const  [customers , setCustomers] = useState ([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const [retryFunction, setRetryFunction] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState([]); // Add this state
  const [selectAll, setSelectAll] = useState(false); // Add this state
  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const fetchUrl2 = process.env.REACT_APP_FORE_APILINK;
  const [packages, setPackages] = useState([]);
  const [alltemplates, setAllTemplates] = useState([]);


  const getStatusColor = (status) => {
    switch (status) {
      case 'DRAFT':
        return 'text-gray-500';
      case 'INVOICED':
        return 'text-green-500';
      case 'SENT':
        return 'text-blue-500';
      default:
        return '';
    }
  };

  const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
  
  console.log(filteredCustomers);
const searchTypes = ['Item Name', 'Package Name'];

  const sortArray = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedArray = [...customers].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setCustomers(sortedArray);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return null;
  };

  useEffect(() => {
    handleSearch();
  }, [customers]);

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredCustomers(customers);
      return;
    }
  
    const filtered = customers.filter((customer) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Item Name':
          return customer.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Package Name':
          return customer.company_name?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });

    setFilteredCustomers(filtered);
  };
  
 
  useEffect(() => {
    fetchProductCategories();
    fetchPackages();
    fetchalltemplates();
    // fetchTemplatePackagedata();
  },[])

  const fetchProductCategories = async (attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(fetchUrl + "product-category/get-all-productCategories", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
      
      console.log(data.data)
      if (data.data) {
        setProductCategories(data.data); // Update productCategories state
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);

      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1); // Increment retry count
        setTimeout(() => fetchProductCategories(attempt + 1), 1000); // Retry after 1 second
      } else {
       
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => fetchProductCategories(attempt)); // Set retry function
      }
    }/* finally {
      setIsLoading(false);
    } */
  };

// if (isLoading) {
//   return <Loader />;
// }


const fetchPackages = async () => {


  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${fetchUrl2}/summary/get-packages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    console.log(data.data);
    if (data.success) {
      setPackages(data.data);
    }
  } catch (error) {
    console.error('Failed to fetch packages:', error);
  }
};

const fetchalltemplates = async () => {


    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl2}/summary/get-all-templates-db`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
  
      const data = await response.json();
      console.log(data.data);
      if (data.success) {
        setAllTemplates(data.data);
      }
    } catch (error) {
      console.error('Failed to fetch packages:', error);
    }
  };

  const handleTemplateAction = async (categoryId, packageId, attempt = 1) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl2}/summary/template-package-data-db?product_category_id=${categoryId}&package_id=${packageId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
  
      const data = await response.json();
      console.log("Template package data:", data.data);
  
      if (data.data) {
        navigate("/bb/app/templates/editTemplate", {
          state: {
            templateData: {
            ...data.data,
            category_id: categoryId,
            product_category_name: data.data.product_category_name,
            package_id: packageId,
            package_name: data.data.package_name
          }
        }
        });
        console.log('categoryId:', categoryId); 
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
  
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => handleTemplateAction(categoryId, packageId, attempt + 1), 1000);
      } else {
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => handleTemplateAction(categoryId, packageId, attempt));
      }
    }
  }; 

const handleCheckboxChange = (customer) => {
  if (selectedCustomers.includes(customer)) {
    setSelectedCustomers(selectedCustomers.filter((c) => c !== customer));
    setSelectAll(false); // Update selectAll state when deselecting a checkbox
  } else {
    setSelectedCustomers([...selectedCustomers, customer]);
    if (selectedCustomers.length === customers.length - 1) {
      setSelectAll(true); // Update selectAll state when selecting all checkboxes
    }
  }
};

const handleSelectAll = () => {
  if (selectAll) {
    setSelectedCustomers([]);
    setSelectAll(false);
  } else {
    setSelectedCustomers(customers);
    setSelectAll(true);
  }
};

const handleDelete = async (attempt = 1) => {
  const selectedCustomerIds = selectedCustomers.map((customer) => customer._id);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `customer/delete-customers?customer_ids=${selectedCustomerIds}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      }
    });

    const data = await response.json();
    if (data.status === 200 || data.status === 201 || data.status === 204) {
      // Update the customers state
      setCustomers(customers.filter((customer) => !selectedCustomerIds.includes(customer._id)));
      setSelectedCustomers([]);
      setSelectAll(false);
      
      // Show success popup
      setPopupMessage(data.message);
      setPopupType('200or201');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        navigate("/bb/app/sales/customerslist")
      }, 3000); 
      // Fetch updated customer list
      //fetchCustomers();
    } else {
      console.log(data.message)
      setPopupMessage(data.message);
      setPopupType(`${data.status}`);
      setShowPopup(true);
    }
  } catch (error) {
    if (attempt < RETRY_LIMIT) {
      setRetryCount(attempt + 1);
      setTimeout(() => handleDelete(attempt + 1), 1000);
    } else {
      setIsLoading(false);
      setPopupMessage('Internal Server Error. Kindly Retry.');
      setPopupType('500');
      setShowPopup(true);
      setRetryFunction(() => () => handleDelete(attempt));
    }
  }
};


const handleCreate = () => {
  navigate("/bb/app/templates/template");
};
 const handleNavigateCustomerDetails = (customer) =>{
  navigate("/bb/app/sales/customerdetails", {state:{CustomerData:customer}})
 }

 const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
};

const Retry = async () => {
  console.log("Retry called");
  setIsLoading(true); // Show loader
  setTimeout(async () => {
    if (retryFunction) {
      await retryFunction();
    }
    setIsLoading(false); // Hide loader after retry
  }, 5000); // 5-second delay
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};



  return (
    <div className=" bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
        <div className="flex w-full flex-row">
          <div className="w-full flex-1">
              <div className="flex flex-col w-full h-full justify-center items-center">
              <div className="w-full h-full flex flex-col justify-between ">
                   <div className="w-full h-16 border-b flex items-center">
                   <div className="flex w-full justify-between px-7 items-center">
  {selectedCustomers.length > 0 ? (
    <button onClick={handleDelete}
      className="bg-gray-100 px-2 py-1 shadow-sm text-sm border rounded-md ">Delete</button>
  ) : (
    <>
    <div className='space-x-10 flex flex-row'>
      <h1 className="text-lg font-bold">All Templates</h1>
      <Search onSearch={handleSearch} searchTypes={searchTypes} />
      </div>
      <button onClick={handleCreate}
        className="bg-[#E54643] text-white z-20 px-2 py-1 rounded"
        title="" data-tooltip="Create Customer"  style={{ '--tooltip-bg': 'black' }}>+ New</button>
    </>
  )}
</div>
</div>
                  <div className="w-full h-full bg-white rounded-xs overflow-hidden ">
                  <div className="overflow-y-auto h-[80vh]">
  {alltemplates.length > 0 ? (
    <table className="w-full mb-5">
      <thead className="sticky top-0 bg-[#fafafc] text-[#6c7184] border-b">
        <tr className='border-b'>
          <th className="text-xs py-3 text-center">Category Name</th>
          <th className="text-xs py-3 text-center">Package Name</th>
          <th className="text-xs py-3 text-center">Last Updated</th>
          <th className="text-xs py-3 text-center">Actions</th>
        </tr>
      </thead>
      <tbody className='border-b border-t'>
        {alltemplates.map((template) => (
          <tr key={`${template.category_id}-${template.package_id}`} className="text-sm border-b">
            <td className="py-2 text-center">{template.product_category_name}</td>
            <td className="py-2 text-center">{template.package_name}</td>
            <td className="py-2 text-center">{template.last_updated}</td>
            <td className="py-2 text-center">
            <button
  className="text-[#EA011F] px-2 py-1 rounded"
  onClick={() => handleTemplateAction(template.category_id, template.package_id)}
>
  Edit
</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <>
      <div className="flex justify-center items-center mb-4">
        <h1 className="text-xl font-bold">Create New Template</h1>
      </div>
      <div className="flex justify-center items-center">
        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>
          Create
        </button>
      </div>
    </>
  )}
</div>


    </div>
              </div>
            </div>
          </div>
      {/*   </div> */}
      </div>
      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
      </div>
    
  );
};

export default TemplateList;