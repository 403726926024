import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPlus, faPen, faPaperPlane, faShareAlt, faEnvelope , faSms, faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import  '../Css/Tooltip.css';
import {Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { PDFDownloadLink } from '@react-pdf/renderer';
/* import QuotePdfDocument from './QuotePdfDocument'; // Import the PDF document component
/* import { generatePDF } from './GeneratePdf'; */
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import PurchaseOrderPdfViewer from './PurchaseOrderPdfViewer';



const PurchaseOrderDetails = () => {
  const navigate = useNavigate ();
   const location= useLocation();
  const { PurchaseOrderData } = location.state || {}; 
  console.log(PurchaseOrderData);
 const [PurchaseOrders , setPurchaseOrders] = useState ([]);
 const [SelectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
 const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  //const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSendOptions, setShowSendOptions] = useState(false);
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
 // const [popupType, setPopupType] = useState('');
 
 // const selectedQuotation = quotations(); // Assuming you want to generate a PDF for the first quote
  
  const [showConvertPopup, setShowConvertPopup] = useState(false);
  const [PurchaseorderId, setPurchaseorderId] = useState(null);
  console.log(PurchaseorderId);
  const [Items , setItems] = useState();
  const { 
    showPopup, popupMessage, popupType, retryFunction,
    isSaving ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
    setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
    const [roleId, setRoleId] = useState(null);
    const sendOptionsRef = useRef(null);

    const useOutsideClick = (ref, callback) => {
      const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          callback();
        }
      };
      React.useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }, []);
    };
    
    // Add this hook call
    useOutsideClick(sendOptionsRef, () => setShowSendOptions(false));
  
    useEffect(() => {
      fetchRoleId();
        fetchPurchaseOrder(PurchaseOrderData);
        //fetchPurchaseOrdersbyId(PurchaseOrderData?._id || '');
      }, [PurchaseOrderData]);

      const fetchRoleId = () => {
        const roleId = JSON.parse(sessionStorage.getItem('roleId'));
        setRoleId(roleId);
      };
    
      const PurchaseOrdersToDisplay = PurchaseOrders && roleId === 6 
      ? PurchaseOrders.filter(PurchaseOrder => (PurchaseOrder.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
      : PurchaseOrders ||[];
      console.log(PurchaseOrdersToDisplay);
    
      const fetchPurchaseOrder = async (selectedPurchaseOrder ,attempt = 0) => {
        console.log(selectedPurchaseOrder)
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/get-all-purchaseorders?organizationId=${sessionStorage.getItem('organizationId')}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
    
          const data = await response.json();
          console.log(data)
    if (response.ok) {
      setPurchaseOrders(data.data);
      setSelectedPurchaseOrder(selectedPurchaseOrder);
        handlePurchaseOrderClick(selectedPurchaseOrder);
      //console.log(data.data);
    } else {
      console.error(data.message);
    }
    
    
          if (response.status === 500) {
            setRetryFunction(() => () => fetchPurchaseOrder(attempt));
          }
        } catch (error) {
          console.error('Failed to fetch quotations:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => fetchPurchaseOrder(attempt + 1), 1000);
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchPurchaseOrder(attempt));
          }
        } finally {
          setIsLoading(false);
        }
      }; 

      const fetchPurchaseOrdersbyId = async (id ,attempt = 0) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/get-purchaseorder?purchaseorder_id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
    
          const data = await response.json();
          console.log(data);
          const output =  await handleGetResponse(response , data)
          if(output){
            console.log(output)
           /*  if (output.items && Array.isArray(output.items)) {
              output.items = output.items.map(item => {
                if (item.total === 0) {
                  item.total = item.quantity * item.unit_price;
                }
                return item;
              });
            } */
            setSelectedPurchaseOrder(output);
           
          }else{
          
          }
          }  catch (error) {
            console.error('Failed to fetch:', error);
      
            if (attempt < RETRY_LIMIT) {
              setRetryCount(attempt + 1); // Increment retry count
              setTimeout(() => fetchPurchaseOrdersbyId(id,attempt + 1), 1000); // Retry after 1 second
            } else {
              setIsLoading(false);
              setPopupMessage('Internal Server Error. Kindly Retry.');
              setPopupType('500');
              setShowPopup(true);
              setRetryFunction(() => () => fetchPurchaseOrdersbyId(id,attempt)); // Set retry function
            }
          }finally{
            //setProductGroupLoader(false)
          }
        };
    
  

  const  handlePurchaseOrderClick = (PurchaseOrder) => {
    
    setPurchaseorderId(PurchaseOrder?._id);
    setPurchaseorderId(PurchaseOrder._id);
    setSelectedPurchaseOrder(PurchaseOrder);
    fetchPurchaseOrdersbyId(PurchaseOrder?._id);
   
   console.log(PurchaseOrder);
  }

  const handleClose = () => {
    navigate('/bb/app/purchases/purchaseorderlist');
  }

  if (isLoading) {
    return <Loader />;
  }

 const handleCreatePurchaseOrder = () => {
  navigate("/bb/app/purchases/newpurchaseorder");
 }

/*  const handleCreatePurchaseOrder = () => {
  navigate('/bb/app/purchases/newpurchaseorder');
 } */

 const handleEdit = () => {
  navigate('/bb/app/purchases/editpurchaseorder', {state:{PurchaseOrderID: PurchaseorderId}});
  console.log(PurchaseorderId);
 }

 const handleSendClick = () => {
  setShowSendOptions(!showSendOptions);
};

/* const sendPurchaseOrderToUser = async () => {
  setShowSendOptions(false); 
  //console.log(selectedQuote);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/send-purchaseorder-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify({
        purchaseOrderId: SelectedPurchaseOrder._id,
        vendorId: SelectedPurchaseOrder.vendor_id._id
      })
    });

    const data = await response.json();

    if (response.ok) {
      setPopupMessage('Purchase Order sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    } else {
      throw new Error(data.message || 'Failed to send Purchaseorder');
    }
  } catch (error) {
    console.error('Error sending Purchase order:', error);
    setPopupMessage('Failed to send Purchase order');
    setPopupType('400');
  }
}; */
  
const sendPurchaseOrderToUser = async () => {
  setIsSaving(true);
  setShowSendOptions(false);
  try {
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/purchaseorder/send-purchaseorder-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': sessionStorage.getItem('token'),
      },
      body: JSON.stringify({
        vendorId: SelectedPurchaseOrder.vendor_id._id,
        documentType: 'Purchase Order',
        documentData: {
          documentNumber: SelectedPurchaseOrder.purchaseorder_number,
          date: SelectedPurchaseOrder.purchaseorder_date,
          status: SelectedPurchaseOrder.purchaseorder_status,
          items: SelectedPurchaseOrder.items.map(item => ({
            item_name: item.item_name,
            quantity: item.quantity,
            UOM: item.UOM,
            price: item.cost_price,
            tax_rate: item.tax_rate,
            total: item.total
          })),
          additionalInfo: {
            'Sub Total': SelectedPurchaseOrder.subtotal,
            'Tax': SelectedPurchaseOrder.tax_amount,
            'Total': SelectedPurchaseOrder.total
          }
        }
      }),
    });

    const data = await response.json();

    if (data.success) {
      setPopupMessage('Purchase Order sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      }, 2000);
    } else {
      //throw new Error(data.message || 'Failed to send Purchase Order');
      if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      }else if (response.status === 404) {
        setPopupType('404');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      }
    }
  } catch (error) {
    console.error('Error sending purchase order:', error);
    setPopupMessage('Failed to send purchase order');
    setPopupType('400');
    setShowPopup(true);
  }finally {
    setIsSaving(false);
  }
};


const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
  setPopupMessage('');
  setPopupType('');

};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb/admin');
};

const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};

return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
{/* List of Vendors Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Purchase Orders
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button onClick={handleCreatePurchaseOrder}
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"  data-tooltip="Create Purchase Orders" title=""  style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className="flex justify-center overflow-y-hidden flex-col">
  <div className="flex flex-col h-[80vh] overflow-y-auto">
    {PurchaseOrdersToDisplay.map((PurchaseOrder, index) => (
      <div
        key={PurchaseOrder._id}
        className={`border-b text-sm flex items-center px-4 py-2 ${PurchaseorderId === PurchaseOrder._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
      onClick={() => handlePurchaseOrderClick(PurchaseOrder)}
      >
        <div className="flex flex-row">
          <div>
            <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
          </div>
          <div className="flex flex-col">
            <p className="text-md font-semibold">{PurchaseOrder.purchaseorder_number}</p>
            <p className="text-gray-600 text-sm flex items-center">
  {PurchaseOrder?.status} 
  <span className="mx-1 text-gray-600">&middot;</span>
   {new Date(PurchaseOrder.purchaseorder_date).toLocaleDateString()} 
</p>
            <p className="text-gray-600 text-sm"></p>
          </div>
        </div>
      </div>
    ))} 
  </div>
</div>
</div>
{/* List of Vendors Div end */}

<div className="border-l  w-full flex flex-col ">

<div className="border-l w-full flex flex-col">
        <div className="border-b h-30">
          <div className="p-4 flex justify-between items-center">
             <span className="text-md font-semibold">{SelectedPurchaseOrder?.purchaseorder_number}</span> 
             <button className="text-white px-1 py-1 rounded-md">
              <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-[#f7525a] text-sm mx-2 cursor-pointer" />
            </button>

          </div>
          <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
            <button className="border-r px-4 py-2 text-sm mr-2" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
              Edit
            </button>
            <div ref={sendOptionsRef} className="relative">
            <button 
  onClick={handleSendClick} 
  className={`px-4 py-2 border-r text-sm mr-2 ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
  disabled={isSaving}
>
  <div className="flex items-center justify-center space-x-2">
    <FontAwesomeIcon icon={faPaperPlane} className="mr-1 w-3 h-3" />
    <span>Send</span> {isSaving && <Spinner />}
  </div>
</button>
  {showSendOptions && (
    <div className="p-0.5 absolute left-0 top-full mt-1 bg-white border rounded shadow-lg z-10">
      <button onClick={sendPurchaseOrderToUser} className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Email
      </button>
      <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faSms} className="mr-2" />
        SMS
      </button>
    </div>
  )}
</div>

           {/*  <button className="text-sm border-r px-4 py-2 mr-2">
              <FontAwesomeIcon icon={faShareAlt} className="mr-2 w-3 h-3" />
              Share
            </button> */}
            {/* <PDFDownloadLink
            
            document={<QuotePdfDocument quote={selectedQuote} />}
            fileName={`quotation_${selectedQuote?.quotation_number}.pdf`}
            
            className="text-sm px-4 border-r py-2 mr-2">
              <FontAwesomeIcon icon={faFilePdf} className="mr-2 w-3 h-3" />
              PDF/Print
            {({ loading }) =>
              loading ? 'Generating PDF...' : 'Download PDF'
            }
          </PDFDownloadLink> */}
          {/* <button onClick={() => generatePDF(selectedQuote)}>Generate PDF</button> */}
        {/*     <button
  className="text-sm border-r px-4 py-2 mr-2"
 onClick={() => setShowConvertPopup(!showConvertPopup)}
>
 <FontAwesomeIcon icon={faExchangeAlt} className="mr-2 w-3 h-3" /> 
  Mark as Issued
   <FontAwesomeIcon icon={faChevronDown} className="ml-2 w-3 h-3" /> 
</button> */}
{/* {showConvertPopup && (
  <div
    className="absolute top-[9.5rem] left-[51.5rem] w-42 bg-white shadow-md p-2"
    style={{ zIndex: 1 }}
  >
    <button  onClick={handleCreatePurchaseOrder}  className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1">Convert to Purchase Order</button>
    </div>
)} */}
            <button className="text-sm border-r px-4 py-2 mr-2">
              <FontAwesomeIcon icon={faEllipsisV} className="mr-2 w-3 h-3" />
              
            </button>
            {/* <button className="text-sm px-6 py-2 mr-2">
              
              Download PDF
            </button> */}
           
          </div>
        </div>
        <div className="w-full h-full bg-white rounded-xs overflow-hidden">
  <div className="flex flex-col h-[80vh] overflow-hidden">
    <div className="overflow-y-auto flex-1 p-6">
      {/* "What's Next?" section */}
      {SelectedPurchaseOrder && (
        <>
          {/* <div className="mt-4 mx-10 px-2 border p-2 flex items-center  border-l-4">
            <div className="flex-1">
              <p className="text-sm font-semibold"> What's Next?</p>
              <p className="mt-1 mb-2 text-sm text-gray-700">
              Send this purchase order to your vendor or mark it as issued
              </p>
            </div>
            <div className="flex-shrink-0 flex space-x-2">
              <button className="px-2 py-1 text-xs bg-[#f7525a] border-[#f7525a] text-white rounded-md">
                Send Purchase Order
              </button>
              <button className="bg-gray-100 px-2 py-1 shadow-sm text-xs border rounded-md">
                Mark as Issued
              </button>
            </div>
          </div> */}
          <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{SelectedPurchaseOrder?.status}</h1>
          <div className="mt-4">
            {  <PurchaseOrderPdfViewer purchaseorder={SelectedPurchaseOrder} />  }
          </div>
        </>
      )} 
    </div>
  </div>
</div>
      </div>
    </div>
    {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}
   </div>
  );
};
export default PurchaseOrderDetails;