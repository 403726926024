import { faChevronLeft, faPhone, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../BBAdmin/CommenResponseAndErrorHandlers/CommenResponseHandler';

const QuotationSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectionSummary, totalAmount, quotationId  } = location.state;
  console.log(quotationId)
  console.log(selectionSummary);
  const [isLoading, setIsLoading] = useState(true);
  const [uomData, setUomData] = useState({});

  const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();


  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const fetchUOMs = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}UOMs/get-all-UOMs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      const data = await response.json();
    
       console.log(data.data);
        setUomData(data.data);
  
    } catch (error) {
      console.error('Failed to fetch UOMs:', error);
    }
  };
  
  useEffect(() => {
    fetchUOMs();
  }, []);

  const getUomName = (uomId) => {
    return uomData.find(u => u._id === uomId)?.UOM || '';
  };


  const handleDeleteQuotation = async (quotationId) => {
    console.log('Deleting quotation with ID:', quotationId);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${fetchUrl}customer/quotations/delete-quotation?quotation_id=${quotationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
  
      const data = await response.json();
      console.log(data);
      
      if (data.success) {
        // Show success message
        setPopupMessage('Quotation deleted successfully');
        setPopupType('success');
        setShowPopup(true);
        
        // Refresh quotations list or navigate back
        navigate(-1);
      } else {
        setPopupMessage(data.message || 'Failed to delete quotation');
        setPopupType('error');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error deleting quotation:', error);
      setPopupMessage('Failed to delete quotation');
      setPopupType('error');
      setShowPopup(true);
    }
  };



  const SkeletonLoader = () => (
    <div className="animate-pulse">
      {[1, 2, 3].map((item) => (
        <div key={item} className="mb-4 p-4 bg-gray-100 rounded-lg">
          <div className="h-5 bg-gray-200 rounded w-3/4 mb-4"></div>
          <div className="grid grid-cols-2 gap-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-6 bg-gray-200 rounded col-span-2 mt-2"></div>
          </div>
        </div>
      ))}
      
      {/* Total Amount Section Skeleton */}
      <div className="p-4 border-t bg-gray-50">
        <div className="flex justify-between items-center mb-4">
          <div className="h-7 bg-gray-200 rounded w-1/4"></div>
          <div className="h-8 bg-gray-200 rounded w-1/3"></div>
        </div>
        <div className="flex justify-between items-center">
          <div className="h-6 bg-gray-200 rounded w-16"></div>
          <div className="flex gap-4">
            <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
            <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
  

  return (
    <div className="bg-[#F5F7FA] flex flex-col w-full h-full overflow-y-auto  pt-16 pb-20">
      <div className="bg-white rounded-xl shadow-lg max-w-4xl mx-auto">
        <div className="p-2 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Quotation Summary</h2>
        </div>

        <div className="">
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            // Your existing summary content
            <div className="">
  {/* Header */}
  <div className="grid grid-cols-5 gap-6 px-2 py-1 bg-gray-100 rounded-t-lg font-semibold text-gray-700">
    <div className='text-center'>Type</div>
    <div className="text-center">No</div>
    <div className="text-center">Area <span className="text-xs">(Sq.Ft)</span></div>
    <div className="text-center">Price <span className="text-xs">(Sq.Ft)</span></div>
    <div className="text-center">Total</div>
  </div>

  {/* Window Items */}
  {Object.entries(selectionSummary.reduce((acc, selection) => {
    const key = selection.product.product_name;
    if (!acc[key]) {
      acc[key] = {
        totalArea: 0,
        totalPrice: 0,
        count: 0,
        uom_id: selection.product.UOM_id,
        selling_price: selection.product.selling_price
      };
    }
    acc[key].totalArea += selection.areaInSFT;
    acc[key].totalPrice += selection.areaInSFT * selection.product.selling_price;
    acc[key].count += 1;
    return acc;
  }, {})).map(([productName, data], index) => (
    <div key={productName} className="border-b last:border-b-0">
      <div className={`grid grid-cols-5 gap-2 p-2 text-sm items-center ${index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}`}>
        <div className="font-medium text-gray-800 text-center">{productName}</div>
        <div className="text-center">{data.count}</div>
        <div className="text-center">{data.totalArea.toFixed(2)}</div>
        <div className="text-center">₹{data.selling_price}</div>
        <div className="font-bold text-blue-600  text-center">
          ₹{Math.round(data.totalPrice).toLocaleString('en-IN')}
        </div>
      </div>
    </div>
  ))}

  {/* Total Section */}
  <div className="mt-6 p-4 border-t bg-gray-50 rounded-lg">
    <div className="flex justify-between items-center mb-4">
      <span className="text-xl font-bold">Total Amount:</span>
      <span className="text-2xl font-bold text-blue-600">
        ₹{totalAmount.toLocaleString('en-IN')}
      </span>
    </div>

    <div className="flex gap-4 justify-between items-center">
      <button
        onClick={() => handleDeleteQuotation(quotationId)}
        className="px-2 py-1 font-semibold text-lg underline text-blue-600"
      >
        <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" /> Back
      </button>

      <div className="flex gap-4">
        <button className="p-2 rounded-full bg-blue-50 hover:bg-blue-100 text-blue-600">
          <FontAwesomeIcon icon={faShare} className="w-5 h-5" />
        </button>
        <button className="p-2 rounded-full bg-green-50 hover:bg-green-100 text-green-600">
          <FontAwesomeIcon icon={faPhone} className="w-5 h-5" />
        </button>
      </div>
    </div>
  </div>
</div>
          )}
          </div>
          </div>
          </div>
  );
};

export default QuotationSummary;
