import React from 'react';

const formatNumber = (value) => {
  const parsedValue = parseFloat(value);
  if (Number.isFinite(parsedValue)) {
    const roundedValue = (Math.round(parsedValue * 100) / 100);
    return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
  }
  return '0.00';
};

const PaymentHtmlDocument = ({ payment }) => (
  <div className="p-8 flex flex-col"
    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}  
  >
    <div className="flex flex-col mb-8">
      <h1 className="text-3xl font-bold text-black mb-4">BRICKBUCKET</h1>
      <div className="text-sm text-gray-600 space-y-1">
        <p>Telangana</p>
        <p>India</p>
        <p>GSTIN 36AAACH7409R1Z2</p>
        <p>sai.naguboina@premitus.com</p>
      </div>
    </div>

    <div className="border-t border-gray-300 my-6"></div>

    <div className="text-center mb-12">
      <h1 className="text-lg  text-black tracking-wide">PAYMENT RECEIPT</h1>
    </div>

    <div className="space-y-4 mb-8">
  <div className="flex justify-between items-center">
    <div className="space-y-6">
      <div className="flex items-center">
        <p className="text-sm text-gray-500 w-40">Payment Date</p>
        <p className="text-sm font-bold text-black border-b border-gray-200 pb-1 w-60">{new Date(payment?.payment_date).toLocaleDateString()}</p>
      </div>
      <div className="flex items-center">
        <p className="text-sm text-gray-500 w-40">Reference Number</p>
        <p className="text-sm font-bold text-black border-b border-gray-200 pb-1 w-60">{payment?.reference_number}</p>
      </div>
      <div className="flex items-center">
        <p className="text-sm text-gray-500 w-40">Payment Mode</p>
        <p className="text-sm font-bold text-black border-b border-gray-200 pb-1 w-60">{payment?.payment_mode}</p>
      </div>
    </div>



<div className="bg-emerald-600 text-white p-4 rounded-lg w-40 h-36 flex flex-col justify-center items-center">
  <p className="text-sm font-semibold mb-2">Amount Received</p>
  <p className="text-xl font-bold">₹{formatNumber(payment?.amount)}</p>
</div>
    
  </div>
</div>

<div className="mt-8">
  <p className="text-sm font-semibold text-black">Amount Received In Words: <span className="font-normal">Indian Rupee {payment?.amount_in_words} Only</span></p>
</div>


    <div className="mt-20">
      <div className="flex items-center">
        <h3 className="text-sm font-semibold mr-4">Authorized Signature</h3>
        <div className="border-t border-black" style={{ width: '150px', height: '1px' }}></div>
      </div>
    </div>
  </div>
);

const PaymentPdfViewer = ({ payment }) => (
  <div className="h-screen">
    <PaymentHtmlDocument payment={payment} />
  </div>
);

export default PaymentPdfViewer;
