import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPlus, faPen, faPaperPlane, faEnvelope , faSms, faFile} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import  '../Css/Tooltip.css';
import {Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages';
import { PDFDownloadLink } from '@react-pdf/renderer';
/* import QuotePdfDocument from './QuotePdfDocument'; // Import the PDF document component
import PdfViewer from './PdfViewer'; // Import PdfViewer component */
/* import { generatePDF } from './GeneratePdf'; */
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import SalesOrderPdfViewer from './SalesOrderPdfViewer';



const SalesOrderDetails = () => {
  const navigate = useNavigate ();
   const location= useLocation();
  const { SalesOrderData } = location.state || {}; 
  console.log(SalesOrderData);
  const salesOrderId = location.state?.salesOrderId;
  console.log(salesOrderId);
 const [salesOrders , setSalesOrders] = useState ([]);
 const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
 const [showSendOptions, setShowSendOptions] = useState(false);
 const RETRY_LIMIT = 3; // Maximum number of retries
  const [retryCount, setRetryCount] = useState(0); // Track retry attempts
  //const [retryFunction, setRetryFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //const [showPopup, setShowPopup] = useState(false);
  //const [popupMessage, setPopupMessage] = useState('');
 // const [popupType, setPopupType] = useState('');
 
 // const selectedQuotation = quotations(); // Assuming you want to generate a PDF for the first quote
  
  const [showConvertPopup, setShowConvertPopup] = useState(false);
  const [salesorderId, setSalesorderId] = useState(null);
  console.log(salesorderId);
  const [Items , setItems] = useState();
  const { 
    showPopup, popupMessage, popupType, retryFunction,
    isSaving ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
    setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();
    const [roleId, setRoleId] = useState(null);
    const sendOptionsRef = useRef(null);


    const useOutsideClick = (ref, callback) => {
      const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          callback();
        }
      };
      React.useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }, []);
    };
    
    useOutsideClick(sendOptionsRef, () => setShowSendOptions(false));

  
    useEffect(() => {
      fetchRoleId();
        fetchSalesOrders(SalesOrderData);
        if (SalesOrderData?._id) {
          fetchSalesOrdersbyId(SalesOrderData._id);
        }
      }, [SalesOrderData]);

      useEffect(() => {
        fetchRoleId();
        fetchSalesOrders(SalesOrderData);
        if (salesOrderId) {
          fetchSalesOrdersbyId(salesOrderId);
        }
      }, [salesOrderId]);

      const fetchRoleId = () => {
        const roleId = JSON.parse(sessionStorage.getItem('roleId'));
        setRoleId(roleId);
      };
    
      const salesOrdersToDisplay = salesOrders && roleId === 6 
      ? salesOrders.filter(salesorder => (salesorder.product_category_id?.product_category_name.toLowerCase()) === 'tiles')
      : salesOrders || [];
      console.log(salesOrdersToDisplay);
    
      const fetchSalesOrders = async (selectedSalesOrder ,attempt = 0) => {
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-all-salesorders?organizationId=${sessionStorage.getItem('organizationId')}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
    
          const data = await response.json();
        if (response.ok) {
        console.log(data.data);
        setSalesOrders(data.data);
        //setSelectedSalesOrder(selectedSalesOrder);
        handleSalesOrderClick(selectedSalesOrder || salesOrderId);
        //setquotations(data.data);
     // setSelectedQuote(selectedQuote);
     // handleQuotationClick(selectedQuote);
     
      /* const FindQuote = data.data.find(p => p._id===QuoteData._id)
      handleQuotationClick(FindQuote);
      setQuoteId(FindQuote?._id);
      setItems(); */
    }
       else {
      console.error(data.message);
    }
    
    
          if (response.status === 500) {
            setRetryFunction(() => () => fetchSalesOrders(attempt));
          }
        } catch (error) {
          console.error('Failed to fetch quotations:', error);
    
          if (attempt < RETRY_LIMIT) {
            setRetryCount(attempt + 1);
            setTimeout(() => fetchSalesOrders(attempt + 1), 1000);
          } else {
            setIsLoading(false);
            setPopupMessage('Internal Server Error. Kindly Retry.');
            setPopupType('500');
            setShowPopup(true);
            setRetryFunction(() => () => fetchSalesOrders(attempt));
          }
        } finally {
          setIsLoading(false);
        }
      };

      const fetchSalesOrdersbyId = async (id ,attempt = 0) => {
        const salesOrderIdToUse = id || salesOrderId;
if (!salesOrderIdToUse) {
  console.log(id, salesOrderId);
  // Handle the case where no valid ID is available
  setPopupMessage('No valid sales order ID provided');
  setPopupType('400');
  setShowPopup(true);
  return;
}
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/get-salesorder?salesorder_id=${salesOrderIdToUse}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token,
            },
          });
    
          const data = await response.json();
          console.log(data);
          const output =  await handleGetResponse(response , data)
          if(output){
            console.log(output)
           /*  if (output.items && Array.isArray(output.items)) {
              output.items = output.items.map(item => {
                if (item.total === 0) {
                  item.total = item.quantity * item.unit_price;
                }
                return item;
              });
            } */
            setSelectedSalesOrder(output);
           
          }else{
          
          }
          }  catch (error) {
            console.error('Failed to fetch:', error);
      
            if (attempt < RETRY_LIMIT) {
              setRetryCount(attempt + 1); // Increment retry count
              setTimeout(() => fetchSalesOrdersbyId(id,attempt + 1), 1000); // Retry after 1 second
            } else {
              setIsLoading(false);
              setPopupMessage('Internal Server Error. Kindly Retry.');
              setPopupType('500');
              setShowPopup(true);
              setRetryFunction(() => () => fetchSalesOrdersbyId(id,attempt)); // Set retry function
            }
          }finally{
            //setProductGroupLoader(false)
          }
        };
    
  

  const  handleSalesOrderClick = (salesOrder) => {
    setSalesorderId(salesOrder?._id || salesOrderId);
    setSalesorderId(salesOrder?._id || salesOrderId);
    setSelectedSalesOrder(salesOrder);
    fetchSalesOrdersbyId(salesOrder?._id || salesOrderId);
   console.log(salesOrder);
  }

  const handleClose = () => {
    navigate('/bb/app/sales/salesorderslist');
  }

  if (isLoading) {
    return <Loader />;
  }

 const handleCreateSalesOrder = () => {
  navigate("/bb/app/sales/newsalesorder");
 }

 const handleCreatePurchaseOrder = () => {
  const PCID = selectedSalesOrder.product_category_id?._id;
  const SO = selectedSalesOrder?._id;
  const vendor = selectedSalesOrder?.items[0].vendor_id;
  const SOType = selectedSalesOrder?.salesorder_type;
  const selectedCategories = [
    {
      salesOrderId: SO,
      productCategoryId: PCID,
      vendor_id: vendor,
      salesOrderType: SOType}]
      console.log(selectedCategories);
  navigate('/bb/app/purchases/newpurchaseorder',{ state: { selectedCategories } });
 }

 const handleEdit = () => {
  navigate('/bb/app/sales/editsalesorder', {state:{salesId: salesorderId}});
  console.log(salesorderId);
 }

 const closePopup = () => {
  console.log("closePopup called");
  setShowPopup(false);
  setPopupMessage('');
        setPopupType('');

};

const Retry = async() => {
  console.log("Retry called");
  setTimeout(async ()=>{
    if(retryFunction){

      await retryFunction ();
    }

    setIsLoading(false)
  },1000)
  
  setShowPopup(false);
};

const BackToLoginPage = () => {
  console.log("Back To Login Page");
  setShowPopup(false);
  localStorage.removeItem('LoginData');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('appId');
navigate('/bb');
};
  
const formatNumber = (value) => {
  // Parse the input as a number
  const parsedValue = parseFloat(value);

  // Check if the parsed value is a finite number
  if (Number.isFinite(parsedValue)) {
    // Round to two decimal places
    return (Math.round(parsedValue * 100) / 100).toFixed(2);
  }

  // Return '0.00' if the value is not a valid number
  return '0.00';
};

const handleSendClick = () => {
  setShowSendOptions(!showSendOptions);
};

/* const sendSalesOrderToUser = async () => {
  setShowSendOptions(false); 
  //console.log(selectedQuote);
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/send-salesorder-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
      },
      body: JSON.stringify({
        salesorderId: selectedSalesOrder._id,
        customerId: selectedSalesOrder.customer_id._id
      })
    });

    const data = await response.json();

    if (response.ok) {
      setPopupMessage('Sales Order sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    } else {
      throw new Error(data.message || 'Failed to send Salesorder');
    }
  } catch (error) {
    console.error('Error sending sales order:', error);
    setPopupMessage('Failed to send sales order');
    setPopupType('400');
  }
}; */

const sendSalesOrderToUser = async () => {
  setIsSaving(true);
  setShowSendOptions(false);
  try {
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}customer/salesorder/send-salesorder-to-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': sessionStorage.getItem('token'),
      },
      body: JSON.stringify({
        customerId: selectedSalesOrder.customer_id._id,
        documentData: {
          documentType: 'Sales Order',
          documentNumber: selectedSalesOrder.salesorder_number,
          date: selectedSalesOrder.salesorder_date,
          status: selectedSalesOrder.salesorder_status,
          items: selectedSalesOrder.items.map(item => ({
            item_name: item.item_name,
            quantity: item.quantity,
            UOM: item.UOM,
            price: item.selling_price,
            tax_rate: item.tax_rate,
            total: item.sales_total
          })),
          additionalInfo: {
            'Sub Total': selectedSalesOrder.subtotal,
            'Tax': selectedSalesOrder.tax_amount,
            'Total': selectedSalesOrder.total
          }}
      }),
    });

    const data = await response.json();

    if (data.success) {
      setIsSaving(true);
      setPopupMessage('Sales Order sent successfully');
      setPopupType('200or201');
      setTimeout(() => {
        setShowPopup(false);
        setPopupMessage('');
        setPopupType('');
      }, 2000);
    } else {
      //throw new Error(data.message || 'Failed to send Sales Order');
      if (response.status === 400) {
        setPopupType('400');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 401) {
        setPopupType('401');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } else if (response.status === 403) {
        setPopupType('403');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      }else if (response.status === 404) {
        setPopupType('404');
        setPopupMessage(data.message);
        setShowPopup(true);
        return null
      } 

    }
  } catch (error) {
    console.error('Error sending sales order:', error);
    setPopupMessage('Failed to send sales order');
    setPopupType('400');
    setShowPopup(true);
  }finally 
  {
    setIsSaving(false);
  }
};




return (

    <div className=" bg-white flex flex-row w-full justify-center overflow-y-hidden">
{/* List of Vendors Div start */}
      <div className=" flex flex-col  w-[32%]  ">
  <div className="p-4 border-b  flex flex-row justify-between ">
    <div>
    <h5 className="text-md font-semibold cursor-pointer ">All Sales Orders
     <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer " />
    </h5>
    </div>
    <div className=''>
    <button onClick={handleCreateSalesOrder}
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md"  data-tooltip="Create Sales Orders" title=""  style={{ '--tooltip-bg': 'black' }}>
  <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>
</div>
  </div>

  <div className="flex justify-center overflow-y-hidden flex-col">
  <div className="flex flex-col h-[80vh] overflow-y-auto">
    {salesOrdersToDisplay.map((salesOrder, index) => (
      <div
        key={salesOrder._id}
        className={`border-b text-sm flex items-center px-4 py-2 ${salesorderId === salesOrder._id ? 'bg-gray-100' : ''} hover:bg-gray-100 cursor-pointer`}
      onClick={() => handleSalesOrderClick(salesOrder)}
      >
        <div className="flex flex-row">
          <div>
            <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
          </div>
          <div className="flex flex-col">
            <p className="text-md font-semibold">{salesOrder.salesorder_number}</p>
            <p className="text-gray-600 text-sm flex items-center">
  {salesOrder?.status} 
  <span className="mx-1 text-gray-600">&middot;</span>
   {new Date(salesOrder.salesorder_date).toLocaleDateString()} 
</p>
            <p className="text-gray-600 text-sm"></p>
          </div>
        </div>
      </div>
    ))} 
  </div>
</div>
</div>
{/* List of Vendors Div end */}

<div className="border-l  w-full flex flex-col ">

<div className="border-l w-full flex flex-col">
        <div className="border-b h-30">
          <div className="p-4 flex justify-between items-center">
             <span className="text-md font-semibold">{selectedSalesOrder?.salesorder_number}</span> 
            <button className="text-white px-1 py-1 rounded-md"
             title="" data-tooltip="Close"  style={{ '--tooltip-bg': 'black' }}>
              <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-sm text-[#f7525a] mx-2 cursor-pointer" />
            </button>
          </div>
          <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
            <button className="border-r px-4 py-2 text-sm mr-2" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
              Edit
            </button>
            <div ref={sendOptionsRef} className="relative">
            <button 
  onClick={handleSendClick} 
  className={`px-4 py-2 border-r text-sm mr-2 ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
  disabled={isSaving}
>
  <div className="flex items-center justify-center space-x-2">
    <FontAwesomeIcon icon={faPaperPlane} className="mr-1 w-3 h-3" />
    <span>Send</span> {isSaving && <Spinner />}
  </div>
</button>
  {showSendOptions && (
    <div className="p-0.5 absolute left-0 top-full mt-1 bg-white border rounded shadow-lg z-10">
      <button onClick={sendSalesOrderToUser} className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Email
      </button>
      <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
        <FontAwesomeIcon icon={faSms} className="mr-2" />
        SMS
      </button>
    </div>
  )}
</div>
<div>
<button
      className="details-menu-item text-center nav-link over-flow"
      type="button"
      onClick={() => navigate('/bb/app/sales/newinvoice', {
        state: {
          salesOrderData: selectedSalesOrder,
          fromSalesOrder: true
        }
      })}
    >
      <span>
      <FontAwesomeIcon icon={faFile} className="mr-2" />
      </span>
      <span className="ps-1">Convert to Invoice</span>
    </button>
    </div>
           {/*  <button className="text-sm border-r px-4 py-2 mr-2">
              <FontAwesomeIcon icon={faShareAlt} className="mr-2 w-3 h-3" />
              Share
            </button> */}
            {/* <PDFDownloadLink
            
            document={<QuotePdfDocument quote={selectedQuote} />}
            fileName={`quotation_${selectedQuote?.quotation_number}.pdf`}
            
            className="text-sm px-4 border-r py-2 mr-2">
              <FontAwesomeIcon icon={faFilePdf} className="mr-2 w-3 h-3" />
              PDF/Print
            {({ loading }) =>
              loading ? 'Generating PDF...' : 'Download PDF'
            }
          </PDFDownloadLink> */}
          {/* <button onClick={() => generatePDF(selectedQuote)}>Generate PDF</button> */}
            {/* <button
  className="text-sm border-r px-4 py-2 mr-2"
  onClick={() => setShowConvertPopup(!showConvertPopup)}
>
  <FontAwesomeIcon icon={faExchangeAlt} className="mr-2 w-3 h-3" />
  Convert
  <FontAwesomeIcon icon={faChevronDown} className="ml-2 w-3 h-3" />
</button> */}
{showConvertPopup && (
  <div
    className="absolute top-[9.5rem] left-[51.5rem] w-42 bg-white shadow-md p-2"
    style={{ zIndex: 1 }}
  >
    <button  onClick={handleCreatePurchaseOrder}  className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1">Convert to Purchase Order</button>
    </div>
)}
            {/* <button className="text-sm border-r px-4 py-2 mr-2">
              <FontAwesomeIcon icon={faEllipsisV} className="mr-2 w-3 h-3" />
              
            </button> */}
            {/* <button className="text-sm px-6 py-2 mr-2">
              
              Download PDF
            </button> */}
           
          </div>
        </div>
        <div className="w-full h-full bg-white rounded-xs overflow-hidden">
  <div className="flex flex-col h-[80vh] overflow-hidden">
    <div className="overflow-y-auto flex-1 p-6">
      {/* "What's Next?" section */}
      {selectedSalesOrder && (
        <>
          <div className="mt-4 mx-10 px-2 border p-2 flex items-center  border-l-4">
            <div className="flex-1">
              <p className="text-sm font-semibold"> What's Next?</p>
              <p className="mt-1 mb-2 text-sm text-gray-700">
                Convert into Purchase Order.
              </p>
            </div>
            <div className="flex-shrink-0 flex space-x-2">
              <button  onClick={handleCreatePurchaseOrder} 
               className="px-2 py-1 text-xs bg-[#f7525a] border-[#f7525a] text-white rounded-md">
                Convert to Purchase Order
              </button>
             
            </div>
          </div>
          <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{selectedSalesOrder?.status}</h1>
          <div className="mt-4">
             <SalesOrderPdfViewer salesorder={selectedSalesOrder} /> 
          </div>
        </>
      )} 
    </div>
  </div>
</div>




      </div>
    </div>
    {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
   </div>
  );
};
export default SalesOrderDetails;