import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShare, faInfoCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const SummaryHeader = ({ packageData, totalPrice, }) => {
  const navigate = useNavigate();
  const [isPriceDetailsVisible, setIsPriceDetailsVisible] = useState(false);
 console.log(packageData)
  return (
    <div className="bg-white shadow-lg">
      {/* Top Navigation Bar */}
      <div className="px-4 py-3 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <button 
            onClick={() => navigate(-1)}
            className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-red-50 transition-colors"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-gray-700" />
          </button>
          <div className="flex flex-col">
          <h1 className="text-xl font-bold bg-gradient-to-r from-gray-800 to-gray-600 text-transparent bg-clip-text leading-tight truncate">
          {packageData?.projectName?.toUpperCase?.() || ''}
        </h1>

            <div className="flex items-center gap-2">
              <span className="text-xs font-medium text-[#EA011F]">
                {packageData.category}
              </span>
              <span className="w-1 h-1 bg-gray-300 rounded-full"/>
              {/* <span className="text-xs text-gray-500">
                {packageData.itemCount} 
              </span> */}
            </div>
          </div>
        </div>
        
        <button className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-red-50 transition-colors">
          <FontAwesomeIcon icon={faShare} className="text-gray-700" />
        </button>
      </div>

      {/* Price Section */}
      <div className="px-4 py-3 bg-gradient-to-r from-red-50 via-red-50 to-white">
        <button 
          onClick={() => setIsPriceDetailsVisible(!isPriceDetailsVisible)}
          className="w-full"
        >
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-gray-600">Total Amount</span>
              <FontAwesomeIcon 
                icon={faInfoCircle} 
                className="text-gray-400 text-xs" 
              />
            </div>
            <FontAwesomeIcon 
              icon={faChevronDown} 
              className={`text-gray-400 text-xs transition-transform duration-200 
                ${isPriceDetailsVisible ? 'transform rotate-180' : ''}`}
            />
          </div>
          <div className="flex items-baseline gap-2">
            <span className="text-2xl font-bold text-[#EA011F]">
              ₹{totalPrice.total.toLocaleString('en-IN')}
            </span>
            <span className="text-xs text-gray-500">incl. of all taxes</span>
          </div>
        </button>

        {/* Expandable Price Details */}
        {isPriceDetailsVisible && (
          <div className="mt-3 pt-3 border-t border-red-100 animate-fadeIn">
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Base Price</span>
                <span className="text-sm font-medium text-gray-800">
                  ₹{totalPrice.subtotal.toLocaleString('en-IN')}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">GST (18%)</span>
                <span className="text-sm font-medium text-gray-800">
                  ₹{totalPrice.tax.toLocaleString('en-IN')}
                </span>
              </div>
              <div className="flex items-center justify-between pt-2 border-t border-red-100">
                <span className="text-sm font-medium text-gray-700">Total Amount</span>
                <span className="text-sm font-bold text-[#EA011F]">
                  ₹{totalPrice.total.toLocaleString('en-IN')}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Package Tags */}
    {/*   <div className="px-4 py-2 flex gap-2 overflow-x-auto no-scrollbar">
        <span className="px-3 py-1 bg-green-50 text-green-700 text-xs font-medium rounded-full whitespace-nowrap">
          Free Installation
        </span>
        <span className="px-3 py-1 bg-blue-50 text-blue-700 text-xs font-medium rounded-full whitespace-nowrap">
          5 Year Warranty
        </span>
        <span className="px-3 py-1 bg-purple-50 text-purple-700 text-xs font-medium rounded-full whitespace-nowrap">
          Premium Finish
        </span>
      </div> */}
    </div>
  );
};

export default SummaryHeader;
