import React from 'react';

const formatNumber = (value) => {
  const parsedValue = parseFloat(value);
  if (Number.isFinite(parsedValue)) {
    const roundedValue = (Math.round(parsedValue * 100) / 100);
    return roundedValue.toLocaleString('hi-IN', { minimumFractionDigits: 2 }); 
  }
  return '0.00';
};

const InvoiceHtmlDocument = ({ invoice }) => (
 
  <div className="p-8 flex flex-col"
    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}  
  >
    { console.log(invoice)}
    <div className="flex justify-between mb-4 text-xs">
      <div className="flex-1">
        <h1 className="font-bold text-md">Brickbucket</h1>
        <p>Andhra Pradesh</p>
        <p>India</p>
        <p>GSTIN 37AAKCK7567P1ZG</p>
        <p>info@brickbucket.com</p>
        <div className="mt-10">
          <h1 className='font-semibold'>Bill To</h1>
        </div>
        <p className="font-semibold">{invoice?.customer_id?.customer_name}</p>
        <p>{invoice?.customer_id?.customer_address}</p>
        <p>{invoice?.customer_id?.customer_city}</p>
        <p>{invoice?.customer_id?.customer_state}</p>
        <div className="mt-1 flex flex-row">
          <h1>GST: </h1>
          <p>{invoice?.customer_id?.gstin}</p>
        </div>
        <p>{invoice?.customer_id?.email}</p>
        <p>{invoice?.customer_id?.mobile_number}</p>
      </div>

      <div className="flex-1 mt-2 text-right">
        <h1 className="text-2xl font-semibold mb-0">Invoice</h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{invoice?.invoice_number}</h1>
        <h1 className="text-sm mt-5 font-semibold">
          {new Date(invoice?.invoice_date).toLocaleDateString()}
        </h1>
        <h1 className="text-md mt-1 font-semibold mb-0">{invoice?.status}</h1>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
      <table className="w-full border-collapse">
        <thead className='bg-[#3C3D3A] text-white p-2 h-12 text-left'>
          <tr>
            <th className="text-center">#</th>
            <th>Items</th>
            <th>Item Category</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Tax</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoice?.items?.map((item, index) => (
            <tr key={index} className='border-b p-2 h-12 text-left'>
              <td className="p-1 text-center">{index + 1}</td>
              <td className="p-1">{item?.item_name}</td>
              <td className="p-1">{invoice?.product_category}</td>
              <td className="p-1">{item?.quantity} {item?.UOM}</td>
              <td className="p-1">₹{formatNumber(item?.selling_price)}</td>
              <td className="p-1">{item?.tax_rate}%</td>
              <td className="p-1">₹{formatNumber(item?.total)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div className="flex justify-end mb-4">
      <div className="text-right p-2 text-xs">
        <div className="mb-2 flex justify-between space-x-20 p-2">
          <p className="text-sm font-semibold">Sub Total:</p>
          <p className="text-sm font-semibold">₹{formatNumber(invoice?.sub_total)}</p>
        </div>
        <div className="flex justify-between p-2">
          <p className="text-sm font-regular">Tax Amount:</p>
          <p className="text-sm font-regular">₹{formatNumber(invoice?.tax_amount)}</p>
        </div>
        <div className="flex justify-between bg-[#F5F4F3] p-2">
          <p className="text-sm font-bold">Total:</p>
          <p className="text-sm font-bold">₹{formatNumber(invoice?.total)}</p>
        </div>
        <div className="flex justify-between p-2">
          <p className="text-sm font-regular">Payment Made:</p>
          <p className="text-sm font-regular text-red-500"> (-)₹{formatNumber(invoice?.total-invoice?.balance_due)}</p>
        </div>
        <div className="flex justify-between p-2">
          <p className="text-sm font-bold">Balance Due:</p>
          <p className="text-sm font-bold">₹{formatNumber(invoice?.balance_due)}</p>
        </div>
      </div>
    </div>

    <div className="mb-4 p-2 text-xs">
      <div className="mt-6 flex items-center">
        <h3 className="text-sm font-regular mr-4 flex-shrink-0">Authorized Signature</h3>
        <div className="flex-grow">
          <div className="border-t border-black" style={{ width: '150px', height: '1px', marginTop: '1rem' }}></div>
        </div>
      </div>
    </div>
  </div>
);

const InvoicePdfViewer = ({ invoice }) => (
  <div className="h-screen">
    <InvoiceHtmlDocument invoice={invoice} />
  </div>
);

export default InvoicePdfViewer;
