import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import Login from './Admin/Login';
import ForgotPassword from './Admin/ForgotPasswordPage';
import ResetPassword from './Admin/ResetPasswordPage';
import Quotes from './Admin/Quotes';
import Main_Page from './Admin/BBMainPage';
import ProductCategories from './ProductCategories/ProductCategories';
import NewProductCategory from './ProductCategories/NewProductCategory';
import EditProductCategory from './ProductCategories/EditProductCategory';

/* import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword'; */
import AllCustomers from './Customers/AllCustomers'
import ViewCustomer from './Customers/ViewCustomer';
import Home from './Admin/Home';
import NewCustomer from './Customers/NewCustomer';
import NewVendor from './Vendors/NewVendor'; 
import Allvendors from './Vendors/AllVendors';
import ProductAttributes from './ProductCategories/ProductAttributes';

import ViewProductCategory from './ProductCategories/ViewProductCategory'
import ViewVendor from './Vendors/ViewVendor';
 import ItemGroupsList from './ItemGroups/ItemGroupsList'
 import NewItemGroups from './ItemGroups/newItemGroup'
 import NewItem from './Items/NewItem'
 import ItemGroupDetails from './ItemGroups/ItemGroupDetails'
import EditVendor from './Vendors/EditVendor';
import BulkUploadItems from './Items/BulkUploadItems';
import ItemsList from './Items/ItemsList'
import ItemDetails from './Items/ItemDetails'
import NewPriceList from './Items/PriceList';
import EditCustomer from './Customers/EditCustomer';
import ViewPriceLists from './Items/ViewPriceLists';
import EditPriceList from './Items/EditPriceList';
import EditProductGroups from './ItemGroups/EditItemGroup';
import NewQuote from './Quotations/NewQuote';

import NewSalesOrder from './SalesOrder/NewSalesOrder';
import QuotationsList from './Quotations/QuotationsList';
import SalesOrdersItemWise from './SalesOrder/SalesOrdersItemWise';
import QuoteDetails from './Quotations/QuoteDetails';
import NewPurchaseOrder from './PurchaseOrder/NewPurchaseOrder';
import SalesOrderDetails from './SalesOrder/SalesOrderDetails';
import PurchaseOrdersItemWIse from './PurchaseOrder/PurchaseOrderItemWise';
import PurchaseOrdersList from './PurchaseOrder/PurchaseOrderList';
import EditSalesOrder from './SalesOrder/EditSalesOrder';
import EditQuote from './Quotations/EditQuote';
import PurchaseOrderDetails from './PurchaseOrder/PurchaseOrderDetails';
import SalesOrdersList from './SalesOrder/SaleOrdersList';
import EditPurchaseOrder from './PurchaseOrder/EditPurchaseOrder'

import Projects from '../Measurit_v3.0/ProjectList';
import NewProject from '../Measurit_v3.0/NewProject';
import ProjectDetails from '../Measurit_v3.0/ProjectDetails';
import BlockDetails from '../Measurit_v3.0/BlockDetails';
import NewMeasurements from '../Measurit_v3.0/Measurements/NewMeasurements';

import NewDeliveryChallans from './DeliveryChallans/NewDeliveryChallans';
import DeliveryChallansList from './DeliveryChallans/DeliveryChallansList';
import DeliveryChallanDetails from './DeliveryChallans/DeliveryChallanDetails';
import EditDeliveryChallan from './DeliveryChallans/EditDeliveryChallan';

import VehicalSchedulingDetails from './SalesOrder/VehicalSchedulingDetails';
import VehicalSheduling from './SalesOrder/VehicalSheduling';
import VehicleSheduleList from './SalesOrder/VehicleSheduleList';
import EditItems from './Items/EditItems';




import NewOrganization from './Settings/NewOrganization';
import OrganizationList from './Settings/OrganizationList';
import OrganizationDetails from './Settings/OrganizationDetails';
import EditOrganization from './Settings/EditOrganization';
import EditProject from '../Measurit_v3.0/EditProject';
import MaterialReq2 from '../ConsumerApp/MaterialReq2';
import PaymentReceivedList from './PaymentsReceived/PaymentReceivedList';
import NewPaymentReceived from './PaymentsReceived/NewPaymentReceived';
import PaymentReceivedDetails from './PaymentsReceived/PaymentReceivedDetails';
import InvoiceList from './Invoices/InvoicesList';
import NewInvoice from './Invoices/NewInvoice';
import InvoiceDetails from './Invoices/InvoiceDetails';
import EditInvoice from './Invoices/EditInvoice';
import EditPaymentReceived from './PaymentsReceived/EditPaymentReceived';

import NewTax  from './Settings/NewTax';
import TaxList from './Settings/TaxList';
import EditTax from './Settings/EditTax';
import TaxDetails from './Settings/TaxDetails';
import Template from './Templates/Template';

import PackageTemplate from './PakageTemplates/door_packages_template'
import TemplateList from './Templates/TemplateList';
import EditTemplate from './Templates/EditTemplate';


function App() {
  const [appId, setappId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  


  useEffect(() => {
    const BrickBucket = () => {
      if (location.pathname.startsWith('/bb')) {
        const storedLoginData = JSON.parse(localStorage.getItem('LoginData'));
        if (storedLoginData) {
          const appId = storedLoginData.appId;
        setappId(appId);
        setLoading(false);
        } else {
          // Handle case where user data is not available
          // For example, navigate to login page
          if (location.pathname.startsWith('/bb') && 
          !location.pathname.startsWith('/bb/reset-password') &&
          !location.pathname.startsWith('/bb/forgot-password') &&
          !location.pathname.startsWith('/bb/reset-password')
      ) {
          navigate('/bb');
          setLoading(false);
      }
          
        }
      }
    };

    BrickBucket(); // Call BrickBucket function directly here
  }, [navigate, location.pathname]); // Add navigate and location.pathname as dependencies


  if (loading) {
    return <div>Loading...</div>;
  }

  const AccessDenied = () => {
    return (
      <div
        className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
        style={{ backdropFilter: 'blur(100px)' }}
        role="dialog"
        aria-modal="true"
      >
<div className="bg-white rounded shadow-md p-8 max-w-md">
  <h2 className="text-lg font-bold text-center">Access Denied</h2>
  <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
  <div className="flex justify-center mt-2">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/bb');
      }}
    >
      Go Back
    </button>
  </div>
</div>
      </div>
    );
  };

  return (
    <Routes>
       <Route path="/bb" element={<Login />} />
      
       <Route path="/bb/forgot-password" element={<ForgotPassword />} />
      <Route path="/bb/reset-password/:resetToken" element={<ResetPassword />} />
      {/* <Route path="*" element={<AccessDenied />} />  */}
      <Route path="/bb/Quotes" element={<Quotes/>}/>
     
      <Route path="/bb/app" element={<Main_Page />}>
      <Route path="/bb/app/materialreq2" element={<MaterialReq2/>} />
      <Route path='home' element = {<Home/>}/>
      <Route path="items/productCategories" element={<ProductCategories/>} />
      <Route path="items/editproductCategory" element={<EditProductCategory/>} />
      <Route path="items/newproductCategory" element={<NewProductCategory/>} />
      <Route path='items/productcategorydetails' element={<ViewProductCategory/>}/>
      <Route path='items/productgroupslist' element={<ItemGroupsList/>}/>
      <Route path='items/newproductgroups' element={<NewItemGroups/>}/>
      <Route path='items/productgroupdetails' element={<ItemGroupDetails/>}/>
      <Route path='items/productAttributes' element={<ProductAttributes/>} />
      <Route path='items/newitem' element={<NewItem/>}/>
      <Route path='items/itemslist' element={<ItemsList/>}/>
      <Route path='items/itemdetails' element={<ItemDetails/>}/>
      <Route path='items/viewpricelists' element={<ViewPriceLists/>}/>
      <Route path='items/editpricelist' element={<EditPriceList/>} />
      <Route path='items/edititemgroup' element={<EditProductGroups/>}/>
      <Route path='items/edititems' element={<EditItems/>}/>
      <Route path='items/bulkuploaditems'element={<BulkUploadItems/>}/>
      <Route path='items/newpricelist' element={<NewPriceList/>} />
      <Route path='sales/customerslist' element = {<AllCustomers/>}/>
      <Route path='sales/customerdetails' element = { <ViewCustomer/>}/>
      
      <Route path='sales/newcustomer' element={<NewCustomer/>}/>
      <Route path='purchases/newvendor' element={<NewVendor/>} /> 
      <Route path='purchases/vendorslist' element={<Allvendors/>}/>
      <Route path='purchases/vendordetails' element={<ViewVendor/>} />    
      <Route path='purchases/editvendor' element={<EditVendor/>}/>
      <Route path='sales/editcustomer' element={<EditCustomer/>} />      
      <Route path='sales/newquote' element={<NewQuote/>}/>
      <Route path='sales/newsalesorder' element={<NewSalesOrder/>}/>
      <Route path='sales/quotelists' element={<QuotationsList/>} />
      <Route path='sales/salesordersitemwise' element={<SalesOrdersItemWise/>}/>
      <Route path='sales/salesorderslist' element={<SalesOrdersList/>}/>
      <Route path='sales/quotedetails' element={<QuoteDetails/>} />
      <Route path='purchases/newpurchaseorder' element={<NewPurchaseOrder/>}/>
      <Route path='sales/salesorderdetails' element={<SalesOrderDetails />} />
      <Route path='purchases/purchaseorderlist' element={<PurchaseOrdersList />} />
      <Route path='purchases/purchaseorderitemwise' element={<PurchaseOrdersItemWIse />} />
      <Route path='sales/editsalesorder' element={<EditSalesOrder />} />
      <Route path='sales/editquote' element={<EditQuote/>} />
      <Route path = 'sales/paymentreceivedlist' element={<PaymentReceivedList/>} />
      <Route path='sales/newpaymentreceived' element={<NewPaymentReceived/>} />
      <Route path='sales/paymentreceiveddetails' element={<PaymentReceivedDetails/>} />
      <Route path='sales/invoiceslist' element = {<InvoiceList/>} />
      <Route path='sales/newinvoice' element = {<NewInvoice/>} />
      <Route path='sales/invoicedetails' element = {<InvoiceDetails/>} />
      <Route path='sales/editinvoice' element = {<EditInvoice/>} />
      <Route path='sales/editpaymentreceived' element = {<EditPaymentReceived/>} />

      <Route path='purchases/purchaseorderdetails' element={<PurchaseOrderDetails/>} />
      <Route path='purchases/editpurchaseorder' element={<EditPurchaseOrder/>} />    

      <Route path='projects/projectslist' element={<Projects/>} />  
      <Route path='projects/newproject' element={<NewProject/>} />
      <Route path='projects/projectdetails' element={<ProjectDetails/>} />
      <Route path='projects/blockdetails' element = {<BlockDetails/>}/>
      <Route path='projects/measurements' element={<NewMeasurements/>} />
      <Route path='projects/editproject' element={<EditProject/>} />


      <Route path='settings/neworganization' element={<NewOrganization />} />
      <Route path='settings/organizationlist' element={<OrganizationList/>} />
      <Route path='settings/organizationdetails' element={<OrganizationDetails/>} />
      <Route path='settings/editorganization' element={<EditOrganization/>} />
    
      <Route path='settings/newtax' element={<NewTax />} />
      <Route path='settings/taxlist' element={<TaxList/>} />
      <Route path='settings/taxdetails' element={<TaxDetails/>} />
      <Route path='settings/edittax' element={<EditTax/>} />
      <Route path = "settings/PackageTemplate" element = {<PackageTemplate/>}/>

      <Route path='deliverychallans/newdeliverychallans' element={<NewDeliveryChallans/>} />
      <Route path='deliverychallans/deliverychallanslist' element={<DeliveryChallansList/>} />
      <Route path='deliverychallans/deliverychallandetails' element={<DeliveryChallanDetails/>} />
      <Route path='deliverychallans/editdeliverychallan' element={<EditDeliveryChallan/>} />

      <Route path='sales/vehicleschedulingdetails' element={<VehicalSchedulingDetails/>} />
      <Route path='sales/newvehicleschedule' element={<VehicalSheduling/>} />
      <Route path='sales/vehicleshedulelist' element={<VehicleSheduleList/>} />

      <Route path='templates/template' element = {<Template/>} />
      <Route path='templates/templatelist' element = {<TemplateList/>} />
      <Route path='templates/edittemplate' element = {<EditTemplate/>} />

      </Route>
   
    </Routes>
  );
}

export default App;
