import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPlus, faPen, faPaperPlane, faMessage, faEnvelope, faSms, faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import InvoicePdfViewer from './InvoicePdfViewer';


const InvoiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { InvoiceData } = location.state || {};
  const invoiceId = location.state?.invoiceId;
  const [invoices, setInvoices] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  const { 
    showPopup, popupMessage, popupType, retryFunction,
    isSaving ,setPopupMessage,setPopupType,handleGetResponse, handleSaveResponse, setShowPopup,
    setRetryFunction, handleDeleteResponse, setIsSaving  } = useCommonResponseHandler();

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  console.log(selectedInvoice);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreditsPopup, setShowCreditsPopup] = useState(false);
  const [creditAmounts, setCreditAmounts] = useState({});
  const [advancePayments, setAdvancePayments] = useState([]);
  const sendOptionsRef = useRef(null);
  const  [ invoiceid  , setInvoiceid] = useState(null);
  const getStatusColor = (status) => {
    switch (status) {
      case 'Draft':
        return 'text-gray-500';
      case 'Paid':
        return 'text-green-500';
      case 'Pending':
        return 'text-yellow-500';
      case 'Overdue':
        return 'text-red-500';
      default:
        return '';
    }
  };
  


useEffect(() => {
  if (InvoiceData) {

    setSelectedInvoice(InvoiceData);
  }

  if(InvoiceData){
console.log(InvoiceData.customer_id);
    getAllAdvancePaymentsOfCustomer(InvoiceData?.customer_id?._id)
  }

}, [InvoiceData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sendOptionsRef.current && !sendOptionsRef.current.contains(event.target)) {
        setShowSendOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSendClick = () => {
    setShowSendOptions(!showSendOptions);
  };

  const handleEdit = () => {
    navigate("/bb/app/sales/editinvoice",{state:{invoiceId: invoiceId}});
  };

  

  const handleCreate = () => {
    navigate("/bb/app/sales/newinvoice");
  };
  
  const handleClose = () => {
    navigate('/bb/app/sales/invoiceslist');
  };
  const handleApplyCredits = () => {
    setShowCreditsPopup(true);
  };
  const handleCloseCreditsPopup = () => {
    setShowCreditsPopup(false);
  };


  const totalCreditAmount = Object.values(creditAmounts)
  .reduce((sum, amount) => sum + (parseFloat(amount) || 0), 0)
  .toLocaleString('en-IN');



  const handleCreditAmountChange = (paymentId, value) => {
    setCreditAmounts(prevAmounts => ({
      ...prevAmounts,
      [paymentId]: value
    }));
  };
  

  async function getAllInvoices(Invoice) {
    try {
      const response = await fetch(`${fetchUrl}invoice/get-all-invoices`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          console.log('Invoices:', data.data);
          let FindInvoice;
          if(invoiceId){
            FindInvoice = data?.data.find(p => p?._id===invoiceId)
          }else{
            FindInvoice = data?.data.find(p => p?._id===Invoice?._id)
          }
          handleInvoiceClick(FindInvoice);
          setInvoiceid(FindInvoice?._id);
          setInvoices(data.data)
        } else {
          console.error('Error:', data.message);
          alert(`Failed to fetch invoices: ${data.message}`);
        }
      } else {
        console.error('HTTP Error:', response.status);
        alert(`HTTP Error: ${response.status}`);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      alert('Failed to fetch invoices due to a network error');
    }
  }

  useEffect(() => {
    getAllInvoices(InvoiceData);
  }, [InvoiceData]);


  const sendInvoiceToUser = async () => {
    setIsSaving(true);
    setShowSendOptions(false);
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}invoice/send-invoice-to-customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          customerId: selectedInvoice.customer_id._id,
          documentData: {
            documentType: 'Invoice',
            documentNumber: selectedInvoice.invoice_number,
            date: selectedInvoice.invoice_date,
            status: selectedInvoice.status,
            items: selectedInvoice.items.map(item => ({
              item_name: item.item_name,
              quantity: item.quantity,
              UOM : item.UOM,
              price: item.selling_price,
              tax_rate: item.tax_rate,
              total: item.total
            })),
            additionalInfo: {
              'Sub Total': selectedInvoice.subtotal,
              'Tax': selectedInvoice.tax_amount,
              'Total': selectedInvoice.total
            }}
        }),
      });
  
      const data = await response.json();
  console.log(data);
      if (data.success === true) {
        setPopupMessage('Invoice sent successfully');
        setPopupType('200or201');
        setShowPopup(true); // Add this line
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
          setPopupType('');
        }, 2000);
      } else {
        //throw new Error(data.message || 'Failed to send Quotation');
       if (response.status === 400) {
          setPopupType('400');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 401) {
          setPopupType('401');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 403) {
          setPopupType('403');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        }
      }
    } catch (error) {
      console.error('Error sending Invoice:', error);
      setPopupMessage('Failed to send Invoice');
      setPopupType('500');
      setShowPopup(true);
    }finally{
    setIsSaving(false);
    }
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
    setPopupMessage('');
          setPopupType('');
  };


  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){
  
        await retryFunction ();
      }
  
      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleInvoiceClick = (invoice) => {
    setInvoiceid(invoice?._id);
    setShowPopup(false);
    setSelectedInvoice(invoice);
    if(invoice?.customer_id?._id) {
      getAllAdvancePaymentsOfCustomer(invoice.customer_id._id);
    }
  };
  

  const getAllAdvancePaymentsOfCustomer = async (customerId) => {
    try {
      // Replace with your API endpoint
      const apiUrl = `${fetchUrl}payments/get-all-advance-payments-of-customer?customer_id=${customerId}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token': sessionStorage.getItem('token'),
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  console.log("Advance Payments:", data.data);
  setAdvancePayments(data.data);  
      if (data.success) {
        console.log('Advance Payments retrieved:', data.data);
        return data.data; // Process the payments as needed
      } else {
        console.warn('Failed to retrieve advance payments:', data.message);
        return [];
      }
    } catch (error) {
      console.error('Error fetching advance payments:', error);
      return [];
    }
  };
  

  const applyAdvancePayments = async () => {
    setIsSaving(true);
    
    try {
      const response = await fetch(`${fetchUrl}payments/apply-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token': sessionStorage.getItem('token')
        },
        body: JSON.stringify({
          invoice_id: selectedInvoice._id,
          payments: Object.entries(creditAmounts).map(([paymentId, amount]) => ({
            payment_id: paymentId,
            amount_applied: parseFloat(amount)
          }))
        })
      });
  
      const data = await response.json();
  console.log("Response Data:", data);
      if (data.success) {
        setPopupMessage('Credits applied successfully');
        setPopupType('200or201');
        setShowCreditsPopup(false);
        // Refresh invoice details after applying credits
        getAllInvoices();
      } else {
        if (response.status === 400) {
          setPopupType('400');
          setPopupMessage(data.message);
        } else if (response.status === 401) {
          setPopupType('401');
          setPopupMessage(data.message);
        } else if (response.status === 403) {
          setPopupType('403');
          setPopupMessage(data.message);
        }
      }
    } catch (error) {
      setPopupMessage('Failed to apply credits');
      setPopupType('500');
    } finally {
      setIsSaving(false);
      setShowPopup(true);
    }
  };
  
  const handleMarkAsSent = async () => {
    console.log("invoice id:",invoiceid)
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}invoice/mark-as-sent?invoice_id=${invoiceid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
  
      if (data.success) {
        setPopupMessage('Invoice marked as sent successfully');
        setShowPopup(true); // Make sure to show the popup

        setPopupType('200or201');
        setTimeout(() => {
          setShowPopup(false);
          setPopupMessage('');
          setPopupType('');
        }, 3000);
      } else {
        //throw new Error(data.message || 'Failed to send Quotation');
       if (response.status === 400) {
          setPopupType('400');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 401) {
          setPopupType('401');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        } else if (response.status === 403) {
          setPopupType('403');
          setPopupMessage(data.message);
          setShowPopup(true);
          return null
        }
      }
    } catch (error) {
      console.error('Error sending Invoice:', error);
      setPopupMessage('Failed to send Invoice');
      setPopupType('500');
      setShowPopup(true);
    }finally{
    setIsSaving(false);
    }
  }

  return (
    <div className="bg-white flex flex-row w-full justify-center overflow-y-hidden">

{/* Left Panel - Invoice List */}
<div className="flex flex-col w-[32%]">
  <div className="p-4 border-b flex flex-row justify-between">
    <div>
      <h5 className="text-md font-semibold cursor-pointer">All Invoices
        <FontAwesomeIcon icon={faChevronDown} className="text-xs mb-0.5 text-blue-600 mx-1 cursor-pointer" />
      </h5>
    </div>
    <div>
    <button 
  onClick={handleCreate}
  className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md" 
  data-tooltip="Create Invoice" 
  title=""
>
  <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
</button>

    </div>
  </div>

  <div className="flex flex-col h-[80vh] overflow-y-auto">
    {invoices.map((invoice) => (
      <div
  key={invoice._id}
  onClick={() => handleInvoiceClick(invoice)}
  className={`border-b text-sm flex items-center px-4 py-2 ${
    invoice._id === invoiceid ? 'bg-gray-100' : 'bg-white'
  } hover:bg-gray-100 cursor-pointer`}
>



        <div className="flex flex-row">
          <div>
            <input type="checkbox" className="mr-2 border border-gray-400 shadow-sm hover:shadow-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded" />
          </div>
          <div className="flex flex-col">
            <p className="text-md font-semibold">{invoice.invoice_number}</p>
            <p className="text-gray-600 text-sm flex items-center">
              {invoice.status}
              <span className="mx-1 text-gray-600">&middot;</span>
              {new Date(invoice.invoice_date).toLocaleDateString()}
            </p>
            {/* <p className="text-gray-600 text-sm">₹{invoice.amount.toLocaleString('hi-IN')}</p> */}
          </div>
        </div>
      </div>
    ))}
  </div>
</div>



      {/* Right Panel - Invoice Details */}
      <div className="border-l w-full flex flex-col">
  <div className="border-b">
    <div className="p-4 flex justify-between items-center">
      <span className="text-md font-semibold">{selectedInvoice?.invoice_number}</span>
      <FontAwesomeIcon 
  icon={faX} 
  onClick={handleClose} 
  className="text-md text-[#f7525a] mx-2 cursor-pointer" 
/>


    </div>
    <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
      <button className="border-r px-4 py-2 text-sm mr-2" onClick={handleEdit}>
        <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
        Edit
      </button>
      <div ref={sendOptionsRef} className="relative">
        <button className="px-4 py-2 border-r text-sm mr-2" onClick={handleSendClick}>
          <FontAwesomeIcon icon={faPaperPlane} className="mr-2 w-3 h-3" />
          Send
        </button>
        {showSendOptions && (
          <div className="absolute left-0 top-full mt-1 bg-white border rounded shadow-lg z-10">
            <button onClick={sendInvoiceToUser} className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              Email
            </button>
            <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
              <FontAwesomeIcon icon={faSms} className="mr-2" />
              SMS
            </button>
          </div>
        )}
      </div>
      <button className="text-sm border-r px-4 py-2 mr-2" onClick={handleMarkAsSent}>
              <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="mr-1 w-4 h-4" />
              Mark as Sent  
            </button>
    </div>
  </div>

  <div className="flex flex-col">
    {selectedInvoice && (
      <>
      <div className="mx-10 mt-4 px-2 border p-2 flex flex-col border-l-4">
        {/* Credits Available Section */}
        {(selectedInvoice?.status?.toLowerCase() === "sent"|| selectedInvoice?.status?.toLowerCase() === 'partially_paid') && (
        <div className="flex items-center justify-between">
     
  <div className="text-sm">
    <span className="text-gray-600">Credits Available:</span>
    <span className="font-semibold ml-2">
      ₹{advancePayments.reduce((total, payment) => total + (payment.unused_amount || 0), 0).toLocaleString('en-IN')}
    </span>
  </div>


          <button 
            onClick={handleApplyCredits}
            className="px-3 py-1.5 text-sm bg-[#f7525a] hover:bg-[#f7525a]/90 text-white rounded-md"
          >
            Apply Now
          </button>
        </div>
        
      )}
    
    <hr className="my-3 border-gray-200" />
    {selectedInvoice?.status === 'Sent' ? (
  <div className="mx-10 mt-4 px-2 border p-2 flex items-center border-l-4 border-green-500">
    <div className="flex-1">
      <p className="text-sm font-bold text-green-600 uppercase tracking-wide">
        INVOICE SENT
      </p>
      <p className="mt-1 text-sm text-gray-600">
        This invoice has been sent to customer
      </p>
    </div>
    <div className="flex-shrink-0">
      <svg className="h-6 w-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    </div>
  </div>
) : (
  <div className="mx-10 mt-4 px-2 border p-2 flex items-center border-l-4">
    <div className="flex-1">
      <p className="text-sm font-bold text-black uppercase tracking-wide">WHAT'S NEXT?</p>
      <p className="mt-1 text-sm text-gray-600">
        Send this Invoice to customer or mark it as Sent
      </p>
    </div>
    <div className="flex-shrink-0 flex space-x-2">
      <button className="px-3 py-1.5 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded-md" onClick={handleSendClick}>
        Send Invoice
      </button>
      <button className="px-3 py-1.5 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 border border-gray-300 rounded-md" onClick={handleMarkAsSent}>
        Mark as Sent
      </button>
    </div>
  </div>
)}
        </div>
      </>
    )}
  </div>

    
        {/* Invoice Content */}
<div className="w-full h-full bg-white rounded-xs overflow-hidden">
  <div className="flex flex-col h-[80vh] overflow-hidden">
    <div className="overflow-y-auto flex-1 p-6">
      {selectedInvoice && (
        <>
          <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{selectedInvoice.status}</h1>
          <div className="mt-4">
            <InvoicePdfViewer invoice={selectedInvoice} />
          </div>
        </>
      )}
    </div>
  </div>
</div>

      </div>
      {showCreditsPopup && (
  <div className="pt-2 pb-2 fixed z-20 inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50">
    <div
      className="bg-white rounded-b-md shadow-md w-full h-full flex flex-col animate-slide-in"
      style={{ maxWidth: '70vw', maxHeight: '80vh' }}
    >
      <div className="bg-[#F9F9FB] flex justify-between items-center w-full h-[12%] px-6">
        <h2 className="text-md">Applied Credits for {selectedInvoice?.invoice_number}</h2>
        <button onClick={handleCloseCreditsPopup} className="text-white px-1 py-1 rounded-md">
          <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
        </button>
      </div>
      
      <hr className="border-t border-gray-200 w-full" />
      
      <div className="flex flex-col px-6 py-2">
        <div className="mt-2 text-sm flex justify-end">
          <p>Invoice Balance : ₹{selectedInvoice?.balance_due || 0}</p>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto px-6 py-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="text-xs bg-gray-100">
              <th className="border px-4 py-2 text-left font-normal text-gray-500">PAYMENT NUMBER</th>
              <th className="border px-4 py-2 text-left font-normal text-gray-600">PAYMENT DATE</th>
              <th className="border px-4 py-2 text-center font-normal text-gray-600">AMOUNT</th>
              <th className="border px-4 py-2 text-center font-normal text-gray-600">UNUSED AMOUNT</th>
              <th className="border px-4 py-2 text-right font-normal text-gray-600">AMOUNT TO CREDIT</th>
            </tr>
          </thead>
          <tbody>
            {advancePayments.map((payment) => (
              <tr key={payment._id}>
                <td className="border text-sm px-2 py-1 text-left">{payment.payment_number}</td>
                <td className="border text-sm px-4 py-2">
                  {new Date(payment.payment_date).toLocaleDateString()}
                </td>
                <td className="border px-4 py-2 text-center">₹{payment.amount.toLocaleString()}</td>
                <td className="border px-4 py-2 text-center">₹{payment.unused_amount.toLocaleString()}</td>
                <td className="border px-4 py-2 text-right">
                <div className="flex items-center">
  <input
    className="outline-blue-500 text-right text-sm w-full h-full p-2 bg-transparent rounded-md hover:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:ring-opacity-90"
    type="text"
    value={creditAmounts[payment._id] || ''}
    onChange={(e) => handleCreditAmountChange(payment._id, e.target.value)}
    placeholder="Enter amount"
  />
  <span className="ml-2 text-sm">₹</span>
</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mt-4">
          <div className="flex flex-col items-end">
            <div className="px-4 py-2">
              <span className="text-gray-600">Amount to Credit : </span>
              <span className="text-black font-medium">₹{totalCreditAmount}</span>
            </div>
            <div className="bg-gray-100 px-4 py-2 rounded-md mb-2">
              <span className="text-gray-600">Invoice Balance Due : </span>
              <span className="text-black font-medium">₹{selectedInvoice?.balance_due}</span>
            </div>
          </div>
        </div>
      </div>

      <hr className="border-t border-gray-200" />

      <div className="flex justify-start px-6 py-4 space-x-4">
        <button 
         onClick={applyAdvancePayments}
          className="text-sm px-2 py-1 bg-[#f7525a] border-[#f7525a] text-white rounded-md"
        >
          Apply Credits
        </button>
        <button 
          onClick={handleCloseCreditsPopup} 
          className="text-sm border hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}




      {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={closePopup} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup}/>}
  </div>
)}

    </div>
  );
};

export default InvoiceDetails;
