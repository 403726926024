import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  CheckCircleIcon, 
  SparklesIcon 
} from '@heroicons/react/24/solid';
import { 
  RocketLaunchIcon 
} from '@heroicons/react/24/outline';

const LoginSuccessModal = ({ userName, organizationName, onClose }) => {
  const [animationStage, setAnimationStage] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => setAnimationStage(1), 500);
    const timer2 = setTimeout(() => setAnimationStage(2), 1500);
    
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  const dynamicIcons = [
    <CheckCircleIcon key="check" className="h-16 w-16 text-green-500" />,
    <SparklesIcon key="sparkle" className="h-16 w-16 text-yellow-500 animate-pulse" />,
    <RocketLaunchIcon key="rocket" className="h-16 w-16 text-blue-500 animate-bounce" />
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <motion.div 
        initial={{ scale: 0.7, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ 
          type: "spring", 
          stiffness: 260, 
          damping: 20 
        }}
        className="bg-white rounded-2xl shadow-2xl p-10 max-w-md w-full mx-4 text-center"
      >
        <div className="flex flex-col items-center space-y-6">
          <AnimatePresence mode="wait">
            <motion.div
              key={animationStage}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.3 }}
            >
              {dynamicIcons[animationStage]}
            </motion.div>
          </AnimatePresence>
          
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-3xl font-bold text-gray-800"
          >
            Login Successful
          </motion.h2>
          
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="text-center"
          >
            <p className="text-lg text-gray-600 mb-2">
              Welcome, <span className="font-semibold text-[#EB001B]">{userName}</span>
            </p>
            <p className="text-sm text-gray-500">
              Organization: {organizationName}
            </p>
          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
            className="w-full"
          >
            <button 
              onClick={onClose}
              className="w-full bg-[#EB001B] text-white py-3 rounded-lg 
              hover:bg-red-700 transition duration-300 
              transform hover:scale-105 active:scale-95
              flex items-center justify-center space-x-2"
            >
              <span>Continue to Dashboard</span>
              <RocketLaunchIcon className="h-5 w-5" />
            </button>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default LoginSuccessModal;
