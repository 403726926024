import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faX, faPlus, faPen, faPaperPlane, faEnvelope, faSms } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import PaymentPdfViewer from './PaymentPdfViewer';

const PaymentReceivedDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { PaymentData } = location.state || {};
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sendOptionsRef = useRef(null);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  const { 
    showPopup, popupMessage, popupType, retryFunction,
    isSaving, setPopupMessage, setPopupType, handleGetResponse, 
    setShowPopup, setRetryFunction, setIsSaving 
  } = useCommonResponseHandler();
 
  useEffect(() => {
    if (PaymentData) {
      setSelectedPayment(PaymentData);
    }
  }, [PaymentData]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sendOptionsRef.current && !sendOptionsRef.current.contains(event.target)) {
        setShowSendOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSendClick = () => {
    setShowSendOptions(!showSendOptions);
  };

  const handleEdit = () => {
    console.log(selectedPayment)
    navigate("/bb/app/sales/editpaymentreceived", { state: { PaymentID: selectedPayment?._id } });
  };

  const handleClose = () => {
    navigate('/bb/app/sales/paymentreceivedlist');
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Received':
        return 'text-green-500';
      case 'Pending':
        return 'text-yellow-500';
      default:
        return '';
    }
  };

  async function getAllPayments() {
    try {
      const response = await fetch(`${fetchUrl}payments/get-all-payments`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          console.log('payments:', data.data);
          setPayments(data.data)
        } else {
          console.error('Error:', data.message);
          alert(`Failed to fetch payments: ${data.message}`);
        }
      } else {
        console.error('HTTP Error:', response.status);
        alert(`HTTP Error: ${response.status}`);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      alert('Failed to fetch invoices due to a network error');
    }
  }
  
  
  
  useEffect(() => {
    getAllPayments();
  }, [fetchUrl]);

  return (
    <div className="bg-white flex flex-row w-full justify-center overflow-y-hidden">
      {/* Left Panel - Payments List */}
      <div className="flex flex-col w-[32%]">
        <div className="p-4 border-b flex flex-row justify-between">
          <div>
            <h5 className="text-md font-semibold cursor-pointer">All Payments</h5>
          </div>
          <div>
            <button className="mr-2 bg-[#f7525a] text-white px-1 py-0.5 rounded-md" data-tooltip="Create Payment" title="">
              <FontAwesomeIcon icon={faPlus} className="mb-0.5 text-xs text-white mx-1 cursor-pointer" />
            </button>
          </div>
        </div>

        <div className="flex flex-col h-[80vh] overflow-y-auto">
          {payments.map((payment) => (
            <div
              key={payment.id}
              className={`border-b text-sm flex items-center px-4 py-2 ${selectedPayment?.id === payment.id ? '' : ''} hover:bg-gray-100 cursor-pointer`}
              onClick={() => setSelectedPayment(payment)}
            >
              <div className="flex flex-row w-full">
                <div>
                  <input type="checkbox" className="mr-2" />
                </div>
                <div className="flex flex-col w-full">
                  <div className="flex justify-between">
                    <p className="text-md font-semibold">{payment.payment_number}</p>
                    <p className={`text-sm ${getStatusColor(payment.status)}`}>{payment.status}</p>
                  </div>
                  <p className="text-gray-600 text-sm">{payment.customer_name}</p>
                  <div className="flex justify-between text-gray-600 text-sm">
  <span>{payment.payment_date ? new Date(payment.payment_date).toLocaleDateString() : 'N/A'}</span>
  <span>₹{payment.amount.toLocaleString('hi-IN')}</span>
</div>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Right Panel - Payment Details */}
      <div className="border-l w-full flex flex-col">
        <div className="border-b">
          <div className="p-4 flex justify-between items-center">
            <span className="text-md font-semibold">{selectedPayment?.payment_number}</span>
            <button className="text-white px-1 py-1 rounded-md" title="" data-tooltip="Close">
              <FontAwesomeIcon icon={faX} onClick={handleClose} className="text-md text-[#f7525a] mx-2 cursor-pointer" />
            </button>
          </div>
          <div className="flex items-center px-2 bg-[#F9F9FB] border-t">
            <button className="border-r px-4 py-2 text-sm mr-2" onClick={handleEdit}>
              <FontAwesomeIcon icon={faPen} className="mr-2 w-3 h-3" />
              Edit
            </button>
            <div ref={sendOptionsRef} className="relative">
              <button className="px-4 py-2 border-r text-sm mr-2" onClick={handleSendClick}>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2 w-3 h-3" />
                Send
              </button>
              {showSendOptions && (
                <div className="absolute left-0 top-full mt-1 bg-white border rounded shadow-lg z-10">
                  <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    Email
                  </button>
                  <button className="text-sm hover:bg-blue-500 hover:text-white text-center hover:rounded-md p-1 w-full">
                    <FontAwesomeIcon icon={faSms} className="mr-2" />
                    SMS
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Payment Content */}
        <div className="w-full h-full bg-white rounded-xs overflow-hidden">
  <div className="flex flex-col h-[80vh] overflow-hidden">
    <div className="overflow-y-auto flex-1 p-6">
      {selectedPayment && (
        <>
          <h1 className="text-lg mt-4 px-4 font-semibold mb-0">{selectedPayment.status}</h1>
          <div className="mt-4">
            <PaymentPdfViewer payment={selectedPayment} />
          </div>
        </>
      )}
    </div>
  </div>
</div>


      </div>
    </div>
  );
};

export default PaymentReceivedDetails;
