import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faArrowRight, faHeadset } from '@fortawesome/free-solid-svg-icons';

const SummaryFooter = ({ onCallBack, onInterested, }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-[0_-8px_30px_rgb(0,0,0,0.12)] z-50">
      <div className="max-w-lg mx-auto px-4 py-3">
        {/* Price Summary */}
       {/*  <div className="flex items-center justify-between mb-3 px-1">
          <div className="flex flex-col">
            <span className="text-xs text-gray-500">Total Amount</span>
            <span className="text-lg font-bold text-[#EA011F]">
              ₹{totalPrice?.toLocaleString('en-IN') || '0'}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <button className="p-2 rounded-full bg-green-50 hover:bg-green-100 transition-colors">
              <FontAwesomeIcon icon={faWhatsapp} className="text-green-600 text-lg" />
            </button>
            <button className="p-2 rounded-full bg-red-50 hover:bg-red-100 transition-colors">
              <FontAwesomeIcon icon={faPhone} className="text-[#EA011F] text-lg" />
            </button>
          </div>
        </div> */}

        {/* Action Buttons */}
        <div className="flex items-center gap-3">
          <button 
            onClick={onCallBack}
            className="flex-1 group relative overflow-hidden rounded-xl bg-gradient-to-r from-red-50 to-white border border-red-100"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-red-100 to-transparent transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"/>
            <div className="relative flex items-center justify-center gap-2 py-3">
              <div className="w-7 h-7 rounded-full bg-white flex items-center justify-center shadow-sm">
                <FontAwesomeIcon icon={faHeadset} className="text-[#EA011F] text-sm" />
              </div>
              <div className="flex flex-col items-start">
                <span className="text-sm font-medium text-gray-700">Request Callback</span>
                <span className="text-xs text-gray-500">Get expert assistance</span>
              </div>
            </div>
          </button>

          <button
            onClick={onInterested}
            className="flex-1 relative overflow-hidden rounded-xl bg-gradient-to-r from-[#EA011F] to-red-600 hover:from-red-600 hover:to-[#EA011F] transform hover:scale-[1.02] transition-all duration-300"
          >
            <div className="py-3 px-4">
              <div className="flex items-center justify-between">
                <div className="flex flex-col items-start"
              >
                  <span className="text-sm font-medium text-white">I'm Interested</span>
                  <span className="text-xs text-red-100">Mark your intrest</span>
                </div>
                <div className="w-7 h-7 rounded-full bg-white/10 flex items-center justify-center">
                  <FontAwesomeIcon 
                    icon={faArrowRight} 
                    className="text-white text-sm group-hover:translate-x-1 transition-transform" 
                  />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummaryFooter;
