import React, { useRef ,useState, useEffect, useCallback  } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlusCircle, faQuestionCircle, faPaperclip,faTrash, faX, faExclamationTriangle, faCalendar, faTriangleExclamation, faImage, faChevronLeft, faUser, faFileInvoice, faMoneyBill, faMoneyBillTransfer, faCreditCard, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Loader, Spinner } from '../Loader/Loader';
import { Msg200or201, Msg204, Msg400, Msg401 ,Msg403, Msg500 } from '../Messages/Messages'
import TableSearchDropDown from '../Components/TableSearchDropDownForSalesOrder';
import TableSearchForAreaName from '../Components/TabelSerchForAreaName'
import  '../Css/Tooltip.css';
import {useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { layer } from '@fortawesome/fontawesome-svg-core';

// Custom hook to handle outside clicks
const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};

const CustomDropdown = ({ options, selected, onSelect, showAddNew, placeholder, showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const dropdownRef = React.useRef(null);
  const searchInputRef = useRef(null);
  

  const flattenOptions = (options) => {
    return options.reduce((acc, option) => {
      if (option.children) {
        return [
          ...acc,
          { ...option, isParent: true },
          ...option.children.reduce((childAcc, child) => {
            if (child.details) {
              return [
                ...childAcc,
                { ...child, isSubParent: true },
                ...child.details.map(detail => ({ ...detail, parentValue: child.value }))
              ];
            }
            return [...childAcc, { ...child, parentValue: option.value }];
          }, [])
        ];
      }
      return [...acc, option];
    }, []);
  };

  const filteredOptions = flattenOptions(options).filter(option => {
    if (!option || !option.label) return false;
    return option.label.toLowerCase().includes(searchTerm.toLowerCase());
  });


  const renderOption = (option) => {
    const className = `p-2 cursor-pointer text-sm 
      ${option.isParent ? 'font-bold bg-gray-100' : ''} 
      ${option.isSubParent ? 'pl-8 font-semibold' : ''} 
      ${!option.isParent && !option.isSubParent ? 'pl-12' : ''}
      ${focusedOptionIndex === option.value ? 'bg-blue-500 text-white' : ''} 
      hover:bg-blue-500 hover:text-white`;
  
    return (
      <div
        key={option.value}
        className={className}
        onClick={() => !option.isParent && handleOptionClick(option)}
      >
        {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
        {option.label}
      </div>
    );
  };
  

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
    setFocusedOptionIndex(null);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return; // Only handle keydown events when the dropdown is open
  
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? 0 : Math.min(prevIndex + 1, filteredOptions.length - 1);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex(prevIndex => {
          const newIndex = prevIndex === null ? filteredOptions.length - 1 : Math.max(prevIndex - 1, 0);
          scrollToOption(newIndex);
          return newIndex;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== null) {
          const selectedOption = filteredOptions[focusedOptionIndex];
          handleOptionClick(selectedOption);
        }
        break;
      default:
        break;
    }
  };

  const scrollToOption = (index) => {
    const optionElements = dropdownRef.current.querySelectorAll('div[data-index]');
    if (optionElements && optionElements[index]) {
      optionElements[index].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };


  useOutsideClick(dropdownRef, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);


  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex justify-between items-center p-2 border bg-white border-gray-300 rounded-md cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
  role="button" // Add role prop
        onKeyDown={(e) => isOpen && handleKeyDown(e)} // Only call handleKeyDown when the dropdown is open
      >
        <span className={`text-sm ${selected ? 'text-black' : 'text-[#838195]'}`}>
          {selected?.label || placeholder} {/* Use selected.label if selected is an object */}
        </span>
        <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3 text-gray-500" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full max-h-40 bg-white border border-gray-300 rounded-md shadow-md z-10 flex flex-col">
          {showSearch && (
            <input
              type="text"
              className="p-1 border rounded-md border-blue-500 focus:outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={isOpen}
            />
          )}
          <div className="overflow-y-auto scrollbar-visible flex-grow">
          {filteredOptions.length > 0 ? (
  filteredOptions.map((option, index) => (
    <div
      key={option.value}
      data-index={index}
      className={`p-2 cursor-pointer text-sm 
        ${option.isParent ? 'font-bold' : ''} 
        ${option.children ? '' : option.parentValue ? 'pl-8' : ''}
        ${index === focusedOptionIndex ? 'bg-blue-500 text-white' : ''} 
        hover:bg-blue-500 hover:text-white`}
      onClick={() => handleOptionClick(option)}
    >
      {option.icon && <FontAwesomeIcon icon={option.icon} className="mr-2" />}
      {option.label}
    </div>
  ))
            ) : (
              <div className="p-2 text-sm text-gray-500">
                Not available
              </div>
            )}
          </div>
          {showAddNew && (
            <div
              className="p-2 cursor-pointer text-sm text-blue-500 border-t border-gray-300"
              onClick={() => handleOptionClick({value: 'add-new', label: 'Add New'})}
            >
              {options.find(option => option.value === 'add-new')?.icon && (
                <FontAwesomeIcon
                  icon={options.find(option => option.value === 'add-new').icon}
                  className="mr-2"
                />
              )}
              {options.find(option => option.value === 'add-new')?.label}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EditPaymentReceived = () => {
  const navigate = useNavigate ();

  const location = useLocation();

const paymentId = location.state?.PaymentID;

console.log("paymentId", paymentId);  

  const paymentModeOptions = [
    { label: "Bank Remittance", value: "bankremittance" },
    { label: "Bank Transfer", value: "bank_transfer" },
    { label: "Cash", value: "cash" },
    { label: "Credit Card", value: "creditcard" },
    { label: "UPI", value: "upi" }
  ];
  
  // Add these sample deposit options  
  const depositToOptions = [
    {
      label: "Bank",
      value: "banks",
      children: [
            { label: "[50200064957841] Harini", value: "banks" },
            { label: "[50200064957842] Pavani", value: "banks" }
      ]
    },
    {
      label: "Cash",
      value: "cash",
      children: [
        { label: "Petty Cash", value: "petty_cash" },
        { label: "Undeposited Funds", value: "undeposited" },
        
      ]
    },
    {
        label: "Other Current Liability",
        value: "cash",
        children: [
          { label: "Employee Reimbursement", value: "employee_reimbursement" },
          { label: "Opening Balance Adjastements", value: "opening_balance_adjustments" },
          {label: 'TDS Payable', value: 'tds_payable'},
        ]
      }
  ];
  

  const [amountReceived, setAmountReceived] = useState('');
const [bankCharges, setBankCharges] = useState('');
const [paymentNumber, setPaymentNumber] = useState('');
const [selectedPaymentMode, setSelectedPaymentMode] = useState({ label: "Cash", value: "cash" });
const [selectedDepositTo, setSelectedDepositTo] = useState( { label: "Petty Cash", value: "petty_cash" });
const [referenceNumber, setReferenceNumber] = useState('');
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
//  console.log(selectedCustomer);
  const [salesorderDate, setsalesorderDate] = useState(new Date());
  const [productCategories, setProductCategories] = useState([]);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const FetchProjectsUrl = process.env.REACT_APP_FORE_APILINK; //measurit  
const imageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES; 
  const [isLoading, setIsLoading] = useState(true);
  const { 
    showPopup, popupMessage, popupType,retryFunction, handleGetResponse, handleSaveResponse, setShowPopup,
    isSaving, setRetryFunction, handleDeleteResponse,  setIsSaving ,    setPopupMessage,
    setPopupType } = useCommonResponseHandler();

    const RETRY_LIMIT = 3; // Maximum number of retries
    const [retryCount, setRetryCount] = useState(0); // Track retry attempts
    const [customersLoaded, setCustomersLoaded] = useState(false);
const [roleId, setRoleId] = useState(null);
const [salesErrorMessages, setSalesErrorMessages] = useState([]);
const [taxDeducted, setTaxDeducted] = useState('no');
const [Invoices,setInvoices] = useState([]);

  const [totalPayment, setTotalPayment] = useState(0);
  const [showAttachmentOptions, setShowAttachmentOptions] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [showAttachmentList, setShowAttachmentList] = useState(false);
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [activeTab, setActiveTab] = useState('details');
  const [activePaymentTab, setActivePaymentTab] = useState('invoice');
  const [selectedTax, setSelectedTax] = useState(null);

// Add this with your other options constants
const taxOptions = [
  { label: "IGST0 [0%]", value: "igst0" },
  { label: "IGST3 [3%]", value: "igst3" },
  { label: "IGST5 [5%]", value: "igst5" },
  { label: "IGST12 [12%]", value: "igst12" },
  { label: "IGST18 [18%]", value: "igst18" },
  { label: "IGST28 [28%]", value: "igst28" }
];




    useEffect(() => {
      fetchRoleId();
      fetchAllApprovedCustomers();
    }, [fetchUrl]);


    const fetchRoleId = () => {
      const roleId = JSON.parse(sessionStorage.getItem('roleId'));
      setRoleId(roleId);
    };
  
    const categoriesToDisplay = roleId === 6 
    ? productCategories.filter(category => (category.product_category_name.toLowerCase()) === 'tiles')
    : productCategories;


      
    const fetchAllApprovedCustomers = async (attempt = 1) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(fetchUrl + `customer/get-all-approved-customers?organizationId=${sessionStorage.getItem('organizationId')}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
        const data = await response.json();
        const output = await handleGetResponse(response, data);
        console.log(output);
        if (output) {
          // Format data for dropdown
          const formattedCustomers = output.map(customer => ({
            id: customer._id,  // Use customer ID as value
            label: customer.customer_name, // Use customer name as label
            value: customer.customer_name,
            company_name: customer.company_name,
        email: customer.email,
        gstin: customer.gstin,
        pan: customer.pan,
        type_of_firm: customer.type_of_firm,
        mobile_number: customer.mobile_number,
        city: customer.city,
        state: customer.state,
        association: customer.association,
        address: customer.address,
        total_orders_amount: customer.total_orders_amount
          }));
          setCustomers(formattedCustomers); // Update state with formatted data
         // console.log(formattedCustomers);
         setCustomersLoaded(true);
        } else {
          handleFetchError(response, attempt, fetchAllApprovedCustomers);
        }
      } catch (error) {
        handleErrorWithRetry(error, attempt, fetchAllApprovedCustomers);
      }
    };

   // Centralized error handling
const handleFetchError = (response, attempt, retryFunction) => {
  if (response.status === 500) {
    setRetryFunction(() => retryFunction); 
  }
};
    
    const handleErrorWithRetry = (error, attempt, retryFunction, ...args) => {
      console.error('Failed to fetch:', error);
    
      if (attempt < RETRY_LIMIT) {
        setRetryCount(attempt + 1);
        setTimeout(() => retryFunction(...args, attempt + 1), 1000);
      } else {
        setIsLoading(false);
        setPopupMessage('Internal Server Error. Kindly Retry.');
        setPopupType('500');
        setShowPopup(true);
        setRetryFunction(() => () => retryFunction(...args, attempt));
      }
    };
   
  const handleDateChange = (date) => {
    setsalesorderDate(date);
  };


  const handleCustomerSelect = (option) => { 
    setSelectedCustomer(option); // Set the selected option object
  };

  const handleTaxDeductionChange = (value) => {
    setTaxDeducted(value);
  };

  const handlePaymentChange = (value, index) => {
    const newPayment = parseFloat(value) || 0;
    
    // Create a copy of current invoices
    const updatedInvoices = [...Invoices];
    updatedInvoices[index] = {
        ...updatedInvoices[index],
        payment_amount: newPayment
    };
    
    // Update invoices state
    setInvoices(updatedInvoices);
    
    // Calculate total payment across all invoices
    const total = updatedInvoices.reduce((sum, invoice) => 
        sum + (invoice.payment_amount || 0), 0);
    setTotalPayment(total);
};


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      setAttachments([...attachments, file]);
    } else {
      alert('File size exceeds 5MB limit');
    }
  };

  const closePopup = () => {
    console.log("closePopup called");
    setShowPopup(false);
  };
  
  const Retry = async() => {
    console.log("Retry called");
    setTimeout(async ()=>{
      if(retryFunction){

        await retryFunction ();
      }

      setIsLoading(false)
    },1000)
    
    setShowPopup(false);
  };
  
  const BackToLoginPage = () => {
    console.log("Back To Login Page");
    setShowPopup(false);
    localStorage.removeItem('LoginData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('appId');
  navigate('/bb');
  };

  const handleStayHere = () => {
    setShowCancelPopup(false);
  }

  const handleCancel = () => {
    setShowCancelPopup(true);
  }

  const handleLeave = () => {
    navigate(-1);
  }

//   if (isLoading) {
//     return <Loader />;
//   }


useEffect(() => {
  const fetchPaymentData = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');  
    try {
      const response = await fetch(`${fetchUrl}payments/get-payment?payment_id=${paymentId}`, {
        method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
      });
      
      if (response.ok) {
        const responseData = await response.json();
        const data = responseData.data;

        console.log(data);

        // Map payment data to state variables
        setPaymentNumber(data?.payment_number);
        setAmountReceived(data?.amount?.toString());
        setBankCharges(data?.bank_charges?.toString());
        
        // Format payment date
        const parsedPaymentDate = data.payment_date ? new Date(data.payment_date) : new Date();
        setsalesorderDate(parsedPaymentDate);

        // Map payment mode
        setSelectedPaymentMode({
          label: data.payment_mode.charAt(0).toUpperCase() + data.payment_mode.slice(1),
          value: data.payment_mode
        });

        // Map customer data
        const customerData = {
          id: data.customer_id._id,
          label: data.customer_id.customer_name,
          value: data.customer_id._id,
          email: data.customer_id.email,
          mobile_number: data.customer_id.mobile_number,
          gstin: data.customer_id.gstin,
          pan: data.customer_id.pan
        };
        setSelectedCustomer(customerData);

        // Map invoices if any
        if (data.invoices && data.invoices.length > 0) {
          setInvoices(data.invoices);
        }

        // Map attachments if any
        if (data.files && data.files.length > 0) {
          setAttachments(data.files);
        }
      }
    } catch (error) {
      console.log('Error fetching payment data:', error);
      setPopupMessage('Error fetching payment data');
      setPopupType('400');
    } finally {
      setIsLoading(false);
    }
};

  


  if (paymentId) {
    fetchPaymentData();
  }
}, [paymentId]);


const handleSavePayment = async () => {
  setIsSaving(true);
  try {
    const paymentData = {
      customer_id: selectedCustomer.id,
      payment_number: paymentNumber,
      payment_date: salesorderDate,
      payment_mode: selectedPaymentMode.value,
      amount: parseFloat(amountReceived),
      bank_charges: parseFloat(bankCharges) || 0,
      status: "Draft",
      invoices: Invoices,
      organizationId: sessionStorage?.getItem('organizationId'),
      tax_deducted: taxDeducted === 'yes',
      deposit_to: selectedDepositTo.value,
      reference_number: referenceNumber
    };

    const response = await fetch(`${fetchUrl}payments/update-payment?payment_id=${paymentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(paymentData)
    });

    const data = await response.json();
    
    if (response.ok) {
      setPopupMessage('Payment updated successfully');
      setPopupType('200or201');
      navigate('/bb/app/sales/paymentreceivedlist');
    } else {
      throw new Error(data.message || 'Error updating payment');
    }
  } catch (error) {
    setPopupMessage(error.message || 'Error updating Payment');
    setPopupType('400');
  } finally {
    setIsSaving(false);
  }
};

const handlePayInFull = (index) => {
  const invoice = Invoices[index];
  const updatedInvoices = [...Invoices];
  
  updatedInvoices[index] = {
      ...invoice,
      payment_amount: invoice.balance_amount
  };
  
  setInvoices(updatedInvoices);
  
  const total = updatedInvoices.reduce((sum, inv) => 
      sum + (inv.payment_amount || 0), 0);
  setTotalPayment(total);
};


  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
            <div className="w-full h-16 border-b">
  <div className="flex w-full px-4 h-full">
    <div className="flex items-end space-x-6 h-full">
      <button
        className={`px-4 h-[calc(100%-1px)] text-sm font-medium focus:outline-none relative ${
          activePaymentTab === 'invoice'
          ? 'text-[#4c526c] border-b-2 border-[#4c526c]'
          : 'text-gray-500 hover:text-[#4c526c]'
        }`}
        onClick={() => setActivePaymentTab('invoice')}
      >
        Invoice Payment
      </button>
{/*       <button
        className={`px-4 h-[calc(100%-1px)] text-sm font-medium focus:outline-none relative ${
          activePaymentTab === 'advance'
          ? 'text-[#4c526c] border-b-2 border-[#4c526c]'
          : 'text-gray-500 hover:text-[#4c526c]'
        }`}
        onClick={() => setActivePaymentTab('advance')}
      >
        Customer Advance
      </button> */}
    </div>
  </div>
</div>

              
              {salesErrorMessages.length > 0 && (
  <div className="flex justify-between items-center px-auto py-3 bg-[#fddcde] rounded-md">
    <div className="px-6 flex flex-col justify-start items-start">
      {salesErrorMessages.map((errorMessage, index) => (
        <span key={index} className="text-sm">
          • {errorMessage}
        </span>
      ))}
    </div>
    <div className='px-4'>
      <button
        className="text-gray-700 hover:text-red-500"
        // onClick={handleCloseSalesError}
      >
        <FontAwesomeIcon icon={faX} className="text-xs text-[#F7525A] cursor-pointer" />
      </button>
    </div>
  </div>
)}


              <div className="flex flex-col justify-start items-start w-full flex-grow h-80 overflow-y-auto">
                <div className="w-full flex flex-col justify-start items-start px-4 bg-[#F9F9FB]">
                  <div className='w-full flex flex-row'>
                    <div className="flex flex-col w-full">
                    <div className="w-full flex flex-row items-center mb-4 mt-5">
  <label htmlFor="customername" className="block text-sm font-regular text-[#e54643] w-1/6">
    Customer Name<span className="text-red-500">*</span>
  </label>
  <div className='w-1/3'>
    <CustomDropdown
      options={customers}
      selected={selectedCustomer}
      onSelect={handleCustomerSelect}
      placeholder='Select Customer'
      showAddNew={false}
      showSearch={true}
    />
  </div>
  {selectedCustomer && (
  <div
    className="ml-auto z-10 flex items-center space-x-2 cursor-pointer hover:opacity-90 px-3 py-2 rounded-l bg-[#4c526c]"
    onClick={() => setShowCustomerDetails(true)}
  >
    <span className="text-sm font-medium text-white">{selectedCustomer.label}</span>
    <FontAwesomeIcon icon={faChevronLeft} className="text-white text-sm" />
  </div>
)}

</div>

                      
                    </div>
                  </div>
                  </div>
                  <div className="w-full flex flex-col justify-start items-start px-4">
                  <div className='w-full flex flex-row'>
                  <div className="flex flex-col w-full">
                  <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Amount Received<span className="text-red-500">*</span>
                      </label>
                      <input
  type="text"
  value={amountReceived}
  onChange={(e) => setAmountReceived(e.target.value)}
  className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
                    </div>
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                       Bank charges (if any)
                      </label>
                      <input
  type="text"
  value={bankCharges}
  onChange={(e) => setBankCharges(e.target.value)} 
  className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
                    </div>  
                    {activePaymentTab === 'advance' && (
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                       Tax
                      </label>
                      <div className='w-1/3'>
                      <CustomDropdown
      options={taxOptions}
      selected={selectedTax}
      onSelect={setSelectedTax}
      placeholder='Select Tax'
      showAddNew={false}
      showSearch={true}
    />
  </div>
                    </div>  
                    )}


                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="salesorderDate" className="block text-sm font-regular text-[#e54643] w-1/6">
                            Payment Date<span className="text-red-500">*</span>
                      </label>
                      <DatePicker
                        selected={salesorderDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="w-full outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
                      />
                    </div>
                     <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                        Payment # <span className="text-red-500">*</span>
                      </label>
                      <input
  type="text"
  value={paymentNumber}
  onChange={(e) => setPaymentNumber(e.target.value)}
  className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>

                    </div> 
                    
                    <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                  Payment Mode
                </label>           
                    <div className='w-1/3'>
                    <CustomDropdown
  options={paymentModeOptions}
  selected={selectedPaymentMode}
  onSelect={setSelectedPaymentMode}
  placeholder="Select Payment Mode"
  showAddNew={false}
  showSearch={true}
/>
                    </div>
                    
              </div>
              
              <div className=" w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                <label htmlFor="productCategoryName" className="block text-sm font-regular text-[#e54643] w-1/6">
                 Deposite To <span className="text-red-500">*</span>
                </label> 
                <div className='w-1/3'>          
                <CustomDropdown
  options={depositToOptions}
  selected={selectedDepositTo}
  onSelect={setSelectedDepositTo}
  placeholder="Select Deposit Account"
  showAddNew={false}
  showSearch={true}
/>
  </div>
              </div>
              <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
                      <label htmlFor="productSubCategoryName" className="block text-sm font-regular text-gray-700 w-1/6">
                        Reference# 
                      </label>
                      <input
  type="text"
  value={referenceNumber}
  onChange={(e) => setReferenceNumber(e.target.value)}
  className="w-1/3 outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 p-2 text-sm text-gray-700"
/>
                    </div> 
                    {activePaymentTab === 'invoice' && (
                    <div className="w-full flex flex-row items-center mb-4 mt-5 space-x-2">
  <label htmlFor="taxDeduction" className="block text-sm font-regular text-gray-700 w-1/6">
    Tax deducted?
  </label>
  <div className="flex space-x-4 w-1/3">
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="taxDeduction"
        value="no"
        checked={taxDeducted === 'no'}
        onChange={() => handleTaxDeductionChange('no')}
        className="form-radio text-blue-500"
      />
      <span className="ml-2 text-sm text-gray-700">No tax deducted</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        name="taxDeduction"
        value="yes"
        checked={taxDeducted === 'yes'}
        onChange={() => handleTaxDeductionChange('yes')}
        className="form-radio text-blue-500"
      />
      <span className="ml-2 text-sm text-gray-700">Yes, TDS (Income tax)</span>
    </label>
  </div>
</div> 
                    )}
                  </div>
                </div>
               
              </div>
              {activePaymentTab === 'invoice' && (
              <div className="w-full flex flex-col justify-start items-start px-4">
  <div className='w-full flex flex-row'>
    <div className="flex flex-col w-full">
      <div className="w-full container mx-auto px-4 mb-2 mt-4">
        <div className='border-b bg-[#fafafc]'>
          <div className="flex justify-between items-center py-2 px-2">
            <div className="flex items-center space-x-4">
              <h2 className="text-lg font-semibold">Unpaid Invoices</h2>
              <span className="text-gray-300 mx-2">|</span>
              <div className="flex items-center text-sm text-gray-600 cursor-pointer">
                <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                <span>Filter by Date Range</span>
                <FontAwesomeIcon icon={faChevronDown} className="ml-2 w-3 h-3" />
              </div>
            </div>
            <span className="text-blue-500 text-sm cursor-pointer hover:text-blue-700">
              Clear Applied Amount
            </span>
          </div>
          
        </div>
        {activePaymentTab === 'invoice' && (
        <div className="px-4 py-2">
            <table className="w-full">
              <thead>
                <tr className="border-b">
                  <th className="text-left py-2 text-sm font-semibold text-gray-600">Date</th>
                  <th className="text-left py-2 text-sm font-semibold text-gray-600">Invoice Number</th>
                  <th className="text-right py-2 text-sm font-semibold text-gray-600">Invoice Amount</th>
                  <th className="text-right py-2 text-sm font-semibold text-gray-600">Amount Due</th>
                  <th className="text-right py-2 text-sm font-semibold text-gray-600">Payment</th>
                </tr>
              </thead>
              <tbody>
              {Invoices.map((invoice, index) => (
    <tr key={index} className="border-b">
        <td className="py-2 text-sm align-top">{invoice?.date}</td>
        <td className="py-2 text-sm align-top">{invoice?.invoice_number}</td>
        <td className="py-2 text-sm text-right align-top">{invoice?.amount_applied}</td>
        <td className="py-2 text-sm text-right align-top">₹{invoice?.balance_amount.toFixed(2)}</td>
        <td className="py-2 text-sm text-right align-top flex flex-col items-end">
            <input
                type="text"
                value={invoice.payment_amount || ''}
                className="w-24 p-1 text-right outline-none block rounded-md border border-gray-300 hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm text-gray-700"
                placeholder="0.00"
                onChange={(e) => handlePaymentChange(e.target.value, index)}
            />
            <span 
                className="text-blue-500 text-xs cursor-pointer mt-1"
                onClick={() => handlePayInFull(index)}
            >
                Pay in Full
            </span>
        </td>
    </tr>
))}

</tbody>
            </table>
            <div className="flex justify-between mt-4">
    <div className="text-xs text-gray-500 italic">
      **List contains only SENT invoices
    </div>
    <div className="flex justify-end space-x-20">
      <div className="text-right">
        <div className=" text-sm">Total</div>
        {/* <div className="text-sm">₹{totalAmountDue.toFixed(2)}</div> */}
      </div>
      <div className="text-right">
        {/* <div className="font-semibold text-sm">Amount to apply</div> */}
        <div className="text-sm">0.00</div>
      </div>
    </div>
  </div>
</div>
        )}
  </div>
    </div>
    </div>
        </div>
              )}
   
   {activePaymentTab === 'invoice' && (
              <div className='pt-2 px-6 py-6 flex flex-row justify-end w-full'>
             
              <div className={`p-6 space-y-6 flex flex-col justify-between rounded-md w-1/2 ${totalPayment > 0 ? 'bg-[#fef4ea]' : 'bg-[#F7F7FE]'}`}>
              <div className='flex justify-between'>
                    <div>
                <p className='text-sm'>Amount Received:</p>
                </div>
                <div className="text-sm">₹{amountReceived}</div>
                </div>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm'>Amount used for payments:</p>
                </div>
                <div className="text-sm">₹{totalPayment}</div>
                </div>
                <div className='flex justify-between'>
                    <div>
                <p className='text-sm '>Amount Refunded:</p>
                </div>
                <div className="text-sm">₹0.00</div>
                </div>
                <div className='flex justify-between'>
  <div className='flex items-center'>
  <FontAwesomeIcon 
        icon={faTriangleExclamation} 
        className={`${totalPayment > 0 ? 'text-yellow-500' : 'text-red-500'} mr-2`} 
      />
    <p className='text-sm'>Amount in Excess:</p>
  </div>
  <div className="text-sm">₹{(amountReceived - totalPayment).toFixed(2)}</div>
</div>                
                </div>
                </div>
   )}

                <div className="px-6 mb-4 w-full">
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Notes <span className="text-gray-500 font-normal">(Internal use. Not visible to customer)</span>
  </label>
  <textarea
    className="w-full h-14 p-2 border border-gray-300 rounded-md outline-none hover:border-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
    // placeholder="Add notes here..."
  />
</div>
<div className="px-6 mb-10 flex items-start space-x-2">
  <div className="flex-1">
    <label className="block text-sm font-medium text-gray-700 mb-2">
      Attachments
    </label>
    <div className="relative">
      <input
        type="file"
        className="hidden"
        onChange={handleFileUpload}
        id="fileUpload"
      />
      <div className="inline-flex items-center space-x-2 border border-dashed border-gray-300 rounded-md">
        <button 
          className="flex items-center px-3 py-2 text-sm text-gray-700"
          onClick={() => document.getElementById('fileUpload').click()}
        >
          <FontAwesomeIcon icon={faPaperclip} className="mr-2" />
          Upload File
        </button>
        <span className="text-gray-300">|</span>
        <div>
          <button
            className="flex items-center text-gray-700 hover:bg-gray-50 px-2 py-2 rounded-md"
            onClick={() => setShowAttachmentOptions(!showAttachmentOptions)}
          >
            <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3" />
          </button>
        </div>
      </div>
      {showAttachmentOptions && (
        <div className="absolute mt-1 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
          <div className="py-1">
            <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              Attach from Desktop
            </button>
            <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              Attach from Documents
            </button>
          </div>
        </div>
      )}
    </div>
    <span className="text-xs text-gray-500 mt-1 block">You can upload a maximum of 5 files, 5MB each</span>
  </div>

  <div className="mt-8">
  <div className="relative">
    <FontAwesomeIcon
      icon={faPaperclip}
      className="text-gray-600 text-lg cursor-pointer"
      onClick={() => setShowAttachmentList(!showAttachmentList)}
    />
    {attachments.length > 0 && (
      <span className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
        {attachments.length}
      </span>
    )}
    {showAttachmentList && attachments.length > 0 && (
      <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-10">
        <div className="py-2">
          {attachments.map((file, index) => (
            <div key={index} className="px-4 py-2 text-sm text-gray-700 flex items-center">
              <FontAwesomeIcon icon={faImage} className="mr-2 text-blue-500" />
              {file.name}
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
</div>
</div>

</div>


{/* Sidebar component */}
<div className={`fixed right-0 top-12 z-20 h-[calc(100vh-48px)] w-96 bg-white shadow-lg transform transition-transform duration-300 ${showCustomerDetails ? 'translate-x-0' : 'translate-x-full'}`}>
  <div className="flex flex-col h-full">
  <div className="p-4 border-b flex justify-between items-center bg-white">
  <div className="flex items-center">
    <div className="w-10 h-10 rounded-lg bg-[#E9EBF3] flex items-center justify-center mr-3">
      <span className="text-[#9A98A8] font-medium">
        {selectedCustomer?.label?.charAt(0).toUpperCase()}
      </span>
    </div>
    <div>
      <span className="text-xs text-gray-500 block">Customer</span>
      <h2 className="font-bold text-[#4c526c]">{selectedCustomer?.label}</h2>
    </div>
  </div>
  <button onClick={() => setShowCustomerDetails(false)}>
    <FontAwesomeIcon icon={faX} className="text-gray-500 text-sm" />
  </button>
</div>


    <div className="border-b ">
      <div className="flex">
        <button
          className={`px-6 py-3 text-sm font-medium focus:outline-none ${
            activeTab === 'details' 
            ? 'text-[#4c526c] border-b-2 border-[#4c526c]' 
            : 'text-gray-500 hover:text-[#4c526c]'
          }`}
          onClick={() => setActiveTab('details')}
        >
          Details
        </button>
        <button
          className={`px-6 py-3 text-sm font-medium focus:outline-none ${
            activeTab === 'activity' 
            ? 'text-[#4c526c] border-b-2 border-[#4c526c]' 
            : 'text-gray-500 hover:text-[#4c526c]'
          }`}
          onClick={() => setActiveTab('activity')}
        >
          Activity Log
        </button>
      </div>
    </div>

    <div className="flex-1 overflow-y-auto bg-gray-50">
  {activeTab === 'details' ? (
    <div className="p-6">
      <div className="bg-white border border-gray-300 p-4 rounded-md mb-6 ">
        <div className="flex justify-between items-center relative">
          <div className="text-center flex-1">
            <div className="w-10 h-10 flex items-center justify-center mx-auto mb-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 text-sm" />
            </div>
            <div>
              <label className="text-xs text-gray-500 block">Outstanding Receivables</label>
              <p className="text-sm font-medium text-[#4c526c] mt-1">₹25,000.00</p>
            </div>
          </div>
          
          <div className="h-full w-[1px] bg-gray-300 absolute left-1/2 top-1/2 transform -translate-y-1/2"></div>
          
          <div className="text-center flex-1">
            <div className="w-10 h-10 flex items-center justify-center mx-auto mb-2">
              <FontAwesomeIcon icon={faCreditCard} className="text-[#4caf50] text-sm" />
            </div>
            <div>
              <label className="text-xs text-gray-500 block">Unused Credits</label>
              <p className="text-sm font-medium text-[#4c526c] mt-1">₹0.00</p>
            </div>
          </div>
        </div>
      </div>



          <div className="space-y-5 overflow-y-auto h-full">
            <div>
              <label className="text-xs text-gray-500 block mb-1">Customer Name</label>
              <p className="text-sm text-[#4c526c]">{selectedCustomer?.label}</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">Company Name</label>
              <p className="text-sm text-[#4c526c]">{selectedCustomer?.company_name}</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">Email</label>
              <p className="text-sm text-[#4c526c]">{selectedCustomer?.email}</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">GSTIN</label>
              <p className="text-sm text-[#4c526c]">{selectedCustomer?.gstin}</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">Pan</label>
              <p className="text-sm text-[#4c526c]">{selectedCustomer?.pan}</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">Currency</label>
              <p className="text-sm text-[#4c526c]">Indian Rupee (₹)</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">Payment Terms</label>
              <p className="text-sm text-[#4c526c]">Due on Receipt</p>
            </div>
            <div>
              <label className="text-xs text-gray-500 block mb-1">Type of Firm</label>
              <p className="text-sm text-[#4c526c]">{selectedCustomer?.type_of_firm}</p>
            </div>
            
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="space-y-6">
            <div className="flex items-start">
              <div className="w-8 h-8 rounded-full bg-[#e8f3ff] flex items-center justify-center">
                <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-[#2995ff] text-sm" />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm text-[#4c526c]">Payment of ₹25,000.00 received</p>
                <div className="flex items-center mt-1">
                  <span className="text-xs text-gray-500">by Admin</span>
                  <span className="mx-2 text-gray-300">•</span>
                  <span className="text-xs text-gray-500">Mar 15, 2024 10:30 AM</span>
                </div>
              </div>
            </div>

            <div className="flex items-start">
              <div className="w-8 h-8 rounded-full bg-[#fff4e5] flex items-center justify-center">
                <FontAwesomeIcon icon={faFileInvoice} className="text-[#ffa726] text-sm" />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm text-[#4c526c]">Invoice INV-001 created</p>
                <div className="flex items-center mt-1">
                  <span className="text-xs text-gray-500">by System</span>
                  <span className="mx-2 text-gray-300">•</span>
                  <span className="text-xs text-gray-500">Mar 14, 2024 02:15 PM</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
</div>
              <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
          <button className="px-2 py-1 bg-[#f7525a] text-white border border-[#DDDDDD] rounded-md"
          onClick={handleSavePayment}
           >
             Save as Draft {isSaving && <Spinner />}
            </button>
           
            <button onClick={handleCancel}  className={`hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50 px-2 py-1 rounded-md ${
          isSaving ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD]'
        }`}
        disabled={isSaving}>
          Cancel</button>
       </div>
      </div>
            </div>
          </div>
        </div>
      </div>

{/* pop up starts */}



{/* pop up starts */}


  



{showCancelPopup && (
    <div className="fixed inset-0 flex items-top justify-center bg-gray-800 bg-opacity-50 ">
    <div className="bg-white rounded-b-md shadow-md w-[550px] h-[250px] flex flex-col" style={{ maxWidth: '550px', maxHeight: '250px' }}>
    <div className='bg-[#F9F9FB] flex justify-between items-center w-full h-14 px-6'>
    <h2 className="text-md font-semibold">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl text-yellow-500 mr-4 " />
      Leave this page?</h2>
      <button onClick={handleStayHere} className="text-white px-1 py-1 rounded-md ">   
        <FontAwesomeIcon icon={faX} className="text-xs text-[#212529] cursor-pointer " />
      </button>
    </div>
      <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
      <div className='pt-4 pl-4 '>
    <div className="w-full items-center p-5">
      <label htmlFor="Other_details" className="block text-sm font-regular text-[#212529] ">
      If you leave, your unsaved changes will be discarded.
      </label>
    </div>  
  </div>
      <div className="mt-auto w-full">
        <hr className="border-t border-gray-200 w-full" style={{ borderTopWidth: '1px' }} />
        <div className="text-sm flex justify-start items-end space-x-2 px-6 mt-5 mb-4">
        <button className="px-2 py-1 bg-[#408dfb] border-[#f7525a] text-white rounded-md" onClick={handleStayHere}>
  Stay Here  {isSaving && (
      <Spinner/>
    )}
  </button>
          <button onClick={handleLeave} 
          className="hover:border-[#DDDDDD] focus:border-[#DDDDDD] focus:ring focus:ring-[#DDDDDD] focus:ring-opacity-50  px-2 py-1 bg-[#f5f5f5] text-gray-700 border border-[#DDDDDD] rounded-md">
            Leave & Discard Changes</button>
        </div>
      </div>
    </div>
  </div>
)}
    {showPopup && (
  <div>
    {popupType === '200or201' && <Msg200or201 message={popupMessage} timeout={3000} />}
    {popupType === '204' && <Msg204 message={popupMessage} timeout={3000} />}
    {popupType === '400' && <Msg400 message={popupMessage} closePopup={closePopup} />}
          {popupType === '401' && <Msg401 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '403' && <Msg403 message={popupMessage} closePopup={BackToLoginPage} />}
          {popupType === '500' && <Msg500 message={popupMessage} closePopup={Retry} cancelPopup={closePopup} />}
  </div>
)}
{/* popup ends */}
    </div>
   
  );
};

export default EditPaymentReceived;
