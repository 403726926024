import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import useCommonResponseHandler from '../CommenResponseAndErrorHandlers/CommenResponseHandler';
import { Msg200or201, Msg204, Msg400, Msg401, Msg403, Msg500 } from '../Messages/Messages';
import { Loader } from '../Loader/Loader';
import Search from '../Components/Search';

const InvoiceList = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchUrl = process.env.REACT_APP_FORE_BRICKBUCKET;


  const searchTypes = ['Invoice No', 'Order No', 'Customer Name', 'Status'];

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'text-green-500';
      case 'Pending':
        return 'text-gray-500';
      case 'Overdue':
        return 'text-gray-500';
      default:
        return '';
    }
  };
  const [invoices, setInvoices] = useState([]);
  

  const handleSearch = (query, type) => {
    if (!query || !type) {
      setFilteredInvoices(invoices);
      return;
    }
  
    const filtered = invoices.filter((invoice) => {
      const lowercaseQuery = query.toLowerCase();
      switch (type) {
        case 'Invoice No':
          return invoice?.invoice_number?.toLowerCase().includes(lowercaseQuery);
        case 'Order No':
          return invoice?.order_number?.toLowerCase().includes(lowercaseQuery);
        case 'Customer Name':
          return invoice?.customer_name?.toLowerCase().includes(lowercaseQuery);
        case 'Status':
          return invoice?.status?.toLowerCase().includes(lowercaseQuery);
        default:
          return false;
      }
    });
    setFilteredInvoices(filtered);
  };


const handleCreate = () => {
  navigate("/bb/app/sales/newinvoice");
};
const handleNavigateToInvoiceDetails = (invoice) => {
  const invoiceWithItems = {
    ...invoice,
  };
  
  navigate("/bb/app/sales/invoicedetails", {
    state: {
      InvoiceData: invoiceWithItems,
      invoiceId: invoice._id
    }
  });
};

async function getAllInvoices() {
  try {
    const response = await fetch(`${fetchUrl}invoice/get-all-invoices`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        console.log('Invoices:', data.data);
        setInvoices(data.data)
      } else {
        console.error('Error:', data.message);
        alert(`Failed to fetch invoices: ${data.message}`);
      }
    } else {
      console.error('HTTP Error:', response.status);
      alert(`HTTP Error: ${response.status}`);
    }
  } catch (error) {
    console.error('Fetch Error:', error);
    alert('Failed to fetch invoices due to a network error');
  }
}



useEffect(() => {
  getAllInvoices();
}, [fetchUrl]);





  return (
    <div className="bg-white flex flex-row w-full justify-left h-full overflow-y-hidden">
      <div className="flex flex-row w-full">
        <div className="w-full flex-1">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="w-full h-full flex flex-col justify-between">
              <div className="w-full h-16 border-b flex items-center">
                <div className="flex w-full justify-between px-5 items-center">
                  <div className='space-x-10 flex flex-row'>
                    <h1 className="text-lg font-semibold">Invoice Lists</h1>
                    <Search searchTypes={searchTypes} onSearch={handleSearch} />
                  </div>
<button 
  onClick={handleCreate} 
  className="bg-[#F7525A] text-white z-20 px-2 py-1 rounded hover:bg-[#d14148]"
  title=""
  data-tooltip="Create Invoice" 
  style={{ '--tooltip-bg': 'black' }}
>
  + New
</button>

                </div>
              </div>
              <div className="w-full h-full bg-white rounded-xs overflow-hidden">
                <div className="overflow-y-auto h-[80vh]">
                {invoices.length > 0 ?(
                  <table className="w-full mb-5">
                    <thead className="sticky top-0 z-10 bg-[#fafafc] text-[#6c7184] border-b">
                      <tr className='border-b'>
                        <th className="text-xs py-3 text-center">
                          <input type="checkbox" className="mr-2" />
                        </th>
                        <th className="text-xs py-3 text-center">DATE</th>
                        <th className="text-xs py-3 text-center">INVOICE No</th>
                        <th className="text-xs py-3 text-center">ORDER No</th>
                        <th className="text-xs py-3 text-center">CUSTOMER NAME</th>
                        <th className="text-xs py-3 text-center">STATUS</th>
                        <th className="text-xs py-3 text-center">DUE DATE</th>
                        <th className="text-xs py-3 text-center">AMOUNT</th>
                        <th className="text-xs py-3 text-center">BALANCE DUE</th>
                      </tr>
                    </thead>
                    <tbody className='border-b border-t'>
                      {/* Add your invoice data mapping here */}
                      
  {(filteredInvoices.length > 0 ? filteredInvoices : invoices).map((invoice) => (
    <tr key={invoice.id} className="text-sm border-b">
      <td className="py-2 text-center">
        <input type="checkbox" className="mr-2" />
      </td>
      <td className="py-2 text-center">
        {new Date(invoice?.invoice_date).toLocaleDateString()}
      </td>
      <td 
  className="py-2 text-center text-[#408dfb] font-500 cursor-pointer"
  onClick={() => handleNavigateToInvoiceDetails(invoice)}
>
  {invoice?.invoice_number}
</td>

      <td className="py-2 text-center">
        {invoice?.order_number}
      </td>
      <td className="py-2 text-center">
        {invoice?.customer_id?.customer_name}
      </td>
      <td className={`py-2 text-center ${getStatusColor(invoice.status)}`}>
        {invoice?.status}
      </td>
      <td className="py-2 text-center">
        {new Date(invoice.due_date).toLocaleDateString()}
      </td>
      <td className="py-2 text-center">
        ₹{invoice?.total?.toLocaleString('hi-IN', { minimumFractionDigits: 2 })}
      </td>
      <td className="py-2 text-center">
        ₹{invoice?.balance_due?.toLocaleString('hi-IN', { minimumFractionDigits: 2 })}
      </td>
    </tr>
  ))}


                    </tbody>
                  </table>
                ) : (
                    <>
                            <div className="m-4 p-4 text-center border border-grey-500 text-gray-500">
          No Invoices Found.
        </div>
                      <div className="flex justify-center items-center mb-4">
                        <h1 className="text-xl font-bold">Create New Invoice</h1>
                      </div>
                      <div className="flex justify-center items-center">
                        <button className="bg-[#F7525A] text-white px-2 py-1 rounded-md" onClick={handleCreate}>Create</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceList;
